import React, { lazy } from 'react';

import { createBrowserHistory } from 'history'
import { PromiseType } from 'utility-types';

export const history = createBrowserHistory()

const dummy: React.FC = () => {
	return <></>
}
const RETRY_STORAGE_NAME = ''
// (c) https://raphael-leger.medium.com/react-webpack-chunkloaderror-loading-chunk-x-failed-ac385bd110e0
export const lazyWithRetry: typeof lazy = (componentImport) => {
	return lazy(async () => {
		const pageHasAlreadyBeenForceRefreshed = JSON.parse(
			window.localStorage.getItem(RETRY_STORAGE_NAME) || 'false',
		) as boolean;

		let component = { default: dummy } as PromiseType<ReturnType<typeof componentImport>>;
		try {
			component = await componentImport();
			window.localStorage.setItem(RETRY_STORAGE_NAME, 'false');

		}
		catch (error: unknown) {
			if (!pageHasAlreadyBeenForceRefreshed) {
				// Assuming that the user is not on the latest version of the application.
				// Let's refresh the page immediately.
				window.localStorage.setItem(RETRY_STORAGE_NAME, 'true' );
				window.location.reload();
			}
			else {
				// The page has already been reloaded
				// Assuming that user is already using the latest version of the application.
				// Let's let the application crash and raise the error.
				throw error;
			}
		}
		return component;
	});
}