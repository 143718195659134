export const configurator = {
	'field_name': 'Configurators name',
	'field_configs[x].name': 'Display name',
	'field_configs[x].defaultValue': 'Fallback value',
	'field_configs[x].valueDataType': 'Data type of value',
	'field_configs[x].path': 'Path',

	'valueDataType.NUMERIC': 'Numeric',
	'valueDataType.NUMERIC_INT': 'Integer',
	'valueDataType.NUMERIC_FLOAT': 'Float',

	'valueDataType.CATEGORICAL': 'Categorical',
	'valueDataType.CATEGORICAL_STRING': 'String',
	'valueDataType.CATEGORICAL_INT': 'Integer',
	'valueDataType.CATEGORICAL_BOOLEAN': 'Boolean',
	'valueDataType.CATEGORICAL_DATETIME': 'Datetime',
	'valueDataType.CATEGORICAL_TIMESTAMP': 'Timestamp',

	'valueDataType.OTHER': 'Other',
	'valueDataType.OTHER_STRING': 'String',
}
export default configurator;