export const preferences = {
	'field_user_type_FREE': 'Community',
	'field_user_type_PROFESSIONAL': 'Team',
	'field_user_type_ENTERPRISE': 'Custom',
	'field_user_type_ADMIN': 'Admin',

	'field_team_role_OWNER': 'Owner',
	'field_team_role_ADMIN': 'Admin',
	'field_team_role_MEMBER': 'Member',
	'field_team_role_SERVICEACCOUNT': 'Service account',

	'field_createTeamMembershipRequest.email': 'Email',
	'field_createTeamMembershipRequest.email_placeholder': 'Invite people to your team by entering their email',
}
export default preferences;
