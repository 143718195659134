// JSON SCHEMA taken from puppeteer https://github.com/puppeteer/puppeteer/blob/main/src/common/EvalTypes.ts#L42
export type Serializable =
	| number
	| string
	| boolean
	| null
	| bigint
	| JSONArray
	| JSONObject;
export type JSONArray = Serializable[];
// eslint-disable-next-line @typescript-eslint/naming-convention
export interface JSONObject {
	[key: string]: Serializable;
}


// inspired by https://stackoverflow.com/a/33005742/809423
export const dotNotate = (
	obj: JSONObject,
	target: Set<string> = new Set<string>(),
	prefix = '',
): Set<string> => {
	Object
		.keys(obj)
		.forEach((key) => {
			if ( typeof(obj[key]) === 'object' && obj[key] !== null ) {
				dotNotate(obj[key] as JSONObject, target, `${prefix}${key}.`);
			}
			else {
				return target.add(`${prefix}${key}`);
			}
		});

	return target;
}