import React, { createContext, useContext, useEffect, useRef } from 'react';

import { useSnackbar } from 'notistack';
import { createStore, useStore }  from 'zustand';
import { combine, devtools, persist } from 'zustand/middleware';

import mixpanel from '../../contexts/mixpanel';


export interface IStarredState {
	starred: Array<string>;
	toggle: (id: string, name?: string) => void;
	add: (id: string, name?: string) => void;
	remove: (id: string, name?: string) => void;

}

export const createStarredStore = (userId: string, enqueueSnackbar: ReturnType<typeof useSnackbar>['enqueueSnackbar']) => {
	return createStore<IStarredState>()(
		devtools(
			persist(
				combine(
					{
						starred: new Array<string>(),
					},
					(set) => ({
						toggle: (id: string, name?: string) => set((state) => {
							const newStarred = [...state.starred];
							const pos = newStarred.indexOf(id);
							// unstarr
							if (pos >= 0) {
								newStarred.splice(pos, 1);
								enqueueSnackbar(`Unstarred ${name ? name : 'dataset'}`, { variant: 'warning' });
								mixpanel.track('dataset-starring', { datasetId: id, starred: false });
							}
							// starr
							else {
								// add to the beginning
								newStarred.splice(0,0, id);
								enqueueSnackbar(`Starred ${name ? name : 'dataset'}`, { variant: 'success' });
								mixpanel.track('dataset-starring', { datasetId: id, starred: true });
							}
							return { starred: newStarred }
						}),
						add: (id: string, name?: string) => set((state) => {
							const newStarred = [...state.starred];
							const pos = newStarred.indexOf(id);
							if (pos === -1) {
								// add to the beginning
								newStarred.splice(0,0, id);
							}
							enqueueSnackbar(`Starred ${name ? name : 'dataset'}`, { variant: 'success' });
							mixpanel.track('dataset-starring', { datasetId: id, starred: true });
							return { starred: newStarred }
						}),
						remove: (id: string, name?: string) => set((state) => {
							const newStarred = [...state.starred];
							const pos = newStarred.indexOf(id);
							if (pos >= 0) {
								newStarred.splice(pos, 1);
							}
							enqueueSnackbar(`Unstarred ${name ? name : 'dataset'}`, { variant: 'success' });
							mixpanel.track('dataset-starring', { datasetId: id, starred: false });
							return { starred: newStarred }
						}),
					}),
				),
				{
					name: `starredDatasets-${userId}`,
				},
			),
		),
	);
}



export type StarredStore = ReturnType<typeof createStarredStore>
export const StarredContext = createContext<StarredStore | null>(null)

export function useStarredContext<T>(selector: (state: IStarredState) => T): T {
	const store = useContext(StarredContext)
	if (!store) throw new Error('Missing BearContext.Provider in the tree')
	return useStore(store, selector)
}

export function StarredProvider({ children, ...props }: React.PropsWithChildren<{userId: string}>) {
	const { userId } = props;
	const { enqueueSnackbar } = useSnackbar();
	const starredStore = useRef<StarredStore>(createStarredStore(userId, enqueueSnackbar ));
	useEffect(() => {
		starredStore.current = createStarredStore(userId , enqueueSnackbar )
	},[enqueueSnackbar, userId])

	return <StarredContext.Provider value={starredStore.current}>
		{children}
	</StarredContext.Provider>
}