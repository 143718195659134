import React, { useCallback, useEffect, useRef, useState } from 'react';

import { makeStyles } from 'tss-react/mui';
import useResizeObserver from 'use-resize-observer';

import { Box, Alert, AlertTitle, Collapse, Theme } from '@mui/material';

interface IGenericHint {
	title?: string;
	message: string | React.ReactElement;
	onClose?: () => void;
	onOpen?: () => void;
	type?: 'minimal' | 'normal';
	open?: boolean;
	closedSize?: ReturnType<typeof useResizeObserver>
}


const useStyles = makeStyles()((theme: Theme) => ({
	alert: {
		transition: theme.transitions.create('background-color', {
			easing: theme.transitions.easing.easeOut,
			duration: theme.transitions.duration.enteringScreen,
		}),

		'&.closed': {
			backgroundColor: 'transparent',
			cursor: 'pointer',
		},
	},
}));

export const GenericHint: React.FC<IGenericHint> = (props) => {
	const { title, message, type = 'normal', open = true, onClose, onOpen, closedSize } = props;

	const { classes, cx } = useStyles();

	const [isEnded, setIsEnded] = useState(open);
	const onExited = useCallback(() => {
		setIsEnded(false);
	}, []);
	const onEntered = useCallback(() => {
		setIsEnded(true);
	}, []);

	// remember size before closing otherwise it will go crazy opening up again
	const refResize = useRef(null);
	const size = useResizeObserver<HTMLElement>({
		ref: refResize,
	});
	const [lastSize, setLastSize] = useState<ReturnType<typeof useResizeObserver>>(!open ? closedSize || size : size);
	useEffect(() => {
		if (open && isEnded && size.width !== lastSize.width) {
			setLastSize(size);
		}
		else if (lastSize.width === 0 && lastSize.height === 0) {
			setLastSize(size);
		}
	}, [size, lastSize, open, isEnded]);

	return <>
		<Box ref={refResize} my={type === 'normal' ? 1 : 0}>
			<Box onClick={!open ? onOpen : undefined} >
				<Collapse in={open} collapsedSize={50} onExited={onExited} onEntered={onEntered} onExiting={() => setIsEnded(false)} onEntering={() => setIsEnded(false)}>
					<Collapse in={open} orientation='horizontal' collapsedSize={50}>
						<Box sx={(!open || !isEnded) ? { minWidth: lastSize.width, minHeight: lastSize.height } : {}}>
							<Alert severity='info' onClose={onClose} className={cx(classes.alert, !open ? 'closed' : false)} >
								{title && <AlertTitle>{title}</AlertTitle>}
								<span>{message}</span>
							</Alert>
						</Box>
					</Collapse>
				</Collapse>
			</Box>
		</Box>
	</>;
};