import { dataset } from '../../components/mydatasets/dataset.en.json';
import { interactiveReport } from '../../components/mydatasets/DatasetRunReport/interactiveReport.en.json';
import { downloadExport } from '../../components/mydatasets/export.en.json';
import { searchBar_en } from '../../components/SearchBar';
import { docker } from '../../pages/docker/docker.en.json';
import { dockerworker } from '../../pages/docker/worker/dockerworker.en.json';
import { glossary } from '../../pages/glossary/glossary.en.json';
import { configurator } from '../../pages/metadata/configurator.en.json';
import { metadata } from '../../pages/metadata/metadata.en.json';
import { questionnaire } from '../../pages/onboarding/questionnaire.en.json';
import { preferences } from '../../pages/preferences/preferences.en.json';
import { sample } from '../../pages/sample/sample.en.json';
import { sampling } from '../../pages/sampling/sampling.en.json';
import { tag } from '../../pages/tags/tag.en.json';

import { errorCodes } from './errorCodes.json';



export const dict = {
	error_codes: errorCodes,
	errors: {
		'unspecific': 'There was an unknown error with your network connection. Please try again.',
		'TypeError.Failed to fetch': 'We were not able to reach the API. Please try again.',
		// safari specific "cant connect to the server" error
		'TypeError.Could not connect to the server.': 'We were not able to reach the API. Please try again.',
		'Error.Request failed with status code 403': 'You do not have permission to access this resource.',
	},
	error_form: {
		'unspecific': 'This field is invalid.',
		'field_must_be_oneOf_default': 'OneOf for {{i18nLabel}} is not what we expected...',
		'field_must_be_oneOf_passwordRepeat': 'The passwords need to match.',
		'field_must_be_oneOf': '$t(error_form:field_must_be_oneOf_{{field}}, error_form:field_must_be_oneOf_default).',
		'field_required': '$t({{i18nLabel}}) is required.',
		'field_defined': '$t({{i18nLabel}}) is required.',
		'field_must_be_type_number': '$t({{i18nLabel}}) needs to be a valid number.',
		'field_must_be_type_string': '$t({{i18nLabel}}) needs to be a valid string.',
		'field_must_be_type_date': '$t({{i18nLabel}}) needs to be a valid date.',
		'field_must_be_type_boolean': '$t({{i18nLabel}}) needs to be a boolean (true or false).',
		// string
		'field_not_matches': '$t({{i18nLabel}}) $t(error_form_regex:{{props.regex}}).',
		'field_not_email': 'Please enter a valid email.',
		'field_too_short': '$t({{i18nLabel}}) needs to be at least {{props.min}} chars long.',
		// number,
		'field_value_small': '$t({{i18nLabel}}) needs to be greater than or equal to {{props.min}}.',
		'field_value_big': '$t({{i18nLabel}}) needs to be less than or equal to {{props.max}}.',
		'field_value_positive': '$t({{i18nLabel}}) needs to be a positive number.',
		'field_value_integer': '$t({{i18nLabel}}) must not be a floating point number.',
	},
	error_form_regex: {
		'/^[a-f0-9]{24}$/': ' needs to ba a valid MongoDB Object.',
		'/^[a-zA-Z0-9_-]+$/': ' is only allowed to include alphanumeric chars and the special chars - _. Whitespace is not permitted.',
		'/^[a-zA-Z0-9][a-zA-Z0-9 _-]+$/': ' is only allowed to include alphanumeric chars at the beginning followed by further alphanumeric chars and the special chars - _ as well as whitespace.',

		'/^0x[a-f0-9]+$/': ' needs to be a valid hex string.',

		// s3 externalId regex, see https://docs.aws.amazon.com/cli/latest/reference/sts/assume-role.html
		// actual regex is ^[a-zA-Z0-9_+=,.@:\\/-]+$
		// Explanation: we use extensive interpolation with i18n (The lib for translations etc.) and have it
		// about 3 levels deep (see i18n/form.ts and error_form of this file) so we can create
		// nice error messages based on form failures when using yup. When we do this deeplevel interpolation
		//  with $t( it interprets a , as another param. As the regex has an , in it, i18n expects this to be
		// some param and only uses the left part as a key.
		// I was not able to configure i18n in such a way that interpolation ignored the , successfully
		'/^[a-zA-Z0-9_+=': ' is only allowed to include alphanumeric chars and the special chars _ - + = . @ : / - ,',

		// because i18n treats : as a namespace, we need to double add it to the translation
		'/^[a-zA-Z0-9][a-zA-Z0-9 .:;=@_-]+$/': ' is only allowed to include alphanumeric chars at the beginning followed by further alphanumeric chars and the special chars - _ . : ; = @ as well as whitespace.',
		'/^[a-zA-Z0-9][a-zA-Z0-9 .::;=@_-]+$/': ' is only allowed to include alphanumeric chars at the beginning followed by further alphanumeric chars and the special chars - _ . : ; = @ as well as whitespace.',

		// because i18n treats : as a namespace, we need to double add it to the translation
		'/^arn:aws:iam::[0-9]{12}:role.+$/': ' needs to be a valid AWS ARN of a role.',
		'/^arn::aws::iam::::[0-9]{12}::role.+$/': ' needs to be a valid AWS ARN of a role.',

		// because i18n treats : as a namespace, we need to double add it to the translation
		'/^arn:aws:kms:[a-zA-Z0-9-]*:[0-9]{12}:key.+$/': ' needs to be a valid AWS ARN of a KMS key.',
		'/^arn::aws::kms::[a-zA-Z0-9-]*::[0-9]{12}::key.+$/': ' needs to be a valid AWS ARN of a KMS key.',
	},

	sample,
	metadata,
	sampling,
	dataset,
	docker,
	dockerworker,
	glossary,
	tag,
	configurator,
	preferences,
	questionnaire,
	export: downloadExport,

	// little stuff
	searchBar: searchBar_en,

	interactiveReport,
};
