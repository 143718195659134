/* tslint:disable */
/* eslint-disable */
/**
 * Lightly API
 * Lightly.ai enables you to do self-supervised learning in an easy and intuitive way. The lightly.ai OpenAPI spec defines how one can interact with our REST API to unleash the full potential of lightly.ai
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: support@lightly.ai
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
// SRC: https://github.com/OpenAPITools/openapi-generator/blob/master/modules/openapi-generator/src/main/resources/typescript-fetch/modelGeneric.mustache
// synched 02.02.2023
import * as yup from 'yup';
import typia from 'typia';
import * as fastJSON from 'fast-json-stringify';
import { Options, ArraySchema, ObjectSchema } from 'fast-json-stringify';
import { exists, mapValues } from '../runtime';
import type { Sector } from './Sector';
import { SectorFromJSON, SectorFromJSONTyped, SectorToJSON, } from './Sector';
import type { Usage } from './Usage';
import { UsageFromJSON, UsageFromJSONTyped, UsageToJSON, } from './Usage';
/*
causes circular dependendies
*/
// SRC: https://github.com/OpenAPITools/openapi-generator/blob/master/modules/openapi-generator/src/main/resources/typescript-fetch/modelGenericInterfaces.mustache
// synced: 17.07.2023
/**
 *
 * @export
 * @interface QuestionnaireData
 *
 */
// create custom validators for the regexes
export interface QuestionnaireData {
    /**
     *
     * #type string
     * #memberof QuestionnaireData
        
        
    * @minLength 3
         */
    company?: string;
    /**
     *
     * #type Sector
     * #memberof QuestionnaireData
        
        
         */
    sector?: Sector;
    /**
     *
     * #type Usage
     * #memberof QuestionnaireData
        
        
         */
    usage?: Usage;
    /**
     *
     * #type string
     * #memberof QuestionnaireData
        
        
         */
    usageCustomReason?: string | null;
}
/**
 * Check if a given object implements the QuestionnaireData interface.
 */
export function instanceOfQuestionnaireData(value: object): boolean {
    let isInstance = true;
    return isInstance;
}
/* LIGHTLY CUSTOM START -> modelGeneric */
import { SectorValidator, SectorStringifyShape, SectorStringifyShapeProperties, } from './Sector';
import { UsageValidator, UsageStringifyShape, UsageStringifyShapeProperties, } from './Usage';
export const QuestionnaireDataTypiaAssertEquals = (input: any) => {
    return ((input: any): QuestionnaireData => {
        const __is = (input: any, _exceptionable: boolean = true): input is QuestionnaireData => {
            const $io0 = (input: any, _exceptionable: boolean = true): boolean => (undefined === input.company || "string" === typeof input.company && 3 <= input.company.length) && (undefined === input.sector || "ADVERTISING" === input.sector || "AGRICULTURE" === input.sector || "AUTOMOTIVE" === input.sector || "EDUCATION" === input.sector || "ENERGY" === input.sector || "ENTERTAINMENT" === input.sector || "ENVIRONMENTAL" === input.sector || "FINANCE" === input.sector || "FOOD" === input.sector || "HEALTHCARE" === input.sector || "INTERNET_OF_THINGS" === input.sector || "LOGISTICS" === input.sector || "MACHINE_LEARNING" === input.sector || "MANUFACTURING" === input.sector || "MEDICINE" === input.sector || "RECYCLING" === input.sector || "RETAIL" === input.sector || "ROBOTICS" === input.sector || "SECURITY" === input.sector || "SOFTWARE_DEVELOPMENT" === input.sector || "SPORTS" === input.sector || "SURVEILLANCE" === input.sector || "TRANSPORTATION" === input.sector || "OTHER" === input.sector) && (undefined === input.usage || "LABELING" === input.usage || "SELECTING" === input.usage || "VISUALIZATION" === input.usage || "MODEL_TRAINING" === input.usage || "DATASET_MANAGING" === input.usage || "CHECKING_THE_TOOL" === input.usage || "OTHER" === input.usage) && (null === input.usageCustomReason || undefined === input.usageCustomReason || "string" === typeof input.usageCustomReason) && (0 === Object.keys(input).length || Object.keys(input).every((key: any) => {
                if (["company", "sector", "usage", "usageCustomReason"].some((prop: any) => key === prop))
                    return true;
                const value = input[key];
                if (undefined === value)
                    return true;
                return false;
            }));
            return "object" === typeof input && null !== input && false === Array.isArray(input) && $io0(input, true);
        };
        if (false === __is(input))
            ((input: any, _path: string, _exceptionable: boolean = true): input is QuestionnaireData => {
                const $guard = (typia.createAssertEquals as any).guard;
                const $join = (typia.createAssertEquals as any).join;
                const $ao0 = (input: any, _path: string, _exceptionable: boolean = true): boolean => (undefined === input.company || "string" === typeof input.company && (3 <= input.company.length || $guard(_exceptionable, {
                    path: _path + ".company",
                    expected: "string (@minLength 3)",
                    value: input.company
                })) || $guard(_exceptionable, {
                    path: _path + ".company",
                    expected: "(string | undefined)",
                    value: input.company
                })) && (undefined === input.sector || "ADVERTISING" === input.sector || "AGRICULTURE" === input.sector || "AUTOMOTIVE" === input.sector || "EDUCATION" === input.sector || "ENERGY" === input.sector || "ENTERTAINMENT" === input.sector || "ENVIRONMENTAL" === input.sector || "FINANCE" === input.sector || "FOOD" === input.sector || "HEALTHCARE" === input.sector || "INTERNET_OF_THINGS" === input.sector || "LOGISTICS" === input.sector || "MACHINE_LEARNING" === input.sector || "MANUFACTURING" === input.sector || "MEDICINE" === input.sector || "RECYCLING" === input.sector || "RETAIL" === input.sector || "ROBOTICS" === input.sector || "SECURITY" === input.sector || "SOFTWARE_DEVELOPMENT" === input.sector || "SPORTS" === input.sector || "SURVEILLANCE" === input.sector || "TRANSPORTATION" === input.sector || "OTHER" === input.sector || $guard(_exceptionable, {
                    path: _path + ".sector",
                    expected: "(\"ADVERTISING\" | \"AGRICULTURE\" | \"AUTOMOTIVE\" | \"EDUCATION\" | \"ENERGY\" | \"ENTERTAINMENT\" | \"ENVIRONMENTAL\" | \"FINANCE\" | \"FOOD\" | \"HEALTHCARE\" | \"INTERNET_OF_THINGS\" | \"LOGISTICS\" | \"MACHINE_LEARNING\" | \"MANUFACTURING\" | \"MEDICINE\" | \"OTHER\" | \"RECYCLING\" | \"RETAIL\" | \"ROBOTICS\" | \"SECURITY\" | \"SOFTWARE_DEVELOPMENT\" | \"SPORTS\" | \"SURVEILLANCE\" | \"TRANSPORTATION\" | undefined)",
                    value: input.sector
                })) && (undefined === input.usage || "LABELING" === input.usage || "SELECTING" === input.usage || "VISUALIZATION" === input.usage || "MODEL_TRAINING" === input.usage || "DATASET_MANAGING" === input.usage || "CHECKING_THE_TOOL" === input.usage || "OTHER" === input.usage || $guard(_exceptionable, {
                    path: _path + ".usage",
                    expected: "(\"CHECKING_THE_TOOL\" | \"DATASET_MANAGING\" | \"LABELING\" | \"MODEL_TRAINING\" | \"OTHER\" | \"SELECTING\" | \"VISUALIZATION\" | undefined)",
                    value: input.usage
                })) && (null === input.usageCustomReason || undefined === input.usageCustomReason || "string" === typeof input.usageCustomReason || $guard(_exceptionable, {
                    path: _path + ".usageCustomReason",
                    expected: "(null | string | undefined)",
                    value: input.usageCustomReason
                })) && (0 === Object.keys(input).length || (false === _exceptionable || Object.keys(input).every((key: any) => {
                    if (["company", "sector", "usage", "usageCustomReason"].some((prop: any) => key === prop))
                        return true;
                    const value = input[key];
                    if (undefined === value)
                        return true;
                    return $guard(_exceptionable, {
                        path: _path + $join(key),
                        expected: "undefined",
                        value: value
                    });
                })));
                return ("object" === typeof input && null !== input && false === Array.isArray(input) || $guard(true, {
                    path: _path + "",
                    expected: "QuestionnaireData",
                    value: input
                })) && $ao0(input, _path + "", true) || $guard(true, {
                    path: _path + "",
                    expected: "QuestionnaireData",
                    value: input
                });
            })(input, "$input", true);
        return input;
    })(input);
};
export const QuestionnaireDataTypiaAssertStringify = (input: QuestionnaireData): string => {
    return ((input: any): string => { const assert = (input: any): QuestionnaireData => {
        const __is = (input: any): input is QuestionnaireData => {
            const $io0 = (input: any): boolean => (undefined === input.company || "string" === typeof input.company && 3 <= input.company.length) && (undefined === input.sector || "ADVERTISING" === input.sector || "AGRICULTURE" === input.sector || "AUTOMOTIVE" === input.sector || "EDUCATION" === input.sector || "ENERGY" === input.sector || "ENTERTAINMENT" === input.sector || "ENVIRONMENTAL" === input.sector || "FINANCE" === input.sector || "FOOD" === input.sector || "HEALTHCARE" === input.sector || "INTERNET_OF_THINGS" === input.sector || "LOGISTICS" === input.sector || "MACHINE_LEARNING" === input.sector || "MANUFACTURING" === input.sector || "MEDICINE" === input.sector || "RECYCLING" === input.sector || "RETAIL" === input.sector || "ROBOTICS" === input.sector || "SECURITY" === input.sector || "SOFTWARE_DEVELOPMENT" === input.sector || "SPORTS" === input.sector || "SURVEILLANCE" === input.sector || "TRANSPORTATION" === input.sector || "OTHER" === input.sector) && (undefined === input.usage || "LABELING" === input.usage || "SELECTING" === input.usage || "VISUALIZATION" === input.usage || "MODEL_TRAINING" === input.usage || "DATASET_MANAGING" === input.usage || "CHECKING_THE_TOOL" === input.usage || "OTHER" === input.usage) && (null === input.usageCustomReason || undefined === input.usageCustomReason || "string" === typeof input.usageCustomReason);
            return "object" === typeof input && null !== input && false === Array.isArray(input) && $io0(input);
        };
        if (false === __is(input))
            ((input: any, _path: string, _exceptionable: boolean = true): input is QuestionnaireData => {
                const $guard = (typia.createAssertStringify as any).guard;
                const $ao0 = (input: any, _path: string, _exceptionable: boolean = true): boolean => (undefined === input.company || "string" === typeof input.company && (3 <= input.company.length || $guard(_exceptionable, {
                    path: _path + ".company",
                    expected: "string (@minLength 3)",
                    value: input.company
                })) || $guard(_exceptionable, {
                    path: _path + ".company",
                    expected: "(string | undefined)",
                    value: input.company
                })) && (undefined === input.sector || "ADVERTISING" === input.sector || "AGRICULTURE" === input.sector || "AUTOMOTIVE" === input.sector || "EDUCATION" === input.sector || "ENERGY" === input.sector || "ENTERTAINMENT" === input.sector || "ENVIRONMENTAL" === input.sector || "FINANCE" === input.sector || "FOOD" === input.sector || "HEALTHCARE" === input.sector || "INTERNET_OF_THINGS" === input.sector || "LOGISTICS" === input.sector || "MACHINE_LEARNING" === input.sector || "MANUFACTURING" === input.sector || "MEDICINE" === input.sector || "RECYCLING" === input.sector || "RETAIL" === input.sector || "ROBOTICS" === input.sector || "SECURITY" === input.sector || "SOFTWARE_DEVELOPMENT" === input.sector || "SPORTS" === input.sector || "SURVEILLANCE" === input.sector || "TRANSPORTATION" === input.sector || "OTHER" === input.sector || $guard(_exceptionable, {
                    path: _path + ".sector",
                    expected: "(\"ADVERTISING\" | \"AGRICULTURE\" | \"AUTOMOTIVE\" | \"EDUCATION\" | \"ENERGY\" | \"ENTERTAINMENT\" | \"ENVIRONMENTAL\" | \"FINANCE\" | \"FOOD\" | \"HEALTHCARE\" | \"INTERNET_OF_THINGS\" | \"LOGISTICS\" | \"MACHINE_LEARNING\" | \"MANUFACTURING\" | \"MEDICINE\" | \"OTHER\" | \"RECYCLING\" | \"RETAIL\" | \"ROBOTICS\" | \"SECURITY\" | \"SOFTWARE_DEVELOPMENT\" | \"SPORTS\" | \"SURVEILLANCE\" | \"TRANSPORTATION\" | undefined)",
                    value: input.sector
                })) && (undefined === input.usage || "LABELING" === input.usage || "SELECTING" === input.usage || "VISUALIZATION" === input.usage || "MODEL_TRAINING" === input.usage || "DATASET_MANAGING" === input.usage || "CHECKING_THE_TOOL" === input.usage || "OTHER" === input.usage || $guard(_exceptionable, {
                    path: _path + ".usage",
                    expected: "(\"CHECKING_THE_TOOL\" | \"DATASET_MANAGING\" | \"LABELING\" | \"MODEL_TRAINING\" | \"OTHER\" | \"SELECTING\" | \"VISUALIZATION\" | undefined)",
                    value: input.usage
                })) && (null === input.usageCustomReason || undefined === input.usageCustomReason || "string" === typeof input.usageCustomReason || $guard(_exceptionable, {
                    path: _path + ".usageCustomReason",
                    expected: "(null | string | undefined)",
                    value: input.usageCustomReason
                }));
                return ("object" === typeof input && null !== input && false === Array.isArray(input) || $guard(true, {
                    path: _path + "",
                    expected: "QuestionnaireData",
                    value: input
                })) && $ao0(input, _path + "", true) || $guard(true, {
                    path: _path + "",
                    expected: "QuestionnaireData",
                    value: input
                });
            })(input, "$input", true);
        return input;
    }; const stringify = (input: QuestionnaireData): string => {
        const $string = (typia.createAssertStringify as any).string;
        const $throws = (typia.createAssertStringify as any).throws;
        const $tail = (typia.createAssertStringify as any).tail;
        const $so0 = (input: any): any => `{${$tail(`${undefined === input.company ? "" : `"company":${undefined !== input.company ? $string(input.company) : undefined},`}${undefined === input.sector ? "" : `"sector":${undefined !== input.sector ? (() => {
            if ("string" === typeof input.sector)
                return $string(input.sector);
            if ("string" === typeof input.sector)
                return "\"" + input.sector + "\"";
            $throws({
                expected: "(\"ADVERTISING\" | \"AGRICULTURE\" | \"AUTOMOTIVE\" | \"EDUCATION\" | \"ENERGY\" | \"ENTERTAINMENT\" | \"ENVIRONMENTAL\" | \"FINANCE\" | \"FOOD\" | \"HEALTHCARE\" | \"INTERNET_OF_THINGS\" | \"LOGISTICS\" | \"MACHINE_LEARNING\" | \"MANUFACTURING\" | \"MEDICINE\" | \"OTHER\" | \"RECYCLING\" | \"RETAIL\" | \"ROBOTICS\" | \"SECURITY\" | \"SOFTWARE_DEVELOPMENT\" | \"SPORTS\" | \"SURVEILLANCE\" | \"TRANSPORTATION\" | undefined)",
                value: input.sector
            });
        })() : undefined},`}${undefined === input.usage ? "" : `"usage":${undefined !== input.usage ? (() => {
            if ("string" === typeof input.usage)
                return $string(input.usage);
            if ("string" === typeof input.usage)
                return "\"" + input.usage + "\"";
            $throws({
                expected: "(\"CHECKING_THE_TOOL\" | \"DATASET_MANAGING\" | \"LABELING\" | \"MODEL_TRAINING\" | \"OTHER\" | \"SELECTING\" | \"VISUALIZATION\" | undefined)",
                value: input.usage
            });
        })() : undefined},`}${undefined === input.usageCustomReason ? "" : `"usageCustomReason":${undefined !== input.usageCustomReason ? null !== input.usageCustomReason ? $string(input.usageCustomReason) : "null" : undefined}`}`)}}`;
        return $so0(input);
    }; return stringify(assert(input)); })(input);
};
export const QuestionnaireDataValidatorShape = {
    'company': yup.string().min(3),
    'sector': SectorValidator.optional().default(undefined).test('is-valid', "Please select a sector", (value: any) => {
        if (!value) {
            return false;
        }
        return true;
    }),
    'usage': UsageValidator.optional().default(undefined).test('is-valid', "Please select a usage", (value: any) => {
        if (!value) {
            return false;
        }
        return true;
    }),
    'usageCustomReason': yup.string().nullable(),
};
export const QuestionnaireDataValidator = yup.object().shape(QuestionnaireDataValidatorShape);
export const QuestionnaireDataStringifyShapeProperties: ObjectSchema['properties'] = {
    'company': {
        'type': 'string',
        'minLength': 3,
    } as unknown as fastJSON.StringSchema,
    'sector': SectorStringifyShape,
    'usage': UsageStringifyShape,
    'usageCustomReason': {
        'type': 'string',
        'nullable': true,
    } as unknown as fastJSON.StringSchema,
};
export const QuestionnaireDataStringifyShape: ObjectSchema = {
    title: 'QuestionnaireData Stringifier',
    type: 'object',
    properties: {
        ...QuestionnaireDataStringifyShapeProperties
    },
    required: [],
};
export const QuestionnaireDataStringifyCompiled = (typeof process === 'object' && String(process) === '[object process]') ?
    fastJSON.build(QuestionnaireDataStringifyShape, {
        ajv: {
            // since we are already doing this with yup, lets skip
            validateSchema: false,
            ownProperties: true,
            validateFormats: false,
        },
        largeArrayMechanism: 'json-stringify',
        largeArraySize: 1000,
    } as Options)
    :
        () => { throw new Error('fast-json-stringify only works in nodejs'); };
export const QuestionnaireDataStringify: (data: QuestionnaireData) => string = QuestionnaireDataStringifyCompiled;
/* LIGHTLY CUSTOM END -> Generic */
export function QuestionnaireDataFromJSON(json: any): QuestionnaireData {
    return QuestionnaireDataFromJSONTyped(json, false);
}
export function QuestionnaireDataFromJSONTyped(json: any, ignoreDiscriminator: boolean): QuestionnaireData {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    /* causes circular depdendencies
*/
    return {
        'company': !exists(json, 'company') ? undefined : json['company'],
        'sector': !exists(json, 'sector') ? undefined : SectorFromJSON(json['sector']),
        'usage': !exists(json, 'usage') ? undefined : UsageFromJSON(json['usage']),
        'usageCustomReason': !exists(json, 'usageCustomReason') ? undefined : json['usageCustomReason'],
    };
}
export function QuestionnaireDataToJSON(value?: QuestionnaireData | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'company': value.company,
        'sector': SectorToJSON(value.sector),
        'usage': UsageToJSON(value.usage),
        'usageCustomReason': value.usageCustomReason,
    };
}
