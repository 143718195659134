import React, { FunctionComponent, useCallback, useEffect, useState } from 'react';

import HelpIcon from '@mui/icons-material/Help';
import { TooltipProps } from '@mui/material/Tooltip';
import { useTranslation } from 'react-i18next';
import { withStyles } from 'tss-react/mui';
import useResizeObserver from 'use-resize-observer';

import { Box, IconButtonProps, Typography, Theme, Tooltip } from '@mui/material';

import mixpanel from '../contexts/mixpanel';
import { getStorage, setStorage } from '../helpers/storage';
import { GlossaryEntries } from '../pages/glossary/Glossary';
import { glossaryRoute } from '../routes';

import { LightlyIconButton } from './Button';
import { GenericHint } from './GenericHint';

type IBigTextTooltip = Pick<TooltipProps, 'title' | 'placement'> & {
	id: string,
	icon?: React.ReactNode,
	size?: IconButtonProps['size'];
};


export const CustomTooltip = withStyles(Tooltip, (theme: Theme) => ({
	tooltip: {
		backgroundColor: theme.palette.grey[200],
		color: 'rgba(0, 0, 0, 0.77)',
		maxWidth: 300,
		fontSize: theme.typography.pxToRem(13),
		border: `1px solid ${theme.palette.grey[400]}`,
		'& ul': {
			paddingLeft: '1.5em',
		},
	},
	arrow: {
		color: theme.palette.grey[200],
		'&::before': {
			border: `1px solid ${theme.palette.grey[400]}`,
		},
	},
}));


export const ErrorTooltip = withStyles(Tooltip, (theme: Theme) => ({
	tooltip: {
		backgroundColor: theme.palette.grey[200],
		color: theme.palette.error.main,
		maxWidth: 300,
		fontSize: theme.typography.pxToRem(13),
		border: `1px solid ${theme.palette.grey[400]}`,
	},
	arrow: {
		color: theme.palette.grey[200],
		'&::before': {
			border: `1px solid ${theme.palette.grey[400]}`,
		},
	},
}));

export const BigTextTooltip: FunctionComponent<IBigTextTooltip> = ({ id, title, placement = 'right', icon, size = 'medium' }: IBigTextTooltip) => {
	const track = useCallback(() => {
		mixpanel.track('tooltip: open', { id });
	}, [id]);

	return (
		<CustomTooltip arrow onOpen={track} title={title} placement={placement}>
			<LightlyIconButton aria-label='help' size={size}>
				{
					icon ?
						icon
						:
						<HelpIcon fontSize={size !== 'small' ? 'medium' : 'small'} />
				}
			</LightlyIconButton>
		</CustomTooltip>
	);
};



interface IGlossaryTooltip {
	glossaryKey: GlossaryEntries;
	placement?: TooltipProps['placement'];
	size?: IconButtonProps['size'];
}

export const GlossaryTooltip: React.FC<IGlossaryTooltip> = ({ glossaryKey, placement = 'right', size = 'medium' }) => {
	const { t } = useTranslation();
	const link = `${glossaryRoute.path}#${glossaryKey}`;
	const title = <React.Fragment>
		<Typography variant='subtitle2'>{t(`glossary:${glossaryKey}`)}</Typography>
		<Box mb={1} mt={1} dangerouslySetInnerHTML={
			{ __html: t([`glossary:${glossaryKey}.short`, `glossary:${glossaryKey}.intro`], { defaultValue: '', interpolation: { escapeValue: false } }) }
		} />
		<a href={link} target='_blank' rel='noreferrer'>{'>'} learn more</a>
	</React.Fragment>;

	return <span onClick={() => window.open(link, '_blank')}>
		<BigTextTooltip id={glossaryKey.split('.')[1] || glossaryKey} title={title} placement={placement} size={size} />
	</span>;
};




const GLOSSARY_INFO_BOX_VISIBILITY_LOCALSTORAGE_KEY = 'GLOSSARY_INFO_BOX_VISIBLE'


interface IGlossaryInfo {
	glossaryKey: GlossaryEntries;
	withTitle?: boolean;
	withLink?: boolean;
	closeable?: boolean;
	closedSize?: ReturnType<typeof useResizeObserver>;
	onToggle?: (open: boolean) => void;
}

export const GlossaryInfo: React.FC<IGlossaryInfo> = (props) => {
	const { glossaryKey, withTitle = true, withLink = true, closeable = false, onToggle, closedSize } = props;
	const { t } = useTranslation();
	const link = `${glossaryRoute.path}#${glossaryKey}`;
	const message = <React.Fragment>
		{withTitle && <Typography variant='subtitle2'>{t(`glossary:${glossaryKey}`)}</Typography>}
		<Box mb={withLink ? 1 : 0} mt={withTitle ? 1 : 0} dangerouslySetInnerHTML={
			{ __html: t([`glossary:${glossaryKey}.short`, `glossary:${glossaryKey}.intro`], { defaultValue: '', interpolation: { escapeValue: false } }) }
		} />
		{withLink && <a href={link} target='_blank' rel='noreferrer' >more info</a>}
	</React.Fragment>;

	const isVisible = getStorage(`${GLOSSARY_INFO_BOX_VISIBILITY_LOCALSTORAGE_KEY}:${glossaryKey}`) as boolean ?? true;

	// handle open/close state
	const [open, setOpen] = useState(closeable ? !!isVisible : true);
	const onClose = useCallback(() => {
		onToggle && onToggle(false)
		setStorage(`${GLOSSARY_INFO_BOX_VISIBILITY_LOCALSTORAGE_KEY}:${glossaryKey}`, false);
		setOpen(false);
	}, [glossaryKey, onToggle]);
	const onOpen = useCallback(() => {
		onToggle && onToggle(true)
		setStorage(`${GLOSSARY_INFO_BOX_VISIBILITY_LOCALSTORAGE_KEY}:${glossaryKey}`, true);
		setOpen(true);
	}, [glossaryKey, onToggle]);
	// ensure to notify outside listener upon first status
	useEffect(() => {
		if (closeable && onToggle) {
			onToggle(open)
		}
	},[closeable, onToggle, open])
	return <GenericHint message={message} type='minimal' open={open} closedSize={closedSize} onClose={closeable ? onClose : undefined} onOpen={closeable ? onOpen : undefined} />;
};
