// SRC: https://github.com/OpenAPITools/openapi-generator/blob/master/modules/openapi-generator/src/main/resources/typescript-fetch/apis.mustache
// synched 02.02.2023
import * as yup from 'yup';
import typia from 'typia';
/* tslint:disable */
/* eslint-disable */
/**
 * Lightly API
 * Lightly.ai enables you to do self-supervised learning in an easy and intuitive way. The lightly.ai OpenAPI spec defines how one can interact with our REST API to unleash the full potential of lightly.ai
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: support@lightly.ai
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import * as runtime from '../runtime';
import type { ApiErrorResponse, CreateTeamMembershipRequest, ProfileBasicData, ServiceAccountBasicData, TeamData, UpdateTeamMembershipRequest, } from '../models';
import { ApiErrorResponseFromJSON, ApiErrorResponseToJSON, ApiErrorResponseValidator, CreateTeamMembershipRequestFromJSON, CreateTeamMembershipRequestToJSON, CreateTeamMembershipRequestValidator, ProfileBasicDataFromJSON, ProfileBasicDataToJSON, ProfileBasicDataValidator, ServiceAccountBasicDataFromJSON, ServiceAccountBasicDataToJSON, ServiceAccountBasicDataValidator, TeamDataFromJSON, TeamDataToJSON, TeamDataValidator, UpdateTeamMembershipRequestFromJSON, UpdateTeamMembershipRequestToJSON, UpdateTeamMembershipRequestValidator, } from '../models';
import * as models from '../models';
/* LIGHTLY CUSTOM START -> -> moved from bottom to top:  apiMustache */
/* LIGHTLY CUSTOM END -> moved from bottom to top: apiMustache */
export interface AddTeamMemberRequest {
    createTeamMembershipRequest: models.CreateTeamMembershipRequest;
    teamId: string;
}
/* LIGHTLY CUSTOM START -> apis */
export const AddTeamMemberRequestValidatorShape = {
    'createTeamMembershipRequest': models.CreateTeamMembershipRequestValidator.defined(),
    'teamId': yup.string().matches(/^[a-f0-9]{24}$/).defined(),
};
export const AddTeamMemberRequestValidator = yup.object().shape(AddTeamMemberRequestValidatorShape);
export interface DeleteTeamMemberByIdRequest {
    teamId: string;
    userId: string;
}
/* LIGHTLY CUSTOM START -> apis */
export const DeleteTeamMemberByIdRequestValidatorShape = {
    'teamId': yup.string().matches(/^[a-f0-9]{24}$/).defined(),
    'userId': yup.string().defined(),
};
export const DeleteTeamMemberByIdRequestValidator = yup.object().shape(DeleteTeamMemberByIdRequestValidatorShape);
export interface GetServiceAccountsByTeamIdRequest {
    teamId: string;
}
/* LIGHTLY CUSTOM START -> apis */
export const GetServiceAccountsByTeamIdRequestValidatorShape = {
    'teamId': yup.string().matches(/^[a-f0-9]{24}$/).defined(),
};
export const GetServiceAccountsByTeamIdRequestValidator = yup.object().shape(GetServiceAccountsByTeamIdRequestValidatorShape);
export interface GetTeamByIdRequest {
    teamId: string;
}
/* LIGHTLY CUSTOM START -> apis */
export const GetTeamByIdRequestValidatorShape = {
    'teamId': yup.string().matches(/^[a-f0-9]{24}$/).defined(),
};
export const GetTeamByIdRequestValidator = yup.object().shape(GetTeamByIdRequestValidatorShape);
export interface GetTeamMembersByIdRequest {
    teamId: string;
}
/* LIGHTLY CUSTOM START -> apis */
export const GetTeamMembersByIdRequestValidatorShape = {
    'teamId': yup.string().matches(/^[a-f0-9]{24}$/).defined(),
};
export const GetTeamMembersByIdRequestValidator = yup.object().shape(GetTeamMembersByIdRequestValidatorShape);
export interface ServiceAccountResetTokenRequest {
    teamId: string;
    serviceAccountId: string;
}
/* LIGHTLY CUSTOM START -> apis */
export const ServiceAccountResetTokenRequestValidatorShape = {
    'teamId': yup.string().matches(/^[a-f0-9]{24}$/).defined(),
    'serviceAccountId': yup.string().defined(),
};
export const ServiceAccountResetTokenRequestValidator = yup.object().shape(ServiceAccountResetTokenRequestValidatorShape);
export interface UpdateTeamMemberByIdRequest {
    updateTeamMembershipRequest: models.UpdateTeamMembershipRequest;
    teamId: string;
    userId: string;
}
/* LIGHTLY CUSTOM START -> apis */
export const UpdateTeamMemberByIdRequestValidatorShape = {
    'updateTeamMembershipRequest': models.UpdateTeamMembershipRequestValidator.defined(),
    'teamId': yup.string().matches(/^[a-f0-9]{24}$/).defined(),
    'userId': yup.string().defined(),
};
export const UpdateTeamMemberByIdRequestValidator = yup.object().shape(UpdateTeamMemberByIdRequestValidatorShape);
export interface AddTeamMemberRequestParams {
    teamId: string;
}
export function AddTeamMemberRequestParamsSanitizer(json: any): AddTeamMemberRequestParams {
    return {
        'teamId': json['teamId'],
    };
}
export const AddTeamMemberRequestParamsValidatorShape = {
    'teamId': yup.string().matches(/^[a-f0-9]{24}$/).defined(),
};
export const AddTeamMemberRequestParamsValidator = yup.object().shape(AddTeamMemberRequestParamsValidatorShape);
export interface DeleteTeamMemberByIdRequestParams {
    teamId: string;
    userId: string;
}
export function DeleteTeamMemberByIdRequestParamsSanitizer(json: any): DeleteTeamMemberByIdRequestParams {
    return {
        'teamId': json['teamId'],
        'userId': json['userId'],
    };
}
export const DeleteTeamMemberByIdRequestParamsValidatorShape = {
    'teamId': yup.string().matches(/^[a-f0-9]{24}$/).defined(),
    'userId': yup.string().defined(),
};
export const DeleteTeamMemberByIdRequestParamsValidator = yup.object().shape(DeleteTeamMemberByIdRequestParamsValidatorShape);
export interface GetServiceAccountsByTeamIdRequestParams {
    teamId: string;
}
export function GetServiceAccountsByTeamIdRequestParamsSanitizer(json: any): GetServiceAccountsByTeamIdRequestParams {
    return {
        'teamId': json['teamId'],
    };
}
export const GetServiceAccountsByTeamIdRequestParamsValidatorShape = {
    'teamId': yup.string().matches(/^[a-f0-9]{24}$/).defined(),
};
export const GetServiceAccountsByTeamIdRequestParamsValidator = yup.object().shape(GetServiceAccountsByTeamIdRequestParamsValidatorShape);
export interface GetTeamByIdRequestParams {
    teamId: string;
}
export function GetTeamByIdRequestParamsSanitizer(json: any): GetTeamByIdRequestParams {
    return {
        'teamId': json['teamId'],
    };
}
export const GetTeamByIdRequestParamsValidatorShape = {
    'teamId': yup.string().matches(/^[a-f0-9]{24}$/).defined(),
};
export const GetTeamByIdRequestParamsValidator = yup.object().shape(GetTeamByIdRequestParamsValidatorShape);
export interface GetTeamMembersByIdRequestParams {
    teamId: string;
}
export function GetTeamMembersByIdRequestParamsSanitizer(json: any): GetTeamMembersByIdRequestParams {
    return {
        'teamId': json['teamId'],
    };
}
export const GetTeamMembersByIdRequestParamsValidatorShape = {
    'teamId': yup.string().matches(/^[a-f0-9]{24}$/).defined(),
};
export const GetTeamMembersByIdRequestParamsValidator = yup.object().shape(GetTeamMembersByIdRequestParamsValidatorShape);
export interface ServiceAccountResetTokenRequestParams {
    teamId: string;
    serviceAccountId: string;
}
export function ServiceAccountResetTokenRequestParamsSanitizer(json: any): ServiceAccountResetTokenRequestParams {
    return {
        'teamId': json['teamId'],
        'serviceAccountId': json['serviceAccountId'],
    };
}
export const ServiceAccountResetTokenRequestParamsValidatorShape = {
    'teamId': yup.string().matches(/^[a-f0-9]{24}$/).defined(),
    'serviceAccountId': yup.string().defined(),
};
export const ServiceAccountResetTokenRequestParamsValidator = yup.object().shape(ServiceAccountResetTokenRequestParamsValidatorShape);
export interface UpdateTeamMemberByIdRequestParams {
    teamId: string;
    userId: string;
}
export function UpdateTeamMemberByIdRequestParamsSanitizer(json: any): UpdateTeamMemberByIdRequestParams {
    return {
        'teamId': json['teamId'],
        'userId': json['userId'],
    };
}
export const UpdateTeamMemberByIdRequestParamsValidatorShape = {
    'teamId': yup.string().matches(/^[a-f0-9]{24}$/).defined(),
    'userId': yup.string().defined(),
};
export const UpdateTeamMemberByIdRequestParamsValidator = yup.object().shape(UpdateTeamMemberByIdRequestParamsValidatorShape);
export interface AddTeamMemberRequestQuery {
}
export function AddTeamMemberRequestQuerySanitizer(json: any): AddTeamMemberRequestQuery {
    return {};
}
export const AddTeamMemberRequestQueryValidatorShape = {};
export const AddTeamMemberRequestQueryValidator = yup.object().shape(AddTeamMemberRequestQueryValidatorShape);
export interface DeleteTeamMemberByIdRequestQuery {
}
export function DeleteTeamMemberByIdRequestQuerySanitizer(json: any): DeleteTeamMemberByIdRequestQuery {
    return {};
}
export const DeleteTeamMemberByIdRequestQueryValidatorShape = {};
export const DeleteTeamMemberByIdRequestQueryValidator = yup.object().shape(DeleteTeamMemberByIdRequestQueryValidatorShape);
export interface GetServiceAccountsByTeamIdRequestQuery {
}
export function GetServiceAccountsByTeamIdRequestQuerySanitizer(json: any): GetServiceAccountsByTeamIdRequestQuery {
    return {};
}
export const GetServiceAccountsByTeamIdRequestQueryValidatorShape = {};
export const GetServiceAccountsByTeamIdRequestQueryValidator = yup.object().shape(GetServiceAccountsByTeamIdRequestQueryValidatorShape);
export interface GetTeamByIdRequestQuery {
}
export function GetTeamByIdRequestQuerySanitizer(json: any): GetTeamByIdRequestQuery {
    return {};
}
export const GetTeamByIdRequestQueryValidatorShape = {};
export const GetTeamByIdRequestQueryValidator = yup.object().shape(GetTeamByIdRequestQueryValidatorShape);
export interface GetTeamMembersByIdRequestQuery {
}
export function GetTeamMembersByIdRequestQuerySanitizer(json: any): GetTeamMembersByIdRequestQuery {
    return {};
}
export const GetTeamMembersByIdRequestQueryValidatorShape = {};
export const GetTeamMembersByIdRequestQueryValidator = yup.object().shape(GetTeamMembersByIdRequestQueryValidatorShape);
export interface ServiceAccountResetTokenRequestQuery {
}
export function ServiceAccountResetTokenRequestQuerySanitizer(json: any): ServiceAccountResetTokenRequestQuery {
    return {};
}
export const ServiceAccountResetTokenRequestQueryValidatorShape = {};
export const ServiceAccountResetTokenRequestQueryValidator = yup.object().shape(ServiceAccountResetTokenRequestQueryValidatorShape);
export interface UpdateTeamMemberByIdRequestQuery {
}
export function UpdateTeamMemberByIdRequestQuerySanitizer(json: any): UpdateTeamMemberByIdRequestQuery {
    return {};
}
export const UpdateTeamMemberByIdRequestQueryValidatorShape = {};
export const UpdateTeamMemberByIdRequestQueryValidator = yup.object().shape(UpdateTeamMemberByIdRequestQueryValidatorShape);
export type AddTeamMemberRequestBody = CreateTeamMembershipRequest;
export const AddTeamMemberRequestBodySanitizer = CreateTeamMembershipRequestFromJSON;
export const AddTeamMemberRequestBodyValidator = CreateTeamMembershipRequestValidator;
export const AddTeamMemberTypiaAssertEquals = (input: any) => {
    return ((input: any): AddTeamMemberRequestBody => {
        const __is = (input: any, _exceptionable: boolean = true): input is AddTeamMemberRequestBody => {
            const $is_email = (typia.createAssertEquals as any).is_email;
            const $io0 = (input: any, _exceptionable: boolean = true): boolean => "string" === typeof input.email && $is_email(input.email) && ("OWNER" === input.role || "ADMIN" === input.role || "MEMBER" === input.role || "SERVICEACCOUNT" === input.role) && (2 === Object.keys(input).length || Object.keys(input).every((key: any) => {
                if (["email", "role"].some((prop: any) => key === prop))
                    return true;
                const value = input[key];
                if (undefined === value)
                    return true;
                return false;
            }));
            return "object" === typeof input && null !== input && $io0(input, true);
        };
        if (false === __is(input))
            ((input: any, _path: string, _exceptionable: boolean = true): input is AddTeamMemberRequestBody => {
                const $guard = (typia.createAssertEquals as any).guard;
                const $is_email = (typia.createAssertEquals as any).is_email;
                const $join = (typia.createAssertEquals as any).join;
                const $ao0 = (input: any, _path: string, _exceptionable: boolean = true): boolean => ("string" === typeof input.email && ($is_email(input.email) || $guard(_exceptionable, {
                    path: _path + ".email",
                    expected: "string (@format email)",
                    value: input.email
                })) || $guard(_exceptionable, {
                    path: _path + ".email",
                    expected: "string",
                    value: input.email
                })) && ("OWNER" === input.role || "ADMIN" === input.role || "MEMBER" === input.role || "SERVICEACCOUNT" === input.role || $guard(_exceptionable, {
                    path: _path + ".role",
                    expected: "(\"ADMIN\" | \"MEMBER\" | \"OWNER\" | \"SERVICEACCOUNT\")",
                    value: input.role
                })) && (2 === Object.keys(input).length || (false === _exceptionable || Object.keys(input).every((key: any) => {
                    if (["email", "role"].some((prop: any) => key === prop))
                        return true;
                    const value = input[key];
                    if (undefined === value)
                        return true;
                    return $guard(_exceptionable, {
                        path: _path + $join(key),
                        expected: "undefined",
                        value: value
                    });
                })));
                return ("object" === typeof input && null !== input || $guard(true, {
                    path: _path + "",
                    expected: "CreateTeamMembershipRequest",
                    value: input
                })) && $ao0(input, _path + "", true) || $guard(true, {
                    path: _path + "",
                    expected: "CreateTeamMembershipRequest",
                    value: input
                });
            })(input, "$input", true);
        return input;
    })(input);
};
export const AddTeamMemberTypiaAssertStringify = (input: AddTeamMemberRequestBody): string => {
    return ((input: any): string => { const assert = (input: any): AddTeamMemberRequestBody => {
        const __is = (input: any): input is AddTeamMemberRequestBody => {
            const $is_email = (typia.createAssertStringify as any).is_email;
            const $io0 = (input: any): boolean => "string" === typeof input.email && $is_email(input.email) && ("OWNER" === input.role || "ADMIN" === input.role || "MEMBER" === input.role || "SERVICEACCOUNT" === input.role);
            return "object" === typeof input && null !== input && $io0(input);
        };
        if (false === __is(input))
            ((input: any, _path: string, _exceptionable: boolean = true): input is AddTeamMemberRequestBody => {
                const $guard = (typia.createAssertStringify as any).guard;
                const $is_email = (typia.createAssertStringify as any).is_email;
                const $ao0 = (input: any, _path: string, _exceptionable: boolean = true): boolean => ("string" === typeof input.email && ($is_email(input.email) || $guard(_exceptionable, {
                    path: _path + ".email",
                    expected: "string (@format email)",
                    value: input.email
                })) || $guard(_exceptionable, {
                    path: _path + ".email",
                    expected: "string",
                    value: input.email
                })) && ("OWNER" === input.role || "ADMIN" === input.role || "MEMBER" === input.role || "SERVICEACCOUNT" === input.role || $guard(_exceptionable, {
                    path: _path + ".role",
                    expected: "(\"ADMIN\" | \"MEMBER\" | \"OWNER\" | \"SERVICEACCOUNT\")",
                    value: input.role
                }));
                return ("object" === typeof input && null !== input || $guard(true, {
                    path: _path + "",
                    expected: "CreateTeamMembershipRequest",
                    value: input
                })) && $ao0(input, _path + "", true) || $guard(true, {
                    path: _path + "",
                    expected: "CreateTeamMembershipRequest",
                    value: input
                });
            })(input, "$input", true);
        return input;
    }; const stringify = (input: AddTeamMemberRequestBody): string => {
        const $string = (typia.createAssertStringify as any).string;
        const $throws = (typia.createAssertStringify as any).throws;
        const $is_email = (typia.createAssertStringify as any).is_email;
        const $so0 = (input: any): any => `{"email":${$string(input.email)},"role":${(() => {
            if ("string" === typeof input.role)
                return $string(input.role);
            if ("string" === typeof input.role)
                return "\"" + input.role + "\"";
            $throws({
                expected: "(\"ADMIN\" | \"MEMBER\" | \"OWNER\" | \"SERVICEACCOUNT\")",
                value: input.role
            });
        })()}}`;
        return $so0(input);
    }; return stringify(assert(input)); })(input);
};
export type UpdateTeamMemberByIdRequestBody = UpdateTeamMembershipRequest;
export const UpdateTeamMemberByIdRequestBodySanitizer = UpdateTeamMembershipRequestFromJSON;
export const UpdateTeamMemberByIdRequestBodyValidator = UpdateTeamMembershipRequestValidator;
export const UpdateTeamMemberByIdTypiaAssertEquals = (input: any) => {
    return ((input: any): UpdateTeamMemberByIdRequestBody => {
        const __is = (input: any, _exceptionable: boolean = true): input is UpdateTeamMemberByIdRequestBody => {
            const $io0 = (input: any, _exceptionable: boolean = true): boolean => ("OWNER" === input.role || "ADMIN" === input.role || "MEMBER" === input.role || "SERVICEACCOUNT" === input.role) && (1 === Object.keys(input).length || Object.keys(input).every((key: any) => {
                if (["role"].some((prop: any) => key === prop))
                    return true;
                const value = input[key];
                if (undefined === value)
                    return true;
                return false;
            }));
            return "object" === typeof input && null !== input && $io0(input, true);
        };
        if (false === __is(input))
            ((input: any, _path: string, _exceptionable: boolean = true): input is UpdateTeamMemberByIdRequestBody => {
                const $guard = (typia.createAssertEquals as any).guard;
                const $join = (typia.createAssertEquals as any).join;
                const $ao0 = (input: any, _path: string, _exceptionable: boolean = true): boolean => ("OWNER" === input.role || "ADMIN" === input.role || "MEMBER" === input.role || "SERVICEACCOUNT" === input.role || $guard(_exceptionable, {
                    path: _path + ".role",
                    expected: "(\"ADMIN\" | \"MEMBER\" | \"OWNER\" | \"SERVICEACCOUNT\")",
                    value: input.role
                })) && (1 === Object.keys(input).length || (false === _exceptionable || Object.keys(input).every((key: any) => {
                    if (["role"].some((prop: any) => key === prop))
                        return true;
                    const value = input[key];
                    if (undefined === value)
                        return true;
                    return $guard(_exceptionable, {
                        path: _path + $join(key),
                        expected: "undefined",
                        value: value
                    });
                })));
                return ("object" === typeof input && null !== input || $guard(true, {
                    path: _path + "",
                    expected: "UpdateTeamMembershipRequest",
                    value: input
                })) && $ao0(input, _path + "", true) || $guard(true, {
                    path: _path + "",
                    expected: "UpdateTeamMembershipRequest",
                    value: input
                });
            })(input, "$input", true);
        return input;
    })(input);
};
export const UpdateTeamMemberByIdTypiaAssertStringify = (input: UpdateTeamMemberByIdRequestBody): string => {
    return ((input: any): string => { const assert = (input: any): UpdateTeamMemberByIdRequestBody => {
        const __is = (input: any): input is UpdateTeamMemberByIdRequestBody => {
            const $io0 = (input: any): boolean => "OWNER" === input.role || "ADMIN" === input.role || "MEMBER" === input.role || "SERVICEACCOUNT" === input.role;
            return "object" === typeof input && null !== input && $io0(input);
        };
        if (false === __is(input))
            ((input: any, _path: string, _exceptionable: boolean = true): input is UpdateTeamMemberByIdRequestBody => {
                const $guard = (typia.createAssertStringify as any).guard;
                const $ao0 = (input: any, _path: string, _exceptionable: boolean = true): boolean => "OWNER" === input.role || "ADMIN" === input.role || "MEMBER" === input.role || "SERVICEACCOUNT" === input.role || $guard(_exceptionable, {
                    path: _path + ".role",
                    expected: "(\"ADMIN\" | \"MEMBER\" | \"OWNER\" | \"SERVICEACCOUNT\")",
                    value: input.role
                });
                return ("object" === typeof input && null !== input || $guard(true, {
                    path: _path + "",
                    expected: "UpdateTeamMembershipRequest",
                    value: input
                })) && $ao0(input, _path + "", true) || $guard(true, {
                    path: _path + "",
                    expected: "UpdateTeamMembershipRequest",
                    value: input
                });
            })(input, "$input", true);
        return input;
    }; const stringify = (input: UpdateTeamMemberByIdRequestBody): string => {
        const $string = (typia.createAssertStringify as any).string;
        const $throws = (typia.createAssertStringify as any).throws;
        const $so0 = (input: any): any => `{"role":${(() => {
            if ("string" === typeof input.role)
                return $string(input.role);
            if ("string" === typeof input.role)
                return "\"" + input.role + "\"";
            $throws({
                expected: "(\"ADMIN\" | \"MEMBER\" | \"OWNER\" | \"SERVICEACCOUNT\")",
                value: input.role
            });
        })()}}`;
        return $so0(input);
    }; return stringify(assert(input)); })(input);
};
/*
HANDLERS:



/v1/teams/{teamId}/members

export const addTeamMemberFN: RequestHandler<
    AddTeamMemberRequestParams,
    string | ApiErrorResponse,
    AddTeamMemberRequestBody,
    AddTeamMemberRequestQuery
> = async (req, res) => {
    if (!ensureUserOnReq(req)) {
        return
    }

    const {
        
    } = await validateAndSanitizeStrict(req.body, AddTeamMemberRequestBodySanitizer, AddTeamMemberRequestBodyValidator);
    const {
        
        teamId,
    } =  await validateAndSanitize(req.params, AddTeamMemberRequestParamsSanitizer, AddTeamMemberRequestParamsValidator);
    const {
        
        
    } = await validateAndSanitize(req.query, AddTeamMemberRequestQuerySanitizer, AddTeamMemberRequestQueryValidator);



    
    const schema = ....

    const { _id: id, ...rest } = schema;
    return fastJSONResponse(
        res,
        stringStringify,
        validateAndRespond({ id, ...rest }, stringFromJSON, stringValidator),
    );
}



/v1/teams/{teamId}/members/{userId}

export const deleteTeamMemberByIdFN: RequestHandler<
    DeleteTeamMemberByIdRequestParams,
     | ApiErrorResponse,
    DeleteTeamMemberByIdRequestBody,
    DeleteTeamMemberByIdRequestQuery
> = async (req, res) => {
    if (!ensureUserOnReq(req)) {
        return
    }

    const {
        
    } = await validateAndSanitizeStrict(req.body, DeleteTeamMemberByIdRequestBodySanitizer, DeleteTeamMemberByIdRequestBodyValidator);
    const {
        teamId,
        userId,
    } =  await validateAndSanitize(req.params, DeleteTeamMemberByIdRequestParamsSanitizer, DeleteTeamMemberByIdRequestParamsValidator);
    const {
        
        
    } = await validateAndSanitize(req.query, DeleteTeamMemberByIdRequestQuerySanitizer, DeleteTeamMemberByIdRequestQueryValidator);



    
    const schema = ....

    const { _id: id, ...rest } = schema;
    return fastJSONResponse(
        res,
        Stringify,
        validateAndRespond({ id, ...rest }, FromJSON, Validator),
    );
}



/v1/teams/{teamId}/serviceaccounts
const ServiceAccountBasicDataArrayStringify = fastJSONArrayify(ServiceAccountBasicDataStringifyShape);
export const getServiceAccountsByTeamIdFN: RequestHandler<
    GetServiceAccountsByTeamIdRequestParams,
    ServiceAccountBasicData[] | ApiErrorResponse,
    GetServiceAccountsByTeamIdRequestBody,
    GetServiceAccountsByTeamIdRequestQuery
> = async (req, res) => {
    if (!ensureUserOnReq(req)) {
        return
    }

    const {
        
    } = await validateAndSanitizeStrict(req.body, GetServiceAccountsByTeamIdRequestBodySanitizer, GetServiceAccountsByTeamIdRequestBodyValidator);
    const {
        teamId,
    } =  await validateAndSanitize(req.params, GetServiceAccountsByTeamIdRequestParamsSanitizer, GetServiceAccountsByTeamIdRequestParamsValidator);
    const {
        
    } = await validateAndSanitize(req.query, GetServiceAccountsByTeamIdRequestQuerySanitizer, GetServiceAccountsByTeamIdRequestQueryValidator);



    
    const schemas = ....

    return fastJSONResponse(
        res,
        ServiceAccountBasicDataArrayStringify,
        schemas.map((schema) => {
            const { _id: id, ...rest } = schema;
            return validateAndRespond({ id, ...rest }, ServiceAccountBasicDataFromJSON, ServiceAccountBasicDataValidator);
        }),
    );
}



/v1/teams/{teamId}

export const getTeamByIdFN: RequestHandler<
    GetTeamByIdRequestParams,
    TeamData | ApiErrorResponse,
    GetTeamByIdRequestBody,
    GetTeamByIdRequestQuery
> = async (req, res) => {
    if (!ensureUserOnReq(req)) {
        return
    }

    const {
        
    } = await validateAndSanitizeStrict(req.body, GetTeamByIdRequestBodySanitizer, GetTeamByIdRequestBodyValidator);
    const {
        teamId,
    } =  await validateAndSanitize(req.params, GetTeamByIdRequestParamsSanitizer, GetTeamByIdRequestParamsValidator);
    const {
        
    } = await validateAndSanitize(req.query, GetTeamByIdRequestQuerySanitizer, GetTeamByIdRequestQueryValidator);



    
    const schema = ....

    const { _id: id, ...rest } = schema;
    return fastJSONResponse(
        res,
        TeamDataStringify,
        validateAndRespond({ id, ...rest }, TeamDataFromJSON, TeamDataValidator),
    );
}



/v1/teams/{teamId}/members
const ProfileBasicDataArrayStringify = fastJSONArrayify(ProfileBasicDataStringifyShape);
export const getTeamMembersByIdFN: RequestHandler<
    GetTeamMembersByIdRequestParams,
    ProfileBasicData[] | ApiErrorResponse,
    GetTeamMembersByIdRequestBody,
    GetTeamMembersByIdRequestQuery
> = async (req, res) => {
    if (!ensureUserOnReq(req)) {
        return
    }

    const {
        
    } = await validateAndSanitizeStrict(req.body, GetTeamMembersByIdRequestBodySanitizer, GetTeamMembersByIdRequestBodyValidator);
    const {
        teamId,
    } =  await validateAndSanitize(req.params, GetTeamMembersByIdRequestParamsSanitizer, GetTeamMembersByIdRequestParamsValidator);
    const {
        
    } = await validateAndSanitize(req.query, GetTeamMembersByIdRequestQuerySanitizer, GetTeamMembersByIdRequestQueryValidator);



    
    const schemas = ....

    return fastJSONResponse(
        res,
        ProfileBasicDataArrayStringify,
        schemas.map((schema) => {
            const { _id: id, ...rest } = schema;
            return validateAndRespond({ id, ...rest }, ProfileBasicDataFromJSON, ProfileBasicDataValidator);
        }),
    );
}



/v1/teams/{teamId}/serviceaccounts/{serviceAccountId}/token

export const serviceAccountResetTokenFN: RequestHandler<
    ServiceAccountResetTokenRequestParams,
    string | ApiErrorResponse,
    ServiceAccountResetTokenRequestBody,
    ServiceAccountResetTokenRequestQuery
> = async (req, res) => {
    if (!ensureUserOnReq(req)) {
        return
    }

    const {
        
    } = await validateAndSanitizeStrict(req.body, ServiceAccountResetTokenRequestBodySanitizer, ServiceAccountResetTokenRequestBodyValidator);
    const {
        teamId,
        serviceAccountId,
    } =  await validateAndSanitize(req.params, ServiceAccountResetTokenRequestParamsSanitizer, ServiceAccountResetTokenRequestParamsValidator);
    const {
        
        
    } = await validateAndSanitize(req.query, ServiceAccountResetTokenRequestQuerySanitizer, ServiceAccountResetTokenRequestQueryValidator);



    
    const schema = ....

    const { _id: id, ...rest } = schema;
    return fastJSONResponse(
        res,
        stringStringify,
        validateAndRespond({ id, ...rest }, stringFromJSON, stringValidator),
    );
}



/v1/teams/{teamId}/members/{userId}

export const updateTeamMemberByIdFN: RequestHandler<
    UpdateTeamMemberByIdRequestParams,
     | ApiErrorResponse,
    UpdateTeamMemberByIdRequestBody,
    UpdateTeamMemberByIdRequestQuery
> = async (req, res) => {
    if (!ensureUserOnReq(req)) {
        return
    }

    const {
        
    } = await validateAndSanitizeStrict(req.body, UpdateTeamMemberByIdRequestBodySanitizer, UpdateTeamMemberByIdRequestBodyValidator);
    const {
        
        teamId,
        userId,
    } =  await validateAndSanitize(req.params, UpdateTeamMemberByIdRequestParamsSanitizer, UpdateTeamMemberByIdRequestParamsValidator);
    const {
        
        
        
    } = await validateAndSanitize(req.query, UpdateTeamMemberByIdRequestQuerySanitizer, UpdateTeamMemberByIdRequestQueryValidator);



    
    const schema = ....

    const { _id: id, ...rest } = schema;
    return fastJSONResponse(
        res,
        Stringify,
        validateAndRespond({ id, ...rest }, FromJSON, Validator),
    );
}


*/
/* LIGHTLY CUSTOM END -> api */
/**
 * TeamsApi - interface
 *
 * @export
 * @interface TeamsApiInterface
 */
export interface TeamsApiInterface {
    /**
     * Add a team member. One needs to be part of the team to do so.
     * @param {CreateTeamMembershipRequest} createTeamMembershipRequest
     * @param {string} teamId id of the team
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TeamsApiInterface
     */
    addTeamMemberRaw(requestParameters: AddTeamMemberRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<string>>;
    /**
     * Add a team member. One needs to be part of the team to do so.
     */
    addTeamMember(requestParameters: AddTeamMemberRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<string>;
    /**
     * Deletes a member from a team. One needs to be part of the team to do so.
     * @param {string} teamId id of the team
     * @param {string} userId id of the user
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TeamsApiInterface
     */
    deleteTeamMemberByIdRaw(requestParameters: DeleteTeamMemberByIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>>;
    /**
     * Deletes a member from a team. One needs to be part of the team to do so.
     */
    deleteTeamMemberById(requestParameters: DeleteTeamMemberByIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void>;
    /**
     * Get the service accounts of a team. One needs to be part of the team to do so.
     * @param {string} teamId id of the team
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TeamsApiInterface
     */
    getServiceAccountsByTeamIdRaw(requestParameters: GetServiceAccountsByTeamIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<ServiceAccountBasicData>>>;
    /**
     * Get the service accounts of a team. One needs to be part of the team to do so.
     */
    getServiceAccountsByTeamId(requestParameters: GetServiceAccountsByTeamIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<ServiceAccountBasicData>>;
    /**
     * Get basic team information by ID.
     * @param {string} teamId id of the team
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TeamsApiInterface
     */
    getTeamByIdRaw(requestParameters: GetTeamByIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<TeamData>>;
    /**
     * Get basic team information by ID.
     */
    getTeamById(requestParameters: GetTeamByIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<TeamData>;
    /**
     * Get the members of a team. One needs to be part of the team to do so.
     * @param {string} teamId id of the team
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TeamsApiInterface
     */
    getTeamMembersByIdRaw(requestParameters: GetTeamMembersByIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<ProfileBasicData>>>;
    /**
     * Get the members of a team. One needs to be part of the team to do so.
     */
    getTeamMembersById(requestParameters: GetTeamMembersByIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<ProfileBasicData>>;
    /**
     * Reset token of service account and generate a new one. This is not ideal because the user does not have a transition time between the old and new token (e.g for this pipelines). Also, the token should be a JWT or similar.
     * @param {string} teamId id of the team
     * @param {string} serviceAccountId id of the serviceAccount
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TeamsApiInterface
     */
    serviceAccountResetTokenRaw(requestParameters: ServiceAccountResetTokenRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<string>>;
    /**
     * Reset token of service account and generate a new one. This is not ideal because the user does not have a transition time between the old and new token (e.g for this pipelines). Also, the token should be a JWT or similar.
     */
    serviceAccountResetToken(requestParameters: ServiceAccountResetTokenRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<string>;
    /**
     * Update the team membership of a user. One needs to be part of the team to do so.
     * @param {UpdateTeamMembershipRequest} updateTeamMembershipRequest
     * @param {string} teamId id of the team
     * @param {string} userId id of the user
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TeamsApiInterface
     */
    updateTeamMemberByIdRaw(requestParameters: UpdateTeamMemberByIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>>;
    /**
     * Update the team membership of a user. One needs to be part of the team to do so.
     */
    updateTeamMemberById(requestParameters: UpdateTeamMemberByIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void>;
}
/**
 *
 */
export class TeamsApi extends runtime.BaseAPI implements TeamsApiInterface {
    /**
     * Add a team member. One needs to be part of the team to do so.
     */
    async addTeamMemberRaw(requestParameters: AddTeamMemberRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<string>> {
        if (requestParameters.createTeamMembershipRequest === null || requestParameters.createTeamMembershipRequest === undefined) {
            throw new runtime.RequiredError('createTeamMembershipRequest', 'Required parameter requestParameters.createTeamMembershipRequest was null or undefined when calling addTeamMember.');
        }
        if (requestParameters.teamId === null || requestParameters.teamId === undefined) {
            throw new runtime.RequiredError('teamId', 'Required parameter requestParameters.teamId was null or undefined when calling addTeamMember.');
        }
        const queryParameters: any = {};
        const headerParameters: runtime.HTTPHeaders = {};
        headerParameters['Content-Type'] = 'application/json';
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("auth0Bearer", []);
            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        if (this.configuration && this.configuration.apiKey) {
            queryParameters["token"] = this.configuration.apiKey("token"); // ApiKeyAuth authentication
        }
        const response = await this.request({
            path: `/v1/teams/{teamId}/members`.replace(`{${"teamId"}}`, encodeURIComponent(String(requestParameters.teamId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateTeamMembershipRequestToJSON(requestParameters.createTeamMembershipRequest),
        }, initOverrides);
        return new runtime.TextApiResponse(response) as any;
    }
    /**
     * Add a team member. One needs to be part of the team to do so.
     */
    async addTeamMember(requestParameters: AddTeamMemberRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<string> {
        const response = await this.addTeamMemberRaw(requestParameters, initOverrides);
        return await response.value();
    }
    /**
     * Deletes a member from a team. One needs to be part of the team to do so.
     */
    async deleteTeamMemberByIdRaw(requestParameters: DeleteTeamMemberByIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.teamId === null || requestParameters.teamId === undefined) {
            throw new runtime.RequiredError('teamId', 'Required parameter requestParameters.teamId was null or undefined when calling deleteTeamMemberById.');
        }
        if (requestParameters.userId === null || requestParameters.userId === undefined) {
            throw new runtime.RequiredError('userId', 'Required parameter requestParameters.userId was null or undefined when calling deleteTeamMemberById.');
        }
        const queryParameters: any = {};
        const headerParameters: runtime.HTTPHeaders = {};
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("auth0Bearer", []);
            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        if (this.configuration && this.configuration.apiKey) {
            queryParameters["token"] = this.configuration.apiKey("token"); // ApiKeyAuth authentication
        }
        const response = await this.request({
            path: `/v1/teams/{teamId}/members/{userId}`.replace(`{${"teamId"}}`, encodeURIComponent(String(requestParameters.teamId))).replace(`{${"userId"}}`, encodeURIComponent(String(requestParameters.userId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);
        return new runtime.VoidApiResponse(response);
    }
    /**
     * Deletes a member from a team. One needs to be part of the team to do so.
     */
    async deleteTeamMemberById(requestParameters: DeleteTeamMemberByIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.deleteTeamMemberByIdRaw(requestParameters, initOverrides);
    }
    /**
     * Get the service accounts of a team. One needs to be part of the team to do so.
     */
    async getServiceAccountsByTeamIdRaw(requestParameters: GetServiceAccountsByTeamIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<ServiceAccountBasicData>>> {
        if (requestParameters.teamId === null || requestParameters.teamId === undefined) {
            throw new runtime.RequiredError('teamId', 'Required parameter requestParameters.teamId was null or undefined when calling getServiceAccountsByTeamId.');
        }
        const queryParameters: any = {};
        const headerParameters: runtime.HTTPHeaders = {};
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("auth0Bearer", []);
            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        if (this.configuration && this.configuration.apiKey) {
            queryParameters["token"] = this.configuration.apiKey("token"); // ApiKeyAuth authentication
        }
        const response = await this.request({
            path: `/v1/teams/{teamId}/serviceaccounts`.replace(`{${"teamId"}}`, encodeURIComponent(String(requestParameters.teamId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ServiceAccountBasicDataFromJSON));
    }
    /**
     * Get the service accounts of a team. One needs to be part of the team to do so.
     */
    async getServiceAccountsByTeamId(requestParameters: GetServiceAccountsByTeamIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<ServiceAccountBasicData>> {
        const response = await this.getServiceAccountsByTeamIdRaw(requestParameters, initOverrides);
        return await response.value();
    }
    /**
     * Get basic team information by ID.
     */
    async getTeamByIdRaw(requestParameters: GetTeamByIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<TeamData>> {
        if (requestParameters.teamId === null || requestParameters.teamId === undefined) {
            throw new runtime.RequiredError('teamId', 'Required parameter requestParameters.teamId was null or undefined when calling getTeamById.');
        }
        const queryParameters: any = {};
        const headerParameters: runtime.HTTPHeaders = {};
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("auth0Bearer", []);
            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        if (this.configuration && this.configuration.apiKey) {
            queryParameters["token"] = this.configuration.apiKey("token"); // ApiKeyAuth authentication
        }
        const response = await this.request({
            path: `/v1/teams/{teamId}`.replace(`{${"teamId"}}`, encodeURIComponent(String(requestParameters.teamId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => TeamDataFromJSON(jsonValue));
    }
    /**
     * Get basic team information by ID.
     */
    async getTeamById(requestParameters: GetTeamByIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<TeamData> {
        const response = await this.getTeamByIdRaw(requestParameters, initOverrides);
        return await response.value();
    }
    /**
     * Get the members of a team. One needs to be part of the team to do so.
     */
    async getTeamMembersByIdRaw(requestParameters: GetTeamMembersByIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<ProfileBasicData>>> {
        if (requestParameters.teamId === null || requestParameters.teamId === undefined) {
            throw new runtime.RequiredError('teamId', 'Required parameter requestParameters.teamId was null or undefined when calling getTeamMembersById.');
        }
        const queryParameters: any = {};
        const headerParameters: runtime.HTTPHeaders = {};
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("auth0Bearer", []);
            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        if (this.configuration && this.configuration.apiKey) {
            queryParameters["token"] = this.configuration.apiKey("token"); // ApiKeyAuth authentication
        }
        const response = await this.request({
            path: `/v1/teams/{teamId}/members`.replace(`{${"teamId"}}`, encodeURIComponent(String(requestParameters.teamId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ProfileBasicDataFromJSON));
    }
    /**
     * Get the members of a team. One needs to be part of the team to do so.
     */
    async getTeamMembersById(requestParameters: GetTeamMembersByIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<ProfileBasicData>> {
        const response = await this.getTeamMembersByIdRaw(requestParameters, initOverrides);
        return await response.value();
    }
    /**
     * Reset token of service account and generate a new one. This is not ideal because the user does not have a transition time between the old and new token (e.g for this pipelines). Also, the token should be a JWT or similar.
     */
    async serviceAccountResetTokenRaw(requestParameters: ServiceAccountResetTokenRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<string>> {
        if (requestParameters.teamId === null || requestParameters.teamId === undefined) {
            throw new runtime.RequiredError('teamId', 'Required parameter requestParameters.teamId was null or undefined when calling serviceAccountResetToken.');
        }
        if (requestParameters.serviceAccountId === null || requestParameters.serviceAccountId === undefined) {
            throw new runtime.RequiredError('serviceAccountId', 'Required parameter requestParameters.serviceAccountId was null or undefined when calling serviceAccountResetToken.');
        }
        const queryParameters: any = {};
        const headerParameters: runtime.HTTPHeaders = {};
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("auth0Bearer", []);
            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        if (this.configuration && this.configuration.apiKey) {
            queryParameters["token"] = this.configuration.apiKey("token"); // ApiKeyAuth authentication
        }
        const response = await this.request({
            path: `/v1/teams/{teamId}/serviceaccounts/{serviceAccountId}/token`.replace(`{${"teamId"}}`, encodeURIComponent(String(requestParameters.teamId))).replace(`{${"serviceAccountId"}}`, encodeURIComponent(String(requestParameters.serviceAccountId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);
        return new runtime.TextApiResponse(response) as any;
    }
    /**
     * Reset token of service account and generate a new one. This is not ideal because the user does not have a transition time between the old and new token (e.g for this pipelines). Also, the token should be a JWT or similar.
     */
    async serviceAccountResetToken(requestParameters: ServiceAccountResetTokenRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<string> {
        const response = await this.serviceAccountResetTokenRaw(requestParameters, initOverrides);
        return await response.value();
    }
    /**
     * Update the team membership of a user. One needs to be part of the team to do so.
     */
    async updateTeamMemberByIdRaw(requestParameters: UpdateTeamMemberByIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.updateTeamMembershipRequest === null || requestParameters.updateTeamMembershipRequest === undefined) {
            throw new runtime.RequiredError('updateTeamMembershipRequest', 'Required parameter requestParameters.updateTeamMembershipRequest was null or undefined when calling updateTeamMemberById.');
        }
        if (requestParameters.teamId === null || requestParameters.teamId === undefined) {
            throw new runtime.RequiredError('teamId', 'Required parameter requestParameters.teamId was null or undefined when calling updateTeamMemberById.');
        }
        if (requestParameters.userId === null || requestParameters.userId === undefined) {
            throw new runtime.RequiredError('userId', 'Required parameter requestParameters.userId was null or undefined when calling updateTeamMemberById.');
        }
        const queryParameters: any = {};
        const headerParameters: runtime.HTTPHeaders = {};
        headerParameters['Content-Type'] = 'application/json';
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("auth0Bearer", []);
            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        if (this.configuration && this.configuration.apiKey) {
            queryParameters["token"] = this.configuration.apiKey("token"); // ApiKeyAuth authentication
        }
        const response = await this.request({
            path: `/v1/teams/{teamId}/members/{userId}`.replace(`{${"teamId"}}`, encodeURIComponent(String(requestParameters.teamId))).replace(`{${"userId"}}`, encodeURIComponent(String(requestParameters.userId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateTeamMembershipRequestToJSON(requestParameters.updateTeamMembershipRequest),
        }, initOverrides);
        return new runtime.VoidApiResponse(response);
    }
    /**
     * Update the team membership of a user. One needs to be part of the team to do so.
     */
    async updateTeamMemberById(requestParameters: UpdateTeamMemberByIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.updateTeamMemberByIdRaw(requestParameters, initOverrides);
    }
}
