/* tslint:disable */
/* eslint-disable */
/**
 * Lightly API
 * Lightly.ai enables you to do self-supervised learning in an easy and intuitive way. The lightly.ai OpenAPI spec defines how one can interact with our REST API to unleash the full potential of lightly.ai
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: support@lightly.ai
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
// SRC: https://github.com/OpenAPITools/openapi-generator/blob/master/modules/openapi-generator/src/main/resources/typescript-fetch/modelGeneric.mustache
// synched 02.02.2023
import * as yup from 'yup';
import typia from 'typia';
import * as fastJSON from 'fast-json-stringify';
import { Options, ArraySchema, ObjectSchema } from 'fast-json-stringify';
import { exists, mapValues } from '../runtime';
/*
causes circular dependendies
*/
// SRC: https://github.com/OpenAPITools/openapi-generator/blob/master/modules/openapi-generator/src/main/resources/typescript-fetch/modelGenericInterfaces.mustache
// synced: 17.07.2023
/**
 *
 * @export
 * @interface Consent
 *
 */
// create custom validators for the regexes
export interface Consent {
    [key: string]: any | any;
    /**
     *
     * #type boolean
     * #memberof Consent
        
        
         */
    acceptedNewsletter?: boolean;
    /**
     * unix timestamp in milliseconds
     * #type number
     * #memberof Consent
        
    * @minimum 0
            
         */
    acceptedTermsDate?: number;
}
/**
 * Check if a given object implements the Consent interface.
 */
export function instanceOfConsent(value: object): boolean {
    let isInstance = true;
    return isInstance;
}
/* LIGHTLY CUSTOM START -> modelGeneric */
export const ConsentTypiaAssertEquals = (input: any) => {
    return ((input: any): Consent => {
        const __is = (input: any, _exceptionable: boolean = true): input is Consent => {
            const $join = (typia.createAssertEquals as any).join;
            const $io0 = (input: any, _exceptionable: boolean = true): boolean => (undefined === input.acceptedNewsletter || "boolean" === typeof input.acceptedNewsletter) && (undefined === input.acceptedTermsDate || "number" === typeof input.acceptedTermsDate && 0 <= input.acceptedTermsDate) && Object.keys(input).every((key: any) => {
                if (["acceptedNewsletter", "acceptedTermsDate"].some((prop: any) => key === prop))
                    return true;
                const value = input[key];
                if (undefined === value)
                    return true;
                if (RegExp(/(.*)/).test(key))
                    return true;
                return false;
            });
            return "object" === typeof input && null !== input && false === Array.isArray(input) && $io0(input, true);
        };
        if (false === __is(input))
            ((input: any, _path: string, _exceptionable: boolean = true): input is Consent => {
                const $guard = (typia.createAssertEquals as any).guard;
                const $join = (typia.createAssertEquals as any).join;
                const $ao0 = (input: any, _path: string, _exceptionable: boolean = true): boolean => (undefined === input.acceptedNewsletter || "boolean" === typeof input.acceptedNewsletter || $guard(_exceptionable, {
                    path: _path + ".acceptedNewsletter",
                    expected: "(boolean | undefined)",
                    value: input.acceptedNewsletter
                })) && (undefined === input.acceptedTermsDate || "number" === typeof input.acceptedTermsDate && (0 <= input.acceptedTermsDate || $guard(_exceptionable, {
                    path: _path + ".acceptedTermsDate",
                    expected: "number (@minimum 0)",
                    value: input.acceptedTermsDate
                })) || $guard(_exceptionable, {
                    path: _path + ".acceptedTermsDate",
                    expected: "(number | undefined)",
                    value: input.acceptedTermsDate
                })) && (false === _exceptionable || Object.keys(input).every((key: any) => {
                    if (["acceptedNewsletter", "acceptedTermsDate"].some((prop: any) => key === prop))
                        return true;
                    const value = input[key];
                    if (undefined === value)
                        return true;
                    if (RegExp(/(.*)/).test(key))
                        return true;
                    return $guard(_exceptionable, {
                        path: _path + $join(key),
                        expected: "undefined",
                        value: value
                    });
                }));
                return ("object" === typeof input && null !== input && false === Array.isArray(input) || $guard(true, {
                    path: _path + "",
                    expected: "Consent",
                    value: input
                })) && $ao0(input, _path + "", true) || $guard(true, {
                    path: _path + "",
                    expected: "Consent",
                    value: input
                });
            })(input, "$input", true);
        return input;
    })(input);
};
export const ConsentTypiaAssertStringify = (input: Consent): string => {
    return ((input: any): string => { const assert = (input: any): Consent => {
        const __is = (input: any): input is Consent => {
            const $join = (typia.createAssertStringify as any).join;
            const $io0 = (input: any): boolean => (undefined === input.acceptedNewsletter || "boolean" === typeof input.acceptedNewsletter) && (undefined === input.acceptedTermsDate || "number" === typeof input.acceptedTermsDate && !Number.isNaN(input.acceptedTermsDate) && 0 <= input.acceptedTermsDate) && Object.keys(input).every((key: any) => {
                if (["acceptedNewsletter", "acceptedTermsDate"].some((prop: any) => key === prop))
                    return true;
                const value = input[key];
                if (undefined === value)
                    return true;
                if (RegExp(/(.*)/).test(key))
                    return true;
                return true;
            });
            return "object" === typeof input && null !== input && false === Array.isArray(input) && $io0(input);
        };
        if (false === __is(input))
            ((input: any, _path: string, _exceptionable: boolean = true): input is Consent => {
                const $guard = (typia.createAssertStringify as any).guard;
                const $join = (typia.createAssertStringify as any).join;
                const $ao0 = (input: any, _path: string, _exceptionable: boolean = true): boolean => (undefined === input.acceptedNewsletter || "boolean" === typeof input.acceptedNewsletter || $guard(_exceptionable, {
                    path: _path + ".acceptedNewsletter",
                    expected: "(boolean | undefined)",
                    value: input.acceptedNewsletter
                })) && (undefined === input.acceptedTermsDate || "number" === typeof input.acceptedTermsDate && !Number.isNaN(input.acceptedTermsDate) && (0 <= input.acceptedTermsDate || $guard(_exceptionable, {
                    path: _path + ".acceptedTermsDate",
                    expected: "number (@minimum 0)",
                    value: input.acceptedTermsDate
                })) || $guard(_exceptionable, {
                    path: _path + ".acceptedTermsDate",
                    expected: "(number | undefined)",
                    value: input.acceptedTermsDate
                })) && (false === _exceptionable || Object.keys(input).every((key: any) => {
                    if (["acceptedNewsletter", "acceptedTermsDate"].some((prop: any) => key === prop))
                        return true;
                    const value = input[key];
                    if (undefined === value)
                        return true;
                    if (RegExp(/(.*)/).test(key))
                        return true;
                    return true;
                }));
                return ("object" === typeof input && null !== input && false === Array.isArray(input) || $guard(true, {
                    path: _path + "",
                    expected: "Consent",
                    value: input
                })) && $ao0(input, _path + "", true) || $guard(true, {
                    path: _path + "",
                    expected: "Consent",
                    value: input
                });
            })(input, "$input", true);
        return input;
    }; const stringify = (input: Consent): string => {
        const $join = (typia.createAssertStringify as any).join;
        const $tail = (typia.createAssertStringify as any).tail;
        const $so0 = (input: any): any => `{${$tail(`${undefined === input.acceptedNewsletter ? "" : `"acceptedNewsletter":${undefined !== input.acceptedNewsletter ? input.acceptedNewsletter : undefined},`}${undefined === input.acceptedTermsDate ? "" : `"acceptedTermsDate":${undefined !== input.acceptedTermsDate ? input.acceptedTermsDate : undefined},`}${Object.entries(input).map(([key, value]: [string, any]) => { if (undefined === value)
            return ""; if (["acceptedNewsletter", "acceptedTermsDate"].some((regular: any) => regular === key))
            return ""; return `${JSON.stringify(key)}:${undefined !== value ? JSON.stringify(value) : undefined}`; }).filter((str: any) => "" !== str).join(",")}`)}}`;
        return $so0(input);
    }; return stringify(assert(input)); })(input);
};
export const ConsentValidatorShape = {
    'acceptedNewsletter': yup.boolean(),
    'acceptedTermsDate': yup.number().min(0),
};
export const ConsentValidator = yup.mixed().transform((val: any) => { return val instanceof Object && !Array.isArray(val) ? val : {}; });
export const ConsentStringifyShapeProperties: ObjectSchema['properties'] = {
    'acceptedNewsletter': {
        'type': 'boolean',
    },
    'acceptedTermsDate': {
        'type': 'number',
        'minimum': 0,
    } as unknown as fastJSON.NumberSchema,
};
export const ConsentStringifyShape: ObjectSchema = {
    title: 'Consent Stringifier',
    type: 'object',
    properties: {
        ...ConsentStringifyShapeProperties
    },
    required: [],
    additionalProperties: true
};
export const ConsentStringifyCompiled = (typeof process === 'object' && String(process) === '[object process]') ?
    fastJSON.build(ConsentStringifyShape, {
        ajv: {
            // since we are already doing this with yup, lets skip
            validateSchema: false,
            ownProperties: true,
            validateFormats: false,
        },
        largeArrayMechanism: 'json-stringify',
        largeArraySize: 1000,
    } as Options)
    :
        () => { throw new Error('fast-json-stringify only works in nodejs'); };
export const ConsentStringify: (data: Consent) => string = ConsentStringifyCompiled;
/* LIGHTLY CUSTOM END -> Generic */
export function ConsentFromJSON(json: any): Consent {
    return ConsentFromJSONTyped(json, false);
}
export function ConsentFromJSONTyped(json: any, ignoreDiscriminator: boolean): Consent {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    /* causes circular depdendencies
*/
    return {
        ...json,
        'acceptedNewsletter': !exists(json, 'acceptedNewsletter') ? undefined : json['acceptedNewsletter'],
        'acceptedTermsDate': !exists(json, 'acceptedTermsDate') ? undefined : json['acceptedTermsDate'],
    };
}
export function ConsentToJSON(value?: Consent | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        ...value,
        'acceptedNewsletter': value.acceptedNewsletter,
        'acceptedTermsDate': value.acceptedTermsDate,
    };
}
