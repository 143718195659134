import React from 'react';


import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { TFunction } from 'i18next';
import { isString } from 'lodash';
import * as Yup from 'yup';

import { ErrorTooltip } from '../common/BigTextTooltip';
import { LightlyIconButton } from '../common/Button';

type Translator = (errorObj: Yup.ValidationError) => string | React.ReactNode; // from mui-rff but somehow they fuckedup their exports and thus, its typed here again


interface IFormError {
	key: string;
	field: string;
	props?: Record<string, unknown>;
	label?: string;
}


export const makeTranslator = (t: TFunction, i18nLabelPrefix = '', i18nReplaceArray = false): Translator => {
	const fn: Translator = (msgs) => {
		const { message: msg } = msgs;
		if (isString(msg)) {
			return msg;
		}
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		const { key, ...fields } = msg as Record<string, keyof IFormError>;

		let field: string = fields.field;
		if (i18nReplaceArray) {
			field = field.replace(/\[\d+\]/,'[x]');
		}

		return t(
			[key, 'error_form:unspecific'],
			{
				...fields,
				i18nLabel: `${i18nLabelPrefix}:field_${field}`,
			},
		);
	};
	return fn;
}




export const TranslationErrorTooltip: React.FC<{title: string | React.ReactElement; showByDefault?: boolean}> = ({ title, showByDefault = true }) => {
	const [open, setOpen] =  React.useState(showByDefault)

	return <ErrorTooltip
		arrow
		title={title}
		open={open}
		onOpen={() => setOpen(true)}
		onClose={() => setOpen(false)}
	>
		<LightlyIconButton aria-label='error' size='small'>
			<ErrorOutlineIcon fontSize='small' color='error' />
		</LightlyIconButton>
	</ErrorTooltip>
}



export const makeTranslatorIcon = (t: TFunction, i18nLabelPrefix = ''): Translator => {
	const fn: Translator = (msgs) => {
		const { message: msg } = msgs;

		if (isString(msg)) {
			return <TranslationErrorTooltip key={msg} title={msg} />
		}
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		const { key, ...fields } = msg as Record<string, keyof IFormError>;

		return <TranslationErrorTooltip
			key={key}
			title={
				<>
					{
						t(
							[key, 'error_form:unspecific'],
							{
								...fields,
								i18nLabel: `${i18nLabelPrefix}:field_${fields.field}`,
							},
						)
					}
				</>
			}
		/>
	};
	return fn;
}


