/* tslint:disable */
/* eslint-disable */
/**
 * Lightly API
 * Lightly.ai enables you to do self-supervised learning in an easy and intuitive way. The lightly.ai OpenAPI spec defines how one can interact with our REST API to unleash the full potential of lightly.ai
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: support@lightly.ai
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
// SRC: https://github.com/OpenAPITools/openapi-generator/blob/master/modules/openapi-generator/src/main/resources/typescript-fetch/modelGeneric.mustache
// synched 02.02.2023
import * as yup from 'yup';
import typia from 'typia';
import * as fastJSON from 'fast-json-stringify';
import { Options, ArraySchema, ObjectSchema } from 'fast-json-stringify';
import { exists, mapValues } from '../runtime';
/*
causes circular dependendies
*/
// SRC: https://github.com/OpenAPITools/openapi-generator/blob/master/modules/openapi-generator/src/main/resources/typescript-fetch/modelGenericInterfaces.mustache
// synced: 17.07.2023
/**
 *
 * @export
 * @interface DockerWorkerConfigV3DockerCorruptnessCheck
 *
 */
// create custom validators for the regexes
export interface DockerWorkerConfigV3DockerCorruptnessCheck {
    /**
     *
     * #type number
     * #memberof DockerWorkerConfigV3DockerCorruptnessCheck
        
    * @minimum 0.0
        * @maximum 1.0
        
         */
    corruptionThreshold?: number;
}
/**
 * Check if a given object implements the DockerWorkerConfigV3DockerCorruptnessCheck interface.
 */
export function instanceOfDockerWorkerConfigV3DockerCorruptnessCheck(value: object): boolean {
    let isInstance = true;
    return isInstance;
}
/* LIGHTLY CUSTOM START -> modelGeneric */
export const DockerWorkerConfigV3DockerCorruptnessCheckTypiaAssertEquals = (input: any) => {
    return ((input: any): DockerWorkerConfigV3DockerCorruptnessCheck => {
        const __is = (input: any, _exceptionable: boolean = true): input is DockerWorkerConfigV3DockerCorruptnessCheck => {
            const $io0 = (input: any, _exceptionable: boolean = true): boolean => (undefined === input.corruptionThreshold || "number" === typeof input.corruptionThreshold && 0 <= input.corruptionThreshold && 1 >= input.corruptionThreshold) && (0 === Object.keys(input).length || Object.keys(input).every((key: any) => {
                if (["corruptionThreshold"].some((prop: any) => key === prop))
                    return true;
                const value = input[key];
                if (undefined === value)
                    return true;
                return false;
            }));
            return "object" === typeof input && null !== input && false === Array.isArray(input) && $io0(input, true);
        };
        if (false === __is(input))
            ((input: any, _path: string, _exceptionable: boolean = true): input is DockerWorkerConfigV3DockerCorruptnessCheck => {
                const $guard = (typia.createAssertEquals as any).guard;
                const $join = (typia.createAssertEquals as any).join;
                const $ao0 = (input: any, _path: string, _exceptionable: boolean = true): boolean => (undefined === input.corruptionThreshold || "number" === typeof input.corruptionThreshold && (0 <= input.corruptionThreshold || $guard(_exceptionable, {
                    path: _path + ".corruptionThreshold",
                    expected: "number (@minimum 0)",
                    value: input.corruptionThreshold
                })) && (1 >= input.corruptionThreshold || $guard(_exceptionable, {
                    path: _path + ".corruptionThreshold",
                    expected: "number (@maximum 1)",
                    value: input.corruptionThreshold
                })) || $guard(_exceptionable, {
                    path: _path + ".corruptionThreshold",
                    expected: "(number | undefined)",
                    value: input.corruptionThreshold
                })) && (0 === Object.keys(input).length || (false === _exceptionable || Object.keys(input).every((key: any) => {
                    if (["corruptionThreshold"].some((prop: any) => key === prop))
                        return true;
                    const value = input[key];
                    if (undefined === value)
                        return true;
                    return $guard(_exceptionable, {
                        path: _path + $join(key),
                        expected: "undefined",
                        value: value
                    });
                })));
                return ("object" === typeof input && null !== input && false === Array.isArray(input) || $guard(true, {
                    path: _path + "",
                    expected: "DockerWorkerConfigV3DockerCorruptnessCheck",
                    value: input
                })) && $ao0(input, _path + "", true) || $guard(true, {
                    path: _path + "",
                    expected: "DockerWorkerConfigV3DockerCorruptnessCheck",
                    value: input
                });
            })(input, "$input", true);
        return input;
    })(input);
};
export const DockerWorkerConfigV3DockerCorruptnessCheckTypiaAssertStringify = (input: DockerWorkerConfigV3DockerCorruptnessCheck): string => {
    return ((input: any): string => { const assert = (input: any): DockerWorkerConfigV3DockerCorruptnessCheck => {
        const __is = (input: any): input is DockerWorkerConfigV3DockerCorruptnessCheck => {
            const $io0 = (input: any): boolean => undefined === input.corruptionThreshold || "number" === typeof input.corruptionThreshold && 0 <= input.corruptionThreshold && 1 >= input.corruptionThreshold;
            return "object" === typeof input && null !== input && false === Array.isArray(input) && $io0(input);
        };
        if (false === __is(input))
            ((input: any, _path: string, _exceptionable: boolean = true): input is DockerWorkerConfigV3DockerCorruptnessCheck => {
                const $guard = (typia.createAssertStringify as any).guard;
                const $ao0 = (input: any, _path: string, _exceptionable: boolean = true): boolean => undefined === input.corruptionThreshold || "number" === typeof input.corruptionThreshold && (0 <= input.corruptionThreshold || $guard(_exceptionable, {
                    path: _path + ".corruptionThreshold",
                    expected: "number (@minimum 0)",
                    value: input.corruptionThreshold
                })) && (1 >= input.corruptionThreshold || $guard(_exceptionable, {
                    path: _path + ".corruptionThreshold",
                    expected: "number (@maximum 1)",
                    value: input.corruptionThreshold
                })) || $guard(_exceptionable, {
                    path: _path + ".corruptionThreshold",
                    expected: "(number | undefined)",
                    value: input.corruptionThreshold
                });
                return ("object" === typeof input && null !== input && false === Array.isArray(input) || $guard(true, {
                    path: _path + "",
                    expected: "DockerWorkerConfigV3DockerCorruptnessCheck",
                    value: input
                })) && $ao0(input, _path + "", true) || $guard(true, {
                    path: _path + "",
                    expected: "DockerWorkerConfigV3DockerCorruptnessCheck",
                    value: input
                });
            })(input, "$input", true);
        return input;
    }; const stringify = (input: DockerWorkerConfigV3DockerCorruptnessCheck): string => {
        const $tail = (typia.createAssertStringify as any).tail;
        const $so0 = (input: any): any => `{${$tail(`${undefined === input.corruptionThreshold ? "" : `"corruptionThreshold":${undefined !== input.corruptionThreshold ? input.corruptionThreshold : undefined}`}`)}}`;
        return $so0(input);
    }; return stringify(assert(input)); })(input);
};
export const DockerWorkerConfigV3DockerCorruptnessCheckValidatorShape = {
    'corruptionThreshold': yup.number().min(0.0).max(1.0),
};
export const DockerWorkerConfigV3DockerCorruptnessCheckValidator = yup.object().shape(DockerWorkerConfigV3DockerCorruptnessCheckValidatorShape);
export const DockerWorkerConfigV3DockerCorruptnessCheckStringifyShapeProperties: ObjectSchema['properties'] = {
    'corruptionThreshold': {
        'type': 'number',
        'minimum': 0.0,
        'maximum': 1.0,
    } as unknown as fastJSON.NumberSchema,
};
export const DockerWorkerConfigV3DockerCorruptnessCheckStringifyShape: ObjectSchema = {
    title: 'DockerWorkerConfigV3DockerCorruptnessCheck Stringifier',
    type: 'object',
    properties: {
        ...DockerWorkerConfigV3DockerCorruptnessCheckStringifyShapeProperties
    },
    required: [],
};
export const DockerWorkerConfigV3DockerCorruptnessCheckStringifyCompiled = (typeof process === 'object' && String(process) === '[object process]') ?
    fastJSON.build(DockerWorkerConfigV3DockerCorruptnessCheckStringifyShape, {
        ajv: {
            // since we are already doing this with yup, lets skip
            validateSchema: false,
            ownProperties: true,
            validateFormats: false,
        },
        largeArrayMechanism: 'json-stringify',
        largeArraySize: 1000,
    } as Options)
    :
        () => { throw new Error('fast-json-stringify only works in nodejs'); };
export const DockerWorkerConfigV3DockerCorruptnessCheckStringify: (data: DockerWorkerConfigV3DockerCorruptnessCheck) => string = DockerWorkerConfigV3DockerCorruptnessCheckStringifyCompiled;
/* LIGHTLY CUSTOM END -> Generic */
export function DockerWorkerConfigV3DockerCorruptnessCheckFromJSON(json: any): DockerWorkerConfigV3DockerCorruptnessCheck {
    return DockerWorkerConfigV3DockerCorruptnessCheckFromJSONTyped(json, false);
}
export function DockerWorkerConfigV3DockerCorruptnessCheckFromJSONTyped(json: any, ignoreDiscriminator: boolean): DockerWorkerConfigV3DockerCorruptnessCheck {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    /* causes circular depdendencies
*/
    return {
        'corruptionThreshold': !exists(json, 'corruptionThreshold') ? undefined : json['corruptionThreshold'],
    };
}
export function DockerWorkerConfigV3DockerCorruptnessCheckToJSON(value?: DockerWorkerConfigV3DockerCorruptnessCheck | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'corruptionThreshold': value.corruptionThreshold,
    };
}
