// based on https://casl.js.org/v6/en/cookbook/roles-with-static-permissions
// and on https://casl.js.org/v6/en/advanced/typescript


import { createMongoAbility, ForcedSubject, CreateAbility, MongoAbility, AbilityBuilder } from '@casl/ability';

import { DatasetData, TeamRole, DatasourceConfig, DockerWorkerRegistryEntryData, DockerRunScheduledData, DockerRunData, DockerWorkerConfig } from '@lightly/api-spec';

import { PERMISSIONS } from './typePermission';


// eslint-disable-next-line @typescript-eslint/naming-convention
interface ObjectIdFrontendCompat {
	toHexString(): string;
}


const actionsTeam = ['manage', 'create', 'update', 'read', 'delete', 'none'] as const;
const subjectsTeam = ['DatasetData', 'DatasourceConfig', 'DockerWorkerRegistryEntryData', 'DockerRunData', 'DockerRunScheduledData', 'DockerWorkerConfig', 'all'] as const; // 'DatasetData', 'Team', 'DockerRunData',

// type bla = typeof PERMISSIONS.COMMENT
export type ActionsTeam = typeof actionsTeam[number];
export type SubjectsTeam = typeof subjectsTeam[number];

export const Permission2Action: Record<PERMISSIONS, ActionsTeam> = {
	[PERMISSIONS.MANAGE]: 'manage',
	[PERMISSIONS.CREATE]: 'create',
	[PERMISSIONS.UPDATE]: 'update',
	[PERMISSIONS.DELETE]: 'delete',
	[PERMISSIONS.READ]: 'read',
	[PERMISSIONS.NONE]: 'none',
	[PERMISSIONS.NOT_EXIST]: 'none',
} as const

type AppTeamAbilities = [
    ActionsTeam,
    SubjectsTeam | ForcedSubject<Exclude<SubjectsTeam, 'all'>> | DatasetData  | DatasourceConfig | DockerWorkerRegistryEntryData | DockerRunData | DockerRunScheduledData | DockerWorkerConfig
];

export type AppTeamAbility = MongoAbility<AppTeamAbilities>;
export const createAppTeamAbility = createMongoAbility as CreateAbility<AppTeamAbility>;




type UserTeamThingEntry = {
	role: TeamRole;
	id?: unknown; // typically set from frontend
	teamId?: unknown; // typically set from backend
}

type UserTeamThing = {
	_id?: string;
	id?: string;
	isServiceAccount?: boolean;
	teams?: UserTeamThingEntry[];
}

type UserTeamThingChecked = {
	id: string;
	isServiceAccount: boolean;
	team: {
		role: TeamRole;
		id: unknown;
	}
}

type DefinePermissions = (props: {userActive: UserTeamThingChecked, userOther: UserTeamThingChecked}, builder: AbilityBuilder<AppTeamAbility>) => void;



const enhanceWithPermissionOnOthersAssets = (userActive: UserTeamThingChecked, userOther: UserTeamThingChecked, can: AbilityBuilder<AppTeamAbility>['can']) => {
	const teamRoleAbility = defineTeamRoleAbility(userActive.team.role);

	// can see other peoples datasets
	if (teamRoleAbility.can('manage', 'TeamDatasets')) {
		can(['manage', 'delete', 'create', 'update', 'read'], 'DatasetData', { userId: userOther.id });
		can(['manage', 'delete', 'create', 'update', 'read'], 'DatasourceConfig', { userId: userOther.id });
		can(['manage', 'delete', 'create', 'update', 'read'], 'DockerRunData', { userId: userOther.id });
		can(['manage', 'delete', 'create', 'update', 'read'], 'DockerRunScheduledData', { userId: userOther.id });
		can(['manage', 'delete', 'create', 'update', 'read'], 'DockerWorkerConfig', { userId: userOther.id });
	}
	else if (teamRoleAbility.can('edit', 'TeamDatasets')) {
		can(['create', 'update', 'read'], 'DatasetData', { userId: userOther.id });
		can(['update', 'read'], 'DatasourceConfig', { userId: userOther.id });
		can(['read'], 'DockerRunData', { userId: userOther.id });
		can(['read'], 'DockerRunScheduledData', { userId: userOther.id });
		can(['read'], 'DockerWorkerConfig', { userId: userOther.id });
	}
	else if (teamRoleAbility.can('view', 'TeamDatasets')) {
		can(['read'], 'DatasetData', { userId: userOther.id });
		can(['read'], 'DatasourceConfig', { userId: userOther.id });
		can(['read'], 'DockerRunData', { userId: userOther.id });
		can(['read'], 'DockerRunScheduledData', { userId: userOther.id });
		can(['read'], 'DockerWorkerConfig', { userId: userOther.id });
	}

	// can manage others worker
	// if (teamRoleAbility.can('manage', 'TeamSAWorkers')) {
	// 	can(['create', 'update', 'read'], 'DockerWorkerRegistryEntryData', { userId: userOther.id });
	// }
	// else if (teamRoleAbility.can('view', 'TeamSAWorkers')) {
	// 	can(['read'], 'DockerWorkerRegistryEntryData', { userId: userOther.id });
	// }
}

export const roleHierarchy = [TeamRole.SERVICEACCOUNT, TeamRole.MEMBER, TeamRole.ADMIN, TeamRole.OWNER];
const teamRolePermissions: Record<TeamRole, DefinePermissions> = {
	OWNER: ({ userActive, userOther }, { can }) => {
		// active user can do with service account's assets
		if (userOther.isServiceAccount ||  userOther.team.role === TeamRole.SERVICEACCOUNT) {
			can(['manage', 'delete', 'create', 'update', 'read'], 'DatasetData', { userId: userOther.id });
			can(['manage', 'delete', 'create', 'update', 'read'], 'DatasourceConfig', { userId: userOther.id });
			can(['manage', 'delete', 'create', 'update', 'read'], 'DockerWorkerRegistryEntryData', { userId: userOther.id });
			can(['manage', 'delete', 'create', 'update', 'read'], 'DockerRunData', { userId: userOther.id });
			can(['manage', 'delete', 'create', 'update', 'read'], 'DockerRunScheduledData', { userId: userOther.id });
			can(['manage', 'delete', 'create', 'update', 'read'], 'DockerWorkerConfig', { userId: userOther.id });
		}
		// active user can do with other team members assets
		else {
			enhanceWithPermissionOnOthersAssets(userActive, userOther, can);
		}
	},
	ADMIN: ({ userActive, userOther }, { can }) => {
		// active user can do with service account
		if (userOther.isServiceAccount ||  userOther.team.role === TeamRole.SERVICEACCOUNT) {
			can(['manage', 'delete', 'create', 'update', 'read'], 'DatasetData', { userId: userOther.id });
			can(['manage', 'delete', 'create', 'update', 'read'], 'DatasourceConfig', { userId: userOther.id });
			can(['manage', 'delete', 'create', 'update', 'read'], 'DockerWorkerRegistryEntryData', { userId: userOther.id });
			can(['manage', 'delete', 'create', 'update', 'read'], 'DockerRunData', { userId: userOther.id });
			can(['manage', 'delete', 'create', 'update', 'read'], 'DockerRunScheduledData', { userId: userOther.id });
			can(['manage', 'delete', 'create', 'update', 'read'], 'DockerWorkerConfig', { userId: userOther.id });
		}
		// active user can do with other team members assets
		else {
			enhanceWithPermissionOnOthersAssets(userActive, userOther, can);
		}
	},
	MEMBER: ({ userActive, userOther }, { can }) => {
		if (userOther.isServiceAccount ||  userOther.team.role === TeamRole.SERVICEACCOUNT) {
			can(['create', 'update', 'read'], 'DatasetData', { userId: userOther.id });
			can(['update', 'read'], 'DatasourceConfig', { userId: userOther.id });
			can(['read'], 'DockerWorkerRegistryEntryData', { userId: userOther.id });
			can(['read'], 'DockerRunData', { userId: userOther.id });
			can(['read'], 'DockerRunScheduledData', { userId: userOther.id });
			can(['read'], 'DockerWorkerConfig', { userId: userOther.id });
		}
		// active user can do with other team members assets
		else {
			enhanceWithPermissionOnOthersAssets(userActive, userOther, can);
		}
	},
	SERVICEACCOUNT:  ({ userActive, userOther }, { can }) => {
		// service accounts can do everything in other service accounts
		if (userOther.isServiceAccount ||  userOther.team.role === TeamRole.SERVICEACCOUNT) {
			can(['manage', 'delete', 'create', 'update', 'read'], 'DatasetData', { userId: userOther.id });
			can(['manage', 'delete', 'create', 'update', 'read'], 'DatasourceConfig', { userId: userOther.id });
			can(['manage', 'delete', 'create', 'update', 'read'], 'DockerWorkerRegistryEntryData', { userId: userOther.id });
			can(['read'], 'DockerRunData', { userId: userOther.id });
			can(['manage', 'delete', 'create', 'update', 'read'], 'DockerRunScheduledData', { userId: userOther.id });
			can(['read'], 'DockerWorkerConfig', { userId: userOther.id });
		}
		// active user can do with other team members assets
		else {
			enhanceWithPermissionOnOthersAssets(userActive, userOther, can);
		}
	},
};


/*
* Creates a predicate to find the team within the userThing.teams array which can be typed differently if it comes from frontend/backend or middleware
* Either teamId or id will be set. However, it can be a string or an ObjectId and we need to handle this while safeguaring against undefined
* As the frontend does not know about mongodb/ObjectId, we need to use a pseudo type for the backend
*/
export const findTeamPredicate = (teamId: string) => {
	return (team: UserTeamThingEntry) => {
		return (
			// check teamId (commonly from backend)
			team.teamId !== undefined && (
				typeof team.teamId !== 'string' ?
					( team.teamId as ObjectIdFrontendCompat).toHexString() === teamId
					:
					team.teamId === teamId
			)
		)
			||
		(
			// check id (commonly from frontend)
			team.id !== undefined && (
				typeof team.id !== 'string' ?
					( team.id as ObjectIdFrontendCompat).toHexString() === teamId
					:
					team.id === teamId
			)
		)
	}
}


export function defineTeamAbilityFor(userActive: UserTeamThing, userOther: UserTeamThing, teamId?: string): AppTeamAbility {
	const builder = new AbilityBuilder<AppTeamAbility>(createMongoAbility);

	if (!userActive.teams || !userActive.teams.length) {
		throw new Error('active user is not part of team')
	}
	if (!userOther.teams || !userOther.teams.length) {
		throw new Error('other user is not part of team')
	}

	// TODO: the common package should not have any mongodb dependencies because its also used by the frontend. No idea how this even compiles
	// find matching team
	const activeTeam = (!teamId) ?
		userActive.teams[0] :
		userActive.teams.find(findTeamPredicate(teamId));

	const otherTeam = (!teamId) ?
		userOther.teams[0] :
		userOther.teams.find(findTeamPredicate(teamId));

	if (!activeTeam || !otherTeam) {
		throw new Error('users are not in teams with the specified teamId')
	}
	// ensure that we have a structure to compare
	if (!activeTeam.id && !activeTeam.teamId) {
		throw new Error('active user is not part of team which that makes sense')
	}

	// ensure that both user are in the same team
	if (
		String(activeTeam.id) !== String(otherTeam.id)
		||
		String(activeTeam.teamId) !== String(otherTeam.teamId)
	) {
		throw new Error('users are not in same team')
	}


	// get role
	if (typeof teamRolePermissions[activeTeam.role] === 'function') {
		teamRolePermissions[activeTeam.role]({
			userActive: {
				id: userActive.id || userActive._id || '',
				isServiceAccount: userActive.isServiceAccount || false,
				team:{
					role: activeTeam.role,
					id: activeTeam.id || activeTeam.teamId || '',
				},
			},
			userOther:{
				id: userOther.id || userOther._id || '',
				isServiceAccount: userOther.isServiceAccount || false,
				team:{
					role: otherTeam.role,
					id: otherTeam.id || otherTeam.teamId || '',
				},
			},
		}, builder);
	}
	else {
		throw new Error(`Trying to use unknown role "${activeTeam.role}"`);
	}

	return builder.build();
}




/**
 * Which Features are allowed based on the role a user has
 */
const actionsTeamRole = ['manage', 'edit', 'view'] as const;
const subjectsTeamRole = ['TeamDatasets', 'TeamMembers', 'TeamServiceAccounts', 'TeamSAWorkers', 'Subscription'] as const;

export type ActionsTeamRole = typeof actionsTeamRole[number];
export type SubjectsTeamRole = typeof subjectsTeamRole[number];

type AppTeamRoleAbilities = [
    ActionsTeamRole,
    SubjectsTeamRole | ForcedSubject<Exclude<SubjectsTeamRole, 'all'>>
];

export type AppTeamRoleAbility = MongoAbility<AppTeamRoleAbilities>;

export function defineTeamRoleAbility(userActive: UserTeamThing | TeamRole, teamId?: string): AppTeamRoleAbility {
	const builder = new AbilityBuilder<AppTeamRoleAbility>(createMongoAbility);

	let role:TeamRole;

	if (typeof userActive === 'object') {
		const userActiveUTT: UserTeamThing = userActive;
		if (!userActiveUTT.teams || !userActiveUTT.teams.length) {
			throw new Error('active user is not part of team')
		}

		// find matching team
		const activeTeam = !teamId ?
			userActiveUTT.teams[0]
			:
			userActiveUTT.teams.find(findTeamPredicate(teamId));

		if (!activeTeam) {
			throw new Error('active user is not part of team')
		}
		role = activeTeam.role;
	}
	else {
		role = userActive;
	}

	switch (role) {
		case TeamRole.OWNER:
			builder.can(['manage', 'edit', 'view'], 'TeamDatasets');
			builder.can(['manage', 'edit', 'view'], 'TeamMembers');
			builder.can(['manage', 'edit', 'view'], 'TeamServiceAccounts');
			builder.can(['manage', 'edit', 'view'], 'TeamSAWorkers');
			builder.can(['manage', 'edit', 'view'], 'Subscription');
			break;
		case TeamRole.ADMIN:
			builder.can(['manage', 'edit', 'view'], 'TeamDatasets');
			builder.can(['manage', 'edit', 'view'], 'TeamMembers');
			builder.can(['manage', 'edit', 'view'], 'TeamServiceAccounts');
			builder.can(['manage', 'edit', 'view'], 'TeamSAWorkers');
			break;
		case TeamRole.MEMBER:
			builder.can(['edit', 'view'], 'TeamDatasets');
			builder.can(['view'], 'TeamServiceAccounts');
			builder.can(['view'], 'TeamMembers');
			builder.can(['view'], 'TeamSAWorkers');
			break;
		case TeamRole.SERVICEACCOUNT:
			builder.can(['view'], 'TeamServiceAccounts');
			break;
	}

	return builder.build();
}
