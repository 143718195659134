/* tslint:disable */
/* eslint-disable */
/**
 * Lightly API
 * Lightly.ai enables you to do self-supervised learning in an easy and intuitive way. The lightly.ai OpenAPI spec defines how one can interact with our REST API to unleash the full potential of lightly.ai
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: support@lightly.ai
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
// SRC: https://github.com/OpenAPITools/openapi-generator/blob/master/modules/openapi-generator/src/main/resources/typescript-fetch/modelGeneric.mustache
// synched 02.02.2023
import * as yup from 'yup';
import typia from 'typia';
import * as fastJSON from 'fast-json-stringify';
import { Options, ArraySchema, ObjectSchema } from 'fast-json-stringify';
import { exists, mapValues } from '../runtime';
import type { DockerRunScheduledPriority } from './DockerRunScheduledPriority';
import { DockerRunScheduledPriorityFromJSON, DockerRunScheduledPriorityFromJSONTyped, DockerRunScheduledPriorityToJSON, } from './DockerRunScheduledPriority';
import type { DockerRunScheduledState } from './DockerRunScheduledState';
import { DockerRunScheduledStateFromJSON, DockerRunScheduledStateFromJSONTyped, DockerRunScheduledStateToJSON, } from './DockerRunScheduledState';
import type { DockerWorkerLabels } from './DockerWorkerLabels';
import { DockerWorkerLabelsFromJSON, DockerWorkerLabelsFromJSONTyped, DockerWorkerLabelsToJSON, } from './DockerWorkerLabels';
/*
causes circular dependendies
*/
// SRC: https://github.com/OpenAPITools/openapi-generator/blob/master/modules/openapi-generator/src/main/resources/typescript-fetch/modelGenericInterfaces.mustache
// synced: 17.07.2023
/**
 *
 * @export
 * @interface DockerRunScheduledData
 *
 */
// create custom validators for the regexes
typia.customValidators.insert("pattern_id")("string")(() => {
    const regex = new RegExp(/^[a-f0-9]{24}$/);
    return (value: string) => !!regex.exec(value);
});
typia.customValidators.insert("pattern_datasetId")("string")(() => {
    const regex = new RegExp(/^[a-f0-9]{24}$/);
    return (value: string) => !!regex.exec(value);
});
typia.customValidators.insert("pattern_configId")("string")(() => {
    const regex = new RegExp(/^[a-f0-9]{24}$/);
    return (value: string) => !!regex.exec(value);
});
typia.customValidators.insert("pattern_owner")("string")(() => {
    const regex = new RegExp(/^[a-f0-9]{24}$/);
    return (value: string) => !!regex.exec(value);
});
export interface DockerRunScheduledData {
    /**
     * MongoDB ObjectId
     * #type string
     * #memberof DockerRunScheduledData
        
        
    * @pattern_id
         */
    id: string;
    /**
     * MongoDB ObjectId
     * #type string
     * #memberof DockerRunScheduledData
        
        
    * @pattern_datasetId
         */
    datasetId: string;
    /**
     *
     * #type string
     * #memberof DockerRunScheduledData
        
        
         */
    userId?: string;
    /**
     * MongoDB ObjectId
     * #type string
     * #memberof DockerRunScheduledData
        
        
    * @pattern_configId
         */
    configId: string;
    /**
     *
     * #type DockerRunScheduledPriority
     * #memberof DockerRunScheduledData
        
        
         */
    priority: DockerRunScheduledPriority;
    /**
     *
     * #type DockerWorkerLabels
     * #memberof DockerRunScheduledData
        
        
         */
    runsOn: DockerWorkerLabels;
    /**
     *
     * #type DockerRunScheduledState
     * #memberof DockerRunScheduledData
        
        
         */
    state: DockerRunScheduledState;
    /**
     * unix timestamp in milliseconds
     * #type number
     * #memberof DockerRunScheduledData
        
    * @minimum 0
            
         */
    createdAt: number;
    /**
     * unix timestamp in milliseconds
     * #type number
     * #memberof DockerRunScheduledData
        
    * @minimum 0
            
         */
    lastModifiedAt: number;
    /**
     * MongoDB ObjectId
     * #type string
     * #memberof DockerRunScheduledData
        
        
    * @pattern_owner
         */
    owner?: string;
}
/**
 * Check if a given object implements the DockerRunScheduledData interface.
 */
export function instanceOfDockerRunScheduledData(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "datasetId" in value;
    isInstance = isInstance && "configId" in value;
    isInstance = isInstance && "priority" in value;
    isInstance = isInstance && "runsOn" in value;
    isInstance = isInstance && "state" in value;
    isInstance = isInstance && "createdAt" in value;
    isInstance = isInstance && "lastModifiedAt" in value;
    return isInstance;
}
/* LIGHTLY CUSTOM START -> modelGeneric */
import { DockerRunScheduledPriorityValidator, DockerRunScheduledPriorityStringifyShape, DockerRunScheduledPriorityStringifyShapeProperties, } from './DockerRunScheduledPriority';
import { DockerRunScheduledStateValidator, DockerRunScheduledStateStringifyShape, DockerRunScheduledStateStringifyShapeProperties, } from './DockerRunScheduledState';
import { DockerWorkerLabelsValidator, DockerWorkerLabelsStringifyShape, DockerWorkerLabelsStringifyShapeProperties, } from './DockerWorkerLabels';
export const DockerRunScheduledDataTypiaAssertEquals = (input: any) => {
    return ((input: any): DockerRunScheduledData => {
        const __is = (input: any, _exceptionable: boolean = true): input is DockerRunScheduledData => {
            const $is_custom = (typia.createAssertEquals as any).is_custom;
            const $join = (typia.createAssertEquals as any).join;
            const $io0 = (input: any, _exceptionable: boolean = true): boolean => "string" === typeof input.id && $is_custom("pattern_id", "string", "", input.id) && ("string" === typeof input.datasetId && $is_custom("pattern_datasetId", "string", "", input.datasetId)) && (undefined === input.userId || "string" === typeof input.userId) && ("string" === typeof input.configId && $is_custom("pattern_configId", "string", "", input.configId)) && ("LOW" === input.priority || "MID" === input.priority || "HIGH" === input.priority || "CRITICAL" === input.priority) && ("object" === typeof input.runsOn && null !== input.runsOn && $io1(input.runsOn, true && _exceptionable)) && ("OPEN" === input.state || "LOCKED" === input.state || "DONE" === input.state || "CANCELED" === input.state) && ("number" === typeof input.createdAt && 0 <= input.createdAt) && ("number" === typeof input.lastModifiedAt && 0 <= input.lastModifiedAt) && (undefined === input.owner || "string" === typeof input.owner && $is_custom("pattern_owner", "string", "", input.owner)) && (8 === Object.keys(input).length || Object.keys(input).every((key: any) => {
                if (["id", "datasetId", "userId", "configId", "priority", "runsOn", "state", "createdAt", "lastModifiedAt", "owner"].some((prop: any) => key === prop))
                    return true;
                const value = input[key];
                if (undefined === value)
                    return true;
                return false;
            }));
            const $io1 = (input: any, _exceptionable: boolean = true): boolean => "number" === typeof input.length && Object.keys(input).every((key: any) => {
                if (["length"].some((prop: any) => key === prop))
                    return true;
                const value = input[key];
                if (undefined === value)
                    return true;
                if (RegExp(/^[+-]?\d+(?:\.\d+)?(?:[eE][+-]?\d+)?$/).test(key))
                    return "string" === typeof value;
                return false;
            });
            return "object" === typeof input && null !== input && $io0(input, true);
        };
        if (false === __is(input))
            ((input: any, _path: string, _exceptionable: boolean = true): input is DockerRunScheduledData => {
                const $guard = (typia.createAssertEquals as any).guard;
                const $is_custom = (typia.createAssertEquals as any).is_custom;
                const $join = (typia.createAssertEquals as any).join;
                const $ao0 = (input: any, _path: string, _exceptionable: boolean = true): boolean => ("string" === typeof input.id && ($is_custom("pattern_id", "string", "", input.id) || $guard(_exceptionable, {
                    path: _path + ".id",
                    expected: "string (@pattern_id)",
                    value: input.id
                })) || $guard(_exceptionable, {
                    path: _path + ".id",
                    expected: "string",
                    value: input.id
                })) && ("string" === typeof input.datasetId && ($is_custom("pattern_datasetId", "string", "", input.datasetId) || $guard(_exceptionable, {
                    path: _path + ".datasetId",
                    expected: "string (@pattern_datasetId)",
                    value: input.datasetId
                })) || $guard(_exceptionable, {
                    path: _path + ".datasetId",
                    expected: "string",
                    value: input.datasetId
                })) && (undefined === input.userId || "string" === typeof input.userId || $guard(_exceptionable, {
                    path: _path + ".userId",
                    expected: "(string | undefined)",
                    value: input.userId
                })) && ("string" === typeof input.configId && ($is_custom("pattern_configId", "string", "", input.configId) || $guard(_exceptionable, {
                    path: _path + ".configId",
                    expected: "string (@pattern_configId)",
                    value: input.configId
                })) || $guard(_exceptionable, {
                    path: _path + ".configId",
                    expected: "string",
                    value: input.configId
                })) && ("LOW" === input.priority || "MID" === input.priority || "HIGH" === input.priority || "CRITICAL" === input.priority || $guard(_exceptionable, {
                    path: _path + ".priority",
                    expected: "(\"CRITICAL\" | \"HIGH\" | \"LOW\" | \"MID\")",
                    value: input.priority
                })) && (("object" === typeof input.runsOn && null !== input.runsOn || $guard(_exceptionable, {
                    path: _path + ".runsOn",
                    expected: "DockerWorkerLabels",
                    value: input.runsOn
                })) && $ao1(input.runsOn, _path + ".runsOn", true && _exceptionable) || $guard(_exceptionable, {
                    path: _path + ".runsOn",
                    expected: "DockerWorkerLabels",
                    value: input.runsOn
                })) && ("OPEN" === input.state || "LOCKED" === input.state || "DONE" === input.state || "CANCELED" === input.state || $guard(_exceptionable, {
                    path: _path + ".state",
                    expected: "(\"CANCELED\" | \"DONE\" | \"LOCKED\" | \"OPEN\")",
                    value: input.state
                })) && ("number" === typeof input.createdAt && (0 <= input.createdAt || $guard(_exceptionable, {
                    path: _path + ".createdAt",
                    expected: "number (@minimum 0)",
                    value: input.createdAt
                })) || $guard(_exceptionable, {
                    path: _path + ".createdAt",
                    expected: "number",
                    value: input.createdAt
                })) && ("number" === typeof input.lastModifiedAt && (0 <= input.lastModifiedAt || $guard(_exceptionable, {
                    path: _path + ".lastModifiedAt",
                    expected: "number (@minimum 0)",
                    value: input.lastModifiedAt
                })) || $guard(_exceptionable, {
                    path: _path + ".lastModifiedAt",
                    expected: "number",
                    value: input.lastModifiedAt
                })) && (undefined === input.owner || "string" === typeof input.owner && ($is_custom("pattern_owner", "string", "", input.owner) || $guard(_exceptionable, {
                    path: _path + ".owner",
                    expected: "string (@pattern_owner)",
                    value: input.owner
                })) || $guard(_exceptionable, {
                    path: _path + ".owner",
                    expected: "(string | undefined)",
                    value: input.owner
                })) && (8 === Object.keys(input).length || (false === _exceptionable || Object.keys(input).every((key: any) => {
                    if (["id", "datasetId", "userId", "configId", "priority", "runsOn", "state", "createdAt", "lastModifiedAt", "owner"].some((prop: any) => key === prop))
                        return true;
                    const value = input[key];
                    if (undefined === value)
                        return true;
                    return $guard(_exceptionable, {
                        path: _path + $join(key),
                        expected: "undefined",
                        value: value
                    });
                })));
                const $ao1 = (input: any, _path: string, _exceptionable: boolean = true): boolean => ("number" === typeof input.length || $guard(_exceptionable, {
                    path: _path + ".length",
                    expected: "number",
                    value: input.length
                })) && (false === _exceptionable || Object.keys(input).every((key: any) => {
                    if (["length"].some((prop: any) => key === prop))
                        return true;
                    const value = input[key];
                    if (undefined === value)
                        return true;
                    if (RegExp(/^[+-]?\d+(?:\.\d+)?(?:[eE][+-]?\d+)?$/).test(key))
                        return "string" === typeof value || $guard(_exceptionable, {
                            path: _path + $join(key),
                            expected: "string",
                            value: value
                        });
                    return $guard(_exceptionable, {
                        path: _path + $join(key),
                        expected: "undefined",
                        value: value
                    });
                }));
                return ("object" === typeof input && null !== input || $guard(true, {
                    path: _path + "",
                    expected: "DockerRunScheduledData",
                    value: input
                })) && $ao0(input, _path + "", true) || $guard(true, {
                    path: _path + "",
                    expected: "DockerRunScheduledData",
                    value: input
                });
            })(input, "$input", true);
        return input;
    })(input);
};
export const DockerRunScheduledDataTypiaAssertStringify = (input: DockerRunScheduledData): string => {
    return ((input: any): string => { const assert = (input: any): DockerRunScheduledData => {
        const __is = (input: any): input is DockerRunScheduledData => {
            const $is_custom = (typia.createAssertStringify as any).is_custom;
            const $join = (typia.createAssertStringify as any).join;
            const $io0 = (input: any): boolean => "string" === typeof input.id && $is_custom("pattern_id", "string", "", input.id) && ("string" === typeof input.datasetId && $is_custom("pattern_datasetId", "string", "", input.datasetId)) && (undefined === input.userId || "string" === typeof input.userId) && ("string" === typeof input.configId && $is_custom("pattern_configId", "string", "", input.configId)) && ("LOW" === input.priority || "MID" === input.priority || "HIGH" === input.priority || "CRITICAL" === input.priority) && ("object" === typeof input.runsOn && null !== input.runsOn && $io1(input.runsOn)) && ("OPEN" === input.state || "LOCKED" === input.state || "DONE" === input.state || "CANCELED" === input.state) && ("number" === typeof input.createdAt && !Number.isNaN(input.createdAt) && 0 <= input.createdAt) && ("number" === typeof input.lastModifiedAt && !Number.isNaN(input.lastModifiedAt) && 0 <= input.lastModifiedAt) && (undefined === input.owner || "string" === typeof input.owner && $is_custom("pattern_owner", "string", "", input.owner));
            const $io1 = (input: any): boolean => "number" === typeof input.length && !Number.isNaN(input.length) && Object.keys(input).every((key: any) => {
                if (["length"].some((prop: any) => key === prop))
                    return true;
                const value = input[key];
                if (undefined === value)
                    return true;
                if (RegExp(/^[+-]?\d+(?:\.\d+)?(?:[eE][+-]?\d+)?$/).test(key))
                    return "string" === typeof value;
                return true;
            });
            return "object" === typeof input && null !== input && $io0(input);
        };
        if (false === __is(input))
            ((input: any, _path: string, _exceptionable: boolean = true): input is DockerRunScheduledData => {
                const $guard = (typia.createAssertStringify as any).guard;
                const $is_custom = (typia.createAssertStringify as any).is_custom;
                const $join = (typia.createAssertStringify as any).join;
                const $ao0 = (input: any, _path: string, _exceptionable: boolean = true): boolean => ("string" === typeof input.id && ($is_custom("pattern_id", "string", "", input.id) || $guard(_exceptionable, {
                    path: _path + ".id",
                    expected: "string (@pattern_id)",
                    value: input.id
                })) || $guard(_exceptionable, {
                    path: _path + ".id",
                    expected: "string",
                    value: input.id
                })) && ("string" === typeof input.datasetId && ($is_custom("pattern_datasetId", "string", "", input.datasetId) || $guard(_exceptionable, {
                    path: _path + ".datasetId",
                    expected: "string (@pattern_datasetId)",
                    value: input.datasetId
                })) || $guard(_exceptionable, {
                    path: _path + ".datasetId",
                    expected: "string",
                    value: input.datasetId
                })) && (undefined === input.userId || "string" === typeof input.userId || $guard(_exceptionable, {
                    path: _path + ".userId",
                    expected: "(string | undefined)",
                    value: input.userId
                })) && ("string" === typeof input.configId && ($is_custom("pattern_configId", "string", "", input.configId) || $guard(_exceptionable, {
                    path: _path + ".configId",
                    expected: "string (@pattern_configId)",
                    value: input.configId
                })) || $guard(_exceptionable, {
                    path: _path + ".configId",
                    expected: "string",
                    value: input.configId
                })) && ("LOW" === input.priority || "MID" === input.priority || "HIGH" === input.priority || "CRITICAL" === input.priority || $guard(_exceptionable, {
                    path: _path + ".priority",
                    expected: "(\"CRITICAL\" | \"HIGH\" | \"LOW\" | \"MID\")",
                    value: input.priority
                })) && (("object" === typeof input.runsOn && null !== input.runsOn || $guard(_exceptionable, {
                    path: _path + ".runsOn",
                    expected: "DockerWorkerLabels",
                    value: input.runsOn
                })) && $ao1(input.runsOn, _path + ".runsOn", true && _exceptionable) || $guard(_exceptionable, {
                    path: _path + ".runsOn",
                    expected: "DockerWorkerLabels",
                    value: input.runsOn
                })) && ("OPEN" === input.state || "LOCKED" === input.state || "DONE" === input.state || "CANCELED" === input.state || $guard(_exceptionable, {
                    path: _path + ".state",
                    expected: "(\"CANCELED\" | \"DONE\" | \"LOCKED\" | \"OPEN\")",
                    value: input.state
                })) && ("number" === typeof input.createdAt && !Number.isNaN(input.createdAt) && (0 <= input.createdAt || $guard(_exceptionable, {
                    path: _path + ".createdAt",
                    expected: "number (@minimum 0)",
                    value: input.createdAt
                })) || $guard(_exceptionable, {
                    path: _path + ".createdAt",
                    expected: "number",
                    value: input.createdAt
                })) && ("number" === typeof input.lastModifiedAt && !Number.isNaN(input.lastModifiedAt) && (0 <= input.lastModifiedAt || $guard(_exceptionable, {
                    path: _path + ".lastModifiedAt",
                    expected: "number (@minimum 0)",
                    value: input.lastModifiedAt
                })) || $guard(_exceptionable, {
                    path: _path + ".lastModifiedAt",
                    expected: "number",
                    value: input.lastModifiedAt
                })) && (undefined === input.owner || "string" === typeof input.owner && ($is_custom("pattern_owner", "string", "", input.owner) || $guard(_exceptionable, {
                    path: _path + ".owner",
                    expected: "string (@pattern_owner)",
                    value: input.owner
                })) || $guard(_exceptionable, {
                    path: _path + ".owner",
                    expected: "(string | undefined)",
                    value: input.owner
                }));
                const $ao1 = (input: any, _path: string, _exceptionable: boolean = true): boolean => ("number" === typeof input.length && !Number.isNaN(input.length) || $guard(_exceptionable, {
                    path: _path + ".length",
                    expected: "number",
                    value: input.length
                })) && (false === _exceptionable || Object.keys(input).every((key: any) => {
                    if (["length"].some((prop: any) => key === prop))
                        return true;
                    const value = input[key];
                    if (undefined === value)
                        return true;
                    if (RegExp(/^[+-]?\d+(?:\.\d+)?(?:[eE][+-]?\d+)?$/).test(key))
                        return "string" === typeof value || $guard(_exceptionable, {
                            path: _path + $join(key),
                            expected: "string",
                            value: value
                        });
                    return true;
                }));
                return ("object" === typeof input && null !== input || $guard(true, {
                    path: _path + "",
                    expected: "DockerRunScheduledData",
                    value: input
                })) && $ao0(input, _path + "", true) || $guard(true, {
                    path: _path + "",
                    expected: "DockerRunScheduledData",
                    value: input
                });
            })(input, "$input", true);
        return input;
    }; const stringify = (input: DockerRunScheduledData): string => {
        const $io1 = (input: any): boolean => "number" === typeof input.length && Object.keys(input).every((key: any) => {
            if (["length"].some((prop: any) => key === prop))
                return true;
            const value = input[key];
            if (undefined === value)
                return true;
            if (RegExp(/^[+-]?\d+(?:\.\d+)?(?:[eE][+-]?\d+)?$/).test(key))
                return "string" === typeof value;
            return true;
        });
        const $string = (typia.createAssertStringify as any).string;
        const $throws = (typia.createAssertStringify as any).throws;
        const $join = (typia.createAssertStringify as any).join;
        const $tail = (typia.createAssertStringify as any).tail;
        const $is_custom = (typia.createAssertStringify as any).is_custom;
        const $so0 = (input: any): any => `{${undefined === input.userId ? "" : `"userId":${undefined !== input.userId ? $string(input.userId) : undefined},`}${undefined === input.owner ? "" : `"owner":${undefined !== input.owner ? $string(input.owner) : undefined},`}"id":${$string(input.id)},"datasetId":${$string(input.datasetId)},"configId":${$string(input.configId)},"priority":${(() => {
            if ("string" === typeof input.priority)
                return $string(input.priority);
            if ("string" === typeof input.priority)
                return "\"" + input.priority + "\"";
            $throws({
                expected: "(\"CRITICAL\" | \"HIGH\" | \"LOW\" | \"MID\")",
                value: input.priority
            });
        })()},"runsOn":${$so1(input.runsOn)},"state":${(() => {
            if ("string" === typeof input.state)
                return $string(input.state);
            if ("string" === typeof input.state)
                return "\"" + input.state + "\"";
            $throws({
                expected: "(\"CANCELED\" | \"DONE\" | \"LOCKED\" | \"OPEN\")",
                value: input.state
            });
        })()},"createdAt":${input.createdAt},"lastModifiedAt":${input.lastModifiedAt}}`;
        const $so1 = (input: any): any => `{${$tail(`"length":${input.length},${Object.entries(input).map(([key, value]: [string, any]) => { if (undefined === value)
            return ""; if (["length"].some((regular: any) => regular === key))
            return ""; if (RegExp(/^[+-]?\d+(?:\.\d+)?(?:[eE][+-]?\d+)?$/).test(key))
            return `${JSON.stringify(key)}:${$string(value)}`; return ""; }).filter((str: any) => "" !== str).join(",")}`)}}`;
        return $so0(input);
    }; return stringify(assert(input)); })(input);
};
export const DockerRunScheduledDataValidatorShape = {
    'id': yup.string().matches(/^[a-f0-9]{24}$/).defined(),
    'datasetId': yup.string().matches(/^[a-f0-9]{24}$/).defined(),
    'userId': yup.string(),
    'configId': yup.string().matches(/^[a-f0-9]{24}$/).defined(),
    'priority': DockerRunScheduledPriorityValidator.defined(),
    'runsOn': DockerWorkerLabelsValidator.defined(),
    'state': DockerRunScheduledStateValidator.defined(),
    'createdAt': yup.number().min(0).defined(),
    'lastModifiedAt': yup.number().min(0).defined(),
    'owner': yup.string().matches(/^[a-f0-9]{24}$/),
};
export const DockerRunScheduledDataValidator = yup.object().shape(DockerRunScheduledDataValidatorShape);
export const DockerRunScheduledDataStringifyShapeProperties: ObjectSchema['properties'] = {
    'id': {
        'type': 'string',
        'pattern': "/^[a-f0-9]{24}$/".slice(1, -1),
    } as unknown as fastJSON.StringSchema,
    'datasetId': {
        'type': 'string',
        'pattern': "/^[a-f0-9]{24}$/".slice(1, -1),
    } as unknown as fastJSON.StringSchema,
    'userId': {
        'type': 'string',
    } as unknown as fastJSON.StringSchema,
    'configId': {
        'type': 'string',
        'pattern': "/^[a-f0-9]{24}$/".slice(1, -1),
    } as unknown as fastJSON.StringSchema,
    'priority': DockerRunScheduledPriorityStringifyShape,
    'runsOn': DockerWorkerLabelsStringifyShape,
    'state': DockerRunScheduledStateStringifyShape,
    'createdAt': {
        'type': 'number',
        'minimum': 0,
    } as unknown as fastJSON.NumberSchema,
    'lastModifiedAt': {
        'type': 'number',
        'minimum': 0,
    } as unknown as fastJSON.NumberSchema,
    'owner': {
        'type': 'string',
        'pattern': "/^[a-f0-9]{24}$/".slice(1, -1),
    } as unknown as fastJSON.StringSchema,
};
export const DockerRunScheduledDataStringifyShape: ObjectSchema = {
    title: 'DockerRunScheduledData Stringifier',
    type: 'object',
    properties: {
        ...DockerRunScheduledDataStringifyShapeProperties
    },
    required: [
        'id',
        'datasetId',
        'configId',
        'priority',
        'runsOn',
        'state',
        'createdAt',
        'lastModifiedAt',
    ],
};
export const DockerRunScheduledDataStringifyCompiled = (typeof process === 'object' && String(process) === '[object process]') ?
    fastJSON.build(DockerRunScheduledDataStringifyShape, {
        ajv: {
            // since we are already doing this with yup, lets skip
            validateSchema: false,
            ownProperties: true,
            validateFormats: false,
        },
        largeArrayMechanism: 'json-stringify',
        largeArraySize: 1000,
    } as Options)
    :
        () => { throw new Error('fast-json-stringify only works in nodejs'); };
export const DockerRunScheduledDataStringify: (data: DockerRunScheduledData) => string = DockerRunScheduledDataStringifyCompiled;
/* LIGHTLY CUSTOM END -> Generic */
export function DockerRunScheduledDataFromJSON(json: any): DockerRunScheduledData {
    return DockerRunScheduledDataFromJSONTyped(json, false);
}
export function DockerRunScheduledDataFromJSONTyped(json: any, ignoreDiscriminator: boolean): DockerRunScheduledData {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    /* causes circular depdendencies
*/
    return {
        'id': json['id'],
        'datasetId': json['datasetId'],
        'userId': !exists(json, 'userId') ? undefined : json['userId'],
        'configId': json['configId'],
        'priority': DockerRunScheduledPriorityFromJSON(json['priority']),
        'runsOn': DockerWorkerLabelsFromJSON(json['runsOn']),
        'state': DockerRunScheduledStateFromJSON(json['state']),
        'createdAt': json['createdAt'],
        'lastModifiedAt': json['lastModifiedAt'],
        'owner': !exists(json, 'owner') ? undefined : json['owner'],
    };
}
export function DockerRunScheduledDataToJSON(value?: DockerRunScheduledData | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'id': value.id,
        'datasetId': value.datasetId,
        'userId': value.userId,
        'configId': value.configId,
        'priority': DockerRunScheduledPriorityToJSON(value.priority),
        'runsOn': DockerWorkerLabelsToJSON(value.runsOn),
        'state': DockerRunScheduledStateToJSON(value.state),
        'createdAt': value.createdAt,
        'lastModifiedAt': value.lastModifiedAt,
        'owner': value.owner,
    };
}
