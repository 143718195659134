/* tslint:disable */
/* eslint-disable */
/**
 * Lightly API
 * Lightly.ai enables you to do self-supervised learning in an easy and intuitive way. The lightly.ai OpenAPI spec defines how one can interact with our REST API to unleash the full potential of lightly.ai
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: support@lightly.ai
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
// SRC: https://github.com/OpenAPITools/openapi-generator/blob/master/modules/openapi-generator/src/main/resources/typescript-fetch/modelOneOf.mustache
// synched 02.02.2023
import * as yup from 'yup';
import typia from 'typia';
import * as fastJSON from 'fast-json-stringify';
import { Options } from 'fast-json-stringify';
import { AutoTaskCrop, instanceOfAutoTaskCrop, AutoTaskCropFromJSON, AutoTaskCropFromJSONTyped, AutoTaskCropToJSON, } from './AutoTaskCrop';
import { AutoTaskTiling, instanceOfAutoTaskTiling, AutoTaskTilingFromJSON, AutoTaskTilingFromJSONTyped, AutoTaskTilingToJSON, } from './AutoTaskTiling';
/**
 * @type AutoTask
 *
 * @export
 */
export type AutoTask = {
    type: 'AutoTaskCrop';
} & AutoTaskCrop | {
    type: 'AutoTaskTiling';
} & AutoTaskTiling | {
    type: 'CROP';
} & AutoTaskCrop | {
    type: 'TILING';
} & AutoTaskTiling;
/* LIGHTLY CUSTOM START -> modelOneOf */
import { AutoTaskCropStringifyShape, AutoTaskCropStringifyShapeProperties, AutoTaskCropValidator } from './AutoTaskCrop';
import { AutoTaskTilingStringifyShape, AutoTaskTilingStringifyShapeProperties, AutoTaskTilingValidator } from './AutoTaskTiling';
export const AutoTaskTypiaAssertEquals = (input: any) => {
    return ((input: any): AutoTask => {
        const __is = (input: any, _exceptionable: boolean = true): input is AutoTask => {
            const $is_custom = (typia.createAssertEquals as any).is_custom;
            const $join = (typia.createAssertEquals as any).join;
            const $io0 = (input: any, _exceptionable: boolean = true): boolean => "AutoTaskCrop" === input.type && (Array.isArray(input.bboxs) && input.bboxs.every((elem: any, _index1: number) => "object" === typeof elem && null !== elem && $io1(elem, true && _exceptionable))) && ("string" === typeof input.name && $is_custom("pattern_name", "string", "", input.name)) && (3 === Object.keys(input).length || Object.keys(input).every((key: any) => {
                if (["type", "bboxs", "name"].some((prop: any) => key === prop))
                    return true;
                const value = input[key];
                if (undefined === value)
                    return true;
                return false;
            }));
            const $io1 = (input: any, _exceptionable: boolean = true): boolean => "number" === typeof input.length && Object.keys(input).every((key: any) => {
                if (["length"].some((prop: any) => key === prop))
                    return true;
                const value = input[key];
                if (undefined === value)
                    return true;
                if (RegExp(/^[+-]?\d+(?:\.\d+)?(?:[eE][+-]?\d+)?$/).test(key))
                    return "number" === typeof value;
                return false;
            });
            const $io2 = (input: any, _exceptionable: boolean = true): boolean => "AutoTaskTiling" === input.type && ("number" === typeof input.numRows && Math.floor(input.numRows) === input.numRows && (-2147483648 <= input.numRows && input.numRows <= 2147483647) && 1 <= input.numRows) && ("number" === typeof input.numCols && Math.floor(input.numCols) === input.numCols && (-2147483648 <= input.numCols && input.numCols <= 2147483647) && 1 <= input.numCols) && (undefined === input.overlap || "number" === typeof input.overlap && 0 <= input.overlap) && ("string" === typeof input.name && $is_custom("pattern_name", "string", "", input.name)) && (4 === Object.keys(input).length || Object.keys(input).every((key: any) => {
                if (["type", "numRows", "numCols", "overlap", "name"].some((prop: any) => key === prop))
                    return true;
                const value = input[key];
                if (undefined === value)
                    return true;
                return false;
            }));
            const $io3 = (input: any, _exceptionable: boolean = true): boolean => "CROP" === input.type && (Array.isArray(input.bboxs) && input.bboxs.every((elem: any, _index2: number) => "object" === typeof elem && null !== elem && $io1(elem, true && _exceptionable))) && ("string" === typeof input.name && $is_custom("pattern_name", "string", "", input.name)) && (3 === Object.keys(input).length || Object.keys(input).every((key: any) => {
                if (["type", "bboxs", "name"].some((prop: any) => key === prop))
                    return true;
                const value = input[key];
                if (undefined === value)
                    return true;
                return false;
            }));
            const $io4 = (input: any, _exceptionable: boolean = true): boolean => "TILING" === input.type && ("number" === typeof input.numRows && Math.floor(input.numRows) === input.numRows && (-2147483648 <= input.numRows && input.numRows <= 2147483647) && 1 <= input.numRows) && ("number" === typeof input.numCols && Math.floor(input.numCols) === input.numCols && (-2147483648 <= input.numCols && input.numCols <= 2147483647) && 1 <= input.numCols) && (undefined === input.overlap || "number" === typeof input.overlap && 0 <= input.overlap) && ("string" === typeof input.name && $is_custom("pattern_name", "string", "", input.name)) && (4 === Object.keys(input).length || Object.keys(input).every((key: any) => {
                if (["type", "numRows", "numCols", "overlap", "name"].some((prop: any) => key === prop))
                    return true;
                const value = input[key];
                if (undefined === value)
                    return true;
                return false;
            }));
            const $iu0 = (input: any, _exceptionable: boolean = true): any => (() => {
                if ("AutoTaskCrop" === input.type)
                    return $io0(input, true && _exceptionable);
                if ("AutoTaskTiling" === input.type)
                    return $io2(input, true && _exceptionable);
                if ("CROP" === input.type)
                    return $io3(input, true && _exceptionable);
                if ("TILING" === input.type)
                    return $io4(input, true && _exceptionable);
                return false;
            })();
            return "object" === typeof input && null !== input && $iu0(input, true);
        };
        if (false === __is(input))
            ((input: any, _path: string, _exceptionable: boolean = true): input is AutoTask => {
                const $guard = (typia.createAssertEquals as any).guard;
                const $is_custom = (typia.createAssertEquals as any).is_custom;
                const $join = (typia.createAssertEquals as any).join;
                const $ao0 = (input: any, _path: string, _exceptionable: boolean = true): boolean => ("AutoTaskCrop" === input.type || $guard(_exceptionable, {
                    path: _path + ".type",
                    expected: "\"AutoTaskCrop\"",
                    value: input.type
                })) && ((Array.isArray(input.bboxs) || $guard(_exceptionable, {
                    path: _path + ".bboxs",
                    expected: "Array<BoundingBoxRelative>",
                    value: input.bboxs
                })) && input.bboxs.every((elem: any, _index1: number) => ("object" === typeof elem && null !== elem || $guard(_exceptionable, {
                    path: _path + ".bboxs[" + _index1 + "]",
                    expected: "BoundingBoxRelative",
                    value: elem
                })) && $ao1(elem, _path + ".bboxs[" + _index1 + "]", true && _exceptionable) || $guard(_exceptionable, {
                    path: _path + ".bboxs[" + _index1 + "]",
                    expected: "BoundingBoxRelative",
                    value: elem
                })) || $guard(_exceptionable, {
                    path: _path + ".bboxs",
                    expected: "Array<BoundingBoxRelative>",
                    value: input.bboxs
                })) && ("string" === typeof input.name && ($is_custom("pattern_name", "string", "", input.name) || $guard(_exceptionable, {
                    path: _path + ".name",
                    expected: "string (@pattern_name)",
                    value: input.name
                })) || $guard(_exceptionable, {
                    path: _path + ".name",
                    expected: "string",
                    value: input.name
                })) && (3 === Object.keys(input).length || (false === _exceptionable || Object.keys(input).every((key: any) => {
                    if (["type", "bboxs", "name"].some((prop: any) => key === prop))
                        return true;
                    const value = input[key];
                    if (undefined === value)
                        return true;
                    return $guard(_exceptionable, {
                        path: _path + $join(key),
                        expected: "undefined",
                        value: value
                    });
                })));
                const $ao1 = (input: any, _path: string, _exceptionable: boolean = true): boolean => ("number" === typeof input.length || $guard(_exceptionable, {
                    path: _path + ".length",
                    expected: "number",
                    value: input.length
                })) && (false === _exceptionable || Object.keys(input).every((key: any) => {
                    if (["length"].some((prop: any) => key === prop))
                        return true;
                    const value = input[key];
                    if (undefined === value)
                        return true;
                    if (RegExp(/^[+-]?\d+(?:\.\d+)?(?:[eE][+-]?\d+)?$/).test(key))
                        return "number" === typeof value || $guard(_exceptionable, {
                            path: _path + $join(key),
                            expected: "number",
                            value: value
                        });
                    return $guard(_exceptionable, {
                        path: _path + $join(key),
                        expected: "undefined",
                        value: value
                    });
                }));
                const $ao2 = (input: any, _path: string, _exceptionable: boolean = true): boolean => ("AutoTaskTiling" === input.type || $guard(_exceptionable, {
                    path: _path + ".type",
                    expected: "\"AutoTaskTiling\"",
                    value: input.type
                })) && ("number" === typeof input.numRows && (Math.floor(input.numRows) === input.numRows || $guard(_exceptionable, {
                    path: _path + ".numRows",
                    expected: "number (@type int)",
                    value: input.numRows
                })) && (-2147483648 <= input.numRows && input.numRows <= 2147483647 || $guard(_exceptionable, {
                    path: _path + ".numRows",
                    expected: "number (@type int)",
                    value: input.numRows
                })) && (1 <= input.numRows || $guard(_exceptionable, {
                    path: _path + ".numRows",
                    expected: "number (@minimum 1)",
                    value: input.numRows
                })) || $guard(_exceptionable, {
                    path: _path + ".numRows",
                    expected: "number",
                    value: input.numRows
                })) && ("number" === typeof input.numCols && (Math.floor(input.numCols) === input.numCols || $guard(_exceptionable, {
                    path: _path + ".numCols",
                    expected: "number (@type int)",
                    value: input.numCols
                })) && (-2147483648 <= input.numCols && input.numCols <= 2147483647 || $guard(_exceptionable, {
                    path: _path + ".numCols",
                    expected: "number (@type int)",
                    value: input.numCols
                })) && (1 <= input.numCols || $guard(_exceptionable, {
                    path: _path + ".numCols",
                    expected: "number (@minimum 1)",
                    value: input.numCols
                })) || $guard(_exceptionable, {
                    path: _path + ".numCols",
                    expected: "number",
                    value: input.numCols
                })) && (undefined === input.overlap || "number" === typeof input.overlap && (0 <= input.overlap || $guard(_exceptionable, {
                    path: _path + ".overlap",
                    expected: "number (@minimum 0)",
                    value: input.overlap
                })) || $guard(_exceptionable, {
                    path: _path + ".overlap",
                    expected: "(number | undefined)",
                    value: input.overlap
                })) && ("string" === typeof input.name && ($is_custom("pattern_name", "string", "", input.name) || $guard(_exceptionable, {
                    path: _path + ".name",
                    expected: "string (@pattern_name)",
                    value: input.name
                })) || $guard(_exceptionable, {
                    path: _path + ".name",
                    expected: "string",
                    value: input.name
                })) && (4 === Object.keys(input).length || (false === _exceptionable || Object.keys(input).every((key: any) => {
                    if (["type", "numRows", "numCols", "overlap", "name"].some((prop: any) => key === prop))
                        return true;
                    const value = input[key];
                    if (undefined === value)
                        return true;
                    return $guard(_exceptionable, {
                        path: _path + $join(key),
                        expected: "undefined",
                        value: value
                    });
                })));
                const $ao3 = (input: any, _path: string, _exceptionable: boolean = true): boolean => ("CROP" === input.type || $guard(_exceptionable, {
                    path: _path + ".type",
                    expected: "\"CROP\"",
                    value: input.type
                })) && ((Array.isArray(input.bboxs) || $guard(_exceptionable, {
                    path: _path + ".bboxs",
                    expected: "Array<BoundingBoxRelative>",
                    value: input.bboxs
                })) && input.bboxs.every((elem: any, _index2: number) => ("object" === typeof elem && null !== elem || $guard(_exceptionable, {
                    path: _path + ".bboxs[" + _index2 + "]",
                    expected: "BoundingBoxRelative",
                    value: elem
                })) && $ao1(elem, _path + ".bboxs[" + _index2 + "]", true && _exceptionable) || $guard(_exceptionable, {
                    path: _path + ".bboxs[" + _index2 + "]",
                    expected: "BoundingBoxRelative",
                    value: elem
                })) || $guard(_exceptionable, {
                    path: _path + ".bboxs",
                    expected: "Array<BoundingBoxRelative>",
                    value: input.bboxs
                })) && ("string" === typeof input.name && ($is_custom("pattern_name", "string", "", input.name) || $guard(_exceptionable, {
                    path: _path + ".name",
                    expected: "string (@pattern_name)",
                    value: input.name
                })) || $guard(_exceptionable, {
                    path: _path + ".name",
                    expected: "string",
                    value: input.name
                })) && (3 === Object.keys(input).length || (false === _exceptionable || Object.keys(input).every((key: any) => {
                    if (["type", "bboxs", "name"].some((prop: any) => key === prop))
                        return true;
                    const value = input[key];
                    if (undefined === value)
                        return true;
                    return $guard(_exceptionable, {
                        path: _path + $join(key),
                        expected: "undefined",
                        value: value
                    });
                })));
                const $ao4 = (input: any, _path: string, _exceptionable: boolean = true): boolean => ("TILING" === input.type || $guard(_exceptionable, {
                    path: _path + ".type",
                    expected: "\"TILING\"",
                    value: input.type
                })) && ("number" === typeof input.numRows && (Math.floor(input.numRows) === input.numRows || $guard(_exceptionable, {
                    path: _path + ".numRows",
                    expected: "number (@type int)",
                    value: input.numRows
                })) && (-2147483648 <= input.numRows && input.numRows <= 2147483647 || $guard(_exceptionable, {
                    path: _path + ".numRows",
                    expected: "number (@type int)",
                    value: input.numRows
                })) && (1 <= input.numRows || $guard(_exceptionable, {
                    path: _path + ".numRows",
                    expected: "number (@minimum 1)",
                    value: input.numRows
                })) || $guard(_exceptionable, {
                    path: _path + ".numRows",
                    expected: "number",
                    value: input.numRows
                })) && ("number" === typeof input.numCols && (Math.floor(input.numCols) === input.numCols || $guard(_exceptionable, {
                    path: _path + ".numCols",
                    expected: "number (@type int)",
                    value: input.numCols
                })) && (-2147483648 <= input.numCols && input.numCols <= 2147483647 || $guard(_exceptionable, {
                    path: _path + ".numCols",
                    expected: "number (@type int)",
                    value: input.numCols
                })) && (1 <= input.numCols || $guard(_exceptionable, {
                    path: _path + ".numCols",
                    expected: "number (@minimum 1)",
                    value: input.numCols
                })) || $guard(_exceptionable, {
                    path: _path + ".numCols",
                    expected: "number",
                    value: input.numCols
                })) && (undefined === input.overlap || "number" === typeof input.overlap && (0 <= input.overlap || $guard(_exceptionable, {
                    path: _path + ".overlap",
                    expected: "number (@minimum 0)",
                    value: input.overlap
                })) || $guard(_exceptionable, {
                    path: _path + ".overlap",
                    expected: "(number | undefined)",
                    value: input.overlap
                })) && ("string" === typeof input.name && ($is_custom("pattern_name", "string", "", input.name) || $guard(_exceptionable, {
                    path: _path + ".name",
                    expected: "string (@pattern_name)",
                    value: input.name
                })) || $guard(_exceptionable, {
                    path: _path + ".name",
                    expected: "string",
                    value: input.name
                })) && (4 === Object.keys(input).length || (false === _exceptionable || Object.keys(input).every((key: any) => {
                    if (["type", "numRows", "numCols", "overlap", "name"].some((prop: any) => key === prop))
                        return true;
                    const value = input[key];
                    if (undefined === value)
                        return true;
                    return $guard(_exceptionable, {
                        path: _path + $join(key),
                        expected: "undefined",
                        value: value
                    });
                })));
                const $au0 = (input: any, _path: string, _exceptionable: boolean = true): any => (() => {
                    if ("AutoTaskCrop" === input.type)
                        return $ao0(input, _path, true && _exceptionable);
                    if ("AutoTaskTiling" === input.type)
                        return $ao2(input, _path, true && _exceptionable);
                    if ("CROP" === input.type)
                        return $ao3(input, _path, true && _exceptionable);
                    if ("TILING" === input.type)
                        return $ao4(input, _path, true && _exceptionable);
                    return $guard(_exceptionable, {
                        path: _path,
                        expected: "({ type: \"AutoTaskCrop\"; } & AutoTaskCrop | { type: \"AutoTaskTiling\"; } & AutoTaskTiling | { type: \"CROP\"; } & AutoTaskCrop | { type: \"TILING\"; } & AutoTaskTiling)",
                        value: input
                    });
                })();
                return ("object" === typeof input && null !== input || $guard(true, {
                    path: _path + "",
                    expected: "({ type: \"AutoTaskCrop\"; } & AutoTaskCrop | { type: \"AutoTaskTiling\"; } & AutoTaskTiling | { type: \"CROP\"; } & AutoTaskCrop | { type: \"TILING\"; } & AutoTaskTiling)",
                    value: input
                })) && $au0(input, _path + "", true) || $guard(true, {
                    path: _path + "",
                    expected: "({ type: \"AutoTaskCrop\"; } & AutoTaskCrop | { type: \"AutoTaskTiling\"; } & AutoTaskTiling | { type: \"CROP\"; } & AutoTaskCrop | { type: \"TILING\"; } & AutoTaskTiling)",
                    value: input
                });
            })(input, "$input", true);
        return input;
    })(input);
};
export const AutoTaskTypiaAssertStringify = (input: AutoTask): string => {
    return ((input: any): string => { const assert = (input: any): AutoTask => {
        const __is = (input: any): input is AutoTask => {
            const $is_custom = (typia.createAssertStringify as any).is_custom;
            const $join = (typia.createAssertStringify as any).join;
            const $io0 = (input: any): boolean => "AutoTaskCrop" === input.type && (Array.isArray(input.bboxs) && input.bboxs.every((elem: any) => "object" === typeof elem && null !== elem && $io1(elem))) && ("string" === typeof input.name && $is_custom("pattern_name", "string", "", input.name));
            const $io1 = (input: any): boolean => "number" === typeof input.length && !Number.isNaN(input.length) && Object.keys(input).every((key: any) => {
                if (["length"].some((prop: any) => key === prop))
                    return true;
                const value = input[key];
                if (undefined === value)
                    return true;
                if (RegExp(/^[+-]?\d+(?:\.\d+)?(?:[eE][+-]?\d+)?$/).test(key))
                    return "number" === typeof value && !Number.isNaN(value);
                return true;
            });
            const $io2 = (input: any): boolean => "AutoTaskTiling" === input.type && ("number" === typeof input.numRows && !Number.isNaN(input.numRows) && Math.floor(input.numRows) === input.numRows && (-2147483648 <= input.numRows && input.numRows <= 2147483647) && 1 <= input.numRows) && ("number" === typeof input.numCols && !Number.isNaN(input.numCols) && Math.floor(input.numCols) === input.numCols && (-2147483648 <= input.numCols && input.numCols <= 2147483647) && 1 <= input.numCols) && (undefined === input.overlap || "number" === typeof input.overlap && !Number.isNaN(input.overlap) && 0 <= input.overlap) && ("string" === typeof input.name && $is_custom("pattern_name", "string", "", input.name));
            const $io3 = (input: any): boolean => "CROP" === input.type && (Array.isArray(input.bboxs) && input.bboxs.every((elem: any) => "object" === typeof elem && null !== elem && $io1(elem))) && ("string" === typeof input.name && $is_custom("pattern_name", "string", "", input.name));
            const $io4 = (input: any): boolean => "TILING" === input.type && ("number" === typeof input.numRows && !Number.isNaN(input.numRows) && Math.floor(input.numRows) === input.numRows && (-2147483648 <= input.numRows && input.numRows <= 2147483647) && 1 <= input.numRows) && ("number" === typeof input.numCols && !Number.isNaN(input.numCols) && Math.floor(input.numCols) === input.numCols && (-2147483648 <= input.numCols && input.numCols <= 2147483647) && 1 <= input.numCols) && (undefined === input.overlap || "number" === typeof input.overlap && !Number.isNaN(input.overlap) && 0 <= input.overlap) && ("string" === typeof input.name && $is_custom("pattern_name", "string", "", input.name));
            const $iu0 = (input: any): any => (() => {
                if ("AutoTaskCrop" === input.type)
                    return $io0(input);
                if ("AutoTaskTiling" === input.type)
                    return $io2(input);
                if ("CROP" === input.type)
                    return $io3(input);
                if ("TILING" === input.type)
                    return $io4(input);
                return false;
            })();
            return "object" === typeof input && null !== input && $iu0(input);
        };
        if (false === __is(input))
            ((input: any, _path: string, _exceptionable: boolean = true): input is AutoTask => {
                const $guard = (typia.createAssertStringify as any).guard;
                const $is_custom = (typia.createAssertStringify as any).is_custom;
                const $join = (typia.createAssertStringify as any).join;
                const $ao0 = (input: any, _path: string, _exceptionable: boolean = true): boolean => ("AutoTaskCrop" === input.type || $guard(_exceptionable, {
                    path: _path + ".type",
                    expected: "\"AutoTaskCrop\"",
                    value: input.type
                })) && ((Array.isArray(input.bboxs) || $guard(_exceptionable, {
                    path: _path + ".bboxs",
                    expected: "Array<BoundingBoxRelative>",
                    value: input.bboxs
                })) && input.bboxs.every((elem: any, _index1: number) => ("object" === typeof elem && null !== elem || $guard(_exceptionable, {
                    path: _path + ".bboxs[" + _index1 + "]",
                    expected: "BoundingBoxRelative",
                    value: elem
                })) && $ao1(elem, _path + ".bboxs[" + _index1 + "]", true && _exceptionable) || $guard(_exceptionable, {
                    path: _path + ".bboxs[" + _index1 + "]",
                    expected: "BoundingBoxRelative",
                    value: elem
                })) || $guard(_exceptionable, {
                    path: _path + ".bboxs",
                    expected: "Array<BoundingBoxRelative>",
                    value: input.bboxs
                })) && ("string" === typeof input.name && ($is_custom("pattern_name", "string", "", input.name) || $guard(_exceptionable, {
                    path: _path + ".name",
                    expected: "string (@pattern_name)",
                    value: input.name
                })) || $guard(_exceptionable, {
                    path: _path + ".name",
                    expected: "string",
                    value: input.name
                }));
                const $ao1 = (input: any, _path: string, _exceptionable: boolean = true): boolean => ("number" === typeof input.length && !Number.isNaN(input.length) || $guard(_exceptionable, {
                    path: _path + ".length",
                    expected: "number",
                    value: input.length
                })) && (false === _exceptionable || Object.keys(input).every((key: any) => {
                    if (["length"].some((prop: any) => key === prop))
                        return true;
                    const value = input[key];
                    if (undefined === value)
                        return true;
                    if (RegExp(/^[+-]?\d+(?:\.\d+)?(?:[eE][+-]?\d+)?$/).test(key))
                        return "number" === typeof value && !Number.isNaN(value) || $guard(_exceptionable, {
                            path: _path + $join(key),
                            expected: "number",
                            value: value
                        });
                    return true;
                }));
                const $ao2 = (input: any, _path: string, _exceptionable: boolean = true): boolean => ("AutoTaskTiling" === input.type || $guard(_exceptionable, {
                    path: _path + ".type",
                    expected: "\"AutoTaskTiling\"",
                    value: input.type
                })) && ("number" === typeof input.numRows && !Number.isNaN(input.numRows) && (Math.floor(input.numRows) === input.numRows || $guard(_exceptionable, {
                    path: _path + ".numRows",
                    expected: "number (@type int)",
                    value: input.numRows
                })) && (-2147483648 <= input.numRows && input.numRows <= 2147483647 || $guard(_exceptionable, {
                    path: _path + ".numRows",
                    expected: "number (@type int)",
                    value: input.numRows
                })) && (1 <= input.numRows || $guard(_exceptionable, {
                    path: _path + ".numRows",
                    expected: "number (@minimum 1)",
                    value: input.numRows
                })) || $guard(_exceptionable, {
                    path: _path + ".numRows",
                    expected: "number",
                    value: input.numRows
                })) && ("number" === typeof input.numCols && !Number.isNaN(input.numCols) && (Math.floor(input.numCols) === input.numCols || $guard(_exceptionable, {
                    path: _path + ".numCols",
                    expected: "number (@type int)",
                    value: input.numCols
                })) && (-2147483648 <= input.numCols && input.numCols <= 2147483647 || $guard(_exceptionable, {
                    path: _path + ".numCols",
                    expected: "number (@type int)",
                    value: input.numCols
                })) && (1 <= input.numCols || $guard(_exceptionable, {
                    path: _path + ".numCols",
                    expected: "number (@minimum 1)",
                    value: input.numCols
                })) || $guard(_exceptionable, {
                    path: _path + ".numCols",
                    expected: "number",
                    value: input.numCols
                })) && (undefined === input.overlap || "number" === typeof input.overlap && !Number.isNaN(input.overlap) && (0 <= input.overlap || $guard(_exceptionable, {
                    path: _path + ".overlap",
                    expected: "number (@minimum 0)",
                    value: input.overlap
                })) || $guard(_exceptionable, {
                    path: _path + ".overlap",
                    expected: "(number | undefined)",
                    value: input.overlap
                })) && ("string" === typeof input.name && ($is_custom("pattern_name", "string", "", input.name) || $guard(_exceptionable, {
                    path: _path + ".name",
                    expected: "string (@pattern_name)",
                    value: input.name
                })) || $guard(_exceptionable, {
                    path: _path + ".name",
                    expected: "string",
                    value: input.name
                }));
                const $ao3 = (input: any, _path: string, _exceptionable: boolean = true): boolean => ("CROP" === input.type || $guard(_exceptionable, {
                    path: _path + ".type",
                    expected: "\"CROP\"",
                    value: input.type
                })) && ((Array.isArray(input.bboxs) || $guard(_exceptionable, {
                    path: _path + ".bboxs",
                    expected: "Array<BoundingBoxRelative>",
                    value: input.bboxs
                })) && input.bboxs.every((elem: any, _index2: number) => ("object" === typeof elem && null !== elem || $guard(_exceptionable, {
                    path: _path + ".bboxs[" + _index2 + "]",
                    expected: "BoundingBoxRelative",
                    value: elem
                })) && $ao1(elem, _path + ".bboxs[" + _index2 + "]", true && _exceptionable) || $guard(_exceptionable, {
                    path: _path + ".bboxs[" + _index2 + "]",
                    expected: "BoundingBoxRelative",
                    value: elem
                })) || $guard(_exceptionable, {
                    path: _path + ".bboxs",
                    expected: "Array<BoundingBoxRelative>",
                    value: input.bboxs
                })) && ("string" === typeof input.name && ($is_custom("pattern_name", "string", "", input.name) || $guard(_exceptionable, {
                    path: _path + ".name",
                    expected: "string (@pattern_name)",
                    value: input.name
                })) || $guard(_exceptionable, {
                    path: _path + ".name",
                    expected: "string",
                    value: input.name
                }));
                const $ao4 = (input: any, _path: string, _exceptionable: boolean = true): boolean => ("TILING" === input.type || $guard(_exceptionable, {
                    path: _path + ".type",
                    expected: "\"TILING\"",
                    value: input.type
                })) && ("number" === typeof input.numRows && !Number.isNaN(input.numRows) && (Math.floor(input.numRows) === input.numRows || $guard(_exceptionable, {
                    path: _path + ".numRows",
                    expected: "number (@type int)",
                    value: input.numRows
                })) && (-2147483648 <= input.numRows && input.numRows <= 2147483647 || $guard(_exceptionable, {
                    path: _path + ".numRows",
                    expected: "number (@type int)",
                    value: input.numRows
                })) && (1 <= input.numRows || $guard(_exceptionable, {
                    path: _path + ".numRows",
                    expected: "number (@minimum 1)",
                    value: input.numRows
                })) || $guard(_exceptionable, {
                    path: _path + ".numRows",
                    expected: "number",
                    value: input.numRows
                })) && ("number" === typeof input.numCols && !Number.isNaN(input.numCols) && (Math.floor(input.numCols) === input.numCols || $guard(_exceptionable, {
                    path: _path + ".numCols",
                    expected: "number (@type int)",
                    value: input.numCols
                })) && (-2147483648 <= input.numCols && input.numCols <= 2147483647 || $guard(_exceptionable, {
                    path: _path + ".numCols",
                    expected: "number (@type int)",
                    value: input.numCols
                })) && (1 <= input.numCols || $guard(_exceptionable, {
                    path: _path + ".numCols",
                    expected: "number (@minimum 1)",
                    value: input.numCols
                })) || $guard(_exceptionable, {
                    path: _path + ".numCols",
                    expected: "number",
                    value: input.numCols
                })) && (undefined === input.overlap || "number" === typeof input.overlap && !Number.isNaN(input.overlap) && (0 <= input.overlap || $guard(_exceptionable, {
                    path: _path + ".overlap",
                    expected: "number (@minimum 0)",
                    value: input.overlap
                })) || $guard(_exceptionable, {
                    path: _path + ".overlap",
                    expected: "(number | undefined)",
                    value: input.overlap
                })) && ("string" === typeof input.name && ($is_custom("pattern_name", "string", "", input.name) || $guard(_exceptionable, {
                    path: _path + ".name",
                    expected: "string (@pattern_name)",
                    value: input.name
                })) || $guard(_exceptionable, {
                    path: _path + ".name",
                    expected: "string",
                    value: input.name
                }));
                const $au0 = (input: any, _path: string, _exceptionable: boolean = true): any => (() => {
                    if ("AutoTaskCrop" === input.type)
                        return $ao0(input, _path, true && _exceptionable);
                    if ("AutoTaskTiling" === input.type)
                        return $ao2(input, _path, true && _exceptionable);
                    if ("CROP" === input.type)
                        return $ao3(input, _path, true && _exceptionable);
                    if ("TILING" === input.type)
                        return $ao4(input, _path, true && _exceptionable);
                    return $guard(_exceptionable, {
                        path: _path,
                        expected: "({ type: \"AutoTaskCrop\"; } & AutoTaskCrop | { type: \"AutoTaskTiling\"; } & AutoTaskTiling | { type: \"CROP\"; } & AutoTaskCrop | { type: \"TILING\"; } & AutoTaskTiling)",
                        value: input
                    });
                })();
                return ("object" === typeof input && null !== input || $guard(true, {
                    path: _path + "",
                    expected: "({ type: \"AutoTaskCrop\"; } & AutoTaskCrop | { type: \"AutoTaskTiling\"; } & AutoTaskTiling | { type: \"CROP\"; } & AutoTaskCrop | { type: \"TILING\"; } & AutoTaskTiling)",
                    value: input
                })) && $au0(input, _path + "", true) || $guard(true, {
                    path: _path + "",
                    expected: "({ type: \"AutoTaskCrop\"; } & AutoTaskCrop | { type: \"AutoTaskTiling\"; } & AutoTaskTiling | { type: \"CROP\"; } & AutoTaskCrop | { type: \"TILING\"; } & AutoTaskTiling)",
                    value: input
                });
            })(input, "$input", true);
        return input;
    }; const stringify = (input: AutoTask): string => {
        const $io0 = (input: any): boolean => "AutoTaskCrop" === input.type && (Array.isArray(input.bboxs) && input.bboxs.every((elem: any) => "object" === typeof elem && null !== elem && $io1(elem))) && ("string" === typeof input.name && $is_custom("pattern_name", "string", "", input.name));
        const $io1 = (input: any): boolean => "number" === typeof input.length && Object.keys(input).every((key: any) => {
            if (["length"].some((prop: any) => key === prop))
                return true;
            const value = input[key];
            if (undefined === value)
                return true;
            if (RegExp(/^[+-]?\d+(?:\.\d+)?(?:[eE][+-]?\d+)?$/).test(key))
                return "number" === typeof value;
            return true;
        });
        const $io2 = (input: any): boolean => "AutoTaskTiling" === input.type && ("number" === typeof input.numRows && Math.floor(input.numRows) === input.numRows && (-2147483648 <= input.numRows && input.numRows <= 2147483647) && 1 <= input.numRows) && ("number" === typeof input.numCols && Math.floor(input.numCols) === input.numCols && (-2147483648 <= input.numCols && input.numCols <= 2147483647) && 1 <= input.numCols) && (undefined === input.overlap || "number" === typeof input.overlap && 0 <= input.overlap) && ("string" === typeof input.name && $is_custom("pattern_name", "string", "", input.name));
        const $io3 = (input: any): boolean => "CROP" === input.type && (Array.isArray(input.bboxs) && input.bboxs.every((elem: any) => "object" === typeof elem && null !== elem && $io1(elem))) && ("string" === typeof input.name && $is_custom("pattern_name", "string", "", input.name));
        const $io4 = (input: any): boolean => "TILING" === input.type && ("number" === typeof input.numRows && Math.floor(input.numRows) === input.numRows && (-2147483648 <= input.numRows && input.numRows <= 2147483647) && 1 <= input.numRows) && ("number" === typeof input.numCols && Math.floor(input.numCols) === input.numCols && (-2147483648 <= input.numCols && input.numCols <= 2147483647) && 1 <= input.numCols) && (undefined === input.overlap || "number" === typeof input.overlap && 0 <= input.overlap) && ("string" === typeof input.name && $is_custom("pattern_name", "string", "", input.name));
        const $string = (typia.createAssertStringify as any).string;
        const $throws = (typia.createAssertStringify as any).throws;
        const $join = (typia.createAssertStringify as any).join;
        const $tail = (typia.createAssertStringify as any).tail;
        const $is_custom = (typia.createAssertStringify as any).is_custom;
        const $so0 = (input: any): any => `{"type":${(() => {
            if ("string" === typeof input.type)
                return $string(input.type);
            if ("string" === typeof input.type)
                return "\"" + input.type + "\"";
            $throws({
                expected: "\"AutoTaskCrop\"",
                value: input.type
            });
        })()},"bboxs":${`[${input.bboxs.map((elem: any) => $so1(elem)).join(",")}]`},"name":${$string(input.name)}}`;
        const $so1 = (input: any): any => `{${$tail(`"length":${input.length},${Object.entries(input).map(([key, value]: [string, any]) => { if (undefined === value)
            return ""; if (["length"].some((regular: any) => regular === key))
            return ""; if (RegExp(/^[+-]?\d+(?:\.\d+)?(?:[eE][+-]?\d+)?$/).test(key))
            return `${JSON.stringify(key)}:${value}`; return ""; }).filter((str: any) => "" !== str).join(",")}`)}}`;
        const $so2 = (input: any): any => `{${undefined === input.overlap ? "" : `"overlap":${undefined !== input.overlap ? input.overlap : undefined},`}"type":${(() => {
            if ("string" === typeof input.type)
                return $string(input.type);
            if ("string" === typeof input.type)
                return "\"" + input.type + "\"";
            $throws({
                expected: "\"AutoTaskTiling\"",
                value: input.type
            });
        })()},"numRows":${input.numRows},"numCols":${input.numCols},"name":${$string(input.name)}}`;
        const $so3 = (input: any): any => `{"type":${(() => {
            if ("string" === typeof input.type)
                return $string(input.type);
            if ("string" === typeof input.type)
                return "\"" + input.type + "\"";
            $throws({
                expected: "\"CROP\"",
                value: input.type
            });
        })()},"bboxs":${`[${input.bboxs.map((elem: any) => $so1(elem)).join(",")}]`},"name":${$string(input.name)}}`;
        const $so4 = (input: any): any => `{${undefined === input.overlap ? "" : `"overlap":${undefined !== input.overlap ? input.overlap : undefined},`}"type":${(() => {
            if ("string" === typeof input.type)
                return $string(input.type);
            if ("string" === typeof input.type)
                return "\"" + input.type + "\"";
            $throws({
                expected: "\"TILING\"",
                value: input.type
            });
        })()},"numRows":${input.numRows},"numCols":${input.numCols},"name":${$string(input.name)}}`;
        const $su0 = (input: any): any => (() => {
            if ("AutoTaskCrop" === input.type)
                return $so0(input);
            if ("AutoTaskTiling" === input.type)
                return $so2(input);
            if ("CROP" === input.type)
                return $so3(input);
            if ("TILING" === input.type)
                return $so4(input);
            $throws({
                expected: "({ type: \"AutoTaskCrop\"; } & AutoTaskCrop | { type: \"AutoTaskTiling\"; } & AutoTaskTiling | { type: \"CROP\"; } & AutoTaskCrop | { type: \"TILING\"; } & AutoTaskTiling)",
                value: input
            });
        })();
        return $su0(input);
    }; return stringify(assert(input)); })(input);
};
export enum AutoTaskTypeDiscriminator {
    AutoTaskCrop = 'AutoTaskCrop',
    AutoTaskTiling = 'AutoTaskTiling',
    CROP = 'CROP',
    TILING = 'TILING'
}
export const AutoTaskValidatorShape = yup.lazy((json) => {
    switch (json['type']) {
        case 'AutoTaskCrop':
            return AutoTaskCropValidator.defined();
        case 'AutoTaskTiling':
            return AutoTaskTilingValidator.defined();
        case 'CROP':
            return AutoTaskCropValidator.defined();
        case 'TILING':
            return AutoTaskTilingValidator.defined();
        default:
            // do not throw directly when constructing the lazy schema otherwise we have an infinite loop
            return yup.mixed().test('AutoTaskShapeTest', 'AutoTask needs to be one of  AutoTaskCrop,  AutoTaskTiling,  CROP,  TILING ', (data) => {
                throw new Error(`No variant of AutoTask exists with 'type=${json['type']}'`);
            });
    }
});
export const AutoTaskValidator = AutoTaskValidatorShape as any;
export const AutoTaskStringifyShapeProperties = {};
export const AutoTaskStringifyShape: any = {
    title: 'AutoTask OneOf Stringifier',
    type: 'object',
    'if': {
        type: 'object',
        'properties': {
            'type': { 'type': 'string', 'enum': ['AutoTaskCrop'] }
        }
    },
    'then': {
        'properties': AutoTaskCropStringifyShape.properties || {}
    },
    'else': {
        'if': {
            type: 'object',
            'properties': {
                'type': { 'type': 'string', 'enum': ['AutoTaskTiling'] }
            }
        },
        'then': {
            'properties': AutoTaskTilingStringifyShape.properties || {}
        },
        'else': {
            'if': {
                type: 'object',
                'properties': {
                    'type': { 'type': 'string', 'enum': ['CROP'] }
                }
            },
            'then': {
                'properties': AutoTaskCropStringifyShape.properties || {}
            },
            'else': {
                'if': {
                    type: 'object',
                    'properties': {
                        'type': { 'type': 'string', 'enum': ['TILING'] }
                    }
                },
                'then': {
                    'properties': AutoTaskTilingStringifyShape.properties || {}
                }
            },
        },
    },
};
export const AutoTaskStringifyCompiled = (typeof process === 'object' && String(process) === '[object process]') ?
    fastJSON.build(AutoTaskStringifyShape, {
        ajv: {
            // since we are already doing this with yup, lets skip
            validateSchema: false,
            ownProperties: true,
            validateFormats: false,
        },
        largeArrayMechanism: 'json-stringify',
        largeArraySize: 1000,
    } as Options)
    :
        () => { throw new Error('fast-json-stringify only works in nodejs'); };
export const AutoTaskStringify: (data: AutoTask) => string = AutoTaskStringifyCompiled;
/* LIGHTLY CUSTOM END -> oneOf */
export function AutoTaskFromJSON(json: any): AutoTask {
    return AutoTaskFromJSONTyped(json, false);
}
export function AutoTaskFromJSONTyped(json: any, ignoreDiscriminator: boolean): AutoTask {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    switch (json['type']) {
        case 'AutoTaskCrop':
            return { ...AutoTaskCropFromJSONTyped(json, true), type: 'AutoTaskCrop' };
        case 'AutoTaskTiling':
            return { ...AutoTaskTilingFromJSONTyped(json, true), type: 'AutoTaskTiling' };
        case 'CROP':
            return { ...AutoTaskCropFromJSONTyped(json, true), type: 'CROP' };
        case 'TILING':
            return { ...AutoTaskTilingFromJSONTyped(json, true), type: 'TILING' };
        default:
            throw new Error(`No variant of AutoTask exists with 'type=${json['type']}'`);
    }
}
export function AutoTaskToJSON(value?: AutoTask | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    switch (value['type']) {
        case 'AutoTaskCrop':
            return AutoTaskCropToJSON(value);
        case 'AutoTaskTiling':
            return AutoTaskTilingToJSON(value);
        case 'CROP':
            return AutoTaskCropToJSON(value);
        case 'TILING':
            return AutoTaskTilingToJSON(value);
        default:
            throw new Error(`No variant of AutoTask exists with 'type=${value['type']}'`);
    }
}
