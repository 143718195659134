/* tslint:disable */
/* eslint-disable */
/**
 * Lightly API
 * Lightly.ai enables you to do self-supervised learning in an easy and intuitive way. The lightly.ai OpenAPI spec defines how one can interact with our REST API to unleash the full potential of lightly.ai
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: support@lightly.ai
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
// SRC: https://github.com/OpenAPITools/openapi-generator/blob/master/modules/openapi-generator/src/main/resources/typescript-fetch/modelEnumInterfaces.mustache
// synched 02.02.2023
import * as yup from 'yup';
import * as fastJSON from 'fast-json-stringify';
import { StringSchema } from 'fast-json-stringify';
/**
 * ids: return only the id
 * fileNames: return the id and fileName
 * full: return all data
 * @export
 * @enum {string}
 */
export enum SamplePartialMode {
    ids = 'ids',
    fileNames = 'fileNames',
    full = 'full'
}
/* LIGHTLY CUSTOM START -> modelEnumInterfaces */
export const SamplePartialModeValidatorShape = [
    'ids',
    'fileNames',
    'full'
];
export const SamplePartialModeValidator = yup.mixed().oneOf(SamplePartialModeValidatorShape);
export const SamplePartialModeStringifyShapeProperties = {};
export const SamplePartialModeStringifyShape: StringSchema = {
    title: 'SamplePartialMode Stringifier',
    type: 'string'
};
export const SamplePartialModeStringify = (typeof process === 'object' && String(process) === '[object process]') ?
    fastJSON.build(SamplePartialModeStringifyShape)
    :
        () => { throw new Error('fast-json-stringify only works in nodejs'); };
/* LIGHTLY CUSTOM END */
export function SamplePartialModeFromJSON(json: any): SamplePartialMode {
    return SamplePartialModeFromJSONTyped(json, false);
}
export function SamplePartialModeFromJSONTyped(json: any, ignoreDiscriminator: boolean): SamplePartialMode {
    return json as SamplePartialMode;
}
export function SamplePartialModeToJSON(value?: SamplePartialMode | null): any {
    return value as any;
}
