import { ApiErrorCode } from '@lightly/api-spec';


export const errorCodes: Record<ApiErrorCode,string> = {
	'BAD_REQUEST': 'The request made was erroneous.',
	'NOT_IMPLEMENTED': 'The request made is not implemented.',
	'FORBIDDEN': 'You do not have permission to access this resource.',
	'UNAUTHORIZED': 'You are not authorized to access the system.',
	'NOT_FOUND': 'The resource you requested was not found.',
	'NOT_MODIFIED': 'The resource exists and was not modified.',
	'CONFLICT': 'The resource you are trying to modify was already modified or the conditioned state is not given anymore.',
	'MALFORMED_REQUEST': 'The request you sent was malformed and could not be handled.',
	'MALFORMED_RESPONSE': 'The server is sending gibberish. Please contact support@lightly.ai.',
	'PAYLOAD_TOO_LARGE': 'The payload is too huge and can\'t be processed.',
	'CREATION_FAILED': 'It was not possible to create the entity you wanted to create. Please try again or contact support if this persists.',

	'JWT_INVALID': 'The provided JWT is not valid anymore or the signature does not match.',
	'JWT_MALFORMED': 'The provided JWT was malformed or does not match the endpoint.',

	'JOB_CREATION_FAILED': 'We were not able to create a job.',
	'JOB_UNKNOWN': 'The specified job is unknown.',

	'USER_NOT_KNOWN': 'The user is unknown.',
	'USER_ACCOUNT_DEACTIVATED': 'The user account has been deactivated.',
	'USER_ACCOUNT_BLOCKED': 'The user account has been blocked.',
	'TEAM_ACCOUNT_PLAN_INSUFFICIENT': 'Your current plan is not sufficient to do this action.',

	'ILLEGAL_ACTION_RESOURCE_IN_USE': 'Operation not permitted. The specified resource is in use by something else or is dependent upon by another resource.',

	'DATASET_TAG_INVALID': 'The specified tag for the dataset is invalid.',
	'DATASET_NOT_SUPPORTED': 'This action is not supported by your dataset.',
	'DATASET_NAME_EXISTS': 'A dataset with this name already exists.',
	'DATASET_UNKNOWN': 'The specified dataset is unknown.',
	'DATASET_AT_MAX_CAPACITY': 'The specified dataset already has the maximum allowed capacity.',

	'DATASET_DATASOURCE_UNKNOWN': 'There is no known datasource for the dataset.',
	'DATASET_DATASOURCE_INVALID': 'The datasource configuration is invalid.',
	'DATASET_DATASOURCE_CREDENTIALS_ERROR': 'There is an issue with the credentials of the datasource.',
	'DATASET_DATASOURCE_ACTION_NOT_IMPLEMENTED': 'The action is not implemented for this datasource.',
	'DATASET_DATASOURCE_ILLEGAL_ACTION': 'The action you wanted to perform is considered illegal or dangerous.',
	'DATASET_DATASOURCE_FILE_TOO_LARGE': 'The referenced file was too large to be processed.',
	'DATASET_DATASOURCE_RELEVANT_FILENAMES_INVALID': 'The file of relevant filenames is not valid or does not exist.',

	'ACCESS_CONTROL_UNKNOWN': 'The specified access control is not known.',

	'EMBEDDING_NAME_EXISTS': 'An embedding with this name already exists.',
	'EMBEDDING_INVALID': 'The specified embedding is invalid.',
	'EMBEDDING_UNKNOWN': 'The specified embedding is unknown.',
	'EMBEDDING_NOT_READY': 'The specified embedding is not ready for further processing yet.',
	'EMBEDDING_ROW_COUNT_UNKNOWN': 'The specified embedding does not have an attribute rowCount.',
	'EMBEDDING_ROW_COUNT_INVALID': 'The embedding attribute rowCount is invalid (must be non-negative).',

	'EMBEDDING_2D_UNKNOWN': 'The specified 2d embedding is unknown.',

	'TAG_UNKNOWN': 'The specified tag could not be found.',
	'TAG_NAME_EXISTS': 'The name of the tag already exists for your dataset.',
	'TAG_INITIAL_EXISTS': 'The initial tag for your dataset has already been created.',
	'TAG_PREVTAG_NOT_IN_DATASET': 'The previous/parent tag specified is not part of the same dataset.',
	'TAG_QUERYTAG_NOT_IN_DATASET': 'The specified query tag is not part of the same dataset.',
	'TAG_PRESELECTEDTAG_NOT_IN_DATASET': 'The specified preselected tag is not part of the same dataset.',
	'TAG_NO_TAG_IN_DATASET': 'None of the specified tagIds (prevTagId, queryTagId, preselectedTagId) has been found in the same dataset.',
	'TAG_UNDELETABLE_NOT_A_LEAF': 'The tag can not be deleted as it has another tag which depends on or references it.',
	'TAG_UNDELETABLE_IS_INITIAL': 'The tag can not be deleted as it is the initial tag.',
	'TAG_NO_SCORES_AVAILABLE': 'There are no active learning scores available. Please upload active learning scores.',

	'METADATA_CONFIGURATION_UNKNOWN': 'The specified metadata configuration could not be found.',

	'SAMPLE_UNKNOWN': 'The specified sample could not be found.',
	'SAMPLE_THUMBNAME_UNKNOWN': 'The specified sample does not have a thumbnail.',
	'SAMPLE_CREATE_REQUEST_INVALID_FORMAT': 'The create request for the sample has an invalid format.',
	'SAMPLE_CREATE_REQUEST_INVALID_CROP_DATA': 'The create request for the sample has a invalid crop data.',

	'PREDICTION_TASK_SCHEMA_UNKNOWN': 'The specified prediction task schema is unknown.',
	'PREDICTION_TASK_SCHEMA_CATEGORIES_NOT_UNIQUE': 'The category ids within the prediction task schema need to be unique.',

	'SCORE_UNKNOWN': 'The specified score could not be found.',
	'SCORES_CANNOT_BE_SET': 'The scores can not be set for this tag.',

	'DOCKER_RUN_UNKNOWN': 'The specified docker run could not be found.',
	'DOCKER_RUN_DATASET_UNAVAILABLE': 'The specified docker run does not have the dataset available.',
	'DOCKER_RUN_REPORT_UNAVAILABLE': 'The specified docker run does not have a report available.',
	'DOCKER_RUN_ARTIFACT_UNKNOWN': 'The specified artifact of a docker run could not be found.',
	'DOCKER_RUN_ARTIFACT_UNAVAILABLE': 'The specified docker run does not have the specific artifact available.',
	'DOCKER_RUN_ARTIFACT_EXISTS': 'The specified docker run artifact already exists.',

	'DOCKER_WORKER_UNKNOWN': 'The specified worker could not be found.',

	'DOCKER_WORKER_CONFIG_UNKNOWN': 'The specified worker configuration could not be found.',
	'DOCKER_WORKER_CONFIG_NOT_COMPATIBLE_WITH_DATASOURCE': 'The specified configuration is not compatible with the datasource of the dataset.',
	'DOCKER_WORKER_CONFIG_REFERENCES_INVALID_FILES': 'The specified configuration references files in the datasets datasource which are not valid.',
	'DOCKER_WORKER_CONFIG_IN_USE': 'The specified worker configuration is currently in use by a scheduled run. Unschedule the run or wait until it is finished to change the configuration.',
	'DOCKER_WORKER_CONFIG_INVALID': 'The specified worker configuration is wrong.',

	'DOCKER_WORKER_SCHEDULE_UNKNOWN': 'The specified scheduled run of a worker could not be found.',
	'DOCKER_WORKER_SCHEDULE_UPDATE_FAILED': 'The requested update to a scheduled run of a worker could not be completed.',

	'CUSTOM_METADATA_AT_MAX_SIZE': 'The provided custom metadata exceeds the maximum allowed size.',

	'ONPREM_SUBSCRIPTION_INSUFFICIENT': 'You have reached your quota limit for your on-premise license, please contact sales at sales@lightly.ai to upgrade your account.',
	'ACCOUNT_SUBSCRIPTION_INSUFFICIENT': 'You have reached your quota limit, please contact sales at sales@lightly.ai to upgrade your account.',

	'TEAM_UNKNOWN': 'The specified team could not be found.',
}
