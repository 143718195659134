export const docker = {
	'field_state_STARTED': 'started',
	'field_state_INITIALIZING': 'initializing',
	'field_state_LOADING_DATASET': 'loading dataset',
	'field_state_LOADING_PREDICTION': 'loading predictions',
	'field_state_CHECKING_CORRUPTNESS': 'checking for corrupt images',
	'field_state_INITIALIZING_OBJECT_CROPS': 'initializing object crops',
	'field_state_LOADING_METADATA': 'loading metadata',
	'field_state_COMPUTING_METADATA': 'computing metadata',
	'field_state_TRAINING': 'training',
	'field_state_EMBEDDING': 'embedding',
	'field_state_EMBEDDING_OBJECT_CROPS': 'embedding object crops',
	'field_state_PRETAGGING': 'pretagging',
	'field_state_COMPUTING_ACTIVE_LEARNING_SCORES': 'computing active learning scores',
	'field_state_SAMPLING': 'selecting',
	'field_state_EMBEDDING_FULL_IMAGES': 'embedding full images',
	'field_state_SAVING_RESULTS': 'saving results',
	'field_state_UPLOADING_DATASET': 'uploading dataset',
	'field_state_GENERATING_REPORT': 'generating report',
	'field_state_UPLOADING_REPORT': 'uploading report',
	'field_state_UPLOADED_REPORT': 'report uploaded',
	'field_state_UPLOADING_ARTIFACTS': 'uploading artifacts',
	'field_state_UPLOADED_ARTIFACTS': 'artifacts uploaded',
	'field_state_COMPLETED': 'completed',
	'field_state_CRASHED': 'crashed / timeout',
	'field_state_FAILED': 'failed',
	'field_state_ABORTED': 'aborted',

	// run artifacts
	'run_artifact_REPORT_JSON': 'report json v1',
	'run_artifact_REPORT_V2_JSON': 'report json v2',
	'run_artifact_REPORT_PDF': 'PDF report',
	'run_artifact_LOG': 'log',
	'run_artifact_CHECKPOINT': 'checkpoint',
	'run_artifact_MEMLOG': 'memory log',
	'run_artifact_CORRUPTNESS_CHECK_INFORMATION': 'corruptness check info',
	'run_artifact_SEQUENCE_INFORMATION': 'sequence information',
	'run_artifact_RELEVANT_FILENAMES': 'relevant filenames',
};
export default docker;