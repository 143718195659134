/* eslint-disable import/prefer-default-export */

export const SERVER_LOCATION: string = process.env.REACT_APP_SERVER_BASE_URL as string;
export const MIN_NAME_CHARACTERS = 3;
export const VALID_FILE_ENDINGS: string[] = ['jpg', 'jpeg', 'png', 'tiff', 'bmp'];

export const MAX_SAMPLES_FRONTEND_UPLOAD = 1000;
export const VERSION = '1.3';
const GB: number = 1024 * 1024 * 1024;
export const ToGB = (x: number): number => Number((x / GB).toFixed(2));
export const MAX_FILE_SIZE_IN_GB = 50; // 50GBytes
export const ETA_SMOOTHING_FACTOR = 0.1;
export const TOOLTIP_TOKEN_TEXT = 'The token is a 8 digit code you have received from your account manager';
export const TOOLTIP_DATASET_TEXT = 'Select your archived dataset (*.zip, *.tar)';
export const TOOLTIP_FILTER_STRENGTH_TEXT = 'Choose a filter depending on your goal. You can either reduce data annotation costs (up to 50%) or reduce overfitting.';
export const TOOLTIP_USE_CASE_TEXT = 'Some of our filters are optimized for specific use cases such as object detection or semantic segmentation.';
export const RESULTS_SUCCESS_TEXT = 'Your dataset has been uploaded successfully and will now be processed. You will receive a mail with the results.';
export const RESULTS_FAILURE_TEXT = 'There has been an issue during uploading and verifying your dataset. Please try again or contact your account manager.';
