/* tslint:disable */
/* eslint-disable */
/**
 * Lightly API
 * Lightly.ai enables you to do self-supervised learning in an easy and intuitive way. The lightly.ai OpenAPI spec defines how one can interact with our REST API to unleash the full potential of lightly.ai
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: support@lightly.ai
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
// SRC: https://github.com/OpenAPITools/openapi-generator/blob/master/modules/openapi-generator/src/main/resources/typescript-fetch/modelEnumInterfaces.mustache
// synched 02.02.2023
import * as yup from 'yup';
import * as fastJSON from 'fast-json-stringify';
import { StringSchema } from 'fast-json-stringify';
/**
 * Type of the sample (VideoFrame vs IMAGE vs CROP). Determined by the API!
 * @export
 * @enum {string}
 */
export enum SampleType {
    CROP = 'CROP',
    IMAGE = 'IMAGE',
    VIDEO_FRAME = 'VIDEO_FRAME'
}
/* LIGHTLY CUSTOM START -> modelEnumInterfaces */
export const SampleTypeValidatorShape = [
    'CROP',
    'IMAGE',
    'VIDEO_FRAME'
];
export const SampleTypeValidator = yup.mixed().oneOf(SampleTypeValidatorShape);
export const SampleTypeStringifyShapeProperties = {};
export const SampleTypeStringifyShape: StringSchema = {
    title: 'SampleType Stringifier',
    type: 'string'
};
export const SampleTypeStringify = (typeof process === 'object' && String(process) === '[object process]') ?
    fastJSON.build(SampleTypeStringifyShape)
    :
        () => { throw new Error('fast-json-stringify only works in nodejs'); };
/* LIGHTLY CUSTOM END */
export function SampleTypeFromJSON(json: any): SampleType {
    return SampleTypeFromJSONTyped(json, false);
}
export function SampleTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): SampleType {
    return json as SampleType;
}
export function SampleTypeToJSON(value?: SampleType | null): any {
    return value as any;
}
