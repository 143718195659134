export const sample = {
	'datasetId-id': 'Sample link',
	'datasetId': 'Part of',
	'createdAt': 'Created at',
	'lastModifiedAt': 'Last modified',
	'metaData.sizeInBytes': 'File size',
	'metaData.size': 'File size',
	'fileName': 'File name',

	'metaData.shape': 'Aspect ratio',
	'metaData.aspectRatio': 'Aspect ratio',
	'metaData.shape[0]': 'Height',
	'metaData.imgHeight': 'Height',
	'metaData.shape[1]': 'Width',
	'metaData.imgWidth': 'Width',
	'metaData.sharpness': 'Sharpness',
	'metaData.uniformRowRatio': 'Uniform row ratio',
	'metaData.snr': 'Signal to noise ratio',

	'metaData.mean[0]': 'Red channel mean',
	'metaData.meanR': 'Red channel mean',
	'metaData.mean[1]': 'Green channel mean',
	'metaData.meanG': 'Green channel mean',
	'metaData.mean[2]': 'Blue channel mean',
	'metaData.meanB': 'Blue channel mean',

	'exif.Make': 'Make',
	'exif.Model': 'Model',
	'exif.DateTimeOriginal': 'Originally created at',
	'exif.ExposureTime': 'Exposure time',
	'exif.Copyright': 'Copyright',
	'exif.Software': 'Software',

	'dyn.predictions': 'Predictions',
	'dyn.activeLearningv2': 'Active Learning',
	'dyn.userDefined': 'Custom metadata',

	//custom metadata fns
	'luminance': 'Luminance',
	'contrast': 'Contrast',
}
export default sample;