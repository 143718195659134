
import i18n, { i18n as Ii18n } from 'i18next';
import { initReactI18next } from 'react-i18next';

import { dict as en } from './en/en.json'


export const langResources = {
	en,
}

export const getUserLanguage = (): string => {
	return 'en'
}

export const setupI18N = (): Ii18n => {
	// eslint-disable-next-line import/no-named-as-default-member
	i18n.use(initReactI18next) // passes i18n down to react-i18next
		.init({
			returnNull: false,
			resources: langResources,
			lng: getUserLanguage(),
			fallbackLng: 'en',
			keySeparator: '::',
			interpolation: { escapeValue: false },
		})
		.then()
		.catch((err) => {
			console.error('error initializing i18n', err);
		});

	return i18n;
}
setupI18N();

export default i18n;