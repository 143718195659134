/* tslint:disable */
/* eslint-disable */
/**
 * Lightly API
 * Lightly.ai enables you to do self-supervised learning in an easy and intuitive way. The lightly.ai OpenAPI spec defines how one can interact with our REST API to unleash the full potential of lightly.ai
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: support@lightly.ai
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
// SRC: https://github.com/OpenAPITools/openapi-generator/blob/master/modules/openapi-generator/src/main/resources/typescript-fetch/modelGeneric.mustache
// synched 02.02.2023
import * as yup from 'yup';
import typia from 'typia';
import * as fastJSON from 'fast-json-stringify';
import { Options, ArraySchema, ObjectSchema } from 'fast-json-stringify';
import { exists, mapValues } from '../runtime';
import type { TeamRole } from './TeamRole';
import { TeamRoleFromJSON, TeamRoleFromJSONTyped, TeamRoleToJSON, } from './TeamRole';
/*
causes circular dependendies
*/
// SRC: https://github.com/OpenAPITools/openapi-generator/blob/master/modules/openapi-generator/src/main/resources/typescript-fetch/modelGenericInterfaces.mustache
// synced: 17.07.2023
/**
 *
 * @export
 * @interface CreateTeamMembershipRequest
 *
 */
// create custom validators for the regexes
export interface CreateTeamMembershipRequest {
    /**
     * email of the user
     * #type string
     * #memberof CreateTeamMembershipRequest
        
        
    * @format email
         */
    email: string;
    /**
     *
     * #type TeamRole
     * #memberof CreateTeamMembershipRequest
        
        
         */
    role: TeamRole;
}
/**
 * Check if a given object implements the CreateTeamMembershipRequest interface.
 */
export function instanceOfCreateTeamMembershipRequest(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "email" in value;
    isInstance = isInstance && "role" in value;
    return isInstance;
}
/* LIGHTLY CUSTOM START -> modelGeneric */
import { TeamRoleValidator, TeamRoleStringifyShape, TeamRoleStringifyShapeProperties, } from './TeamRole';
export const CreateTeamMembershipRequestTypiaAssertEquals = (input: any) => {
    return ((input: any): CreateTeamMembershipRequest => {
        const __is = (input: any, _exceptionable: boolean = true): input is CreateTeamMembershipRequest => {
            const $is_email = (typia.createAssertEquals as any).is_email;
            const $io0 = (input: any, _exceptionable: boolean = true): boolean => "string" === typeof input.email && $is_email(input.email) && ("OWNER" === input.role || "ADMIN" === input.role || "MEMBER" === input.role || "SERVICEACCOUNT" === input.role) && (2 === Object.keys(input).length || Object.keys(input).every((key: any) => {
                if (["email", "role"].some((prop: any) => key === prop))
                    return true;
                const value = input[key];
                if (undefined === value)
                    return true;
                return false;
            }));
            return "object" === typeof input && null !== input && $io0(input, true);
        };
        if (false === __is(input))
            ((input: any, _path: string, _exceptionable: boolean = true): input is CreateTeamMembershipRequest => {
                const $guard = (typia.createAssertEquals as any).guard;
                const $is_email = (typia.createAssertEquals as any).is_email;
                const $join = (typia.createAssertEquals as any).join;
                const $ao0 = (input: any, _path: string, _exceptionable: boolean = true): boolean => ("string" === typeof input.email && ($is_email(input.email) || $guard(_exceptionable, {
                    path: _path + ".email",
                    expected: "string (@format email)",
                    value: input.email
                })) || $guard(_exceptionable, {
                    path: _path + ".email",
                    expected: "string",
                    value: input.email
                })) && ("OWNER" === input.role || "ADMIN" === input.role || "MEMBER" === input.role || "SERVICEACCOUNT" === input.role || $guard(_exceptionable, {
                    path: _path + ".role",
                    expected: "(\"ADMIN\" | \"MEMBER\" | \"OWNER\" | \"SERVICEACCOUNT\")",
                    value: input.role
                })) && (2 === Object.keys(input).length || (false === _exceptionable || Object.keys(input).every((key: any) => {
                    if (["email", "role"].some((prop: any) => key === prop))
                        return true;
                    const value = input[key];
                    if (undefined === value)
                        return true;
                    return $guard(_exceptionable, {
                        path: _path + $join(key),
                        expected: "undefined",
                        value: value
                    });
                })));
                return ("object" === typeof input && null !== input || $guard(true, {
                    path: _path + "",
                    expected: "CreateTeamMembershipRequest",
                    value: input
                })) && $ao0(input, _path + "", true) || $guard(true, {
                    path: _path + "",
                    expected: "CreateTeamMembershipRequest",
                    value: input
                });
            })(input, "$input", true);
        return input;
    })(input);
};
export const CreateTeamMembershipRequestTypiaAssertStringify = (input: CreateTeamMembershipRequest): string => {
    return ((input: any): string => { const assert = (input: any): CreateTeamMembershipRequest => {
        const __is = (input: any): input is CreateTeamMembershipRequest => {
            const $is_email = (typia.createAssertStringify as any).is_email;
            const $io0 = (input: any): boolean => "string" === typeof input.email && $is_email(input.email) && ("OWNER" === input.role || "ADMIN" === input.role || "MEMBER" === input.role || "SERVICEACCOUNT" === input.role);
            return "object" === typeof input && null !== input && $io0(input);
        };
        if (false === __is(input))
            ((input: any, _path: string, _exceptionable: boolean = true): input is CreateTeamMembershipRequest => {
                const $guard = (typia.createAssertStringify as any).guard;
                const $is_email = (typia.createAssertStringify as any).is_email;
                const $ao0 = (input: any, _path: string, _exceptionable: boolean = true): boolean => ("string" === typeof input.email && ($is_email(input.email) || $guard(_exceptionable, {
                    path: _path + ".email",
                    expected: "string (@format email)",
                    value: input.email
                })) || $guard(_exceptionable, {
                    path: _path + ".email",
                    expected: "string",
                    value: input.email
                })) && ("OWNER" === input.role || "ADMIN" === input.role || "MEMBER" === input.role || "SERVICEACCOUNT" === input.role || $guard(_exceptionable, {
                    path: _path + ".role",
                    expected: "(\"ADMIN\" | \"MEMBER\" | \"OWNER\" | \"SERVICEACCOUNT\")",
                    value: input.role
                }));
                return ("object" === typeof input && null !== input || $guard(true, {
                    path: _path + "",
                    expected: "CreateTeamMembershipRequest",
                    value: input
                })) && $ao0(input, _path + "", true) || $guard(true, {
                    path: _path + "",
                    expected: "CreateTeamMembershipRequest",
                    value: input
                });
            })(input, "$input", true);
        return input;
    }; const stringify = (input: CreateTeamMembershipRequest): string => {
        const $string = (typia.createAssertStringify as any).string;
        const $throws = (typia.createAssertStringify as any).throws;
        const $is_email = (typia.createAssertStringify as any).is_email;
        const $so0 = (input: any): any => `{"email":${$string(input.email)},"role":${(() => {
            if ("string" === typeof input.role)
                return $string(input.role);
            if ("string" === typeof input.role)
                return "\"" + input.role + "\"";
            $throws({
                expected: "(\"ADMIN\" | \"MEMBER\" | \"OWNER\" | \"SERVICEACCOUNT\")",
                value: input.role
            });
        })()}}`;
        return $so0(input);
    }; return stringify(assert(input)); })(input);
};
export const CreateTeamMembershipRequestValidatorShape = {
    'email': yup.string().email().defined(),
    'role': TeamRoleValidator.defined(),
};
export const CreateTeamMembershipRequestValidator = yup.object().shape(CreateTeamMembershipRequestValidatorShape);
export const CreateTeamMembershipRequestStringifyShapeProperties: ObjectSchema['properties'] = {
    'email': {
        'type': 'string',
    } as unknown as fastJSON.StringSchema,
    'role': TeamRoleStringifyShape,
};
export const CreateTeamMembershipRequestStringifyShape: ObjectSchema = {
    title: 'CreateTeamMembershipRequest Stringifier',
    type: 'object',
    properties: {
        ...CreateTeamMembershipRequestStringifyShapeProperties
    },
    required: [
        'email',
        'role',
    ],
};
export const CreateTeamMembershipRequestStringifyCompiled = (typeof process === 'object' && String(process) === '[object process]') ?
    fastJSON.build(CreateTeamMembershipRequestStringifyShape, {
        ajv: {
            // since we are already doing this with yup, lets skip
            validateSchema: false,
            ownProperties: true,
            validateFormats: false,
        },
        largeArrayMechanism: 'json-stringify',
        largeArraySize: 1000,
    } as Options)
    :
        () => { throw new Error('fast-json-stringify only works in nodejs'); };
export const CreateTeamMembershipRequestStringify: (data: CreateTeamMembershipRequest) => string = CreateTeamMembershipRequestStringifyCompiled;
/* LIGHTLY CUSTOM END -> Generic */
export function CreateTeamMembershipRequestFromJSON(json: any): CreateTeamMembershipRequest {
    return CreateTeamMembershipRequestFromJSONTyped(json, false);
}
export function CreateTeamMembershipRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateTeamMembershipRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    /* causes circular depdendencies
*/
    return {
        'email': json['email'],
        'role': TeamRoleFromJSON(json['role']),
    };
}
export function CreateTeamMembershipRequestToJSON(value?: CreateTeamMembershipRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'email': value.email,
        'role': TeamRoleToJSON(value.role),
    };
}
