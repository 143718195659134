import { subject } from '@casl/ability';

import { UserType, DatasetData, DockerRunScheduledData } from '@lightly/api-spec';
import { ActionsTeamRole, Features, PERMISSIONS, Permission2Action, ROLES, SubjectsTeamRole, defineTeamAbilityFor, defineTeamRoleAbility, isUserAllowedTo } from '@lightly/common';

import { useAuth0 } from '../contexts/auth0-context';

import { useGetDatasetById, useGetDockerRunByScheduledId, useGetProfileOfLoggedInUser, useGetProfileOfUserById } from './api';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const useDatasetOwner = (userId?: string) => {
	const { user } = useAuth0();
	return useGetProfileOfUserById(userId && userId !== user?.sub ? { userId } : null);
};


export const useHasPermissionOnDataset = (datasetId: string, permission: PERMISSIONS): boolean => {
	const { data: datasetData } = useGetDatasetById({ datasetId });
	if (datasetData && datasetData.accessRole) {
		if (Number(datasetData.accessRole) & permission) {
			return true;
		}
	}
	return false;
};

export const useIsRoleOnDataset = (datasetId: string, role: ROLES): boolean => {
	const { data: datasetData } = useGetDatasetById({ datasetId });
	if (datasetData && datasetData.accessRole) {
		if (Number(datasetData.accessRole) === role) {
			return true;
		}
	}
	return false;
};



export const useHasPermissionToDataset = (datasetIdOrDataset: string | DatasetData | undefined, permission: PERMISSIONS) => {
	const { data: activeUserData, isLoading: activeUserIsLoading, error: activeUserError } = useGetProfileOfLoggedInUser(undefined);
	const { data: datasetData, isLoading: datasetIsLoading, error: datasetError } = useGetDatasetById(
		datasetIdOrDataset && typeof datasetIdOrDataset === 'string' ?
			{ datasetId: datasetIdOrDataset }
			:
			null,
	);
	const dataset = datasetIdOrDataset && typeof datasetIdOrDataset !== 'string' ? datasetIdOrDataset : datasetData ;
	const { data: datasetOwnerData, isLoading: datasetOwnerIsLoading, error: datasetOwnerError } = useDatasetOwner(dataset?.userId);

	const isLoading = activeUserIsLoading || datasetIsLoading || datasetOwnerIsLoading;
	let error = activeUserError || datasetError || datasetOwnerError;

	let granted = false;
	if (!isLoading && dataset && dataset.accessRole) {
		// check if owner
		if (dataset.userId === activeUserData?.id) {
			granted = true;
		}
		// check if it was shared with user
		else if (Number(dataset.accessRole) & permission) {
			granted = true;
		}
		// check if user is team member and has permission
		else if (activeUserData && datasetOwnerData) {
			try {
				const ability = defineTeamAbilityFor(activeUserData, datasetOwnerData);
				granted = ability.can(Permission2Action[permission], subject('DatasetData', dataset));
			}
			catch (err) {
				if (err instanceof Error) {
					error = err
				}
				granted = false;
			}
		}
	}

	return {
		isLoading,
		error,
		granted,
	}
};


export const useHasPermissionToDockerRun = (datasetId: string, permission: PERMISSIONS) => {
	return useHasPermissionToDataset(datasetId, permission);
}



export const useHasPermissionToScheduledRun = (scheduledIdOrScheduledRun: string | DockerRunScheduledData | undefined, permission: PERMISSIONS) => {
	const { data: activeUserData, isLoading: activeUserIsLoading, error: activeUserError } = useGetProfileOfLoggedInUser(undefined);
	const { data: scheduledRunData, isLoading: scheduledRunIsLoading, error: scheduledRunError } = useGetDockerRunByScheduledId(
		scheduledIdOrScheduledRun && typeof scheduledIdOrScheduledRun === 'string' ?
			{ scheduledId: scheduledIdOrScheduledRun }
			:
			null,
	);
	const scheduledRun = scheduledIdOrScheduledRun && typeof scheduledIdOrScheduledRun !== 'string' ? scheduledIdOrScheduledRun : scheduledRunData;
	const { data: scheduledRunOwnerData, isLoading: scheduledRunOwnerIsLoading, error: scheduledRunOwnerError } = useDatasetOwner(scheduledRun?.userId);

	const isLoading = activeUserIsLoading || scheduledRunIsLoading || scheduledRunOwnerIsLoading;
	let error = activeUserError || scheduledRunError || scheduledRunOwnerError;

	let granted = false;
	if (!isLoading && scheduledRun) {
		// check if owner
		if (scheduledRun.userId === activeUserData?.id) {
			granted = true;
		}
		// check if user is team member and has permission
		else if (activeUserData && scheduledRunOwnerData) {
			try {
				const ability = defineTeamAbilityFor(activeUserData, scheduledRunOwnerData);
				granted = ability.can(Permission2Action[permission], subject('DockerRunScheduledData', scheduledRun));
			}
			catch (err) {
				if (err instanceof Error) {
					error = err
				}
				granted = false;
			}
		}
	}

	return {
		isLoading,
		error,
		granted,
	}
};




export const useHasPermissionToDatasource = (datasetId: string, permission: PERMISSIONS) => {
	const { data: activeUserData, isLoading: activeUserIsLoading, error: activeUserError } = useGetProfileOfLoggedInUser(undefined);
	const { data: datasetData, isLoading: datasetIsLoading, error: datasetError } = useGetDatasetById(datasetId ? { datasetId } : null);
	const { data: datasetOwnerData, isLoading: datasetOwnerIsLoading, error: datasetOwnerError } = useDatasetOwner(datasetData?.userId);

	const isLoading = activeUserIsLoading || datasetIsLoading || datasetOwnerIsLoading;
	let error = activeUserError || datasetError || datasetOwnerError;

	let granted = false;
	if (!isLoading && datasetData && datasetData.accessRole) {
		// check if owner
		if (datasetData.userId === activeUserData?.id) {
			granted = true;
		}
		// check if user is team member and has permission
		else if (activeUserData && datasetOwnerData) {
			try {
				const ability = defineTeamAbilityFor(activeUserData, datasetOwnerData);
				granted = ability.can(Permission2Action[permission], subject('DatasourceConfig', datasetData));
			}
			catch (err) {
				if (err instanceof Error) {
					error = err
				}
				granted = false;
			}
		}
	}

	return {
		isLoading,
		error,
		granted,
	}
}


export const useHasTeamRoleAbility = (action: ActionsTeamRole, subject: SubjectsTeamRole): [boolean, boolean, Error | undefined] => {
	const { data: profileData, error: profileError, isLoading: profileIsLoading } = useGetProfileOfLoggedInUser(undefined);

	let permitted = false;
	if (profileData) {
		try {
			const ability = defineTeamRoleAbility(profileData);
			permitted = ability.can(action, subject);
		}
		catch (err) {
			permitted = false;
		}
	}

	return [permitted, profileIsLoading, profileError];
};


// if feature is not defined, we don't query the API.
// This is useful for the initial loading of the app when we don't know yet if the user is logged in or if he has a token
export const useIsFeatureActivated = (feature?: Features): [boolean, boolean, Error | undefined] => {
	const { data: profileData, error: profileError, isLoading: profileIsLoading } = useGetProfileOfLoggedInUser(feature !== undefined ? undefined : null);
	let featureActive = false;
	if (feature !== undefined && profileData) {
		const userType = profileData?.userType || UserType.FREE
		featureActive = isUserAllowedTo(feature, userType)
	}

	return [featureActive, profileIsLoading, profileError];
};
