/* tslint:disable */
/* eslint-disable */
/**
 * Lightly API
 * Lightly.ai enables you to do self-supervised learning in an easy and intuitive way. The lightly.ai OpenAPI spec defines how one can interact with our REST API to unleash the full potential of lightly.ai
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: support@lightly.ai
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
// SRC: https://github.com/OpenAPITools/openapi-generator/blob/master/modules/openapi-generator/src/main/resources/typescript-fetch/modelGeneric.mustache
// synched 02.02.2023
import * as yup from 'yup';
import typia from 'typia';
import * as fastJSON from 'fast-json-stringify';
import { Options, ArraySchema, ObjectSchema } from 'fast-json-stringify';
import { exists, mapValues } from '../runtime';
import type { PredictionTaskSchema } from './PredictionTaskSchema';
import { PredictionTaskSchemaFromJSON, PredictionTaskSchemaFromJSONTyped, PredictionTaskSchemaToJSON, } from './PredictionTaskSchema';
/*
causes circular dependendies
*/
// SRC: https://github.com/OpenAPITools/openapi-generator/blob/master/modules/openapi-generator/src/main/resources/typescript-fetch/modelGenericInterfaces.mustache
// synced: 17.07.2023
/**
 *
 * @export
 * @interface PredictionTaskSchemas
 *
 */
// create custom validators for the regexes
export interface PredictionTaskSchemas {
    /**
     * unix timestamp in milliseconds
     * #type number
     * #memberof PredictionTaskSchemas
        
    * @minimum 0
            
         */
    predictionUUIDTimestamp: number;
    /**
     *
     * #type Array<PredictionTaskSchema>
     * #memberof PredictionTaskSchemas
        
        
         */
    schemas: Array<PredictionTaskSchema>;
}
/**
 * Check if a given object implements the PredictionTaskSchemas interface.
 */
export function instanceOfPredictionTaskSchemas(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "predictionUUIDTimestamp" in value;
    isInstance = isInstance && "schemas" in value;
    return isInstance;
}
/* LIGHTLY CUSTOM START -> modelGeneric */
import { PredictionTaskSchemaValidator, PredictionTaskSchemaStringifyShape, PredictionTaskSchemaStringifyShapeProperties, } from './PredictionTaskSchema';
export const PredictionTaskSchemasTypiaAssertEquals = (input: any) => {
    return ((input: any): PredictionTaskSchemas => {
        const __is = (input: any, _exceptionable: boolean = true): input is PredictionTaskSchemas => {
            const $is_custom = (typia.createAssertEquals as any).is_custom;
            const $join = (typia.createAssertEquals as any).join;
            const $io0 = (input: any, _exceptionable: boolean = true): boolean => "number" === typeof input.predictionUUIDTimestamp && 0 <= input.predictionUUIDTimestamp && (Array.isArray(input.schemas) && input.schemas.every((elem: any, _index1: number) => "object" === typeof elem && null !== elem && $iu0(elem, true && _exceptionable))) && (2 === Object.keys(input).length || Object.keys(input).every((key: any) => {
                if (["predictionUUIDTimestamp", "schemas"].some((prop: any) => key === prop))
                    return true;
                const value = input[key];
                if (undefined === value)
                    return true;
                return false;
            }));
            const $io1 = (input: any, _exceptionable: boolean = true): boolean => "CLASSIFICATION" === input.type && (Array.isArray(input.categories) && input.categories.every((elem: any, _index2: number) => "object" === typeof elem && null !== elem && $io2(elem, true && _exceptionable))) && ("string" === typeof input.name && $is_custom("pattern_name", "string", "", input.name)) && (3 === Object.keys(input).length || Object.keys(input).every((key: any) => {
                if (["type", "categories", "name"].some((prop: any) => key === prop))
                    return true;
                const value = input[key];
                if (undefined === value)
                    return true;
                return false;
            }));
            const $io2 = (input: any, _exceptionable: boolean = true): boolean => "number" === typeof input.id && 0 <= input.id && ("string" === typeof input.name && 1 <= input.name.length) && (2 === Object.keys(input).length || Object.keys(input).every((key: any) => {
                if (["id", "name"].some((prop: any) => key === prop))
                    return true;
                const value = input[key];
                if (undefined === value)
                    return true;
                return false;
            }));
            const $io3 = (input: any, _exceptionable: boolean = true): boolean => "INSTANCE_SEGMENTATION" === input.type && (Array.isArray(input.categories) && input.categories.every((elem: any, _index3: number) => "object" === typeof elem && null !== elem && $io2(elem, true && _exceptionable))) && ("string" === typeof input.name && $is_custom("pattern_name", "string", "", input.name)) && (3 === Object.keys(input).length || Object.keys(input).every((key: any) => {
                if (["type", "categories", "name"].some((prop: any) => key === prop))
                    return true;
                const value = input[key];
                if (undefined === value)
                    return true;
                return false;
            }));
            const $io4 = (input: any, _exceptionable: boolean = true): boolean => "KEYPOINT_DETECTION" === input.type && (Array.isArray(input.categories) && input.categories.every((elem: any, _index4: number) => "object" === typeof elem && null !== elem && $io5(elem, true && _exceptionable))) && ("string" === typeof input.name && $is_custom("pattern_name", "string", "", input.name)) && (3 === Object.keys(input).length || Object.keys(input).every((key: any) => {
                if (["type", "categories", "name"].some((prop: any) => key === prop))
                    return true;
                const value = input[key];
                if (undefined === value)
                    return true;
                return false;
            }));
            const $io5 = (input: any, _exceptionable: boolean = true): boolean => "number" === typeof input.id && 0 <= input.id && ("string" === typeof input.name && 1 <= input.name.length) && (undefined === input.keypointNames || Array.isArray(input.keypointNames) && input.keypointNames.every((elem: any, _index5: number) => "string" === typeof elem)) && (undefined === input.keypointSkeleton || Array.isArray(input.keypointSkeleton) && input.keypointSkeleton.every((elem: any, _index6: number) => "object" === typeof elem && null !== elem && $io6(elem, true && _exceptionable))) && (2 === Object.keys(input).length || Object.keys(input).every((key: any) => {
                if (["id", "name", "keypointNames", "keypointSkeleton"].some((prop: any) => key === prop))
                    return true;
                const value = input[key];
                if (undefined === value)
                    return true;
                return false;
            }));
            const $io6 = (input: any, _exceptionable: boolean = true): boolean => "number" === typeof input.length && Object.keys(input).every((key: any) => {
                if (["length"].some((prop: any) => key === prop))
                    return true;
                const value = input[key];
                if (undefined === value)
                    return true;
                if (RegExp(/^[+-]?\d+(?:\.\d+)?(?:[eE][+-]?\d+)?$/).test(key))
                    return "number" === typeof value;
                return false;
            });
            const $io7 = (input: any, _exceptionable: boolean = true): boolean => "OBJECT_DETECTION" === input.type && (Array.isArray(input.categories) && input.categories.every((elem: any, _index7: number) => "object" === typeof elem && null !== elem && $io2(elem, true && _exceptionable))) && ("string" === typeof input.name && $is_custom("pattern_name", "string", "", input.name)) && (3 === Object.keys(input).length || Object.keys(input).every((key: any) => {
                if (["type", "categories", "name"].some((prop: any) => key === prop))
                    return true;
                const value = input[key];
                if (undefined === value)
                    return true;
                return false;
            }));
            const $io8 = (input: any, _exceptionable: boolean = true): boolean => "PredictionTaskSchemaKeypoint" === input.type && (Array.isArray(input.categories) && input.categories.every((elem: any, _index8: number) => "object" === typeof elem && null !== elem && $io5(elem, true && _exceptionable))) && ("string" === typeof input.name && $is_custom("pattern_name", "string", "", input.name)) && (3 === Object.keys(input).length || Object.keys(input).every((key: any) => {
                if (["type", "categories", "name"].some((prop: any) => key === prop))
                    return true;
                const value = input[key];
                if (undefined === value)
                    return true;
                return false;
            }));
            const $io9 = (input: any, _exceptionable: boolean = true): boolean => "PredictionTaskSchemaSimple" === input.type && (Array.isArray(input.categories) && input.categories.every((elem: any, _index9: number) => "object" === typeof elem && null !== elem && $io2(elem, true && _exceptionable))) && ("string" === typeof input.name && $is_custom("pattern_name", "string", "", input.name)) && (3 === Object.keys(input).length || Object.keys(input).every((key: any) => {
                if (["type", "categories", "name"].some((prop: any) => key === prop))
                    return true;
                const value = input[key];
                if (undefined === value)
                    return true;
                return false;
            }));
            const $io10 = (input: any, _exceptionable: boolean = true): boolean => "SEMANTIC_SEGMENTATION" === input.type && (Array.isArray(input.categories) && input.categories.every((elem: any, _index10: number) => "object" === typeof elem && null !== elem && $io2(elem, true && _exceptionable))) && ("string" === typeof input.name && $is_custom("pattern_name", "string", "", input.name)) && (3 === Object.keys(input).length || Object.keys(input).every((key: any) => {
                if (["type", "categories", "name"].some((prop: any) => key === prop))
                    return true;
                const value = input[key];
                if (undefined === value)
                    return true;
                return false;
            }));
            const $iu0 = (input: any, _exceptionable: boolean = true): any => (() => {
                if ("SEMANTIC_SEGMENTATION" === input.type)
                    return $io10(input, true && _exceptionable);
                if ("PredictionTaskSchemaSimple" === input.type)
                    return $io9(input, true && _exceptionable);
                if ("PredictionTaskSchemaKeypoint" === input.type)
                    return $io8(input, true && _exceptionable);
                if ("OBJECT_DETECTION" === input.type)
                    return $io7(input, true && _exceptionable);
                if ("KEYPOINT_DETECTION" === input.type)
                    return $io4(input, true && _exceptionable);
                if ("INSTANCE_SEGMENTATION" === input.type)
                    return $io3(input, true && _exceptionable);
                if ("CLASSIFICATION" === input.type)
                    return $io1(input, true && _exceptionable);
                return false;
            })();
            return "object" === typeof input && null !== input && $io0(input, true);
        };
        if (false === __is(input))
            ((input: any, _path: string, _exceptionable: boolean = true): input is PredictionTaskSchemas => {
                const $guard = (typia.createAssertEquals as any).guard;
                const $join = (typia.createAssertEquals as any).join;
                const $is_custom = (typia.createAssertEquals as any).is_custom;
                const $ao0 = (input: any, _path: string, _exceptionable: boolean = true): boolean => ("number" === typeof input.predictionUUIDTimestamp && (0 <= input.predictionUUIDTimestamp || $guard(_exceptionable, {
                    path: _path + ".predictionUUIDTimestamp",
                    expected: "number (@minimum 0)",
                    value: input.predictionUUIDTimestamp
                })) || $guard(_exceptionable, {
                    path: _path + ".predictionUUIDTimestamp",
                    expected: "number",
                    value: input.predictionUUIDTimestamp
                })) && ((Array.isArray(input.schemas) || $guard(_exceptionable, {
                    path: _path + ".schemas",
                    expected: "Array<PredictionTaskSchema>",
                    value: input.schemas
                })) && input.schemas.every((elem: any, _index1: number) => ("object" === typeof elem && null !== elem || $guard(_exceptionable, {
                    path: _path + ".schemas[" + _index1 + "]",
                    expected: "({ type: \"CLASSIFICATION\"; } & PredictionTaskSchemaSimple | { type: \"INSTANCE_SEGMENTATION\"; } & PredictionTaskSchemaSimple | { type: \"KEYPOINT_DETECTION\"; } & PredictionTaskSchemaKeypoint | { type: \"OBJECT_DETECTION\"; } & PredictionTaskSchemaSimple | { type: \"PredictionTaskSchemaKeypoint\"; } & PredictionTaskSchemaKeypoint | { type: \"PredictionTaskSchemaSimple\"; } & PredictionTaskSchemaSimple | { type: \"SEMANTIC_SEGMENTATION\"; } & PredictionTaskSchemaSimple)",
                    value: elem
                })) && $au0(elem, _path + ".schemas[" + _index1 + "]", true && _exceptionable) || $guard(_exceptionable, {
                    path: _path + ".schemas[" + _index1 + "]",
                    expected: "({ type: \"CLASSIFICATION\"; } & PredictionTaskSchemaSimple | { type: \"INSTANCE_SEGMENTATION\"; } & PredictionTaskSchemaSimple | { type: \"KEYPOINT_DETECTION\"; } & PredictionTaskSchemaKeypoint | { type: \"OBJECT_DETECTION\"; } & PredictionTaskSchemaSimple | { type: \"PredictionTaskSchemaKeypoint\"; } & PredictionTaskSchemaKeypoint | { type: \"PredictionTaskSchemaSimple\"; } & PredictionTaskSchemaSimple | { type: \"SEMANTIC_SEGMENTATION\"; } & PredictionTaskSchemaSimple)",
                    value: elem
                })) || $guard(_exceptionable, {
                    path: _path + ".schemas",
                    expected: "Array<PredictionTaskSchema>",
                    value: input.schemas
                })) && (2 === Object.keys(input).length || (false === _exceptionable || Object.keys(input).every((key: any) => {
                    if (["predictionUUIDTimestamp", "schemas"].some((prop: any) => key === prop))
                        return true;
                    const value = input[key];
                    if (undefined === value)
                        return true;
                    return $guard(_exceptionable, {
                        path: _path + $join(key),
                        expected: "undefined",
                        value: value
                    });
                })));
                const $ao1 = (input: any, _path: string, _exceptionable: boolean = true): boolean => ("CLASSIFICATION" === input.type || $guard(_exceptionable, {
                    path: _path + ".type",
                    expected: "\"CLASSIFICATION\"",
                    value: input.type
                })) && ((Array.isArray(input.categories) || $guard(_exceptionable, {
                    path: _path + ".categories",
                    expected: "Array<PredictionTaskSchemaCategory>",
                    value: input.categories
                })) && input.categories.every((elem: any, _index2: number) => ("object" === typeof elem && null !== elem || $guard(_exceptionable, {
                    path: _path + ".categories[" + _index2 + "]",
                    expected: "PredictionTaskSchemaCategory",
                    value: elem
                })) && $ao2(elem, _path + ".categories[" + _index2 + "]", true && _exceptionable) || $guard(_exceptionable, {
                    path: _path + ".categories[" + _index2 + "]",
                    expected: "PredictionTaskSchemaCategory",
                    value: elem
                })) || $guard(_exceptionable, {
                    path: _path + ".categories",
                    expected: "Array<PredictionTaskSchemaCategory>",
                    value: input.categories
                })) && ("string" === typeof input.name && ($is_custom("pattern_name", "string", "", input.name) || $guard(_exceptionable, {
                    path: _path + ".name",
                    expected: "string (@pattern_name)",
                    value: input.name
                })) || $guard(_exceptionable, {
                    path: _path + ".name",
                    expected: "string",
                    value: input.name
                })) && (3 === Object.keys(input).length || (false === _exceptionable || Object.keys(input).every((key: any) => {
                    if (["type", "categories", "name"].some((prop: any) => key === prop))
                        return true;
                    const value = input[key];
                    if (undefined === value)
                        return true;
                    return $guard(_exceptionable, {
                        path: _path + $join(key),
                        expected: "undefined",
                        value: value
                    });
                })));
                const $ao2 = (input: any, _path: string, _exceptionable: boolean = true): boolean => ("number" === typeof input.id && (0 <= input.id || $guard(_exceptionable, {
                    path: _path + ".id",
                    expected: "number (@minimum 0)",
                    value: input.id
                })) || $guard(_exceptionable, {
                    path: _path + ".id",
                    expected: "number",
                    value: input.id
                })) && ("string" === typeof input.name && (1 <= input.name.length || $guard(_exceptionable, {
                    path: _path + ".name",
                    expected: "string (@minLength 1)",
                    value: input.name
                })) || $guard(_exceptionable, {
                    path: _path + ".name",
                    expected: "string",
                    value: input.name
                })) && (2 === Object.keys(input).length || (false === _exceptionable || Object.keys(input).every((key: any) => {
                    if (["id", "name"].some((prop: any) => key === prop))
                        return true;
                    const value = input[key];
                    if (undefined === value)
                        return true;
                    return $guard(_exceptionable, {
                        path: _path + $join(key),
                        expected: "undefined",
                        value: value
                    });
                })));
                const $ao3 = (input: any, _path: string, _exceptionable: boolean = true): boolean => ("INSTANCE_SEGMENTATION" === input.type || $guard(_exceptionable, {
                    path: _path + ".type",
                    expected: "\"INSTANCE_SEGMENTATION\"",
                    value: input.type
                })) && ((Array.isArray(input.categories) || $guard(_exceptionable, {
                    path: _path + ".categories",
                    expected: "Array<PredictionTaskSchemaCategory>",
                    value: input.categories
                })) && input.categories.every((elem: any, _index3: number) => ("object" === typeof elem && null !== elem || $guard(_exceptionable, {
                    path: _path + ".categories[" + _index3 + "]",
                    expected: "PredictionTaskSchemaCategory",
                    value: elem
                })) && $ao2(elem, _path + ".categories[" + _index3 + "]", true && _exceptionable) || $guard(_exceptionable, {
                    path: _path + ".categories[" + _index3 + "]",
                    expected: "PredictionTaskSchemaCategory",
                    value: elem
                })) || $guard(_exceptionable, {
                    path: _path + ".categories",
                    expected: "Array<PredictionTaskSchemaCategory>",
                    value: input.categories
                })) && ("string" === typeof input.name && ($is_custom("pattern_name", "string", "", input.name) || $guard(_exceptionable, {
                    path: _path + ".name",
                    expected: "string (@pattern_name)",
                    value: input.name
                })) || $guard(_exceptionable, {
                    path: _path + ".name",
                    expected: "string",
                    value: input.name
                })) && (3 === Object.keys(input).length || (false === _exceptionable || Object.keys(input).every((key: any) => {
                    if (["type", "categories", "name"].some((prop: any) => key === prop))
                        return true;
                    const value = input[key];
                    if (undefined === value)
                        return true;
                    return $guard(_exceptionable, {
                        path: _path + $join(key),
                        expected: "undefined",
                        value: value
                    });
                })));
                const $ao4 = (input: any, _path: string, _exceptionable: boolean = true): boolean => ("KEYPOINT_DETECTION" === input.type || $guard(_exceptionable, {
                    path: _path + ".type",
                    expected: "\"KEYPOINT_DETECTION\"",
                    value: input.type
                })) && ((Array.isArray(input.categories) || $guard(_exceptionable, {
                    path: _path + ".categories",
                    expected: "Array<PredictionTaskSchemaCategoryKeypoints>",
                    value: input.categories
                })) && input.categories.every((elem: any, _index4: number) => ("object" === typeof elem && null !== elem || $guard(_exceptionable, {
                    path: _path + ".categories[" + _index4 + "]",
                    expected: "PredictionTaskSchemaCategoryKeypoints",
                    value: elem
                })) && $ao5(elem, _path + ".categories[" + _index4 + "]", true && _exceptionable) || $guard(_exceptionable, {
                    path: _path + ".categories[" + _index4 + "]",
                    expected: "PredictionTaskSchemaCategoryKeypoints",
                    value: elem
                })) || $guard(_exceptionable, {
                    path: _path + ".categories",
                    expected: "Array<PredictionTaskSchemaCategoryKeypoints>",
                    value: input.categories
                })) && ("string" === typeof input.name && ($is_custom("pattern_name", "string", "", input.name) || $guard(_exceptionable, {
                    path: _path + ".name",
                    expected: "string (@pattern_name)",
                    value: input.name
                })) || $guard(_exceptionable, {
                    path: _path + ".name",
                    expected: "string",
                    value: input.name
                })) && (3 === Object.keys(input).length || (false === _exceptionable || Object.keys(input).every((key: any) => {
                    if (["type", "categories", "name"].some((prop: any) => key === prop))
                        return true;
                    const value = input[key];
                    if (undefined === value)
                        return true;
                    return $guard(_exceptionable, {
                        path: _path + $join(key),
                        expected: "undefined",
                        value: value
                    });
                })));
                const $ao5 = (input: any, _path: string, _exceptionable: boolean = true): boolean => ("number" === typeof input.id && (0 <= input.id || $guard(_exceptionable, {
                    path: _path + ".id",
                    expected: "number (@minimum 0)",
                    value: input.id
                })) || $guard(_exceptionable, {
                    path: _path + ".id",
                    expected: "number",
                    value: input.id
                })) && ("string" === typeof input.name && (1 <= input.name.length || $guard(_exceptionable, {
                    path: _path + ".name",
                    expected: "string (@minLength 1)",
                    value: input.name
                })) || $guard(_exceptionable, {
                    path: _path + ".name",
                    expected: "string",
                    value: input.name
                })) && (undefined === input.keypointNames || (Array.isArray(input.keypointNames) || $guard(_exceptionable, {
                    path: _path + ".keypointNames",
                    expected: "(Array<string> | undefined)",
                    value: input.keypointNames
                })) && input.keypointNames.every((elem: any, _index5: number) => "string" === typeof elem || $guard(_exceptionable, {
                    path: _path + ".keypointNames[" + _index5 + "]",
                    expected: "string",
                    value: elem
                })) || $guard(_exceptionable, {
                    path: _path + ".keypointNames",
                    expected: "(Array<string> | undefined)",
                    value: input.keypointNames
                })) && (undefined === input.keypointSkeleton || (Array.isArray(input.keypointSkeleton) || $guard(_exceptionable, {
                    path: _path + ".keypointSkeleton",
                    expected: "(Array<KeypointSkeletonConnection> | undefined)",
                    value: input.keypointSkeleton
                })) && input.keypointSkeleton.every((elem: any, _index6: number) => ("object" === typeof elem && null !== elem || $guard(_exceptionable, {
                    path: _path + ".keypointSkeleton[" + _index6 + "]",
                    expected: "KeypointSkeletonConnection",
                    value: elem
                })) && $ao6(elem, _path + ".keypointSkeleton[" + _index6 + "]", true && _exceptionable) || $guard(_exceptionable, {
                    path: _path + ".keypointSkeleton[" + _index6 + "]",
                    expected: "KeypointSkeletonConnection",
                    value: elem
                })) || $guard(_exceptionable, {
                    path: _path + ".keypointSkeleton",
                    expected: "(Array<KeypointSkeletonConnection> | undefined)",
                    value: input.keypointSkeleton
                })) && (2 === Object.keys(input).length || (false === _exceptionable || Object.keys(input).every((key: any) => {
                    if (["id", "name", "keypointNames", "keypointSkeleton"].some((prop: any) => key === prop))
                        return true;
                    const value = input[key];
                    if (undefined === value)
                        return true;
                    return $guard(_exceptionable, {
                        path: _path + $join(key),
                        expected: "undefined",
                        value: value
                    });
                })));
                const $ao6 = (input: any, _path: string, _exceptionable: boolean = true): boolean => ("number" === typeof input.length || $guard(_exceptionable, {
                    path: _path + ".length",
                    expected: "number",
                    value: input.length
                })) && (false === _exceptionable || Object.keys(input).every((key: any) => {
                    if (["length"].some((prop: any) => key === prop))
                        return true;
                    const value = input[key];
                    if (undefined === value)
                        return true;
                    if (RegExp(/^[+-]?\d+(?:\.\d+)?(?:[eE][+-]?\d+)?$/).test(key))
                        return "number" === typeof value || $guard(_exceptionable, {
                            path: _path + $join(key),
                            expected: "number",
                            value: value
                        });
                    return $guard(_exceptionable, {
                        path: _path + $join(key),
                        expected: "undefined",
                        value: value
                    });
                }));
                const $ao7 = (input: any, _path: string, _exceptionable: boolean = true): boolean => ("OBJECT_DETECTION" === input.type || $guard(_exceptionable, {
                    path: _path + ".type",
                    expected: "\"OBJECT_DETECTION\"",
                    value: input.type
                })) && ((Array.isArray(input.categories) || $guard(_exceptionable, {
                    path: _path + ".categories",
                    expected: "Array<PredictionTaskSchemaCategory>",
                    value: input.categories
                })) && input.categories.every((elem: any, _index7: number) => ("object" === typeof elem && null !== elem || $guard(_exceptionable, {
                    path: _path + ".categories[" + _index7 + "]",
                    expected: "PredictionTaskSchemaCategory",
                    value: elem
                })) && $ao2(elem, _path + ".categories[" + _index7 + "]", true && _exceptionable) || $guard(_exceptionable, {
                    path: _path + ".categories[" + _index7 + "]",
                    expected: "PredictionTaskSchemaCategory",
                    value: elem
                })) || $guard(_exceptionable, {
                    path: _path + ".categories",
                    expected: "Array<PredictionTaskSchemaCategory>",
                    value: input.categories
                })) && ("string" === typeof input.name && ($is_custom("pattern_name", "string", "", input.name) || $guard(_exceptionable, {
                    path: _path + ".name",
                    expected: "string (@pattern_name)",
                    value: input.name
                })) || $guard(_exceptionable, {
                    path: _path + ".name",
                    expected: "string",
                    value: input.name
                })) && (3 === Object.keys(input).length || (false === _exceptionable || Object.keys(input).every((key: any) => {
                    if (["type", "categories", "name"].some((prop: any) => key === prop))
                        return true;
                    const value = input[key];
                    if (undefined === value)
                        return true;
                    return $guard(_exceptionable, {
                        path: _path + $join(key),
                        expected: "undefined",
                        value: value
                    });
                })));
                const $ao8 = (input: any, _path: string, _exceptionable: boolean = true): boolean => ("PredictionTaskSchemaKeypoint" === input.type || $guard(_exceptionable, {
                    path: _path + ".type",
                    expected: "\"PredictionTaskSchemaKeypoint\"",
                    value: input.type
                })) && ((Array.isArray(input.categories) || $guard(_exceptionable, {
                    path: _path + ".categories",
                    expected: "Array<PredictionTaskSchemaCategoryKeypoints>",
                    value: input.categories
                })) && input.categories.every((elem: any, _index8: number) => ("object" === typeof elem && null !== elem || $guard(_exceptionable, {
                    path: _path + ".categories[" + _index8 + "]",
                    expected: "PredictionTaskSchemaCategoryKeypoints",
                    value: elem
                })) && $ao5(elem, _path + ".categories[" + _index8 + "]", true && _exceptionable) || $guard(_exceptionable, {
                    path: _path + ".categories[" + _index8 + "]",
                    expected: "PredictionTaskSchemaCategoryKeypoints",
                    value: elem
                })) || $guard(_exceptionable, {
                    path: _path + ".categories",
                    expected: "Array<PredictionTaskSchemaCategoryKeypoints>",
                    value: input.categories
                })) && ("string" === typeof input.name && ($is_custom("pattern_name", "string", "", input.name) || $guard(_exceptionable, {
                    path: _path + ".name",
                    expected: "string (@pattern_name)",
                    value: input.name
                })) || $guard(_exceptionable, {
                    path: _path + ".name",
                    expected: "string",
                    value: input.name
                })) && (3 === Object.keys(input).length || (false === _exceptionable || Object.keys(input).every((key: any) => {
                    if (["type", "categories", "name"].some((prop: any) => key === prop))
                        return true;
                    const value = input[key];
                    if (undefined === value)
                        return true;
                    return $guard(_exceptionable, {
                        path: _path + $join(key),
                        expected: "undefined",
                        value: value
                    });
                })));
                const $ao9 = (input: any, _path: string, _exceptionable: boolean = true): boolean => ("PredictionTaskSchemaSimple" === input.type || $guard(_exceptionable, {
                    path: _path + ".type",
                    expected: "\"PredictionTaskSchemaSimple\"",
                    value: input.type
                })) && ((Array.isArray(input.categories) || $guard(_exceptionable, {
                    path: _path + ".categories",
                    expected: "Array<PredictionTaskSchemaCategory>",
                    value: input.categories
                })) && input.categories.every((elem: any, _index9: number) => ("object" === typeof elem && null !== elem || $guard(_exceptionable, {
                    path: _path + ".categories[" + _index9 + "]",
                    expected: "PredictionTaskSchemaCategory",
                    value: elem
                })) && $ao2(elem, _path + ".categories[" + _index9 + "]", true && _exceptionable) || $guard(_exceptionable, {
                    path: _path + ".categories[" + _index9 + "]",
                    expected: "PredictionTaskSchemaCategory",
                    value: elem
                })) || $guard(_exceptionable, {
                    path: _path + ".categories",
                    expected: "Array<PredictionTaskSchemaCategory>",
                    value: input.categories
                })) && ("string" === typeof input.name && ($is_custom("pattern_name", "string", "", input.name) || $guard(_exceptionable, {
                    path: _path + ".name",
                    expected: "string (@pattern_name)",
                    value: input.name
                })) || $guard(_exceptionable, {
                    path: _path + ".name",
                    expected: "string",
                    value: input.name
                })) && (3 === Object.keys(input).length || (false === _exceptionable || Object.keys(input).every((key: any) => {
                    if (["type", "categories", "name"].some((prop: any) => key === prop))
                        return true;
                    const value = input[key];
                    if (undefined === value)
                        return true;
                    return $guard(_exceptionable, {
                        path: _path + $join(key),
                        expected: "undefined",
                        value: value
                    });
                })));
                const $ao10 = (input: any, _path: string, _exceptionable: boolean = true): boolean => ("SEMANTIC_SEGMENTATION" === input.type || $guard(_exceptionable, {
                    path: _path + ".type",
                    expected: "\"SEMANTIC_SEGMENTATION\"",
                    value: input.type
                })) && ((Array.isArray(input.categories) || $guard(_exceptionable, {
                    path: _path + ".categories",
                    expected: "Array<PredictionTaskSchemaCategory>",
                    value: input.categories
                })) && input.categories.every((elem: any, _index10: number) => ("object" === typeof elem && null !== elem || $guard(_exceptionable, {
                    path: _path + ".categories[" + _index10 + "]",
                    expected: "PredictionTaskSchemaCategory",
                    value: elem
                })) && $ao2(elem, _path + ".categories[" + _index10 + "]", true && _exceptionable) || $guard(_exceptionable, {
                    path: _path + ".categories[" + _index10 + "]",
                    expected: "PredictionTaskSchemaCategory",
                    value: elem
                })) || $guard(_exceptionable, {
                    path: _path + ".categories",
                    expected: "Array<PredictionTaskSchemaCategory>",
                    value: input.categories
                })) && ("string" === typeof input.name && ($is_custom("pattern_name", "string", "", input.name) || $guard(_exceptionable, {
                    path: _path + ".name",
                    expected: "string (@pattern_name)",
                    value: input.name
                })) || $guard(_exceptionable, {
                    path: _path + ".name",
                    expected: "string",
                    value: input.name
                })) && (3 === Object.keys(input).length || (false === _exceptionable || Object.keys(input).every((key: any) => {
                    if (["type", "categories", "name"].some((prop: any) => key === prop))
                        return true;
                    const value = input[key];
                    if (undefined === value)
                        return true;
                    return $guard(_exceptionable, {
                        path: _path + $join(key),
                        expected: "undefined",
                        value: value
                    });
                })));
                const $au0 = (input: any, _path: string, _exceptionable: boolean = true): any => (() => {
                    if ("SEMANTIC_SEGMENTATION" === input.type)
                        return $ao10(input, _path, true && _exceptionable);
                    if ("PredictionTaskSchemaSimple" === input.type)
                        return $ao9(input, _path, true && _exceptionable);
                    if ("PredictionTaskSchemaKeypoint" === input.type)
                        return $ao8(input, _path, true && _exceptionable);
                    if ("OBJECT_DETECTION" === input.type)
                        return $ao7(input, _path, true && _exceptionable);
                    if ("KEYPOINT_DETECTION" === input.type)
                        return $ao4(input, _path, true && _exceptionable);
                    if ("INSTANCE_SEGMENTATION" === input.type)
                        return $ao3(input, _path, true && _exceptionable);
                    if ("CLASSIFICATION" === input.type)
                        return $ao1(input, _path, true && _exceptionable);
                    return $guard(_exceptionable, {
                        path: _path,
                        expected: "({ type: \"SEMANTIC_SEGMENTATION\"; } & PredictionTaskSchemaSimple | { type: \"PredictionTaskSchemaSimple\"; } & PredictionTaskSchemaSimple | { type: \"PredictionTaskSchemaKeypoint\"; } & PredictionTaskSchemaKeypoint | { type: \"OBJECT_DETECTION\"; } & PredictionTaskSchemaSimple | { type: \"KEYPOINT_DETECTION\"; } & PredictionTaskSchemaKeypoint | { type: \"INSTANCE_SEGMENTATION\"; } & PredictionTaskSchemaSimple | { type: \"CLASSIFICATION\"; } & PredictionTaskSchemaSimple)",
                        value: input
                    });
                })();
                return ("object" === typeof input && null !== input || $guard(true, {
                    path: _path + "",
                    expected: "PredictionTaskSchemas",
                    value: input
                })) && $ao0(input, _path + "", true) || $guard(true, {
                    path: _path + "",
                    expected: "PredictionTaskSchemas",
                    value: input
                });
            })(input, "$input", true);
        return input;
    })(input);
};
export const PredictionTaskSchemasTypiaAssertStringify = (input: PredictionTaskSchemas): string => {
    return ((input: any): string => { const assert = (input: any): PredictionTaskSchemas => {
        const __is = (input: any): input is PredictionTaskSchemas => {
            const $is_custom = (typia.createAssertStringify as any).is_custom;
            const $join = (typia.createAssertStringify as any).join;
            const $io0 = (input: any): boolean => "number" === typeof input.predictionUUIDTimestamp && !Number.isNaN(input.predictionUUIDTimestamp) && 0 <= input.predictionUUIDTimestamp && (Array.isArray(input.schemas) && input.schemas.every((elem: any) => "object" === typeof elem && null !== elem && $iu0(elem)));
            const $io1 = (input: any): boolean => "CLASSIFICATION" === input.type && (Array.isArray(input.categories) && input.categories.every((elem: any) => "object" === typeof elem && null !== elem && $io2(elem))) && ("string" === typeof input.name && $is_custom("pattern_name", "string", "", input.name));
            const $io2 = (input: any): boolean => "number" === typeof input.id && !Number.isNaN(input.id) && 0 <= input.id && ("string" === typeof input.name && 1 <= input.name.length);
            const $io3 = (input: any): boolean => "INSTANCE_SEGMENTATION" === input.type && (Array.isArray(input.categories) && input.categories.every((elem: any) => "object" === typeof elem && null !== elem && $io2(elem))) && ("string" === typeof input.name && $is_custom("pattern_name", "string", "", input.name));
            const $io4 = (input: any): boolean => "KEYPOINT_DETECTION" === input.type && (Array.isArray(input.categories) && input.categories.every((elem: any) => "object" === typeof elem && null !== elem && $io5(elem))) && ("string" === typeof input.name && $is_custom("pattern_name", "string", "", input.name));
            const $io5 = (input: any): boolean => "number" === typeof input.id && !Number.isNaN(input.id) && 0 <= input.id && ("string" === typeof input.name && 1 <= input.name.length) && (undefined === input.keypointNames || Array.isArray(input.keypointNames) && input.keypointNames.every((elem: any) => "string" === typeof elem)) && (undefined === input.keypointSkeleton || Array.isArray(input.keypointSkeleton) && input.keypointSkeleton.every((elem: any) => "object" === typeof elem && null !== elem && $io6(elem)));
            const $io6 = (input: any): boolean => "number" === typeof input.length && !Number.isNaN(input.length) && Object.keys(input).every((key: any) => {
                if (["length"].some((prop: any) => key === prop))
                    return true;
                const value = input[key];
                if (undefined === value)
                    return true;
                if (RegExp(/^[+-]?\d+(?:\.\d+)?(?:[eE][+-]?\d+)?$/).test(key))
                    return "number" === typeof value && !Number.isNaN(value);
                return true;
            });
            const $io7 = (input: any): boolean => "OBJECT_DETECTION" === input.type && (Array.isArray(input.categories) && input.categories.every((elem: any) => "object" === typeof elem && null !== elem && $io2(elem))) && ("string" === typeof input.name && $is_custom("pattern_name", "string", "", input.name));
            const $io8 = (input: any): boolean => "PredictionTaskSchemaKeypoint" === input.type && (Array.isArray(input.categories) && input.categories.every((elem: any) => "object" === typeof elem && null !== elem && $io5(elem))) && ("string" === typeof input.name && $is_custom("pattern_name", "string", "", input.name));
            const $io9 = (input: any): boolean => "PredictionTaskSchemaSimple" === input.type && (Array.isArray(input.categories) && input.categories.every((elem: any) => "object" === typeof elem && null !== elem && $io2(elem))) && ("string" === typeof input.name && $is_custom("pattern_name", "string", "", input.name));
            const $io10 = (input: any): boolean => "SEMANTIC_SEGMENTATION" === input.type && (Array.isArray(input.categories) && input.categories.every((elem: any) => "object" === typeof elem && null !== elem && $io2(elem))) && ("string" === typeof input.name && $is_custom("pattern_name", "string", "", input.name));
            const $iu0 = (input: any): any => (() => {
                if ("SEMANTIC_SEGMENTATION" === input.type)
                    return $io10(input);
                if ("PredictionTaskSchemaSimple" === input.type)
                    return $io9(input);
                if ("PredictionTaskSchemaKeypoint" === input.type)
                    return $io8(input);
                if ("OBJECT_DETECTION" === input.type)
                    return $io7(input);
                if ("KEYPOINT_DETECTION" === input.type)
                    return $io4(input);
                if ("INSTANCE_SEGMENTATION" === input.type)
                    return $io3(input);
                if ("CLASSIFICATION" === input.type)
                    return $io1(input);
                return false;
            })();
            return "object" === typeof input && null !== input && $io0(input);
        };
        if (false === __is(input))
            ((input: any, _path: string, _exceptionable: boolean = true): input is PredictionTaskSchemas => {
                const $guard = (typia.createAssertStringify as any).guard;
                const $is_custom = (typia.createAssertStringify as any).is_custom;
                const $join = (typia.createAssertStringify as any).join;
                const $ao0 = (input: any, _path: string, _exceptionable: boolean = true): boolean => ("number" === typeof input.predictionUUIDTimestamp && !Number.isNaN(input.predictionUUIDTimestamp) && (0 <= input.predictionUUIDTimestamp || $guard(_exceptionable, {
                    path: _path + ".predictionUUIDTimestamp",
                    expected: "number (@minimum 0)",
                    value: input.predictionUUIDTimestamp
                })) || $guard(_exceptionable, {
                    path: _path + ".predictionUUIDTimestamp",
                    expected: "number",
                    value: input.predictionUUIDTimestamp
                })) && ((Array.isArray(input.schemas) || $guard(_exceptionable, {
                    path: _path + ".schemas",
                    expected: "Array<PredictionTaskSchema>",
                    value: input.schemas
                })) && input.schemas.every((elem: any, _index1: number) => ("object" === typeof elem && null !== elem || $guard(_exceptionable, {
                    path: _path + ".schemas[" + _index1 + "]",
                    expected: "({ type: \"CLASSIFICATION\"; } & PredictionTaskSchemaSimple | { type: \"INSTANCE_SEGMENTATION\"; } & PredictionTaskSchemaSimple | { type: \"KEYPOINT_DETECTION\"; } & PredictionTaskSchemaKeypoint | { type: \"OBJECT_DETECTION\"; } & PredictionTaskSchemaSimple | { type: \"PredictionTaskSchemaKeypoint\"; } & PredictionTaskSchemaKeypoint | { type: \"PredictionTaskSchemaSimple\"; } & PredictionTaskSchemaSimple | { type: \"SEMANTIC_SEGMENTATION\"; } & PredictionTaskSchemaSimple)",
                    value: elem
                })) && $au0(elem, _path + ".schemas[" + _index1 + "]", true && _exceptionable) || $guard(_exceptionable, {
                    path: _path + ".schemas[" + _index1 + "]",
                    expected: "({ type: \"CLASSIFICATION\"; } & PredictionTaskSchemaSimple | { type: \"INSTANCE_SEGMENTATION\"; } & PredictionTaskSchemaSimple | { type: \"KEYPOINT_DETECTION\"; } & PredictionTaskSchemaKeypoint | { type: \"OBJECT_DETECTION\"; } & PredictionTaskSchemaSimple | { type: \"PredictionTaskSchemaKeypoint\"; } & PredictionTaskSchemaKeypoint | { type: \"PredictionTaskSchemaSimple\"; } & PredictionTaskSchemaSimple | { type: \"SEMANTIC_SEGMENTATION\"; } & PredictionTaskSchemaSimple)",
                    value: elem
                })) || $guard(_exceptionable, {
                    path: _path + ".schemas",
                    expected: "Array<PredictionTaskSchema>",
                    value: input.schemas
                }));
                const $ao1 = (input: any, _path: string, _exceptionable: boolean = true): boolean => ("CLASSIFICATION" === input.type || $guard(_exceptionable, {
                    path: _path + ".type",
                    expected: "\"CLASSIFICATION\"",
                    value: input.type
                })) && ((Array.isArray(input.categories) || $guard(_exceptionable, {
                    path: _path + ".categories",
                    expected: "Array<PredictionTaskSchemaCategory>",
                    value: input.categories
                })) && input.categories.every((elem: any, _index2: number) => ("object" === typeof elem && null !== elem || $guard(_exceptionable, {
                    path: _path + ".categories[" + _index2 + "]",
                    expected: "PredictionTaskSchemaCategory",
                    value: elem
                })) && $ao2(elem, _path + ".categories[" + _index2 + "]", true && _exceptionable) || $guard(_exceptionable, {
                    path: _path + ".categories[" + _index2 + "]",
                    expected: "PredictionTaskSchemaCategory",
                    value: elem
                })) || $guard(_exceptionable, {
                    path: _path + ".categories",
                    expected: "Array<PredictionTaskSchemaCategory>",
                    value: input.categories
                })) && ("string" === typeof input.name && ($is_custom("pattern_name", "string", "", input.name) || $guard(_exceptionable, {
                    path: _path + ".name",
                    expected: "string (@pattern_name)",
                    value: input.name
                })) || $guard(_exceptionable, {
                    path: _path + ".name",
                    expected: "string",
                    value: input.name
                }));
                const $ao2 = (input: any, _path: string, _exceptionable: boolean = true): boolean => ("number" === typeof input.id && !Number.isNaN(input.id) && (0 <= input.id || $guard(_exceptionable, {
                    path: _path + ".id",
                    expected: "number (@minimum 0)",
                    value: input.id
                })) || $guard(_exceptionable, {
                    path: _path + ".id",
                    expected: "number",
                    value: input.id
                })) && ("string" === typeof input.name && (1 <= input.name.length || $guard(_exceptionable, {
                    path: _path + ".name",
                    expected: "string (@minLength 1)",
                    value: input.name
                })) || $guard(_exceptionable, {
                    path: _path + ".name",
                    expected: "string",
                    value: input.name
                }));
                const $ao3 = (input: any, _path: string, _exceptionable: boolean = true): boolean => ("INSTANCE_SEGMENTATION" === input.type || $guard(_exceptionable, {
                    path: _path + ".type",
                    expected: "\"INSTANCE_SEGMENTATION\"",
                    value: input.type
                })) && ((Array.isArray(input.categories) || $guard(_exceptionable, {
                    path: _path + ".categories",
                    expected: "Array<PredictionTaskSchemaCategory>",
                    value: input.categories
                })) && input.categories.every((elem: any, _index3: number) => ("object" === typeof elem && null !== elem || $guard(_exceptionable, {
                    path: _path + ".categories[" + _index3 + "]",
                    expected: "PredictionTaskSchemaCategory",
                    value: elem
                })) && $ao2(elem, _path + ".categories[" + _index3 + "]", true && _exceptionable) || $guard(_exceptionable, {
                    path: _path + ".categories[" + _index3 + "]",
                    expected: "PredictionTaskSchemaCategory",
                    value: elem
                })) || $guard(_exceptionable, {
                    path: _path + ".categories",
                    expected: "Array<PredictionTaskSchemaCategory>",
                    value: input.categories
                })) && ("string" === typeof input.name && ($is_custom("pattern_name", "string", "", input.name) || $guard(_exceptionable, {
                    path: _path + ".name",
                    expected: "string (@pattern_name)",
                    value: input.name
                })) || $guard(_exceptionable, {
                    path: _path + ".name",
                    expected: "string",
                    value: input.name
                }));
                const $ao4 = (input: any, _path: string, _exceptionable: boolean = true): boolean => ("KEYPOINT_DETECTION" === input.type || $guard(_exceptionable, {
                    path: _path + ".type",
                    expected: "\"KEYPOINT_DETECTION\"",
                    value: input.type
                })) && ((Array.isArray(input.categories) || $guard(_exceptionable, {
                    path: _path + ".categories",
                    expected: "Array<PredictionTaskSchemaCategoryKeypoints>",
                    value: input.categories
                })) && input.categories.every((elem: any, _index4: number) => ("object" === typeof elem && null !== elem || $guard(_exceptionable, {
                    path: _path + ".categories[" + _index4 + "]",
                    expected: "PredictionTaskSchemaCategoryKeypoints",
                    value: elem
                })) && $ao5(elem, _path + ".categories[" + _index4 + "]", true && _exceptionable) || $guard(_exceptionable, {
                    path: _path + ".categories[" + _index4 + "]",
                    expected: "PredictionTaskSchemaCategoryKeypoints",
                    value: elem
                })) || $guard(_exceptionable, {
                    path: _path + ".categories",
                    expected: "Array<PredictionTaskSchemaCategoryKeypoints>",
                    value: input.categories
                })) && ("string" === typeof input.name && ($is_custom("pattern_name", "string", "", input.name) || $guard(_exceptionable, {
                    path: _path + ".name",
                    expected: "string (@pattern_name)",
                    value: input.name
                })) || $guard(_exceptionable, {
                    path: _path + ".name",
                    expected: "string",
                    value: input.name
                }));
                const $ao5 = (input: any, _path: string, _exceptionable: boolean = true): boolean => ("number" === typeof input.id && !Number.isNaN(input.id) && (0 <= input.id || $guard(_exceptionable, {
                    path: _path + ".id",
                    expected: "number (@minimum 0)",
                    value: input.id
                })) || $guard(_exceptionable, {
                    path: _path + ".id",
                    expected: "number",
                    value: input.id
                })) && ("string" === typeof input.name && (1 <= input.name.length || $guard(_exceptionable, {
                    path: _path + ".name",
                    expected: "string (@minLength 1)",
                    value: input.name
                })) || $guard(_exceptionable, {
                    path: _path + ".name",
                    expected: "string",
                    value: input.name
                })) && (undefined === input.keypointNames || (Array.isArray(input.keypointNames) || $guard(_exceptionable, {
                    path: _path + ".keypointNames",
                    expected: "(Array<string> | undefined)",
                    value: input.keypointNames
                })) && input.keypointNames.every((elem: any, _index5: number) => "string" === typeof elem || $guard(_exceptionable, {
                    path: _path + ".keypointNames[" + _index5 + "]",
                    expected: "string",
                    value: elem
                })) || $guard(_exceptionable, {
                    path: _path + ".keypointNames",
                    expected: "(Array<string> | undefined)",
                    value: input.keypointNames
                })) && (undefined === input.keypointSkeleton || (Array.isArray(input.keypointSkeleton) || $guard(_exceptionable, {
                    path: _path + ".keypointSkeleton",
                    expected: "(Array<KeypointSkeletonConnection> | undefined)",
                    value: input.keypointSkeleton
                })) && input.keypointSkeleton.every((elem: any, _index6: number) => ("object" === typeof elem && null !== elem || $guard(_exceptionable, {
                    path: _path + ".keypointSkeleton[" + _index6 + "]",
                    expected: "KeypointSkeletonConnection",
                    value: elem
                })) && $ao6(elem, _path + ".keypointSkeleton[" + _index6 + "]", true && _exceptionable) || $guard(_exceptionable, {
                    path: _path + ".keypointSkeleton[" + _index6 + "]",
                    expected: "KeypointSkeletonConnection",
                    value: elem
                })) || $guard(_exceptionable, {
                    path: _path + ".keypointSkeleton",
                    expected: "(Array<KeypointSkeletonConnection> | undefined)",
                    value: input.keypointSkeleton
                }));
                const $ao6 = (input: any, _path: string, _exceptionable: boolean = true): boolean => ("number" === typeof input.length && !Number.isNaN(input.length) || $guard(_exceptionable, {
                    path: _path + ".length",
                    expected: "number",
                    value: input.length
                })) && (false === _exceptionable || Object.keys(input).every((key: any) => {
                    if (["length"].some((prop: any) => key === prop))
                        return true;
                    const value = input[key];
                    if (undefined === value)
                        return true;
                    if (RegExp(/^[+-]?\d+(?:\.\d+)?(?:[eE][+-]?\d+)?$/).test(key))
                        return "number" === typeof value && !Number.isNaN(value) || $guard(_exceptionable, {
                            path: _path + $join(key),
                            expected: "number",
                            value: value
                        });
                    return true;
                }));
                const $ao7 = (input: any, _path: string, _exceptionable: boolean = true): boolean => ("OBJECT_DETECTION" === input.type || $guard(_exceptionable, {
                    path: _path + ".type",
                    expected: "\"OBJECT_DETECTION\"",
                    value: input.type
                })) && ((Array.isArray(input.categories) || $guard(_exceptionable, {
                    path: _path + ".categories",
                    expected: "Array<PredictionTaskSchemaCategory>",
                    value: input.categories
                })) && input.categories.every((elem: any, _index7: number) => ("object" === typeof elem && null !== elem || $guard(_exceptionable, {
                    path: _path + ".categories[" + _index7 + "]",
                    expected: "PredictionTaskSchemaCategory",
                    value: elem
                })) && $ao2(elem, _path + ".categories[" + _index7 + "]", true && _exceptionable) || $guard(_exceptionable, {
                    path: _path + ".categories[" + _index7 + "]",
                    expected: "PredictionTaskSchemaCategory",
                    value: elem
                })) || $guard(_exceptionable, {
                    path: _path + ".categories",
                    expected: "Array<PredictionTaskSchemaCategory>",
                    value: input.categories
                })) && ("string" === typeof input.name && ($is_custom("pattern_name", "string", "", input.name) || $guard(_exceptionable, {
                    path: _path + ".name",
                    expected: "string (@pattern_name)",
                    value: input.name
                })) || $guard(_exceptionable, {
                    path: _path + ".name",
                    expected: "string",
                    value: input.name
                }));
                const $ao8 = (input: any, _path: string, _exceptionable: boolean = true): boolean => ("PredictionTaskSchemaKeypoint" === input.type || $guard(_exceptionable, {
                    path: _path + ".type",
                    expected: "\"PredictionTaskSchemaKeypoint\"",
                    value: input.type
                })) && ((Array.isArray(input.categories) || $guard(_exceptionable, {
                    path: _path + ".categories",
                    expected: "Array<PredictionTaskSchemaCategoryKeypoints>",
                    value: input.categories
                })) && input.categories.every((elem: any, _index8: number) => ("object" === typeof elem && null !== elem || $guard(_exceptionable, {
                    path: _path + ".categories[" + _index8 + "]",
                    expected: "PredictionTaskSchemaCategoryKeypoints",
                    value: elem
                })) && $ao5(elem, _path + ".categories[" + _index8 + "]", true && _exceptionable) || $guard(_exceptionable, {
                    path: _path + ".categories[" + _index8 + "]",
                    expected: "PredictionTaskSchemaCategoryKeypoints",
                    value: elem
                })) || $guard(_exceptionable, {
                    path: _path + ".categories",
                    expected: "Array<PredictionTaskSchemaCategoryKeypoints>",
                    value: input.categories
                })) && ("string" === typeof input.name && ($is_custom("pattern_name", "string", "", input.name) || $guard(_exceptionable, {
                    path: _path + ".name",
                    expected: "string (@pattern_name)",
                    value: input.name
                })) || $guard(_exceptionable, {
                    path: _path + ".name",
                    expected: "string",
                    value: input.name
                }));
                const $ao9 = (input: any, _path: string, _exceptionable: boolean = true): boolean => ("PredictionTaskSchemaSimple" === input.type || $guard(_exceptionable, {
                    path: _path + ".type",
                    expected: "\"PredictionTaskSchemaSimple\"",
                    value: input.type
                })) && ((Array.isArray(input.categories) || $guard(_exceptionable, {
                    path: _path + ".categories",
                    expected: "Array<PredictionTaskSchemaCategory>",
                    value: input.categories
                })) && input.categories.every((elem: any, _index9: number) => ("object" === typeof elem && null !== elem || $guard(_exceptionable, {
                    path: _path + ".categories[" + _index9 + "]",
                    expected: "PredictionTaskSchemaCategory",
                    value: elem
                })) && $ao2(elem, _path + ".categories[" + _index9 + "]", true && _exceptionable) || $guard(_exceptionable, {
                    path: _path + ".categories[" + _index9 + "]",
                    expected: "PredictionTaskSchemaCategory",
                    value: elem
                })) || $guard(_exceptionable, {
                    path: _path + ".categories",
                    expected: "Array<PredictionTaskSchemaCategory>",
                    value: input.categories
                })) && ("string" === typeof input.name && ($is_custom("pattern_name", "string", "", input.name) || $guard(_exceptionable, {
                    path: _path + ".name",
                    expected: "string (@pattern_name)",
                    value: input.name
                })) || $guard(_exceptionable, {
                    path: _path + ".name",
                    expected: "string",
                    value: input.name
                }));
                const $ao10 = (input: any, _path: string, _exceptionable: boolean = true): boolean => ("SEMANTIC_SEGMENTATION" === input.type || $guard(_exceptionable, {
                    path: _path + ".type",
                    expected: "\"SEMANTIC_SEGMENTATION\"",
                    value: input.type
                })) && ((Array.isArray(input.categories) || $guard(_exceptionable, {
                    path: _path + ".categories",
                    expected: "Array<PredictionTaskSchemaCategory>",
                    value: input.categories
                })) && input.categories.every((elem: any, _index10: number) => ("object" === typeof elem && null !== elem || $guard(_exceptionable, {
                    path: _path + ".categories[" + _index10 + "]",
                    expected: "PredictionTaskSchemaCategory",
                    value: elem
                })) && $ao2(elem, _path + ".categories[" + _index10 + "]", true && _exceptionable) || $guard(_exceptionable, {
                    path: _path + ".categories[" + _index10 + "]",
                    expected: "PredictionTaskSchemaCategory",
                    value: elem
                })) || $guard(_exceptionable, {
                    path: _path + ".categories",
                    expected: "Array<PredictionTaskSchemaCategory>",
                    value: input.categories
                })) && ("string" === typeof input.name && ($is_custom("pattern_name", "string", "", input.name) || $guard(_exceptionable, {
                    path: _path + ".name",
                    expected: "string (@pattern_name)",
                    value: input.name
                })) || $guard(_exceptionable, {
                    path: _path + ".name",
                    expected: "string",
                    value: input.name
                }));
                const $au0 = (input: any, _path: string, _exceptionable: boolean = true): any => (() => {
                    if ("SEMANTIC_SEGMENTATION" === input.type)
                        return $ao10(input, _path, true && _exceptionable);
                    if ("PredictionTaskSchemaSimple" === input.type)
                        return $ao9(input, _path, true && _exceptionable);
                    if ("PredictionTaskSchemaKeypoint" === input.type)
                        return $ao8(input, _path, true && _exceptionable);
                    if ("OBJECT_DETECTION" === input.type)
                        return $ao7(input, _path, true && _exceptionable);
                    if ("KEYPOINT_DETECTION" === input.type)
                        return $ao4(input, _path, true && _exceptionable);
                    if ("INSTANCE_SEGMENTATION" === input.type)
                        return $ao3(input, _path, true && _exceptionable);
                    if ("CLASSIFICATION" === input.type)
                        return $ao1(input, _path, true && _exceptionable);
                    return $guard(_exceptionable, {
                        path: _path,
                        expected: "({ type: \"SEMANTIC_SEGMENTATION\"; } & PredictionTaskSchemaSimple | { type: \"PredictionTaskSchemaSimple\"; } & PredictionTaskSchemaSimple | { type: \"PredictionTaskSchemaKeypoint\"; } & PredictionTaskSchemaKeypoint | { type: \"OBJECT_DETECTION\"; } & PredictionTaskSchemaSimple | { type: \"KEYPOINT_DETECTION\"; } & PredictionTaskSchemaKeypoint | { type: \"INSTANCE_SEGMENTATION\"; } & PredictionTaskSchemaSimple | { type: \"CLASSIFICATION\"; } & PredictionTaskSchemaSimple)",
                        value: input
                    });
                })();
                return ("object" === typeof input && null !== input || $guard(true, {
                    path: _path + "",
                    expected: "PredictionTaskSchemas",
                    value: input
                })) && $ao0(input, _path + "", true) || $guard(true, {
                    path: _path + "",
                    expected: "PredictionTaskSchemas",
                    value: input
                });
            })(input, "$input", true);
        return input;
    }; const stringify = (input: PredictionTaskSchemas): string => {
        const $io1 = (input: any): boolean => "CLASSIFICATION" === input.type && (Array.isArray(input.categories) && input.categories.every((elem: any) => "object" === typeof elem && null !== elem && $io2(elem))) && ("string" === typeof input.name && $is_custom("pattern_name", "string", "", input.name));
        const $io2 = (input: any): boolean => "number" === typeof input.id && 0 <= input.id && ("string" === typeof input.name && 1 <= input.name.length);
        const $io3 = (input: any): boolean => "INSTANCE_SEGMENTATION" === input.type && (Array.isArray(input.categories) && input.categories.every((elem: any) => "object" === typeof elem && null !== elem && $io2(elem))) && ("string" === typeof input.name && $is_custom("pattern_name", "string", "", input.name));
        const $io4 = (input: any): boolean => "KEYPOINT_DETECTION" === input.type && (Array.isArray(input.categories) && input.categories.every((elem: any) => "object" === typeof elem && null !== elem && $io5(elem))) && ("string" === typeof input.name && $is_custom("pattern_name", "string", "", input.name));
        const $io5 = (input: any): boolean => "number" === typeof input.id && 0 <= input.id && ("string" === typeof input.name && 1 <= input.name.length) && (undefined === input.keypointNames || Array.isArray(input.keypointNames) && input.keypointNames.every((elem: any) => "string" === typeof elem)) && (undefined === input.keypointSkeleton || Array.isArray(input.keypointSkeleton) && input.keypointSkeleton.every((elem: any) => "object" === typeof elem && null !== elem && $io6(elem)));
        const $io6 = (input: any): boolean => "number" === typeof input.length && Object.keys(input).every((key: any) => {
            if (["length"].some((prop: any) => key === prop))
                return true;
            const value = input[key];
            if (undefined === value)
                return true;
            if (RegExp(/^[+-]?\d+(?:\.\d+)?(?:[eE][+-]?\d+)?$/).test(key))
                return "number" === typeof value;
            return true;
        });
        const $io7 = (input: any): boolean => "OBJECT_DETECTION" === input.type && (Array.isArray(input.categories) && input.categories.every((elem: any) => "object" === typeof elem && null !== elem && $io2(elem))) && ("string" === typeof input.name && $is_custom("pattern_name", "string", "", input.name));
        const $io8 = (input: any): boolean => "PredictionTaskSchemaKeypoint" === input.type && (Array.isArray(input.categories) && input.categories.every((elem: any) => "object" === typeof elem && null !== elem && $io5(elem))) && ("string" === typeof input.name && $is_custom("pattern_name", "string", "", input.name));
        const $io9 = (input: any): boolean => "PredictionTaskSchemaSimple" === input.type && (Array.isArray(input.categories) && input.categories.every((elem: any) => "object" === typeof elem && null !== elem && $io2(elem))) && ("string" === typeof input.name && $is_custom("pattern_name", "string", "", input.name));
        const $io10 = (input: any): boolean => "SEMANTIC_SEGMENTATION" === input.type && (Array.isArray(input.categories) && input.categories.every((elem: any) => "object" === typeof elem && null !== elem && $io2(elem))) && ("string" === typeof input.name && $is_custom("pattern_name", "string", "", input.name));
        const $iu0 = (input: any): any => (() => {
            if ("SEMANTIC_SEGMENTATION" === input.type)
                return $io10(input);
            if ("PredictionTaskSchemaSimple" === input.type)
                return $io9(input);
            if ("PredictionTaskSchemaKeypoint" === input.type)
                return $io8(input);
            if ("OBJECT_DETECTION" === input.type)
                return $io7(input);
            if ("KEYPOINT_DETECTION" === input.type)
                return $io4(input);
            if ("INSTANCE_SEGMENTATION" === input.type)
                return $io3(input);
            if ("CLASSIFICATION" === input.type)
                return $io1(input);
            return false;
        })();
        const $string = (typia.createAssertStringify as any).string;
        const $throws = (typia.createAssertStringify as any).throws;
        const $join = (typia.createAssertStringify as any).join;
        const $tail = (typia.createAssertStringify as any).tail;
        const $is_custom = (typia.createAssertStringify as any).is_custom;
        const $so0 = (input: any): any => `{"predictionUUIDTimestamp":${input.predictionUUIDTimestamp},"schemas":${`[${input.schemas.map((elem: any) => $su0(elem)).join(",")}]`}}`;
        const $so1 = (input: any): any => `{"type":${(() => {
            if ("string" === typeof input.type)
                return $string(input.type);
            if ("string" === typeof input.type)
                return "\"" + input.type + "\"";
            $throws({
                expected: "\"CLASSIFICATION\"",
                value: input.type
            });
        })()},"categories":${`[${input.categories.map((elem: any) => `{"id":${(elem as any).id},"name":${$string((elem as any).name)}}`).join(",")}]`},"name":${$string(input.name)}}`;
        const $so3 = (input: any): any => `{"type":${(() => {
            if ("string" === typeof input.type)
                return $string(input.type);
            if ("string" === typeof input.type)
                return "\"" + input.type + "\"";
            $throws({
                expected: "\"INSTANCE_SEGMENTATION\"",
                value: input.type
            });
        })()},"categories":${`[${input.categories.map((elem: any) => `{"id":${(elem as any).id},"name":${$string((elem as any).name)}}`).join(",")}]`},"name":${$string(input.name)}}`;
        const $so4 = (input: any): any => `{"type":${(() => {
            if ("string" === typeof input.type)
                return $string(input.type);
            if ("string" === typeof input.type)
                return "\"" + input.type + "\"";
            $throws({
                expected: "\"KEYPOINT_DETECTION\"",
                value: input.type
            });
        })()},"categories":${`[${input.categories.map((elem: any) => $so5(elem)).join(",")}]`},"name":${$string(input.name)}}`;
        const $so5 = (input: any): any => `{${undefined === input.keypointNames ? "" : `"keypointNames":${undefined !== input.keypointNames ? `[${input.keypointNames.map((elem: any) => $string(elem)).join(",")}]` : undefined},`}${undefined === input.keypointSkeleton ? "" : `"keypointSkeleton":${undefined !== input.keypointSkeleton ? `[${input.keypointSkeleton.map((elem: any) => $so6(elem)).join(",")}]` : undefined},`}"id":${input.id},"name":${$string(input.name)}}`;
        const $so6 = (input: any): any => `{${$tail(`"length":${input.length},${Object.entries(input).map(([key, value]: [string, any]) => { if (undefined === value)
            return ""; if (["length"].some((regular: any) => regular === key))
            return ""; if (RegExp(/^[+-]?\d+(?:\.\d+)?(?:[eE][+-]?\d+)?$/).test(key))
            return `${JSON.stringify(key)}:${value}`; return ""; }).filter((str: any) => "" !== str).join(",")}`)}}`;
        const $so7 = (input: any): any => `{"type":${(() => {
            if ("string" === typeof input.type)
                return $string(input.type);
            if ("string" === typeof input.type)
                return "\"" + input.type + "\"";
            $throws({
                expected: "\"OBJECT_DETECTION\"",
                value: input.type
            });
        })()},"categories":${`[${input.categories.map((elem: any) => `{"id":${(elem as any).id},"name":${$string((elem as any).name)}}`).join(",")}]`},"name":${$string(input.name)}}`;
        const $so8 = (input: any): any => `{"type":${(() => {
            if ("string" === typeof input.type)
                return $string(input.type);
            if ("string" === typeof input.type)
                return "\"" + input.type + "\"";
            $throws({
                expected: "\"PredictionTaskSchemaKeypoint\"",
                value: input.type
            });
        })()},"categories":${`[${input.categories.map((elem: any) => $so5(elem)).join(",")}]`},"name":${$string(input.name)}}`;
        const $so9 = (input: any): any => `{"type":${(() => {
            if ("string" === typeof input.type)
                return $string(input.type);
            if ("string" === typeof input.type)
                return "\"" + input.type + "\"";
            $throws({
                expected: "\"PredictionTaskSchemaSimple\"",
                value: input.type
            });
        })()},"categories":${`[${input.categories.map((elem: any) => `{"id":${(elem as any).id},"name":${$string((elem as any).name)}}`).join(",")}]`},"name":${$string(input.name)}}`;
        const $so10 = (input: any): any => `{"type":${(() => {
            if ("string" === typeof input.type)
                return $string(input.type);
            if ("string" === typeof input.type)
                return "\"" + input.type + "\"";
            $throws({
                expected: "\"SEMANTIC_SEGMENTATION\"",
                value: input.type
            });
        })()},"categories":${`[${input.categories.map((elem: any) => `{"id":${(elem as any).id},"name":${$string((elem as any).name)}}`).join(",")}]`},"name":${$string(input.name)}}`;
        const $su0 = (input: any): any => (() => {
            if ("SEMANTIC_SEGMENTATION" === input.type)
                return $so10(input);
            if ("PredictionTaskSchemaSimple" === input.type)
                return $so9(input);
            if ("PredictionTaskSchemaKeypoint" === input.type)
                return $so8(input);
            if ("OBJECT_DETECTION" === input.type)
                return $so7(input);
            if ("KEYPOINT_DETECTION" === input.type)
                return $so4(input);
            if ("INSTANCE_SEGMENTATION" === input.type)
                return $so3(input);
            if ("CLASSIFICATION" === input.type)
                return $so1(input);
            $throws({
                expected: "({ type: \"SEMANTIC_SEGMENTATION\"; } & PredictionTaskSchemaSimple | { type: \"PredictionTaskSchemaSimple\"; } & PredictionTaskSchemaSimple | { type: \"PredictionTaskSchemaKeypoint\"; } & PredictionTaskSchemaKeypoint | { type: \"OBJECT_DETECTION\"; } & PredictionTaskSchemaSimple | { type: \"KEYPOINT_DETECTION\"; } & PredictionTaskSchemaKeypoint | { type: \"INSTANCE_SEGMENTATION\"; } & PredictionTaskSchemaSimple | { type: \"CLASSIFICATION\"; } & PredictionTaskSchemaSimple)",
                value: input
            });
        })();
        return $so0(input);
    }; return stringify(assert(input)); })(input);
};
export const PredictionTaskSchemasValidatorShape = {
    'predictionUUIDTimestamp': yup.number().min(0).defined(),
    'schemas': yup.array().of(PredictionTaskSchemaValidator).defined(),
};
export const PredictionTaskSchemasValidator = yup.object().shape(PredictionTaskSchemasValidatorShape);
export const PredictionTaskSchemasStringifyShapeProperties: ObjectSchema['properties'] = {
    'predictionUUIDTimestamp': {
        'type': 'number',
        'minimum': 0,
    } as unknown as fastJSON.NumberSchema,
    'schemas': {
        type: 'array',
        items: {
            PredictionTaskSchemaStringifyShape
        }
    },
};
export const PredictionTaskSchemasStringifyShape: ObjectSchema = {
    title: 'PredictionTaskSchemas Stringifier',
    type: 'object',
    properties: {
        ...PredictionTaskSchemasStringifyShapeProperties
    },
    required: [
        'predictionUUIDTimestamp',
        'schemas',
    ],
};
export const PredictionTaskSchemasStringifyCompiled = (typeof process === 'object' && String(process) === '[object process]') ?
    fastJSON.build(PredictionTaskSchemasStringifyShape, {
        ajv: {
            // since we are already doing this with yup, lets skip
            validateSchema: false,
            ownProperties: true,
            validateFormats: false,
        },
        largeArrayMechanism: 'json-stringify',
        largeArraySize: 1000,
    } as Options)
    :
        () => { throw new Error('fast-json-stringify only works in nodejs'); };
export const PredictionTaskSchemasStringify: (data: PredictionTaskSchemas) => string = PredictionTaskSchemasStringifyCompiled;
/* LIGHTLY CUSTOM END -> Generic */
export function PredictionTaskSchemasFromJSON(json: any): PredictionTaskSchemas {
    return PredictionTaskSchemasFromJSONTyped(json, false);
}
export function PredictionTaskSchemasFromJSONTyped(json: any, ignoreDiscriminator: boolean): PredictionTaskSchemas {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    /* causes circular depdendencies
*/
    return {
        'predictionUUIDTimestamp': json['predictionUUIDTimestamp'],
        'schemas': ((json['schemas'] as Array<any> || []).map(PredictionTaskSchemaFromJSON)),
    };
}
export function PredictionTaskSchemasToJSON(value?: PredictionTaskSchemas | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'predictionUUIDTimestamp': value.predictionUUIDTimestamp,
        'schemas': ((value.schemas as Array<any> || []).map(PredictionTaskSchemaToJSON)),
    };
}
