import { QuadTree, Box, Point, Circle } from 'js-quadtree';


const euclidDistance = (ax: number, ay:number, bx:number, by:number) => {
	return Math.sqrt(Math.pow(ax - bx, 2) + Math.pow(ay - by, 2));
}


const MAX_SEARCH_ITERATIONS = 100;
export class NNeighbor<P extends {x: number, y: number} = Point> {
	private quad: QuadTree;
	length: number;
	constructor(entries: P[]) {
		this.length  = entries.length;
		this.quad = new QuadTree(
			new Box(-1, -1, 2, 2),
			{
				maximumDepth: 16,
			},
		);
		this.quad.insert(entries as unknown as Point[]); // there is an error in the typings
	}
	findRect(xCenter: number, yCenter: number, dim = .1): P[] {
		return this.quad.query(new Box(xCenter - dim / 2, yCenter - dim / 2, dim, dim)) as unknown as P[];
	}
	findCircle(xCenter: number, yCenter: number, radius = .1): P[] {
		return this.quad.query(new Circle(xCenter, yCenter, radius)) as unknown as P[];
	}
	closest(x: number, y: number, amount = 10): {dist:number, entry: P}[] {
		let found: P[] = [];
		// search as long until we found our amount
		for (let i = 0; found.length < amount && this.length > found.length && i < MAX_SEARCH_ITERATIONS; i++) {
			found = this.findRect(x, y, 0.1 * i);
		}

		const closest = found
			.map((f) => {
				return {
					entry: f,
					dist: euclidDistance(x, y, f.x, f.y),
				};
			})
			.sort((a,b) => a.dist - b.dist)
			.slice(0, amount) // ignore the first as thats "us"

		return closest
	}
}