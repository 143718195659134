/* tslint:disable */
/* eslint-disable */
/**
 * Lightly API
 * Lightly.ai enables you to do self-supervised learning in an easy and intuitive way. The lightly.ai OpenAPI spec defines how one can interact with our REST API to unleash the full potential of lightly.ai
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: support@lightly.ai
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
// SRC: https://github.com/OpenAPITools/openapi-generator/blob/master/modules/openapi-generator/src/main/resources/typescript-fetch/modelEnumInterfaces.mustache
// synched 02.02.2023
import * as yup from 'yup';
import * as fastJSON from 'fast-json-stringify';
import { StringSchema } from 'fast-json-stringify';
/**
 *
 * @export
 * @enum {string}
 */
export enum DockerRunState {
    STARTED = 'STARTED',
    INITIALIZING = 'INITIALIZING',
    LOADING_DATASET = 'LOADING_DATASET',
    LOADING_METADATA = 'LOADING_METADATA',
    LOADING_PREDICTION = 'LOADING_PREDICTION',
    CHECKING_CORRUPTNESS = 'CHECKING_CORRUPTNESS',
    INITIALIZING_OBJECT_CROPS = 'INITIALIZING_OBJECT_CROPS',
    COMPUTING_METADATA = 'COMPUTING_METADATA',
    TRAINING = 'TRAINING',
    EMBEDDING = 'EMBEDDING',
    EMBEDDING_OBJECT_CROPS = 'EMBEDDING_OBJECT_CROPS',
    PRETAGGING = 'PRETAGGING',
    COMPUTING_ACTIVE_LEARNING_SCORES = 'COMPUTING_ACTIVE_LEARNING_SCORES',
    SAMPLING = 'SAMPLING',
    EMBEDDING_FULL_IMAGES = 'EMBEDDING_FULL_IMAGES',
    SAVING_RESULTS = 'SAVING_RESULTS',
    UPLOADING_DATASET = 'UPLOADING_DATASET',
    GENERATING_REPORT = 'GENERATING_REPORT',
    UPLOADING_REPORT = 'UPLOADING_REPORT',
    UPLOADED_REPORT = 'UPLOADED_REPORT',
    UPLOADING_ARTIFACTS = 'UPLOADING_ARTIFACTS',
    UPLOADED_ARTIFACTS = 'UPLOADED_ARTIFACTS',
    COMPLETED = 'COMPLETED',
    FAILED = 'FAILED',
    CRASHED = 'CRASHED',
    ABORTED = 'ABORTED'
}
/* LIGHTLY CUSTOM START -> modelEnumInterfaces */
export const DockerRunStateValidatorShape = [
    'STARTED',
    'INITIALIZING',
    'LOADING_DATASET',
    'LOADING_METADATA',
    'LOADING_PREDICTION',
    'CHECKING_CORRUPTNESS',
    'INITIALIZING_OBJECT_CROPS',
    'COMPUTING_METADATA',
    'TRAINING',
    'EMBEDDING',
    'EMBEDDING_OBJECT_CROPS',
    'PRETAGGING',
    'COMPUTING_ACTIVE_LEARNING_SCORES',
    'SAMPLING',
    'EMBEDDING_FULL_IMAGES',
    'SAVING_RESULTS',
    'UPLOADING_DATASET',
    'GENERATING_REPORT',
    'UPLOADING_REPORT',
    'UPLOADED_REPORT',
    'UPLOADING_ARTIFACTS',
    'UPLOADED_ARTIFACTS',
    'COMPLETED',
    'FAILED',
    'CRASHED',
    'ABORTED'
];
export const DockerRunStateValidator = yup.mixed().oneOf(DockerRunStateValidatorShape);
export const DockerRunStateStringifyShapeProperties = {};
export const DockerRunStateStringifyShape: StringSchema = {
    title: 'DockerRunState Stringifier',
    type: 'string'
};
export const DockerRunStateStringify = (typeof process === 'object' && String(process) === '[object process]') ?
    fastJSON.build(DockerRunStateStringifyShape)
    :
        () => { throw new Error('fast-json-stringify only works in nodejs'); };
/* LIGHTLY CUSTOM END */
export function DockerRunStateFromJSON(json: any): DockerRunState {
    return DockerRunStateFromJSONTyped(json, false);
}
export function DockerRunStateFromJSONTyped(json: any, ignoreDiscriminator: boolean): DockerRunState {
    return json as DockerRunState;
}
export function DockerRunStateToJSON(value?: DockerRunState | null): any {
    return value as any;
}
