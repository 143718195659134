/* tslint:disable */
/* eslint-disable */
/**
 * Lightly API
 * Lightly.ai enables you to do self-supervised learning in an easy and intuitive way. The lightly.ai OpenAPI spec defines how one can interact with our REST API to unleash the full potential of lightly.ai
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: support@lightly.ai
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
// SRC: https://github.com/OpenAPITools/openapi-generator/blob/master/modules/openapi-generator/src/main/resources/typescript-fetch/modelGeneric.mustache
// synched 02.02.2023
import * as yup from 'yup';
import typia from 'typia';
import * as fastJSON from 'fast-json-stringify';
import { Options, ArraySchema, ObjectSchema } from 'fast-json-stringify';
import { exists, mapValues } from '../runtime';
/*
causes circular dependendies
*/
// SRC: https://github.com/OpenAPITools/openapi-generator/blob/master/modules/openapi-generator/src/main/resources/typescript-fetch/modelGenericInterfaces.mustache
// synced: 17.07.2023
/**
 *
 * @export
 * @interface DockerWorkerConfigV3LightlyCheckpointCallback
 *
 */
// create custom validators for the regexes
export interface DockerWorkerConfigV3LightlyCheckpointCallback {
    /**
     * If True, the checkpoint from the last epoch is saved.
     * #type boolean
     * #memberof DockerWorkerConfigV3LightlyCheckpointCallback
        
        
         */
    saveLast?: boolean;
}
/**
 * Check if a given object implements the DockerWorkerConfigV3LightlyCheckpointCallback interface.
 */
export function instanceOfDockerWorkerConfigV3LightlyCheckpointCallback(value: object): boolean {
    let isInstance = true;
    return isInstance;
}
/* LIGHTLY CUSTOM START -> modelGeneric */
export const DockerWorkerConfigV3LightlyCheckpointCallbackTypiaAssertEquals = (input: any) => {
    return ((input: any): DockerWorkerConfigV3LightlyCheckpointCallback => {
        const __is = (input: any, _exceptionable: boolean = true): input is DockerWorkerConfigV3LightlyCheckpointCallback => {
            const $io0 = (input: any, _exceptionable: boolean = true): boolean => (undefined === input.saveLast || "boolean" === typeof input.saveLast) && (0 === Object.keys(input).length || Object.keys(input).every((key: any) => {
                if (["saveLast"].some((prop: any) => key === prop))
                    return true;
                const value = input[key];
                if (undefined === value)
                    return true;
                return false;
            }));
            return "object" === typeof input && null !== input && false === Array.isArray(input) && $io0(input, true);
        };
        if (false === __is(input))
            ((input: any, _path: string, _exceptionable: boolean = true): input is DockerWorkerConfigV3LightlyCheckpointCallback => {
                const $guard = (typia.createAssertEquals as any).guard;
                const $join = (typia.createAssertEquals as any).join;
                const $ao0 = (input: any, _path: string, _exceptionable: boolean = true): boolean => (undefined === input.saveLast || "boolean" === typeof input.saveLast || $guard(_exceptionable, {
                    path: _path + ".saveLast",
                    expected: "(boolean | undefined)",
                    value: input.saveLast
                })) && (0 === Object.keys(input).length || (false === _exceptionable || Object.keys(input).every((key: any) => {
                    if (["saveLast"].some((prop: any) => key === prop))
                        return true;
                    const value = input[key];
                    if (undefined === value)
                        return true;
                    return $guard(_exceptionable, {
                        path: _path + $join(key),
                        expected: "undefined",
                        value: value
                    });
                })));
                return ("object" === typeof input && null !== input && false === Array.isArray(input) || $guard(true, {
                    path: _path + "",
                    expected: "DockerWorkerConfigV3LightlyCheckpointCallback",
                    value: input
                })) && $ao0(input, _path + "", true) || $guard(true, {
                    path: _path + "",
                    expected: "DockerWorkerConfigV3LightlyCheckpointCallback",
                    value: input
                });
            })(input, "$input", true);
        return input;
    })(input);
};
export const DockerWorkerConfigV3LightlyCheckpointCallbackTypiaAssertStringify = (input: DockerWorkerConfigV3LightlyCheckpointCallback): string => {
    return ((input: any): string => { const assert = (input: any): DockerWorkerConfigV3LightlyCheckpointCallback => {
        const __is = (input: any): input is DockerWorkerConfigV3LightlyCheckpointCallback => {
            const $io0 = (input: any): boolean => undefined === input.saveLast || "boolean" === typeof input.saveLast;
            return "object" === typeof input && null !== input && false === Array.isArray(input) && $io0(input);
        };
        if (false === __is(input))
            ((input: any, _path: string, _exceptionable: boolean = true): input is DockerWorkerConfigV3LightlyCheckpointCallback => {
                const $guard = (typia.createAssertStringify as any).guard;
                const $ao0 = (input: any, _path: string, _exceptionable: boolean = true): boolean => undefined === input.saveLast || "boolean" === typeof input.saveLast || $guard(_exceptionable, {
                    path: _path + ".saveLast",
                    expected: "(boolean | undefined)",
                    value: input.saveLast
                });
                return ("object" === typeof input && null !== input && false === Array.isArray(input) || $guard(true, {
                    path: _path + "",
                    expected: "DockerWorkerConfigV3LightlyCheckpointCallback",
                    value: input
                })) && $ao0(input, _path + "", true) || $guard(true, {
                    path: _path + "",
                    expected: "DockerWorkerConfigV3LightlyCheckpointCallback",
                    value: input
                });
            })(input, "$input", true);
        return input;
    }; const stringify = (input: DockerWorkerConfigV3LightlyCheckpointCallback): string => {
        const $tail = (typia.createAssertStringify as any).tail;
        const $so0 = (input: any): any => `{${$tail(`${undefined === input.saveLast ? "" : `"saveLast":${undefined !== input.saveLast ? input.saveLast : undefined}`}`)}}`;
        return $so0(input);
    }; return stringify(assert(input)); })(input);
};
export const DockerWorkerConfigV3LightlyCheckpointCallbackValidatorShape = {
    'saveLast': yup.boolean(),
};
export const DockerWorkerConfigV3LightlyCheckpointCallbackValidator = yup.object().shape(DockerWorkerConfigV3LightlyCheckpointCallbackValidatorShape);
export const DockerWorkerConfigV3LightlyCheckpointCallbackStringifyShapeProperties: ObjectSchema['properties'] = {
    'saveLast': {
        'type': 'boolean',
    },
};
export const DockerWorkerConfigV3LightlyCheckpointCallbackStringifyShape: ObjectSchema = {
    title: 'DockerWorkerConfigV3LightlyCheckpointCallback Stringifier',
    type: 'object',
    properties: {
        ...DockerWorkerConfigV3LightlyCheckpointCallbackStringifyShapeProperties
    },
    required: [],
};
export const DockerWorkerConfigV3LightlyCheckpointCallbackStringifyCompiled = (typeof process === 'object' && String(process) === '[object process]') ?
    fastJSON.build(DockerWorkerConfigV3LightlyCheckpointCallbackStringifyShape, {
        ajv: {
            // since we are already doing this with yup, lets skip
            validateSchema: false,
            ownProperties: true,
            validateFormats: false,
        },
        largeArrayMechanism: 'json-stringify',
        largeArraySize: 1000,
    } as Options)
    :
        () => { throw new Error('fast-json-stringify only works in nodejs'); };
export const DockerWorkerConfigV3LightlyCheckpointCallbackStringify: (data: DockerWorkerConfigV3LightlyCheckpointCallback) => string = DockerWorkerConfigV3LightlyCheckpointCallbackStringifyCompiled;
/* LIGHTLY CUSTOM END -> Generic */
export function DockerWorkerConfigV3LightlyCheckpointCallbackFromJSON(json: any): DockerWorkerConfigV3LightlyCheckpointCallback {
    return DockerWorkerConfigV3LightlyCheckpointCallbackFromJSONTyped(json, false);
}
export function DockerWorkerConfigV3LightlyCheckpointCallbackFromJSONTyped(json: any, ignoreDiscriminator: boolean): DockerWorkerConfigV3LightlyCheckpointCallback {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    /* causes circular depdendencies
*/
    return {
        'saveLast': !exists(json, 'saveLast') ? undefined : json['saveLast'],
    };
}
export function DockerWorkerConfigV3LightlyCheckpointCallbackToJSON(value?: DockerWorkerConfigV3LightlyCheckpointCallback | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'saveLast': value.saveLast,
    };
}
