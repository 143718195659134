/* tslint:disable */
/* eslint-disable */
/**
 * Lightly API
 * Lightly.ai enables you to do self-supervised learning in an easy and intuitive way. The lightly.ai OpenAPI spec defines how one can interact with our REST API to unleash the full potential of lightly.ai
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: support@lightly.ai
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
// SRC: https://github.com/OpenAPITools/openapi-generator/blob/master/modules/openapi-generator/src/main/resources/typescript-fetch/modelGeneric.mustache
// synched 02.02.2023
import * as yup from 'yup';
import typia from 'typia';
import * as fastJSON from 'fast-json-stringify';
import { Options, ArraySchema, ObjectSchema } from 'fast-json-stringify';
import { exists, mapValues } from '../runtime';
import type { AutoTaskBase } from './AutoTaskBase';
import { AutoTaskBaseFromJSON, AutoTaskBaseFromJSONTyped, AutoTaskBaseToJSON, } from './AutoTaskBase';
/*
causes circular dependendies
// .concat(AutoTaskBaseValidator)

*/
// SRC: https://github.com/OpenAPITools/openapi-generator/blob/master/modules/openapi-generator/src/main/resources/typescript-fetch/modelGenericInterfaces.mustache
// synced: 17.07.2023
/**
 * Create multiple predictions which equally split the image into tiles
 * @export
 * @interface AutoTaskTiling
 *
 */
// create custom validators for the regexes
export interface AutoTaskTiling extends AutoTaskBase {
    /**
     * The number of rows the image should be split into
     * #type number
     * #memberof AutoTaskTiling
        * @type int
    * @minimum 1
            
         */
    numRows: number;
    /**
     * The number of column the image should be split into
     * #type number
     * #memberof AutoTaskTiling
        * @type int
    * @minimum 1
            
         */
    numCols: number;
    /**
     * The relative overlap between tiles. The overlap is a fraction of the tile size. For example, if the overlap is 0.1 and the tile size is 100, the overlap is 10 pixels.
     * #type number
     * #memberof AutoTaskTiling
        
    * @minimum 0.0
            
         */
    overlap?: number;
}
/**
 * Check if a given object implements the AutoTaskTiling interface.
 */
export function instanceOfAutoTaskTiling(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "numRows" in value;
    isInstance = isInstance && "numCols" in value;
    return isInstance;
}
/* LIGHTLY CUSTOM START -> modelGeneric */
import { AutoTaskBaseValidator, AutoTaskBaseStringifyShape, AutoTaskBaseStringifyShapeProperties, } from './AutoTaskBase';
export const AutoTaskTilingTypiaAssertEquals = (input: any) => {
    return ((input: any): AutoTaskTiling => {
        const __is = (input: any, _exceptionable: boolean = true): input is AutoTaskTiling => {
            const $is_custom = (typia.createAssertEquals as any).is_custom;
            const $io0 = (input: any, _exceptionable: boolean = true): boolean => "number" === typeof input.numRows && Math.floor(input.numRows) === input.numRows && (-2147483648 <= input.numRows && input.numRows <= 2147483647) && 1 <= input.numRows && ("number" === typeof input.numCols && Math.floor(input.numCols) === input.numCols && (-2147483648 <= input.numCols && input.numCols <= 2147483647) && 1 <= input.numCols) && (undefined === input.overlap || "number" === typeof input.overlap && 0 <= input.overlap) && "string" === typeof input.type && ("string" === typeof input.name && $is_custom("pattern_name", "string", "", input.name)) && (4 === Object.keys(input).length || Object.keys(input).every((key: any) => {
                if (["numRows", "numCols", "overlap", "type", "name"].some((prop: any) => key === prop))
                    return true;
                const value = input[key];
                if (undefined === value)
                    return true;
                return false;
            }));
            return "object" === typeof input && null !== input && $io0(input, true);
        };
        if (false === __is(input))
            ((input: any, _path: string, _exceptionable: boolean = true): input is AutoTaskTiling => {
                const $guard = (typia.createAssertEquals as any).guard;
                const $is_custom = (typia.createAssertEquals as any).is_custom;
                const $join = (typia.createAssertEquals as any).join;
                const $ao0 = (input: any, _path: string, _exceptionable: boolean = true): boolean => ("number" === typeof input.numRows && (Math.floor(input.numRows) === input.numRows || $guard(_exceptionable, {
                    path: _path + ".numRows",
                    expected: "number (@type int)",
                    value: input.numRows
                })) && (-2147483648 <= input.numRows && input.numRows <= 2147483647 || $guard(_exceptionable, {
                    path: _path + ".numRows",
                    expected: "number (@type int)",
                    value: input.numRows
                })) && (1 <= input.numRows || $guard(_exceptionable, {
                    path: _path + ".numRows",
                    expected: "number (@minimum 1)",
                    value: input.numRows
                })) || $guard(_exceptionable, {
                    path: _path + ".numRows",
                    expected: "number",
                    value: input.numRows
                })) && ("number" === typeof input.numCols && (Math.floor(input.numCols) === input.numCols || $guard(_exceptionable, {
                    path: _path + ".numCols",
                    expected: "number (@type int)",
                    value: input.numCols
                })) && (-2147483648 <= input.numCols && input.numCols <= 2147483647 || $guard(_exceptionable, {
                    path: _path + ".numCols",
                    expected: "number (@type int)",
                    value: input.numCols
                })) && (1 <= input.numCols || $guard(_exceptionable, {
                    path: _path + ".numCols",
                    expected: "number (@minimum 1)",
                    value: input.numCols
                })) || $guard(_exceptionable, {
                    path: _path + ".numCols",
                    expected: "number",
                    value: input.numCols
                })) && (undefined === input.overlap || "number" === typeof input.overlap && (0 <= input.overlap || $guard(_exceptionable, {
                    path: _path + ".overlap",
                    expected: "number (@minimum 0)",
                    value: input.overlap
                })) || $guard(_exceptionable, {
                    path: _path + ".overlap",
                    expected: "(number | undefined)",
                    value: input.overlap
                })) && ("string" === typeof input.type || $guard(_exceptionable, {
                    path: _path + ".type",
                    expected: "string",
                    value: input.type
                })) && ("string" === typeof input.name && ($is_custom("pattern_name", "string", "", input.name) || $guard(_exceptionable, {
                    path: _path + ".name",
                    expected: "string (@pattern_name)",
                    value: input.name
                })) || $guard(_exceptionable, {
                    path: _path + ".name",
                    expected: "string",
                    value: input.name
                })) && (4 === Object.keys(input).length || (false === _exceptionable || Object.keys(input).every((key: any) => {
                    if (["numRows", "numCols", "overlap", "type", "name"].some((prop: any) => key === prop))
                        return true;
                    const value = input[key];
                    if (undefined === value)
                        return true;
                    return $guard(_exceptionable, {
                        path: _path + $join(key),
                        expected: "undefined",
                        value: value
                    });
                })));
                return ("object" === typeof input && null !== input || $guard(true, {
                    path: _path + "",
                    expected: "AutoTaskTiling",
                    value: input
                })) && $ao0(input, _path + "", true) || $guard(true, {
                    path: _path + "",
                    expected: "AutoTaskTiling",
                    value: input
                });
            })(input, "$input", true);
        return input;
    })(input);
};
export const AutoTaskTilingTypiaAssertStringify = (input: AutoTaskTiling): string => {
    return ((input: any): string => { const assert = (input: any): AutoTaskTiling => {
        const __is = (input: any): input is AutoTaskTiling => {
            const $is_custom = (typia.createAssertStringify as any).is_custom;
            const $io0 = (input: any): boolean => "number" === typeof input.numRows && !Number.isNaN(input.numRows) && Math.floor(input.numRows) === input.numRows && (-2147483648 <= input.numRows && input.numRows <= 2147483647) && 1 <= input.numRows && ("number" === typeof input.numCols && !Number.isNaN(input.numCols) && Math.floor(input.numCols) === input.numCols && (-2147483648 <= input.numCols && input.numCols <= 2147483647) && 1 <= input.numCols) && (undefined === input.overlap || "number" === typeof input.overlap && !Number.isNaN(input.overlap) && 0 <= input.overlap) && "string" === typeof input.type && ("string" === typeof input.name && $is_custom("pattern_name", "string", "", input.name));
            return "object" === typeof input && null !== input && $io0(input);
        };
        if (false === __is(input))
            ((input: any, _path: string, _exceptionable: boolean = true): input is AutoTaskTiling => {
                const $guard = (typia.createAssertStringify as any).guard;
                const $is_custom = (typia.createAssertStringify as any).is_custom;
                const $ao0 = (input: any, _path: string, _exceptionable: boolean = true): boolean => ("number" === typeof input.numRows && !Number.isNaN(input.numRows) && (Math.floor(input.numRows) === input.numRows || $guard(_exceptionable, {
                    path: _path + ".numRows",
                    expected: "number (@type int)",
                    value: input.numRows
                })) && (-2147483648 <= input.numRows && input.numRows <= 2147483647 || $guard(_exceptionable, {
                    path: _path + ".numRows",
                    expected: "number (@type int)",
                    value: input.numRows
                })) && (1 <= input.numRows || $guard(_exceptionable, {
                    path: _path + ".numRows",
                    expected: "number (@minimum 1)",
                    value: input.numRows
                })) || $guard(_exceptionable, {
                    path: _path + ".numRows",
                    expected: "number",
                    value: input.numRows
                })) && ("number" === typeof input.numCols && !Number.isNaN(input.numCols) && (Math.floor(input.numCols) === input.numCols || $guard(_exceptionable, {
                    path: _path + ".numCols",
                    expected: "number (@type int)",
                    value: input.numCols
                })) && (-2147483648 <= input.numCols && input.numCols <= 2147483647 || $guard(_exceptionable, {
                    path: _path + ".numCols",
                    expected: "number (@type int)",
                    value: input.numCols
                })) && (1 <= input.numCols || $guard(_exceptionable, {
                    path: _path + ".numCols",
                    expected: "number (@minimum 1)",
                    value: input.numCols
                })) || $guard(_exceptionable, {
                    path: _path + ".numCols",
                    expected: "number",
                    value: input.numCols
                })) && (undefined === input.overlap || "number" === typeof input.overlap && !Number.isNaN(input.overlap) && (0 <= input.overlap || $guard(_exceptionable, {
                    path: _path + ".overlap",
                    expected: "number (@minimum 0)",
                    value: input.overlap
                })) || $guard(_exceptionable, {
                    path: _path + ".overlap",
                    expected: "(number | undefined)",
                    value: input.overlap
                })) && ("string" === typeof input.type || $guard(_exceptionable, {
                    path: _path + ".type",
                    expected: "string",
                    value: input.type
                })) && ("string" === typeof input.name && ($is_custom("pattern_name", "string", "", input.name) || $guard(_exceptionable, {
                    path: _path + ".name",
                    expected: "string (@pattern_name)",
                    value: input.name
                })) || $guard(_exceptionable, {
                    path: _path + ".name",
                    expected: "string",
                    value: input.name
                }));
                return ("object" === typeof input && null !== input || $guard(true, {
                    path: _path + "",
                    expected: "AutoTaskTiling",
                    value: input
                })) && $ao0(input, _path + "", true) || $guard(true, {
                    path: _path + "",
                    expected: "AutoTaskTiling",
                    value: input
                });
            })(input, "$input", true);
        return input;
    }; const stringify = (input: AutoTaskTiling): string => {
        const $string = (typia.createAssertStringify as any).string;
        const $is_custom = (typia.createAssertStringify as any).is_custom;
        const $so0 = (input: any): any => `{${undefined === input.overlap ? "" : `"overlap":${undefined !== input.overlap ? input.overlap : undefined},`}"numRows":${input.numRows},"numCols":${input.numCols},"type":${$string(input.type)},"name":${$string(input.name)}}`;
        return $so0(input);
    }; return stringify(assert(input)); })(input);
};
export const AutoTaskTilingValidatorShape = {
    'numRows': yup.number().integer().min(1).defined(),
    'numCols': yup.number().integer().min(1).defined(),
    'overlap': yup.number().min(0.0),
};
export const AutoTaskTilingValidator = yup.object().shape(AutoTaskTilingValidatorShape).concat(AutoTaskBaseValidator);
export const AutoTaskTilingStringifyShapeProperties: ObjectSchema['properties'] = {
    'numRows': {
        'type': 'integer',
        'minimum': 1,
    } as unknown as fastJSON.IntegerSchema,
    'numCols': {
        'type': 'integer',
        'minimum': 1,
    } as unknown as fastJSON.IntegerSchema,
    'overlap': {
        'type': 'number',
        'minimum': 0.0,
    } as unknown as fastJSON.NumberSchema,
};
export const AutoTaskTilingStringifyShape: ObjectSchema = {
    title: 'AutoTaskTiling Stringifier',
    type: 'object',
    properties: {
        ...AutoTaskBaseStringifyShapeProperties,
        ...AutoTaskTilingStringifyShapeProperties
    },
    required: [
        ...(AutoTaskBaseStringifyShape.required ? AutoTaskBaseStringifyShape.required : []),
        'numRows',
        'numCols',
    ],
};
export const AutoTaskTilingStringifyCompiled = (typeof process === 'object' && String(process) === '[object process]') ?
    fastJSON.build(AutoTaskTilingStringifyShape, {
        ajv: {
            // since we are already doing this with yup, lets skip
            validateSchema: false,
            ownProperties: true,
            validateFormats: false,
        },
        largeArrayMechanism: 'json-stringify',
        largeArraySize: 1000,
    } as Options)
    :
        () => { throw new Error('fast-json-stringify only works in nodejs'); };
export const AutoTaskTilingStringify: (data: AutoTaskTiling) => string = AutoTaskTilingStringifyCompiled;
/* LIGHTLY CUSTOM END -> Generic */
export function AutoTaskTilingFromJSON(json: any): AutoTaskTiling {
    return AutoTaskTilingFromJSONTyped(json, false);
}
export function AutoTaskTilingFromJSONTyped(json: any, ignoreDiscriminator: boolean): AutoTaskTiling {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    /* causes circular depdendencies
    if (!ignoreDiscriminator) {
    }
*/
    return {
        ...AutoTaskBaseFromJSONTyped(json, ignoreDiscriminator),
        'numRows': json['numRows'],
        'numCols': json['numCols'],
        'overlap': !exists(json, 'overlap') ? undefined : json['overlap'],
    };
}
export function AutoTaskTilingToJSON(value?: AutoTaskTiling | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        ...AutoTaskBaseToJSON(value),
        'numRows': value.numRows,
        'numCols': value.numCols,
        'overlap': value.overlap,
    };
}
