/* tslint:disable */
/* eslint-disable */
/**
 * Lightly API
 * Lightly.ai enables you to do self-supervised learning in an easy and intuitive way. The lightly.ai OpenAPI spec defines how one can interact with our REST API to unleash the full potential of lightly.ai
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: support@lightly.ai
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
// SRC: https://github.com/OpenAPITools/openapi-generator/blob/master/modules/openapi-generator/src/main/resources/typescript-fetch/modelGeneric.mustache
// synched 02.02.2023
import * as yup from 'yup';
import typia from 'typia';
import * as fastJSON from 'fast-json-stringify';
import { Options, ArraySchema, ObjectSchema } from 'fast-json-stringify';
import { exists, mapValues } from '../runtime';
import type { DockerWorkerConfigV2LightlyCollate } from './DockerWorkerConfigV2LightlyCollate';
import { DockerWorkerConfigV2LightlyCollateFromJSON, DockerWorkerConfigV2LightlyCollateFromJSONTyped, DockerWorkerConfigV2LightlyCollateToJSON, } from './DockerWorkerConfigV2LightlyCollate';
import type { DockerWorkerConfigV2LightlyModel } from './DockerWorkerConfigV2LightlyModel';
import { DockerWorkerConfigV2LightlyModelFromJSON, DockerWorkerConfigV2LightlyModelFromJSONTyped, DockerWorkerConfigV2LightlyModelToJSON, } from './DockerWorkerConfigV2LightlyModel';
import type { DockerWorkerConfigV2LightlyTrainer } from './DockerWorkerConfigV2LightlyTrainer';
import { DockerWorkerConfigV2LightlyTrainerFromJSON, DockerWorkerConfigV2LightlyTrainerFromJSONTyped, DockerWorkerConfigV2LightlyTrainerToJSON, } from './DockerWorkerConfigV2LightlyTrainer';
import type { DockerWorkerConfigV3LightlyCriterion } from './DockerWorkerConfigV3LightlyCriterion';
import { DockerWorkerConfigV3LightlyCriterionFromJSON, DockerWorkerConfigV3LightlyCriterionFromJSONTyped, DockerWorkerConfigV3LightlyCriterionToJSON, } from './DockerWorkerConfigV3LightlyCriterion';
import type { DockerWorkerConfigV3LightlyLoader } from './DockerWorkerConfigV3LightlyLoader';
import { DockerWorkerConfigV3LightlyLoaderFromJSON, DockerWorkerConfigV3LightlyLoaderFromJSONTyped, DockerWorkerConfigV3LightlyLoaderToJSON, } from './DockerWorkerConfigV3LightlyLoader';
import type { DockerWorkerConfigV3LightlyOptimizer } from './DockerWorkerConfigV3LightlyOptimizer';
import { DockerWorkerConfigV3LightlyOptimizerFromJSON, DockerWorkerConfigV3LightlyOptimizerFromJSONTyped, DockerWorkerConfigV3LightlyOptimizerToJSON, } from './DockerWorkerConfigV3LightlyOptimizer';
/*
causes circular dependendies
*/
// SRC: https://github.com/OpenAPITools/openapi-generator/blob/master/modules/openapi-generator/src/main/resources/typescript-fetch/modelGenericInterfaces.mustache
// synced: 17.07.2023
/**
 * Lightly configurations which are passed to a Lightly Worker run. For information
 * about the options see https://docs.lightly.ai/docs/all-configuration-options#run-configuration.
 * @export
 * @interface DockerWorkerConfigV2Lightly
 *
 */
// create custom validators for the regexes
export interface DockerWorkerConfigV2Lightly {
    /**
     *
     * #type DockerWorkerConfigV3LightlyLoader
     * #memberof DockerWorkerConfigV2Lightly
        
        
         */
    loader?: DockerWorkerConfigV3LightlyLoader;
    /**
     *
     * #type DockerWorkerConfigV2LightlyModel
     * #memberof DockerWorkerConfigV2Lightly
        
        
         */
    model?: DockerWorkerConfigV2LightlyModel;
    /**
     *
     * #type DockerWorkerConfigV2LightlyTrainer
     * #memberof DockerWorkerConfigV2Lightly
        
        
         */
    trainer?: DockerWorkerConfigV2LightlyTrainer;
    /**
     *
     * #type DockerWorkerConfigV3LightlyCriterion
     * #memberof DockerWorkerConfigV2Lightly
        
        
         */
    criterion?: DockerWorkerConfigV3LightlyCriterion;
    /**
     *
     * #type DockerWorkerConfigV3LightlyOptimizer
     * #memberof DockerWorkerConfigV2Lightly
        
        
         */
    optimizer?: DockerWorkerConfigV3LightlyOptimizer;
    /**
     *
     * #type DockerWorkerConfigV2LightlyCollate
     * #memberof DockerWorkerConfigV2Lightly
        
        
         */
    collate?: DockerWorkerConfigV2LightlyCollate;
}
/**
 * Check if a given object implements the DockerWorkerConfigV2Lightly interface.
 */
export function instanceOfDockerWorkerConfigV2Lightly(value: object): boolean {
    let isInstance = true;
    return isInstance;
}
/* LIGHTLY CUSTOM START -> modelGeneric */
import { DockerWorkerConfigV2LightlyCollateValidator, DockerWorkerConfigV2LightlyCollateStringifyShape, DockerWorkerConfigV2LightlyCollateStringifyShapeProperties, } from './DockerWorkerConfigV2LightlyCollate';
import { DockerWorkerConfigV2LightlyModelValidator, DockerWorkerConfigV2LightlyModelStringifyShape, DockerWorkerConfigV2LightlyModelStringifyShapeProperties, } from './DockerWorkerConfigV2LightlyModel';
import { DockerWorkerConfigV2LightlyTrainerValidator, DockerWorkerConfigV2LightlyTrainerStringifyShape, DockerWorkerConfigV2LightlyTrainerStringifyShapeProperties, } from './DockerWorkerConfigV2LightlyTrainer';
import { DockerWorkerConfigV3LightlyCriterionValidator, DockerWorkerConfigV3LightlyCriterionStringifyShape, DockerWorkerConfigV3LightlyCriterionStringifyShapeProperties, } from './DockerWorkerConfigV3LightlyCriterion';
import { DockerWorkerConfigV3LightlyLoaderValidator, DockerWorkerConfigV3LightlyLoaderStringifyShape, DockerWorkerConfigV3LightlyLoaderStringifyShapeProperties, } from './DockerWorkerConfigV3LightlyLoader';
import { DockerWorkerConfigV3LightlyOptimizerValidator, DockerWorkerConfigV3LightlyOptimizerStringifyShape, DockerWorkerConfigV3LightlyOptimizerStringifyShapeProperties, } from './DockerWorkerConfigV3LightlyOptimizer';
export const DockerWorkerConfigV2LightlyTypiaAssertEquals = (input: any) => {
    return ((input: any): DockerWorkerConfigV2Lightly => {
        const __is = (input: any, _exceptionable: boolean = true): input is DockerWorkerConfigV2Lightly => {
            const $io0 = (input: any, _exceptionable: boolean = true): boolean => (undefined === input.loader || "object" === typeof input.loader && null !== input.loader && false === Array.isArray(input.loader) && $io1(input.loader, true && _exceptionable)) && (undefined === input.model || "object" === typeof input.model && null !== input.model && false === Array.isArray(input.model) && $io2(input.model, true && _exceptionable)) && (undefined === input.trainer || "object" === typeof input.trainer && null !== input.trainer && false === Array.isArray(input.trainer) && $io3(input.trainer, true && _exceptionable)) && (undefined === input.criterion || "object" === typeof input.criterion && null !== input.criterion && false === Array.isArray(input.criterion) && $io4(input.criterion, true && _exceptionable)) && (undefined === input.optimizer || "object" === typeof input.optimizer && null !== input.optimizer && false === Array.isArray(input.optimizer) && $io5(input.optimizer, true && _exceptionable)) && (undefined === input.collate || "object" === typeof input.collate && null !== input.collate && false === Array.isArray(input.collate) && $io6(input.collate, true && _exceptionable)) && (0 === Object.keys(input).length || Object.keys(input).every((key: any) => {
                if (["loader", "model", "trainer", "criterion", "optimizer", "collate"].some((prop: any) => key === prop))
                    return true;
                const value = input[key];
                if (undefined === value)
                    return true;
                return false;
            }));
            const $io1 = (input: any, _exceptionable: boolean = true): boolean => (undefined === input.batchSize || "number" === typeof input.batchSize && Math.floor(input.batchSize) === input.batchSize && (-2147483648 <= input.batchSize && input.batchSize <= 2147483647) && 1 <= input.batchSize) && (undefined === input.shuffle || "boolean" === typeof input.shuffle) && (undefined === input.numWorkers || "number" === typeof input.numWorkers && Math.floor(input.numWorkers) === input.numWorkers && (-2147483648 <= input.numWorkers && input.numWorkers <= 2147483647) && -1 <= input.numWorkers) && (undefined === input.dropLast || "boolean" === typeof input.dropLast) && (0 === Object.keys(input).length || Object.keys(input).every((key: any) => {
                if (["batchSize", "shuffle", "numWorkers", "dropLast"].some((prop: any) => key === prop))
                    return true;
                const value = input[key];
                if (undefined === value)
                    return true;
                return false;
            }));
            const $io2 = (input: any, _exceptionable: boolean = true): boolean => (undefined === input.name || "resnet-18" === input.name || "resnet-34" === input.name || "resnet-50" === input.name || "resnet-101" === input.name || "resnet-152" === input.name) && (undefined === input.outDim || "number" === typeof input.outDim && Math.floor(input.outDim) === input.outDim && (-2147483648 <= input.outDim && input.outDim <= 2147483647) && 1 <= input.outDim) && (undefined === input.numFtrs || "number" === typeof input.numFtrs && Math.floor(input.numFtrs) === input.numFtrs && (-2147483648 <= input.numFtrs && input.numFtrs <= 2147483647) && 1 <= input.numFtrs) && (undefined === input.width || "number" === typeof input.width && Math.floor(input.width) === input.width && (-2147483648 <= input.width && input.width <= 2147483647) && 1 <= input.width) && (0 === Object.keys(input).length || Object.keys(input).every((key: any) => {
                if (["name", "outDim", "numFtrs", "width"].some((prop: any) => key === prop))
                    return true;
                const value = input[key];
                if (undefined === value)
                    return true;
                return false;
            }));
            const $io3 = (input: any, _exceptionable: boolean = true): boolean => (undefined === input.gpus || "number" === typeof input.gpus && Math.floor(input.gpus) === input.gpus && (-2147483648 <= input.gpus && input.gpus <= 2147483647) && 0 <= input.gpus) && (undefined === input.maxEpochs || "number" === typeof input.maxEpochs && Math.floor(input.maxEpochs) === input.maxEpochs && (-2147483648 <= input.maxEpochs && input.maxEpochs <= 2147483647) && 0 <= input.maxEpochs) && (undefined === input.precision || 16 === input.precision || 32 === input.precision) && (0 === Object.keys(input).length || Object.keys(input).every((key: any) => {
                if (["gpus", "maxEpochs", "precision"].some((prop: any) => key === prop))
                    return true;
                const value = input[key];
                if (undefined === value)
                    return true;
                return false;
            }));
            const $io4 = (input: any, _exceptionable: boolean = true): boolean => (undefined === input.temperature || "number" === typeof input.temperature && 0 < input.temperature) && (0 === Object.keys(input).length || Object.keys(input).every((key: any) => {
                if (["temperature"].some((prop: any) => key === prop))
                    return true;
                const value = input[key];
                if (undefined === value)
                    return true;
                return false;
            }));
            const $io5 = (input: any, _exceptionable: boolean = true): boolean => (undefined === input.lr || "number" === typeof input.lr && 0 <= input.lr) && (undefined === input.weightDecay || "number" === typeof input.weightDecay && 0 <= input.weightDecay && 1 >= input.weightDecay) && (0 === Object.keys(input).length || Object.keys(input).every((key: any) => {
                if (["lr", "weightDecay"].some((prop: any) => key === prop))
                    return true;
                const value = input[key];
                if (undefined === value)
                    return true;
                return false;
            }));
            const $io6 = (input: any, _exceptionable: boolean = true): boolean => (undefined === input.inputSize || "number" === typeof input.inputSize && Math.floor(input.inputSize) === input.inputSize && (-2147483648 <= input.inputSize && input.inputSize <= 2147483647) && 1 <= input.inputSize) && (undefined === input.cjProb || "number" === typeof input.cjProb && 0 <= input.cjProb && 1 >= input.cjProb) && (undefined === input.cjBright || "number" === typeof input.cjBright && 0 <= input.cjBright && 1 >= input.cjBright) && (undefined === input.cjContrast || "number" === typeof input.cjContrast && 0 <= input.cjContrast && 1 >= input.cjContrast) && (undefined === input.cjSat || "number" === typeof input.cjSat && 0 <= input.cjSat && 1 >= input.cjSat) && (undefined === input.cjHue || "number" === typeof input.cjHue && 0 <= input.cjHue && 1 >= input.cjHue) && (undefined === input.minScale || "number" === typeof input.minScale && 0 <= input.minScale && 1 >= input.minScale) && (undefined === input.randomGrayScale || "number" === typeof input.randomGrayScale && 0 <= input.randomGrayScale && 1 >= input.randomGrayScale) && (undefined === input.gaussianBlur || "number" === typeof input.gaussianBlur && 0 <= input.gaussianBlur && 1 >= input.gaussianBlur) && (undefined === input.kernelSize || "number" === typeof input.kernelSize && 0 <= input.kernelSize) && (undefined === input.sigmas || Array.isArray(input.sigmas) && input.sigmas.every((elem: any, _index1: number) => "number" === typeof elem)) && (undefined === input.vfProb || "number" === typeof input.vfProb && 0 <= input.vfProb && 1 >= input.vfProb) && (undefined === input.hfProb || "number" === typeof input.hfProb && 0 <= input.hfProb && 1 >= input.hfProb) && (undefined === input.rrProb || "number" === typeof input.rrProb && 0 <= input.rrProb && 1 >= input.rrProb) && (0 === Object.keys(input).length || Object.keys(input).every((key: any) => {
                if (["inputSize", "cjProb", "cjBright", "cjContrast", "cjSat", "cjHue", "minScale", "randomGrayScale", "gaussianBlur", "kernelSize", "sigmas", "vfProb", "hfProb", "rrProb"].some((prop: any) => key === prop))
                    return true;
                const value = input[key];
                if (undefined === value)
                    return true;
                return false;
            }));
            return "object" === typeof input && null !== input && false === Array.isArray(input) && $io0(input, true);
        };
        if (false === __is(input))
            ((input: any, _path: string, _exceptionable: boolean = true): input is DockerWorkerConfigV2Lightly => {
                const $guard = (typia.createAssertEquals as any).guard;
                const $join = (typia.createAssertEquals as any).join;
                const $ao0 = (input: any, _path: string, _exceptionable: boolean = true): boolean => (undefined === input.loader || ("object" === typeof input.loader && null !== input.loader && false === Array.isArray(input.loader) || $guard(_exceptionable, {
                    path: _path + ".loader",
                    expected: "(DockerWorkerConfigV3LightlyLoader | undefined)",
                    value: input.loader
                })) && $ao1(input.loader, _path + ".loader", true && _exceptionable) || $guard(_exceptionable, {
                    path: _path + ".loader",
                    expected: "(DockerWorkerConfigV3LightlyLoader | undefined)",
                    value: input.loader
                })) && (undefined === input.model || ("object" === typeof input.model && null !== input.model && false === Array.isArray(input.model) || $guard(_exceptionable, {
                    path: _path + ".model",
                    expected: "(DockerWorkerConfigV2LightlyModel | undefined)",
                    value: input.model
                })) && $ao2(input.model, _path + ".model", true && _exceptionable) || $guard(_exceptionable, {
                    path: _path + ".model",
                    expected: "(DockerWorkerConfigV2LightlyModel | undefined)",
                    value: input.model
                })) && (undefined === input.trainer || ("object" === typeof input.trainer && null !== input.trainer && false === Array.isArray(input.trainer) || $guard(_exceptionable, {
                    path: _path + ".trainer",
                    expected: "(DockerWorkerConfigV2LightlyTrainer | undefined)",
                    value: input.trainer
                })) && $ao3(input.trainer, _path + ".trainer", true && _exceptionable) || $guard(_exceptionable, {
                    path: _path + ".trainer",
                    expected: "(DockerWorkerConfigV2LightlyTrainer | undefined)",
                    value: input.trainer
                })) && (undefined === input.criterion || ("object" === typeof input.criterion && null !== input.criterion && false === Array.isArray(input.criterion) || $guard(_exceptionable, {
                    path: _path + ".criterion",
                    expected: "(DockerWorkerConfigV3LightlyCriterion | undefined)",
                    value: input.criterion
                })) && $ao4(input.criterion, _path + ".criterion", true && _exceptionable) || $guard(_exceptionable, {
                    path: _path + ".criterion",
                    expected: "(DockerWorkerConfigV3LightlyCriterion | undefined)",
                    value: input.criterion
                })) && (undefined === input.optimizer || ("object" === typeof input.optimizer && null !== input.optimizer && false === Array.isArray(input.optimizer) || $guard(_exceptionable, {
                    path: _path + ".optimizer",
                    expected: "(DockerWorkerConfigV3LightlyOptimizer | undefined)",
                    value: input.optimizer
                })) && $ao5(input.optimizer, _path + ".optimizer", true && _exceptionable) || $guard(_exceptionable, {
                    path: _path + ".optimizer",
                    expected: "(DockerWorkerConfigV3LightlyOptimizer | undefined)",
                    value: input.optimizer
                })) && (undefined === input.collate || ("object" === typeof input.collate && null !== input.collate && false === Array.isArray(input.collate) || $guard(_exceptionable, {
                    path: _path + ".collate",
                    expected: "(DockerWorkerConfigV2LightlyCollate | undefined)",
                    value: input.collate
                })) && $ao6(input.collate, _path + ".collate", true && _exceptionable) || $guard(_exceptionable, {
                    path: _path + ".collate",
                    expected: "(DockerWorkerConfigV2LightlyCollate | undefined)",
                    value: input.collate
                })) && (0 === Object.keys(input).length || (false === _exceptionable || Object.keys(input).every((key: any) => {
                    if (["loader", "model", "trainer", "criterion", "optimizer", "collate"].some((prop: any) => key === prop))
                        return true;
                    const value = input[key];
                    if (undefined === value)
                        return true;
                    return $guard(_exceptionable, {
                        path: _path + $join(key),
                        expected: "undefined",
                        value: value
                    });
                })));
                const $ao1 = (input: any, _path: string, _exceptionable: boolean = true): boolean => (undefined === input.batchSize || "number" === typeof input.batchSize && (Math.floor(input.batchSize) === input.batchSize || $guard(_exceptionable, {
                    path: _path + ".batchSize",
                    expected: "number (@type int)",
                    value: input.batchSize
                })) && (-2147483648 <= input.batchSize && input.batchSize <= 2147483647 || $guard(_exceptionable, {
                    path: _path + ".batchSize",
                    expected: "number (@type int)",
                    value: input.batchSize
                })) && (1 <= input.batchSize || $guard(_exceptionable, {
                    path: _path + ".batchSize",
                    expected: "number (@minimum 1)",
                    value: input.batchSize
                })) || $guard(_exceptionable, {
                    path: _path + ".batchSize",
                    expected: "(number | undefined)",
                    value: input.batchSize
                })) && (undefined === input.shuffle || "boolean" === typeof input.shuffle || $guard(_exceptionable, {
                    path: _path + ".shuffle",
                    expected: "(boolean | undefined)",
                    value: input.shuffle
                })) && (undefined === input.numWorkers || "number" === typeof input.numWorkers && (Math.floor(input.numWorkers) === input.numWorkers || $guard(_exceptionable, {
                    path: _path + ".numWorkers",
                    expected: "number (@type int)",
                    value: input.numWorkers
                })) && (-2147483648 <= input.numWorkers && input.numWorkers <= 2147483647 || $guard(_exceptionable, {
                    path: _path + ".numWorkers",
                    expected: "number (@type int)",
                    value: input.numWorkers
                })) && (-1 <= input.numWorkers || $guard(_exceptionable, {
                    path: _path + ".numWorkers",
                    expected: "number (@minimum -1)",
                    value: input.numWorkers
                })) || $guard(_exceptionable, {
                    path: _path + ".numWorkers",
                    expected: "(number | undefined)",
                    value: input.numWorkers
                })) && (undefined === input.dropLast || "boolean" === typeof input.dropLast || $guard(_exceptionable, {
                    path: _path + ".dropLast",
                    expected: "(boolean | undefined)",
                    value: input.dropLast
                })) && (0 === Object.keys(input).length || (false === _exceptionable || Object.keys(input).every((key: any) => {
                    if (["batchSize", "shuffle", "numWorkers", "dropLast"].some((prop: any) => key === prop))
                        return true;
                    const value = input[key];
                    if (undefined === value)
                        return true;
                    return $guard(_exceptionable, {
                        path: _path + $join(key),
                        expected: "undefined",
                        value: value
                    });
                })));
                const $ao2 = (input: any, _path: string, _exceptionable: boolean = true): boolean => (undefined === input.name || "resnet-18" === input.name || "resnet-34" === input.name || "resnet-50" === input.name || "resnet-101" === input.name || "resnet-152" === input.name || $guard(_exceptionable, {
                    path: _path + ".name",
                    expected: "(\"resnet-101\" | \"resnet-152\" | \"resnet-18\" | \"resnet-34\" | \"resnet-50\" | undefined)",
                    value: input.name
                })) && (undefined === input.outDim || "number" === typeof input.outDim && (Math.floor(input.outDim) === input.outDim || $guard(_exceptionable, {
                    path: _path + ".outDim",
                    expected: "number (@type int)",
                    value: input.outDim
                })) && (-2147483648 <= input.outDim && input.outDim <= 2147483647 || $guard(_exceptionable, {
                    path: _path + ".outDim",
                    expected: "number (@type int)",
                    value: input.outDim
                })) && (1 <= input.outDim || $guard(_exceptionable, {
                    path: _path + ".outDim",
                    expected: "number (@minimum 1)",
                    value: input.outDim
                })) || $guard(_exceptionable, {
                    path: _path + ".outDim",
                    expected: "(number | undefined)",
                    value: input.outDim
                })) && (undefined === input.numFtrs || "number" === typeof input.numFtrs && (Math.floor(input.numFtrs) === input.numFtrs || $guard(_exceptionable, {
                    path: _path + ".numFtrs",
                    expected: "number (@type int)",
                    value: input.numFtrs
                })) && (-2147483648 <= input.numFtrs && input.numFtrs <= 2147483647 || $guard(_exceptionable, {
                    path: _path + ".numFtrs",
                    expected: "number (@type int)",
                    value: input.numFtrs
                })) && (1 <= input.numFtrs || $guard(_exceptionable, {
                    path: _path + ".numFtrs",
                    expected: "number (@minimum 1)",
                    value: input.numFtrs
                })) || $guard(_exceptionable, {
                    path: _path + ".numFtrs",
                    expected: "(number | undefined)",
                    value: input.numFtrs
                })) && (undefined === input.width || "number" === typeof input.width && (Math.floor(input.width) === input.width || $guard(_exceptionable, {
                    path: _path + ".width",
                    expected: "number (@type int)",
                    value: input.width
                })) && (-2147483648 <= input.width && input.width <= 2147483647 || $guard(_exceptionable, {
                    path: _path + ".width",
                    expected: "number (@type int)",
                    value: input.width
                })) && (1 <= input.width || $guard(_exceptionable, {
                    path: _path + ".width",
                    expected: "number (@minimum 1)",
                    value: input.width
                })) || $guard(_exceptionable, {
                    path: _path + ".width",
                    expected: "(number | undefined)",
                    value: input.width
                })) && (0 === Object.keys(input).length || (false === _exceptionable || Object.keys(input).every((key: any) => {
                    if (["name", "outDim", "numFtrs", "width"].some((prop: any) => key === prop))
                        return true;
                    const value = input[key];
                    if (undefined === value)
                        return true;
                    return $guard(_exceptionable, {
                        path: _path + $join(key),
                        expected: "undefined",
                        value: value
                    });
                })));
                const $ao3 = (input: any, _path: string, _exceptionable: boolean = true): boolean => (undefined === input.gpus || "number" === typeof input.gpus && (Math.floor(input.gpus) === input.gpus || $guard(_exceptionable, {
                    path: _path + ".gpus",
                    expected: "number (@type int)",
                    value: input.gpus
                })) && (-2147483648 <= input.gpus && input.gpus <= 2147483647 || $guard(_exceptionable, {
                    path: _path + ".gpus",
                    expected: "number (@type int)",
                    value: input.gpus
                })) && (0 <= input.gpus || $guard(_exceptionable, {
                    path: _path + ".gpus",
                    expected: "number (@minimum 0)",
                    value: input.gpus
                })) || $guard(_exceptionable, {
                    path: _path + ".gpus",
                    expected: "(number | undefined)",
                    value: input.gpus
                })) && (undefined === input.maxEpochs || "number" === typeof input.maxEpochs && (Math.floor(input.maxEpochs) === input.maxEpochs || $guard(_exceptionable, {
                    path: _path + ".maxEpochs",
                    expected: "number (@type int)",
                    value: input.maxEpochs
                })) && (-2147483648 <= input.maxEpochs && input.maxEpochs <= 2147483647 || $guard(_exceptionable, {
                    path: _path + ".maxEpochs",
                    expected: "number (@type int)",
                    value: input.maxEpochs
                })) && (0 <= input.maxEpochs || $guard(_exceptionable, {
                    path: _path + ".maxEpochs",
                    expected: "number (@minimum 0)",
                    value: input.maxEpochs
                })) || $guard(_exceptionable, {
                    path: _path + ".maxEpochs",
                    expected: "(number | undefined)",
                    value: input.maxEpochs
                })) && (undefined === input.precision || 16 === input.precision || 32 === input.precision || $guard(_exceptionable, {
                    path: _path + ".precision",
                    expected: "(16 | 32 | undefined)",
                    value: input.precision
                })) && (0 === Object.keys(input).length || (false === _exceptionable || Object.keys(input).every((key: any) => {
                    if (["gpus", "maxEpochs", "precision"].some((prop: any) => key === prop))
                        return true;
                    const value = input[key];
                    if (undefined === value)
                        return true;
                    return $guard(_exceptionable, {
                        path: _path + $join(key),
                        expected: "undefined",
                        value: value
                    });
                })));
                const $ao4 = (input: any, _path: string, _exceptionable: boolean = true): boolean => (undefined === input.temperature || "number" === typeof input.temperature && (0 < input.temperature || $guard(_exceptionable, {
                    path: _path + ".temperature",
                    expected: "number (@exclusiveMinimum 0)",
                    value: input.temperature
                })) || $guard(_exceptionable, {
                    path: _path + ".temperature",
                    expected: "(number | undefined)",
                    value: input.temperature
                })) && (0 === Object.keys(input).length || (false === _exceptionable || Object.keys(input).every((key: any) => {
                    if (["temperature"].some((prop: any) => key === prop))
                        return true;
                    const value = input[key];
                    if (undefined === value)
                        return true;
                    return $guard(_exceptionable, {
                        path: _path + $join(key),
                        expected: "undefined",
                        value: value
                    });
                })));
                const $ao5 = (input: any, _path: string, _exceptionable: boolean = true): boolean => (undefined === input.lr || "number" === typeof input.lr && (0 <= input.lr || $guard(_exceptionable, {
                    path: _path + ".lr",
                    expected: "number (@minimum 0)",
                    value: input.lr
                })) || $guard(_exceptionable, {
                    path: _path + ".lr",
                    expected: "(number | undefined)",
                    value: input.lr
                })) && (undefined === input.weightDecay || "number" === typeof input.weightDecay && (0 <= input.weightDecay || $guard(_exceptionable, {
                    path: _path + ".weightDecay",
                    expected: "number (@minimum 0)",
                    value: input.weightDecay
                })) && (1 >= input.weightDecay || $guard(_exceptionable, {
                    path: _path + ".weightDecay",
                    expected: "number (@maximum 1)",
                    value: input.weightDecay
                })) || $guard(_exceptionable, {
                    path: _path + ".weightDecay",
                    expected: "(number | undefined)",
                    value: input.weightDecay
                })) && (0 === Object.keys(input).length || (false === _exceptionable || Object.keys(input).every((key: any) => {
                    if (["lr", "weightDecay"].some((prop: any) => key === prop))
                        return true;
                    const value = input[key];
                    if (undefined === value)
                        return true;
                    return $guard(_exceptionable, {
                        path: _path + $join(key),
                        expected: "undefined",
                        value: value
                    });
                })));
                const $ao6 = (input: any, _path: string, _exceptionable: boolean = true): boolean => (undefined === input.inputSize || "number" === typeof input.inputSize && (Math.floor(input.inputSize) === input.inputSize || $guard(_exceptionable, {
                    path: _path + ".inputSize",
                    expected: "number (@type int)",
                    value: input.inputSize
                })) && (-2147483648 <= input.inputSize && input.inputSize <= 2147483647 || $guard(_exceptionable, {
                    path: _path + ".inputSize",
                    expected: "number (@type int)",
                    value: input.inputSize
                })) && (1 <= input.inputSize || $guard(_exceptionable, {
                    path: _path + ".inputSize",
                    expected: "number (@minimum 1)",
                    value: input.inputSize
                })) || $guard(_exceptionable, {
                    path: _path + ".inputSize",
                    expected: "(number | undefined)",
                    value: input.inputSize
                })) && (undefined === input.cjProb || "number" === typeof input.cjProb && (0 <= input.cjProb || $guard(_exceptionable, {
                    path: _path + ".cjProb",
                    expected: "number (@minimum 0)",
                    value: input.cjProb
                })) && (1 >= input.cjProb || $guard(_exceptionable, {
                    path: _path + ".cjProb",
                    expected: "number (@maximum 1)",
                    value: input.cjProb
                })) || $guard(_exceptionable, {
                    path: _path + ".cjProb",
                    expected: "(number | undefined)",
                    value: input.cjProb
                })) && (undefined === input.cjBright || "number" === typeof input.cjBright && (0 <= input.cjBright || $guard(_exceptionable, {
                    path: _path + ".cjBright",
                    expected: "number (@minimum 0)",
                    value: input.cjBright
                })) && (1 >= input.cjBright || $guard(_exceptionable, {
                    path: _path + ".cjBright",
                    expected: "number (@maximum 1)",
                    value: input.cjBright
                })) || $guard(_exceptionable, {
                    path: _path + ".cjBright",
                    expected: "(number | undefined)",
                    value: input.cjBright
                })) && (undefined === input.cjContrast || "number" === typeof input.cjContrast && (0 <= input.cjContrast || $guard(_exceptionable, {
                    path: _path + ".cjContrast",
                    expected: "number (@minimum 0)",
                    value: input.cjContrast
                })) && (1 >= input.cjContrast || $guard(_exceptionable, {
                    path: _path + ".cjContrast",
                    expected: "number (@maximum 1)",
                    value: input.cjContrast
                })) || $guard(_exceptionable, {
                    path: _path + ".cjContrast",
                    expected: "(number | undefined)",
                    value: input.cjContrast
                })) && (undefined === input.cjSat || "number" === typeof input.cjSat && (0 <= input.cjSat || $guard(_exceptionable, {
                    path: _path + ".cjSat",
                    expected: "number (@minimum 0)",
                    value: input.cjSat
                })) && (1 >= input.cjSat || $guard(_exceptionable, {
                    path: _path + ".cjSat",
                    expected: "number (@maximum 1)",
                    value: input.cjSat
                })) || $guard(_exceptionable, {
                    path: _path + ".cjSat",
                    expected: "(number | undefined)",
                    value: input.cjSat
                })) && (undefined === input.cjHue || "number" === typeof input.cjHue && (0 <= input.cjHue || $guard(_exceptionable, {
                    path: _path + ".cjHue",
                    expected: "number (@minimum 0)",
                    value: input.cjHue
                })) && (1 >= input.cjHue || $guard(_exceptionable, {
                    path: _path + ".cjHue",
                    expected: "number (@maximum 1)",
                    value: input.cjHue
                })) || $guard(_exceptionable, {
                    path: _path + ".cjHue",
                    expected: "(number | undefined)",
                    value: input.cjHue
                })) && (undefined === input.minScale || "number" === typeof input.minScale && (0 <= input.minScale || $guard(_exceptionable, {
                    path: _path + ".minScale",
                    expected: "number (@minimum 0)",
                    value: input.minScale
                })) && (1 >= input.minScale || $guard(_exceptionable, {
                    path: _path + ".minScale",
                    expected: "number (@maximum 1)",
                    value: input.minScale
                })) || $guard(_exceptionable, {
                    path: _path + ".minScale",
                    expected: "(number | undefined)",
                    value: input.minScale
                })) && (undefined === input.randomGrayScale || "number" === typeof input.randomGrayScale && (0 <= input.randomGrayScale || $guard(_exceptionable, {
                    path: _path + ".randomGrayScale",
                    expected: "number (@minimum 0)",
                    value: input.randomGrayScale
                })) && (1 >= input.randomGrayScale || $guard(_exceptionable, {
                    path: _path + ".randomGrayScale",
                    expected: "number (@maximum 1)",
                    value: input.randomGrayScale
                })) || $guard(_exceptionable, {
                    path: _path + ".randomGrayScale",
                    expected: "(number | undefined)",
                    value: input.randomGrayScale
                })) && (undefined === input.gaussianBlur || "number" === typeof input.gaussianBlur && (0 <= input.gaussianBlur || $guard(_exceptionable, {
                    path: _path + ".gaussianBlur",
                    expected: "number (@minimum 0)",
                    value: input.gaussianBlur
                })) && (1 >= input.gaussianBlur || $guard(_exceptionable, {
                    path: _path + ".gaussianBlur",
                    expected: "number (@maximum 1)",
                    value: input.gaussianBlur
                })) || $guard(_exceptionable, {
                    path: _path + ".gaussianBlur",
                    expected: "(number | undefined)",
                    value: input.gaussianBlur
                })) && (undefined === input.kernelSize || "number" === typeof input.kernelSize && (0 <= input.kernelSize || $guard(_exceptionable, {
                    path: _path + ".kernelSize",
                    expected: "number (@minimum 0)",
                    value: input.kernelSize
                })) || $guard(_exceptionable, {
                    path: _path + ".kernelSize",
                    expected: "(number | undefined)",
                    value: input.kernelSize
                })) && (undefined === input.sigmas || (Array.isArray(input.sigmas) || $guard(_exceptionable, {
                    path: _path + ".sigmas",
                    expected: "(Array<number> | undefined)",
                    value: input.sigmas
                })) && input.sigmas.every((elem: any, _index1: number) => "number" === typeof elem || $guard(_exceptionable, {
                    path: _path + ".sigmas[" + _index1 + "]",
                    expected: "number",
                    value: elem
                })) || $guard(_exceptionable, {
                    path: _path + ".sigmas",
                    expected: "(Array<number> | undefined)",
                    value: input.sigmas
                })) && (undefined === input.vfProb || "number" === typeof input.vfProb && (0 <= input.vfProb || $guard(_exceptionable, {
                    path: _path + ".vfProb",
                    expected: "number (@minimum 0)",
                    value: input.vfProb
                })) && (1 >= input.vfProb || $guard(_exceptionable, {
                    path: _path + ".vfProb",
                    expected: "number (@maximum 1)",
                    value: input.vfProb
                })) || $guard(_exceptionable, {
                    path: _path + ".vfProb",
                    expected: "(number | undefined)",
                    value: input.vfProb
                })) && (undefined === input.hfProb || "number" === typeof input.hfProb && (0 <= input.hfProb || $guard(_exceptionable, {
                    path: _path + ".hfProb",
                    expected: "number (@minimum 0)",
                    value: input.hfProb
                })) && (1 >= input.hfProb || $guard(_exceptionable, {
                    path: _path + ".hfProb",
                    expected: "number (@maximum 1)",
                    value: input.hfProb
                })) || $guard(_exceptionable, {
                    path: _path + ".hfProb",
                    expected: "(number | undefined)",
                    value: input.hfProb
                })) && (undefined === input.rrProb || "number" === typeof input.rrProb && (0 <= input.rrProb || $guard(_exceptionable, {
                    path: _path + ".rrProb",
                    expected: "number (@minimum 0)",
                    value: input.rrProb
                })) && (1 >= input.rrProb || $guard(_exceptionable, {
                    path: _path + ".rrProb",
                    expected: "number (@maximum 1)",
                    value: input.rrProb
                })) || $guard(_exceptionable, {
                    path: _path + ".rrProb",
                    expected: "(number | undefined)",
                    value: input.rrProb
                })) && (0 === Object.keys(input).length || (false === _exceptionable || Object.keys(input).every((key: any) => {
                    if (["inputSize", "cjProb", "cjBright", "cjContrast", "cjSat", "cjHue", "minScale", "randomGrayScale", "gaussianBlur", "kernelSize", "sigmas", "vfProb", "hfProb", "rrProb"].some((prop: any) => key === prop))
                        return true;
                    const value = input[key];
                    if (undefined === value)
                        return true;
                    return $guard(_exceptionable, {
                        path: _path + $join(key),
                        expected: "undefined",
                        value: value
                    });
                })));
                return ("object" === typeof input && null !== input && false === Array.isArray(input) || $guard(true, {
                    path: _path + "",
                    expected: "DockerWorkerConfigV2Lightly",
                    value: input
                })) && $ao0(input, _path + "", true) || $guard(true, {
                    path: _path + "",
                    expected: "DockerWorkerConfigV2Lightly",
                    value: input
                });
            })(input, "$input", true);
        return input;
    })(input);
};
export const DockerWorkerConfigV2LightlyTypiaAssertStringify = (input: DockerWorkerConfigV2Lightly): string => {
    return ((input: any): string => { const assert = (input: any): DockerWorkerConfigV2Lightly => {
        const __is = (input: any): input is DockerWorkerConfigV2Lightly => {
            const $io0 = (input: any): boolean => (undefined === input.loader || "object" === typeof input.loader && null !== input.loader && false === Array.isArray(input.loader) && $io1(input.loader)) && (undefined === input.model || "object" === typeof input.model && null !== input.model && false === Array.isArray(input.model) && $io2(input.model)) && (undefined === input.trainer || "object" === typeof input.trainer && null !== input.trainer && false === Array.isArray(input.trainer) && $io3(input.trainer)) && (undefined === input.criterion || "object" === typeof input.criterion && null !== input.criterion && false === Array.isArray(input.criterion) && $io4(input.criterion)) && (undefined === input.optimizer || "object" === typeof input.optimizer && null !== input.optimizer && false === Array.isArray(input.optimizer) && $io5(input.optimizer)) && (undefined === input.collate || "object" === typeof input.collate && null !== input.collate && false === Array.isArray(input.collate) && $io6(input.collate));
            const $io1 = (input: any): boolean => (undefined === input.batchSize || "number" === typeof input.batchSize && !Number.isNaN(input.batchSize) && Math.floor(input.batchSize) === input.batchSize && (-2147483648 <= input.batchSize && input.batchSize <= 2147483647) && 1 <= input.batchSize) && (undefined === input.shuffle || "boolean" === typeof input.shuffle) && (undefined === input.numWorkers || "number" === typeof input.numWorkers && !Number.isNaN(input.numWorkers) && Math.floor(input.numWorkers) === input.numWorkers && (-2147483648 <= input.numWorkers && input.numWorkers <= 2147483647) && -1 <= input.numWorkers) && (undefined === input.dropLast || "boolean" === typeof input.dropLast);
            const $io2 = (input: any): boolean => (undefined === input.name || "resnet-18" === input.name || "resnet-34" === input.name || "resnet-50" === input.name || "resnet-101" === input.name || "resnet-152" === input.name) && (undefined === input.outDim || "number" === typeof input.outDim && !Number.isNaN(input.outDim) && Math.floor(input.outDim) === input.outDim && (-2147483648 <= input.outDim && input.outDim <= 2147483647) && 1 <= input.outDim) && (undefined === input.numFtrs || "number" === typeof input.numFtrs && !Number.isNaN(input.numFtrs) && Math.floor(input.numFtrs) === input.numFtrs && (-2147483648 <= input.numFtrs && input.numFtrs <= 2147483647) && 1 <= input.numFtrs) && (undefined === input.width || "number" === typeof input.width && !Number.isNaN(input.width) && Math.floor(input.width) === input.width && (-2147483648 <= input.width && input.width <= 2147483647) && 1 <= input.width);
            const $io3 = (input: any): boolean => (undefined === input.gpus || "number" === typeof input.gpus && !Number.isNaN(input.gpus) && Math.floor(input.gpus) === input.gpus && (-2147483648 <= input.gpus && input.gpus <= 2147483647) && 0 <= input.gpus) && (undefined === input.maxEpochs || "number" === typeof input.maxEpochs && !Number.isNaN(input.maxEpochs) && Math.floor(input.maxEpochs) === input.maxEpochs && (-2147483648 <= input.maxEpochs && input.maxEpochs <= 2147483647) && 0 <= input.maxEpochs) && (undefined === input.precision || 16 === input.precision || 32 === input.precision);
            const $io4 = (input: any): boolean => undefined === input.temperature || "number" === typeof input.temperature && !Number.isNaN(input.temperature) && 0 < input.temperature;
            const $io5 = (input: any): boolean => (undefined === input.lr || "number" === typeof input.lr && !Number.isNaN(input.lr) && 0 <= input.lr) && (undefined === input.weightDecay || "number" === typeof input.weightDecay && 0 <= input.weightDecay && 1 >= input.weightDecay);
            const $io6 = (input: any): boolean => (undefined === input.inputSize || "number" === typeof input.inputSize && !Number.isNaN(input.inputSize) && Math.floor(input.inputSize) === input.inputSize && (-2147483648 <= input.inputSize && input.inputSize <= 2147483647) && 1 <= input.inputSize) && (undefined === input.cjProb || "number" === typeof input.cjProb && 0 <= input.cjProb && 1 >= input.cjProb) && (undefined === input.cjBright || "number" === typeof input.cjBright && 0 <= input.cjBright && 1 >= input.cjBright) && (undefined === input.cjContrast || "number" === typeof input.cjContrast && 0 <= input.cjContrast && 1 >= input.cjContrast) && (undefined === input.cjSat || "number" === typeof input.cjSat && 0 <= input.cjSat && 1 >= input.cjSat) && (undefined === input.cjHue || "number" === typeof input.cjHue && 0 <= input.cjHue && 1 >= input.cjHue) && (undefined === input.minScale || "number" === typeof input.minScale && 0 <= input.minScale && 1 >= input.minScale) && (undefined === input.randomGrayScale || "number" === typeof input.randomGrayScale && 0 <= input.randomGrayScale && 1 >= input.randomGrayScale) && (undefined === input.gaussianBlur || "number" === typeof input.gaussianBlur && 0 <= input.gaussianBlur && 1 >= input.gaussianBlur) && (undefined === input.kernelSize || "number" === typeof input.kernelSize && !Number.isNaN(input.kernelSize) && 0 <= input.kernelSize) && (undefined === input.sigmas || Array.isArray(input.sigmas) && input.sigmas.every((elem: any) => "number" === typeof elem && !Number.isNaN(elem))) && (undefined === input.vfProb || "number" === typeof input.vfProb && 0 <= input.vfProb && 1 >= input.vfProb) && (undefined === input.hfProb || "number" === typeof input.hfProb && 0 <= input.hfProb && 1 >= input.hfProb) && (undefined === input.rrProb || "number" === typeof input.rrProb && 0 <= input.rrProb && 1 >= input.rrProb);
            return "object" === typeof input && null !== input && false === Array.isArray(input) && $io0(input);
        };
        if (false === __is(input))
            ((input: any, _path: string, _exceptionable: boolean = true): input is DockerWorkerConfigV2Lightly => {
                const $guard = (typia.createAssertStringify as any).guard;
                const $ao0 = (input: any, _path: string, _exceptionable: boolean = true): boolean => (undefined === input.loader || ("object" === typeof input.loader && null !== input.loader && false === Array.isArray(input.loader) || $guard(_exceptionable, {
                    path: _path + ".loader",
                    expected: "(DockerWorkerConfigV3LightlyLoader | undefined)",
                    value: input.loader
                })) && $ao1(input.loader, _path + ".loader", true && _exceptionable) || $guard(_exceptionable, {
                    path: _path + ".loader",
                    expected: "(DockerWorkerConfigV3LightlyLoader | undefined)",
                    value: input.loader
                })) && (undefined === input.model || ("object" === typeof input.model && null !== input.model && false === Array.isArray(input.model) || $guard(_exceptionable, {
                    path: _path + ".model",
                    expected: "(DockerWorkerConfigV2LightlyModel | undefined)",
                    value: input.model
                })) && $ao2(input.model, _path + ".model", true && _exceptionable) || $guard(_exceptionable, {
                    path: _path + ".model",
                    expected: "(DockerWorkerConfigV2LightlyModel | undefined)",
                    value: input.model
                })) && (undefined === input.trainer || ("object" === typeof input.trainer && null !== input.trainer && false === Array.isArray(input.trainer) || $guard(_exceptionable, {
                    path: _path + ".trainer",
                    expected: "(DockerWorkerConfigV2LightlyTrainer | undefined)",
                    value: input.trainer
                })) && $ao3(input.trainer, _path + ".trainer", true && _exceptionable) || $guard(_exceptionable, {
                    path: _path + ".trainer",
                    expected: "(DockerWorkerConfigV2LightlyTrainer | undefined)",
                    value: input.trainer
                })) && (undefined === input.criterion || ("object" === typeof input.criterion && null !== input.criterion && false === Array.isArray(input.criterion) || $guard(_exceptionable, {
                    path: _path + ".criterion",
                    expected: "(DockerWorkerConfigV3LightlyCriterion | undefined)",
                    value: input.criterion
                })) && $ao4(input.criterion, _path + ".criterion", true && _exceptionable) || $guard(_exceptionable, {
                    path: _path + ".criterion",
                    expected: "(DockerWorkerConfigV3LightlyCriterion | undefined)",
                    value: input.criterion
                })) && (undefined === input.optimizer || ("object" === typeof input.optimizer && null !== input.optimizer && false === Array.isArray(input.optimizer) || $guard(_exceptionable, {
                    path: _path + ".optimizer",
                    expected: "(DockerWorkerConfigV3LightlyOptimizer | undefined)",
                    value: input.optimizer
                })) && $ao5(input.optimizer, _path + ".optimizer", true && _exceptionable) || $guard(_exceptionable, {
                    path: _path + ".optimizer",
                    expected: "(DockerWorkerConfigV3LightlyOptimizer | undefined)",
                    value: input.optimizer
                })) && (undefined === input.collate || ("object" === typeof input.collate && null !== input.collate && false === Array.isArray(input.collate) || $guard(_exceptionable, {
                    path: _path + ".collate",
                    expected: "(DockerWorkerConfigV2LightlyCollate | undefined)",
                    value: input.collate
                })) && $ao6(input.collate, _path + ".collate", true && _exceptionable) || $guard(_exceptionable, {
                    path: _path + ".collate",
                    expected: "(DockerWorkerConfigV2LightlyCollate | undefined)",
                    value: input.collate
                }));
                const $ao1 = (input: any, _path: string, _exceptionable: boolean = true): boolean => (undefined === input.batchSize || "number" === typeof input.batchSize && !Number.isNaN(input.batchSize) && (Math.floor(input.batchSize) === input.batchSize || $guard(_exceptionable, {
                    path: _path + ".batchSize",
                    expected: "number (@type int)",
                    value: input.batchSize
                })) && (-2147483648 <= input.batchSize && input.batchSize <= 2147483647 || $guard(_exceptionable, {
                    path: _path + ".batchSize",
                    expected: "number (@type int)",
                    value: input.batchSize
                })) && (1 <= input.batchSize || $guard(_exceptionable, {
                    path: _path + ".batchSize",
                    expected: "number (@minimum 1)",
                    value: input.batchSize
                })) || $guard(_exceptionable, {
                    path: _path + ".batchSize",
                    expected: "(number | undefined)",
                    value: input.batchSize
                })) && (undefined === input.shuffle || "boolean" === typeof input.shuffle || $guard(_exceptionable, {
                    path: _path + ".shuffle",
                    expected: "(boolean | undefined)",
                    value: input.shuffle
                })) && (undefined === input.numWorkers || "number" === typeof input.numWorkers && !Number.isNaN(input.numWorkers) && (Math.floor(input.numWorkers) === input.numWorkers || $guard(_exceptionable, {
                    path: _path + ".numWorkers",
                    expected: "number (@type int)",
                    value: input.numWorkers
                })) && (-2147483648 <= input.numWorkers && input.numWorkers <= 2147483647 || $guard(_exceptionable, {
                    path: _path + ".numWorkers",
                    expected: "number (@type int)",
                    value: input.numWorkers
                })) && (-1 <= input.numWorkers || $guard(_exceptionable, {
                    path: _path + ".numWorkers",
                    expected: "number (@minimum -1)",
                    value: input.numWorkers
                })) || $guard(_exceptionable, {
                    path: _path + ".numWorkers",
                    expected: "(number | undefined)",
                    value: input.numWorkers
                })) && (undefined === input.dropLast || "boolean" === typeof input.dropLast || $guard(_exceptionable, {
                    path: _path + ".dropLast",
                    expected: "(boolean | undefined)",
                    value: input.dropLast
                }));
                const $ao2 = (input: any, _path: string, _exceptionable: boolean = true): boolean => (undefined === input.name || "resnet-18" === input.name || "resnet-34" === input.name || "resnet-50" === input.name || "resnet-101" === input.name || "resnet-152" === input.name || $guard(_exceptionable, {
                    path: _path + ".name",
                    expected: "(\"resnet-101\" | \"resnet-152\" | \"resnet-18\" | \"resnet-34\" | \"resnet-50\" | undefined)",
                    value: input.name
                })) && (undefined === input.outDim || "number" === typeof input.outDim && !Number.isNaN(input.outDim) && (Math.floor(input.outDim) === input.outDim || $guard(_exceptionable, {
                    path: _path + ".outDim",
                    expected: "number (@type int)",
                    value: input.outDim
                })) && (-2147483648 <= input.outDim && input.outDim <= 2147483647 || $guard(_exceptionable, {
                    path: _path + ".outDim",
                    expected: "number (@type int)",
                    value: input.outDim
                })) && (1 <= input.outDim || $guard(_exceptionable, {
                    path: _path + ".outDim",
                    expected: "number (@minimum 1)",
                    value: input.outDim
                })) || $guard(_exceptionable, {
                    path: _path + ".outDim",
                    expected: "(number | undefined)",
                    value: input.outDim
                })) && (undefined === input.numFtrs || "number" === typeof input.numFtrs && !Number.isNaN(input.numFtrs) && (Math.floor(input.numFtrs) === input.numFtrs || $guard(_exceptionable, {
                    path: _path + ".numFtrs",
                    expected: "number (@type int)",
                    value: input.numFtrs
                })) && (-2147483648 <= input.numFtrs && input.numFtrs <= 2147483647 || $guard(_exceptionable, {
                    path: _path + ".numFtrs",
                    expected: "number (@type int)",
                    value: input.numFtrs
                })) && (1 <= input.numFtrs || $guard(_exceptionable, {
                    path: _path + ".numFtrs",
                    expected: "number (@minimum 1)",
                    value: input.numFtrs
                })) || $guard(_exceptionable, {
                    path: _path + ".numFtrs",
                    expected: "(number | undefined)",
                    value: input.numFtrs
                })) && (undefined === input.width || "number" === typeof input.width && !Number.isNaN(input.width) && (Math.floor(input.width) === input.width || $guard(_exceptionable, {
                    path: _path + ".width",
                    expected: "number (@type int)",
                    value: input.width
                })) && (-2147483648 <= input.width && input.width <= 2147483647 || $guard(_exceptionable, {
                    path: _path + ".width",
                    expected: "number (@type int)",
                    value: input.width
                })) && (1 <= input.width || $guard(_exceptionable, {
                    path: _path + ".width",
                    expected: "number (@minimum 1)",
                    value: input.width
                })) || $guard(_exceptionable, {
                    path: _path + ".width",
                    expected: "(number | undefined)",
                    value: input.width
                }));
                const $ao3 = (input: any, _path: string, _exceptionable: boolean = true): boolean => (undefined === input.gpus || "number" === typeof input.gpus && !Number.isNaN(input.gpus) && (Math.floor(input.gpus) === input.gpus || $guard(_exceptionable, {
                    path: _path + ".gpus",
                    expected: "number (@type int)",
                    value: input.gpus
                })) && (-2147483648 <= input.gpus && input.gpus <= 2147483647 || $guard(_exceptionable, {
                    path: _path + ".gpus",
                    expected: "number (@type int)",
                    value: input.gpus
                })) && (0 <= input.gpus || $guard(_exceptionable, {
                    path: _path + ".gpus",
                    expected: "number (@minimum 0)",
                    value: input.gpus
                })) || $guard(_exceptionable, {
                    path: _path + ".gpus",
                    expected: "(number | undefined)",
                    value: input.gpus
                })) && (undefined === input.maxEpochs || "number" === typeof input.maxEpochs && !Number.isNaN(input.maxEpochs) && (Math.floor(input.maxEpochs) === input.maxEpochs || $guard(_exceptionable, {
                    path: _path + ".maxEpochs",
                    expected: "number (@type int)",
                    value: input.maxEpochs
                })) && (-2147483648 <= input.maxEpochs && input.maxEpochs <= 2147483647 || $guard(_exceptionable, {
                    path: _path + ".maxEpochs",
                    expected: "number (@type int)",
                    value: input.maxEpochs
                })) && (0 <= input.maxEpochs || $guard(_exceptionable, {
                    path: _path + ".maxEpochs",
                    expected: "number (@minimum 0)",
                    value: input.maxEpochs
                })) || $guard(_exceptionable, {
                    path: _path + ".maxEpochs",
                    expected: "(number | undefined)",
                    value: input.maxEpochs
                })) && (undefined === input.precision || 16 === input.precision || 32 === input.precision || $guard(_exceptionable, {
                    path: _path + ".precision",
                    expected: "(16 | 32 | undefined)",
                    value: input.precision
                }));
                const $ao4 = (input: any, _path: string, _exceptionable: boolean = true): boolean => undefined === input.temperature || "number" === typeof input.temperature && !Number.isNaN(input.temperature) && (0 < input.temperature || $guard(_exceptionable, {
                    path: _path + ".temperature",
                    expected: "number (@exclusiveMinimum 0)",
                    value: input.temperature
                })) || $guard(_exceptionable, {
                    path: _path + ".temperature",
                    expected: "(number | undefined)",
                    value: input.temperature
                });
                const $ao5 = (input: any, _path: string, _exceptionable: boolean = true): boolean => (undefined === input.lr || "number" === typeof input.lr && !Number.isNaN(input.lr) && (0 <= input.lr || $guard(_exceptionable, {
                    path: _path + ".lr",
                    expected: "number (@minimum 0)",
                    value: input.lr
                })) || $guard(_exceptionable, {
                    path: _path + ".lr",
                    expected: "(number | undefined)",
                    value: input.lr
                })) && (undefined === input.weightDecay || "number" === typeof input.weightDecay && (0 <= input.weightDecay || $guard(_exceptionable, {
                    path: _path + ".weightDecay",
                    expected: "number (@minimum 0)",
                    value: input.weightDecay
                })) && (1 >= input.weightDecay || $guard(_exceptionable, {
                    path: _path + ".weightDecay",
                    expected: "number (@maximum 1)",
                    value: input.weightDecay
                })) || $guard(_exceptionable, {
                    path: _path + ".weightDecay",
                    expected: "(number | undefined)",
                    value: input.weightDecay
                }));
                const $ao6 = (input: any, _path: string, _exceptionable: boolean = true): boolean => (undefined === input.inputSize || "number" === typeof input.inputSize && !Number.isNaN(input.inputSize) && (Math.floor(input.inputSize) === input.inputSize || $guard(_exceptionable, {
                    path: _path + ".inputSize",
                    expected: "number (@type int)",
                    value: input.inputSize
                })) && (-2147483648 <= input.inputSize && input.inputSize <= 2147483647 || $guard(_exceptionable, {
                    path: _path + ".inputSize",
                    expected: "number (@type int)",
                    value: input.inputSize
                })) && (1 <= input.inputSize || $guard(_exceptionable, {
                    path: _path + ".inputSize",
                    expected: "number (@minimum 1)",
                    value: input.inputSize
                })) || $guard(_exceptionable, {
                    path: _path + ".inputSize",
                    expected: "(number | undefined)",
                    value: input.inputSize
                })) && (undefined === input.cjProb || "number" === typeof input.cjProb && (0 <= input.cjProb || $guard(_exceptionable, {
                    path: _path + ".cjProb",
                    expected: "number (@minimum 0)",
                    value: input.cjProb
                })) && (1 >= input.cjProb || $guard(_exceptionable, {
                    path: _path + ".cjProb",
                    expected: "number (@maximum 1)",
                    value: input.cjProb
                })) || $guard(_exceptionable, {
                    path: _path + ".cjProb",
                    expected: "(number | undefined)",
                    value: input.cjProb
                })) && (undefined === input.cjBright || "number" === typeof input.cjBright && (0 <= input.cjBright || $guard(_exceptionable, {
                    path: _path + ".cjBright",
                    expected: "number (@minimum 0)",
                    value: input.cjBright
                })) && (1 >= input.cjBright || $guard(_exceptionable, {
                    path: _path + ".cjBright",
                    expected: "number (@maximum 1)",
                    value: input.cjBright
                })) || $guard(_exceptionable, {
                    path: _path + ".cjBright",
                    expected: "(number | undefined)",
                    value: input.cjBright
                })) && (undefined === input.cjContrast || "number" === typeof input.cjContrast && (0 <= input.cjContrast || $guard(_exceptionable, {
                    path: _path + ".cjContrast",
                    expected: "number (@minimum 0)",
                    value: input.cjContrast
                })) && (1 >= input.cjContrast || $guard(_exceptionable, {
                    path: _path + ".cjContrast",
                    expected: "number (@maximum 1)",
                    value: input.cjContrast
                })) || $guard(_exceptionable, {
                    path: _path + ".cjContrast",
                    expected: "(number | undefined)",
                    value: input.cjContrast
                })) && (undefined === input.cjSat || "number" === typeof input.cjSat && (0 <= input.cjSat || $guard(_exceptionable, {
                    path: _path + ".cjSat",
                    expected: "number (@minimum 0)",
                    value: input.cjSat
                })) && (1 >= input.cjSat || $guard(_exceptionable, {
                    path: _path + ".cjSat",
                    expected: "number (@maximum 1)",
                    value: input.cjSat
                })) || $guard(_exceptionable, {
                    path: _path + ".cjSat",
                    expected: "(number | undefined)",
                    value: input.cjSat
                })) && (undefined === input.cjHue || "number" === typeof input.cjHue && (0 <= input.cjHue || $guard(_exceptionable, {
                    path: _path + ".cjHue",
                    expected: "number (@minimum 0)",
                    value: input.cjHue
                })) && (1 >= input.cjHue || $guard(_exceptionable, {
                    path: _path + ".cjHue",
                    expected: "number (@maximum 1)",
                    value: input.cjHue
                })) || $guard(_exceptionable, {
                    path: _path + ".cjHue",
                    expected: "(number | undefined)",
                    value: input.cjHue
                })) && (undefined === input.minScale || "number" === typeof input.minScale && (0 <= input.minScale || $guard(_exceptionable, {
                    path: _path + ".minScale",
                    expected: "number (@minimum 0)",
                    value: input.minScale
                })) && (1 >= input.minScale || $guard(_exceptionable, {
                    path: _path + ".minScale",
                    expected: "number (@maximum 1)",
                    value: input.minScale
                })) || $guard(_exceptionable, {
                    path: _path + ".minScale",
                    expected: "(number | undefined)",
                    value: input.minScale
                })) && (undefined === input.randomGrayScale || "number" === typeof input.randomGrayScale && (0 <= input.randomGrayScale || $guard(_exceptionable, {
                    path: _path + ".randomGrayScale",
                    expected: "number (@minimum 0)",
                    value: input.randomGrayScale
                })) && (1 >= input.randomGrayScale || $guard(_exceptionable, {
                    path: _path + ".randomGrayScale",
                    expected: "number (@maximum 1)",
                    value: input.randomGrayScale
                })) || $guard(_exceptionable, {
                    path: _path + ".randomGrayScale",
                    expected: "(number | undefined)",
                    value: input.randomGrayScale
                })) && (undefined === input.gaussianBlur || "number" === typeof input.gaussianBlur && (0 <= input.gaussianBlur || $guard(_exceptionable, {
                    path: _path + ".gaussianBlur",
                    expected: "number (@minimum 0)",
                    value: input.gaussianBlur
                })) && (1 >= input.gaussianBlur || $guard(_exceptionable, {
                    path: _path + ".gaussianBlur",
                    expected: "number (@maximum 1)",
                    value: input.gaussianBlur
                })) || $guard(_exceptionable, {
                    path: _path + ".gaussianBlur",
                    expected: "(number | undefined)",
                    value: input.gaussianBlur
                })) && (undefined === input.kernelSize || "number" === typeof input.kernelSize && !Number.isNaN(input.kernelSize) && (0 <= input.kernelSize || $guard(_exceptionable, {
                    path: _path + ".kernelSize",
                    expected: "number (@minimum 0)",
                    value: input.kernelSize
                })) || $guard(_exceptionable, {
                    path: _path + ".kernelSize",
                    expected: "(number | undefined)",
                    value: input.kernelSize
                })) && (undefined === input.sigmas || (Array.isArray(input.sigmas) || $guard(_exceptionable, {
                    path: _path + ".sigmas",
                    expected: "(Array<number> | undefined)",
                    value: input.sigmas
                })) && input.sigmas.every((elem: any, _index1: number) => "number" === typeof elem && !Number.isNaN(elem) || $guard(_exceptionable, {
                    path: _path + ".sigmas[" + _index1 + "]",
                    expected: "number",
                    value: elem
                })) || $guard(_exceptionable, {
                    path: _path + ".sigmas",
                    expected: "(Array<number> | undefined)",
                    value: input.sigmas
                })) && (undefined === input.vfProb || "number" === typeof input.vfProb && (0 <= input.vfProb || $guard(_exceptionable, {
                    path: _path + ".vfProb",
                    expected: "number (@minimum 0)",
                    value: input.vfProb
                })) && (1 >= input.vfProb || $guard(_exceptionable, {
                    path: _path + ".vfProb",
                    expected: "number (@maximum 1)",
                    value: input.vfProb
                })) || $guard(_exceptionable, {
                    path: _path + ".vfProb",
                    expected: "(number | undefined)",
                    value: input.vfProb
                })) && (undefined === input.hfProb || "number" === typeof input.hfProb && (0 <= input.hfProb || $guard(_exceptionable, {
                    path: _path + ".hfProb",
                    expected: "number (@minimum 0)",
                    value: input.hfProb
                })) && (1 >= input.hfProb || $guard(_exceptionable, {
                    path: _path + ".hfProb",
                    expected: "number (@maximum 1)",
                    value: input.hfProb
                })) || $guard(_exceptionable, {
                    path: _path + ".hfProb",
                    expected: "(number | undefined)",
                    value: input.hfProb
                })) && (undefined === input.rrProb || "number" === typeof input.rrProb && (0 <= input.rrProb || $guard(_exceptionable, {
                    path: _path + ".rrProb",
                    expected: "number (@minimum 0)",
                    value: input.rrProb
                })) && (1 >= input.rrProb || $guard(_exceptionable, {
                    path: _path + ".rrProb",
                    expected: "number (@maximum 1)",
                    value: input.rrProb
                })) || $guard(_exceptionable, {
                    path: _path + ".rrProb",
                    expected: "(number | undefined)",
                    value: input.rrProb
                }));
                return ("object" === typeof input && null !== input && false === Array.isArray(input) || $guard(true, {
                    path: _path + "",
                    expected: "DockerWorkerConfigV2Lightly",
                    value: input
                })) && $ao0(input, _path + "", true) || $guard(true, {
                    path: _path + "",
                    expected: "DockerWorkerConfigV2Lightly",
                    value: input
                });
            })(input, "$input", true);
        return input;
    }; const stringify = (input: DockerWorkerConfigV2Lightly): string => {
        const $io1 = (input: any): boolean => (undefined === input.batchSize || "number" === typeof input.batchSize && Math.floor(input.batchSize) === input.batchSize && (-2147483648 <= input.batchSize && input.batchSize <= 2147483647) && 1 <= input.batchSize) && (undefined === input.shuffle || "boolean" === typeof input.shuffle) && (undefined === input.numWorkers || "number" === typeof input.numWorkers && Math.floor(input.numWorkers) === input.numWorkers && (-2147483648 <= input.numWorkers && input.numWorkers <= 2147483647) && -1 <= input.numWorkers) && (undefined === input.dropLast || "boolean" === typeof input.dropLast);
        const $io2 = (input: any): boolean => (undefined === input.name || "resnet-18" === input.name || "resnet-34" === input.name || "resnet-50" === input.name || "resnet-101" === input.name || "resnet-152" === input.name) && (undefined === input.outDim || "number" === typeof input.outDim && Math.floor(input.outDim) === input.outDim && (-2147483648 <= input.outDim && input.outDim <= 2147483647) && 1 <= input.outDim) && (undefined === input.numFtrs || "number" === typeof input.numFtrs && Math.floor(input.numFtrs) === input.numFtrs && (-2147483648 <= input.numFtrs && input.numFtrs <= 2147483647) && 1 <= input.numFtrs) && (undefined === input.width || "number" === typeof input.width && Math.floor(input.width) === input.width && (-2147483648 <= input.width && input.width <= 2147483647) && 1 <= input.width);
        const $io3 = (input: any): boolean => (undefined === input.gpus || "number" === typeof input.gpus && Math.floor(input.gpus) === input.gpus && (-2147483648 <= input.gpus && input.gpus <= 2147483647) && 0 <= input.gpus) && (undefined === input.maxEpochs || "number" === typeof input.maxEpochs && Math.floor(input.maxEpochs) === input.maxEpochs && (-2147483648 <= input.maxEpochs && input.maxEpochs <= 2147483647) && 0 <= input.maxEpochs) && (undefined === input.precision || 16 === input.precision || 32 === input.precision);
        const $io4 = (input: any): boolean => undefined === input.temperature || "number" === typeof input.temperature && 0 < input.temperature;
        const $io5 = (input: any): boolean => (undefined === input.lr || "number" === typeof input.lr && 0 <= input.lr) && (undefined === input.weightDecay || "number" === typeof input.weightDecay && 0 <= input.weightDecay && 1 >= input.weightDecay);
        const $io6 = (input: any): boolean => (undefined === input.inputSize || "number" === typeof input.inputSize && Math.floor(input.inputSize) === input.inputSize && (-2147483648 <= input.inputSize && input.inputSize <= 2147483647) && 1 <= input.inputSize) && (undefined === input.cjProb || "number" === typeof input.cjProb && 0 <= input.cjProb && 1 >= input.cjProb) && (undefined === input.cjBright || "number" === typeof input.cjBright && 0 <= input.cjBright && 1 >= input.cjBright) && (undefined === input.cjContrast || "number" === typeof input.cjContrast && 0 <= input.cjContrast && 1 >= input.cjContrast) && (undefined === input.cjSat || "number" === typeof input.cjSat && 0 <= input.cjSat && 1 >= input.cjSat) && (undefined === input.cjHue || "number" === typeof input.cjHue && 0 <= input.cjHue && 1 >= input.cjHue) && (undefined === input.minScale || "number" === typeof input.minScale && 0 <= input.minScale && 1 >= input.minScale) && (undefined === input.randomGrayScale || "number" === typeof input.randomGrayScale && 0 <= input.randomGrayScale && 1 >= input.randomGrayScale) && (undefined === input.gaussianBlur || "number" === typeof input.gaussianBlur && 0 <= input.gaussianBlur && 1 >= input.gaussianBlur) && (undefined === input.kernelSize || "number" === typeof input.kernelSize && 0 <= input.kernelSize) && (undefined === input.sigmas || Array.isArray(input.sigmas) && input.sigmas.every((elem: any) => "number" === typeof elem)) && (undefined === input.vfProb || "number" === typeof input.vfProb && 0 <= input.vfProb && 1 >= input.vfProb) && (undefined === input.hfProb || "number" === typeof input.hfProb && 0 <= input.hfProb && 1 >= input.hfProb) && (undefined === input.rrProb || "number" === typeof input.rrProb && 0 <= input.rrProb && 1 >= input.rrProb);
        const $tail = (typia.createAssertStringify as any).tail;
        const $string = (typia.createAssertStringify as any).string;
        const $throws = (typia.createAssertStringify as any).throws;
        const $so0 = (input: any): any => `{${$tail(`${undefined === input.loader ? "" : `"loader":${undefined !== input.loader ? $so1(input.loader) : undefined},`}${undefined === input.model ? "" : `"model":${undefined !== input.model ? $so2(input.model) : undefined},`}${undefined === input.trainer ? "" : `"trainer":${undefined !== input.trainer ? $so3(input.trainer) : undefined},`}${undefined === input.criterion ? "" : `"criterion":${undefined !== input.criterion ? $so4(input.criterion) : undefined},`}${undefined === input.optimizer ? "" : `"optimizer":${undefined !== input.optimizer ? $so5(input.optimizer) : undefined},`}${undefined === input.collate ? "" : `"collate":${undefined !== input.collate ? $so6(input.collate) : undefined}`}`)}}`;
        const $so1 = (input: any): any => `{${$tail(`${undefined === input.batchSize ? "" : `"batchSize":${undefined !== input.batchSize ? input.batchSize : undefined},`}${undefined === input.shuffle ? "" : `"shuffle":${undefined !== input.shuffle ? input.shuffle : undefined},`}${undefined === input.numWorkers ? "" : `"numWorkers":${undefined !== input.numWorkers ? input.numWorkers : undefined},`}${undefined === input.dropLast ? "" : `"dropLast":${undefined !== input.dropLast ? input.dropLast : undefined}`}`)}}`;
        const $so2 = (input: any): any => `{${$tail(`${undefined === input.name ? "" : `"name":${undefined !== input.name ? (() => {
            if ("string" === typeof input.name)
                return $string(input.name);
            if ("string" === typeof input.name)
                return "\"" + input.name + "\"";
            $throws({
                expected: "(\"resnet-101\" | \"resnet-152\" | \"resnet-18\" | \"resnet-34\" | \"resnet-50\" | undefined)",
                value: input.name
            });
        })() : undefined},`}${undefined === input.outDim ? "" : `"outDim":${undefined !== input.outDim ? input.outDim : undefined},`}${undefined === input.numFtrs ? "" : `"numFtrs":${undefined !== input.numFtrs ? input.numFtrs : undefined},`}${undefined === input.width ? "" : `"width":${undefined !== input.width ? input.width : undefined}`}`)}}`;
        const $so3 = (input: any): any => `{${$tail(`${undefined === input.gpus ? "" : `"gpus":${undefined !== input.gpus ? input.gpus : undefined},`}${undefined === input.maxEpochs ? "" : `"maxEpochs":${undefined !== input.maxEpochs ? input.maxEpochs : undefined},`}${undefined === input.precision ? "" : `"precision":${undefined !== input.precision ? input.precision : undefined}`}`)}}`;
        const $so4 = (input: any): any => `{${$tail(`${undefined === input.temperature ? "" : `"temperature":${undefined !== input.temperature ? input.temperature : undefined}`}`)}}`;
        const $so5 = (input: any): any => `{${$tail(`${undefined === input.lr ? "" : `"lr":${undefined !== input.lr ? input.lr : undefined},`}${undefined === input.weightDecay ? "" : `"weightDecay":${undefined !== input.weightDecay ? input.weightDecay : undefined}`}`)}}`;
        const $so6 = (input: any): any => `{${$tail(`${undefined === input.inputSize ? "" : `"inputSize":${undefined !== input.inputSize ? input.inputSize : undefined},`}${undefined === input.cjProb ? "" : `"cjProb":${undefined !== input.cjProb ? input.cjProb : undefined},`}${undefined === input.cjBright ? "" : `"cjBright":${undefined !== input.cjBright ? input.cjBright : undefined},`}${undefined === input.cjContrast ? "" : `"cjContrast":${undefined !== input.cjContrast ? input.cjContrast : undefined},`}${undefined === input.cjSat ? "" : `"cjSat":${undefined !== input.cjSat ? input.cjSat : undefined},`}${undefined === input.cjHue ? "" : `"cjHue":${undefined !== input.cjHue ? input.cjHue : undefined},`}${undefined === input.minScale ? "" : `"minScale":${undefined !== input.minScale ? input.minScale : undefined},`}${undefined === input.randomGrayScale ? "" : `"randomGrayScale":${undefined !== input.randomGrayScale ? input.randomGrayScale : undefined},`}${undefined === input.gaussianBlur ? "" : `"gaussianBlur":${undefined !== input.gaussianBlur ? input.gaussianBlur : undefined},`}${undefined === input.kernelSize ? "" : `"kernelSize":${undefined !== input.kernelSize ? input.kernelSize : undefined},`}${undefined === input.sigmas ? "" : `"sigmas":${undefined !== input.sigmas ? `[${input.sigmas.map((elem: any) => elem).join(",")}]` : undefined},`}${undefined === input.vfProb ? "" : `"vfProb":${undefined !== input.vfProb ? input.vfProb : undefined},`}${undefined === input.hfProb ? "" : `"hfProb":${undefined !== input.hfProb ? input.hfProb : undefined},`}${undefined === input.rrProb ? "" : `"rrProb":${undefined !== input.rrProb ? input.rrProb : undefined}`}`)}}`;
        return $so0(input);
    }; return stringify(assert(input)); })(input);
};
export const DockerWorkerConfigV2LightlyValidatorShape = {
    'loader': DockerWorkerConfigV3LightlyLoaderValidator.optional().default(undefined),
    'model': DockerWorkerConfigV2LightlyModelValidator.optional().default(undefined),
    'trainer': DockerWorkerConfigV2LightlyTrainerValidator.optional().default(undefined),
    'criterion': DockerWorkerConfigV3LightlyCriterionValidator.optional().default(undefined),
    'optimizer': DockerWorkerConfigV3LightlyOptimizerValidator.optional().default(undefined),
    'collate': DockerWorkerConfigV2LightlyCollateValidator.optional().default(undefined),
};
export const DockerWorkerConfigV2LightlyValidator = yup.object().shape(DockerWorkerConfigV2LightlyValidatorShape);
export const DockerWorkerConfigV2LightlyStringifyShapeProperties: ObjectSchema['properties'] = {
    'loader': DockerWorkerConfigV3LightlyLoaderStringifyShape,
    'model': DockerWorkerConfigV2LightlyModelStringifyShape,
    'trainer': DockerWorkerConfigV2LightlyTrainerStringifyShape,
    'criterion': DockerWorkerConfigV3LightlyCriterionStringifyShape,
    'optimizer': DockerWorkerConfigV3LightlyOptimizerStringifyShape,
    'collate': DockerWorkerConfigV2LightlyCollateStringifyShape,
};
export const DockerWorkerConfigV2LightlyStringifyShape: ObjectSchema = {
    title: 'DockerWorkerConfigV2Lightly Stringifier',
    type: 'object',
    properties: {
        ...DockerWorkerConfigV2LightlyStringifyShapeProperties
    },
    required: [],
};
export const DockerWorkerConfigV2LightlyStringifyCompiled = (typeof process === 'object' && String(process) === '[object process]') ?
    fastJSON.build(DockerWorkerConfigV2LightlyStringifyShape, {
        ajv: {
            // since we are already doing this with yup, lets skip
            validateSchema: false,
            ownProperties: true,
            validateFormats: false,
        },
        largeArrayMechanism: 'json-stringify',
        largeArraySize: 1000,
    } as Options)
    :
        () => { throw new Error('fast-json-stringify only works in nodejs'); };
export const DockerWorkerConfigV2LightlyStringify: (data: DockerWorkerConfigV2Lightly) => string = DockerWorkerConfigV2LightlyStringifyCompiled;
/* LIGHTLY CUSTOM END -> Generic */
export function DockerWorkerConfigV2LightlyFromJSON(json: any): DockerWorkerConfigV2Lightly {
    return DockerWorkerConfigV2LightlyFromJSONTyped(json, false);
}
export function DockerWorkerConfigV2LightlyFromJSONTyped(json: any, ignoreDiscriminator: boolean): DockerWorkerConfigV2Lightly {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    /* causes circular depdendencies
*/
    return {
        'loader': !exists(json, 'loader') ? undefined : DockerWorkerConfigV3LightlyLoaderFromJSON(json['loader']),
        'model': !exists(json, 'model') ? undefined : DockerWorkerConfigV2LightlyModelFromJSON(json['model']),
        'trainer': !exists(json, 'trainer') ? undefined : DockerWorkerConfigV2LightlyTrainerFromJSON(json['trainer']),
        'criterion': !exists(json, 'criterion') ? undefined : DockerWorkerConfigV3LightlyCriterionFromJSON(json['criterion']),
        'optimizer': !exists(json, 'optimizer') ? undefined : DockerWorkerConfigV3LightlyOptimizerFromJSON(json['optimizer']),
        'collate': !exists(json, 'collate') ? undefined : DockerWorkerConfigV2LightlyCollateFromJSON(json['collate']),
    };
}
export function DockerWorkerConfigV2LightlyToJSON(value?: DockerWorkerConfigV2Lightly | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'loader': DockerWorkerConfigV3LightlyLoaderToJSON(value.loader),
        'model': DockerWorkerConfigV2LightlyModelToJSON(value.model),
        'trainer': DockerWorkerConfigV2LightlyTrainerToJSON(value.trainer),
        'criterion': DockerWorkerConfigV3LightlyCriterionToJSON(value.criterion),
        'optimizer': DockerWorkerConfigV3LightlyOptimizerToJSON(value.optimizer),
        'collate': DockerWorkerConfigV2LightlyCollateToJSON(value.collate),
    };
}
