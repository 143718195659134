/* tslint:disable */
/* eslint-disable */
/**
 * Lightly API
 * Lightly.ai enables you to do self-supervised learning in an easy and intuitive way. The lightly.ai OpenAPI spec defines how one can interact with our REST API to unleash the full potential of lightly.ai
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: support@lightly.ai
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
// SRC: https://github.com/OpenAPITools/openapi-generator/blob/master/modules/openapi-generator/src/main/resources/typescript-fetch/modelEnumInterfaces.mustache
// synched 02.02.2023
import * as yup from 'yup';
import * as fastJSON from 'fast-json-stringify';
import { StringSchema } from 'fast-json-stringify';
/**
 * The region where your bucket is located (see https://docs.aws.amazon.com/general/latest/gr/s3.html for further information)
 * @export
 * @enum {string}
 */
export enum S3Region {
    us_east_2 = 'us-east-2',
    us_east_1 = 'us-east-1',
    us_west_1 = 'us-west-1',
    us_west_2 = 'us-west-2',
    af_south_1 = 'af-south-1',
    ap_east_1 = 'ap-east-1',
    ap_south_2 = 'ap-south-2',
    ap_southeast_3 = 'ap-southeast-3',
    ap_southeast_4 = 'ap-southeast-4',
    ap_south_1 = 'ap-south-1',
    ap_northeast_3 = 'ap-northeast-3',
    ap_northeast_2 = 'ap-northeast-2',
    ap_southeast_1 = 'ap-southeast-1',
    ap_southeast_2 = 'ap-southeast-2',
    ap_northeast_1 = 'ap-northeast-1',
    ca_central_1 = 'ca-central-1',
    cn_northwest_1 = 'cn-northwest-1',
    eu_central_1 = 'eu-central-1',
    eu_west_1 = 'eu-west-1',
    eu_west_2 = 'eu-west-2',
    eu_south_1 = 'eu-south-1',
    eu_west_3 = 'eu-west-3',
    eu_north_1 = 'eu-north-1',
    eu_south_2 = 'eu-south-2',
    eu_central_2 = 'eu-central-2',
    me_south_1 = 'me-south-1',
    me_central_1 = 'me-central-1',
    sa_east_1 = 'sa-east-1',
    us_gov_east = 'us-gov-east',
    us_gov_west = 'us-gov-west'
}
/* LIGHTLY CUSTOM START -> modelEnumInterfaces */
export const S3RegionValidatorShape = [
    'us-east-2',
    'us-east-1',
    'us-west-1',
    'us-west-2',
    'af-south-1',
    'ap-east-1',
    'ap-south-2',
    'ap-southeast-3',
    'ap-southeast-4',
    'ap-south-1',
    'ap-northeast-3',
    'ap-northeast-2',
    'ap-southeast-1',
    'ap-southeast-2',
    'ap-northeast-1',
    'ca-central-1',
    'cn-northwest-1',
    'eu-central-1',
    'eu-west-1',
    'eu-west-2',
    'eu-south-1',
    'eu-west-3',
    'eu-north-1',
    'eu-south-2',
    'eu-central-2',
    'me-south-1',
    'me-central-1',
    'sa-east-1',
    'us-gov-east',
    'us-gov-west'
];
export const S3RegionValidator = yup.mixed().oneOf(S3RegionValidatorShape);
export const S3RegionStringifyShapeProperties = {};
export const S3RegionStringifyShape: StringSchema = {
    title: 'S3Region Stringifier',
    type: 'string'
};
export const S3RegionStringify = (typeof process === 'object' && String(process) === '[object process]') ?
    fastJSON.build(S3RegionStringifyShape)
    :
        () => { throw new Error('fast-json-stringify only works in nodejs'); };
/* LIGHTLY CUSTOM END */
export function S3RegionFromJSON(json: any): S3Region {
    return S3RegionFromJSONTyped(json, false);
}
export function S3RegionFromJSONTyped(json: any, ignoreDiscriminator: boolean): S3Region {
    return json as S3Region;
}
export function S3RegionToJSON(value?: S3Region | null): any {
    return value as any;
}
