import React, { useCallback, useMemo, useState } from 'react';

import ClearIcon from '@mui/icons-material/Clear';
import SearchIcon from '@mui/icons-material/Search';
import { makeValidate, TextField } from 'mui-rff';
import { Form } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';

import { CircularProgress, InputAdornment } from '@mui/material';

import { LightlyIconButton, LightlyIconStateButtonMinimal } from '../common/Button';
import mixpanel from '../contexts/mixpanel';
import { makeTranslator } from '../i18n/translators';



export type ISearchTermState = {searchTerm: RegExp | string | null, isCaseSensitive: boolean, isRegex: boolean} | null;

type ISearchTermFormData = {
	searchTerm: string;
};

interface ISearchBar {
	locationId?: string;
	defaultVal?: RegExp | string;
	placeholder?: string;
	onSearchTermChange: (searchTermState: ISearchTermState) => void;
	isLoading?: boolean;

	defaultCaseSensitive?: boolean;
	defaultRegex?: boolean;

	disableRegex?: boolean;
	disableCaseSensitive?: boolean;
}
export const SearchBar: React.FC<ISearchBar> = (props) => {

	const { t } = useTranslation();

	const { locationId = '', defaultVal = '', isLoading = false, defaultCaseSensitive = false, defaultRegex = false, placeholder = 'Filter by filenames', onSearchTermChange, disableCaseSensitive = false, disableRegex = false } = props;

	const [isRegex, setIsRegex] = useState(typeof defaultVal === 'string' ? defaultRegex ? true : false : true)
	const toggleRegex = useCallback(() => {
		setIsRegex((prev) => !prev)
	},[])
	const [isCaseSensitive, setIsCaseSensitive] = useState(typeof defaultVal === 'string' ? defaultCaseSensitive : defaultVal.flags !== 'i')
	const toggleCaseSensitive = useCallback(() => {
		setIsCaseSensitive((prev) => !prev)
	},[])

	const validate = useCallback(async (value: ISearchTermFormData) => {
		const SearchTermValidatorShape = {
			'searchTerm': yup.string().notRequired().test(
				'is-valid-regex',
				'Please use a valid regular expression',
				(value, _context) => {
					let isValid = true;
					if (value) {
						try {
							new RegExp(value)
						}
						catch (err) {
							isValid = false;
						}
					}
					return isValid;
				},
			),
		};
		const SearchTermValidator = yup.object().shape(SearchTermValidatorShape);

		// eslint-disable-next-line @typescript-eslint/no-unsafe-argument, @typescript-eslint/no-explicit-any
		const validationErrors = await makeValidate(SearchTermValidator, makeTranslator(t, 'searchBar'))(value as any);

		if (!validationErrors || Object.keys(validationErrors).length === 0 ) {
			setTimeout(() => {
				const { searchTerm } = (value );
				if (searchTerm) {
					mixpanel.track('searchbar: search', { locationId, isCaseSensitive, isRegex, searchTerm });
				}
				onSearchTermChange({
					searchTerm: searchTerm ? isRegex ? new RegExp( searchTerm, isCaseSensitive ? '' : 'i') : searchTerm : null,
					isCaseSensitive,
					isRegex,
				})
			},1)
		}
		return validationErrors;
	}, [t, locationId, isCaseSensitive, isRegex, onSearchTermChange]);


	const onSubmit = useCallback((values: ISearchTermFormData) => {
		mixpanel.track('searchbar: search', { locationId, isCaseSensitive, isRegex, searchTerm: values.searchTerm });
		onSearchTermChange({
			searchTerm: values.searchTerm ? isRegex ? new RegExp(values.searchTerm, isCaseSensitive ? '' : 'i') : values.searchTerm : null,
			isCaseSensitive,
			isRegex,
		})
	}, [isCaseSensitive, isRegex, locationId, onSearchTermChange]);

	// Setup Update form
	const initialData: ISearchTermFormData = useMemo(() => ({
		searchTerm: typeof defaultVal === 'string' ?
			defaultVal
			:
			String(defaultVal).split('/')[1],
	}),[defaultVal])

	return  <Form
		onSubmit={onSubmit}
		initialValues={initialData}
		validate={validate}
		render={({ handleSubmit, form: { reset }, values: { searchTerm } }) => {
			// eslint-disable-next-line @typescript-eslint/no-misused-promises
			return <form onSubmit={handleSubmit} noValidate style={{ width:'100%' }}>
				<TextField
					variant='standard'
					autoFocus
					placeholder={placeholder}
					name='searchTerm'
					required
					inputProps={
						{ 'aria-label': 'search' }
					}
					InputProps={{
						startAdornment: <InputAdornment position='start'>
							{
								searchTerm ?
									<LightlyIconButton sx={{ padding: 0 }} disableRipple onClick={() => reset()}>
										{
											!isLoading ?
												<ClearIcon />
												:
												<CircularProgress disableShrink size={24} />
										}
									</LightlyIconButton>
									:
									<SearchIcon />
							}
						</InputAdornment>,
						endAdornment: <InputAdornment position='end'>
							{
								!disableCaseSensitive && <LightlyIconStateButtonMinimal onClick={toggleCaseSensitive}  active={isCaseSensitive} title={isCaseSensitive ? 'disable case sensitivity' : 'use case sensitivity'}>
									<div>Aa</div>
								</LightlyIconStateButtonMinimal>
							}
							{
								!disableRegex && <LightlyIconStateButtonMinimal onClick={toggleRegex} active={isRegex} title={isRegex ? 'disable regular expression' : 'use regular expression'}>
									<div style={{ fontSize: '1.25em', lineHeight: '1.25em' }}>.*</div>
								</LightlyIconStateButtonMinimal>
							}
						</InputAdornment>,
					}}
				/>
			</form>
		}}
	/>
};


export const SearchBarCheck = (searchTerm: ISearchTermState, value: string): boolean => {
	if (searchTerm) {
		// string search
		if (typeof searchTerm.searchTerm === 'string') {
			if (!searchTerm.isCaseSensitive) {
				return value.toLowerCase().includes(searchTerm.searchTerm.toLowerCase());
			}
			else {
				return value.includes(searchTerm.searchTerm);
			}
		}
		// regexp
		else if (searchTerm.searchTerm) {
			return searchTerm.searchTerm.test(value)
		}
	}
	return true;
}

export const SearchBarFilterName: React.FC<{searchState: ISearchTermState}> = ({ searchState }) => {
	const { searchTerm } = searchState || {};
	return <span>{typeof searchTerm === 'string' ? searchTerm : String(searchTerm).split('/')[1]}</span>
}


export const searchBar_en = {
	'field_searchTerm': 'search term',
}