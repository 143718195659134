import { createTheme, Theme, darken, lighten } from '@mui/material';

export const defaultTheme = createTheme();



declare module '@mui/material/styles' {

	// eslint-disable-next-line @typescript-eslint/naming-convention, @typescript-eslint/no-unused-vars
	interface Palette {
		tagWrapBG: string;
		slate: Palette['primary'];
		lightlyBrandGreen: Palette['primary'];
		lightlyBrandTurquoise: Palette['primary'];
		lightlyBrandBlue: Palette['primary'];
		lightlyLightBG: Palette['primary'];
		lightlyLightTitle: Palette['primary'];
		lightlyLightText: Palette['primary'];
		lightlyDarkBG: Palette['primary'];
		lightlyDarkBGLighter: Palette['primary'];
		lightlyDarkTitle: Palette['primary'];
		lightlyDarkText: Palette['primary'];
		lightlyBlueSpecial: Palette['primary'];
		lightlyPurpleSpecial: Palette['primary'];
	}
	// eslint-disable-next-line @typescript-eslint/naming-convention, @typescript-eslint/no-unused-vars
	interface PaletteOptions {
		tagWrapBG: string;
		slate: PaletteOptions['primary'];
		lightlyBrandGreen: PaletteOptions['primary'];
		lightlyBrandTurquoise: PaletteOptions['primary'];
		lightlyBrandBlue: PaletteOptions['primary'];
		lightlyLightBG: PaletteOptions['primary'];
		lightlyLightTitle: PaletteOptions['primary'];
		lightlyLightText: PaletteOptions['primary'];
		lightlyDarkBG: PaletteOptions['primary'];
		lightlyDarkBGLighter: PaletteOptions['primary'];
		lightlyDarkTitle: PaletteOptions['primary'];
		lightlyDarkText: PaletteOptions['primary'];
		lightlyBlueSpecial: PaletteOptions['primary'];
		lightlyPurpleSpecial: PaletteOptions['primary'];
	}
}

declare module '@mui/material/Button' {
	// eslint-disable-next-line @typescript-eslint/naming-convention, @typescript-eslint/no-unused-vars
	interface ButtonPropsColorOverrides {
		lightlyLightText: true;
	}
}

export const lightlyBrandGreen = '#3BD99F';
export const lightlyBrandTurquoise = '#2CC2BD';
export const lightlyBrandBlue = '#2BA3D1';

export const lightlyLightBG = '#F7F7F7';
export const lightlyLightTitle = '#266078';
export const lightlyLightText = '#000000';

export const lightlyDarkBG = '#092643';
export const lightlyDarkBGLighter = 'hsl(210deg 32% 34%)';
export const lightlyDarkTitle = '#FFFFFF';
export const lightlyDarkText = '#FFFFFF';

export const lightlyBlueSpecial = '#008FFE';

export const LightlyTheme: Theme = createTheme({
	breakpoints: { //use the breakpoints from material v4 as v5 handles xl very weird
		values: {
			xs: 0,
			sm: 600,
			md: 960,
			lg: 1280,
			xl: 1920,
		},
	},
	typography: {
		fontFamily: [
			'Montserrat',
			'Calibri',
			'sans-serif',
			'serif',
		].join(','),

		subtitle1: {
			fontFamily: 'Questrial, sans-serif',
			textTransform: 'uppercase',
			fontWeight: defaultTheme.typography.fontWeightMedium,
			color: lightlyLightTitle,
			fontSize: '1.1rem',
		},
	},
	palette: {
		tagWrapBG: lightlyDarkBGLighter, //defaultTheme.palette.grey[300],
		primary: {
			main: lightlyBrandTurquoise,
			light: lighten(lightlyBrandTurquoise, .1618),
			dark: darken(lightlyBrandTurquoise, .1618),
		},

		secondary: {
			main: '#f9a825', //lightlyBrandGreen,
			light: '#ffd95a', //lighten(lightlyBrandGreen, .1618),
			dark: '#c17900', //darken(lightlyBrandGreen, .1618),
		},

		//lightly
		lightlyBrandGreen: { main: lightlyBrandGreen, light: lighten(lightlyBrandGreen, .1618), dark: darken(lightlyBrandGreen, .1618) },
		lightlyBrandTurquoise: { main: lightlyBrandTurquoise, light: lighten(lightlyBrandTurquoise, .1618), dark: darken(lightlyBrandTurquoise, .1618) },
		lightlyBrandBlue: { main: lightlyBrandBlue, light: lighten(lightlyBrandBlue, .1618), dark: darken(lightlyBrandBlue, .1618) },
		lightlyLightBG: { main: lightlyLightBG, light: lighten(lightlyLightBG, .1618), dark: darken(lightlyLightBG, .1618) },
		lightlyLightTitle: { main: lightlyLightTitle, light: lighten(lightlyLightTitle, .1618), dark: darken(lightlyLightTitle, .1618) },
		lightlyLightText: { main: lightlyLightText, light: lighten(lightlyLightText, .1618), dark: darken(lightlyLightText, .1618) },
		lightlyDarkBG: { main: lightlyDarkBG, light: lighten(lightlyDarkBG, .1618), dark: darken(lightlyDarkBG, .1618) },
		lightlyDarkBGLighter: { main: lightlyDarkBGLighter, light: lighten(lightlyDarkBGLighter, .1618), dark: darken(lightlyDarkBGLighter, .1618) },
		lightlyDarkTitle: { main: lightlyDarkTitle, light: lighten(lightlyDarkTitle, .1618), dark: darken(lightlyDarkTitle, .1618) },
		lightlyDarkText: { main: lightlyDarkText, light: lighten(lightlyDarkText, .1618), dark: darken(lightlyDarkText, .1618) },
		lightlyBlueSpecial: { main: lightlyBlueSpecial, light: lighten(lightlyBlueSpecial, .1618), dark: darken(lightlyBlueSpecial, .1618) },
		lightlyPurpleSpecial: { main: '#9C92AC', light: '#DFDBE5' },

		//greys
		slate: { main: '#5a6574', light: lighten('#5a6574', .1618) },
	},
	components: {
		MuiCssBaseline: {
			styleOverrides: {
				'a:not(.MuiButtonBase-root)': {
					'&, &:link, &:visited, &:hover, &:active': {
						color: '#6a58a7',
						textDecoration: 'initial',
						fontWeight: 600,
					},
				},
			},
		},
		MuiButtonBase: {
			styleOverrides: {
				root: {
					'&.Mui-selected .MuiSvgIcon-root': {
						fill: 'url(#lightlyGradient)',
					},
				},
			},
		},
		MuiCheckbox: {
			styleOverrides: {
				root: {
					'& .MuiSvgIcon-root': { fill: 'url(#lightlyGradient)' },
				},
			},
		},
		MuiCardContent: {
			styleOverrides: {
				root: {
					padding: defaultTheme.spacing(2),
					'&:last-child': {
						paddingBottom: defaultTheme.spacing(2),
					},
				},
			},
		},
	},
});