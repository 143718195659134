/* tslint:disable */
/* eslint-disable */
/**
 * Lightly API
 * Lightly.ai enables you to do self-supervised learning in an easy and intuitive way. The lightly.ai OpenAPI spec defines how one can interact with our REST API to unleash the full potential of lightly.ai
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: support@lightly.ai
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
// SRC: https://github.com/OpenAPITools/openapi-generator/blob/master/modules/openapi-generator/src/main/resources/typescript-fetch/modelGeneric.mustache
// synched 02.02.2023
import * as yup from 'yup';
import typia from 'typia';
import * as fastJSON from 'fast-json-stringify';
import { Options, ArraySchema, ObjectSchema } from 'fast-json-stringify';
import { exists, mapValues } from '../runtime';
import type { DatasourceConfigVerifyDataErrors } from './DatasourceConfigVerifyDataErrors';
import { DatasourceConfigVerifyDataErrorsFromJSON, DatasourceConfigVerifyDataErrorsFromJSONTyped, DatasourceConfigVerifyDataErrorsToJSON, } from './DatasourceConfigVerifyDataErrors';
/*
causes circular dependendies
*/
// SRC: https://github.com/OpenAPITools/openapi-generator/blob/master/modules/openapi-generator/src/main/resources/typescript-fetch/modelGenericInterfaces.mustache
// synced: 17.07.2023
/**
 *
 * @export
 * @interface DatasourceConfigVerifyData
 *
 */
// create custom validators for the regexes
export interface DatasourceConfigVerifyData {
    /**
     *
     * #type boolean
     * #memberof DatasourceConfigVerifyData
        
        
         */
    canRead: boolean;
    /**
     *
     * #type boolean
     * #memberof DatasourceConfigVerifyData
        
        
         */
    canWrite: boolean;
    /**
     *
     * #type boolean
     * #memberof DatasourceConfigVerifyData
        
        
         */
    canList: boolean;
    /**
     *
     * #type boolean
     * #memberof DatasourceConfigVerifyData
        
        
         */
    canOverwrite: boolean;
    /**
     *
     * #type DatasourceConfigVerifyDataErrors
     * #memberof DatasourceConfigVerifyData
        
        
         */
    errors?: DatasourceConfigVerifyDataErrors;
}
/**
 * Check if a given object implements the DatasourceConfigVerifyData interface.
 */
export function instanceOfDatasourceConfigVerifyData(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "canRead" in value;
    isInstance = isInstance && "canWrite" in value;
    isInstance = isInstance && "canList" in value;
    isInstance = isInstance && "canOverwrite" in value;
    return isInstance;
}
/* LIGHTLY CUSTOM START -> modelGeneric */
import { DatasourceConfigVerifyDataErrorsValidator, DatasourceConfigVerifyDataErrorsStringifyShape, DatasourceConfigVerifyDataErrorsStringifyShapeProperties, } from './DatasourceConfigVerifyDataErrors';
export const DatasourceConfigVerifyDataTypiaAssertEquals = (input: any) => {
    return ((input: any): DatasourceConfigVerifyData => {
        const __is = (input: any, _exceptionable: boolean = true): input is DatasourceConfigVerifyData => {
            const $io0 = (input: any, _exceptionable: boolean = true): boolean => "boolean" === typeof input.canRead && "boolean" === typeof input.canWrite && "boolean" === typeof input.canList && "boolean" === typeof input.canOverwrite && (undefined === input.errors || "object" === typeof input.errors && null !== input.errors && false === Array.isArray(input.errors) && $io1(input.errors, true && _exceptionable)) && (4 === Object.keys(input).length || Object.keys(input).every((key: any) => {
                if (["canRead", "canWrite", "canList", "canOverwrite", "errors"].some((prop: any) => key === prop))
                    return true;
                const value = input[key];
                if (undefined === value)
                    return true;
                return false;
            }));
            const $io1 = (input: any, _exceptionable: boolean = true): boolean => (undefined === input.canRead || "string" === typeof input.canRead) && (undefined === input.canWrite || "string" === typeof input.canWrite) && (undefined === input.canList || "string" === typeof input.canList) && (undefined === input.canOverwrite || "string" === typeof input.canOverwrite) && (0 === Object.keys(input).length || Object.keys(input).every((key: any) => {
                if (["canRead", "canWrite", "canList", "canOverwrite"].some((prop: any) => key === prop))
                    return true;
                const value = input[key];
                if (undefined === value)
                    return true;
                return false;
            }));
            return "object" === typeof input && null !== input && $io0(input, true);
        };
        if (false === __is(input))
            ((input: any, _path: string, _exceptionable: boolean = true): input is DatasourceConfigVerifyData => {
                const $guard = (typia.createAssertEquals as any).guard;
                const $join = (typia.createAssertEquals as any).join;
                const $ao0 = (input: any, _path: string, _exceptionable: boolean = true): boolean => ("boolean" === typeof input.canRead || $guard(_exceptionable, {
                    path: _path + ".canRead",
                    expected: "boolean",
                    value: input.canRead
                })) && ("boolean" === typeof input.canWrite || $guard(_exceptionable, {
                    path: _path + ".canWrite",
                    expected: "boolean",
                    value: input.canWrite
                })) && ("boolean" === typeof input.canList || $guard(_exceptionable, {
                    path: _path + ".canList",
                    expected: "boolean",
                    value: input.canList
                })) && ("boolean" === typeof input.canOverwrite || $guard(_exceptionable, {
                    path: _path + ".canOverwrite",
                    expected: "boolean",
                    value: input.canOverwrite
                })) && (undefined === input.errors || ("object" === typeof input.errors && null !== input.errors && false === Array.isArray(input.errors) || $guard(_exceptionable, {
                    path: _path + ".errors",
                    expected: "(DatasourceConfigVerifyDataErrors | undefined)",
                    value: input.errors
                })) && $ao1(input.errors, _path + ".errors", true && _exceptionable) || $guard(_exceptionable, {
                    path: _path + ".errors",
                    expected: "(DatasourceConfigVerifyDataErrors | undefined)",
                    value: input.errors
                })) && (4 === Object.keys(input).length || (false === _exceptionable || Object.keys(input).every((key: any) => {
                    if (["canRead", "canWrite", "canList", "canOverwrite", "errors"].some((prop: any) => key === prop))
                        return true;
                    const value = input[key];
                    if (undefined === value)
                        return true;
                    return $guard(_exceptionable, {
                        path: _path + $join(key),
                        expected: "undefined",
                        value: value
                    });
                })));
                const $ao1 = (input: any, _path: string, _exceptionable: boolean = true): boolean => (undefined === input.canRead || "string" === typeof input.canRead || $guard(_exceptionable, {
                    path: _path + ".canRead",
                    expected: "(string | undefined)",
                    value: input.canRead
                })) && (undefined === input.canWrite || "string" === typeof input.canWrite || $guard(_exceptionable, {
                    path: _path + ".canWrite",
                    expected: "(string | undefined)",
                    value: input.canWrite
                })) && (undefined === input.canList || "string" === typeof input.canList || $guard(_exceptionable, {
                    path: _path + ".canList",
                    expected: "(string | undefined)",
                    value: input.canList
                })) && (undefined === input.canOverwrite || "string" === typeof input.canOverwrite || $guard(_exceptionable, {
                    path: _path + ".canOverwrite",
                    expected: "(string | undefined)",
                    value: input.canOverwrite
                })) && (0 === Object.keys(input).length || (false === _exceptionable || Object.keys(input).every((key: any) => {
                    if (["canRead", "canWrite", "canList", "canOverwrite"].some((prop: any) => key === prop))
                        return true;
                    const value = input[key];
                    if (undefined === value)
                        return true;
                    return $guard(_exceptionable, {
                        path: _path + $join(key),
                        expected: "undefined",
                        value: value
                    });
                })));
                return ("object" === typeof input && null !== input || $guard(true, {
                    path: _path + "",
                    expected: "DatasourceConfigVerifyData",
                    value: input
                })) && $ao0(input, _path + "", true) || $guard(true, {
                    path: _path + "",
                    expected: "DatasourceConfigVerifyData",
                    value: input
                });
            })(input, "$input", true);
        return input;
    })(input);
};
export const DatasourceConfigVerifyDataTypiaAssertStringify = (input: DatasourceConfigVerifyData): string => {
    return ((input: any): string => { const assert = (input: any): DatasourceConfigVerifyData => {
        const __is = (input: any): input is DatasourceConfigVerifyData => {
            const $io0 = (input: any): boolean => "boolean" === typeof input.canRead && "boolean" === typeof input.canWrite && "boolean" === typeof input.canList && "boolean" === typeof input.canOverwrite && (undefined === input.errors || "object" === typeof input.errors && null !== input.errors && false === Array.isArray(input.errors) && $io1(input.errors));
            const $io1 = (input: any): boolean => (undefined === input.canRead || "string" === typeof input.canRead) && (undefined === input.canWrite || "string" === typeof input.canWrite) && (undefined === input.canList || "string" === typeof input.canList) && (undefined === input.canOverwrite || "string" === typeof input.canOverwrite);
            return "object" === typeof input && null !== input && $io0(input);
        };
        if (false === __is(input))
            ((input: any, _path: string, _exceptionable: boolean = true): input is DatasourceConfigVerifyData => {
                const $guard = (typia.createAssertStringify as any).guard;
                const $ao0 = (input: any, _path: string, _exceptionable: boolean = true): boolean => ("boolean" === typeof input.canRead || $guard(_exceptionable, {
                    path: _path + ".canRead",
                    expected: "boolean",
                    value: input.canRead
                })) && ("boolean" === typeof input.canWrite || $guard(_exceptionable, {
                    path: _path + ".canWrite",
                    expected: "boolean",
                    value: input.canWrite
                })) && ("boolean" === typeof input.canList || $guard(_exceptionable, {
                    path: _path + ".canList",
                    expected: "boolean",
                    value: input.canList
                })) && ("boolean" === typeof input.canOverwrite || $guard(_exceptionable, {
                    path: _path + ".canOverwrite",
                    expected: "boolean",
                    value: input.canOverwrite
                })) && (undefined === input.errors || ("object" === typeof input.errors && null !== input.errors && false === Array.isArray(input.errors) || $guard(_exceptionable, {
                    path: _path + ".errors",
                    expected: "(DatasourceConfigVerifyDataErrors | undefined)",
                    value: input.errors
                })) && $ao1(input.errors, _path + ".errors", true && _exceptionable) || $guard(_exceptionable, {
                    path: _path + ".errors",
                    expected: "(DatasourceConfigVerifyDataErrors | undefined)",
                    value: input.errors
                }));
                const $ao1 = (input: any, _path: string, _exceptionable: boolean = true): boolean => (undefined === input.canRead || "string" === typeof input.canRead || $guard(_exceptionable, {
                    path: _path + ".canRead",
                    expected: "(string | undefined)",
                    value: input.canRead
                })) && (undefined === input.canWrite || "string" === typeof input.canWrite || $guard(_exceptionable, {
                    path: _path + ".canWrite",
                    expected: "(string | undefined)",
                    value: input.canWrite
                })) && (undefined === input.canList || "string" === typeof input.canList || $guard(_exceptionable, {
                    path: _path + ".canList",
                    expected: "(string | undefined)",
                    value: input.canList
                })) && (undefined === input.canOverwrite || "string" === typeof input.canOverwrite || $guard(_exceptionable, {
                    path: _path + ".canOverwrite",
                    expected: "(string | undefined)",
                    value: input.canOverwrite
                }));
                return ("object" === typeof input && null !== input || $guard(true, {
                    path: _path + "",
                    expected: "DatasourceConfigVerifyData",
                    value: input
                })) && $ao0(input, _path + "", true) || $guard(true, {
                    path: _path + "",
                    expected: "DatasourceConfigVerifyData",
                    value: input
                });
            })(input, "$input", true);
        return input;
    }; const stringify = (input: DatasourceConfigVerifyData): string => {
        const $io1 = (input: any): boolean => (undefined === input.canRead || "string" === typeof input.canRead) && (undefined === input.canWrite || "string" === typeof input.canWrite) && (undefined === input.canList || "string" === typeof input.canList) && (undefined === input.canOverwrite || "string" === typeof input.canOverwrite);
        const $string = (typia.createAssertStringify as any).string;
        const $tail = (typia.createAssertStringify as any).tail;
        const $so0 = (input: any): any => `{${undefined === input.errors ? "" : `"errors":${undefined !== input.errors ? $so1(input.errors) : undefined},`}"canRead":${input.canRead},"canWrite":${input.canWrite},"canList":${input.canList},"canOverwrite":${input.canOverwrite}}`;
        const $so1 = (input: any): any => `{${$tail(`${undefined === input.canRead ? "" : `"canRead":${undefined !== input.canRead ? $string(input.canRead) : undefined},`}${undefined === input.canWrite ? "" : `"canWrite":${undefined !== input.canWrite ? $string(input.canWrite) : undefined},`}${undefined === input.canList ? "" : `"canList":${undefined !== input.canList ? $string(input.canList) : undefined},`}${undefined === input.canOverwrite ? "" : `"canOverwrite":${undefined !== input.canOverwrite ? $string(input.canOverwrite) : undefined}`}`)}}`;
        return $so0(input);
    }; return stringify(assert(input)); })(input);
};
export const DatasourceConfigVerifyDataValidatorShape = {
    'canRead': yup.boolean().defined(),
    'canWrite': yup.boolean().defined(),
    'canList': yup.boolean().defined(),
    'canOverwrite': yup.boolean().defined(),
    'errors': DatasourceConfigVerifyDataErrorsValidator.optional().default(undefined),
};
export const DatasourceConfigVerifyDataValidator = yup.object().shape(DatasourceConfigVerifyDataValidatorShape);
export const DatasourceConfigVerifyDataStringifyShapeProperties: ObjectSchema['properties'] = {
    'canRead': {
        'type': 'boolean',
    },
    'canWrite': {
        'type': 'boolean',
    },
    'canList': {
        'type': 'boolean',
    },
    'canOverwrite': {
        'type': 'boolean',
    },
    'errors': DatasourceConfigVerifyDataErrorsStringifyShape,
};
export const DatasourceConfigVerifyDataStringifyShape: ObjectSchema = {
    title: 'DatasourceConfigVerifyData Stringifier',
    type: 'object',
    properties: {
        ...DatasourceConfigVerifyDataStringifyShapeProperties
    },
    required: [
        'canRead',
        'canWrite',
        'canList',
        'canOverwrite',
    ],
};
export const DatasourceConfigVerifyDataStringifyCompiled = (typeof process === 'object' && String(process) === '[object process]') ?
    fastJSON.build(DatasourceConfigVerifyDataStringifyShape, {
        ajv: {
            // since we are already doing this with yup, lets skip
            validateSchema: false,
            ownProperties: true,
            validateFormats: false,
        },
        largeArrayMechanism: 'json-stringify',
        largeArraySize: 1000,
    } as Options)
    :
        () => { throw new Error('fast-json-stringify only works in nodejs'); };
export const DatasourceConfigVerifyDataStringify: (data: DatasourceConfigVerifyData) => string = DatasourceConfigVerifyDataStringifyCompiled;
/* LIGHTLY CUSTOM END -> Generic */
export function DatasourceConfigVerifyDataFromJSON(json: any): DatasourceConfigVerifyData {
    return DatasourceConfigVerifyDataFromJSONTyped(json, false);
}
export function DatasourceConfigVerifyDataFromJSONTyped(json: any, ignoreDiscriminator: boolean): DatasourceConfigVerifyData {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    /* causes circular depdendencies
*/
    return {
        'canRead': json['canRead'],
        'canWrite': json['canWrite'],
        'canList': json['canList'],
        'canOverwrite': json['canOverwrite'],
        'errors': !exists(json, 'errors') ? undefined : DatasourceConfigVerifyDataErrorsFromJSON(json['errors']),
    };
}
export function DatasourceConfigVerifyDataToJSON(value?: DatasourceConfigVerifyData | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'canRead': value.canRead,
        'canWrite': value.canWrite,
        'canList': value.canList,
        'canOverwrite': value.canOverwrite,
        'errors': DatasourceConfigVerifyDataErrorsToJSON(value.errors),
    };
}
