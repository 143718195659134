// SRC: https://github.com/OpenAPITools/openapi-generator/blob/master/modules/openapi-generator/src/main/resources/typescript-fetch/apis.mustache
// synched 02.02.2023
import * as yup from 'yup';
import typia from 'typia';
/* tslint:disable */
/* eslint-disable */
/**
 * Lightly API
 * Lightly.ai enables you to do self-supervised learning in an easy and intuitive way. The lightly.ai OpenAPI spec defines how one can interact with our REST API to unleash the full potential of lightly.ai
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: support@lightly.ai
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import * as runtime from '../runtime';
import type { ApiErrorResponse, Consent, DelegatedAccessExternalIds, ProfileBasicData, ProfileMeData, QuestionnaireData, } from '../models';
import { ApiErrorResponseFromJSON, ApiErrorResponseToJSON, ApiErrorResponseValidator, ConsentFromJSON, ConsentToJSON, ConsentValidator, DelegatedAccessExternalIdsFromJSON, DelegatedAccessExternalIdsToJSON, DelegatedAccessExternalIdsValidator, ProfileBasicDataFromJSON, ProfileBasicDataToJSON, ProfileBasicDataValidator, ProfileMeDataFromJSON, ProfileMeDataToJSON, ProfileMeDataValidator, QuestionnaireDataFromJSON, QuestionnaireDataToJSON, QuestionnaireDataValidator, } from '../models';
import * as models from '../models';
/* LIGHTLY CUSTOM START -> -> moved from bottom to top:  apiMustache */
/* LIGHTLY CUSTOM END -> moved from bottom to top: apiMustache */
export interface GetProfileOfUserByIdRequest {
    userId: string;
}
/* LIGHTLY CUSTOM START -> apis */
export const GetProfileOfUserByIdRequestValidatorShape = {
    'userId': yup.string().defined(),
};
export const GetProfileOfUserByIdRequestValidator = yup.object().shape(GetProfileOfUserByIdRequestValidatorShape);
export interface UpdateConsentRequest {
    consent: models.Consent;
}
/* LIGHTLY CUSTOM START -> apis */
export const UpdateConsentRequestValidatorShape = {
    'consent': models.ConsentValidator.defined(),
};
export const UpdateConsentRequestValidator = yup.object().shape(UpdateConsentRequestValidatorShape);
export interface UpdateQuestionnaireRequest {
    questionnaireData: models.QuestionnaireData;
}
/* LIGHTLY CUSTOM START -> apis */
export const UpdateQuestionnaireRequestValidatorShape = {
    'questionnaireData': models.QuestionnaireDataValidator.defined(),
};
export const UpdateQuestionnaireRequestValidator = yup.object().shape(UpdateQuestionnaireRequestValidatorShape);
export interface GetConsentRequestParams {
}
export function GetConsentRequestParamsSanitizer(json: any): GetConsentRequestParams {
    return {};
}
export const GetConsentRequestParamsValidatorShape = {};
export const GetConsentRequestParamsValidator = yup.object().shape(GetConsentRequestParamsValidatorShape);
export interface GetDelegatedAccessExternalIdsOfLoggedInUserRequestParams {
}
export function GetDelegatedAccessExternalIdsOfLoggedInUserRequestParamsSanitizer(json: any): GetDelegatedAccessExternalIdsOfLoggedInUserRequestParams {
    return {};
}
export const GetDelegatedAccessExternalIdsOfLoggedInUserRequestParamsValidatorShape = {};
export const GetDelegatedAccessExternalIdsOfLoggedInUserRequestParamsValidator = yup.object().shape(GetDelegatedAccessExternalIdsOfLoggedInUserRequestParamsValidatorShape);
export interface GetProfileOfLoggedInUserRequestParams {
}
export function GetProfileOfLoggedInUserRequestParamsSanitizer(json: any): GetProfileOfLoggedInUserRequestParams {
    return {};
}
export const GetProfileOfLoggedInUserRequestParamsValidatorShape = {};
export const GetProfileOfLoggedInUserRequestParamsValidator = yup.object().shape(GetProfileOfLoggedInUserRequestParamsValidatorShape);
export interface GetProfileOfUserByIdRequestParams {
    userId: string;
}
export function GetProfileOfUserByIdRequestParamsSanitizer(json: any): GetProfileOfUserByIdRequestParams {
    return {
        'userId': json['userId'],
    };
}
export const GetProfileOfUserByIdRequestParamsValidatorShape = {
    'userId': yup.string().defined(),
};
export const GetProfileOfUserByIdRequestParamsValidator = yup.object().shape(GetProfileOfUserByIdRequestParamsValidatorShape);
export interface UpdateConsentRequestParams {
}
export function UpdateConsentRequestParamsSanitizer(json: any): UpdateConsentRequestParams {
    return {};
}
export const UpdateConsentRequestParamsValidatorShape = {};
export const UpdateConsentRequestParamsValidator = yup.object().shape(UpdateConsentRequestParamsValidatorShape);
export interface UpdateQuestionnaireRequestParams {
}
export function UpdateQuestionnaireRequestParamsSanitizer(json: any): UpdateQuestionnaireRequestParams {
    return {};
}
export const UpdateQuestionnaireRequestParamsValidatorShape = {};
export const UpdateQuestionnaireRequestParamsValidator = yup.object().shape(UpdateQuestionnaireRequestParamsValidatorShape);
export interface GetConsentRequestQuery {
}
export function GetConsentRequestQuerySanitizer(json: any): GetConsentRequestQuery {
    return {};
}
export const GetConsentRequestQueryValidatorShape = {};
export const GetConsentRequestQueryValidator = yup.object().shape(GetConsentRequestQueryValidatorShape);
export interface GetDelegatedAccessExternalIdsOfLoggedInUserRequestQuery {
}
export function GetDelegatedAccessExternalIdsOfLoggedInUserRequestQuerySanitizer(json: any): GetDelegatedAccessExternalIdsOfLoggedInUserRequestQuery {
    return {};
}
export const GetDelegatedAccessExternalIdsOfLoggedInUserRequestQueryValidatorShape = {};
export const GetDelegatedAccessExternalIdsOfLoggedInUserRequestQueryValidator = yup.object().shape(GetDelegatedAccessExternalIdsOfLoggedInUserRequestQueryValidatorShape);
export interface GetProfileOfLoggedInUserRequestQuery {
}
export function GetProfileOfLoggedInUserRequestQuerySanitizer(json: any): GetProfileOfLoggedInUserRequestQuery {
    return {};
}
export const GetProfileOfLoggedInUserRequestQueryValidatorShape = {};
export const GetProfileOfLoggedInUserRequestQueryValidator = yup.object().shape(GetProfileOfLoggedInUserRequestQueryValidatorShape);
export interface GetProfileOfUserByIdRequestQuery {
}
export function GetProfileOfUserByIdRequestQuerySanitizer(json: any): GetProfileOfUserByIdRequestQuery {
    return {};
}
export const GetProfileOfUserByIdRequestQueryValidatorShape = {};
export const GetProfileOfUserByIdRequestQueryValidator = yup.object().shape(GetProfileOfUserByIdRequestQueryValidatorShape);
export interface UpdateConsentRequestQuery {
}
export function UpdateConsentRequestQuerySanitizer(json: any): UpdateConsentRequestQuery {
    return {};
}
export const UpdateConsentRequestQueryValidatorShape = {};
export const UpdateConsentRequestQueryValidator = yup.object().shape(UpdateConsentRequestQueryValidatorShape);
export interface UpdateQuestionnaireRequestQuery {
}
export function UpdateQuestionnaireRequestQuerySanitizer(json: any): UpdateQuestionnaireRequestQuery {
    return {};
}
export const UpdateQuestionnaireRequestQueryValidatorShape = {};
export const UpdateQuestionnaireRequestQueryValidator = yup.object().shape(UpdateQuestionnaireRequestQueryValidatorShape);
export type UpdateConsentRequestBody = Consent;
export const UpdateConsentRequestBodySanitizer = ConsentFromJSON;
export const UpdateConsentRequestBodyValidator = ConsentValidator;
export const UpdateConsentTypiaAssertEquals = (input: any) => {
    return ((input: any): UpdateConsentRequestBody => {
        const __is = (input: any, _exceptionable: boolean = true): input is UpdateConsentRequestBody => {
            const $join = (typia.createAssertEquals as any).join;
            const $io0 = (input: any, _exceptionable: boolean = true): boolean => (undefined === input.acceptedNewsletter || "boolean" === typeof input.acceptedNewsletter) && (undefined === input.acceptedTermsDate || "number" === typeof input.acceptedTermsDate && 0 <= input.acceptedTermsDate) && Object.keys(input).every((key: any) => {
                if (["acceptedNewsletter", "acceptedTermsDate"].some((prop: any) => key === prop))
                    return true;
                const value = input[key];
                if (undefined === value)
                    return true;
                if (RegExp(/(.*)/).test(key))
                    return true;
                return false;
            });
            return "object" === typeof input && null !== input && false === Array.isArray(input) && $io0(input, true);
        };
        if (false === __is(input))
            ((input: any, _path: string, _exceptionable: boolean = true): input is UpdateConsentRequestBody => {
                const $guard = (typia.createAssertEquals as any).guard;
                const $join = (typia.createAssertEquals as any).join;
                const $ao0 = (input: any, _path: string, _exceptionable: boolean = true): boolean => (undefined === input.acceptedNewsletter || "boolean" === typeof input.acceptedNewsletter || $guard(_exceptionable, {
                    path: _path + ".acceptedNewsletter",
                    expected: "(boolean | undefined)",
                    value: input.acceptedNewsletter
                })) && (undefined === input.acceptedTermsDate || "number" === typeof input.acceptedTermsDate && (0 <= input.acceptedTermsDate || $guard(_exceptionable, {
                    path: _path + ".acceptedTermsDate",
                    expected: "number (@minimum 0)",
                    value: input.acceptedTermsDate
                })) || $guard(_exceptionable, {
                    path: _path + ".acceptedTermsDate",
                    expected: "(number | undefined)",
                    value: input.acceptedTermsDate
                })) && (false === _exceptionable || Object.keys(input).every((key: any) => {
                    if (["acceptedNewsletter", "acceptedTermsDate"].some((prop: any) => key === prop))
                        return true;
                    const value = input[key];
                    if (undefined === value)
                        return true;
                    if (RegExp(/(.*)/).test(key))
                        return true;
                    return $guard(_exceptionable, {
                        path: _path + $join(key),
                        expected: "undefined",
                        value: value
                    });
                }));
                return ("object" === typeof input && null !== input && false === Array.isArray(input) || $guard(true, {
                    path: _path + "",
                    expected: "Consent",
                    value: input
                })) && $ao0(input, _path + "", true) || $guard(true, {
                    path: _path + "",
                    expected: "Consent",
                    value: input
                });
            })(input, "$input", true);
        return input;
    })(input);
};
export const UpdateConsentTypiaAssertStringify = (input: UpdateConsentRequestBody): string => {
    return ((input: any): string => { const assert = (input: any): UpdateConsentRequestBody => {
        const __is = (input: any): input is UpdateConsentRequestBody => {
            const $join = (typia.createAssertStringify as any).join;
            const $io0 = (input: any): boolean => (undefined === input.acceptedNewsletter || "boolean" === typeof input.acceptedNewsletter) && (undefined === input.acceptedTermsDate || "number" === typeof input.acceptedTermsDate && !Number.isNaN(input.acceptedTermsDate) && 0 <= input.acceptedTermsDate) && Object.keys(input).every((key: any) => {
                if (["acceptedNewsletter", "acceptedTermsDate"].some((prop: any) => key === prop))
                    return true;
                const value = input[key];
                if (undefined === value)
                    return true;
                if (RegExp(/(.*)/).test(key))
                    return true;
                return true;
            });
            return "object" === typeof input && null !== input && false === Array.isArray(input) && $io0(input);
        };
        if (false === __is(input))
            ((input: any, _path: string, _exceptionable: boolean = true): input is UpdateConsentRequestBody => {
                const $guard = (typia.createAssertStringify as any).guard;
                const $join = (typia.createAssertStringify as any).join;
                const $ao0 = (input: any, _path: string, _exceptionable: boolean = true): boolean => (undefined === input.acceptedNewsletter || "boolean" === typeof input.acceptedNewsletter || $guard(_exceptionable, {
                    path: _path + ".acceptedNewsletter",
                    expected: "(boolean | undefined)",
                    value: input.acceptedNewsletter
                })) && (undefined === input.acceptedTermsDate || "number" === typeof input.acceptedTermsDate && !Number.isNaN(input.acceptedTermsDate) && (0 <= input.acceptedTermsDate || $guard(_exceptionable, {
                    path: _path + ".acceptedTermsDate",
                    expected: "number (@minimum 0)",
                    value: input.acceptedTermsDate
                })) || $guard(_exceptionable, {
                    path: _path + ".acceptedTermsDate",
                    expected: "(number | undefined)",
                    value: input.acceptedTermsDate
                })) && (false === _exceptionable || Object.keys(input).every((key: any) => {
                    if (["acceptedNewsletter", "acceptedTermsDate"].some((prop: any) => key === prop))
                        return true;
                    const value = input[key];
                    if (undefined === value)
                        return true;
                    if (RegExp(/(.*)/).test(key))
                        return true;
                    return true;
                }));
                return ("object" === typeof input && null !== input && false === Array.isArray(input) || $guard(true, {
                    path: _path + "",
                    expected: "Consent",
                    value: input
                })) && $ao0(input, _path + "", true) || $guard(true, {
                    path: _path + "",
                    expected: "Consent",
                    value: input
                });
            })(input, "$input", true);
        return input;
    }; const stringify = (input: UpdateConsentRequestBody): string => {
        const $join = (typia.createAssertStringify as any).join;
        const $tail = (typia.createAssertStringify as any).tail;
        const $so0 = (input: any): any => `{${$tail(`${undefined === input.acceptedNewsletter ? "" : `"acceptedNewsletter":${undefined !== input.acceptedNewsletter ? input.acceptedNewsletter : undefined},`}${undefined === input.acceptedTermsDate ? "" : `"acceptedTermsDate":${undefined !== input.acceptedTermsDate ? input.acceptedTermsDate : undefined},`}${Object.entries(input).map(([key, value]: [string, any]) => { if (undefined === value)
            return ""; if (["acceptedNewsletter", "acceptedTermsDate"].some((regular: any) => regular === key))
            return ""; return `${JSON.stringify(key)}:${undefined !== value ? JSON.stringify(value) : undefined}`; }).filter((str: any) => "" !== str).join(",")}`)}}`;
        return $so0(input);
    }; return stringify(assert(input)); })(input);
};
export type UpdateQuestionnaireRequestBody = QuestionnaireData;
export const UpdateQuestionnaireRequestBodySanitizer = QuestionnaireDataFromJSON;
export const UpdateQuestionnaireRequestBodyValidator = QuestionnaireDataValidator;
export const UpdateQuestionnaireTypiaAssertEquals = (input: any) => {
    return ((input: any): UpdateQuestionnaireRequestBody => {
        const __is = (input: any, _exceptionable: boolean = true): input is UpdateQuestionnaireRequestBody => {
            const $io0 = (input: any, _exceptionable: boolean = true): boolean => (undefined === input.company || "string" === typeof input.company && 3 <= input.company.length) && (undefined === input.sector || "ADVERTISING" === input.sector || "AGRICULTURE" === input.sector || "AUTOMOTIVE" === input.sector || "EDUCATION" === input.sector || "ENERGY" === input.sector || "ENTERTAINMENT" === input.sector || "ENVIRONMENTAL" === input.sector || "FINANCE" === input.sector || "FOOD" === input.sector || "HEALTHCARE" === input.sector || "INTERNET_OF_THINGS" === input.sector || "LOGISTICS" === input.sector || "MACHINE_LEARNING" === input.sector || "MANUFACTURING" === input.sector || "MEDICINE" === input.sector || "RECYCLING" === input.sector || "RETAIL" === input.sector || "ROBOTICS" === input.sector || "SECURITY" === input.sector || "SOFTWARE_DEVELOPMENT" === input.sector || "SPORTS" === input.sector || "SURVEILLANCE" === input.sector || "TRANSPORTATION" === input.sector || "OTHER" === input.sector) && (undefined === input.usage || "LABELING" === input.usage || "SELECTING" === input.usage || "VISUALIZATION" === input.usage || "MODEL_TRAINING" === input.usage || "DATASET_MANAGING" === input.usage || "CHECKING_THE_TOOL" === input.usage || "OTHER" === input.usage) && (null === input.usageCustomReason || undefined === input.usageCustomReason || "string" === typeof input.usageCustomReason) && (0 === Object.keys(input).length || Object.keys(input).every((key: any) => {
                if (["company", "sector", "usage", "usageCustomReason"].some((prop: any) => key === prop))
                    return true;
                const value = input[key];
                if (undefined === value)
                    return true;
                return false;
            }));
            return "object" === typeof input && null !== input && false === Array.isArray(input) && $io0(input, true);
        };
        if (false === __is(input))
            ((input: any, _path: string, _exceptionable: boolean = true): input is UpdateQuestionnaireRequestBody => {
                const $guard = (typia.createAssertEquals as any).guard;
                const $join = (typia.createAssertEquals as any).join;
                const $ao0 = (input: any, _path: string, _exceptionable: boolean = true): boolean => (undefined === input.company || "string" === typeof input.company && (3 <= input.company.length || $guard(_exceptionable, {
                    path: _path + ".company",
                    expected: "string (@minLength 3)",
                    value: input.company
                })) || $guard(_exceptionable, {
                    path: _path + ".company",
                    expected: "(string | undefined)",
                    value: input.company
                })) && (undefined === input.sector || "ADVERTISING" === input.sector || "AGRICULTURE" === input.sector || "AUTOMOTIVE" === input.sector || "EDUCATION" === input.sector || "ENERGY" === input.sector || "ENTERTAINMENT" === input.sector || "ENVIRONMENTAL" === input.sector || "FINANCE" === input.sector || "FOOD" === input.sector || "HEALTHCARE" === input.sector || "INTERNET_OF_THINGS" === input.sector || "LOGISTICS" === input.sector || "MACHINE_LEARNING" === input.sector || "MANUFACTURING" === input.sector || "MEDICINE" === input.sector || "RECYCLING" === input.sector || "RETAIL" === input.sector || "ROBOTICS" === input.sector || "SECURITY" === input.sector || "SOFTWARE_DEVELOPMENT" === input.sector || "SPORTS" === input.sector || "SURVEILLANCE" === input.sector || "TRANSPORTATION" === input.sector || "OTHER" === input.sector || $guard(_exceptionable, {
                    path: _path + ".sector",
                    expected: "(\"ADVERTISING\" | \"AGRICULTURE\" | \"AUTOMOTIVE\" | \"EDUCATION\" | \"ENERGY\" | \"ENTERTAINMENT\" | \"ENVIRONMENTAL\" | \"FINANCE\" | \"FOOD\" | \"HEALTHCARE\" | \"INTERNET_OF_THINGS\" | \"LOGISTICS\" | \"MACHINE_LEARNING\" | \"MANUFACTURING\" | \"MEDICINE\" | \"OTHER\" | \"RECYCLING\" | \"RETAIL\" | \"ROBOTICS\" | \"SECURITY\" | \"SOFTWARE_DEVELOPMENT\" | \"SPORTS\" | \"SURVEILLANCE\" | \"TRANSPORTATION\" | undefined)",
                    value: input.sector
                })) && (undefined === input.usage || "LABELING" === input.usage || "SELECTING" === input.usage || "VISUALIZATION" === input.usage || "MODEL_TRAINING" === input.usage || "DATASET_MANAGING" === input.usage || "CHECKING_THE_TOOL" === input.usage || "OTHER" === input.usage || $guard(_exceptionable, {
                    path: _path + ".usage",
                    expected: "(\"CHECKING_THE_TOOL\" | \"DATASET_MANAGING\" | \"LABELING\" | \"MODEL_TRAINING\" | \"OTHER\" | \"SELECTING\" | \"VISUALIZATION\" | undefined)",
                    value: input.usage
                })) && (null === input.usageCustomReason || undefined === input.usageCustomReason || "string" === typeof input.usageCustomReason || $guard(_exceptionable, {
                    path: _path + ".usageCustomReason",
                    expected: "(null | string | undefined)",
                    value: input.usageCustomReason
                })) && (0 === Object.keys(input).length || (false === _exceptionable || Object.keys(input).every((key: any) => {
                    if (["company", "sector", "usage", "usageCustomReason"].some((prop: any) => key === prop))
                        return true;
                    const value = input[key];
                    if (undefined === value)
                        return true;
                    return $guard(_exceptionable, {
                        path: _path + $join(key),
                        expected: "undefined",
                        value: value
                    });
                })));
                return ("object" === typeof input && null !== input && false === Array.isArray(input) || $guard(true, {
                    path: _path + "",
                    expected: "QuestionnaireData",
                    value: input
                })) && $ao0(input, _path + "", true) || $guard(true, {
                    path: _path + "",
                    expected: "QuestionnaireData",
                    value: input
                });
            })(input, "$input", true);
        return input;
    })(input);
};
export const UpdateQuestionnaireTypiaAssertStringify = (input: UpdateQuestionnaireRequestBody): string => {
    return ((input: any): string => { const assert = (input: any): UpdateQuestionnaireRequestBody => {
        const __is = (input: any): input is UpdateQuestionnaireRequestBody => {
            const $io0 = (input: any): boolean => (undefined === input.company || "string" === typeof input.company && 3 <= input.company.length) && (undefined === input.sector || "ADVERTISING" === input.sector || "AGRICULTURE" === input.sector || "AUTOMOTIVE" === input.sector || "EDUCATION" === input.sector || "ENERGY" === input.sector || "ENTERTAINMENT" === input.sector || "ENVIRONMENTAL" === input.sector || "FINANCE" === input.sector || "FOOD" === input.sector || "HEALTHCARE" === input.sector || "INTERNET_OF_THINGS" === input.sector || "LOGISTICS" === input.sector || "MACHINE_LEARNING" === input.sector || "MANUFACTURING" === input.sector || "MEDICINE" === input.sector || "RECYCLING" === input.sector || "RETAIL" === input.sector || "ROBOTICS" === input.sector || "SECURITY" === input.sector || "SOFTWARE_DEVELOPMENT" === input.sector || "SPORTS" === input.sector || "SURVEILLANCE" === input.sector || "TRANSPORTATION" === input.sector || "OTHER" === input.sector) && (undefined === input.usage || "LABELING" === input.usage || "SELECTING" === input.usage || "VISUALIZATION" === input.usage || "MODEL_TRAINING" === input.usage || "DATASET_MANAGING" === input.usage || "CHECKING_THE_TOOL" === input.usage || "OTHER" === input.usage) && (null === input.usageCustomReason || undefined === input.usageCustomReason || "string" === typeof input.usageCustomReason);
            return "object" === typeof input && null !== input && false === Array.isArray(input) && $io0(input);
        };
        if (false === __is(input))
            ((input: any, _path: string, _exceptionable: boolean = true): input is UpdateQuestionnaireRequestBody => {
                const $guard = (typia.createAssertStringify as any).guard;
                const $ao0 = (input: any, _path: string, _exceptionable: boolean = true): boolean => (undefined === input.company || "string" === typeof input.company && (3 <= input.company.length || $guard(_exceptionable, {
                    path: _path + ".company",
                    expected: "string (@minLength 3)",
                    value: input.company
                })) || $guard(_exceptionable, {
                    path: _path + ".company",
                    expected: "(string | undefined)",
                    value: input.company
                })) && (undefined === input.sector || "ADVERTISING" === input.sector || "AGRICULTURE" === input.sector || "AUTOMOTIVE" === input.sector || "EDUCATION" === input.sector || "ENERGY" === input.sector || "ENTERTAINMENT" === input.sector || "ENVIRONMENTAL" === input.sector || "FINANCE" === input.sector || "FOOD" === input.sector || "HEALTHCARE" === input.sector || "INTERNET_OF_THINGS" === input.sector || "LOGISTICS" === input.sector || "MACHINE_LEARNING" === input.sector || "MANUFACTURING" === input.sector || "MEDICINE" === input.sector || "RECYCLING" === input.sector || "RETAIL" === input.sector || "ROBOTICS" === input.sector || "SECURITY" === input.sector || "SOFTWARE_DEVELOPMENT" === input.sector || "SPORTS" === input.sector || "SURVEILLANCE" === input.sector || "TRANSPORTATION" === input.sector || "OTHER" === input.sector || $guard(_exceptionable, {
                    path: _path + ".sector",
                    expected: "(\"ADVERTISING\" | \"AGRICULTURE\" | \"AUTOMOTIVE\" | \"EDUCATION\" | \"ENERGY\" | \"ENTERTAINMENT\" | \"ENVIRONMENTAL\" | \"FINANCE\" | \"FOOD\" | \"HEALTHCARE\" | \"INTERNET_OF_THINGS\" | \"LOGISTICS\" | \"MACHINE_LEARNING\" | \"MANUFACTURING\" | \"MEDICINE\" | \"OTHER\" | \"RECYCLING\" | \"RETAIL\" | \"ROBOTICS\" | \"SECURITY\" | \"SOFTWARE_DEVELOPMENT\" | \"SPORTS\" | \"SURVEILLANCE\" | \"TRANSPORTATION\" | undefined)",
                    value: input.sector
                })) && (undefined === input.usage || "LABELING" === input.usage || "SELECTING" === input.usage || "VISUALIZATION" === input.usage || "MODEL_TRAINING" === input.usage || "DATASET_MANAGING" === input.usage || "CHECKING_THE_TOOL" === input.usage || "OTHER" === input.usage || $guard(_exceptionable, {
                    path: _path + ".usage",
                    expected: "(\"CHECKING_THE_TOOL\" | \"DATASET_MANAGING\" | \"LABELING\" | \"MODEL_TRAINING\" | \"OTHER\" | \"SELECTING\" | \"VISUALIZATION\" | undefined)",
                    value: input.usage
                })) && (null === input.usageCustomReason || undefined === input.usageCustomReason || "string" === typeof input.usageCustomReason || $guard(_exceptionable, {
                    path: _path + ".usageCustomReason",
                    expected: "(null | string | undefined)",
                    value: input.usageCustomReason
                }));
                return ("object" === typeof input && null !== input && false === Array.isArray(input) || $guard(true, {
                    path: _path + "",
                    expected: "QuestionnaireData",
                    value: input
                })) && $ao0(input, _path + "", true) || $guard(true, {
                    path: _path + "",
                    expected: "QuestionnaireData",
                    value: input
                });
            })(input, "$input", true);
        return input;
    }; const stringify = (input: UpdateQuestionnaireRequestBody): string => {
        const $string = (typia.createAssertStringify as any).string;
        const $throws = (typia.createAssertStringify as any).throws;
        const $tail = (typia.createAssertStringify as any).tail;
        const $so0 = (input: any): any => `{${$tail(`${undefined === input.company ? "" : `"company":${undefined !== input.company ? $string(input.company) : undefined},`}${undefined === input.sector ? "" : `"sector":${undefined !== input.sector ? (() => {
            if ("string" === typeof input.sector)
                return $string(input.sector);
            if ("string" === typeof input.sector)
                return "\"" + input.sector + "\"";
            $throws({
                expected: "(\"ADVERTISING\" | \"AGRICULTURE\" | \"AUTOMOTIVE\" | \"EDUCATION\" | \"ENERGY\" | \"ENTERTAINMENT\" | \"ENVIRONMENTAL\" | \"FINANCE\" | \"FOOD\" | \"HEALTHCARE\" | \"INTERNET_OF_THINGS\" | \"LOGISTICS\" | \"MACHINE_LEARNING\" | \"MANUFACTURING\" | \"MEDICINE\" | \"OTHER\" | \"RECYCLING\" | \"RETAIL\" | \"ROBOTICS\" | \"SECURITY\" | \"SOFTWARE_DEVELOPMENT\" | \"SPORTS\" | \"SURVEILLANCE\" | \"TRANSPORTATION\" | undefined)",
                value: input.sector
            });
        })() : undefined},`}${undefined === input.usage ? "" : `"usage":${undefined !== input.usage ? (() => {
            if ("string" === typeof input.usage)
                return $string(input.usage);
            if ("string" === typeof input.usage)
                return "\"" + input.usage + "\"";
            $throws({
                expected: "(\"CHECKING_THE_TOOL\" | \"DATASET_MANAGING\" | \"LABELING\" | \"MODEL_TRAINING\" | \"OTHER\" | \"SELECTING\" | \"VISUALIZATION\" | undefined)",
                value: input.usage
            });
        })() : undefined},`}${undefined === input.usageCustomReason ? "" : `"usageCustomReason":${undefined !== input.usageCustomReason ? null !== input.usageCustomReason ? $string(input.usageCustomReason) : "null" : undefined}`}`)}}`;
        return $so0(input);
    }; return stringify(assert(input)); })(input);
};
/*
HANDLERS:



/v1/profile/consent

export const getConsentFN: RequestHandler<
    GetConsentRequestParams,
    Consent | ApiErrorResponse,
    GetConsentRequestBody,
    GetConsentRequestQuery
> = async (req, res) => {
    if (!ensureUserOnReq(req)) {
        return
    }

    const {
        
    } = await validateAndSanitizeStrict(req.body, GetConsentRequestBodySanitizer, GetConsentRequestBodyValidator);
    const {
    } =  await validateAndSanitize(req.params, GetConsentRequestParamsSanitizer, GetConsentRequestParamsValidator);
    const {
    } = await validateAndSanitize(req.query, GetConsentRequestQuerySanitizer, GetConsentRequestQueryValidator);



    
    const schema = ....

    const { _id: id, ...rest } = schema;
    return fastJSONResponse(
        res,
        ConsentStringify,
        validateAndRespond({ id, ...rest }, ConsentFromJSON, ConsentValidator),
    );
}



/v1/profile/me/secrets/delegatedAccessExternalIds

export const getDelegatedAccessExternalIdsOfLoggedInUserFN: RequestHandler<
    GetDelegatedAccessExternalIdsOfLoggedInUserRequestParams,
    DelegatedAccessExternalIds | ApiErrorResponse,
    GetDelegatedAccessExternalIdsOfLoggedInUserRequestBody,
    GetDelegatedAccessExternalIdsOfLoggedInUserRequestQuery
> = async (req, res) => {
    if (!ensureUserOnReq(req)) {
        return
    }

    const {
        
    } = await validateAndSanitizeStrict(req.body, GetDelegatedAccessExternalIdsOfLoggedInUserRequestBodySanitizer, GetDelegatedAccessExternalIdsOfLoggedInUserRequestBodyValidator);
    const {
    } =  await validateAndSanitize(req.params, GetDelegatedAccessExternalIdsOfLoggedInUserRequestParamsSanitizer, GetDelegatedAccessExternalIdsOfLoggedInUserRequestParamsValidator);
    const {
    } = await validateAndSanitize(req.query, GetDelegatedAccessExternalIdsOfLoggedInUserRequestQuerySanitizer, GetDelegatedAccessExternalIdsOfLoggedInUserRequestQueryValidator);



    
    const schema = ....

    const { _id: id, ...rest } = schema;
    return fastJSONResponse(
        res,
        DelegatedAccessExternalIdsStringify,
        validateAndRespond({ id, ...rest }, DelegatedAccessExternalIdsFromJSON, DelegatedAccessExternalIdsValidator),
    );
}



/v1/profile/me

export const getProfileOfLoggedInUserFN: RequestHandler<
    GetProfileOfLoggedInUserRequestParams,
    ProfileMeData | ApiErrorResponse,
    GetProfileOfLoggedInUserRequestBody,
    GetProfileOfLoggedInUserRequestQuery
> = async (req, res) => {
    if (!ensureUserOnReq(req)) {
        return
    }

    const {
        
    } = await validateAndSanitizeStrict(req.body, GetProfileOfLoggedInUserRequestBodySanitizer, GetProfileOfLoggedInUserRequestBodyValidator);
    const {
    } =  await validateAndSanitize(req.params, GetProfileOfLoggedInUserRequestParamsSanitizer, GetProfileOfLoggedInUserRequestParamsValidator);
    const {
    } = await validateAndSanitize(req.query, GetProfileOfLoggedInUserRequestQuerySanitizer, GetProfileOfLoggedInUserRequestQueryValidator);



    
    const schema = ....

    const { _id: id, ...rest } = schema;
    return fastJSONResponse(
        res,
        ProfileMeDataStringify,
        validateAndRespond({ id, ...rest }, ProfileMeDataFromJSON, ProfileMeDataValidator),
    );
}



/v1/profile/{userId}

export const getProfileOfUserByIdFN: RequestHandler<
    GetProfileOfUserByIdRequestParams,
    ProfileBasicData | ApiErrorResponse,
    GetProfileOfUserByIdRequestBody,
    GetProfileOfUserByIdRequestQuery
> = async (req, res) => {
    if (!ensureUserOnReq(req)) {
        return
    }

    const {
        
    } = await validateAndSanitizeStrict(req.body, GetProfileOfUserByIdRequestBodySanitizer, GetProfileOfUserByIdRequestBodyValidator);
    const {
        userId,
    } =  await validateAndSanitize(req.params, GetProfileOfUserByIdRequestParamsSanitizer, GetProfileOfUserByIdRequestParamsValidator);
    const {
        
    } = await validateAndSanitize(req.query, GetProfileOfUserByIdRequestQuerySanitizer, GetProfileOfUserByIdRequestQueryValidator);



    
    const schema = ....

    const { _id: id, ...rest } = schema;
    return fastJSONResponse(
        res,
        ProfileBasicDataStringify,
        validateAndRespond({ id, ...rest }, ProfileBasicDataFromJSON, ProfileBasicDataValidator),
    );
}



/v1/profile/consent

export const updateConsentFN: RequestHandler<
    UpdateConsentRequestParams,
     | ApiErrorResponse,
    UpdateConsentRequestBody,
    UpdateConsentRequestQuery
> = async (req, res) => {
    if (!ensureUserOnReq(req)) {
        return
    }

    const {
        
    } = await validateAndSanitizeStrict(req.body, UpdateConsentRequestBodySanitizer, UpdateConsentRequestBodyValidator);
    const {
        
    } =  await validateAndSanitize(req.params, UpdateConsentRequestParamsSanitizer, UpdateConsentRequestParamsValidator);
    const {
        
    } = await validateAndSanitize(req.query, UpdateConsentRequestQuerySanitizer, UpdateConsentRequestQueryValidator);



    
    const schema = ....

    const { _id: id, ...rest } = schema;
    return fastJSONResponse(
        res,
        Stringify,
        validateAndRespond({ id, ...rest }, FromJSON, Validator),
    );
}



/v1/profile/questionnaire

export const updateQuestionnaireFN: RequestHandler<
    UpdateQuestionnaireRequestParams,
     | ApiErrorResponse,
    UpdateQuestionnaireRequestBody,
    UpdateQuestionnaireRequestQuery
> = async (req, res) => {
    if (!ensureUserOnReq(req)) {
        return
    }

    const {
        
    } = await validateAndSanitizeStrict(req.body, UpdateQuestionnaireRequestBodySanitizer, UpdateQuestionnaireRequestBodyValidator);
    const {
        
    } =  await validateAndSanitize(req.params, UpdateQuestionnaireRequestParamsSanitizer, UpdateQuestionnaireRequestParamsValidator);
    const {
        
    } = await validateAndSanitize(req.query, UpdateQuestionnaireRequestQuerySanitizer, UpdateQuestionnaireRequestQueryValidator);



    
    const schema = ....

    const { _id: id, ...rest } = schema;
    return fastJSONResponse(
        res,
        Stringify,
        validateAndRespond({ id, ...rest }, FromJSON, Validator),
    );
}


*/
/* LIGHTLY CUSTOM END -> api */
/**
 * ProfilesApi - interface
 *
 * @export
 * @interface ProfilesApiInterface
 */
export interface ProfilesApiInterface {
    /**
     * Get the information about the user accepted the newsletter
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProfilesApiInterface
     */
    getConsentRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Consent>>;
    /**
     * Get the information about the user accepted the newsletter
     */
    getConsent(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Consent>;
    /**
     * Get the externalId for delegated access of the currently logged in user
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProfilesApiInterface
     */
    getDelegatedAccessExternalIdsOfLoggedInUserRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<DelegatedAccessExternalIds>>;
    /**
     * Get the externalId for delegated access of the currently logged in user
     */
    getDelegatedAccessExternalIdsOfLoggedInUser(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<DelegatedAccessExternalIds>;
    /**
     * Get the profile of the currently logged in user
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProfilesApiInterface
     */
    getProfileOfLoggedInUserRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ProfileMeData>>;
    /**
     * Get the profile of the currently logged in user
     */
    getProfileOfLoggedInUser(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ProfileMeData>;
    /**
     * Get the basic profile of user with the id
     * @param {string} userId id of the user
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProfilesApiInterface
     */
    getProfileOfUserByIdRaw(requestParameters: GetProfileOfUserByIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ProfileBasicData>>;
    /**
     * Get the basic profile of user with the id
     */
    getProfileOfUserById(requestParameters: GetProfileOfUserByIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ProfileBasicData>;
    /**
     * Update the information about the consent
     * @param {Consent} consent
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProfilesApiInterface
     */
    updateConsentRaw(requestParameters: UpdateConsentRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>>;
    /**
     * Update the information about the consent
     */
    updateConsent(requestParameters: UpdateConsentRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void>;
    /**
     * Update the questionnaire of the currently logged in user.
     * @param {QuestionnaireData} questionnaireData
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProfilesApiInterface
     */
    updateQuestionnaireRaw(requestParameters: UpdateQuestionnaireRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>>;
    /**
     * Update the questionnaire of the currently logged in user.
     */
    updateQuestionnaire(requestParameters: UpdateQuestionnaireRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void>;
}
/**
 *
 */
export class ProfilesApi extends runtime.BaseAPI implements ProfilesApiInterface {
    /**
     * Get the information about the user accepted the newsletter
     */
    async getConsentRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Consent>> {
        const queryParameters: any = {};
        const headerParameters: runtime.HTTPHeaders = {};
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("auth0Bearer", []);
            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        if (this.configuration && this.configuration.apiKey) {
            queryParameters["token"] = this.configuration.apiKey("token"); // ApiKeyAuth authentication
        }
        const response = await this.request({
            path: `/v1/profile/consent`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => ConsentFromJSON(jsonValue));
    }
    /**
     * Get the information about the user accepted the newsletter
     */
    async getConsent(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Consent> {
        const response = await this.getConsentRaw(initOverrides);
        return await response.value();
    }
    /**
     * Get the externalId for delegated access of the currently logged in user
     */
    async getDelegatedAccessExternalIdsOfLoggedInUserRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<DelegatedAccessExternalIds>> {
        const queryParameters: any = {};
        const headerParameters: runtime.HTTPHeaders = {};
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("auth0Bearer", []);
            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        if (this.configuration && this.configuration.apiKey) {
            queryParameters["token"] = this.configuration.apiKey("token"); // ApiKeyAuth authentication
        }
        const response = await this.request({
            path: `/v1/profile/me/secrets/delegatedAccessExternalIds`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => DelegatedAccessExternalIdsFromJSON(jsonValue));
    }
    /**
     * Get the externalId for delegated access of the currently logged in user
     */
    async getDelegatedAccessExternalIdsOfLoggedInUser(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<DelegatedAccessExternalIds> {
        const response = await this.getDelegatedAccessExternalIdsOfLoggedInUserRaw(initOverrides);
        return await response.value();
    }
    /**
     * Get the profile of the currently logged in user
     */
    async getProfileOfLoggedInUserRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ProfileMeData>> {
        const queryParameters: any = {};
        const headerParameters: runtime.HTTPHeaders = {};
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("auth0Bearer", []);
            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        if (this.configuration && this.configuration.apiKey) {
            queryParameters["token"] = this.configuration.apiKey("token"); // ApiKeyAuth authentication
        }
        const response = await this.request({
            path: `/v1/profile/me`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => ProfileMeDataFromJSON(jsonValue));
    }
    /**
     * Get the profile of the currently logged in user
     */
    async getProfileOfLoggedInUser(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ProfileMeData> {
        const response = await this.getProfileOfLoggedInUserRaw(initOverrides);
        return await response.value();
    }
    /**
     * Get the basic profile of user with the id
     */
    async getProfileOfUserByIdRaw(requestParameters: GetProfileOfUserByIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ProfileBasicData>> {
        if (requestParameters.userId === null || requestParameters.userId === undefined) {
            throw new runtime.RequiredError('userId', 'Required parameter requestParameters.userId was null or undefined when calling getProfileOfUserById.');
        }
        const queryParameters: any = {};
        const headerParameters: runtime.HTTPHeaders = {};
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("auth0Bearer", []);
            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        if (this.configuration && this.configuration.apiKey) {
            queryParameters["token"] = this.configuration.apiKey("token"); // ApiKeyAuth authentication
        }
        const response = await this.request({
            path: `/v1/profile/{userId}`.replace(`{${"userId"}}`, encodeURIComponent(String(requestParameters.userId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => ProfileBasicDataFromJSON(jsonValue));
    }
    /**
     * Get the basic profile of user with the id
     */
    async getProfileOfUserById(requestParameters: GetProfileOfUserByIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ProfileBasicData> {
        const response = await this.getProfileOfUserByIdRaw(requestParameters, initOverrides);
        return await response.value();
    }
    /**
     * Update the information about the consent
     */
    async updateConsentRaw(requestParameters: UpdateConsentRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.consent === null || requestParameters.consent === undefined) {
            throw new runtime.RequiredError('consent', 'Required parameter requestParameters.consent was null or undefined when calling updateConsent.');
        }
        const queryParameters: any = {};
        const headerParameters: runtime.HTTPHeaders = {};
        headerParameters['Content-Type'] = 'application/json';
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("auth0Bearer", []);
            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        if (this.configuration && this.configuration.apiKey) {
            queryParameters["token"] = this.configuration.apiKey("token"); // ApiKeyAuth authentication
        }
        const response = await this.request({
            path: `/v1/profile/consent`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ConsentToJSON(requestParameters.consent),
        }, initOverrides);
        return new runtime.VoidApiResponse(response);
    }
    /**
     * Update the information about the consent
     */
    async updateConsent(requestParameters: UpdateConsentRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.updateConsentRaw(requestParameters, initOverrides);
    }
    /**
     * Update the questionnaire of the currently logged in user.
     */
    async updateQuestionnaireRaw(requestParameters: UpdateQuestionnaireRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.questionnaireData === null || requestParameters.questionnaireData === undefined) {
            throw new runtime.RequiredError('questionnaireData', 'Required parameter requestParameters.questionnaireData was null or undefined when calling updateQuestionnaire.');
        }
        const queryParameters: any = {};
        const headerParameters: runtime.HTTPHeaders = {};
        headerParameters['Content-Type'] = 'application/json';
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("auth0Bearer", []);
            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        if (this.configuration && this.configuration.apiKey) {
            queryParameters["token"] = this.configuration.apiKey("token"); // ApiKeyAuth authentication
        }
        const response = await this.request({
            path: `/v1/profile/questionnaire`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: QuestionnaireDataToJSON(requestParameters.questionnaireData),
        }, initOverrides);
        return new runtime.VoidApiResponse(response);
    }
    /**
     * Update the questionnaire of the currently logged in user.
     */
    async updateQuestionnaire(requestParameters: UpdateQuestionnaireRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.updateQuestionnaireRaw(requestParameters, initOverrides);
    }
}
