/* tslint:disable */
/* eslint-disable */
/**
 * Lightly API
 * Lightly.ai enables you to do self-supervised learning in an easy and intuitive way. The lightly.ai OpenAPI spec defines how one can interact with our REST API to unleash the full potential of lightly.ai
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: support@lightly.ai
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
// SRC: https://github.com/OpenAPITools/openapi-generator/blob/master/modules/openapi-generator/src/main/resources/typescript-fetch/modelGeneric.mustache
// synched 02.02.2023
import * as yup from 'yup';
import typia from 'typia';
import * as fastJSON from 'fast-json-stringify';
import { Options, ArraySchema, ObjectSchema } from 'fast-json-stringify';
import { exists, mapValues } from '../runtime';
/*
causes circular dependendies
*/
// SRC: https://github.com/OpenAPITools/openapi-generator/blob/master/modules/openapi-generator/src/main/resources/typescript-fetch/modelGenericInterfaces.mustache
// synced: 17.07.2023
/**
 *
 * @export
 * @interface DockerWorkerConfigV3DockerTraining
 *
 */
// create custom validators for the regexes
typia.customValidators.insert("pattern_taskName")("string")(() => {
    const regex = new RegExp(/^[a-zA-Z0-9_+=,.@:\/-]*$/);
    return (value: string) => !!regex.exec(value);
});
export interface DockerWorkerConfigV3DockerTraining {
    /**
     * Since we sometimes stitch together SelectionInputTask+ActiveLearningScoreType, they need to follow the same specs of ActiveLearningScoreType. However, this can be an empty string due to internal logic (no minLength). Also v2config.filespecs.ts has this pattern for predictionTaskJSONSchema as well.
     * #type string
     * #memberof DockerWorkerConfigV3DockerTraining
        
        
    * @pattern_taskName
         */
    taskName?: string;
}
/**
 * Check if a given object implements the DockerWorkerConfigV3DockerTraining interface.
 */
export function instanceOfDockerWorkerConfigV3DockerTraining(value: object): boolean {
    let isInstance = true;
    return isInstance;
}
/* LIGHTLY CUSTOM START -> modelGeneric */
export const DockerWorkerConfigV3DockerTrainingTypiaAssertEquals = (input: any) => {
    return ((input: any): DockerWorkerConfigV3DockerTraining => {
        const __is = (input: any, _exceptionable: boolean = true): input is DockerWorkerConfigV3DockerTraining => {
            const $is_custom = (typia.createAssertEquals as any).is_custom;
            const $io0 = (input: any, _exceptionable: boolean = true): boolean => (undefined === input.taskName || "string" === typeof input.taskName && $is_custom("pattern_taskName", "string", "", input.taskName)) && (0 === Object.keys(input).length || Object.keys(input).every((key: any) => {
                if (["taskName"].some((prop: any) => key === prop))
                    return true;
                const value = input[key];
                if (undefined === value)
                    return true;
                return false;
            }));
            return "object" === typeof input && null !== input && false === Array.isArray(input) && $io0(input, true);
        };
        if (false === __is(input))
            ((input: any, _path: string, _exceptionable: boolean = true): input is DockerWorkerConfigV3DockerTraining => {
                const $guard = (typia.createAssertEquals as any).guard;
                const $is_custom = (typia.createAssertEquals as any).is_custom;
                const $join = (typia.createAssertEquals as any).join;
                const $ao0 = (input: any, _path: string, _exceptionable: boolean = true): boolean => (undefined === input.taskName || "string" === typeof input.taskName && ($is_custom("pattern_taskName", "string", "", input.taskName) || $guard(_exceptionable, {
                    path: _path + ".taskName",
                    expected: "string (@pattern_taskName)",
                    value: input.taskName
                })) || $guard(_exceptionable, {
                    path: _path + ".taskName",
                    expected: "(string | undefined)",
                    value: input.taskName
                })) && (0 === Object.keys(input).length || (false === _exceptionable || Object.keys(input).every((key: any) => {
                    if (["taskName"].some((prop: any) => key === prop))
                        return true;
                    const value = input[key];
                    if (undefined === value)
                        return true;
                    return $guard(_exceptionable, {
                        path: _path + $join(key),
                        expected: "undefined",
                        value: value
                    });
                })));
                return ("object" === typeof input && null !== input && false === Array.isArray(input) || $guard(true, {
                    path: _path + "",
                    expected: "DockerWorkerConfigV3DockerTraining",
                    value: input
                })) && $ao0(input, _path + "", true) || $guard(true, {
                    path: _path + "",
                    expected: "DockerWorkerConfigV3DockerTraining",
                    value: input
                });
            })(input, "$input", true);
        return input;
    })(input);
};
export const DockerWorkerConfigV3DockerTrainingTypiaAssertStringify = (input: DockerWorkerConfigV3DockerTraining): string => {
    return ((input: any): string => { const assert = (input: any): DockerWorkerConfigV3DockerTraining => {
        const __is = (input: any): input is DockerWorkerConfigV3DockerTraining => {
            const $is_custom = (typia.createAssertStringify as any).is_custom;
            const $io0 = (input: any): boolean => undefined === input.taskName || "string" === typeof input.taskName && $is_custom("pattern_taskName", "string", "", input.taskName);
            return "object" === typeof input && null !== input && false === Array.isArray(input) && $io0(input);
        };
        if (false === __is(input))
            ((input: any, _path: string, _exceptionable: boolean = true): input is DockerWorkerConfigV3DockerTraining => {
                const $guard = (typia.createAssertStringify as any).guard;
                const $is_custom = (typia.createAssertStringify as any).is_custom;
                const $ao0 = (input: any, _path: string, _exceptionable: boolean = true): boolean => undefined === input.taskName || "string" === typeof input.taskName && ($is_custom("pattern_taskName", "string", "", input.taskName) || $guard(_exceptionable, {
                    path: _path + ".taskName",
                    expected: "string (@pattern_taskName)",
                    value: input.taskName
                })) || $guard(_exceptionable, {
                    path: _path + ".taskName",
                    expected: "(string | undefined)",
                    value: input.taskName
                });
                return ("object" === typeof input && null !== input && false === Array.isArray(input) || $guard(true, {
                    path: _path + "",
                    expected: "DockerWorkerConfigV3DockerTraining",
                    value: input
                })) && $ao0(input, _path + "", true) || $guard(true, {
                    path: _path + "",
                    expected: "DockerWorkerConfigV3DockerTraining",
                    value: input
                });
            })(input, "$input", true);
        return input;
    }; const stringify = (input: DockerWorkerConfigV3DockerTraining): string => {
        const $string = (typia.createAssertStringify as any).string;
        const $tail = (typia.createAssertStringify as any).tail;
        const $is_custom = (typia.createAssertStringify as any).is_custom;
        const $so0 = (input: any): any => `{${$tail(`${undefined === input.taskName ? "" : `"taskName":${undefined !== input.taskName ? $string(input.taskName) : undefined}`}`)}}`;
        return $so0(input);
    }; return stringify(assert(input)); })(input);
};
export const DockerWorkerConfigV3DockerTrainingValidatorShape = {
    'taskName': yup.string().matches(/^[a-zA-Z0-9_+=,.@:\/-]*$/),
};
export const DockerWorkerConfigV3DockerTrainingValidator = yup.object().shape(DockerWorkerConfigV3DockerTrainingValidatorShape);
export const DockerWorkerConfigV3DockerTrainingStringifyShapeProperties: ObjectSchema['properties'] = {
    'taskName': {
        'type': 'string',
        'pattern': "/^[a-zA-Z0-9_+&#x3D;,.@:\/-]*$/".slice(1, -1),
    } as unknown as fastJSON.StringSchema,
};
export const DockerWorkerConfigV3DockerTrainingStringifyShape: ObjectSchema = {
    title: 'DockerWorkerConfigV3DockerTraining Stringifier',
    type: 'object',
    properties: {
        ...DockerWorkerConfigV3DockerTrainingStringifyShapeProperties
    },
    required: [],
};
export const DockerWorkerConfigV3DockerTrainingStringifyCompiled = (typeof process === 'object' && String(process) === '[object process]') ?
    fastJSON.build(DockerWorkerConfigV3DockerTrainingStringifyShape, {
        ajv: {
            // since we are already doing this with yup, lets skip
            validateSchema: false,
            ownProperties: true,
            validateFormats: false,
        },
        largeArrayMechanism: 'json-stringify',
        largeArraySize: 1000,
    } as Options)
    :
        () => { throw new Error('fast-json-stringify only works in nodejs'); };
export const DockerWorkerConfigV3DockerTrainingStringify: (data: DockerWorkerConfigV3DockerTraining) => string = DockerWorkerConfigV3DockerTrainingStringifyCompiled;
/* LIGHTLY CUSTOM END -> Generic */
export function DockerWorkerConfigV3DockerTrainingFromJSON(json: any): DockerWorkerConfigV3DockerTraining {
    return DockerWorkerConfigV3DockerTrainingFromJSONTyped(json, false);
}
export function DockerWorkerConfigV3DockerTrainingFromJSONTyped(json: any, ignoreDiscriminator: boolean): DockerWorkerConfigV3DockerTraining {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    /* causes circular depdendencies
*/
    return {
        'taskName': !exists(json, 'taskName') ? undefined : json['taskName'],
    };
}
export function DockerWorkerConfigV3DockerTrainingToJSON(value?: DockerWorkerConfigV3DockerTraining | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'taskName': value.taskName,
    };
}
