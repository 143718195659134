/* tslint:disable */
/* eslint-disable */
/**
 * Lightly API
 * Lightly.ai enables you to do self-supervised learning in an easy and intuitive way. The lightly.ai OpenAPI spec defines how one can interact with our REST API to unleash the full potential of lightly.ai
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: support@lightly.ai
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
// SRC: https://github.com/OpenAPITools/openapi-generator/blob/master/modules/openapi-generator/src/main/resources/typescript-fetch/modelGeneric.mustache
// synched 02.02.2023
import * as yup from 'yup';
import typia from 'typia';
import * as fastJSON from 'fast-json-stringify';
import { Options, ArraySchema, ObjectSchema } from 'fast-json-stringify';
import { exists, mapValues } from '../runtime';
import type { ConfigurationValueDataType } from './ConfigurationValueDataType';
import { ConfigurationValueDataTypeFromJSON, ConfigurationValueDataTypeFromJSONTyped, ConfigurationValueDataTypeToJSON, } from './ConfigurationValueDataType';
/*
causes circular dependendies
*/
// SRC: https://github.com/OpenAPITools/openapi-generator/blob/master/modules/openapi-generator/src/main/resources/typescript-fetch/modelGenericInterfaces.mustache
// synced: 17.07.2023
/**
 *
 * @export
 * @interface ConfigurationEntry
 *
 */
// create custom validators for the regexes
export interface ConfigurationEntry {
    /**
     * the name of this entry which is displayed in the UI
     * #type string
     * #memberof ConfigurationEntry
        
        
    * @minLength 1
         */
    name: string;
    /**
     * the path is the dotnotation which is used to easily access the customMetadata JSON structure of a sample e.g myArray[0].myObject.field
     * #type string
     * #memberof ConfigurationEntry
        
        
    * @minLength 1
         */
    path: string;
    /**
     * the default value used if its not possible to extract the value using the path or if the value extracted is nullish
     * #type any
     * #memberof ConfigurationEntry
        
        
         */
    defaultValue: any | null;
    /**
     *
     * #type ConfigurationValueDataType
     * #memberof ConfigurationEntry
        
        
         */
    valueDataType: ConfigurationValueDataType;
}
/**
 * Check if a given object implements the ConfigurationEntry interface.
 */
export function instanceOfConfigurationEntry(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "path" in value;
    isInstance = isInstance && "defaultValue" in value;
    isInstance = isInstance && "valueDataType" in value;
    return isInstance;
}
/* LIGHTLY CUSTOM START -> modelGeneric */
import { ConfigurationValueDataTypeValidator, ConfigurationValueDataTypeStringifyShape, ConfigurationValueDataTypeStringifyShapeProperties, } from './ConfigurationValueDataType';
export const ConfigurationEntryTypiaAssertEquals = (input: any) => {
    return ((input: any): ConfigurationEntry => {
        const __is = (input: any, _exceptionable: boolean = true): input is ConfigurationEntry => {
            const $io0 = (input: any, _exceptionable: boolean = true): boolean => "string" === typeof input.name && 1 <= input.name.length && ("string" === typeof input.path && 1 <= input.path.length) && true && ("NUMERIC_INT" === input.valueDataType || "NUMERIC_FLOAT" === input.valueDataType || "CATEGORICAL_STRING" === input.valueDataType || "CATEGORICAL_INT" === input.valueDataType || "CATEGORICAL_BOOLEAN" === input.valueDataType || "CATEGORICAL_DATETIME" === input.valueDataType || "CATEGORICAL_TIMESTAMP" === input.valueDataType || "OTHER_STRING" === input.valueDataType) && (4 === Object.keys(input).length || Object.keys(input).every((key: any) => {
                if (["name", "path", "defaultValue", "valueDataType"].some((prop: any) => key === prop))
                    return true;
                const value = input[key];
                if (undefined === value)
                    return true;
                return false;
            }));
            return "object" === typeof input && null !== input && $io0(input, true);
        };
        if (false === __is(input))
            ((input: any, _path: string, _exceptionable: boolean = true): input is ConfigurationEntry => {
                const $guard = (typia.createAssertEquals as any).guard;
                const $join = (typia.createAssertEquals as any).join;
                const $ao0 = (input: any, _path: string, _exceptionable: boolean = true): boolean => ("string" === typeof input.name && (1 <= input.name.length || $guard(_exceptionable, {
                    path: _path + ".name",
                    expected: "string (@minLength 1)",
                    value: input.name
                })) || $guard(_exceptionable, {
                    path: _path + ".name",
                    expected: "string",
                    value: input.name
                })) && ("string" === typeof input.path && (1 <= input.path.length || $guard(_exceptionable, {
                    path: _path + ".path",
                    expected: "string (@minLength 1)",
                    value: input.path
                })) || $guard(_exceptionable, {
                    path: _path + ".path",
                    expected: "string",
                    value: input.path
                })) && true && ("NUMERIC_INT" === input.valueDataType || "NUMERIC_FLOAT" === input.valueDataType || "CATEGORICAL_STRING" === input.valueDataType || "CATEGORICAL_INT" === input.valueDataType || "CATEGORICAL_BOOLEAN" === input.valueDataType || "CATEGORICAL_DATETIME" === input.valueDataType || "CATEGORICAL_TIMESTAMP" === input.valueDataType || "OTHER_STRING" === input.valueDataType || $guard(_exceptionable, {
                    path: _path + ".valueDataType",
                    expected: "(\"CATEGORICAL_BOOLEAN\" | \"CATEGORICAL_DATETIME\" | \"CATEGORICAL_INT\" | \"CATEGORICAL_STRING\" | \"CATEGORICAL_TIMESTAMP\" | \"NUMERIC_FLOAT\" | \"NUMERIC_INT\" | \"OTHER_STRING\")",
                    value: input.valueDataType
                })) && (4 === Object.keys(input).length || (false === _exceptionable || Object.keys(input).every((key: any) => {
                    if (["name", "path", "defaultValue", "valueDataType"].some((prop: any) => key === prop))
                        return true;
                    const value = input[key];
                    if (undefined === value)
                        return true;
                    return $guard(_exceptionable, {
                        path: _path + $join(key),
                        expected: "undefined",
                        value: value
                    });
                })));
                return ("object" === typeof input && null !== input || $guard(true, {
                    path: _path + "",
                    expected: "ConfigurationEntry",
                    value: input
                })) && $ao0(input, _path + "", true) || $guard(true, {
                    path: _path + "",
                    expected: "ConfigurationEntry",
                    value: input
                });
            })(input, "$input", true);
        return input;
    })(input);
};
export const ConfigurationEntryTypiaAssertStringify = (input: ConfigurationEntry): string => {
    return ((input: any): string => { const assert = (input: any): ConfigurationEntry => {
        const __is = (input: any): input is ConfigurationEntry => {
            const $io0 = (input: any): boolean => "string" === typeof input.name && 1 <= input.name.length && ("string" === typeof input.path && 1 <= input.path.length) && true && ("NUMERIC_INT" === input.valueDataType || "NUMERIC_FLOAT" === input.valueDataType || "CATEGORICAL_STRING" === input.valueDataType || "CATEGORICAL_INT" === input.valueDataType || "CATEGORICAL_BOOLEAN" === input.valueDataType || "CATEGORICAL_DATETIME" === input.valueDataType || "CATEGORICAL_TIMESTAMP" === input.valueDataType || "OTHER_STRING" === input.valueDataType);
            return "object" === typeof input && null !== input && $io0(input);
        };
        if (false === __is(input))
            ((input: any, _path: string, _exceptionable: boolean = true): input is ConfigurationEntry => {
                const $guard = (typia.createAssertStringify as any).guard;
                const $ao0 = (input: any, _path: string, _exceptionable: boolean = true): boolean => ("string" === typeof input.name && (1 <= input.name.length || $guard(_exceptionable, {
                    path: _path + ".name",
                    expected: "string (@minLength 1)",
                    value: input.name
                })) || $guard(_exceptionable, {
                    path: _path + ".name",
                    expected: "string",
                    value: input.name
                })) && ("string" === typeof input.path && (1 <= input.path.length || $guard(_exceptionable, {
                    path: _path + ".path",
                    expected: "string (@minLength 1)",
                    value: input.path
                })) || $guard(_exceptionable, {
                    path: _path + ".path",
                    expected: "string",
                    value: input.path
                })) && true && ("NUMERIC_INT" === input.valueDataType || "NUMERIC_FLOAT" === input.valueDataType || "CATEGORICAL_STRING" === input.valueDataType || "CATEGORICAL_INT" === input.valueDataType || "CATEGORICAL_BOOLEAN" === input.valueDataType || "CATEGORICAL_DATETIME" === input.valueDataType || "CATEGORICAL_TIMESTAMP" === input.valueDataType || "OTHER_STRING" === input.valueDataType || $guard(_exceptionable, {
                    path: _path + ".valueDataType",
                    expected: "(\"CATEGORICAL_BOOLEAN\" | \"CATEGORICAL_DATETIME\" | \"CATEGORICAL_INT\" | \"CATEGORICAL_STRING\" | \"CATEGORICAL_TIMESTAMP\" | \"NUMERIC_FLOAT\" | \"NUMERIC_INT\" | \"OTHER_STRING\")",
                    value: input.valueDataType
                }));
                return ("object" === typeof input && null !== input || $guard(true, {
                    path: _path + "",
                    expected: "ConfigurationEntry",
                    value: input
                })) && $ao0(input, _path + "", true) || $guard(true, {
                    path: _path + "",
                    expected: "ConfigurationEntry",
                    value: input
                });
            })(input, "$input", true);
        return input;
    }; const stringify = (input: ConfigurationEntry): string => {
        const $string = (typia.createAssertStringify as any).string;
        const $throws = (typia.createAssertStringify as any).throws;
        const $so0 = (input: any): any => `{${undefined === input.defaultValue || "function" === typeof input.defaultValue ? "" : `"defaultValue":${undefined !== input.defaultValue ? JSON.stringify(input.defaultValue) : undefined},`}"name":${$string(input.name)},"path":${$string(input.path)},"valueDataType":${(() => {
            if ("string" === typeof input.valueDataType)
                return $string(input.valueDataType);
            if ("string" === typeof input.valueDataType)
                return "\"" + input.valueDataType + "\"";
            $throws({
                expected: "(\"CATEGORICAL_BOOLEAN\" | \"CATEGORICAL_DATETIME\" | \"CATEGORICAL_INT\" | \"CATEGORICAL_STRING\" | \"CATEGORICAL_TIMESTAMP\" | \"NUMERIC_FLOAT\" | \"NUMERIC_INT\" | \"OTHER_STRING\")",
                value: input.valueDataType
            });
        })()}}`;
        return $so0(input);
    }; return stringify(assert(input)); })(input);
};
export const ConfigurationEntryValidatorShape = {
    'name': yup.string().min(1).defined(),
    'path': yup.string().min(1).defined(),
    'defaultValue': yup.mixed().nullable().defined(),
    'valueDataType': ConfigurationValueDataTypeValidator.defined(),
};
export const ConfigurationEntryValidator = yup.object().shape(ConfigurationEntryValidatorShape);
export const ConfigurationEntryStringifyShapeProperties: ObjectSchema['properties'] = {
    'name': {
        'type': 'string',
        'minLength': 1,
    } as unknown as fastJSON.StringSchema,
    'path': {
        'type': 'string',
        'minLength': 1,
    } as unknown as fastJSON.StringSchema,
    'defaultValue': {},
    'valueDataType': ConfigurationValueDataTypeStringifyShape,
};
export const ConfigurationEntryStringifyShape: ObjectSchema = {
    title: 'ConfigurationEntry Stringifier',
    type: 'object',
    properties: {
        ...ConfigurationEntryStringifyShapeProperties
    },
    required: [
        'name',
        'path',
        'defaultValue',
        'valueDataType',
    ],
};
export const ConfigurationEntryStringifyCompiled = (typeof process === 'object' && String(process) === '[object process]') ?
    fastJSON.build(ConfigurationEntryStringifyShape, {
        ajv: {
            // since we are already doing this with yup, lets skip
            validateSchema: false,
            ownProperties: true,
            validateFormats: false,
        },
        largeArrayMechanism: 'json-stringify',
        largeArraySize: 1000,
    } as Options)
    :
        () => { throw new Error('fast-json-stringify only works in nodejs'); };
export const ConfigurationEntryStringify: (data: ConfigurationEntry) => string = ConfigurationEntryStringifyCompiled;
/* LIGHTLY CUSTOM END -> Generic */
export function ConfigurationEntryFromJSON(json: any): ConfigurationEntry {
    return ConfigurationEntryFromJSONTyped(json, false);
}
export function ConfigurationEntryFromJSONTyped(json: any, ignoreDiscriminator: boolean): ConfigurationEntry {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    /* causes circular depdendencies
*/
    return {
        'name': json['name'],
        'path': json['path'],
        'defaultValue': json['defaultValue'],
        'valueDataType': ConfigurationValueDataTypeFromJSON(json['valueDataType']),
    };
}
export function ConfigurationEntryToJSON(value?: ConfigurationEntry | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'name': value.name,
        'path': value.path,
        'defaultValue': value.defaultValue,
        'valueDataType': ConfigurationValueDataTypeToJSON(value.valueDataType),
    };
}
