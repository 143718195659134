
import React, { FunctionComponent, Suspense, useEffect, useState } from 'react';

import '../i18n'; // THIS MUST BE UP HERE REGARDLESS OF WHAT ESLINT SAYS!

import { SnackbarProvider } from 'notistack';
import {
	Switch,
	Route,
	Redirect,
	Router,
} from 'react-router-dom';
import { makeStyles } from 'tss-react/mui';

import { Theme, alpha } from '@mui/material';

import { WhiteoutLoading } from '../common/WhiteoutLoading';
import { useAuth0 } from '../contexts/auth0-context';
import mixpanel from '../contexts/mixpanel';
import { useGetProfileOfLoggedInUser } from '../helpers/api';
import { getAccessToken, setAccessToken } from '../helpers/api.accessToken';
import { history } from '../helpers/router';
import { StarredProvider } from '../pages/dataset/starredDatasets';
import { defaultRoute, routes } from '../routes';
import { makeRouteURL } from '../routes.fn';
import { generateRoutes } from '../routing';
import { LightlyTheme } from '../theme/lightly';

import { Navbar } from './Navbar';

import '../css/App.css';




const snackStyles = makeStyles()((theme: Theme) => ({
	// eslint-disable-next-line tss-unused-classes/unused-classes
	variantSuccess: {
		backgroundColor: alpha(theme.palette.lightlyBrandGreen.dark, 0.95),
		borderColor: theme.palette.lightlyBrandGreen.dark,
	},

	// eslint-disable-next-line tss-unused-classes/unused-classes
	variantError: {
		backgroundColor: alpha(theme.palette.error.main, 0.95),
		borderColor: theme.palette.error.main,
	},
}));


export const App: FunctionComponent = () => {

	const { isLoading, user, getAccessTokenSilently } = useAuth0();
	const [gotToken, setGotToken] = useState(false)
	const { data: profileData } = useGetProfileOfLoggedInUser(gotToken ? undefined : null);
	useEffect(() => {
		if (process.env.NODE_ENV === 'test') {
			return
		}
		const get = async () => {
			let auth0token = '';
			// DOCKERAUTH returns the jwt on the id_token instead on the access_token as auth0 does
			if (String(process.env.REACT_APP_AUTH0_DOCKERAUTH) === 'true') {
				const auth0tokenDetail = await getAccessTokenSilently({ detailedResponse: true });
				auth0token = auth0tokenDetail.id_token || auth0tokenDetail.access_token;
			}
			else {
				auth0token = await getAccessTokenSilently();
			}
			setAccessToken(auth0token);
		};
		if (!isLoading && user) {
			mixpanel.identify(user.sub);

			get()
				.then(() => {
					setGotToken(true);
				})
				.catch(() => {
					setGotToken(false);
				});
		}
	}, [isLoading, user, getAccessTokenSilently])


	useEffect(() => {
		if (user && profileData) {
			// assemble data
			const data = {
				email: profileData.email || user.email,
				name: profileData.name || user.name,
				locale: profileData?.settings?.locale || user.locale,
				team: profileData.teams && profileData.teams[0] ? profileData.teams[0].name : 'no team',
				teams: profileData.teams ? profileData.teams.map((team) => team.name) : [],
				userType: profileData.userType || 'unknown',
			}

			// setup mixpanel
			mixpanel.people.set({
				email: data.email,
				name: data.name,
				locale: data.locale,
				userType: data.userType,
				team: data.team,
				teams: data.teams,
			});

			mixpanel.set_group('group: team', data.team);
			mixpanel.set_group('group: teams', data.teams);
			mixpanel.set_group('group: userType', data.userType);


			// if fullstory is around let fullstory know if the users already have signedup or not
			const fullStory = (window as unknown as Record<string, unknown>)._FS as { identify: (...args: unknown[]) => void } | undefined;
			if (fullStory && fullStory.identify) {
				fullStory.identify(user.sub, {
					displayName: data.name,
					email: data.email,
				});
			}

			// if GA is around let GA know
			const gatag = (window as unknown as Record<string, unknown>).gtag as ((...args: unknown[]) => void) | undefined;
			if (gatag) {
				gatag('config', 'G-XMVPPCC45R', {
					'user_id': user.sub,
				});
			}
		}
	}, [profileData, user]);


	useEffect(() => {
		mixpanel.track('init');
	}, []);

	const { classes: classesSnack } = snackStyles()




	return <SnackbarProvider
		dense
		classes={{
			variantSuccess: classesSnack.variantSuccess,
			variantError: classesSnack.variantError,
		}}
		maxSnack={3}
		anchorOrigin={{
			vertical: 'bottom',
			horizontal: 'center',
		}}
	>
		 <StarredProvider userId={user?.sub ?? ''}>
			<div>
				<svg width='50' height='50' style={{ position: 'absolute' }}>
					<linearGradient id='lightlyGradient' x1='0%' y1='0%' x2='0%' y2='100%'>
						<stop offset='0%' stopColor={LightlyTheme.palette.lightlyBrandGreen.main} />
						<stop offset='50%' stopColor={LightlyTheme.palette.lightlyBrandTurquoise.main} />
						<stop offset='100%' stopColor={LightlyTheme.palette.lightlyBrandBlue.main} />
					</linearGradient>
				</svg>
				{
					<Router history={history}>
						<Navbar gotToken={gotToken} />
						<Suspense fallback={<WhiteoutLoading show />}>
							<Switch>
								{
									generateRoutes(routes, getAccessToken())
								}
								<Route path='*' render={(props) => {
									console.warn('no route found...')
									mixpanel.track('route: unknown route', { path: props.location.pathname || 'unknown' })
									return <Redirect to={makeRouteURL(defaultRoute)}></Redirect>
								}}>
								</Route>
							</Switch>
						</Suspense>
					</Router>
				}
			</div>
		</StarredProvider>
	</SnackbarProvider>
};