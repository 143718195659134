/* tslint:disable */
/* eslint-disable */
/**
 * Lightly API
 * Lightly.ai enables you to do self-supervised learning in an easy and intuitive way. The lightly.ai OpenAPI spec defines how one can interact with our REST API to unleash the full potential of lightly.ai
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: support@lightly.ai
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
// SRC: https://github.com/OpenAPITools/openapi-generator/blob/master/modules/openapi-generator/src/main/resources/typescript-fetch/modelEnumInterfaces.mustache
// synched 02.02.2023
import * as yup from 'yup';
import * as fastJSON from 'fast-json-stringify';
import { StringSchema } from 'fast-json-stringify';
/**
 *
 * @export
 * @enum {string}
 */
export enum DockerRunArtifactStorageLocation {
    LIGHTLY = 'LIGHTLY',
    DATASOURCE = 'DATASOURCE'
}
/* LIGHTLY CUSTOM START -> modelEnumInterfaces */
export const DockerRunArtifactStorageLocationValidatorShape = [
    'LIGHTLY',
    'DATASOURCE'
];
export const DockerRunArtifactStorageLocationValidator = yup.mixed().oneOf(DockerRunArtifactStorageLocationValidatorShape);
export const DockerRunArtifactStorageLocationStringifyShapeProperties = {};
export const DockerRunArtifactStorageLocationStringifyShape: StringSchema = {
    title: 'DockerRunArtifactStorageLocation Stringifier',
    type: 'string'
};
export const DockerRunArtifactStorageLocationStringify = (typeof process === 'object' && String(process) === '[object process]') ?
    fastJSON.build(DockerRunArtifactStorageLocationStringifyShape)
    :
        () => { throw new Error('fast-json-stringify only works in nodejs'); };
/* LIGHTLY CUSTOM END */
export function DockerRunArtifactStorageLocationFromJSON(json: any): DockerRunArtifactStorageLocation {
    return DockerRunArtifactStorageLocationFromJSONTyped(json, false);
}
export function DockerRunArtifactStorageLocationFromJSONTyped(json: any, ignoreDiscriminator: boolean): DockerRunArtifactStorageLocation {
    return json as DockerRunArtifactStorageLocation;
}
export function DockerRunArtifactStorageLocationToJSON(value?: DockerRunArtifactStorageLocation | null): any {
    return value as any;
}
