/* tslint:disable */
/* eslint-disable */
/**
 * Lightly API
 * Lightly.ai enables you to do self-supervised learning in an easy and intuitive way. The lightly.ai OpenAPI spec defines how one can interact with our REST API to unleash the full potential of lightly.ai
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: support@lightly.ai
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
// SRC: https://github.com/OpenAPITools/openapi-generator/blob/master/modules/openapi-generator/src/main/resources/typescript-fetch/modelGeneric.mustache
// synched 02.02.2023
import * as yup from 'yup';
import typia from 'typia';
import * as fastJSON from 'fast-json-stringify';
import { Options, ArraySchema, ObjectSchema } from 'fast-json-stringify';
import { exists, mapValues } from '../runtime';
/*
causes circular dependendies
*/
// SRC: https://github.com/OpenAPITools/openapi-generator/blob/master/modules/openapi-generator/src/main/resources/typescript-fetch/modelGenericInterfaces.mustache
// synced: 17.07.2023
/**
 *
 * @export
 * @interface DockerWorkerConfigV2DockerDatasource
 *
 */
// create custom validators for the regexes
export interface DockerWorkerConfigV2DockerDatasource {
    /**
     *
     * #type boolean
     * #memberof DockerWorkerConfigV2DockerDatasource
        
        
         */
    bypassVerify?: boolean;
    /**
     *
     * #type boolean
     * #memberof DockerWorkerConfigV2DockerDatasource
        
        
         */
    enableDatapoolUpdate?: boolean;
    /**
     *
     * #type boolean
     * #memberof DockerWorkerConfigV2DockerDatasource
        
        
         */
    processAll?: boolean;
}
/**
 * Check if a given object implements the DockerWorkerConfigV2DockerDatasource interface.
 */
export function instanceOfDockerWorkerConfigV2DockerDatasource(value: object): boolean {
    let isInstance = true;
    return isInstance;
}
/* LIGHTLY CUSTOM START -> modelGeneric */
export const DockerWorkerConfigV2DockerDatasourceTypiaAssertEquals = (input: any) => {
    return ((input: any): DockerWorkerConfigV2DockerDatasource => {
        const __is = (input: any, _exceptionable: boolean = true): input is DockerWorkerConfigV2DockerDatasource => {
            const $io0 = (input: any, _exceptionable: boolean = true): boolean => (undefined === input.bypassVerify || "boolean" === typeof input.bypassVerify) && (undefined === input.enableDatapoolUpdate || "boolean" === typeof input.enableDatapoolUpdate) && (undefined === input.processAll || "boolean" === typeof input.processAll) && (0 === Object.keys(input).length || Object.keys(input).every((key: any) => {
                if (["bypassVerify", "enableDatapoolUpdate", "processAll"].some((prop: any) => key === prop))
                    return true;
                const value = input[key];
                if (undefined === value)
                    return true;
                return false;
            }));
            return "object" === typeof input && null !== input && false === Array.isArray(input) && $io0(input, true);
        };
        if (false === __is(input))
            ((input: any, _path: string, _exceptionable: boolean = true): input is DockerWorkerConfigV2DockerDatasource => {
                const $guard = (typia.createAssertEquals as any).guard;
                const $join = (typia.createAssertEquals as any).join;
                const $ao0 = (input: any, _path: string, _exceptionable: boolean = true): boolean => (undefined === input.bypassVerify || "boolean" === typeof input.bypassVerify || $guard(_exceptionable, {
                    path: _path + ".bypassVerify",
                    expected: "(boolean | undefined)",
                    value: input.bypassVerify
                })) && (undefined === input.enableDatapoolUpdate || "boolean" === typeof input.enableDatapoolUpdate || $guard(_exceptionable, {
                    path: _path + ".enableDatapoolUpdate",
                    expected: "(boolean | undefined)",
                    value: input.enableDatapoolUpdate
                })) && (undefined === input.processAll || "boolean" === typeof input.processAll || $guard(_exceptionable, {
                    path: _path + ".processAll",
                    expected: "(boolean | undefined)",
                    value: input.processAll
                })) && (0 === Object.keys(input).length || (false === _exceptionable || Object.keys(input).every((key: any) => {
                    if (["bypassVerify", "enableDatapoolUpdate", "processAll"].some((prop: any) => key === prop))
                        return true;
                    const value = input[key];
                    if (undefined === value)
                        return true;
                    return $guard(_exceptionable, {
                        path: _path + $join(key),
                        expected: "undefined",
                        value: value
                    });
                })));
                return ("object" === typeof input && null !== input && false === Array.isArray(input) || $guard(true, {
                    path: _path + "",
                    expected: "DockerWorkerConfigV2DockerDatasource",
                    value: input
                })) && $ao0(input, _path + "", true) || $guard(true, {
                    path: _path + "",
                    expected: "DockerWorkerConfigV2DockerDatasource",
                    value: input
                });
            })(input, "$input", true);
        return input;
    })(input);
};
export const DockerWorkerConfigV2DockerDatasourceTypiaAssertStringify = (input: DockerWorkerConfigV2DockerDatasource): string => {
    return ((input: any): string => { const assert = (input: any): DockerWorkerConfigV2DockerDatasource => {
        const __is = (input: any): input is DockerWorkerConfigV2DockerDatasource => {
            const $io0 = (input: any): boolean => (undefined === input.bypassVerify || "boolean" === typeof input.bypassVerify) && (undefined === input.enableDatapoolUpdate || "boolean" === typeof input.enableDatapoolUpdate) && (undefined === input.processAll || "boolean" === typeof input.processAll);
            return "object" === typeof input && null !== input && false === Array.isArray(input) && $io0(input);
        };
        if (false === __is(input))
            ((input: any, _path: string, _exceptionable: boolean = true): input is DockerWorkerConfigV2DockerDatasource => {
                const $guard = (typia.createAssertStringify as any).guard;
                const $ao0 = (input: any, _path: string, _exceptionable: boolean = true): boolean => (undefined === input.bypassVerify || "boolean" === typeof input.bypassVerify || $guard(_exceptionable, {
                    path: _path + ".bypassVerify",
                    expected: "(boolean | undefined)",
                    value: input.bypassVerify
                })) && (undefined === input.enableDatapoolUpdate || "boolean" === typeof input.enableDatapoolUpdate || $guard(_exceptionable, {
                    path: _path + ".enableDatapoolUpdate",
                    expected: "(boolean | undefined)",
                    value: input.enableDatapoolUpdate
                })) && (undefined === input.processAll || "boolean" === typeof input.processAll || $guard(_exceptionable, {
                    path: _path + ".processAll",
                    expected: "(boolean | undefined)",
                    value: input.processAll
                }));
                return ("object" === typeof input && null !== input && false === Array.isArray(input) || $guard(true, {
                    path: _path + "",
                    expected: "DockerWorkerConfigV2DockerDatasource",
                    value: input
                })) && $ao0(input, _path + "", true) || $guard(true, {
                    path: _path + "",
                    expected: "DockerWorkerConfigV2DockerDatasource",
                    value: input
                });
            })(input, "$input", true);
        return input;
    }; const stringify = (input: DockerWorkerConfigV2DockerDatasource): string => {
        const $tail = (typia.createAssertStringify as any).tail;
        const $so0 = (input: any): any => `{${$tail(`${undefined === input.bypassVerify ? "" : `"bypassVerify":${undefined !== input.bypassVerify ? input.bypassVerify : undefined},`}${undefined === input.enableDatapoolUpdate ? "" : `"enableDatapoolUpdate":${undefined !== input.enableDatapoolUpdate ? input.enableDatapoolUpdate : undefined},`}${undefined === input.processAll ? "" : `"processAll":${undefined !== input.processAll ? input.processAll : undefined}`}`)}}`;
        return $so0(input);
    }; return stringify(assert(input)); })(input);
};
export const DockerWorkerConfigV2DockerDatasourceValidatorShape = {
    'bypassVerify': yup.boolean(),
    'enableDatapoolUpdate': yup.boolean(),
    'processAll': yup.boolean(),
};
export const DockerWorkerConfigV2DockerDatasourceValidator = yup.object().shape(DockerWorkerConfigV2DockerDatasourceValidatorShape);
export const DockerWorkerConfigV2DockerDatasourceStringifyShapeProperties: ObjectSchema['properties'] = {
    'bypassVerify': {
        'type': 'boolean',
    },
    'enableDatapoolUpdate': {
        'type': 'boolean',
    },
    'processAll': {
        'type': 'boolean',
    },
};
export const DockerWorkerConfigV2DockerDatasourceStringifyShape: ObjectSchema = {
    title: 'DockerWorkerConfigV2DockerDatasource Stringifier',
    type: 'object',
    properties: {
        ...DockerWorkerConfigV2DockerDatasourceStringifyShapeProperties
    },
    required: [],
};
export const DockerWorkerConfigV2DockerDatasourceStringifyCompiled = (typeof process === 'object' && String(process) === '[object process]') ?
    fastJSON.build(DockerWorkerConfigV2DockerDatasourceStringifyShape, {
        ajv: {
            // since we are already doing this with yup, lets skip
            validateSchema: false,
            ownProperties: true,
            validateFormats: false,
        },
        largeArrayMechanism: 'json-stringify',
        largeArraySize: 1000,
    } as Options)
    :
        () => { throw new Error('fast-json-stringify only works in nodejs'); };
export const DockerWorkerConfigV2DockerDatasourceStringify: (data: DockerWorkerConfigV2DockerDatasource) => string = DockerWorkerConfigV2DockerDatasourceStringifyCompiled;
/* LIGHTLY CUSTOM END -> Generic */
export function DockerWorkerConfigV2DockerDatasourceFromJSON(json: any): DockerWorkerConfigV2DockerDatasource {
    return DockerWorkerConfigV2DockerDatasourceFromJSONTyped(json, false);
}
export function DockerWorkerConfigV2DockerDatasourceFromJSONTyped(json: any, ignoreDiscriminator: boolean): DockerWorkerConfigV2DockerDatasource {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    /* causes circular depdendencies
*/
    return {
        'bypassVerify': !exists(json, 'bypassVerify') ? undefined : json['bypassVerify'],
        'enableDatapoolUpdate': !exists(json, 'enableDatapoolUpdate') ? undefined : json['enableDatapoolUpdate'],
        'processAll': !exists(json, 'processAll') ? undefined : json['processAll'],
    };
}
export function DockerWorkerConfigV2DockerDatasourceToJSON(value?: DockerWorkerConfigV2DockerDatasource | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'bypassVerify': value.bypassVerify,
        'enableDatapoolUpdate': value.enableDatapoolUpdate,
        'processAll': value.processAll,
    };
}
