/* tslint:disable */
/* eslint-disable */
/**
 * Lightly API
 * Lightly.ai enables you to do self-supervised learning in an easy and intuitive way. The lightly.ai OpenAPI spec defines how one can interact with our REST API to unleash the full potential of lightly.ai
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: support@lightly.ai
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
// SRC: https://github.com/OpenAPITools/openapi-generator/blob/master/modules/openapi-generator/src/main/resources/typescript-fetch/modelGeneric.mustache
// synched 02.02.2023
import * as yup from 'yup';
import typia from 'typia';
import * as fastJSON from 'fast-json-stringify';
import { Options, ArraySchema, ObjectSchema } from 'fast-json-stringify';
import { exists, mapValues } from '../runtime';
import type { DockerRunLogDockerLoad } from './DockerRunLogDockerLoad';
import { DockerRunLogDockerLoadFromJSON, DockerRunLogDockerLoadFromJSONTyped, DockerRunLogDockerLoadToJSON, } from './DockerRunLogDockerLoad';
import type { DockerRunLogLevel } from './DockerRunLogLevel';
import { DockerRunLogLevelFromJSON, DockerRunLogLevelFromJSONTyped, DockerRunLogLevelToJSON, } from './DockerRunLogLevel';
import type { DockerRunState } from './DockerRunState';
import { DockerRunStateFromJSON, DockerRunStateFromJSONTyped, DockerRunStateToJSON, } from './DockerRunState';
/*
causes circular dependendies
*/
// SRC: https://github.com/OpenAPITools/openapi-generator/blob/master/modules/openapi-generator/src/main/resources/typescript-fetch/modelGenericInterfaces.mustache
// synced: 17.07.2023
/**
 *
 * @export
 * @interface DockerRunLogCreateEntryData
 *
 */
// create custom validators for the regexes
export interface DockerRunLogCreateEntryData {
    /**
     * unix timestamp in milliseconds
     * #type number
     * #memberof DockerRunLogCreateEntryData
        
    * @minimum 0
            
         */
    ts: number;
    /**
     *
     * #type DockerRunLogLevel
     * #memberof DockerRunLogCreateEntryData
        
        
         */
    level: DockerRunLogLevel;
    /**
     * The logger name/group of the log entry.
     * #type string
     * #memberof DockerRunLogCreateEntryData
        
        
         */
    group: string;
    /**
     * The origin/filename+loc from where a log entry was created from.
     * #type string
     * #memberof DockerRunLogCreateEntryData
        
        
         */
    origin?: string;
    /**
     * The actual log message.
     * #type string
     * #memberof DockerRunLogCreateEntryData
        
        
         */
    msg: string;
    /**
     *
     * #type DockerRunState
     * #memberof DockerRunLogCreateEntryData
        
        
         */
    state?: DockerRunState;
    /**
     *
     * #type DockerRunLogDockerLoad
     * #memberof DockerRunLogCreateEntryData
        
        
         */
    load?: DockerRunLogDockerLoad;
}
/**
 * Check if a given object implements the DockerRunLogCreateEntryData interface.
 */
export function instanceOfDockerRunLogCreateEntryData(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "ts" in value;
    isInstance = isInstance && "level" in value;
    isInstance = isInstance && "group" in value;
    isInstance = isInstance && "msg" in value;
    return isInstance;
}
/* LIGHTLY CUSTOM START -> modelGeneric */
import { DockerRunLogDockerLoadValidator, DockerRunLogDockerLoadStringifyShape, DockerRunLogDockerLoadStringifyShapeProperties, } from './DockerRunLogDockerLoad';
import { DockerRunLogLevelValidator, DockerRunLogLevelStringifyShape, DockerRunLogLevelStringifyShapeProperties, } from './DockerRunLogLevel';
import { DockerRunStateValidator, DockerRunStateStringifyShape, DockerRunStateStringifyShapeProperties, } from './DockerRunState';
export const DockerRunLogCreateEntryDataTypiaAssertEquals = (input: any) => {
    return ((input: any): DockerRunLogCreateEntryData => {
        const __is = (input: any, _exceptionable: boolean = true): input is DockerRunLogCreateEntryData => {
            const $io0 = (input: any, _exceptionable: boolean = true): boolean => "number" === typeof input.ts && 0 <= input.ts && ("VERBOSE" === input.level || "NOTSET" === input.level || "DEBUG" === input.level || "INFO" === input.level || "WARNING" === input.level || "ERROR" === input.level || "CRITICAL" === input.level) && "string" === typeof input.group && (undefined === input.origin || "string" === typeof input.origin) && "string" === typeof input.msg && (undefined === input.state || "STARTED" === input.state || "INITIALIZING" === input.state || "LOADING_DATASET" === input.state || "LOADING_METADATA" === input.state || "LOADING_PREDICTION" === input.state || "CHECKING_CORRUPTNESS" === input.state || "INITIALIZING_OBJECT_CROPS" === input.state || "COMPUTING_METADATA" === input.state || "TRAINING" === input.state || "EMBEDDING" === input.state || "EMBEDDING_OBJECT_CROPS" === input.state || "PRETAGGING" === input.state || "COMPUTING_ACTIVE_LEARNING_SCORES" === input.state || "SAMPLING" === input.state || "EMBEDDING_FULL_IMAGES" === input.state || "SAVING_RESULTS" === input.state || "UPLOADING_DATASET" === input.state || "GENERATING_REPORT" === input.state || "UPLOADING_REPORT" === input.state || "UPLOADED_REPORT" === input.state || "UPLOADING_ARTIFACTS" === input.state || "UPLOADED_ARTIFACTS" === input.state || "COMPLETED" === input.state || "FAILED" === input.state || "CRASHED" === input.state || "ABORTED" === input.state) && (undefined === input.load || "object" === typeof input.load && null !== input.load && false === Array.isArray(input.load) && $io1(input.load, true && _exceptionable)) && (4 === Object.keys(input).length || Object.keys(input).every((key: any) => {
                if (["ts", "level", "group", "origin", "msg", "state", "load"].some((prop: any) => key === prop))
                    return true;
                const value = input[key];
                if (undefined === value)
                    return true;
                return false;
            }));
            const $io1 = (input: any, _exceptionable: boolean = true): boolean => (undefined === input.cpu || "number" === typeof input.cpu && 0 <= input.cpu && 100 >= input.cpu) && (undefined === input.mem || "number" === typeof input.mem && 0 <= input.mem && 100 >= input.mem) && (0 === Object.keys(input).length || Object.keys(input).every((key: any) => {
                if (["cpu", "mem"].some((prop: any) => key === prop))
                    return true;
                const value = input[key];
                if (undefined === value)
                    return true;
                return false;
            }));
            return "object" === typeof input && null !== input && $io0(input, true);
        };
        if (false === __is(input))
            ((input: any, _path: string, _exceptionable: boolean = true): input is DockerRunLogCreateEntryData => {
                const $guard = (typia.createAssertEquals as any).guard;
                const $join = (typia.createAssertEquals as any).join;
                const $ao0 = (input: any, _path: string, _exceptionable: boolean = true): boolean => ("number" === typeof input.ts && (0 <= input.ts || $guard(_exceptionable, {
                    path: _path + ".ts",
                    expected: "number (@minimum 0)",
                    value: input.ts
                })) || $guard(_exceptionable, {
                    path: _path + ".ts",
                    expected: "number",
                    value: input.ts
                })) && ("VERBOSE" === input.level || "NOTSET" === input.level || "DEBUG" === input.level || "INFO" === input.level || "WARNING" === input.level || "ERROR" === input.level || "CRITICAL" === input.level || $guard(_exceptionable, {
                    path: _path + ".level",
                    expected: "(\"CRITICAL\" | \"DEBUG\" | \"ERROR\" | \"INFO\" | \"NOTSET\" | \"VERBOSE\" | \"WARNING\")",
                    value: input.level
                })) && ("string" === typeof input.group || $guard(_exceptionable, {
                    path: _path + ".group",
                    expected: "string",
                    value: input.group
                })) && (undefined === input.origin || "string" === typeof input.origin || $guard(_exceptionable, {
                    path: _path + ".origin",
                    expected: "(string | undefined)",
                    value: input.origin
                })) && ("string" === typeof input.msg || $guard(_exceptionable, {
                    path: _path + ".msg",
                    expected: "string",
                    value: input.msg
                })) && (undefined === input.state || "STARTED" === input.state || "INITIALIZING" === input.state || "LOADING_DATASET" === input.state || "LOADING_METADATA" === input.state || "LOADING_PREDICTION" === input.state || "CHECKING_CORRUPTNESS" === input.state || "INITIALIZING_OBJECT_CROPS" === input.state || "COMPUTING_METADATA" === input.state || "TRAINING" === input.state || "EMBEDDING" === input.state || "EMBEDDING_OBJECT_CROPS" === input.state || "PRETAGGING" === input.state || "COMPUTING_ACTIVE_LEARNING_SCORES" === input.state || "SAMPLING" === input.state || "EMBEDDING_FULL_IMAGES" === input.state || "SAVING_RESULTS" === input.state || "UPLOADING_DATASET" === input.state || "GENERATING_REPORT" === input.state || "UPLOADING_REPORT" === input.state || "UPLOADED_REPORT" === input.state || "UPLOADING_ARTIFACTS" === input.state || "UPLOADED_ARTIFACTS" === input.state || "COMPLETED" === input.state || "FAILED" === input.state || "CRASHED" === input.state || "ABORTED" === input.state || $guard(_exceptionable, {
                    path: _path + ".state",
                    expected: "(\"ABORTED\" | \"CHECKING_CORRUPTNESS\" | \"COMPLETED\" | \"COMPUTING_ACTIVE_LEARNING_SCORES\" | \"COMPUTING_METADATA\" | \"CRASHED\" | \"EMBEDDING\" | \"EMBEDDING_FULL_IMAGES\" | \"EMBEDDING_OBJECT_CROPS\" | \"FAILED\" | \"GENERATING_REPORT\" | \"INITIALIZING\" | \"INITIALIZING_OBJECT_CROPS\" | \"LOADING_DATASET\" | \"LOADING_METADATA\" | \"LOADING_PREDICTION\" | \"PRETAGGING\" | \"SAMPLING\" | \"SAVING_RESULTS\" | \"STARTED\" | \"TRAINING\" | \"UPLOADED_ARTIFACTS\" | \"UPLOADED_REPORT\" | \"UPLOADING_ARTIFACTS\" | \"UPLOADING_DATASET\" | \"UPLOADING_REPORT\" | undefined)",
                    value: input.state
                })) && (undefined === input.load || ("object" === typeof input.load && null !== input.load && false === Array.isArray(input.load) || $guard(_exceptionable, {
                    path: _path + ".load",
                    expected: "(DockerRunLogDockerLoad | undefined)",
                    value: input.load
                })) && $ao1(input.load, _path + ".load", true && _exceptionable) || $guard(_exceptionable, {
                    path: _path + ".load",
                    expected: "(DockerRunLogDockerLoad | undefined)",
                    value: input.load
                })) && (4 === Object.keys(input).length || (false === _exceptionable || Object.keys(input).every((key: any) => {
                    if (["ts", "level", "group", "origin", "msg", "state", "load"].some((prop: any) => key === prop))
                        return true;
                    const value = input[key];
                    if (undefined === value)
                        return true;
                    return $guard(_exceptionable, {
                        path: _path + $join(key),
                        expected: "undefined",
                        value: value
                    });
                })));
                const $ao1 = (input: any, _path: string, _exceptionable: boolean = true): boolean => (undefined === input.cpu || "number" === typeof input.cpu && (0 <= input.cpu || $guard(_exceptionable, {
                    path: _path + ".cpu",
                    expected: "number (@minimum 0)",
                    value: input.cpu
                })) && (100 >= input.cpu || $guard(_exceptionable, {
                    path: _path + ".cpu",
                    expected: "number (@maximum 100)",
                    value: input.cpu
                })) || $guard(_exceptionable, {
                    path: _path + ".cpu",
                    expected: "(number | undefined)",
                    value: input.cpu
                })) && (undefined === input.mem || "number" === typeof input.mem && (0 <= input.mem || $guard(_exceptionable, {
                    path: _path + ".mem",
                    expected: "number (@minimum 0)",
                    value: input.mem
                })) && (100 >= input.mem || $guard(_exceptionable, {
                    path: _path + ".mem",
                    expected: "number (@maximum 100)",
                    value: input.mem
                })) || $guard(_exceptionable, {
                    path: _path + ".mem",
                    expected: "(number | undefined)",
                    value: input.mem
                })) && (0 === Object.keys(input).length || (false === _exceptionable || Object.keys(input).every((key: any) => {
                    if (["cpu", "mem"].some((prop: any) => key === prop))
                        return true;
                    const value = input[key];
                    if (undefined === value)
                        return true;
                    return $guard(_exceptionable, {
                        path: _path + $join(key),
                        expected: "undefined",
                        value: value
                    });
                })));
                return ("object" === typeof input && null !== input || $guard(true, {
                    path: _path + "",
                    expected: "DockerRunLogCreateEntryData",
                    value: input
                })) && $ao0(input, _path + "", true) || $guard(true, {
                    path: _path + "",
                    expected: "DockerRunLogCreateEntryData",
                    value: input
                });
            })(input, "$input", true);
        return input;
    })(input);
};
export const DockerRunLogCreateEntryDataTypiaAssertStringify = (input: DockerRunLogCreateEntryData): string => {
    return ((input: any): string => { const assert = (input: any): DockerRunLogCreateEntryData => {
        const __is = (input: any): input is DockerRunLogCreateEntryData => {
            const $io0 = (input: any): boolean => "number" === typeof input.ts && !Number.isNaN(input.ts) && 0 <= input.ts && ("VERBOSE" === input.level || "NOTSET" === input.level || "DEBUG" === input.level || "INFO" === input.level || "WARNING" === input.level || "ERROR" === input.level || "CRITICAL" === input.level) && "string" === typeof input.group && (undefined === input.origin || "string" === typeof input.origin) && "string" === typeof input.msg && (undefined === input.state || "STARTED" === input.state || "INITIALIZING" === input.state || "LOADING_DATASET" === input.state || "LOADING_METADATA" === input.state || "LOADING_PREDICTION" === input.state || "CHECKING_CORRUPTNESS" === input.state || "INITIALIZING_OBJECT_CROPS" === input.state || "COMPUTING_METADATA" === input.state || "TRAINING" === input.state || "EMBEDDING" === input.state || "EMBEDDING_OBJECT_CROPS" === input.state || "PRETAGGING" === input.state || "COMPUTING_ACTIVE_LEARNING_SCORES" === input.state || "SAMPLING" === input.state || "EMBEDDING_FULL_IMAGES" === input.state || "SAVING_RESULTS" === input.state || "UPLOADING_DATASET" === input.state || "GENERATING_REPORT" === input.state || "UPLOADING_REPORT" === input.state || "UPLOADED_REPORT" === input.state || "UPLOADING_ARTIFACTS" === input.state || "UPLOADED_ARTIFACTS" === input.state || "COMPLETED" === input.state || "FAILED" === input.state || "CRASHED" === input.state || "ABORTED" === input.state) && (undefined === input.load || "object" === typeof input.load && null !== input.load && false === Array.isArray(input.load) && $io1(input.load));
            const $io1 = (input: any): boolean => (undefined === input.cpu || "number" === typeof input.cpu && 0 <= input.cpu && 100 >= input.cpu) && (undefined === input.mem || "number" === typeof input.mem && 0 <= input.mem && 100 >= input.mem);
            return "object" === typeof input && null !== input && $io0(input);
        };
        if (false === __is(input))
            ((input: any, _path: string, _exceptionable: boolean = true): input is DockerRunLogCreateEntryData => {
                const $guard = (typia.createAssertStringify as any).guard;
                const $ao0 = (input: any, _path: string, _exceptionable: boolean = true): boolean => ("number" === typeof input.ts && !Number.isNaN(input.ts) && (0 <= input.ts || $guard(_exceptionable, {
                    path: _path + ".ts",
                    expected: "number (@minimum 0)",
                    value: input.ts
                })) || $guard(_exceptionable, {
                    path: _path + ".ts",
                    expected: "number",
                    value: input.ts
                })) && ("VERBOSE" === input.level || "NOTSET" === input.level || "DEBUG" === input.level || "INFO" === input.level || "WARNING" === input.level || "ERROR" === input.level || "CRITICAL" === input.level || $guard(_exceptionable, {
                    path: _path + ".level",
                    expected: "(\"CRITICAL\" | \"DEBUG\" | \"ERROR\" | \"INFO\" | \"NOTSET\" | \"VERBOSE\" | \"WARNING\")",
                    value: input.level
                })) && ("string" === typeof input.group || $guard(_exceptionable, {
                    path: _path + ".group",
                    expected: "string",
                    value: input.group
                })) && (undefined === input.origin || "string" === typeof input.origin || $guard(_exceptionable, {
                    path: _path + ".origin",
                    expected: "(string | undefined)",
                    value: input.origin
                })) && ("string" === typeof input.msg || $guard(_exceptionable, {
                    path: _path + ".msg",
                    expected: "string",
                    value: input.msg
                })) && (undefined === input.state || "STARTED" === input.state || "INITIALIZING" === input.state || "LOADING_DATASET" === input.state || "LOADING_METADATA" === input.state || "LOADING_PREDICTION" === input.state || "CHECKING_CORRUPTNESS" === input.state || "INITIALIZING_OBJECT_CROPS" === input.state || "COMPUTING_METADATA" === input.state || "TRAINING" === input.state || "EMBEDDING" === input.state || "EMBEDDING_OBJECT_CROPS" === input.state || "PRETAGGING" === input.state || "COMPUTING_ACTIVE_LEARNING_SCORES" === input.state || "SAMPLING" === input.state || "EMBEDDING_FULL_IMAGES" === input.state || "SAVING_RESULTS" === input.state || "UPLOADING_DATASET" === input.state || "GENERATING_REPORT" === input.state || "UPLOADING_REPORT" === input.state || "UPLOADED_REPORT" === input.state || "UPLOADING_ARTIFACTS" === input.state || "UPLOADED_ARTIFACTS" === input.state || "COMPLETED" === input.state || "FAILED" === input.state || "CRASHED" === input.state || "ABORTED" === input.state || $guard(_exceptionable, {
                    path: _path + ".state",
                    expected: "(\"ABORTED\" | \"CHECKING_CORRUPTNESS\" | \"COMPLETED\" | \"COMPUTING_ACTIVE_LEARNING_SCORES\" | \"COMPUTING_METADATA\" | \"CRASHED\" | \"EMBEDDING\" | \"EMBEDDING_FULL_IMAGES\" | \"EMBEDDING_OBJECT_CROPS\" | \"FAILED\" | \"GENERATING_REPORT\" | \"INITIALIZING\" | \"INITIALIZING_OBJECT_CROPS\" | \"LOADING_DATASET\" | \"LOADING_METADATA\" | \"LOADING_PREDICTION\" | \"PRETAGGING\" | \"SAMPLING\" | \"SAVING_RESULTS\" | \"STARTED\" | \"TRAINING\" | \"UPLOADED_ARTIFACTS\" | \"UPLOADED_REPORT\" | \"UPLOADING_ARTIFACTS\" | \"UPLOADING_DATASET\" | \"UPLOADING_REPORT\" | undefined)",
                    value: input.state
                })) && (undefined === input.load || ("object" === typeof input.load && null !== input.load && false === Array.isArray(input.load) || $guard(_exceptionable, {
                    path: _path + ".load",
                    expected: "(DockerRunLogDockerLoad | undefined)",
                    value: input.load
                })) && $ao1(input.load, _path + ".load", true && _exceptionable) || $guard(_exceptionable, {
                    path: _path + ".load",
                    expected: "(DockerRunLogDockerLoad | undefined)",
                    value: input.load
                }));
                const $ao1 = (input: any, _path: string, _exceptionable: boolean = true): boolean => (undefined === input.cpu || "number" === typeof input.cpu && (0 <= input.cpu || $guard(_exceptionable, {
                    path: _path + ".cpu",
                    expected: "number (@minimum 0)",
                    value: input.cpu
                })) && (100 >= input.cpu || $guard(_exceptionable, {
                    path: _path + ".cpu",
                    expected: "number (@maximum 100)",
                    value: input.cpu
                })) || $guard(_exceptionable, {
                    path: _path + ".cpu",
                    expected: "(number | undefined)",
                    value: input.cpu
                })) && (undefined === input.mem || "number" === typeof input.mem && (0 <= input.mem || $guard(_exceptionable, {
                    path: _path + ".mem",
                    expected: "number (@minimum 0)",
                    value: input.mem
                })) && (100 >= input.mem || $guard(_exceptionable, {
                    path: _path + ".mem",
                    expected: "number (@maximum 100)",
                    value: input.mem
                })) || $guard(_exceptionable, {
                    path: _path + ".mem",
                    expected: "(number | undefined)",
                    value: input.mem
                }));
                return ("object" === typeof input && null !== input || $guard(true, {
                    path: _path + "",
                    expected: "DockerRunLogCreateEntryData",
                    value: input
                })) && $ao0(input, _path + "", true) || $guard(true, {
                    path: _path + "",
                    expected: "DockerRunLogCreateEntryData",
                    value: input
                });
            })(input, "$input", true);
        return input;
    }; const stringify = (input: DockerRunLogCreateEntryData): string => {
        const $io1 = (input: any): boolean => (undefined === input.cpu || "number" === typeof input.cpu && 0 <= input.cpu && 100 >= input.cpu) && (undefined === input.mem || "number" === typeof input.mem && 0 <= input.mem && 100 >= input.mem);
        const $string = (typia.createAssertStringify as any).string;
        const $throws = (typia.createAssertStringify as any).throws;
        const $tail = (typia.createAssertStringify as any).tail;
        const $so0 = (input: any): any => `{${undefined === input.origin ? "" : `"origin":${undefined !== input.origin ? $string(input.origin) : undefined},`}${undefined === input.state ? "" : `"state":${undefined !== input.state ? (() => {
            if ("string" === typeof input.state)
                return $string(input.state);
            if ("string" === typeof input.state)
                return "\"" + input.state + "\"";
            $throws({
                expected: "(\"ABORTED\" | \"CHECKING_CORRUPTNESS\" | \"COMPLETED\" | \"COMPUTING_ACTIVE_LEARNING_SCORES\" | \"COMPUTING_METADATA\" | \"CRASHED\" | \"EMBEDDING\" | \"EMBEDDING_FULL_IMAGES\" | \"EMBEDDING_OBJECT_CROPS\" | \"FAILED\" | \"GENERATING_REPORT\" | \"INITIALIZING\" | \"INITIALIZING_OBJECT_CROPS\" | \"LOADING_DATASET\" | \"LOADING_METADATA\" | \"LOADING_PREDICTION\" | \"PRETAGGING\" | \"SAMPLING\" | \"SAVING_RESULTS\" | \"STARTED\" | \"TRAINING\" | \"UPLOADED_ARTIFACTS\" | \"UPLOADED_REPORT\" | \"UPLOADING_ARTIFACTS\" | \"UPLOADING_DATASET\" | \"UPLOADING_REPORT\" | undefined)",
                value: input.state
            });
        })() : undefined},`}${undefined === input.load ? "" : `"load":${undefined !== input.load ? $so1(input.load) : undefined},`}"ts":${input.ts},"level":${(() => {
            if ("string" === typeof input.level)
                return $string(input.level);
            if ("string" === typeof input.level)
                return "\"" + input.level + "\"";
            $throws({
                expected: "(\"CRITICAL\" | \"DEBUG\" | \"ERROR\" | \"INFO\" | \"NOTSET\" | \"VERBOSE\" | \"WARNING\")",
                value: input.level
            });
        })()},"group":${$string(input.group)},"msg":${$string(input.msg)}}`;
        const $so1 = (input: any): any => `{${$tail(`${undefined === input.cpu ? "" : `"cpu":${undefined !== input.cpu ? input.cpu : undefined},`}${undefined === input.mem ? "" : `"mem":${undefined !== input.mem ? input.mem : undefined}`}`)}}`;
        return $so0(input);
    }; return stringify(assert(input)); })(input);
};
export const DockerRunLogCreateEntryDataValidatorShape = {
    'ts': yup.number().min(0).defined(),
    'level': DockerRunLogLevelValidator.defined(),
    'group': yup.string().defined(),
    'origin': yup.string(),
    'msg': yup.string().defined(),
    'state': DockerRunStateValidator.optional().default(undefined),
    'load': DockerRunLogDockerLoadValidator.optional().default(undefined),
};
export const DockerRunLogCreateEntryDataValidator = yup.object().shape(DockerRunLogCreateEntryDataValidatorShape);
export const DockerRunLogCreateEntryDataStringifyShapeProperties: ObjectSchema['properties'] = {
    'ts': {
        'type': 'number',
        'minimum': 0,
    } as unknown as fastJSON.NumberSchema,
    'level': DockerRunLogLevelStringifyShape,
    'group': {
        'type': 'string',
    } as unknown as fastJSON.StringSchema,
    'origin': {
        'type': 'string',
    } as unknown as fastJSON.StringSchema,
    'msg': {
        'type': 'string',
    } as unknown as fastJSON.StringSchema,
    'state': DockerRunStateStringifyShape,
    'load': DockerRunLogDockerLoadStringifyShape,
};
export const DockerRunLogCreateEntryDataStringifyShape: ObjectSchema = {
    title: 'DockerRunLogCreateEntryData Stringifier',
    type: 'object',
    properties: {
        ...DockerRunLogCreateEntryDataStringifyShapeProperties
    },
    required: [
        'ts',
        'level',
        'group',
        'msg',
    ],
};
export const DockerRunLogCreateEntryDataStringifyCompiled = (typeof process === 'object' && String(process) === '[object process]') ?
    fastJSON.build(DockerRunLogCreateEntryDataStringifyShape, {
        ajv: {
            // since we are already doing this with yup, lets skip
            validateSchema: false,
            ownProperties: true,
            validateFormats: false,
        },
        largeArrayMechanism: 'json-stringify',
        largeArraySize: 1000,
    } as Options)
    :
        () => { throw new Error('fast-json-stringify only works in nodejs'); };
export const DockerRunLogCreateEntryDataStringify: (data: DockerRunLogCreateEntryData) => string = DockerRunLogCreateEntryDataStringifyCompiled;
/* LIGHTLY CUSTOM END -> Generic */
export function DockerRunLogCreateEntryDataFromJSON(json: any): DockerRunLogCreateEntryData {
    return DockerRunLogCreateEntryDataFromJSONTyped(json, false);
}
export function DockerRunLogCreateEntryDataFromJSONTyped(json: any, ignoreDiscriminator: boolean): DockerRunLogCreateEntryData {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    /* causes circular depdendencies
*/
    return {
        'ts': json['ts'],
        'level': DockerRunLogLevelFromJSON(json['level']),
        'group': json['group'],
        'origin': !exists(json, 'origin') ? undefined : json['origin'],
        'msg': json['msg'],
        'state': !exists(json, 'state') ? undefined : DockerRunStateFromJSON(json['state']),
        'load': !exists(json, 'load') ? undefined : DockerRunLogDockerLoadFromJSON(json['load']),
    };
}
export function DockerRunLogCreateEntryDataToJSON(value?: DockerRunLogCreateEntryData | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'ts': value.ts,
        'level': DockerRunLogLevelToJSON(value.level),
        'group': value.group,
        'origin': value.origin,
        'msg': value.msg,
        'state': DockerRunStateToJSON(value.state),
        'load': DockerRunLogDockerLoadToJSON(value.load),
    };
}
