export const datasetschedule = {
	'priority': 'Priority',
	'priority_LOW': 'Low',
	'priority_MID': 'Medium',
	'priority_HIGH': 'High',
	'priority_CRITICAL': 'Critical',

	// state of scheduled job
	'state_OPEN': 'Pending...',
	'state_LOCKED': 'Running...',
	'state_DONE': 'Completed',
	'state_CANCELED': 'Canceled',
}
export default datasetschedule;
