/* tslint:disable */
/* eslint-disable */
/**
 * Lightly API
 * Lightly.ai enables you to do self-supervised learning in an easy and intuitive way. The lightly.ai OpenAPI spec defines how one can interact with our REST API to unleash the full potential of lightly.ai
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: support@lightly.ai
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
// SRC: https://github.com/OpenAPITools/openapi-generator/blob/master/modules/openapi-generator/src/main/resources/typescript-fetch/modelGeneric.mustache
// synched 02.02.2023
import * as yup from 'yup';
import typia from 'typia';
import * as fastJSON from 'fast-json-stringify';
import { Options, ArraySchema, ObjectSchema } from 'fast-json-stringify';
import { exists, mapValues } from '../runtime';
import type { ProfileMeDataSettings } from './ProfileMeDataSettings';
import { ProfileMeDataSettingsFromJSON, ProfileMeDataSettingsFromJSONTyped, ProfileMeDataSettingsToJSON, } from './ProfileMeDataSettings';
import type { TeamBasicData } from './TeamBasicData';
import { TeamBasicDataFromJSON, TeamBasicDataFromJSONTyped, TeamBasicDataToJSON, } from './TeamBasicData';
import type { UserType } from './UserType';
import { UserTypeFromJSON, UserTypeFromJSONTyped, UserTypeToJSON, } from './UserType';
/*
causes circular dependendies
*/
// SRC: https://github.com/OpenAPITools/openapi-generator/blob/master/modules/openapi-generator/src/main/resources/typescript-fetch/modelGenericInterfaces.mustache
// synced: 17.07.2023
/**
 *
 * @export
 * @interface ProfileMeData
 *
 */
// create custom validators for the regexes
export interface ProfileMeData {
    /**
     *
     * #type string
     * #memberof ProfileMeData
        
        
         */
    id: string;
    /**
     *
     * #type UserType
     * #memberof ProfileMeData
        
        
         */
    userType: UserType;
    /**
     * email of the user
     * #type string
     * #memberof ProfileMeData
        
        
         */
    email: string;
    /**
     *
     * #type string
     * #memberof ProfileMeData
        
        
         */
    nickname?: string;
    /**
     *
     * #type string
     * #memberof ProfileMeData
        
        
         */
    name?: string;
    /**
     *
     * #type string
     * #memberof ProfileMeData
        
        
         */
    givenName?: string;
    /**
     *
     * #type string
     * #memberof ProfileMeData
        
        
         */
    familyName?: string;
    /**
     * The user's token to be used for authentication via token querystring
     * #type string
     * #memberof ProfileMeData
        
        
    * @minLength 5
         */
    token?: string;
    /**
     * unix timestamp in milliseconds
     * #type number
     * #memberof ProfileMeData
        
    * @minimum 0
            
         */
    createdAt: number;
    /**
     *
     * #type Array<TeamBasicData>
     * #memberof ProfileMeData
        
        
         */
    teams?: Array<TeamBasicData>;
    /**
     *
     * #type ProfileMeDataSettings
     * #memberof ProfileMeData
        
        
         */
    settings: ProfileMeDataSettings;
    /**
     *
     * #type number
     * #memberof ProfileMeData
        
        
         */
    onboarding?: number;
}
/**
 * Check if a given object implements the ProfileMeData interface.
 */
export function instanceOfProfileMeData(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "userType" in value;
    isInstance = isInstance && "email" in value;
    isInstance = isInstance && "createdAt" in value;
    isInstance = isInstance && "settings" in value;
    return isInstance;
}
/* LIGHTLY CUSTOM START -> modelGeneric */
import { ProfileMeDataSettingsValidator, ProfileMeDataSettingsStringifyShape, ProfileMeDataSettingsStringifyShapeProperties, } from './ProfileMeDataSettings';
import { TeamBasicDataValidator, TeamBasicDataStringifyShape, TeamBasicDataStringifyShapeProperties, } from './TeamBasicData';
import { UserTypeValidator, UserTypeStringifyShape, UserTypeStringifyShapeProperties, } from './UserType';
export const ProfileMeDataTypiaAssertEquals = (input: any) => {
    return ((input: any): ProfileMeData => {
        const __is = (input: any, _exceptionable: boolean = true): input is ProfileMeData => {
            const $is_custom = (typia.createAssertEquals as any).is_custom;
            const $join = (typia.createAssertEquals as any).join;
            const $io0 = (input: any, _exceptionable: boolean = true): boolean => "string" === typeof input.id && ("FREE" === input.userType || "PROFESSIONAL" === input.userType || "ENTERPRISE" === input.userType || "ADMIN" === input.userType) && "string" === typeof input.email && (undefined === input.nickname || "string" === typeof input.nickname) && (undefined === input.name || "string" === typeof input.name) && (undefined === input.givenName || "string" === typeof input.givenName) && (undefined === input.familyName || "string" === typeof input.familyName) && (undefined === input.token || "string" === typeof input.token && 5 <= input.token.length) && ("number" === typeof input.createdAt && 0 <= input.createdAt) && (undefined === input.teams || Array.isArray(input.teams) && input.teams.every((elem: any, _index1: number) => "object" === typeof elem && null !== elem && $io1(elem, true && _exceptionable))) && ("object" === typeof input.settings && null !== input.settings && false === Array.isArray(input.settings) && $io2(input.settings, true && _exceptionable)) && (undefined === input.onboarding || "number" === typeof input.onboarding) && (5 === Object.keys(input).length || Object.keys(input).every((key: any) => {
                if (["id", "userType", "email", "nickname", "name", "givenName", "familyName", "token", "createdAt", "teams", "settings", "onboarding"].some((prop: any) => key === prop))
                    return true;
                const value = input[key];
                if (undefined === value)
                    return true;
                return false;
            }));
            const $io1 = (input: any, _exceptionable: boolean = true): boolean => "string" === typeof input.id && $is_custom("pattern_id", "string", "", input.id) && "string" === typeof input.name && ("OWNER" === input.role || "ADMIN" === input.role || "MEMBER" === input.role || "SERVICEACCOUNT" === input.role) && (3 === Object.keys(input).length || Object.keys(input).every((key: any) => {
                if (["id", "name", "role"].some((prop: any) => key === prop))
                    return true;
                const value = input[key];
                if (undefined === value)
                    return true;
                return false;
            }));
            const $io2 = (input: any, _exceptionable: boolean = true): boolean => (undefined === input.locale || "string" === typeof input.locale) && (undefined === input.dateFormat || "string" === typeof input.dateFormat) && (undefined === input.numberFormat || "string" === typeof input.numberFormat) && Object.keys(input).every((key: any) => {
                if (["locale", "dateFormat", "numberFormat"].some((prop: any) => key === prop))
                    return true;
                const value = input[key];
                if (undefined === value)
                    return true;
                if (RegExp(/(.*)/).test(key))
                    return true;
                return false;
            });
            return "object" === typeof input && null !== input && $io0(input, true);
        };
        if (false === __is(input))
            ((input: any, _path: string, _exceptionable: boolean = true): input is ProfileMeData => {
                const $guard = (typia.createAssertEquals as any).guard;
                const $join = (typia.createAssertEquals as any).join;
                const $is_custom = (typia.createAssertEquals as any).is_custom;
                const $ao0 = (input: any, _path: string, _exceptionable: boolean = true): boolean => ("string" === typeof input.id || $guard(_exceptionable, {
                    path: _path + ".id",
                    expected: "string",
                    value: input.id
                })) && ("FREE" === input.userType || "PROFESSIONAL" === input.userType || "ENTERPRISE" === input.userType || "ADMIN" === input.userType || $guard(_exceptionable, {
                    path: _path + ".userType",
                    expected: "(\"ADMIN\" | \"ENTERPRISE\" | \"FREE\" | \"PROFESSIONAL\")",
                    value: input.userType
                })) && ("string" === typeof input.email || $guard(_exceptionable, {
                    path: _path + ".email",
                    expected: "string",
                    value: input.email
                })) && (undefined === input.nickname || "string" === typeof input.nickname || $guard(_exceptionable, {
                    path: _path + ".nickname",
                    expected: "(string | undefined)",
                    value: input.nickname
                })) && (undefined === input.name || "string" === typeof input.name || $guard(_exceptionable, {
                    path: _path + ".name",
                    expected: "(string | undefined)",
                    value: input.name
                })) && (undefined === input.givenName || "string" === typeof input.givenName || $guard(_exceptionable, {
                    path: _path + ".givenName",
                    expected: "(string | undefined)",
                    value: input.givenName
                })) && (undefined === input.familyName || "string" === typeof input.familyName || $guard(_exceptionable, {
                    path: _path + ".familyName",
                    expected: "(string | undefined)",
                    value: input.familyName
                })) && (undefined === input.token || "string" === typeof input.token && (5 <= input.token.length || $guard(_exceptionable, {
                    path: _path + ".token",
                    expected: "string (@minLength 5)",
                    value: input.token
                })) || $guard(_exceptionable, {
                    path: _path + ".token",
                    expected: "(string | undefined)",
                    value: input.token
                })) && ("number" === typeof input.createdAt && (0 <= input.createdAt || $guard(_exceptionable, {
                    path: _path + ".createdAt",
                    expected: "number (@minimum 0)",
                    value: input.createdAt
                })) || $guard(_exceptionable, {
                    path: _path + ".createdAt",
                    expected: "number",
                    value: input.createdAt
                })) && (undefined === input.teams || (Array.isArray(input.teams) || $guard(_exceptionable, {
                    path: _path + ".teams",
                    expected: "(Array<TeamBasicData> | undefined)",
                    value: input.teams
                })) && input.teams.every((elem: any, _index1: number) => ("object" === typeof elem && null !== elem || $guard(_exceptionable, {
                    path: _path + ".teams[" + _index1 + "]",
                    expected: "TeamBasicData",
                    value: elem
                })) && $ao1(elem, _path + ".teams[" + _index1 + "]", true && _exceptionable) || $guard(_exceptionable, {
                    path: _path + ".teams[" + _index1 + "]",
                    expected: "TeamBasicData",
                    value: elem
                })) || $guard(_exceptionable, {
                    path: _path + ".teams",
                    expected: "(Array<TeamBasicData> | undefined)",
                    value: input.teams
                })) && (("object" === typeof input.settings && null !== input.settings && false === Array.isArray(input.settings) || $guard(_exceptionable, {
                    path: _path + ".settings",
                    expected: "ProfileMeDataSettings",
                    value: input.settings
                })) && $ao2(input.settings, _path + ".settings", true && _exceptionable) || $guard(_exceptionable, {
                    path: _path + ".settings",
                    expected: "ProfileMeDataSettings",
                    value: input.settings
                })) && (undefined === input.onboarding || "number" === typeof input.onboarding || $guard(_exceptionable, {
                    path: _path + ".onboarding",
                    expected: "(number | undefined)",
                    value: input.onboarding
                })) && (5 === Object.keys(input).length || (false === _exceptionable || Object.keys(input).every((key: any) => {
                    if (["id", "userType", "email", "nickname", "name", "givenName", "familyName", "token", "createdAt", "teams", "settings", "onboarding"].some((prop: any) => key === prop))
                        return true;
                    const value = input[key];
                    if (undefined === value)
                        return true;
                    return $guard(_exceptionable, {
                        path: _path + $join(key),
                        expected: "undefined",
                        value: value
                    });
                })));
                const $ao1 = (input: any, _path: string, _exceptionable: boolean = true): boolean => ("string" === typeof input.id && ($is_custom("pattern_id", "string", "", input.id) || $guard(_exceptionable, {
                    path: _path + ".id",
                    expected: "string (@pattern_id)",
                    value: input.id
                })) || $guard(_exceptionable, {
                    path: _path + ".id",
                    expected: "string",
                    value: input.id
                })) && ("string" === typeof input.name || $guard(_exceptionable, {
                    path: _path + ".name",
                    expected: "string",
                    value: input.name
                })) && ("OWNER" === input.role || "ADMIN" === input.role || "MEMBER" === input.role || "SERVICEACCOUNT" === input.role || $guard(_exceptionable, {
                    path: _path + ".role",
                    expected: "(\"ADMIN\" | \"MEMBER\" | \"OWNER\" | \"SERVICEACCOUNT\")",
                    value: input.role
                })) && (3 === Object.keys(input).length || (false === _exceptionable || Object.keys(input).every((key: any) => {
                    if (["id", "name", "role"].some((prop: any) => key === prop))
                        return true;
                    const value = input[key];
                    if (undefined === value)
                        return true;
                    return $guard(_exceptionable, {
                        path: _path + $join(key),
                        expected: "undefined",
                        value: value
                    });
                })));
                const $ao2 = (input: any, _path: string, _exceptionable: boolean = true): boolean => (undefined === input.locale || "string" === typeof input.locale || $guard(_exceptionable, {
                    path: _path + ".locale",
                    expected: "(string | undefined)",
                    value: input.locale
                })) && (undefined === input.dateFormat || "string" === typeof input.dateFormat || $guard(_exceptionable, {
                    path: _path + ".dateFormat",
                    expected: "(string | undefined)",
                    value: input.dateFormat
                })) && (undefined === input.numberFormat || "string" === typeof input.numberFormat || $guard(_exceptionable, {
                    path: _path + ".numberFormat",
                    expected: "(string | undefined)",
                    value: input.numberFormat
                })) && (false === _exceptionable || Object.keys(input).every((key: any) => {
                    if (["locale", "dateFormat", "numberFormat"].some((prop: any) => key === prop))
                        return true;
                    const value = input[key];
                    if (undefined === value)
                        return true;
                    if (RegExp(/(.*)/).test(key))
                        return true;
                    return $guard(_exceptionable, {
                        path: _path + $join(key),
                        expected: "undefined",
                        value: value
                    });
                }));
                return ("object" === typeof input && null !== input || $guard(true, {
                    path: _path + "",
                    expected: "ProfileMeData",
                    value: input
                })) && $ao0(input, _path + "", true) || $guard(true, {
                    path: _path + "",
                    expected: "ProfileMeData",
                    value: input
                });
            })(input, "$input", true);
        return input;
    })(input);
};
export const ProfileMeDataTypiaAssertStringify = (input: ProfileMeData): string => {
    return ((input: any): string => { const assert = (input: any): ProfileMeData => {
        const __is = (input: any): input is ProfileMeData => {
            const $is_custom = (typia.createAssertStringify as any).is_custom;
            const $join = (typia.createAssertStringify as any).join;
            const $io0 = (input: any): boolean => "string" === typeof input.id && ("FREE" === input.userType || "PROFESSIONAL" === input.userType || "ENTERPRISE" === input.userType || "ADMIN" === input.userType) && "string" === typeof input.email && (undefined === input.nickname || "string" === typeof input.nickname) && (undefined === input.name || "string" === typeof input.name) && (undefined === input.givenName || "string" === typeof input.givenName) && (undefined === input.familyName || "string" === typeof input.familyName) && (undefined === input.token || "string" === typeof input.token && 5 <= input.token.length) && ("number" === typeof input.createdAt && !Number.isNaN(input.createdAt) && 0 <= input.createdAt) && (undefined === input.teams || Array.isArray(input.teams) && input.teams.every((elem: any) => "object" === typeof elem && null !== elem && $io1(elem))) && ("object" === typeof input.settings && null !== input.settings && false === Array.isArray(input.settings) && $io2(input.settings)) && (undefined === input.onboarding || "number" === typeof input.onboarding && !Number.isNaN(input.onboarding));
            const $io1 = (input: any): boolean => "string" === typeof input.id && $is_custom("pattern_id", "string", "", input.id) && "string" === typeof input.name && ("OWNER" === input.role || "ADMIN" === input.role || "MEMBER" === input.role || "SERVICEACCOUNT" === input.role);
            const $io2 = (input: any): boolean => (undefined === input.locale || "string" === typeof input.locale) && (undefined === input.dateFormat || "string" === typeof input.dateFormat) && (undefined === input.numberFormat || "string" === typeof input.numberFormat) && Object.keys(input).every((key: any) => {
                if (["locale", "dateFormat", "numberFormat"].some((prop: any) => key === prop))
                    return true;
                const value = input[key];
                if (undefined === value)
                    return true;
                if (RegExp(/(.*)/).test(key))
                    return true;
                return true;
            });
            return "object" === typeof input && null !== input && $io0(input);
        };
        if (false === __is(input))
            ((input: any, _path: string, _exceptionable: boolean = true): input is ProfileMeData => {
                const $guard = (typia.createAssertStringify as any).guard;
                const $is_custom = (typia.createAssertStringify as any).is_custom;
                const $join = (typia.createAssertStringify as any).join;
                const $ao0 = (input: any, _path: string, _exceptionable: boolean = true): boolean => ("string" === typeof input.id || $guard(_exceptionable, {
                    path: _path + ".id",
                    expected: "string",
                    value: input.id
                })) && ("FREE" === input.userType || "PROFESSIONAL" === input.userType || "ENTERPRISE" === input.userType || "ADMIN" === input.userType || $guard(_exceptionable, {
                    path: _path + ".userType",
                    expected: "(\"ADMIN\" | \"ENTERPRISE\" | \"FREE\" | \"PROFESSIONAL\")",
                    value: input.userType
                })) && ("string" === typeof input.email || $guard(_exceptionable, {
                    path: _path + ".email",
                    expected: "string",
                    value: input.email
                })) && (undefined === input.nickname || "string" === typeof input.nickname || $guard(_exceptionable, {
                    path: _path + ".nickname",
                    expected: "(string | undefined)",
                    value: input.nickname
                })) && (undefined === input.name || "string" === typeof input.name || $guard(_exceptionable, {
                    path: _path + ".name",
                    expected: "(string | undefined)",
                    value: input.name
                })) && (undefined === input.givenName || "string" === typeof input.givenName || $guard(_exceptionable, {
                    path: _path + ".givenName",
                    expected: "(string | undefined)",
                    value: input.givenName
                })) && (undefined === input.familyName || "string" === typeof input.familyName || $guard(_exceptionable, {
                    path: _path + ".familyName",
                    expected: "(string | undefined)",
                    value: input.familyName
                })) && (undefined === input.token || "string" === typeof input.token && (5 <= input.token.length || $guard(_exceptionable, {
                    path: _path + ".token",
                    expected: "string (@minLength 5)",
                    value: input.token
                })) || $guard(_exceptionable, {
                    path: _path + ".token",
                    expected: "(string | undefined)",
                    value: input.token
                })) && ("number" === typeof input.createdAt && !Number.isNaN(input.createdAt) && (0 <= input.createdAt || $guard(_exceptionable, {
                    path: _path + ".createdAt",
                    expected: "number (@minimum 0)",
                    value: input.createdAt
                })) || $guard(_exceptionable, {
                    path: _path + ".createdAt",
                    expected: "number",
                    value: input.createdAt
                })) && (undefined === input.teams || (Array.isArray(input.teams) || $guard(_exceptionable, {
                    path: _path + ".teams",
                    expected: "(Array<TeamBasicData> | undefined)",
                    value: input.teams
                })) && input.teams.every((elem: any, _index1: number) => ("object" === typeof elem && null !== elem || $guard(_exceptionable, {
                    path: _path + ".teams[" + _index1 + "]",
                    expected: "TeamBasicData",
                    value: elem
                })) && $ao1(elem, _path + ".teams[" + _index1 + "]", true && _exceptionable) || $guard(_exceptionable, {
                    path: _path + ".teams[" + _index1 + "]",
                    expected: "TeamBasicData",
                    value: elem
                })) || $guard(_exceptionable, {
                    path: _path + ".teams",
                    expected: "(Array<TeamBasicData> | undefined)",
                    value: input.teams
                })) && (("object" === typeof input.settings && null !== input.settings && false === Array.isArray(input.settings) || $guard(_exceptionable, {
                    path: _path + ".settings",
                    expected: "ProfileMeDataSettings",
                    value: input.settings
                })) && $ao2(input.settings, _path + ".settings", true && _exceptionable) || $guard(_exceptionable, {
                    path: _path + ".settings",
                    expected: "ProfileMeDataSettings",
                    value: input.settings
                })) && (undefined === input.onboarding || "number" === typeof input.onboarding && !Number.isNaN(input.onboarding) || $guard(_exceptionable, {
                    path: _path + ".onboarding",
                    expected: "(number | undefined)",
                    value: input.onboarding
                }));
                const $ao1 = (input: any, _path: string, _exceptionable: boolean = true): boolean => ("string" === typeof input.id && ($is_custom("pattern_id", "string", "", input.id) || $guard(_exceptionable, {
                    path: _path + ".id",
                    expected: "string (@pattern_id)",
                    value: input.id
                })) || $guard(_exceptionable, {
                    path: _path + ".id",
                    expected: "string",
                    value: input.id
                })) && ("string" === typeof input.name || $guard(_exceptionable, {
                    path: _path + ".name",
                    expected: "string",
                    value: input.name
                })) && ("OWNER" === input.role || "ADMIN" === input.role || "MEMBER" === input.role || "SERVICEACCOUNT" === input.role || $guard(_exceptionable, {
                    path: _path + ".role",
                    expected: "(\"ADMIN\" | \"MEMBER\" | \"OWNER\" | \"SERVICEACCOUNT\")",
                    value: input.role
                }));
                const $ao2 = (input: any, _path: string, _exceptionable: boolean = true): boolean => (undefined === input.locale || "string" === typeof input.locale || $guard(_exceptionable, {
                    path: _path + ".locale",
                    expected: "(string | undefined)",
                    value: input.locale
                })) && (undefined === input.dateFormat || "string" === typeof input.dateFormat || $guard(_exceptionable, {
                    path: _path + ".dateFormat",
                    expected: "(string | undefined)",
                    value: input.dateFormat
                })) && (undefined === input.numberFormat || "string" === typeof input.numberFormat || $guard(_exceptionable, {
                    path: _path + ".numberFormat",
                    expected: "(string | undefined)",
                    value: input.numberFormat
                })) && (false === _exceptionable || Object.keys(input).every((key: any) => {
                    if (["locale", "dateFormat", "numberFormat"].some((prop: any) => key === prop))
                        return true;
                    const value = input[key];
                    if (undefined === value)
                        return true;
                    if (RegExp(/(.*)/).test(key))
                        return true;
                    return true;
                }));
                return ("object" === typeof input && null !== input || $guard(true, {
                    path: _path + "",
                    expected: "ProfileMeData",
                    value: input
                })) && $ao0(input, _path + "", true) || $guard(true, {
                    path: _path + "",
                    expected: "ProfileMeData",
                    value: input
                });
            })(input, "$input", true);
        return input;
    }; const stringify = (input: ProfileMeData): string => {
        const $io1 = (input: any): boolean => "string" === typeof input.id && $is_custom("pattern_id", "string", "", input.id) && "string" === typeof input.name && ("OWNER" === input.role || "ADMIN" === input.role || "MEMBER" === input.role || "SERVICEACCOUNT" === input.role);
        const $io2 = (input: any): boolean => (undefined === input.locale || "string" === typeof input.locale) && (undefined === input.dateFormat || "string" === typeof input.dateFormat) && (undefined === input.numberFormat || "string" === typeof input.numberFormat) && Object.keys(input).every((key: any) => {
            if (["locale", "dateFormat", "numberFormat"].some((prop: any) => key === prop))
                return true;
            const value = input[key];
            if (undefined === value)
                return true;
            if (RegExp(/(.*)/).test(key))
                return true;
            return true;
        });
        const $string = (typia.createAssertStringify as any).string;
        const $throws = (typia.createAssertStringify as any).throws;
        const $join = (typia.createAssertStringify as any).join;
        const $tail = (typia.createAssertStringify as any).tail;
        const $is_custom = (typia.createAssertStringify as any).is_custom;
        const $so0 = (input: any): any => `{${undefined === input.nickname ? "" : `"nickname":${undefined !== input.nickname ? $string(input.nickname) : undefined},`}${undefined === input.name ? "" : `"name":${undefined !== input.name ? $string(input.name) : undefined},`}${undefined === input.givenName ? "" : `"givenName":${undefined !== input.givenName ? $string(input.givenName) : undefined},`}${undefined === input.familyName ? "" : `"familyName":${undefined !== input.familyName ? $string(input.familyName) : undefined},`}${undefined === input.token ? "" : `"token":${undefined !== input.token ? $string(input.token) : undefined},`}${undefined === input.teams ? "" : `"teams":${undefined !== input.teams ? `[${input.teams.map((elem: any) => $so1(elem)).join(",")}]` : undefined},`}${undefined === input.onboarding ? "" : `"onboarding":${undefined !== input.onboarding ? input.onboarding : undefined},`}"id":${$string(input.id)},"userType":${(() => {
            if ("string" === typeof input.userType)
                return $string(input.userType);
            if ("string" === typeof input.userType)
                return "\"" + input.userType + "\"";
            $throws({
                expected: "(\"ADMIN\" | \"ENTERPRISE\" | \"FREE\" | \"PROFESSIONAL\")",
                value: input.userType
            });
        })()},"email":${$string(input.email)},"createdAt":${input.createdAt},"settings":${$so2(input.settings)}}`;
        const $so1 = (input: any): any => `{"id":${$string(input.id)},"name":${$string(input.name)},"role":${(() => {
            if ("string" === typeof input.role)
                return $string(input.role);
            if ("string" === typeof input.role)
                return "\"" + input.role + "\"";
            $throws({
                expected: "(\"ADMIN\" | \"MEMBER\" | \"OWNER\" | \"SERVICEACCOUNT\")",
                value: input.role
            });
        })()}}`;
        const $so2 = (input: any): any => `{${$tail(`${undefined === input.locale ? "" : `"locale":${undefined !== input.locale ? $string(input.locale) : undefined},`}${undefined === input.dateFormat ? "" : `"dateFormat":${undefined !== input.dateFormat ? $string(input.dateFormat) : undefined},`}${undefined === input.numberFormat ? "" : `"numberFormat":${undefined !== input.numberFormat ? $string(input.numberFormat) : undefined},`}${Object.entries(input).map(([key, value]: [string, any]) => { if (undefined === value)
            return ""; if (["locale", "dateFormat", "numberFormat"].some((regular: any) => regular === key))
            return ""; return `${JSON.stringify(key)}:${undefined !== value ? JSON.stringify(value) : undefined}`; }).filter((str: any) => "" !== str).join(",")}`)}}`;
        return $so0(input);
    }; return stringify(assert(input)); })(input);
};
export const ProfileMeDataValidatorShape = {
    'id': yup.string().defined(),
    'userType': UserTypeValidator.defined(),
    'email': yup.string().defined(),
    'nickname': yup.string(),
    'name': yup.string(),
    'givenName': yup.string(),
    'familyName': yup.string(),
    'token': yup.string().min(5),
    'createdAt': yup.number().min(0).defined(),
    'teams': yup.array().of(TeamBasicDataValidator),
    'settings': ProfileMeDataSettingsValidator.defined(),
    'onboarding': yup.number(),
};
export const ProfileMeDataValidator = yup.object().shape(ProfileMeDataValidatorShape);
export const ProfileMeDataStringifyShapeProperties: ObjectSchema['properties'] = {
    'id': {
        'type': 'string',
    } as unknown as fastJSON.StringSchema,
    'userType': UserTypeStringifyShape,
    'email': {
        'type': 'string',
    } as unknown as fastJSON.StringSchema,
    'nickname': {
        'type': 'string',
    } as unknown as fastJSON.StringSchema,
    'name': {
        'type': 'string',
    } as unknown as fastJSON.StringSchema,
    'givenName': {
        'type': 'string',
    } as unknown as fastJSON.StringSchema,
    'familyName': {
        'type': 'string',
    } as unknown as fastJSON.StringSchema,
    'token': {
        'type': 'string',
        'minLength': 5,
    } as unknown as fastJSON.StringSchema,
    'createdAt': {
        'type': 'number',
        'minimum': 0,
    } as unknown as fastJSON.NumberSchema,
    'teams': {
        type: 'array',
        items: {
            TeamBasicDataStringifyShape
        }
    },
    'settings': ProfileMeDataSettingsStringifyShape,
    'onboarding': {
        'type': 'number',
    } as unknown as fastJSON.NumberSchema,
};
export const ProfileMeDataStringifyShape: ObjectSchema = {
    title: 'ProfileMeData Stringifier',
    type: 'object',
    properties: {
        ...ProfileMeDataStringifyShapeProperties
    },
    required: [
        'id',
        'userType',
        'email',
        'createdAt',
        'settings',
    ],
};
export const ProfileMeDataStringifyCompiled = (typeof process === 'object' && String(process) === '[object process]') ?
    fastJSON.build(ProfileMeDataStringifyShape, {
        ajv: {
            // since we are already doing this with yup, lets skip
            validateSchema: false,
            ownProperties: true,
            validateFormats: false,
        },
        largeArrayMechanism: 'json-stringify',
        largeArraySize: 1000,
    } as Options)
    :
        () => { throw new Error('fast-json-stringify only works in nodejs'); };
export const ProfileMeDataStringify: (data: ProfileMeData) => string = ProfileMeDataStringifyCompiled;
/* LIGHTLY CUSTOM END -> Generic */
export function ProfileMeDataFromJSON(json: any): ProfileMeData {
    return ProfileMeDataFromJSONTyped(json, false);
}
export function ProfileMeDataFromJSONTyped(json: any, ignoreDiscriminator: boolean): ProfileMeData {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    /* causes circular depdendencies
*/
    return {
        'id': json['id'],
        'userType': UserTypeFromJSON(json['userType']),
        'email': json['email'],
        'nickname': !exists(json, 'nickname') ? undefined : json['nickname'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'givenName': !exists(json, 'givenName') ? undefined : json['givenName'],
        'familyName': !exists(json, 'familyName') ? undefined : json['familyName'],
        'token': !exists(json, 'token') ? undefined : json['token'],
        'createdAt': json['createdAt'],
        'teams': !exists(json, 'teams') ? undefined : ((json['teams'] as Array<any> || []).map(TeamBasicDataFromJSON)),
        'settings': ProfileMeDataSettingsFromJSON(json['settings']),
        'onboarding': !exists(json, 'onboarding') ? undefined : json['onboarding'],
    };
}
export function ProfileMeDataToJSON(value?: ProfileMeData | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'id': value.id,
        'userType': UserTypeToJSON(value.userType),
        'email': value.email,
        'nickname': value.nickname,
        'name': value.name,
        'givenName': value.givenName,
        'familyName': value.familyName,
        'token': value.token,
        'createdAt': value.createdAt,
        'teams': value.teams === undefined ? undefined : ((value.teams as Array<any> || []).map(TeamBasicDataToJSON)),
        'settings': ProfileMeDataSettingsToJSON(value.settings),
        'onboarding': value.onboarding,
    };
}
