export const sampling = {
	'field_embeddingId': 'Select embedding',
	'field_samplingCreateRequest.method': 'Selection strategy',
	'field_samplingCreateRequest.newTagName': 'Name',

	'field_samplingCreateRequest.config.stoppingCondition.nSamples': 'Amount of samples',
	'field_samplingCreateRequest.config.stoppingCondition.nSamples.CORESET': 'Amount of samples to reduce to',
	'field_samplingCreateRequest.config.stoppingCondition.nSamples.RANDOM': 'Amount of samples to reduce to',

	'field_samplingCreateRequest.config.stoppingCondition.minDistance': 'Minimum distance between two samples',
}
export default sampling;