import React, { useCallback, useState, useRef } from 'react';

import DatasetIcon from '@mui/icons-material/Dataset';
import PsychologyAltIcon from '@mui/icons-material/PsychologyAlt';
import { IconAnalyze } from '@tabler/icons-react';
import Gravatar from 'gravatar-url'
import { Link, useHistory } from 'react-router-dom';
import { makeStyles } from 'tss-react/mui';


import { Avatar, Typography, AppBar, Button, Menu, MenuItem, Toolbar, Theme, Tooltip } from '@mui/material';

import { LightlyButton } from '../common/Button';
import { useAuth0 } from '../contexts/auth0-context';
import mixpanel from '../contexts/mixpanel';
import { useRedirectForOnboarding } from '../pages/onboarding/onboarding';
import { datasetOverviewRoute, dockerRunsOverviewRoute, homeRoute, preferencesRoute, signinRoute, signoutRoute } from '../routes';
import { makeRouteURL } from '../routes.fn';
import LogoIcon from '../static/LightlyIcon.png';
import Logo from '../static/LightlyLogo.png';

const useStyles = makeStyles<void, 'title'>()((theme: Theme, _params, classes) => ({
	root: {
		flexGrow: 1,
	},
	title: {
		textDecoration: 'none',
		'& img:last-of-type': {
			display: 'none',
		},

		[theme.breakpoints.down('lg')]: {
			paddingRight: '1rem',
		},
		[theme.breakpoints.down('md')]: {
			paddingRight: theme.spacing(3),

			'& img:first-of-type': {
				display: 'none',
			},
			'& img:last-of-type': {
				display: 'initial',
				marginLeft: '-4px',
    			marginTop: '2px',
			},
		},
		[theme.breakpoints.up('lg')]: {
			minWidth: 'calc(280px - 24px)',
			width: 'calc(16.666667% - 16px)',
		},
	},
	signedOut:{
		[`& .${classes.title}`]: {
			'& img:first-of-type': {
				display: 'initial',
			},
			'& img:last-of-type': {
				display: 'none',
			},
		},
	},
	tagPortal: {
		flexGrow: 1,
		display: 'flex',
		[theme.breakpoints.down('lg')]: {
			justifyContent: 'center',
		},
		[theme.breakpoints.down('md')]: {
			justifyContent: 'initial',
		},
	},
	navbar: {
		backgroundColor: theme.palette.lightlyDarkBG.main,
		transition: 'none',
		'& .MuiToolbar-root':{
			[theme.breakpoints.down('lg')]: {
				paddingRight: '0.5rem',
			},
		},
	},
	navA: {
		textDecoration: 'none',
		color: '#FFFFFF',
		fontWeight: 'bold',
		boxSizing: 'border-box',
		margin: `0 ${theme.spacing(.5)} 0 ${theme.spacing(.5)}`,
		'&:hover label:not(.Mui-disabled)': {
			borderBottom: `${theme.spacing(.5)} solid ${theme.palette.lightlyBrandTurquoise.main}`,
			marginBottom: theme.spacing(-.5),
		},
		'&:last-of-type': {
			marginRight: 0,
		},

		'.MuiMenu-paper &': {
			width: '100%',
			marginLeft: theme.spacing(3),
			marginRight: theme.spacing(3),
		},
		'.MuiMenu-paper li:hover &': {
			borderBottom: `${theme.spacing(.5)} solid ${theme.palette.lightlyBrandTurquoise.main}`,
			marginBottom: theme.spacing(-.5),
		},


		// adjust font size of navigation to give some more leeway..
		'& p': {
			transition: 'font-size .3s ease-in-out',
			fontWeight: '500',
		},
		[theme.breakpoints.down('xl')]: {
			'& p': {
				fontSize: '.9rem',
			},
		},
		[theme.breakpoints.down('xl')]: {
			'& p': {
				fontSize: '.8rem',
			},
		},
		[theme.breakpoints.down('lg')]: {
			'& p': {
				fontSize: '.8rem',
			},
			'& label':{
				paddingLeft: '8px',
				paddingRight: '8px',
			},
		},
		[theme.breakpoints.down('md')]: {
			'& p': {
				fontSize: '.7rem',
			},
			'& label':{
				paddingLeft: '4px',
				paddingRight: '4px',
			},
		},
	},
	navUser:{
		'& .MuiTypography-root':{
			display: 'block',
		},
		[theme.breakpoints.up('md')]: {
			maxWidth: '12rem',
		},
		[theme.breakpoints.up('lg')]: {
			maxWidth: '15rem',
		},
		[theme.breakpoints.up('xl')]: {
			maxWidth: '30rem',
		},
		[theme.breakpoints.down('lg')]: {
			'& .MuiTypography-root':{
				display: 'none',
			},
			'&.MuiButton-root': {
				minWidth: '32px',
			},
			'& .MuiAvatar-root':{
				marginLeft: '0',
			},
		},
	},
	avatar: {
		marginLeft: theme.spacing(1),
		width: theme.spacing(4),
		height: theme.spacing(4),
	},
	navItem: {
		'&.Mui-disabled':{
			pointerEvents:'auto',
			cursor: 'not-allowed',
		},
		'& *': {
			color: '#FFFFFF',
		},
	},
	menuPaper: {
		backgroundColor: theme.palette.lightlyDarkBG.main,
	},
}));

export const Navbar: React.FC<{gotToken: boolean}> = ({ gotToken }) => {
	const { isLoading, user } = useAuth0();
	const anchorElRef = useRef(null);
	const [anchorOpen, setAnchorOpen] = useState<boolean>(false);
	const { classes, cx } = useStyles();

	const history = useHistory();

	const handleOpenMenuClick = useCallback((event: React.MouseEvent<HTMLLabelElement>) => {
		setAnchorOpen(true);
		mixpanel.track('click: usermenu', { ref: 'nav', origEventName: 'usermenu' });
		event.preventDefault();
	}, []);

	const handleMenuClose = useCallback((event: React.MouseEvent<HTMLButtonElement>) => {
		setAnchorOpen(false);
		event.preventDefault();
	}, []);

	const signedOut = !isLoading && !user;

	// check if the user can already navigate to the overview pages
	const canDockerRunsOverviewRoute = !useRedirectForOnboarding(gotToken, dockerRunsOverviewRoute).path;
	const canDatasetOverviewRoute = !useRedirectForOnboarding(gotToken, datasetOverviewRoute).path;

	return (
		<header className={classes.root}>
			<AppBar id='mainNav' position='fixed' className={cx(classes.navbar, signedOut && classes.signedOut)}>
				<Toolbar>
					<Link
						className={cx(classes.title)}
						to={makeRouteURL(homeRoute, null)}
						onClick={() => mixpanel.track('click: home', { ref: 'nav', origEventName: 'home' })}
					>
						<img src={Logo} alt='Lightly' title='Lightly' height={24} />
						<img src={LogoIcon} alt='Lightly' title='Lightly' height={29} />
					</Link>
					<div id='tagPortal' className={classes.tagPortal}>
						{' '}
					</div>
					{!isLoading && user && (
						<Link
							className={cx(classes.navA)}
							to={{ pathname: 'https://docs.lightly.ai/docs' }}
							onClick={() => mixpanel.track('click: documentation', { ref: 'nav', origEventName: 'documentation' })}
							target='_blank'
							rel='noopener'
						>
							<Button size='medium' component='label' className={cx(classes.navItem)} startIcon={<PsychologyAltIcon />}>
								<Typography noWrap>
									Docs
								</Typography>
							</Button>
						</Link>
					)}
					{!isLoading && user && (
						<Link
							className={cx(classes.navA)}
							to={makeRouteURL(dockerRunsOverviewRoute, null)}
							onClick={() => mixpanel.track('click: my runs', { ref: 'nav', origEventName: 'my runs' })}
						>
							<Tooltip arrow title={!canDockerRunsOverviewRoute ? 'Please complete one of our getting started guides to active the full UI' : ''}>
								<Button disabled={!canDockerRunsOverviewRoute} size='medium' component='label' className={cx(classes.navItem)} startIcon={<IconAnalyze size={20} />}>
									<Typography noWrap>
										Runs
									</Typography>
								</Button>
							</Tooltip>
						</Link>
					)}
					{!isLoading && user && (
						<Link
							className={cx(classes.navA)}
							to={makeRouteURL(datasetOverviewRoute, null)}
							onClick={() => mixpanel.track('click: my datasets', { ref: 'nav', origEventName: 'my datasets' })}
						>
							<Tooltip arrow title={!canDatasetOverviewRoute ? 'Please complete one of our getting started guides to active the full UI' : ''}>
								<Button disabled={!canDatasetOverviewRoute} size='medium' component='label' className={cx(classes.navItem)} startIcon={<DatasetIcon />}>
									<Typography noWrap>
										Datasets
									</Typography>
								</Button>
							</Tooltip>
						</Link>
					)}
					{signedOut && (
						<Link ref={anchorElRef}
							to={makeRouteURL(signinRoute, null)}
							onClick={() => {
								mixpanel.track('click: login', { ref: 'nav', origEventName: 'login' } );
							}}
						>
							<LightlyButton size='medium'>
								<Typography noWrap>
									Sign In
								</Typography>
							</LightlyButton>
						</Link>
					)}
					{!isLoading && user &&
						<Link ref={anchorElRef}
							className={cx(classes.navA)}
							to={makeRouteURL(homeRoute, null)}
						>
							<div>
								<Button
									aria-controls='simple-menu'
									aria-haspopup='true'
									className={cx(classes.navUser, classes.navItem)}
									component='label'
									onClick={handleOpenMenuClick}
								>
									<Typography noWrap>
										{user.email}
									</Typography>
									<Avatar alt={user.name || user.email} src={user.picture || (user.email && Gravatar(user.email, { size: 100, default: 'identicon' }))} className={classes.avatar} />
								</Button>
								<Menu
									id='simple-menu'
									anchorEl={anchorElRef.current}
									keepMounted
									open={anchorOpen}
									onClose={handleMenuClose}

									anchorOrigin={{
										vertical: 'bottom',
										horizontal: 'right',
									}}
									transformOrigin={{
										vertical: 'top',
										horizontal: 'right',
									}}
									classes={{ paper: classes.menuPaper }}
								>
									<MenuItem disableGutters onClick={(e) => {
										mixpanel.track('click: preferences', { ref: 'nav', origEventName: 'preferences' });
										setAnchorOpen(false);
										history.push(makeRouteURL(preferencesRoute, null))
										e.preventDefault();
										return false
									}}
									>
										<Link className={cx(classes.navA)} to={makeRouteURL(preferencesRoute, null)}>
											<Button
												size='medium'
												variant='text'
												component='label'
												className={cx(classes.navItem)}
											>
												<Typography noWrap>
													Preferences
												</Typography>
											</Button>
										</Link>
									</MenuItem>

									<MenuItem disableGutters onClick={(e) => {
										mixpanel.track('click: logout', { ref: 'nav', origEventName: 'logout' });
										setAnchorOpen(false);
										history.push(makeRouteURL(signoutRoute, null))
										e.preventDefault();
										return false
									}}
									>
										<Link className={cx(classes.navA)} to={makeRouteURL(signoutRoute, null)}>
											<Button
												size='medium'
												variant='text'
												component='label'
												className={cx(classes.navItem)}
											>
												<Typography noWrap>
													Logout
												</Typography>
											</Button>
										</Link>
									</MenuItem>
								</Menu>
							</div>
						</Link>
					}
				</Toolbar>
			</AppBar>
		</header>
	);
};