import { noop } from 'lodash';
import mixpanel, { OverridedMixpanel } from 'mixpanel-browser';

let mix = mixpanel

if ( String(process.env.REACT_APP_MIXPANEL_TRACK_USER) === 'true') {
	mixpanel.init(
		`${process.env.REACT_APP_MIXPANEL_TOKEN || 'REACT_APP_MIXPANEL_TOKEN not set'}`,
		{
			// this can't be changed: https://help.mixpanel.com/hc/en-us/articles/360039135652-Data-Residency-in-EU
			// api_host: 'https://api-eu.mixpanel.com',
		},
	);

	if (process.env.NODE_ENV !== 'production') {
		mixpanel.set_config({ debug:true })
	}
}
else {
	mix = {
		track: noop,
		identify: noop,
		add_group: noop,
		set_group: noop,
		people:{
			set: noop,
			unset: noop,
			increment: noop,
		},
	} as  OverridedMixpanel
}

export default mix;