import React from 'react';

import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { ButtonProps } from '@mui/material/Button';
import { withStyles, makeStyles } from 'tss-react/mui';

import { Theme, IconButton, IconButtonProps, alpha, darken, Button, keyframes } from '@mui/material';



export const LightlyButton = withStyles(Button, (theme: Theme) => ({
	root: {
		backgroundImage: `linear-gradient(white, white), linear-gradient(to bottom, ${theme.palette.lightlyBrandGreen.main}AA, ${theme.palette.lightlyBrandTurquoise.main}AA, ${theme.palette.lightlyBrandBlue.main}AA)`,
		border: '2px solid transparent',
		backgroundOrigin: 'border-box',
		backgroundClip: 'padding-box, border-box',
		// padding: 0,
		color: theme.palette.lightlyLightTitle.main,
		transition: 'initial',
		position: 'relative',
		'&:hover': {
			borderWidth: '2px',
			color: theme.palette.lightlyDarkText.main,
			backgroundImage: `
			linear-gradient(${theme.palette.lightlyPurpleSpecial.light}00, ${theme.palette.lightlyPurpleSpecial.light}00),
			linear-gradient(to bottom, ${theme.palette.lightlyBrandGreen.main}, ${theme.palette.lightlyBrandTurquoise.main}, ${theme.palette.lightlyBrandBlue.main})
			`,
			'& *': {
				color: theme.palette.lightlyDarkText.main,
			},
		},
		'& .MuiTouchRipple-root': {
			borderRadius: 0,
		},
	},
	outlined: {
		'&.Mui-disabled': {
			border: `2px solid ${theme.palette.grey['A100']}AA`,
		},
	},
	disabled: {
	},
	label: {
		position: 'relative',
		padding: '5px 15px', // from material-ui Button spec
	},
}));

export const LightlyButtonSubscription = withStyles(Button, (theme: Theme) => ({
	root: {
		border: '2px solid transparent',
		backgroundOrigin: 'border-box',
		backgroundClip: 'padding-box, border-box',
		transition: 'initial',
		position: 'relative',
		color: theme.palette.lightlyDarkText.main,
		backgroundImage: `
		linear-gradient(${theme.palette.lightlyPurpleSpecial.light}00, ${theme.palette.lightlyPurpleSpecial.light}00),
		linear-gradient(to bottom, ${theme.palette.lightlyBrandGreen.main}, ${theme.palette.lightlyBrandTurquoise.main}, ${theme.palette.lightlyBrandBlue.main})
		`,
		'&:hover': {
			borderColor: 'black',
		},
		'& .MuiTouchRipple-root': {
			borderRadius: 0,
		},
	},
	outlined: {
		'&.Mui-disabled': {
			border: `2px solid ${theme.palette.grey['A100']}AA`,
		},
	},
	disabled: {
	},
	label: {
		position: 'relative',
		padding: '5px 15px', // from material-ui Button spec
	},
}));

export const LightlyButtonLight = withStyles(Button, (theme: Theme) => ({
	root: {
		backgroundImage: `linear-gradient(white, white), linear-gradient(to bottom, ${theme.palette.lightlyBrandGreen.main}AA, ${theme.palette.lightlyBrandTurquoise.main}AA, ${theme.palette.lightlyBrandBlue.main}AA)`,
		border: '2px solid transparent',
		backgroundOrigin: 'border-box',
		backgroundClip: 'padding-box, border-box',
		color: theme.palette.lightlyLightTitle.main,
		'&:hover': {
			border: '2px solid transparent',
			backgroundImage: `
			linear-gradient(#FFFFFFEE, #FFFFFFEE),
				linear-gradient(to bottom, ${theme.palette.lightlyBrandGreen.main}, ${theme.palette.lightlyBrandTurquoise.main}, ${theme.palette.lightlyBrandBlue.main})
			`,
			'& .MuiSvgIcon-root': {
				fill: 'url(#lightlyGradient)',
			},
		},
		'& .MuiTouchRipple-root': {
			borderRadius: 0,
		},
	},
	outlined: {
		'&.Mui-disabled': {
			border: `2px solid ${theme.palette.grey['A100']}`,
		},
	},
	disabled: {
	},
	label: {
		padding: '5px 15px', // from material-ui Button spec
	},
}));




export const LightlyDangerButton = withStyles((props: ButtonProps) => <Button {...props} variant='outlined' />, () => ({
	root: {
		color: 'rgb(211, 47, 47)',
		borderColor: alpha('rgb(211, 47, 47)', .5),
		'&:hover': {
			backgroundColor: alpha('rgb(211, 47, 47)', .04),
			borderColor: darken('rgb(211, 47, 47)', .1),
		},
	},
}));




const rotate = keyframes`
	to {
		transform: rotate(360deg);
	}
`

const pulse = keyframes`
	from {
		transform: scale3d(1, 1, 1);
	}
	45% {
		transform: scale3d(1.15, 1.15, 1.15);
	}
	to {
		transform: scale3d(1, 1, 1);
	}
`;

export const LightlyIconButton = withStyles(IconButton, () => ({
	root: {
		'&:hover': {

			'& .MuiSvgIcon-root': {
				fill: 'url(#lightlyGradient)',
			},

			'& .tabler-icon *': {
				stroke: 'url(#lightlyGradient) !important',
				fill: 'url(#lightlyGradient) !important',
			},
			// the icon-analyze icon used for dockerRun info has issues with filling the icon with a gradient
			'& .tabler-icon.tabler-icon-analyze *':{
				fill: 'transparent !important',
			},
		},
		'&.animateRotate': {
			animation: `${rotate} 1000ms ease infinite`,
		},
		'&.animatePulse': {
			animation: `${pulse} 1000ms ease infinite`,
		},
		'&.active': {
			'& .MuiSvgIcon-root': {
				fill: 'url(#lightlyGradient)',
			},

			'& .tabler-icon *': {
				stroke: 'url(#lightlyGradient) !important',
			},
		},
	},
}));




const useLightlyIconStateButtonStyles = makeStyles<void, 'active'>()((theme: Theme, _params, classes) => ({
	root: {
		'& div': {
			fontFamily: 'sans-serif',
			width: '24px',
			height: '24px',
			fontWeight: 'bolder',
		},
		[`&.${classes.active} div`]: {
			backgroundImage: `linear-gradient(to bottom, ${theme.palette.lightlyBrandGreen.main}AA, ${theme.palette.lightlyBrandTurquoise.main}AA, ${theme.palette.lightlyBrandBlue.main}AA)`,
			backgroundSize: '100%',

			backgroundClip: 'text',
			textFillColor: 'transparent',
			WebkitBackgroundClip: 'text',
			WebkitTextFillColor: 'transparent',
		},
	},
	nobg: {
		'&:hover': {
			backgroundColor: 'initial',
		},
	},
	active: {
		'& .MuiSvgIcon-root': {
			fill: 'url(#lightlyGradient)',
		},

		'& .tabler-icon *': {
			stroke: 'url(#lightlyGradient) !important',
		},
	},
}));

export const LightlyIconStateButtonMinimal: React.FC<{ active: boolean; } & IconButtonProps> = (props) => {
	const { classes, cx } = useLightlyIconStateButtonStyles();
	const { children, active, className, ...restProps } = props;
	return (
		<IconButton size='small' disableRipple classes={{ root: classes.root }} className={cx(classes.nobg, active ? classes.active : '')} {...restProps}>
			{children}
		</IconButton>
	);
};


export const LightlyIconStateButton: React.FC<{ active: boolean; } & IconButtonProps> = (props) => {
	const { classes } = useLightlyIconStateButtonStyles();
	const { children, active, className, ...restProps } = props;
	return (
		<IconButton
			classes={{ root: classes.root }}
			className={active ? classes.active : ''}
			{...restProps}
			size='large'>
			{children}
		</IconButton>
	);
};




export const LightlyIconButtonOpenClosed = withStyles((props: { open: boolean; } & IconButtonProps) => {
	return <LightlyIconButton {...props} className={props.open ? 'open' : 'closed'}>
		<KeyboardArrowDownIcon />
	</LightlyIconButton>;
}, (theme: Theme) => ({
	root: {
		'transition': theme.transitions.create('transform', { duration: theme.transitions.duration.shortest }),
		'&.open': {
			transform: 'rotate(0)',
		},
		'&.closed': {
			transform: 'rotate(180deg)',
		},
	},
}));
