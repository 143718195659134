import { SelectionInputType, SelectionStrategyTypeV3 } from '@lightly/api-spec';

import { MetricName } from './ReportBuilder/formatMetric';


type SelectionInputTypeKey = `inputType.${SelectionInputType}`;
type SelectionStrategyTypeV3Key = `strategyType.${SelectionStrategyTypeV3}`;

export const interactiveReport: Record<SelectionInputTypeKey | SelectionStrategyTypeV3Key | MetricName, string> = {
	'inputType.EMBEDDINGS': 'Images',
	'inputType.METADATA': 'Text',
	'inputType.PREDICTIONS': 'Audio',
	'inputType.RANDOM': 'Video',
	'inputType.SCORES': 'Other',
	'strategyType.BALANCE': 'Balanced',
	'strategyType.DIVERSITY': 'Diversity',
	'strategyType.SIMILARITY': 'Similarity',
	'strategyType.THRESHOLD': 'Threshold',
	'strategyType.TYPICALITY': 'Typicality',
	'strategyType.WEIGHTS': 'Weights',
	'imagesDiversity': 'Image Diversity',
	'imagesCoverage': 'Image Coverage',
}