import { datasetschedule } from '../../pages/dataset/datasetschedule.en.json';
import { datasetshare } from '../../pages/dataset/datasetshare.en.json'

export const dataset = {
	'field_datasetCreateRequest.name': 'Name',
	'field_datasetUpdateRequest.name': 'Name',

	'dataset.type': 'Input type',

	//datasource
	'datasource.type.LIGHTLY': 'Lightly',
	'datasource.type.S3': 'AWS S3',
	'datasource.type.GCS': 'Google Cloud Storage',
	'datasource.type.AZURE': 'Azure Blob Storage',
	'datasource.type.OBS': 'Object Storage Service (OBS)',
	'datasource.type.LOCAL': 'Local',

	'field_datasources[x].fullPath': 'Resource path',
	'field_datasources[x].fullPathOutput': 'Path to where the .lightly folder is located',
	'field_datasources[x].thumbSuffix': 'Thumbnail suffix',
	'field_datasources[x].containerName': 'Container name', // Azure

	'field_datasources[x].s3AccessKeyId': 'Access key',
	'field_datasources[x].s3SecretAccessKey': 'Secret access key',
	'field_datasources[x].s3Region': 'S3 region',
	'field_datasources[x].s3ARN': 'Role ARN',
	'field_datasources[x].s3ExternalId': 'External ID',

	'field_datasources[x].s3ServerSideEncryptionKMSKey': 'KMS key arn for server side encryption',

	'field_datasources[x].obsAccessKeyId': 'Access key',
	'field_datasources[x].obsSecretAccessKey': 'Secret access key',
	'field_datasources[x].obsEndpoint': 'OBS endpoint',

	'field_datasources[x].gcsProjectId': 'Google project Id',
	'field_datasources[x].gcsCredentials': 'Credentials file',

	'field_datasources[x].AzureAccountName': 'Storage account name',
	'field_datasources[x].AzureSASToken': 'SAS Token',

	'share': datasetshare,
	'schedule': datasetschedule,
}
export default dataset;