import LabelIcon from '@mui/icons-material/Label';
import LibraryBooksIcon from '@mui/icons-material/LibraryBooks';
import WorkIcon from '@mui/icons-material/Work';


import { lazyWithRetry } from './helpers/router';
import { IRoute } from './routes.types';

const SignIn = lazyWithRetry(() => import('./components/SignIn.Lazy'));
const SignOut = lazyWithRetry(() => import('./components/SignOut.Lazy'));
const SignUp = lazyWithRetry(() => import('./components/SignUp.Lazy'));

// Load most pages lazy
const Home = lazyWithRetry(() => import('./components/Home.Lazy'));
const Glossary = lazyWithRetry(() => import('./pages/glossary/Glossary.Lazy'));
const Preferences = lazyWithRetry(() => import('./pages/preferences/Preferences.Lazy'));
const Datasets = lazyWithRetry(() => import('./components/Datasets.Lazy'));
const DatasetDetail = lazyWithRetry(() => import('./components/mydatasets/DatasetDetail.Lazy'));
const DockerRuns = lazyWithRetry(() => import('./pages/docker/DockerRuns.Lazy'));
const DockerWorker = lazyWithRetry(() => import('./pages/docker/worker/DockerWorker.Lazy'));
const Jobs = lazyWithRetry(() => import('./pages/job/Jobs.Lazy'));
const Questionnaire = lazyWithRetry(() => import('./pages/onboarding/Questionnaire.Lazy'));
const Tutorial = lazyWithRetry(() => import('./pages/onboarding/Tutorial.Lazy'));



export type ParamsSubPage = {
	subPage?: string | undefined;
};

export const subpageRoute: IRoute<ParamsDataset & ParamsSubPage> = {
	path: '/dataset/:datasetId/:subPage?:hash([#$])?',
	name: 'Subpage',
	component: DatasetDetail,
};

export type ParamsBasePage = {
	basePage: string;
};
export type ParamsHashPage = {
	hash?: string;
};
export const basePageRoute: IRoute<ParamsBasePage> = {
	path: '/:basePage+:hash([#$])?',
	name: 'BasePage',
	component: DatasetDetail,
};


export const homeRoute: IRoute = {
	path: '/',
	name: 'Home',
	component: Home,
	isPublic: true,
};

export const glossaryRoute: IRoute = {
	path: '/glossary',
	name: 'Glossary',
	component: Glossary,
	isPublic: true,
};

export const signinRoute: IRoute = {
	path: '/signin',
	name: 'Sign In',
	component: SignIn,
	isPublic: true,
};

export const signupRoute: IRoute = {
	path: '/signup',
	name: 'Sign Up',
	component: SignUp,
	isPublic: true,
};

export const signoutRoute: IRoute = {
	path: '/signout',
	name: 'Sign Out',
	component: SignOut,
	isPublic: true,
};

export const dashboardRoute: IRoute = {
	path: '/dashboard',
	name: 'Dashboard',
	component: DatasetDetail, //Annotations,
};


export const teamMemberAddRoute: IRoute = {
	path: '/preferences#/team/member/add',
	name: 'Add team member',
	component: Preferences,
};

export const preferencesHashRoute: IRoute<ParamsHashPage> = {
	path: '/preferences#/:hash(.*)?',
	name: 'preferences sub page',
	component: Preferences,
};

export const preferencesRoute: IRoute = {
	path: '/preferences',
	name: 'Preferences',
	component: Preferences,
	children: [
		teamMemberAddRoute,
		preferencesHashRoute,
	],
};


export const jobsOverviewRoute: IRoute<ParamsDataset> = {
	path: '/jobs',
	name: 'My jobs',
	icon: WorkIcon,
	component: Jobs,
};


export const questionnaireRoute: IRoute = {
	path: '/welcome/questionnaire',
	name: 'Questionnaire',
	component: Questionnaire,
};

export const tutorialRoute: IRoute = {
	path: '/welcome/tutorials',
	name: 'Tutorial',
	component: Tutorial,
};



export type ParamsRun = {
	runId: string;
};
export const dockerRunDetailOverlayRoute: IRoute<ParamsBasePage & ParamsRun> = {
	path: '/:basePage(.*)#/run/:runId/detail',
	name: 'Docker run detail',
	icon: WorkIcon,
	component: DockerRuns,
};
export const dockerRunsOverviewRoute: IRoute<ParamsDataset> = {
	path: '/compute/runs',
	name: 'My docker runs',
	icon: WorkIcon,
	component: DockerRuns,
	children: [
		dockerRunDetailOverlayRoute,
	],
};


export const dockerWorkersCreateRoute: IRoute<ParamsBasePage> = {
	// path: '/compute/workers#/worker/create',
	path: '/:basePage(.*)#/worker/create',
	name: 'Register Docker Worker',
	component: DockerWorker,
};

export const dockerWorkersOverviewRoute: IRoute<ParamsDataset> = {
	path: '/compute/workers',
	name: 'My docker workers',
	icon: WorkIcon,
	component: DockerWorker,
	children: [
		dockerWorkersCreateRoute,
	],
};



export type ParamsConfiguration = {
	configurationId: string;
};



export const datasetConfiguratorRoute: IRoute<ParamsDataset> = {
	path: '/dataset/:datasetId/configurator',
	name: 'Dataset Configurator',
	component: DatasetDetail,
};


export const datasetRunDetailsRoute: IRoute<ParamsDataset & ParamsRun> = {
	path: '/dataset/:datasetId/report/:runId',
	name: 'Dataset Run details',
	component: DatasetDetail,
};

export type ParamsDataset = {
	datasetId: string;
};


export const datasetCreateRoute: IRoute = {
	path: '/dataset/create',
	name: 'Create Dataset',
	component: Datasets,
};

export const datasetDetailMetadataRoute: IRoute<ParamsDataset> = {
	path: '/dataset/:datasetId/metadata',
	name: 'Dataset Metadata',
	component: DatasetDetail,
};
export const datasetDetailEmbeddingRoute: IRoute<ParamsDataset> = {
	path: '/dataset/:datasetId/embedding',
	name: 'Dataset Embedding',
	component: DatasetDetail,
};
export const datasetDetailCorrelationRoute: IRoute<ParamsDataset> = {
	path: '/dataset/:datasetId/correlation',
	name: 'Dataset Correlation',
	component: DatasetDetail,
};


export const datasetDetailSimilarityRoute: IRoute<ParamsDataset> = {
	path: '/dataset/:datasetId/similarity',
	name: 'Dataset Similarity',
	component: DatasetDetail,
};

export const datasetDetailLabelingRoute: IRoute<ParamsDataset> = {
	path: '/dataset/:datasetId/labeling',
	name: 'Dataset Labeling',
	component: DatasetDetail,
};

export const datasetDetailDownloadRoute: IRoute<ParamsDataset> = {
	path: '/dataset/:datasetId/download',
	name: 'Dataset Download',
	component: DatasetDetail,
};

export const datasetDetailInsightsRoute: IRoute<ParamsDataset> = {
	path: '/dataset/:datasetId/insights',
	name: 'Dataset Insights',
	component: DatasetDetail,
};


export const datasetEditRoute: IRoute<ParamsDataset> = {
	path: '/dataset/:datasetId/edit',
	name: 'Edit Dataset',
	component: DatasetDetail,
};

export const datasetSelectionOverlayRoute: IRoute<ParamsDataset & ParamsSubPage> = {
	path: '/dataset/:datasetId/:subPage?#/dataset/selection',
	name: 'Dataset Selection',
	component: DatasetDetail,
};

export const datasetShareOverlayRoute: IRoute<ParamsDataset & ParamsSubPage> = {
	path: '/dataset/:datasetId/:subPage?#/dataset/share',
	name: 'Dataset Sharing',
	component: DatasetDetail,
};

export const datasetScheduleOverlayRoute: IRoute<ParamsDataset & ParamsSubPage> = {
	path: '/dataset/:datasetId/:subPage?#/compute/schedule',
	name: 'Dataset Schedule Worker',
	component: DatasetDetail,
};

export const datasetDetailRoute: IRoute<ParamsDataset> = {
	path: '/dataset/:datasetId',
	name: 'Dataset Detail',
	component: DatasetDetail,
};

export const datasetDetailBasePageRoute: IRoute<ParamsDataset & ParamsBasePage> = {
	path: '/dataset/:datasetId/:basePage(.*):hash([#$])?',
	name: 'DatasetDetailBasePage',
	component: DatasetDetail,
};

export const datasetDetailSubPageRoute: IRoute<ParamsDataset & ParamsSubPage> = {
	path: '/dataset/:datasetId/:subPage([^/]*)',
	name: 'DatasetDetailSubPage',
	component: DatasetDetail,
};


export const datasetOverviewRoute: IRoute = {
	path: '/datasets',
	name: 'My Datasets',
	icon: LibraryBooksIcon,
	component: Datasets,
	children: [
		datasetCreateRoute,
		// datasetDetailMetadataRoute,
		// datasetDetailEmbeddingRoute,
		// datasetDetailDownloadRoute,
		// datasetDetailTrainRoute,
		datasetDetailRoute,
		datasetEditRoute,
	],
};




export type ParamsTag = {
	tagId: string;
};
export const tagEditOverlayRoute: IRoute<ParamsDataset & ParamsSubPage & ParamsTag> = {
	path: '/dataset/:datasetId/:subPage?#/tag/:tagId/edit',
	name: 'Tag Edit',
	component: DatasetDetail,
};




export type ParamsSample = {
	sampleId: string;
};
export const sampleDetailRoute: IRoute<ParamsDataset & ParamsSample> = {
	path: '/dataset/:datasetId/explore/:sampleId',
	name: 'Sample Detail',
	component: DatasetDetail,
};

export const sampleDetailOverlayRoute: IRoute<ParamsDataset & ParamsSubPage & ParamsSample> = {
	path: '/dataset/:datasetId/:subPage?#/sample/:sampleId',
	name: 'Sample Detail',
	component: DatasetDetail,
};

export const sampleOverviewRoute: IRoute<ParamsDataset> = {
	path: '/dataset/:datasetId/explore',
	name: 'Samples',
	icon: LabelIcon,
	component: DatasetDetail,
	children: [sampleDetailRoute, sampleDetailOverlayRoute],
};




export const routes = [
	// annotationOverviewRoute,
	datasetOverviewRoute,
	// sampleOverviewRoute,
	// dashboardRoute,
	jobsOverviewRoute,
	dockerRunsOverviewRoute,
	dockerWorkersOverviewRoute,
	preferencesRoute,
	tutorialRoute,
	questionnaireRoute,

	//public
	signinRoute,
	signupRoute,
	signoutRoute,
	glossaryRoute,
	homeRoute,
];


export const defaultRoute = homeRoute;



const flattenRouteChildren = (routes: IRoute[]) => {
	return routes.reduce((acc, route) => {
		acc.push(route);
		if (route.children) {
			acc.push(...flattenRouteChildren(route.children))
		}
		return acc;
	}, [] as IRoute[]);
}
export const routesAllowedWithoutQuestionnaire = flattenRouteChildren([glossaryRoute, preferencesRoute, signoutRoute, signinRoute, signupRoute]);
export const routesAllowedWithoutDatasets = [...routesAllowedWithoutQuestionnaire, ...flattenRouteChildren([dockerWorkersOverviewRoute, dockerRunsOverviewRoute])];