/* tslint:disable */
/* eslint-disable */
/**
 * Lightly API
 * Lightly.ai enables you to do self-supervised learning in an easy and intuitive way. The lightly.ai OpenAPI spec defines how one can interact with our REST API to unleash the full potential of lightly.ai
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: support@lightly.ai
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
// SRC: https://github.com/OpenAPITools/openapi-generator/blob/master/modules/openapi-generator/src/main/resources/typescript-fetch/modelEnumInterfaces.mustache
// synched 02.02.2023
import * as yup from 'yup';
import * as fastJSON from 'fast-json-stringify';
import { StringSchema } from 'fast-json-stringify';
/**
 * We support different data types for the extracted value. This tells Lightly how to interpret the value and also allows you to do different things.
 * - Numeric means the extracted values are in a range and have a lower and upper bound. E.g used for color ranges
 * - Categorical means the extracted values are distinct and can be grouped. This allows us to e.g plot distributions of each unique value within your dataset and to map each unique value to a color
 *   - string: most often used for class/category e.g for city, animal or weather condition
 *   - int: e.g for ratings of a meal
 *   - boolean: for true/false distinctions as e.g isVerified or flashOn
 *   - datetime: e.g for grouping by time
 *   - timestamp: e.g for grouping by time
 * - Other means that the extracted value is important to you but does not fit another category. It is displayed alongside other information in the sample detail. E.g the license
 * @export
 * @enum {string}
 */
export enum ConfigurationValueDataType {
    NUMERIC_INT = 'NUMERIC_INT',
    NUMERIC_FLOAT = 'NUMERIC_FLOAT',
    CATEGORICAL_STRING = 'CATEGORICAL_STRING',
    CATEGORICAL_INT = 'CATEGORICAL_INT',
    CATEGORICAL_BOOLEAN = 'CATEGORICAL_BOOLEAN',
    CATEGORICAL_DATETIME = 'CATEGORICAL_DATETIME',
    CATEGORICAL_TIMESTAMP = 'CATEGORICAL_TIMESTAMP',
    OTHER_STRING = 'OTHER_STRING'
}
/* LIGHTLY CUSTOM START -> modelEnumInterfaces */
export const ConfigurationValueDataTypeValidatorShape = [
    'NUMERIC_INT',
    'NUMERIC_FLOAT',
    'CATEGORICAL_STRING',
    'CATEGORICAL_INT',
    'CATEGORICAL_BOOLEAN',
    'CATEGORICAL_DATETIME',
    'CATEGORICAL_TIMESTAMP',
    'OTHER_STRING'
];
export const ConfigurationValueDataTypeValidator = yup.mixed().oneOf(ConfigurationValueDataTypeValidatorShape);
export const ConfigurationValueDataTypeStringifyShapeProperties = {};
export const ConfigurationValueDataTypeStringifyShape: StringSchema = {
    title: 'ConfigurationValueDataType Stringifier',
    type: 'string'
};
export const ConfigurationValueDataTypeStringify = (typeof process === 'object' && String(process) === '[object process]') ?
    fastJSON.build(ConfigurationValueDataTypeStringifyShape)
    :
        () => { throw new Error('fast-json-stringify only works in nodejs'); };
/* LIGHTLY CUSTOM END */
export function ConfigurationValueDataTypeFromJSON(json: any): ConfigurationValueDataType {
    return ConfigurationValueDataTypeFromJSONTyped(json, false);
}
export function ConfigurationValueDataTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): ConfigurationValueDataType {
    return json as ConfigurationValueDataType;
}
export function ConfigurationValueDataTypeToJSON(value?: ConfigurationValueDataType | null): any {
    return value as any;
}
