/* tslint:disable */
/* eslint-disable */
/**
 * Lightly API
 * Lightly.ai enables you to do self-supervised learning in an easy and intuitive way. The lightly.ai OpenAPI spec defines how one can interact with our REST API to unleash the full potential of lightly.ai
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: support@lightly.ai
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
// SRC: https://github.com/OpenAPITools/openapi-generator/blob/master/modules/openapi-generator/src/main/resources/typescript-fetch/modelEnumInterfaces.mustache
// synched 02.02.2023
import * as yup from 'yup';
import * as fastJSON from 'fast-json-stringify';
import { StringSchema } from 'fast-json-stringify';
/**
 *
 * @export
 * @enum {string}
 */
export enum TagCreator {
    UNKNOWN = 'UNKNOWN',
    USER_WEBAPP = 'USER_WEBAPP',
    USER_PIP = 'USER_PIP',
    USER_PIP_LIGHTLY_MAGIC = 'USER_PIP_LIGHTLY_MAGIC',
    USER_WORKER = 'USER_WORKER',
    SAMPLER_ACTIVE_LEARNING = 'SAMPLER_ACTIVE_LEARNING',
    SAMPLER_CORAL = 'SAMPLER_CORAL',
    SAMPLER_CORESET = 'SAMPLER_CORESET',
    SAMPLER_RANDOM = 'SAMPLER_RANDOM'
}
/* LIGHTLY CUSTOM START -> modelEnumInterfaces */
export const TagCreatorValidatorShape = [
    'UNKNOWN',
    'USER_WEBAPP',
    'USER_PIP',
    'USER_PIP_LIGHTLY_MAGIC',
    'USER_WORKER',
    'SAMPLER_ACTIVE_LEARNING',
    'SAMPLER_CORAL',
    'SAMPLER_CORESET',
    'SAMPLER_RANDOM'
];
export const TagCreatorValidator = yup.mixed().oneOf(TagCreatorValidatorShape);
export const TagCreatorStringifyShapeProperties = {};
export const TagCreatorStringifyShape: StringSchema = {
    title: 'TagCreator Stringifier',
    type: 'string'
};
export const TagCreatorStringify = (typeof process === 'object' && String(process) === '[object process]') ?
    fastJSON.build(TagCreatorStringifyShape)
    :
        () => { throw new Error('fast-json-stringify only works in nodejs'); };
/* LIGHTLY CUSTOM END */
export function TagCreatorFromJSON(json: any): TagCreator {
    return TagCreatorFromJSONTyped(json, false);
}
export function TagCreatorFromJSONTyped(json: any, ignoreDiscriminator: boolean): TagCreator {
    return json as TagCreator;
}
export function TagCreatorToJSON(value?: TagCreator | null): any {
    return value as any;
}
