/* tslint:disable */
/* eslint-disable */
/**
 * Lightly API
 * Lightly.ai enables you to do self-supervised learning in an easy and intuitive way. The lightly.ai OpenAPI spec defines how one can interact with our REST API to unleash the full potential of lightly.ai
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: support@lightly.ai
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
// SRC: https://github.com/OpenAPITools/openapi-generator/blob/master/modules/openapi-generator/src/main/resources/typescript-fetch/modelGeneric.mustache
// synched 02.02.2023
import * as yup from 'yup';
import typia from 'typia';
import * as fastJSON from 'fast-json-stringify';
import { Options, ArraySchema, ObjectSchema } from 'fast-json-stringify';
import { exists, mapValues } from '../runtime';
/*
causes circular dependendies
*/
// SRC: https://github.com/OpenAPITools/openapi-generator/blob/master/modules/openapi-generator/src/main/resources/typescript-fetch/modelGenericInterfaces.mustache
// synced: 17.07.2023
/**
 *
 * @export
 * @interface SampleMetaData
 *
 */
// create custom validators for the regexes
export interface SampleMetaData {
    /**
     *
     * #type { [key: string]: any | undefined; }
     * #memberof SampleMetaData
        
        
         */
    custom?: {
        [key: string]: any | undefined;
    } | null;
    /**
     *
     * #type { [key: string]: any | undefined; }
     * #memberof SampleMetaData
        
        
         */
    dynamic?: {
        [key: string]: any | undefined;
    } | null;
    /**
     *
     * #type number
     * #memberof SampleMetaData
        
    * @minimum 0
            
         */
    sharpness?: number;
    /**
     *
     * #type number
     * #memberof SampleMetaData
        
    * @minimum 0
        * @maximum 100
        
         */
    luminance?: number;
    /**
     *
     * #type number
     * #memberof SampleMetaData
        * @type int
    * @minimum 0
            
         */
    sizeInBytes?: number;
    /**
     *
     * #type number
     * #memberof SampleMetaData
        
    * @minimum 0
            
         */
    snr?: number;
    /**
     *
     * #type number
     * #memberof SampleMetaData
        
    * @minimum 0
        * @maximum 1
        
         */
    uniformRowRatio?: number;
    /**
     *
     * #type Array<number>
     * #memberof SampleMetaData
        
        
         */
    mean?: Array<number>;
    /**
     *
     * #type Array<number>
     * #memberof SampleMetaData
        
        
         */
    shape?: Array<number>;
    /**
     *
     * #type Array<number>
     * #memberof SampleMetaData
        
        
         */
    std?: Array<number>;
    /**
     *
     * #type Array<number>
     * #memberof SampleMetaData
        
        
         */
    sumOfSquares?: Array<number>;
    /**
     *
     * #type Array<number>
     * #memberof SampleMetaData
        
        
         */
    sumOfValues?: Array<number>;
}
/**
 * Check if a given object implements the SampleMetaData interface.
 */
export function instanceOfSampleMetaData(value: object): boolean {
    let isInstance = true;
    return isInstance;
}
/* LIGHTLY CUSTOM START -> modelGeneric */
export const SampleMetaDataTypiaAssertEquals = (input: any) => {
    return ((input: any): SampleMetaData => {
        const __is = (input: any, _exceptionable: boolean = true): input is SampleMetaData => {
            const $join = (typia.createAssertEquals as any).join;
            const $io0 = (input: any, _exceptionable: boolean = true): boolean => (null === input.custom || undefined === input.custom || "object" === typeof input.custom && null !== input.custom && false === Array.isArray(input.custom) && $io1(input.custom, true && _exceptionable)) && (null === input.dynamic || undefined === input.dynamic || "object" === typeof input.dynamic && null !== input.dynamic && false === Array.isArray(input.dynamic) && $io2(input.dynamic, true && _exceptionable)) && (undefined === input.sharpness || "number" === typeof input.sharpness && 0 <= input.sharpness) && (undefined === input.luminance || "number" === typeof input.luminance && 0 <= input.luminance && 100 >= input.luminance) && (undefined === input.sizeInBytes || "number" === typeof input.sizeInBytes && Math.floor(input.sizeInBytes) === input.sizeInBytes && (-2147483648 <= input.sizeInBytes && input.sizeInBytes <= 2147483647) && 0 <= input.sizeInBytes) && (undefined === input.snr || "number" === typeof input.snr && 0 <= input.snr) && (undefined === input.uniformRowRatio || "number" === typeof input.uniformRowRatio && 0 <= input.uniformRowRatio && 1 >= input.uniformRowRatio) && (undefined === input.mean || Array.isArray(input.mean) && input.mean.every((elem: any, _index1: number) => "number" === typeof elem)) && (undefined === input.shape || Array.isArray(input.shape) && input.shape.every((elem: any, _index2: number) => "number" === typeof elem)) && (undefined === input.std || Array.isArray(input.std) && input.std.every((elem: any, _index3: number) => "number" === typeof elem)) && (undefined === input.sumOfSquares || Array.isArray(input.sumOfSquares) && input.sumOfSquares.every((elem: any, _index4: number) => "number" === typeof elem)) && (undefined === input.sumOfValues || Array.isArray(input.sumOfValues) && input.sumOfValues.every((elem: any, _index5: number) => "number" === typeof elem)) && (0 === Object.keys(input).length || Object.keys(input).every((key: any) => {
                if (["custom", "dynamic", "sharpness", "luminance", "sizeInBytes", "snr", "uniformRowRatio", "mean", "shape", "std", "sumOfSquares", "sumOfValues"].some((prop: any) => key === prop))
                    return true;
                const value = input[key];
                if (undefined === value)
                    return true;
                return false;
            }));
            const $io1 = (input: any, _exceptionable: boolean = true): boolean => Object.keys(input).every((key: any) => {
                const value = input[key];
                if (undefined === value)
                    return true;
                if (RegExp(/(.*)/).test(key))
                    return true;
                return false;
            });
            const $io2 = (input: any, _exceptionable: boolean = true): boolean => Object.keys(input).every((key: any) => {
                const value = input[key];
                if (undefined === value)
                    return true;
                if (RegExp(/(.*)/).test(key))
                    return true;
                return false;
            });
            return "object" === typeof input && null !== input && false === Array.isArray(input) && $io0(input, true);
        };
        if (false === __is(input))
            ((input: any, _path: string, _exceptionable: boolean = true): input is SampleMetaData => {
                const $guard = (typia.createAssertEquals as any).guard;
                const $join = (typia.createAssertEquals as any).join;
                const $ao0 = (input: any, _path: string, _exceptionable: boolean = true): boolean => (null === input.custom || undefined === input.custom || ("object" === typeof input.custom && null !== input.custom && false === Array.isArray(input.custom) || $guard(_exceptionable, {
                    path: _path + ".custom",
                    expected: "(__type | null | undefined)",
                    value: input.custom
                })) && $ao1(input.custom, _path + ".custom", true && _exceptionable) || $guard(_exceptionable, {
                    path: _path + ".custom",
                    expected: "(__type | null | undefined)",
                    value: input.custom
                })) && (null === input.dynamic || undefined === input.dynamic || ("object" === typeof input.dynamic && null !== input.dynamic && false === Array.isArray(input.dynamic) || $guard(_exceptionable, {
                    path: _path + ".dynamic",
                    expected: "(__type.o1 | null | undefined)",
                    value: input.dynamic
                })) && $ao2(input.dynamic, _path + ".dynamic", true && _exceptionable) || $guard(_exceptionable, {
                    path: _path + ".dynamic",
                    expected: "(__type.o1 | null | undefined)",
                    value: input.dynamic
                })) && (undefined === input.sharpness || "number" === typeof input.sharpness && (0 <= input.sharpness || $guard(_exceptionable, {
                    path: _path + ".sharpness",
                    expected: "number (@minimum 0)",
                    value: input.sharpness
                })) || $guard(_exceptionable, {
                    path: _path + ".sharpness",
                    expected: "(number | undefined)",
                    value: input.sharpness
                })) && (undefined === input.luminance || "number" === typeof input.luminance && (0 <= input.luminance || $guard(_exceptionable, {
                    path: _path + ".luminance",
                    expected: "number (@minimum 0)",
                    value: input.luminance
                })) && (100 >= input.luminance || $guard(_exceptionable, {
                    path: _path + ".luminance",
                    expected: "number (@maximum 100)",
                    value: input.luminance
                })) || $guard(_exceptionable, {
                    path: _path + ".luminance",
                    expected: "(number | undefined)",
                    value: input.luminance
                })) && (undefined === input.sizeInBytes || "number" === typeof input.sizeInBytes && (Math.floor(input.sizeInBytes) === input.sizeInBytes || $guard(_exceptionable, {
                    path: _path + ".sizeInBytes",
                    expected: "number (@type int)",
                    value: input.sizeInBytes
                })) && (-2147483648 <= input.sizeInBytes && input.sizeInBytes <= 2147483647 || $guard(_exceptionable, {
                    path: _path + ".sizeInBytes",
                    expected: "number (@type int)",
                    value: input.sizeInBytes
                })) && (0 <= input.sizeInBytes || $guard(_exceptionable, {
                    path: _path + ".sizeInBytes",
                    expected: "number (@minimum 0)",
                    value: input.sizeInBytes
                })) || $guard(_exceptionable, {
                    path: _path + ".sizeInBytes",
                    expected: "(number | undefined)",
                    value: input.sizeInBytes
                })) && (undefined === input.snr || "number" === typeof input.snr && (0 <= input.snr || $guard(_exceptionable, {
                    path: _path + ".snr",
                    expected: "number (@minimum 0)",
                    value: input.snr
                })) || $guard(_exceptionable, {
                    path: _path + ".snr",
                    expected: "(number | undefined)",
                    value: input.snr
                })) && (undefined === input.uniformRowRatio || "number" === typeof input.uniformRowRatio && (0 <= input.uniformRowRatio || $guard(_exceptionable, {
                    path: _path + ".uniformRowRatio",
                    expected: "number (@minimum 0)",
                    value: input.uniformRowRatio
                })) && (1 >= input.uniformRowRatio || $guard(_exceptionable, {
                    path: _path + ".uniformRowRatio",
                    expected: "number (@maximum 1)",
                    value: input.uniformRowRatio
                })) || $guard(_exceptionable, {
                    path: _path + ".uniformRowRatio",
                    expected: "(number | undefined)",
                    value: input.uniformRowRatio
                })) && (undefined === input.mean || (Array.isArray(input.mean) || $guard(_exceptionable, {
                    path: _path + ".mean",
                    expected: "(Array<number> | undefined)",
                    value: input.mean
                })) && input.mean.every((elem: any, _index1: number) => "number" === typeof elem || $guard(_exceptionable, {
                    path: _path + ".mean[" + _index1 + "]",
                    expected: "number",
                    value: elem
                })) || $guard(_exceptionable, {
                    path: _path + ".mean",
                    expected: "(Array<number> | undefined)",
                    value: input.mean
                })) && (undefined === input.shape || (Array.isArray(input.shape) || $guard(_exceptionable, {
                    path: _path + ".shape",
                    expected: "(Array<number> | undefined)",
                    value: input.shape
                })) && input.shape.every((elem: any, _index2: number) => "number" === typeof elem || $guard(_exceptionable, {
                    path: _path + ".shape[" + _index2 + "]",
                    expected: "number",
                    value: elem
                })) || $guard(_exceptionable, {
                    path: _path + ".shape",
                    expected: "(Array<number> | undefined)",
                    value: input.shape
                })) && (undefined === input.std || (Array.isArray(input.std) || $guard(_exceptionable, {
                    path: _path + ".std",
                    expected: "(Array<number> | undefined)",
                    value: input.std
                })) && input.std.every((elem: any, _index3: number) => "number" === typeof elem || $guard(_exceptionable, {
                    path: _path + ".std[" + _index3 + "]",
                    expected: "number",
                    value: elem
                })) || $guard(_exceptionable, {
                    path: _path + ".std",
                    expected: "(Array<number> | undefined)",
                    value: input.std
                })) && (undefined === input.sumOfSquares || (Array.isArray(input.sumOfSquares) || $guard(_exceptionable, {
                    path: _path + ".sumOfSquares",
                    expected: "(Array<number> | undefined)",
                    value: input.sumOfSquares
                })) && input.sumOfSquares.every((elem: any, _index4: number) => "number" === typeof elem || $guard(_exceptionable, {
                    path: _path + ".sumOfSquares[" + _index4 + "]",
                    expected: "number",
                    value: elem
                })) || $guard(_exceptionable, {
                    path: _path + ".sumOfSquares",
                    expected: "(Array<number> | undefined)",
                    value: input.sumOfSquares
                })) && (undefined === input.sumOfValues || (Array.isArray(input.sumOfValues) || $guard(_exceptionable, {
                    path: _path + ".sumOfValues",
                    expected: "(Array<number> | undefined)",
                    value: input.sumOfValues
                })) && input.sumOfValues.every((elem: any, _index5: number) => "number" === typeof elem || $guard(_exceptionable, {
                    path: _path + ".sumOfValues[" + _index5 + "]",
                    expected: "number",
                    value: elem
                })) || $guard(_exceptionable, {
                    path: _path + ".sumOfValues",
                    expected: "(Array<number> | undefined)",
                    value: input.sumOfValues
                })) && (0 === Object.keys(input).length || (false === _exceptionable || Object.keys(input).every((key: any) => {
                    if (["custom", "dynamic", "sharpness", "luminance", "sizeInBytes", "snr", "uniformRowRatio", "mean", "shape", "std", "sumOfSquares", "sumOfValues"].some((prop: any) => key === prop))
                        return true;
                    const value = input[key];
                    if (undefined === value)
                        return true;
                    return $guard(_exceptionable, {
                        path: _path + $join(key),
                        expected: "undefined",
                        value: value
                    });
                })));
                const $ao1 = (input: any, _path: string, _exceptionable: boolean = true): boolean => false === _exceptionable || Object.keys(input).every((key: any) => {
                    const value = input[key];
                    if (undefined === value)
                        return true;
                    if (RegExp(/(.*)/).test(key))
                        return true;
                    return $guard(_exceptionable, {
                        path: _path + $join(key),
                        expected: "undefined",
                        value: value
                    });
                });
                const $ao2 = (input: any, _path: string, _exceptionable: boolean = true): boolean => false === _exceptionable || Object.keys(input).every((key: any) => {
                    const value = input[key];
                    if (undefined === value)
                        return true;
                    if (RegExp(/(.*)/).test(key))
                        return true;
                    return $guard(_exceptionable, {
                        path: _path + $join(key),
                        expected: "undefined",
                        value: value
                    });
                });
                return ("object" === typeof input && null !== input && false === Array.isArray(input) || $guard(true, {
                    path: _path + "",
                    expected: "SampleMetaData",
                    value: input
                })) && $ao0(input, _path + "", true) || $guard(true, {
                    path: _path + "",
                    expected: "SampleMetaData",
                    value: input
                });
            })(input, "$input", true);
        return input;
    })(input);
};
export const SampleMetaDataTypiaAssertStringify = (input: SampleMetaData): string => {
    return ((input: any): string => { const assert = (input: any): SampleMetaData => {
        const __is = (input: any): input is SampleMetaData => {
            const $join = (typia.createAssertStringify as any).join;
            const $io0 = (input: any): boolean => (null === input.custom || undefined === input.custom || "object" === typeof input.custom && null !== input.custom && false === Array.isArray(input.custom) && $io1(input.custom)) && (null === input.dynamic || undefined === input.dynamic || "object" === typeof input.dynamic && null !== input.dynamic && false === Array.isArray(input.dynamic) && $io2(input.dynamic)) && (undefined === input.sharpness || "number" === typeof input.sharpness && !Number.isNaN(input.sharpness) && 0 <= input.sharpness) && (undefined === input.luminance || "number" === typeof input.luminance && 0 <= input.luminance && 100 >= input.luminance) && (undefined === input.sizeInBytes || "number" === typeof input.sizeInBytes && !Number.isNaN(input.sizeInBytes) && Math.floor(input.sizeInBytes) === input.sizeInBytes && (-2147483648 <= input.sizeInBytes && input.sizeInBytes <= 2147483647) && 0 <= input.sizeInBytes) && (undefined === input.snr || "number" === typeof input.snr && !Number.isNaN(input.snr) && 0 <= input.snr) && (undefined === input.uniformRowRatio || "number" === typeof input.uniformRowRatio && 0 <= input.uniformRowRatio && 1 >= input.uniformRowRatio) && (undefined === input.mean || Array.isArray(input.mean) && input.mean.every((elem: any) => "number" === typeof elem && !Number.isNaN(elem))) && (undefined === input.shape || Array.isArray(input.shape) && input.shape.every((elem: any) => "number" === typeof elem && !Number.isNaN(elem))) && (undefined === input.std || Array.isArray(input.std) && input.std.every((elem: any) => "number" === typeof elem && !Number.isNaN(elem))) && (undefined === input.sumOfSquares || Array.isArray(input.sumOfSquares) && input.sumOfSquares.every((elem: any) => "number" === typeof elem && !Number.isNaN(elem))) && (undefined === input.sumOfValues || Array.isArray(input.sumOfValues) && input.sumOfValues.every((elem: any) => "number" === typeof elem && !Number.isNaN(elem)));
            const $io1 = (input: any): boolean => Object.keys(input).every((key: any) => {
                const value = input[key];
                if (undefined === value)
                    return true;
                if (RegExp(/(.*)/).test(key))
                    return true;
                return true;
            });
            const $io2 = (input: any): boolean => Object.keys(input).every((key: any) => {
                const value = input[key];
                if (undefined === value)
                    return true;
                if (RegExp(/(.*)/).test(key))
                    return true;
                return true;
            });
            return "object" === typeof input && null !== input && false === Array.isArray(input) && $io0(input);
        };
        if (false === __is(input))
            ((input: any, _path: string, _exceptionable: boolean = true): input is SampleMetaData => {
                const $guard = (typia.createAssertStringify as any).guard;
                const $join = (typia.createAssertStringify as any).join;
                const $ao0 = (input: any, _path: string, _exceptionable: boolean = true): boolean => (null === input.custom || undefined === input.custom || ("object" === typeof input.custom && null !== input.custom && false === Array.isArray(input.custom) || $guard(_exceptionable, {
                    path: _path + ".custom",
                    expected: "(__type | null | undefined)",
                    value: input.custom
                })) && $ao1(input.custom, _path + ".custom", true && _exceptionable) || $guard(_exceptionable, {
                    path: _path + ".custom",
                    expected: "(__type | null | undefined)",
                    value: input.custom
                })) && (null === input.dynamic || undefined === input.dynamic || ("object" === typeof input.dynamic && null !== input.dynamic && false === Array.isArray(input.dynamic) || $guard(_exceptionable, {
                    path: _path + ".dynamic",
                    expected: "(__type.o1 | null | undefined)",
                    value: input.dynamic
                })) && $ao2(input.dynamic, _path + ".dynamic", true && _exceptionable) || $guard(_exceptionable, {
                    path: _path + ".dynamic",
                    expected: "(__type.o1 | null | undefined)",
                    value: input.dynamic
                })) && (undefined === input.sharpness || "number" === typeof input.sharpness && !Number.isNaN(input.sharpness) && (0 <= input.sharpness || $guard(_exceptionable, {
                    path: _path + ".sharpness",
                    expected: "number (@minimum 0)",
                    value: input.sharpness
                })) || $guard(_exceptionable, {
                    path: _path + ".sharpness",
                    expected: "(number | undefined)",
                    value: input.sharpness
                })) && (undefined === input.luminance || "number" === typeof input.luminance && (0 <= input.luminance || $guard(_exceptionable, {
                    path: _path + ".luminance",
                    expected: "number (@minimum 0)",
                    value: input.luminance
                })) && (100 >= input.luminance || $guard(_exceptionable, {
                    path: _path + ".luminance",
                    expected: "number (@maximum 100)",
                    value: input.luminance
                })) || $guard(_exceptionable, {
                    path: _path + ".luminance",
                    expected: "(number | undefined)",
                    value: input.luminance
                })) && (undefined === input.sizeInBytes || "number" === typeof input.sizeInBytes && !Number.isNaN(input.sizeInBytes) && (Math.floor(input.sizeInBytes) === input.sizeInBytes || $guard(_exceptionable, {
                    path: _path + ".sizeInBytes",
                    expected: "number (@type int)",
                    value: input.sizeInBytes
                })) && (-2147483648 <= input.sizeInBytes && input.sizeInBytes <= 2147483647 || $guard(_exceptionable, {
                    path: _path + ".sizeInBytes",
                    expected: "number (@type int)",
                    value: input.sizeInBytes
                })) && (0 <= input.sizeInBytes || $guard(_exceptionable, {
                    path: _path + ".sizeInBytes",
                    expected: "number (@minimum 0)",
                    value: input.sizeInBytes
                })) || $guard(_exceptionable, {
                    path: _path + ".sizeInBytes",
                    expected: "(number | undefined)",
                    value: input.sizeInBytes
                })) && (undefined === input.snr || "number" === typeof input.snr && !Number.isNaN(input.snr) && (0 <= input.snr || $guard(_exceptionable, {
                    path: _path + ".snr",
                    expected: "number (@minimum 0)",
                    value: input.snr
                })) || $guard(_exceptionable, {
                    path: _path + ".snr",
                    expected: "(number | undefined)",
                    value: input.snr
                })) && (undefined === input.uniformRowRatio || "number" === typeof input.uniformRowRatio && (0 <= input.uniformRowRatio || $guard(_exceptionable, {
                    path: _path + ".uniformRowRatio",
                    expected: "number (@minimum 0)",
                    value: input.uniformRowRatio
                })) && (1 >= input.uniformRowRatio || $guard(_exceptionable, {
                    path: _path + ".uniformRowRatio",
                    expected: "number (@maximum 1)",
                    value: input.uniformRowRatio
                })) || $guard(_exceptionable, {
                    path: _path + ".uniformRowRatio",
                    expected: "(number | undefined)",
                    value: input.uniformRowRatio
                })) && (undefined === input.mean || (Array.isArray(input.mean) || $guard(_exceptionable, {
                    path: _path + ".mean",
                    expected: "(Array<number> | undefined)",
                    value: input.mean
                })) && input.mean.every((elem: any, _index1: number) => "number" === typeof elem && !Number.isNaN(elem) || $guard(_exceptionable, {
                    path: _path + ".mean[" + _index1 + "]",
                    expected: "number",
                    value: elem
                })) || $guard(_exceptionable, {
                    path: _path + ".mean",
                    expected: "(Array<number> | undefined)",
                    value: input.mean
                })) && (undefined === input.shape || (Array.isArray(input.shape) || $guard(_exceptionable, {
                    path: _path + ".shape",
                    expected: "(Array<number> | undefined)",
                    value: input.shape
                })) && input.shape.every((elem: any, _index2: number) => "number" === typeof elem && !Number.isNaN(elem) || $guard(_exceptionable, {
                    path: _path + ".shape[" + _index2 + "]",
                    expected: "number",
                    value: elem
                })) || $guard(_exceptionable, {
                    path: _path + ".shape",
                    expected: "(Array<number> | undefined)",
                    value: input.shape
                })) && (undefined === input.std || (Array.isArray(input.std) || $guard(_exceptionable, {
                    path: _path + ".std",
                    expected: "(Array<number> | undefined)",
                    value: input.std
                })) && input.std.every((elem: any, _index3: number) => "number" === typeof elem && !Number.isNaN(elem) || $guard(_exceptionable, {
                    path: _path + ".std[" + _index3 + "]",
                    expected: "number",
                    value: elem
                })) || $guard(_exceptionable, {
                    path: _path + ".std",
                    expected: "(Array<number> | undefined)",
                    value: input.std
                })) && (undefined === input.sumOfSquares || (Array.isArray(input.sumOfSquares) || $guard(_exceptionable, {
                    path: _path + ".sumOfSquares",
                    expected: "(Array<number> | undefined)",
                    value: input.sumOfSquares
                })) && input.sumOfSquares.every((elem: any, _index4: number) => "number" === typeof elem && !Number.isNaN(elem) || $guard(_exceptionable, {
                    path: _path + ".sumOfSquares[" + _index4 + "]",
                    expected: "number",
                    value: elem
                })) || $guard(_exceptionable, {
                    path: _path + ".sumOfSquares",
                    expected: "(Array<number> | undefined)",
                    value: input.sumOfSquares
                })) && (undefined === input.sumOfValues || (Array.isArray(input.sumOfValues) || $guard(_exceptionable, {
                    path: _path + ".sumOfValues",
                    expected: "(Array<number> | undefined)",
                    value: input.sumOfValues
                })) && input.sumOfValues.every((elem: any, _index5: number) => "number" === typeof elem && !Number.isNaN(elem) || $guard(_exceptionable, {
                    path: _path + ".sumOfValues[" + _index5 + "]",
                    expected: "number",
                    value: elem
                })) || $guard(_exceptionable, {
                    path: _path + ".sumOfValues",
                    expected: "(Array<number> | undefined)",
                    value: input.sumOfValues
                }));
                const $ao1 = (input: any, _path: string, _exceptionable: boolean = true): boolean => false === _exceptionable || Object.keys(input).every((key: any) => {
                    const value = input[key];
                    if (undefined === value)
                        return true;
                    if (RegExp(/(.*)/).test(key))
                        return true;
                    return true;
                });
                const $ao2 = (input: any, _path: string, _exceptionable: boolean = true): boolean => false === _exceptionable || Object.keys(input).every((key: any) => {
                    const value = input[key];
                    if (undefined === value)
                        return true;
                    if (RegExp(/(.*)/).test(key))
                        return true;
                    return true;
                });
                return ("object" === typeof input && null !== input && false === Array.isArray(input) || $guard(true, {
                    path: _path + "",
                    expected: "SampleMetaData",
                    value: input
                })) && $ao0(input, _path + "", true) || $guard(true, {
                    path: _path + "",
                    expected: "SampleMetaData",
                    value: input
                });
            })(input, "$input", true);
        return input;
    }; const stringify = (input: SampleMetaData): string => {
        const $io1 = (input: any): boolean => Object.keys(input).every((key: any) => {
            const value = input[key];
            if (undefined === value)
                return true;
            if (RegExp(/(.*)/).test(key))
                return true;
            return true;
        });
        const $io2 = (input: any): boolean => Object.keys(input).every((key: any) => {
            const value = input[key];
            if (undefined === value)
                return true;
            if (RegExp(/(.*)/).test(key))
                return true;
            return true;
        });
        const $tail = (typia.createAssertStringify as any).tail;
        const $join = (typia.createAssertStringify as any).join;
        const $so0 = (input: any): any => `{${$tail(`${undefined === input.custom ? "" : `"custom":${undefined !== input.custom ? null !== input.custom ? $so1(input.custom) : "null" : undefined},`}${undefined === input.dynamic ? "" : `"dynamic":${undefined !== input.dynamic ? null !== input.dynamic ? $so2(input.dynamic) : "null" : undefined},`}${undefined === input.sharpness ? "" : `"sharpness":${undefined !== input.sharpness ? input.sharpness : undefined},`}${undefined === input.luminance ? "" : `"luminance":${undefined !== input.luminance ? input.luminance : undefined},`}${undefined === input.sizeInBytes ? "" : `"sizeInBytes":${undefined !== input.sizeInBytes ? input.sizeInBytes : undefined},`}${undefined === input.snr ? "" : `"snr":${undefined !== input.snr ? input.snr : undefined},`}${undefined === input.uniformRowRatio ? "" : `"uniformRowRatio":${undefined !== input.uniformRowRatio ? input.uniformRowRatio : undefined},`}${undefined === input.mean ? "" : `"mean":${undefined !== input.mean ? `[${input.mean.map((elem: any) => elem).join(",")}]` : undefined},`}${undefined === input.shape ? "" : `"shape":${undefined !== input.shape ? `[${input.shape.map((elem: any) => elem).join(",")}]` : undefined},`}${undefined === input.std ? "" : `"std":${undefined !== input.std ? `[${input.std.map((elem: any) => elem).join(",")}]` : undefined},`}${undefined === input.sumOfSquares ? "" : `"sumOfSquares":${undefined !== input.sumOfSquares ? `[${input.sumOfSquares.map((elem: any) => elem).join(",")}]` : undefined},`}${undefined === input.sumOfValues ? "" : `"sumOfValues":${undefined !== input.sumOfValues ? `[${input.sumOfValues.map((elem: any) => elem).join(",")}]` : undefined}`}`)}}`;
        const $so1 = (input: any): any => `{${Object.entries(input).map(([key, value]: [string, any]) => { if (undefined === value)
            return ""; return `${JSON.stringify(key)}:${undefined !== value ? JSON.stringify(value) : undefined}`; }).filter((str: any) => "" !== str).join(",")}}`;
        const $so2 = (input: any): any => `{${Object.entries(input).map(([key, value]: [string, any]) => { if (undefined === value)
            return ""; return `${JSON.stringify(key)}:${undefined !== value ? JSON.stringify(value) : undefined}`; }).filter((str: any) => "" !== str).join(",")}}`;
        return $so0(input);
    }; return stringify(assert(input)); })(input);
};
export const SampleMetaDataValidatorShape = {
    'custom': yup.mixed().transform((val: any) => { return val instanceof Object && !Array.isArray(val) ? val : {}; }).nullable(),
    'dynamic': yup.mixed().transform((val: any) => { return val instanceof Object && !Array.isArray(val) ? val : {}; }).nullable(),
    'sharpness': yup.number().min(0),
    'luminance': yup.number().min(0).max(100),
    'sizeInBytes': yup.number().integer().min(0),
    'snr': yup.number().min(0),
    'uniformRowRatio': yup.number().min(0).max(1),
    'mean': yup.array().of(yup.number().min(0).max(1)).min(3).max(3),
    'shape': yup.array().of(yup.number().min(0)).min(3).max(3),
    'std': yup.array().of(yup.number().min(0)).min(3).max(3),
    'sumOfSquares': yup.array().of(yup.number().min(0)).min(3).max(3),
    'sumOfValues': yup.array().of(yup.number().min(0)).min(3).max(3),
};
export const SampleMetaDataValidator = yup.object().shape(SampleMetaDataValidatorShape);
export const SampleMetaDataStringifyShapeProperties: ObjectSchema['properties'] = {
    'custom': {
        type: 'object',
        additionalProperties: true
    },
    'dynamic': {
        type: 'object',
        additionalProperties: true
    },
    'sharpness': {
        'type': 'number',
        'minimum': 0,
    } as unknown as fastJSON.NumberSchema,
    'luminance': {
        'type': 'number',
        'minimum': 0,
        'maximum': 100,
    } as unknown as fastJSON.NumberSchema,
    'sizeInBytes': {
        'type': 'integer',
        'minimum': 0,
    } as unknown as fastJSON.IntegerSchema,
    'snr': {
        'type': 'number',
        'minimum': 0,
    } as unknown as fastJSON.NumberSchema,
    'uniformRowRatio': {
        'type': 'number',
        'minimum': 0,
        'maximum': 1,
    } as unknown as fastJSON.NumberSchema,
    'mean': {
        type: 'array',
        'maxItems': 3,
        'minItems': 3,
        items: {
            type: 'number'
        } as unknown as fastJSON.Schema,
    } as unknown as fastJSON.ArraySchema,
    'shape': {
        type: 'array',
        'maxItems': 3,
        'minItems': 3,
        items: {
            type: 'number'
        } as unknown as fastJSON.Schema,
    } as unknown as fastJSON.ArraySchema,
    'std': {
        type: 'array',
        'maxItems': 3,
        'minItems': 3,
        items: {
            type: 'number'
        } as unknown as fastJSON.Schema,
    } as unknown as fastJSON.ArraySchema,
    'sumOfSquares': {
        type: 'array',
        'maxItems': 3,
        'minItems': 3,
        items: {
            type: 'number'
        } as unknown as fastJSON.Schema,
    } as unknown as fastJSON.ArraySchema,
    'sumOfValues': {
        type: 'array',
        'maxItems': 3,
        'minItems': 3,
        items: {
            type: 'number'
        } as unknown as fastJSON.Schema,
    } as unknown as fastJSON.ArraySchema,
};
export const SampleMetaDataStringifyShape: ObjectSchema = {
    title: 'SampleMetaData Stringifier',
    type: 'object',
    properties: {
        ...SampleMetaDataStringifyShapeProperties
    },
    required: [],
};
export const SampleMetaDataStringifyCompiled = (typeof process === 'object' && String(process) === '[object process]') ?
    fastJSON.build(SampleMetaDataStringifyShape, {
        ajv: {
            // since we are already doing this with yup, lets skip
            validateSchema: false,
            ownProperties: true,
            validateFormats: false,
        },
        largeArrayMechanism: 'json-stringify',
        largeArraySize: 1000,
    } as Options)
    :
        () => { throw new Error('fast-json-stringify only works in nodejs'); };
export const SampleMetaDataStringify: (data: SampleMetaData) => string = SampleMetaDataStringifyCompiled;
/* LIGHTLY CUSTOM END -> Generic */
export function SampleMetaDataFromJSON(json: any): SampleMetaData {
    return SampleMetaDataFromJSONTyped(json, false);
}
export function SampleMetaDataFromJSONTyped(json: any, ignoreDiscriminator: boolean): SampleMetaData {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    /* causes circular depdendencies
*/
    return {
        'custom': !exists(json, 'custom') ? undefined : json['custom'],
        'dynamic': !exists(json, 'dynamic') ? undefined : json['dynamic'],
        'sharpness': !exists(json, 'sharpness') ? undefined : json['sharpness'],
        'luminance': !exists(json, 'luminance') ? undefined : json['luminance'],
        'sizeInBytes': !exists(json, 'sizeInBytes') ? undefined : json['sizeInBytes'],
        'snr': !exists(json, 'snr') ? undefined : json['snr'],
        'uniformRowRatio': !exists(json, 'uniformRowRatio') ? undefined : json['uniformRowRatio'],
        'mean': !exists(json, 'mean') ? undefined : json['mean'],
        'shape': !exists(json, 'shape') ? undefined : json['shape'],
        'std': !exists(json, 'std') ? undefined : json['std'],
        'sumOfSquares': !exists(json, 'sumOfSquares') ? undefined : json['sumOfSquares'],
        'sumOfValues': !exists(json, 'sumOfValues') ? undefined : json['sumOfValues'],
    };
}
export function SampleMetaDataToJSON(value?: SampleMetaData | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'custom': value.custom,
        'dynamic': value.dynamic,
        'sharpness': value.sharpness,
        'luminance': value.luminance,
        'sizeInBytes': value.sizeInBytes,
        'snr': value.snr,
        'uniformRowRatio': value.uniformRowRatio,
        'mean': value.mean,
        'shape': value.shape,
        'std': value.std,
        'sumOfSquares': value.sumOfSquares,
        'sumOfValues': value.sumOfValues,
    };
}
