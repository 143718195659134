import React, { useEffect, useState } from 'react';

import { TransitionGroup, CSSTransition } from 'react-transition-group';
import { makeStyles } from 'tss-react/mui';

import { Grid, Theme, Typography, keyframes } from '@mui/material';

import Logo from '../static/LightlyIcon.png';


const animationTime = 1392;

const textEnterTime = 1392;
const textExitTime = 300;

const pulse = keyframes`
	from {
		transform: scale3d(1, 1, 1);
	}
	45% {
		transform: scale3d(1.05, 1.05, 1.05);
	}
	to {
		transform: scale3d(1, 1, 1);
	}
`;

const shine = keyframes`
	0% {
		opacity: 0;
	}
	10% {
		opacity: 1;
	}
	100% {
		opacity: 0;
		top: -10%;
		left: -10%;
	}
`

const rotate = keyframes`
	to {
		transform: rotate(360deg);
	}
`

const useStyles = makeStyles()((theme: Theme) => ({
	root: {
		position: 'fixed',
		zIndex: 666,
		top: 0,
		left: 0,
		width: '100vw',
		height: '100vh',
		opacity: 0,
		pointerEvents: 'none',
		// BG pattern made with http://www.heropatterns.com/
		backgroundColor: '#DFDBE5AA',
		backgroundImage: 'url("data:image/svg+xml,%3Csvg xmlns=\'http://www.w3.org/2000/svg\' width=\'4\' height=\'4\' viewBox=\'0 0 4 4\'%3E%3Cpath fill=\'%239C92AC\' fill-opacity=\'0.4\' d=\'M1 3h1v1H1V3zm2-2h1v1H3V1z\'%3E%3C/path%3E%3C/svg%3E")',
		transition: 'opacity .3s ease-in-out',
		willChange:'opacity',
	},
	show: {
		opacity: 1,
		pointerEvents: 'all',
		userSelect: 'none',
	},
	grid: {
		minHeight: '100vh',
	},
	wrap: {
		position: 'relative',
		marginTop: '-6.18vh', // move the loader slightly above center
	},
	ring: {
		zIndex: -1,
		position: 'absolute',
		top: '-.3rem',
		left: '-.3rem',
		width: '100%',
		height: '100%',
		padding: '.3rem',
		background: `linear-gradient(120deg,#FFFFFF 0%,${theme.palette.lightlyBrandTurquoise.main} 5%, ${theme.palette.lightlyBrandGreen.main} 30%, ${theme.palette.lightlyBrandBlue.main} 60%, ${theme.palette.lightlyDarkBG.main} 100%)`,
  		borderRadius: '50%',
		animation: `${rotate} ${animationTime}ms ease infinite`,
		boxSizing: 'content-box',
	},
	image: {
		animation: `${pulse} ${animationTime}ms ease infinite`,
		verticalAlign: 'middle', // kill the stupid little margin the browser sets on images
	},
	picture: {
		backgroundColor: 'white',
		display: 'block',
		position: 'relative',
		borderRadius: '50%',
		padding: '1.382rem',
		overflow: 'hidden',
		boxShadow: 'rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;',
		// hack for safari to cut off the "flash" with overflow hidden correctly by placing it in its own composite layer
		transform: 'translateZ(0)',
	},
	flash: {
		animation: `${shine} ${animationTime}ms ease infinite`,
		animationFillMode: 'forwards',
		display: 'inline-block',
		position: 'absolute',
		top: '-100%',
		left: '-200%',
		width: '200%',
		height: '200%',
		opacity: 0,
		transform: 'rotate(30deg)',

		background: `linear-gradient(
			to right,
			rgba(255, 255, 255, 0.0) 0%,
			rgba(255, 255, 255, 0.13) 77%,
			rgba(255, 255, 255, 0.5) 92%,
			rgba(255, 255, 255, 0.0) 100%
		)`,
	},
	textWrapper: {
	},
	text: {
		position: 'absolute',
		transform: 'translate(-50%, 0)',
		marginTop: '1.618vh',

		'&.item-enter': {
			opacity: 0,
			transition: `opacity ${textEnterTime}ms ease-in`,
		},
		'&.item-enter-active': {
			opacity: 1,
		},
		'&.item-exit': {
			opacity: 1,
			transition: `opacity ${textExitTime}ms ease-in`,
		},
		'&.item-exit-active': {
			opacity: 0,
		},
	},
}));



let started = 0;
export const WhiteoutLoading: React.FunctionComponent<{show: boolean, texts?: {key: string, text: string, ref: React.RefObject<HTMLElement>}[]}> = ({ show, texts }) => {
	const { classes, cx } = useStyles();

	// sync the animations of all whiteout loadings
	started = started || Date.now();
	const [animationDelay] = useState(-(((Date.now() - started) % animationTime) ));

	// preload lightly icon image!
	useEffect(() => {
		const preload = new Image();
		preload.src = Logo
	},[])

	return (
		<div className={cx('whiteoutLoading', classes.root, show && classes.show)} key='whiteoutLoading'>
			<Grid
				container
				direction='column'
				alignItems='center'
				justifyContent='center'
				className={cx(classes.grid)}
			>
				<Grid item className={classes.wrap}>
					<picture className={classes.picture}  style={{ animationDelay:`${animationDelay}ms` }}>
						<img src={Logo} className={classes.image} alt='Lightly is loading' title='Lightly is loading' height='138rem'  width='138rem' style={{ animationDelay:`${animationDelay}ms` }} />
						<div className={classes.flash}  style={{ animationDelay:`${animationDelay}ms` }}> </div>
					</picture>
					<div className={classes.ring} style={{ animationDelay:`${animationDelay}ms` }}>
					</div>
				</Grid>
				{
					texts && <Grid item className={classes.textWrapper}>

						<TransitionGroup >
							{
								texts.slice(-1).map((text) => {
									return <CSSTransition nodeRef={text.ref} in timeout={{ enter: textEnterTime, exit: textExitTime }} key={text.key} classNames={'item'}>
										<Typography ref={text.ref}  variant='body2' align='center'  className={classes.text}>
											{text.text}
										</Typography>
									</CSSTransition>
								})
							}
						</TransitionGroup>
					</Grid>
				}
			</Grid>
		</div>
	);
};