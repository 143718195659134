// SRC: https://github.com/OpenAPITools/openapi-generator/blob/master/modules/openapi-generator/src/main/resources/typescript-fetch/apis.mustache
// synched 02.02.2023
import * as yup from 'yup';
import typia from 'typia';
/* tslint:disable */
/* eslint-disable */
/**
 * Lightly API
 * Lightly.ai enables you to do self-supervised learning in an easy and intuitive way. The lightly.ai OpenAPI spec defines how one can interact with our REST API to unleash the full potential of lightly.ai
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: support@lightly.ai
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import * as runtime from '../runtime';
import type { ApiErrorResponse, ConfigurationData, ConfigurationSetRequest, CreateEntityResponse, } from '../models';
import { ApiErrorResponseFromJSON, ApiErrorResponseToJSON, ApiErrorResponseValidator, ConfigurationDataFromJSON, ConfigurationDataToJSON, ConfigurationDataValidator, ConfigurationSetRequestFromJSON, ConfigurationSetRequestToJSON, ConfigurationSetRequestValidator, CreateEntityResponseFromJSON, CreateEntityResponseToJSON, CreateEntityResponseValidator, } from '../models';
import * as models from '../models';
/* LIGHTLY CUSTOM START -> -> moved from bottom to top:  apiMustache */
/* LIGHTLY CUSTOM END -> moved from bottom to top: apiMustache */
export interface CreateMetaDataConfigurationRequest {
    configurationSetRequest: models.ConfigurationSetRequest;
    datasetId: string;
}
/* LIGHTLY CUSTOM START -> apis */
export const CreateMetaDataConfigurationRequestValidatorShape = {
    'configurationSetRequest': models.ConfigurationSetRequestValidator.defined(),
    'datasetId': yup.string().matches(/^[a-f0-9]{24}$/).defined(),
};
export const CreateMetaDataConfigurationRequestValidator = yup.object().shape(CreateMetaDataConfigurationRequestValidatorShape);
export interface GetMetaDataConfigurationByIdRequest {
    datasetId: string;
    configurationId: string;
}
/* LIGHTLY CUSTOM START -> apis */
export const GetMetaDataConfigurationByIdRequestValidatorShape = {
    'datasetId': yup.string().matches(/^[a-f0-9]{24}$/).defined(),
    'configurationId': yup.string().matches(/^[a-f0-9]{24}$/).defined(),
};
export const GetMetaDataConfigurationByIdRequestValidator = yup.object().shape(GetMetaDataConfigurationByIdRequestValidatorShape);
export interface GetMetaDataConfigurationsRequest {
    datasetId: string;
}
/* LIGHTLY CUSTOM START -> apis */
export const GetMetaDataConfigurationsRequestValidatorShape = {
    'datasetId': yup.string().matches(/^[a-f0-9]{24}$/).defined(),
};
export const GetMetaDataConfigurationsRequestValidator = yup.object().shape(GetMetaDataConfigurationsRequestValidatorShape);
export interface UpdateMetaDataConfigurationByIdRequest {
    configurationSetRequest: models.ConfigurationSetRequest;
    datasetId: string;
    configurationId: string;
}
/* LIGHTLY CUSTOM START -> apis */
export const UpdateMetaDataConfigurationByIdRequestValidatorShape = {
    'configurationSetRequest': models.ConfigurationSetRequestValidator.defined(),
    'datasetId': yup.string().matches(/^[a-f0-9]{24}$/).defined(),
    'configurationId': yup.string().matches(/^[a-f0-9]{24}$/).defined(),
};
export const UpdateMetaDataConfigurationByIdRequestValidator = yup.object().shape(UpdateMetaDataConfigurationByIdRequestValidatorShape);
export interface CreateMetaDataConfigurationRequestParams {
    datasetId: string;
}
export function CreateMetaDataConfigurationRequestParamsSanitizer(json: any): CreateMetaDataConfigurationRequestParams {
    return {
        'datasetId': json['datasetId'],
    };
}
export const CreateMetaDataConfigurationRequestParamsValidatorShape = {
    'datasetId': yup.string().matches(/^[a-f0-9]{24}$/).defined(),
};
export const CreateMetaDataConfigurationRequestParamsValidator = yup.object().shape(CreateMetaDataConfigurationRequestParamsValidatorShape);
export interface GetMetaDataConfigurationByIdRequestParams {
    datasetId: string;
    configurationId: string;
}
export function GetMetaDataConfigurationByIdRequestParamsSanitizer(json: any): GetMetaDataConfigurationByIdRequestParams {
    return {
        'datasetId': json['datasetId'],
        'configurationId': json['configurationId'],
    };
}
export const GetMetaDataConfigurationByIdRequestParamsValidatorShape = {
    'datasetId': yup.string().matches(/^[a-f0-9]{24}$/).defined(),
    'configurationId': yup.string().matches(/^[a-f0-9]{24}$/).defined(),
};
export const GetMetaDataConfigurationByIdRequestParamsValidator = yup.object().shape(GetMetaDataConfigurationByIdRequestParamsValidatorShape);
export interface GetMetaDataConfigurationsRequestParams {
    datasetId: string;
}
export function GetMetaDataConfigurationsRequestParamsSanitizer(json: any): GetMetaDataConfigurationsRequestParams {
    return {
        'datasetId': json['datasetId'],
    };
}
export const GetMetaDataConfigurationsRequestParamsValidatorShape = {
    'datasetId': yup.string().matches(/^[a-f0-9]{24}$/).defined(),
};
export const GetMetaDataConfigurationsRequestParamsValidator = yup.object().shape(GetMetaDataConfigurationsRequestParamsValidatorShape);
export interface UpdateMetaDataConfigurationByIdRequestParams {
    datasetId: string;
    configurationId: string;
}
export function UpdateMetaDataConfigurationByIdRequestParamsSanitizer(json: any): UpdateMetaDataConfigurationByIdRequestParams {
    return {
        'datasetId': json['datasetId'],
        'configurationId': json['configurationId'],
    };
}
export const UpdateMetaDataConfigurationByIdRequestParamsValidatorShape = {
    'datasetId': yup.string().matches(/^[a-f0-9]{24}$/).defined(),
    'configurationId': yup.string().matches(/^[a-f0-9]{24}$/).defined(),
};
export const UpdateMetaDataConfigurationByIdRequestParamsValidator = yup.object().shape(UpdateMetaDataConfigurationByIdRequestParamsValidatorShape);
export interface CreateMetaDataConfigurationRequestQuery {
}
export function CreateMetaDataConfigurationRequestQuerySanitizer(json: any): CreateMetaDataConfigurationRequestQuery {
    return {};
}
export const CreateMetaDataConfigurationRequestQueryValidatorShape = {};
export const CreateMetaDataConfigurationRequestQueryValidator = yup.object().shape(CreateMetaDataConfigurationRequestQueryValidatorShape);
export interface GetMetaDataConfigurationByIdRequestQuery {
}
export function GetMetaDataConfigurationByIdRequestQuerySanitizer(json: any): GetMetaDataConfigurationByIdRequestQuery {
    return {};
}
export const GetMetaDataConfigurationByIdRequestQueryValidatorShape = {};
export const GetMetaDataConfigurationByIdRequestQueryValidator = yup.object().shape(GetMetaDataConfigurationByIdRequestQueryValidatorShape);
export interface GetMetaDataConfigurationsRequestQuery {
}
export function GetMetaDataConfigurationsRequestQuerySanitizer(json: any): GetMetaDataConfigurationsRequestQuery {
    return {};
}
export const GetMetaDataConfigurationsRequestQueryValidatorShape = {};
export const GetMetaDataConfigurationsRequestQueryValidator = yup.object().shape(GetMetaDataConfigurationsRequestQueryValidatorShape);
export interface UpdateMetaDataConfigurationByIdRequestQuery {
}
export function UpdateMetaDataConfigurationByIdRequestQuerySanitizer(json: any): UpdateMetaDataConfigurationByIdRequestQuery {
    return {};
}
export const UpdateMetaDataConfigurationByIdRequestQueryValidatorShape = {};
export const UpdateMetaDataConfigurationByIdRequestQueryValidator = yup.object().shape(UpdateMetaDataConfigurationByIdRequestQueryValidatorShape);
export type CreateMetaDataConfigurationRequestBody = ConfigurationSetRequest;
export const CreateMetaDataConfigurationRequestBodySanitizer = ConfigurationSetRequestFromJSON;
export const CreateMetaDataConfigurationRequestBodyValidator = ConfigurationSetRequestValidator;
export const CreateMetaDataConfigurationTypiaAssertEquals = (input: any) => {
    return ((input: any): CreateMetaDataConfigurationRequestBody => {
        const __is = (input: any, _exceptionable: boolean = true): input is CreateMetaDataConfigurationRequestBody => {
            const $io0 = (input: any, _exceptionable: boolean = true): boolean => "string" === typeof input.name && (Array.isArray(input.configs) && input.configs.every((elem: any, _index1: number) => "object" === typeof elem && null !== elem && $io1(elem, true && _exceptionable))) && (2 === Object.keys(input).length || Object.keys(input).every((key: any) => {
                if (["name", "configs"].some((prop: any) => key === prop))
                    return true;
                const value = input[key];
                if (undefined === value)
                    return true;
                return false;
            }));
            const $io1 = (input: any, _exceptionable: boolean = true): boolean => "string" === typeof input.name && 1 <= input.name.length && ("string" === typeof input.path && 1 <= input.path.length) && true && ("NUMERIC_INT" === input.valueDataType || "NUMERIC_FLOAT" === input.valueDataType || "CATEGORICAL_STRING" === input.valueDataType || "CATEGORICAL_INT" === input.valueDataType || "CATEGORICAL_BOOLEAN" === input.valueDataType || "CATEGORICAL_DATETIME" === input.valueDataType || "CATEGORICAL_TIMESTAMP" === input.valueDataType || "OTHER_STRING" === input.valueDataType) && (4 === Object.keys(input).length || Object.keys(input).every((key: any) => {
                if (["name", "path", "defaultValue", "valueDataType"].some((prop: any) => key === prop))
                    return true;
                const value = input[key];
                if (undefined === value)
                    return true;
                return false;
            }));
            return "object" === typeof input && null !== input && $io0(input, true);
        };
        if (false === __is(input))
            ((input: any, _path: string, _exceptionable: boolean = true): input is CreateMetaDataConfigurationRequestBody => {
                const $guard = (typia.createAssertEquals as any).guard;
                const $join = (typia.createAssertEquals as any).join;
                const $ao0 = (input: any, _path: string, _exceptionable: boolean = true): boolean => ("string" === typeof input.name || $guard(_exceptionable, {
                    path: _path + ".name",
                    expected: "string",
                    value: input.name
                })) && ((Array.isArray(input.configs) || $guard(_exceptionable, {
                    path: _path + ".configs",
                    expected: "Array<ConfigurationEntry>",
                    value: input.configs
                })) && input.configs.every((elem: any, _index1: number) => ("object" === typeof elem && null !== elem || $guard(_exceptionable, {
                    path: _path + ".configs[" + _index1 + "]",
                    expected: "ConfigurationEntry",
                    value: elem
                })) && $ao1(elem, _path + ".configs[" + _index1 + "]", true && _exceptionable) || $guard(_exceptionable, {
                    path: _path + ".configs[" + _index1 + "]",
                    expected: "ConfigurationEntry",
                    value: elem
                })) || $guard(_exceptionable, {
                    path: _path + ".configs",
                    expected: "Array<ConfigurationEntry>",
                    value: input.configs
                })) && (2 === Object.keys(input).length || (false === _exceptionable || Object.keys(input).every((key: any) => {
                    if (["name", "configs"].some((prop: any) => key === prop))
                        return true;
                    const value = input[key];
                    if (undefined === value)
                        return true;
                    return $guard(_exceptionable, {
                        path: _path + $join(key),
                        expected: "undefined",
                        value: value
                    });
                })));
                const $ao1 = (input: any, _path: string, _exceptionable: boolean = true): boolean => ("string" === typeof input.name && (1 <= input.name.length || $guard(_exceptionable, {
                    path: _path + ".name",
                    expected: "string (@minLength 1)",
                    value: input.name
                })) || $guard(_exceptionable, {
                    path: _path + ".name",
                    expected: "string",
                    value: input.name
                })) && ("string" === typeof input.path && (1 <= input.path.length || $guard(_exceptionable, {
                    path: _path + ".path",
                    expected: "string (@minLength 1)",
                    value: input.path
                })) || $guard(_exceptionable, {
                    path: _path + ".path",
                    expected: "string",
                    value: input.path
                })) && true && ("NUMERIC_INT" === input.valueDataType || "NUMERIC_FLOAT" === input.valueDataType || "CATEGORICAL_STRING" === input.valueDataType || "CATEGORICAL_INT" === input.valueDataType || "CATEGORICAL_BOOLEAN" === input.valueDataType || "CATEGORICAL_DATETIME" === input.valueDataType || "CATEGORICAL_TIMESTAMP" === input.valueDataType || "OTHER_STRING" === input.valueDataType || $guard(_exceptionable, {
                    path: _path + ".valueDataType",
                    expected: "(\"CATEGORICAL_BOOLEAN\" | \"CATEGORICAL_DATETIME\" | \"CATEGORICAL_INT\" | \"CATEGORICAL_STRING\" | \"CATEGORICAL_TIMESTAMP\" | \"NUMERIC_FLOAT\" | \"NUMERIC_INT\" | \"OTHER_STRING\")",
                    value: input.valueDataType
                })) && (4 === Object.keys(input).length || (false === _exceptionable || Object.keys(input).every((key: any) => {
                    if (["name", "path", "defaultValue", "valueDataType"].some((prop: any) => key === prop))
                        return true;
                    const value = input[key];
                    if (undefined === value)
                        return true;
                    return $guard(_exceptionable, {
                        path: _path + $join(key),
                        expected: "undefined",
                        value: value
                    });
                })));
                return ("object" === typeof input && null !== input || $guard(true, {
                    path: _path + "",
                    expected: "ConfigurationSetRequest",
                    value: input
                })) && $ao0(input, _path + "", true) || $guard(true, {
                    path: _path + "",
                    expected: "ConfigurationSetRequest",
                    value: input
                });
            })(input, "$input", true);
        return input;
    })(input);
};
export const CreateMetaDataConfigurationTypiaAssertStringify = (input: CreateMetaDataConfigurationRequestBody): string => {
    return ((input: any): string => { const assert = (input: any): CreateMetaDataConfigurationRequestBody => {
        const __is = (input: any): input is CreateMetaDataConfigurationRequestBody => {
            const $io0 = (input: any): boolean => "string" === typeof input.name && (Array.isArray(input.configs) && input.configs.every((elem: any) => "object" === typeof elem && null !== elem && $io1(elem)));
            const $io1 = (input: any): boolean => "string" === typeof input.name && 1 <= input.name.length && ("string" === typeof input.path && 1 <= input.path.length) && true && ("NUMERIC_INT" === input.valueDataType || "NUMERIC_FLOAT" === input.valueDataType || "CATEGORICAL_STRING" === input.valueDataType || "CATEGORICAL_INT" === input.valueDataType || "CATEGORICAL_BOOLEAN" === input.valueDataType || "CATEGORICAL_DATETIME" === input.valueDataType || "CATEGORICAL_TIMESTAMP" === input.valueDataType || "OTHER_STRING" === input.valueDataType);
            return "object" === typeof input && null !== input && $io0(input);
        };
        if (false === __is(input))
            ((input: any, _path: string, _exceptionable: boolean = true): input is CreateMetaDataConfigurationRequestBody => {
                const $guard = (typia.createAssertStringify as any).guard;
                const $ao0 = (input: any, _path: string, _exceptionable: boolean = true): boolean => ("string" === typeof input.name || $guard(_exceptionable, {
                    path: _path + ".name",
                    expected: "string",
                    value: input.name
                })) && ((Array.isArray(input.configs) || $guard(_exceptionable, {
                    path: _path + ".configs",
                    expected: "Array<ConfigurationEntry>",
                    value: input.configs
                })) && input.configs.every((elem: any, _index1: number) => ("object" === typeof elem && null !== elem || $guard(_exceptionable, {
                    path: _path + ".configs[" + _index1 + "]",
                    expected: "ConfigurationEntry",
                    value: elem
                })) && $ao1(elem, _path + ".configs[" + _index1 + "]", true && _exceptionable) || $guard(_exceptionable, {
                    path: _path + ".configs[" + _index1 + "]",
                    expected: "ConfigurationEntry",
                    value: elem
                })) || $guard(_exceptionable, {
                    path: _path + ".configs",
                    expected: "Array<ConfigurationEntry>",
                    value: input.configs
                }));
                const $ao1 = (input: any, _path: string, _exceptionable: boolean = true): boolean => ("string" === typeof input.name && (1 <= input.name.length || $guard(_exceptionable, {
                    path: _path + ".name",
                    expected: "string (@minLength 1)",
                    value: input.name
                })) || $guard(_exceptionable, {
                    path: _path + ".name",
                    expected: "string",
                    value: input.name
                })) && ("string" === typeof input.path && (1 <= input.path.length || $guard(_exceptionable, {
                    path: _path + ".path",
                    expected: "string (@minLength 1)",
                    value: input.path
                })) || $guard(_exceptionable, {
                    path: _path + ".path",
                    expected: "string",
                    value: input.path
                })) && true && ("NUMERIC_INT" === input.valueDataType || "NUMERIC_FLOAT" === input.valueDataType || "CATEGORICAL_STRING" === input.valueDataType || "CATEGORICAL_INT" === input.valueDataType || "CATEGORICAL_BOOLEAN" === input.valueDataType || "CATEGORICAL_DATETIME" === input.valueDataType || "CATEGORICAL_TIMESTAMP" === input.valueDataType || "OTHER_STRING" === input.valueDataType || $guard(_exceptionable, {
                    path: _path + ".valueDataType",
                    expected: "(\"CATEGORICAL_BOOLEAN\" | \"CATEGORICAL_DATETIME\" | \"CATEGORICAL_INT\" | \"CATEGORICAL_STRING\" | \"CATEGORICAL_TIMESTAMP\" | \"NUMERIC_FLOAT\" | \"NUMERIC_INT\" | \"OTHER_STRING\")",
                    value: input.valueDataType
                }));
                return ("object" === typeof input && null !== input || $guard(true, {
                    path: _path + "",
                    expected: "ConfigurationSetRequest",
                    value: input
                })) && $ao0(input, _path + "", true) || $guard(true, {
                    path: _path + "",
                    expected: "ConfigurationSetRequest",
                    value: input
                });
            })(input, "$input", true);
        return input;
    }; const stringify = (input: CreateMetaDataConfigurationRequestBody): string => {
        const $io1 = (input: any): boolean => "string" === typeof input.name && 1 <= input.name.length && ("string" === typeof input.path && 1 <= input.path.length) && true && ("NUMERIC_INT" === input.valueDataType || "NUMERIC_FLOAT" === input.valueDataType || "CATEGORICAL_STRING" === input.valueDataType || "CATEGORICAL_INT" === input.valueDataType || "CATEGORICAL_BOOLEAN" === input.valueDataType || "CATEGORICAL_DATETIME" === input.valueDataType || "CATEGORICAL_TIMESTAMP" === input.valueDataType || "OTHER_STRING" === input.valueDataType);
        const $string = (typia.createAssertStringify as any).string;
        const $throws = (typia.createAssertStringify as any).throws;
        const $so0 = (input: any): any => `{"name":${$string(input.name)},"configs":${`[${input.configs.map((elem: any) => $so1(elem)).join(",")}]`}}`;
        const $so1 = (input: any): any => `{${undefined === input.defaultValue || "function" === typeof input.defaultValue ? "" : `"defaultValue":${undefined !== input.defaultValue ? JSON.stringify(input.defaultValue) : undefined},`}"name":${$string(input.name)},"path":${$string(input.path)},"valueDataType":${(() => {
            if ("string" === typeof input.valueDataType)
                return $string(input.valueDataType);
            if ("string" === typeof input.valueDataType)
                return "\"" + input.valueDataType + "\"";
            $throws({
                expected: "(\"CATEGORICAL_BOOLEAN\" | \"CATEGORICAL_DATETIME\" | \"CATEGORICAL_INT\" | \"CATEGORICAL_STRING\" | \"CATEGORICAL_TIMESTAMP\" | \"NUMERIC_FLOAT\" | \"NUMERIC_INT\" | \"OTHER_STRING\")",
                value: input.valueDataType
            });
        })()}}`;
        return $so0(input);
    }; return stringify(assert(input)); })(input);
};
export type UpdateMetaDataConfigurationByIdRequestBody = ConfigurationSetRequest;
export const UpdateMetaDataConfigurationByIdRequestBodySanitizer = ConfigurationSetRequestFromJSON;
export const UpdateMetaDataConfigurationByIdRequestBodyValidator = ConfigurationSetRequestValidator;
export const UpdateMetaDataConfigurationByIdTypiaAssertEquals = (input: any) => {
    return ((input: any): UpdateMetaDataConfigurationByIdRequestBody => {
        const __is = (input: any, _exceptionable: boolean = true): input is UpdateMetaDataConfigurationByIdRequestBody => {
            const $io0 = (input: any, _exceptionable: boolean = true): boolean => "string" === typeof input.name && (Array.isArray(input.configs) && input.configs.every((elem: any, _index1: number) => "object" === typeof elem && null !== elem && $io1(elem, true && _exceptionable))) && (2 === Object.keys(input).length || Object.keys(input).every((key: any) => {
                if (["name", "configs"].some((prop: any) => key === prop))
                    return true;
                const value = input[key];
                if (undefined === value)
                    return true;
                return false;
            }));
            const $io1 = (input: any, _exceptionable: boolean = true): boolean => "string" === typeof input.name && 1 <= input.name.length && ("string" === typeof input.path && 1 <= input.path.length) && true && ("NUMERIC_INT" === input.valueDataType || "NUMERIC_FLOAT" === input.valueDataType || "CATEGORICAL_STRING" === input.valueDataType || "CATEGORICAL_INT" === input.valueDataType || "CATEGORICAL_BOOLEAN" === input.valueDataType || "CATEGORICAL_DATETIME" === input.valueDataType || "CATEGORICAL_TIMESTAMP" === input.valueDataType || "OTHER_STRING" === input.valueDataType) && (4 === Object.keys(input).length || Object.keys(input).every((key: any) => {
                if (["name", "path", "defaultValue", "valueDataType"].some((prop: any) => key === prop))
                    return true;
                const value = input[key];
                if (undefined === value)
                    return true;
                return false;
            }));
            return "object" === typeof input && null !== input && $io0(input, true);
        };
        if (false === __is(input))
            ((input: any, _path: string, _exceptionable: boolean = true): input is UpdateMetaDataConfigurationByIdRequestBody => {
                const $guard = (typia.createAssertEquals as any).guard;
                const $join = (typia.createAssertEquals as any).join;
                const $ao0 = (input: any, _path: string, _exceptionable: boolean = true): boolean => ("string" === typeof input.name || $guard(_exceptionable, {
                    path: _path + ".name",
                    expected: "string",
                    value: input.name
                })) && ((Array.isArray(input.configs) || $guard(_exceptionable, {
                    path: _path + ".configs",
                    expected: "Array<ConfigurationEntry>",
                    value: input.configs
                })) && input.configs.every((elem: any, _index1: number) => ("object" === typeof elem && null !== elem || $guard(_exceptionable, {
                    path: _path + ".configs[" + _index1 + "]",
                    expected: "ConfigurationEntry",
                    value: elem
                })) && $ao1(elem, _path + ".configs[" + _index1 + "]", true && _exceptionable) || $guard(_exceptionable, {
                    path: _path + ".configs[" + _index1 + "]",
                    expected: "ConfigurationEntry",
                    value: elem
                })) || $guard(_exceptionable, {
                    path: _path + ".configs",
                    expected: "Array<ConfigurationEntry>",
                    value: input.configs
                })) && (2 === Object.keys(input).length || (false === _exceptionable || Object.keys(input).every((key: any) => {
                    if (["name", "configs"].some((prop: any) => key === prop))
                        return true;
                    const value = input[key];
                    if (undefined === value)
                        return true;
                    return $guard(_exceptionable, {
                        path: _path + $join(key),
                        expected: "undefined",
                        value: value
                    });
                })));
                const $ao1 = (input: any, _path: string, _exceptionable: boolean = true): boolean => ("string" === typeof input.name && (1 <= input.name.length || $guard(_exceptionable, {
                    path: _path + ".name",
                    expected: "string (@minLength 1)",
                    value: input.name
                })) || $guard(_exceptionable, {
                    path: _path + ".name",
                    expected: "string",
                    value: input.name
                })) && ("string" === typeof input.path && (1 <= input.path.length || $guard(_exceptionable, {
                    path: _path + ".path",
                    expected: "string (@minLength 1)",
                    value: input.path
                })) || $guard(_exceptionable, {
                    path: _path + ".path",
                    expected: "string",
                    value: input.path
                })) && true && ("NUMERIC_INT" === input.valueDataType || "NUMERIC_FLOAT" === input.valueDataType || "CATEGORICAL_STRING" === input.valueDataType || "CATEGORICAL_INT" === input.valueDataType || "CATEGORICAL_BOOLEAN" === input.valueDataType || "CATEGORICAL_DATETIME" === input.valueDataType || "CATEGORICAL_TIMESTAMP" === input.valueDataType || "OTHER_STRING" === input.valueDataType || $guard(_exceptionable, {
                    path: _path + ".valueDataType",
                    expected: "(\"CATEGORICAL_BOOLEAN\" | \"CATEGORICAL_DATETIME\" | \"CATEGORICAL_INT\" | \"CATEGORICAL_STRING\" | \"CATEGORICAL_TIMESTAMP\" | \"NUMERIC_FLOAT\" | \"NUMERIC_INT\" | \"OTHER_STRING\")",
                    value: input.valueDataType
                })) && (4 === Object.keys(input).length || (false === _exceptionable || Object.keys(input).every((key: any) => {
                    if (["name", "path", "defaultValue", "valueDataType"].some((prop: any) => key === prop))
                        return true;
                    const value = input[key];
                    if (undefined === value)
                        return true;
                    return $guard(_exceptionable, {
                        path: _path + $join(key),
                        expected: "undefined",
                        value: value
                    });
                })));
                return ("object" === typeof input && null !== input || $guard(true, {
                    path: _path + "",
                    expected: "ConfigurationSetRequest",
                    value: input
                })) && $ao0(input, _path + "", true) || $guard(true, {
                    path: _path + "",
                    expected: "ConfigurationSetRequest",
                    value: input
                });
            })(input, "$input", true);
        return input;
    })(input);
};
export const UpdateMetaDataConfigurationByIdTypiaAssertStringify = (input: UpdateMetaDataConfigurationByIdRequestBody): string => {
    return ((input: any): string => { const assert = (input: any): UpdateMetaDataConfigurationByIdRequestBody => {
        const __is = (input: any): input is UpdateMetaDataConfigurationByIdRequestBody => {
            const $io0 = (input: any): boolean => "string" === typeof input.name && (Array.isArray(input.configs) && input.configs.every((elem: any) => "object" === typeof elem && null !== elem && $io1(elem)));
            const $io1 = (input: any): boolean => "string" === typeof input.name && 1 <= input.name.length && ("string" === typeof input.path && 1 <= input.path.length) && true && ("NUMERIC_INT" === input.valueDataType || "NUMERIC_FLOAT" === input.valueDataType || "CATEGORICAL_STRING" === input.valueDataType || "CATEGORICAL_INT" === input.valueDataType || "CATEGORICAL_BOOLEAN" === input.valueDataType || "CATEGORICAL_DATETIME" === input.valueDataType || "CATEGORICAL_TIMESTAMP" === input.valueDataType || "OTHER_STRING" === input.valueDataType);
            return "object" === typeof input && null !== input && $io0(input);
        };
        if (false === __is(input))
            ((input: any, _path: string, _exceptionable: boolean = true): input is UpdateMetaDataConfigurationByIdRequestBody => {
                const $guard = (typia.createAssertStringify as any).guard;
                const $ao0 = (input: any, _path: string, _exceptionable: boolean = true): boolean => ("string" === typeof input.name || $guard(_exceptionable, {
                    path: _path + ".name",
                    expected: "string",
                    value: input.name
                })) && ((Array.isArray(input.configs) || $guard(_exceptionable, {
                    path: _path + ".configs",
                    expected: "Array<ConfigurationEntry>",
                    value: input.configs
                })) && input.configs.every((elem: any, _index1: number) => ("object" === typeof elem && null !== elem || $guard(_exceptionable, {
                    path: _path + ".configs[" + _index1 + "]",
                    expected: "ConfigurationEntry",
                    value: elem
                })) && $ao1(elem, _path + ".configs[" + _index1 + "]", true && _exceptionable) || $guard(_exceptionable, {
                    path: _path + ".configs[" + _index1 + "]",
                    expected: "ConfigurationEntry",
                    value: elem
                })) || $guard(_exceptionable, {
                    path: _path + ".configs",
                    expected: "Array<ConfigurationEntry>",
                    value: input.configs
                }));
                const $ao1 = (input: any, _path: string, _exceptionable: boolean = true): boolean => ("string" === typeof input.name && (1 <= input.name.length || $guard(_exceptionable, {
                    path: _path + ".name",
                    expected: "string (@minLength 1)",
                    value: input.name
                })) || $guard(_exceptionable, {
                    path: _path + ".name",
                    expected: "string",
                    value: input.name
                })) && ("string" === typeof input.path && (1 <= input.path.length || $guard(_exceptionable, {
                    path: _path + ".path",
                    expected: "string (@minLength 1)",
                    value: input.path
                })) || $guard(_exceptionable, {
                    path: _path + ".path",
                    expected: "string",
                    value: input.path
                })) && true && ("NUMERIC_INT" === input.valueDataType || "NUMERIC_FLOAT" === input.valueDataType || "CATEGORICAL_STRING" === input.valueDataType || "CATEGORICAL_INT" === input.valueDataType || "CATEGORICAL_BOOLEAN" === input.valueDataType || "CATEGORICAL_DATETIME" === input.valueDataType || "CATEGORICAL_TIMESTAMP" === input.valueDataType || "OTHER_STRING" === input.valueDataType || $guard(_exceptionable, {
                    path: _path + ".valueDataType",
                    expected: "(\"CATEGORICAL_BOOLEAN\" | \"CATEGORICAL_DATETIME\" | \"CATEGORICAL_INT\" | \"CATEGORICAL_STRING\" | \"CATEGORICAL_TIMESTAMP\" | \"NUMERIC_FLOAT\" | \"NUMERIC_INT\" | \"OTHER_STRING\")",
                    value: input.valueDataType
                }));
                return ("object" === typeof input && null !== input || $guard(true, {
                    path: _path + "",
                    expected: "ConfigurationSetRequest",
                    value: input
                })) && $ao0(input, _path + "", true) || $guard(true, {
                    path: _path + "",
                    expected: "ConfigurationSetRequest",
                    value: input
                });
            })(input, "$input", true);
        return input;
    }; const stringify = (input: UpdateMetaDataConfigurationByIdRequestBody): string => {
        const $io1 = (input: any): boolean => "string" === typeof input.name && 1 <= input.name.length && ("string" === typeof input.path && 1 <= input.path.length) && true && ("NUMERIC_INT" === input.valueDataType || "NUMERIC_FLOAT" === input.valueDataType || "CATEGORICAL_STRING" === input.valueDataType || "CATEGORICAL_INT" === input.valueDataType || "CATEGORICAL_BOOLEAN" === input.valueDataType || "CATEGORICAL_DATETIME" === input.valueDataType || "CATEGORICAL_TIMESTAMP" === input.valueDataType || "OTHER_STRING" === input.valueDataType);
        const $string = (typia.createAssertStringify as any).string;
        const $throws = (typia.createAssertStringify as any).throws;
        const $so0 = (input: any): any => `{"name":${$string(input.name)},"configs":${`[${input.configs.map((elem: any) => $so1(elem)).join(",")}]`}}`;
        const $so1 = (input: any): any => `{${undefined === input.defaultValue || "function" === typeof input.defaultValue ? "" : `"defaultValue":${undefined !== input.defaultValue ? JSON.stringify(input.defaultValue) : undefined},`}"name":${$string(input.name)},"path":${$string(input.path)},"valueDataType":${(() => {
            if ("string" === typeof input.valueDataType)
                return $string(input.valueDataType);
            if ("string" === typeof input.valueDataType)
                return "\"" + input.valueDataType + "\"";
            $throws({
                expected: "(\"CATEGORICAL_BOOLEAN\" | \"CATEGORICAL_DATETIME\" | \"CATEGORICAL_INT\" | \"CATEGORICAL_STRING\" | \"CATEGORICAL_TIMESTAMP\" | \"NUMERIC_FLOAT\" | \"NUMERIC_INT\" | \"OTHER_STRING\")",
                value: input.valueDataType
            });
        })()}}`;
        return $so0(input);
    }; return stringify(assert(input)); })(input);
};
/*
HANDLERS:



/v1/datasets/{datasetId}/configuration/metadata

export const createMetaDataConfigurationFN: RequestHandler<
    CreateMetaDataConfigurationRequestParams,
    CreateEntityResponse | ApiErrorResponse,
    CreateMetaDataConfigurationRequestBody,
    CreateMetaDataConfigurationRequestQuery
> = async (req, res) => {
    if (!ensureUserOnReq(req)) {
        return
    }

    const {
        
    } = await validateAndSanitizeStrict(req.body, CreateMetaDataConfigurationRequestBodySanitizer, CreateMetaDataConfigurationRequestBodyValidator);
    const {
        
        datasetId,
    } =  await validateAndSanitize(req.params, CreateMetaDataConfigurationRequestParamsSanitizer, CreateMetaDataConfigurationRequestParamsValidator);
    const {
        
        
    } = await validateAndSanitize(req.query, CreateMetaDataConfigurationRequestQuerySanitizer, CreateMetaDataConfigurationRequestQueryValidator);



    
    const schema = ....

    const { _id: id, ...rest } = schema;
    return fastJSONResponse(
        res,
        CreateEntityResponseStringify,
        validateAndRespond({ id, ...rest }, CreateEntityResponseFromJSON, CreateEntityResponseValidator),
    );
}



/v1/datasets/{datasetId}/configuration/metadata/{configurationId}

export const getMetaDataConfigurationByIdFN: RequestHandler<
    GetMetaDataConfigurationByIdRequestParams,
    ConfigurationData | ApiErrorResponse,
    GetMetaDataConfigurationByIdRequestBody,
    GetMetaDataConfigurationByIdRequestQuery
> = async (req, res) => {
    if (!ensureUserOnReq(req)) {
        return
    }

    const {
        
    } = await validateAndSanitizeStrict(req.body, GetMetaDataConfigurationByIdRequestBodySanitizer, GetMetaDataConfigurationByIdRequestBodyValidator);
    const {
        datasetId,
        configurationId,
    } =  await validateAndSanitize(req.params, GetMetaDataConfigurationByIdRequestParamsSanitizer, GetMetaDataConfigurationByIdRequestParamsValidator);
    const {
        
        
    } = await validateAndSanitize(req.query, GetMetaDataConfigurationByIdRequestQuerySanitizer, GetMetaDataConfigurationByIdRequestQueryValidator);



    
    const schema = ....

    const { _id: id, ...rest } = schema;
    return fastJSONResponse(
        res,
        ConfigurationDataStringify,
        validateAndRespond({ id, ...rest }, ConfigurationDataFromJSON, ConfigurationDataValidator),
    );
}



/v1/datasets/{datasetId}/configuration/metadata
const ConfigurationDataArrayStringify = fastJSONArrayify(ConfigurationDataStringifyShape);
export const getMetaDataConfigurationsFN: RequestHandler<
    GetMetaDataConfigurationsRequestParams,
    ConfigurationData[] | ApiErrorResponse,
    GetMetaDataConfigurationsRequestBody,
    GetMetaDataConfigurationsRequestQuery
> = async (req, res) => {
    if (!ensureUserOnReq(req)) {
        return
    }

    const {
        
    } = await validateAndSanitizeStrict(req.body, GetMetaDataConfigurationsRequestBodySanitizer, GetMetaDataConfigurationsRequestBodyValidator);
    const {
        datasetId,
    } =  await validateAndSanitize(req.params, GetMetaDataConfigurationsRequestParamsSanitizer, GetMetaDataConfigurationsRequestParamsValidator);
    const {
        
    } = await validateAndSanitize(req.query, GetMetaDataConfigurationsRequestQuerySanitizer, GetMetaDataConfigurationsRequestQueryValidator);



    
    const schemas = ....

    return fastJSONResponse(
        res,
        ConfigurationDataArrayStringify,
        schemas.map((schema) => {
            const { _id: id, ...rest } = schema;
            return validateAndRespond({ id, ...rest }, ConfigurationDataFromJSON, ConfigurationDataValidator);
        }),
    );
}



/v1/datasets/{datasetId}/configuration/metadata/{configurationId}

export const updateMetaDataConfigurationByIdFN: RequestHandler<
    UpdateMetaDataConfigurationByIdRequestParams,
     | ApiErrorResponse,
    UpdateMetaDataConfigurationByIdRequestBody,
    UpdateMetaDataConfigurationByIdRequestQuery
> = async (req, res) => {
    if (!ensureUserOnReq(req)) {
        return
    }

    const {
        
    } = await validateAndSanitizeStrict(req.body, UpdateMetaDataConfigurationByIdRequestBodySanitizer, UpdateMetaDataConfigurationByIdRequestBodyValidator);
    const {
        
        datasetId,
        configurationId,
    } =  await validateAndSanitize(req.params, UpdateMetaDataConfigurationByIdRequestParamsSanitizer, UpdateMetaDataConfigurationByIdRequestParamsValidator);
    const {
        
        
        
    } = await validateAndSanitize(req.query, UpdateMetaDataConfigurationByIdRequestQuerySanitizer, UpdateMetaDataConfigurationByIdRequestQueryValidator);



    
    const schema = ....

    const { _id: id, ...rest } = schema;
    return fastJSONResponse(
        res,
        Stringify,
        validateAndRespond({ id, ...rest }, FromJSON, Validator),
    );
}


*/
/* LIGHTLY CUSTOM END -> api */
/**
 * MetaDataConfigurationsApi - interface
 *
 * @export
 * @interface MetaDataConfigurationsApiInterface
 */
export interface MetaDataConfigurationsApiInterface {
    /**
     * Create a new metadata configuration
     * @param {ConfigurationSetRequest} configurationSetRequest
     * @param {string} datasetId ObjectId of the dataset
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MetaDataConfigurationsApiInterface
     */
    createMetaDataConfigurationRaw(requestParameters: CreateMetaDataConfigurationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CreateEntityResponse>>;
    /**
     * Create a new metadata configuration
     */
    createMetaDataConfiguration(requestParameters: CreateMetaDataConfigurationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CreateEntityResponse>;
    /**
     * Get a specific metadata configuration
     * @param {string} datasetId ObjectId of the dataset
     * @param {string} configurationId ObjectId of the metadata configuration
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MetaDataConfigurationsApiInterface
     */
    getMetaDataConfigurationByIdRaw(requestParameters: GetMetaDataConfigurationByIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ConfigurationData>>;
    /**
     * Get a specific metadata configuration
     */
    getMetaDataConfigurationById(requestParameters: GetMetaDataConfigurationByIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ConfigurationData>;
    /**
     * Get the all metadata configurations that exist for a user
     * @param {string} datasetId ObjectId of the dataset
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MetaDataConfigurationsApiInterface
     */
    getMetaDataConfigurationsRaw(requestParameters: GetMetaDataConfigurationsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<ConfigurationData>>>;
    /**
     * Get the all metadata configurations that exist for a user
     */
    getMetaDataConfigurations(requestParameters: GetMetaDataConfigurationsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<ConfigurationData>>;
    /**
     * update a specific metadata configuration
     * @param {ConfigurationSetRequest} configurationSetRequest
     * @param {string} datasetId ObjectId of the dataset
     * @param {string} configurationId ObjectId of the metadata configuration
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MetaDataConfigurationsApiInterface
     */
    updateMetaDataConfigurationByIdRaw(requestParameters: UpdateMetaDataConfigurationByIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>>;
    /**
     * update a specific metadata configuration
     */
    updateMetaDataConfigurationById(requestParameters: UpdateMetaDataConfigurationByIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void>;
}
/**
 *
 */
export class MetaDataConfigurationsApi extends runtime.BaseAPI implements MetaDataConfigurationsApiInterface {
    /**
     * Create a new metadata configuration
     */
    async createMetaDataConfigurationRaw(requestParameters: CreateMetaDataConfigurationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CreateEntityResponse>> {
        if (requestParameters.configurationSetRequest === null || requestParameters.configurationSetRequest === undefined) {
            throw new runtime.RequiredError('configurationSetRequest', 'Required parameter requestParameters.configurationSetRequest was null or undefined when calling createMetaDataConfiguration.');
        }
        if (requestParameters.datasetId === null || requestParameters.datasetId === undefined) {
            throw new runtime.RequiredError('datasetId', 'Required parameter requestParameters.datasetId was null or undefined when calling createMetaDataConfiguration.');
        }
        const queryParameters: any = {};
        const headerParameters: runtime.HTTPHeaders = {};
        headerParameters['Content-Type'] = 'application/json';
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("auth0Bearer", []);
            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        if (this.configuration && this.configuration.apiKey) {
            queryParameters["token"] = this.configuration.apiKey("token"); // ApiKeyAuth authentication
        }
        const response = await this.request({
            path: `/v1/datasets/{datasetId}/configuration/metadata`.replace(`{${"datasetId"}}`, encodeURIComponent(String(requestParameters.datasetId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ConfigurationSetRequestToJSON(requestParameters.configurationSetRequest),
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => CreateEntityResponseFromJSON(jsonValue));
    }
    /**
     * Create a new metadata configuration
     */
    async createMetaDataConfiguration(requestParameters: CreateMetaDataConfigurationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CreateEntityResponse> {
        const response = await this.createMetaDataConfigurationRaw(requestParameters, initOverrides);
        return await response.value();
    }
    /**
     * Get a specific metadata configuration
     */
    async getMetaDataConfigurationByIdRaw(requestParameters: GetMetaDataConfigurationByIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ConfigurationData>> {
        if (requestParameters.datasetId === null || requestParameters.datasetId === undefined) {
            throw new runtime.RequiredError('datasetId', 'Required parameter requestParameters.datasetId was null or undefined when calling getMetaDataConfigurationById.');
        }
        if (requestParameters.configurationId === null || requestParameters.configurationId === undefined) {
            throw new runtime.RequiredError('configurationId', 'Required parameter requestParameters.configurationId was null or undefined when calling getMetaDataConfigurationById.');
        }
        const queryParameters: any = {};
        const headerParameters: runtime.HTTPHeaders = {};
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("auth0Bearer", []);
            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        if (this.configuration && this.configuration.apiKey) {
            queryParameters["token"] = this.configuration.apiKey("token"); // ApiKeyAuth authentication
        }
        const response = await this.request({
            path: `/v1/datasets/{datasetId}/configuration/metadata/{configurationId}`.replace(`{${"datasetId"}}`, encodeURIComponent(String(requestParameters.datasetId))).replace(`{${"configurationId"}}`, encodeURIComponent(String(requestParameters.configurationId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => ConfigurationDataFromJSON(jsonValue));
    }
    /**
     * Get a specific metadata configuration
     */
    async getMetaDataConfigurationById(requestParameters: GetMetaDataConfigurationByIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ConfigurationData> {
        const response = await this.getMetaDataConfigurationByIdRaw(requestParameters, initOverrides);
        return await response.value();
    }
    /**
     * Get the all metadata configurations that exist for a user
     */
    async getMetaDataConfigurationsRaw(requestParameters: GetMetaDataConfigurationsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<ConfigurationData>>> {
        if (requestParameters.datasetId === null || requestParameters.datasetId === undefined) {
            throw new runtime.RequiredError('datasetId', 'Required parameter requestParameters.datasetId was null or undefined when calling getMetaDataConfigurations.');
        }
        const queryParameters: any = {};
        const headerParameters: runtime.HTTPHeaders = {};
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("auth0Bearer", []);
            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        if (this.configuration && this.configuration.apiKey) {
            queryParameters["token"] = this.configuration.apiKey("token"); // ApiKeyAuth authentication
        }
        const response = await this.request({
            path: `/v1/datasets/{datasetId}/configuration/metadata`.replace(`{${"datasetId"}}`, encodeURIComponent(String(requestParameters.datasetId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ConfigurationDataFromJSON));
    }
    /**
     * Get the all metadata configurations that exist for a user
     */
    async getMetaDataConfigurations(requestParameters: GetMetaDataConfigurationsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<ConfigurationData>> {
        const response = await this.getMetaDataConfigurationsRaw(requestParameters, initOverrides);
        return await response.value();
    }
    /**
     * update a specific metadata configuration
     */
    async updateMetaDataConfigurationByIdRaw(requestParameters: UpdateMetaDataConfigurationByIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.configurationSetRequest === null || requestParameters.configurationSetRequest === undefined) {
            throw new runtime.RequiredError('configurationSetRequest', 'Required parameter requestParameters.configurationSetRequest was null or undefined when calling updateMetaDataConfigurationById.');
        }
        if (requestParameters.datasetId === null || requestParameters.datasetId === undefined) {
            throw new runtime.RequiredError('datasetId', 'Required parameter requestParameters.datasetId was null or undefined when calling updateMetaDataConfigurationById.');
        }
        if (requestParameters.configurationId === null || requestParameters.configurationId === undefined) {
            throw new runtime.RequiredError('configurationId', 'Required parameter requestParameters.configurationId was null or undefined when calling updateMetaDataConfigurationById.');
        }
        const queryParameters: any = {};
        const headerParameters: runtime.HTTPHeaders = {};
        headerParameters['Content-Type'] = 'application/json';
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("auth0Bearer", []);
            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        if (this.configuration && this.configuration.apiKey) {
            queryParameters["token"] = this.configuration.apiKey("token"); // ApiKeyAuth authentication
        }
        const response = await this.request({
            path: `/v1/datasets/{datasetId}/configuration/metadata/{configurationId}`.replace(`{${"datasetId"}}`, encodeURIComponent(String(requestParameters.datasetId))).replace(`{${"configurationId"}}`, encodeURIComponent(String(requestParameters.configurationId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: ConfigurationSetRequestToJSON(requestParameters.configurationSetRequest),
        }, initOverrides);
        return new runtime.VoidApiResponse(response);
    }
    /**
     * update a specific metadata configuration
     */
    async updateMetaDataConfigurationById(requestParameters: UpdateMetaDataConfigurationByIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.updateMetaDataConfigurationByIdRaw(requestParameters, initOverrides);
    }
}
