import { useState, useEffect } from 'react';

import { matchPath } from 'react-router-dom';

import { DatasetDataEnriched } from '@lightly/api-spec';
import { Features } from '@lightly/common';

import { useIsFeatureActivated } from '../../helpers/access';
import { useGetProfileOfLoggedInUser, useGetDatasetsEnriched } from '../../helpers/api';
import { datasetDetailRoute, questionnaireRoute, tutorialRoute , routesAllowedWithoutQuestionnaire, routesAllowedWithoutDatasets } from '../../routes';
import { makeRouteURL } from '../../routes.fn';
import { IRoute } from '../../routes.types';



export const useRedirectForOnboarding = (hasToken = false, currentRoute: IRoute) => {
	const [canViewOnboardingPages, canViewOnboardingPagesIsLoading] = useIsFeatureActivated(hasToken ? Features['page:onboarding'] : undefined);
	const {
		data: profileData,
		isLoading: getProfileIsLoading,
	} = useGetProfileOfLoggedInUser(canViewOnboardingPages && hasToken ? undefined : null);

	// use the same query as used for the datasets in DatasetsSneak which is used on home
	const [refreshInterval, setRefreshInterval] = useState(0);
	const {
		data: datasetsData,
		isLoading: datasetsIsLoading,
	} = useGetDatasetsEnriched(canViewOnboardingPages && hasToken ? { pageOffset: 0, pageSize: 3 } : null, { refreshInterval, refreshWhenHidden: true })



	const nonPlaygroundDatasets = getNonPlaygroundDatasets(datasetsData);
	const nonPlaygroundCompletedDatasets = getNonPlaygroundCompletedDatasets(nonPlaygroundDatasets);

	const hasOnlyPlaygroundDatasets = nonPlaygroundDatasets.length === 0;
	const hasOnlyOneNonPlaygroundDataset = nonPlaygroundDatasets.length === 1;

	// it could be that a user does not have any own datasets but got access to shared datasets
	// very edge case and was added because of AVL logging in with the wrong account (which is under the same email)
	const { data: datasetsSharedData, isLoading: datasetsSharedIsLoading } = useGetDatasetsEnriched(canViewOnboardingPages && hasToken ? { shared:true, pageSize: 6 } : null);

	// only refresh the datasets as long as there are only playground datasets
	useEffect(() => {
		if (nonPlaygroundCompletedDatasets.length > 1) {
			setRefreshInterval(0);
		}
		else {
			setRefreshInterval(20_000);
		}
	},[nonPlaygroundCompletedDatasets]);

	const isLoading = canViewOnboardingPagesIsLoading || getProfileIsLoading || datasetsIsLoading || datasetsSharedIsLoading;

	let path = '';
	if (!isLoading && profileData && canViewOnboardingPages) {
		const { onboarding } = profileData || {};

		const goToQuestionnairePage = !onboarding || onboarding === 0;
		const goToTutorialsPage = hasOnlyPlaygroundDatasets;
		const goToDatasetPage = hasOnlyOneNonPlaygroundDataset;

		if (goToQuestionnairePage && !routesAllowedWithoutQuestionnaire.includes(currentRoute) ) {
			path = questionnaireRoute.path;
		}
		else if (goToTutorialsPage && !routesAllowedWithoutQuestionnaire.includes(currentRoute) ) {
			path = tutorialRoute.path;
		}
		else if (goToDatasetPage && !routesAllowedWithoutDatasets.includes(currentRoute) ) {
			const dataset = nonPlaygroundDatasets[0];
			if (dataset.nTags === 0 ) {
				const routePath = makeRouteURL(datasetDetailRoute, { datasetId: dataset.id })
				path = routePath;
			}
		}

		// if the user has no datasets, but got access to shared datasets, skip onboarding
		if (datasetsSharedData && datasetsSharedData.length >= 1) {
			path = '';
		}
	}

	// if we should redirect to the already present page, don't infinite redirect
	if (path && currentRoute.path && matchPath(path, { path: currentRoute.path, exact:true }) ) {
		path = '';
	}

	return { path, isLoading }
}

const getNonPlaygroundDatasets = (datasets: DatasetDataEnriched[] = []) => {
	return datasets.filter((dataset) => {
		return !dataset.name.toLowerCase().startsWith('playground') && !dataset.originalDatasetId;
	})
}

const getNonPlaygroundCompletedDatasets = (datasets: DatasetDataEnriched[] = []) => {
	return datasets.filter((dataset) => {
		return dataset.nTags > 0
	})
}