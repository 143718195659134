/* tslint:disable */
/* eslint-disable */
/**
 * Lightly API
 * Lightly.ai enables you to do self-supervised learning in an easy and intuitive way. The lightly.ai OpenAPI spec defines how one can interact with our REST API to unleash the full potential of lightly.ai
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: support@lightly.ai
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
// SRC: https://github.com/OpenAPITools/openapi-generator/blob/master/modules/openapi-generator/src/main/resources/typescript-fetch/modelEnumInterfaces.mustache
// synched 02.02.2023
import * as yup from 'yup';
import * as fastJSON from 'fast-json-stringify';
import { StringSchema } from 'fast-json-stringify';
/**
 *
 * @export
 * @enum {string}
 */
export enum JobResultType {
    DATASET_PROCESSING = 'DATASET_PROCESSING',
    IMAGEMETA = 'IMAGEMETA',
    EMBEDDING = 'EMBEDDING',
    EMBEDDINGS2D = 'EMBEDDINGS2D',
    SAMPLING = 'SAMPLING'
}
/* LIGHTLY CUSTOM START -> modelEnumInterfaces */
export const JobResultTypeValidatorShape = [
    'DATASET_PROCESSING',
    'IMAGEMETA',
    'EMBEDDING',
    'EMBEDDINGS2D',
    'SAMPLING'
];
export const JobResultTypeValidator = yup.mixed().oneOf(JobResultTypeValidatorShape);
export const JobResultTypeStringifyShapeProperties = {};
export const JobResultTypeStringifyShape: StringSchema = {
    title: 'JobResultType Stringifier',
    type: 'string'
};
export const JobResultTypeStringify = (typeof process === 'object' && String(process) === '[object process]') ?
    fastJSON.build(JobResultTypeStringifyShape)
    :
        () => { throw new Error('fast-json-stringify only works in nodejs'); };
/* LIGHTLY CUSTOM END */
export function JobResultTypeFromJSON(json: any): JobResultType {
    return JobResultTypeFromJSONTyped(json, false);
}
export function JobResultTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): JobResultType {
    return json as JobResultType;
}
export function JobResultTypeToJSON(value?: JobResultType | null): any {
    return value as any;
}
