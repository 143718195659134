/* tslint:disable */
/* eslint-disable */
/**
 * Lightly API
 * Lightly.ai enables you to do self-supervised learning in an easy and intuitive way. The lightly.ai OpenAPI spec defines how one can interact with our REST API to unleash the full potential of lightly.ai
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: support@lightly.ai
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
// SRC: https://github.com/OpenAPITools/openapi-generator/blob/master/modules/openapi-generator/src/main/resources/typescript-fetch/modelEnumInterfaces.mustache
// synched 02.02.2023
import * as yup from 'yup';
import * as fastJSON from 'fast-json-stringify';
import { StringSchema } from 'fast-json-stringify';
/**
 *
 * @export
 * @enum {string}
 */
export enum JobState {
    UNKNOWN = 'UNKNOWN',
    WAITING = 'WAITING',
    RUNNING = 'RUNNING',
    FAILED = 'FAILED',
    FINISHED = 'FINISHED'
}
/* LIGHTLY CUSTOM START -> modelEnumInterfaces */
export const JobStateValidatorShape = [
    'UNKNOWN',
    'WAITING',
    'RUNNING',
    'FAILED',
    'FINISHED'
];
export const JobStateValidator = yup.mixed().oneOf(JobStateValidatorShape);
export const JobStateStringifyShapeProperties = {};
export const JobStateStringifyShape: StringSchema = {
    title: 'JobState Stringifier',
    type: 'string'
};
export const JobStateStringify = (typeof process === 'object' && String(process) === '[object process]') ?
    fastJSON.build(JobStateStringifyShape)
    :
        () => { throw new Error('fast-json-stringify only works in nodejs'); };
/* LIGHTLY CUSTOM END */
export function JobStateFromJSON(json: any): JobState {
    return JobStateFromJSONTyped(json, false);
}
export function JobStateFromJSONTyped(json: any, ignoreDiscriminator: boolean): JobState {
    return json as JobState;
}
export function JobStateToJSON(value?: JobState | null): any {
    return value as any;
}
