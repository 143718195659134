import { createContext, useContext } from 'react';

import { Auth0Provider as Auth0ProviderReal, Auth0ProviderOptions, useAuth0 as useAuth0Real, User as IAuth0User, Auth0ContextInterface } from '@auth0/auth0-react';
import { RedirectLoginOptions, GetTokenSilentlyOptions, GetTokenSilentlyVerboseResponse } from '@auth0/auth0-spa-js';
import { Optional } from 'utility-types';

const ROOT_USER_EMAIL = 'noauth_root@local.docker';
const ROOT_USER: IAuth0User = {
	name: 'noauth_root',
	picture: '', // URL for the user's pucture
	updated_at: '',
	email: ROOT_USER_EMAIL,
	email_verified: true,
	sub: ROOT_USER_EMAIL,
};

function getAccessTokenSilentlyFake(
	options: GetTokenSilentlyOptions & { detailedResponse: true }
  ): Promise<GetTokenSilentlyVerboseResponse>;
function getAccessTokenSilentlyFake(options?: GetTokenSilentlyOptions): Promise<string>;
function getAccessTokenSilentlyFake(options?: GetTokenSilentlyOptions) {
	return options?.detailedResponse ?
		Promise.resolve({
			id_token: 'ROOT_AUTH0_DISABLED',
			access_token: 'ROOT_AUTH0_DISABLED',
			expires_in: 12124124124124,
		})
		:
		Promise.resolve('ROOT_AUTH0_DISABLED')
}


export const Auth0ContextFakeConfig = {
	isAuthenticated: true,
	isLoading: false,
	user: ROOT_USER,
	handleRedirectCallback: (_url?: string | undefined) => Promise.resolve({}),
	loginWithRedirect: (_options?: RedirectLoginOptions) => Promise.resolve(),
	getAccessTokenSilently: getAccessTokenSilentlyFake,
	// eslint-disable-next-line @typescript-eslint/no-empty-function
	logout: () => { },
	getAccessTokenWithPopup: () => Promise.resolve('ROOT_AUTH0_DISABLED'),
	getIdTokenClaims: () => Promise.resolve(undefined),
	loginWithPopup: () => Promise.resolve(undefined),
}
export const Auth0ContextFake = createContext<Auth0ContextInterface<IAuth0User>>(Auth0ContextFakeConfig);

export const useAuth0 = String(process.env.REACT_APP_AUTH0_ENABLED) === 'true' ?
	useAuth0Real
	:
	(): Auth0ContextInterface<IAuth0User> => {
		return useContext(Auth0ContextFake);
	};


export const Auth0Provider: React.FC<Optional<Auth0ProviderOptions>> = (opts) => {
	const config: Auth0ProviderOptions = {
		...opts,
		domain: `${process.env.REACT_APP_AUTH0_DOMAIN || 'REACT_APP_AUTH0_DOMAIN not defined'}`,
		clientId: `${process.env.REACT_APP_AUTH0_CLIENT_ID || 'REACT_APP_AUTH0_CLIENT_ID not defined'}`,
		authorizationParams: {
			audience: `${process.env.REACT_APP_AUTH0_AUDIENCE || 'REACT_APP_AUTH0_AUDIENCE not defined'}`,
			redirect_uri: window.location.origin,
			...String(process.env.REACT_APP_AUTH0_DOCKERAUTH) === 'true' ?
				{
					scope: 'openid email profile offline_access',
				}
				:
				{},
		},
		useFormData: true,
		...String(process.env.REACT_APP_AUTH0_DOCKERAUTH) === 'true' ?
			{
				// docker auth has a weird handling of iframes..
				useRefreshTokens: true,
				useRefreshTokensFallback: true,
				timeoutInSeconds: 20, //faster fail
			}
			:
			{},

		...(
			// if AUTH0 is disabled, we need to fake this
			String(process.env.REACT_APP_AUTH0_ENABLED) === 'true' ?
				{}
				:
				Auth0ContextFakeConfig
		),
	}
	return Auth0ProviderReal(config);
}