/* tslint:disable */
/* eslint-disable */
/**
 * Lightly API
 * Lightly.ai enables you to do self-supervised learning in an easy and intuitive way. The lightly.ai OpenAPI spec defines how one can interact with our REST API to unleash the full potential of lightly.ai
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: support@lightly.ai
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
// SRC: https://github.com/OpenAPITools/openapi-generator/blob/master/modules/openapi-generator/src/main/resources/typescript-fetch/modelGeneric.mustache
// synched 02.02.2023
import * as yup from 'yup';
import typia from 'typia';
import * as fastJSON from 'fast-json-stringify';
import { Options, ArraySchema, ObjectSchema } from 'fast-json-stringify';
import { exists, mapValues } from '../runtime';
import type { JobState } from './JobState';
import { JobStateFromJSON, JobStateFromJSONTyped, JobStateToJSON, } from './JobState';
import type { JobStatusDataResult } from './JobStatusDataResult';
import { JobStatusDataResultFromJSON, JobStatusDataResultFromJSONTyped, JobStatusDataResultToJSON, } from './JobStatusDataResult';
import type { JobStatusMeta } from './JobStatusMeta';
import { JobStatusMetaFromJSON, JobStatusMetaFromJSONTyped, JobStatusMetaToJSON, } from './JobStatusMeta';
/*
causes circular dependendies
*/
// SRC: https://github.com/OpenAPITools/openapi-generator/blob/master/modules/openapi-generator/src/main/resources/typescript-fetch/modelGenericInterfaces.mustache
// synced: 17.07.2023
/**
 *
 * @export
 * @interface JobStatusData
 *
 */
// create custom validators for the regexes
typia.customValidators.insert("pattern_id")("string")(() => {
    const regex = new RegExp(/^[a-f0-9]{24}$/);
    return (value: string) => !!regex.exec(value);
});
typia.customValidators.insert("pattern_datasetId")("string")(() => {
    const regex = new RegExp(/^[a-f0-9]{24}$/);
    return (value: string) => !!regex.exec(value);
});
export interface JobStatusData {
    /**
     * MongoDB ObjectId
     * #type string
     * #memberof JobStatusData
        
        
    * @pattern_id
         */
    id: string;
    /**
     * MongoDB ObjectId
     * #type string
     * #memberof JobStatusData
        
        
    * @pattern_datasetId
         */
    datasetId?: string;
    /**
     *
     * #type JobState
     * #memberof JobStatusData
        
        
         */
    status: JobState;
    /**
     *
     * #type JobStatusMeta
     * #memberof JobStatusData
        
        
         */
    meta?: JobStatusMeta;
    /**
     * The time in seconds the client should wait before doing the next poll.
     * #type number
     * #memberof JobStatusData
        * @type int
        
         */
    waitTimeTillNextPoll: number;
    /**
     * unix timestamp in milliseconds
     * #type number
     * #memberof JobStatusData
        
    * @minimum 0
            
         */
    createdAt: number;
    /**
     * unix timestamp in milliseconds
     * #type number
     * #memberof JobStatusData
        
    * @minimum 0
            
         */
    lastModifiedAt?: number;
    /**
     * unix timestamp in milliseconds
     * #type number
     * #memberof JobStatusData
        
    * @minimum 0
            
         */
    finishedAt?: number;
    /**
     *
     * #type string
     * #memberof JobStatusData
        
        
         */
    error?: string;
    /**
     *
     * #type JobStatusDataResult
     * #memberof JobStatusData
        
        
         */
    result?: JobStatusDataResult;
}
/**
 * Check if a given object implements the JobStatusData interface.
 */
export function instanceOfJobStatusData(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "status" in value;
    isInstance = isInstance && "waitTimeTillNextPoll" in value;
    isInstance = isInstance && "createdAt" in value;
    return isInstance;
}
/* LIGHTLY CUSTOM START -> modelGeneric */
import { JobStateValidator, JobStateStringifyShape, JobStateStringifyShapeProperties, } from './JobState';
import { JobStatusDataResultValidator, JobStatusDataResultStringifyShape, JobStatusDataResultStringifyShapeProperties, } from './JobStatusDataResult';
import { JobStatusMetaValidator, JobStatusMetaStringifyShape, JobStatusMetaStringifyShapeProperties, } from './JobStatusMeta';
export const JobStatusDataTypiaAssertEquals = (input: any) => {
    return ((input: any): JobStatusData => {
        const __is = (input: any, _exceptionable: boolean = true): input is JobStatusData => {
            const $is_custom = (typia.createAssertEquals as any).is_custom;
            const $io0 = (input: any, _exceptionable: boolean = true): boolean => "string" === typeof input.id && $is_custom("pattern_id", "string", "", input.id) && (undefined === input.datasetId || "string" === typeof input.datasetId && $is_custom("pattern_datasetId", "string", "", input.datasetId)) && ("UNKNOWN" === input.status || "WAITING" === input.status || "RUNNING" === input.status || "FAILED" === input.status || "FINISHED" === input.status) && (undefined === input.meta || "object" === typeof input.meta && null !== input.meta && $io1(input.meta, true && _exceptionable)) && ("number" === typeof input.waitTimeTillNextPoll && Math.floor(input.waitTimeTillNextPoll) === input.waitTimeTillNextPoll && (-2147483648 <= input.waitTimeTillNextPoll && input.waitTimeTillNextPoll <= 2147483647)) && ("number" === typeof input.createdAt && 0 <= input.createdAt) && (undefined === input.lastModifiedAt || "number" === typeof input.lastModifiedAt && 0 <= input.lastModifiedAt) && (undefined === input.finishedAt || "number" === typeof input.finishedAt && 0 <= input.finishedAt) && (undefined === input.error || "string" === typeof input.error) && (undefined === input.result || "object" === typeof input.result && null !== input.result && $io2(input.result, true && _exceptionable)) && (4 === Object.keys(input).length || Object.keys(input).every((key: any) => {
                if (["id", "datasetId", "status", "meta", "waitTimeTillNextPoll", "createdAt", "lastModifiedAt", "finishedAt", "error", "result"].some((prop: any) => key === prop))
                    return true;
                const value = input[key];
                if (undefined === value)
                    return true;
                return false;
            }));
            const $io1 = (input: any, _exceptionable: boolean = true): boolean => "number" === typeof input.total && Math.floor(input.total) === input.total && (-2147483648 <= input.total && input.total <= 2147483647) && ("number" === typeof input.processed && Math.floor(input.processed) === input.processed && (-2147483648 <= input.processed && input.processed <= 2147483647)) && (undefined === input.uploadMethod || "USER_WEBAPP" === input.uploadMethod || "USER_PIP" === input.uploadMethod || "INTERNAL" === input.uploadMethod) && (undefined === input.isRegistered || "boolean" === typeof input.isRegistered) && (2 === Object.keys(input).length || Object.keys(input).every((key: any) => {
                if (["total", "processed", "uploadMethod", "isRegistered"].some((prop: any) => key === prop))
                    return true;
                const value = input[key];
                if (undefined === value)
                    return true;
                return false;
            }));
            const $io2 = (input: any, _exceptionable: boolean = true): boolean => ("DATASET_PROCESSING" === input.type || "IMAGEMETA" === input.type || "EMBEDDING" === input.type || "EMBEDDINGS2D" === input.type || "SAMPLING" === input.type) && true && (1 === Object.keys(input).length || Object.keys(input).every((key: any) => {
                if (["type", "data"].some((prop: any) => key === prop))
                    return true;
                const value = input[key];
                if (undefined === value)
                    return true;
                return false;
            }));
            return "object" === typeof input && null !== input && $io0(input, true);
        };
        if (false === __is(input))
            ((input: any, _path: string, _exceptionable: boolean = true): input is JobStatusData => {
                const $guard = (typia.createAssertEquals as any).guard;
                const $is_custom = (typia.createAssertEquals as any).is_custom;
                const $join = (typia.createAssertEquals as any).join;
                const $ao0 = (input: any, _path: string, _exceptionable: boolean = true): boolean => ("string" === typeof input.id && ($is_custom("pattern_id", "string", "", input.id) || $guard(_exceptionable, {
                    path: _path + ".id",
                    expected: "string (@pattern_id)",
                    value: input.id
                })) || $guard(_exceptionable, {
                    path: _path + ".id",
                    expected: "string",
                    value: input.id
                })) && (undefined === input.datasetId || "string" === typeof input.datasetId && ($is_custom("pattern_datasetId", "string", "", input.datasetId) || $guard(_exceptionable, {
                    path: _path + ".datasetId",
                    expected: "string (@pattern_datasetId)",
                    value: input.datasetId
                })) || $guard(_exceptionable, {
                    path: _path + ".datasetId",
                    expected: "(string | undefined)",
                    value: input.datasetId
                })) && ("UNKNOWN" === input.status || "WAITING" === input.status || "RUNNING" === input.status || "FAILED" === input.status || "FINISHED" === input.status || $guard(_exceptionable, {
                    path: _path + ".status",
                    expected: "(\"FAILED\" | \"FINISHED\" | \"RUNNING\" | \"UNKNOWN\" | \"WAITING\")",
                    value: input.status
                })) && (undefined === input.meta || ("object" === typeof input.meta && null !== input.meta || $guard(_exceptionable, {
                    path: _path + ".meta",
                    expected: "(JobStatusMeta | undefined)",
                    value: input.meta
                })) && $ao1(input.meta, _path + ".meta", true && _exceptionable) || $guard(_exceptionable, {
                    path: _path + ".meta",
                    expected: "(JobStatusMeta | undefined)",
                    value: input.meta
                })) && ("number" === typeof input.waitTimeTillNextPoll && (Math.floor(input.waitTimeTillNextPoll) === input.waitTimeTillNextPoll || $guard(_exceptionable, {
                    path: _path + ".waitTimeTillNextPoll",
                    expected: "number (@type int)",
                    value: input.waitTimeTillNextPoll
                })) && (-2147483648 <= input.waitTimeTillNextPoll && input.waitTimeTillNextPoll <= 2147483647 || $guard(_exceptionable, {
                    path: _path + ".waitTimeTillNextPoll",
                    expected: "number (@type int)",
                    value: input.waitTimeTillNextPoll
                })) || $guard(_exceptionable, {
                    path: _path + ".waitTimeTillNextPoll",
                    expected: "number",
                    value: input.waitTimeTillNextPoll
                })) && ("number" === typeof input.createdAt && (0 <= input.createdAt || $guard(_exceptionable, {
                    path: _path + ".createdAt",
                    expected: "number (@minimum 0)",
                    value: input.createdAt
                })) || $guard(_exceptionable, {
                    path: _path + ".createdAt",
                    expected: "number",
                    value: input.createdAt
                })) && (undefined === input.lastModifiedAt || "number" === typeof input.lastModifiedAt && (0 <= input.lastModifiedAt || $guard(_exceptionable, {
                    path: _path + ".lastModifiedAt",
                    expected: "number (@minimum 0)",
                    value: input.lastModifiedAt
                })) || $guard(_exceptionable, {
                    path: _path + ".lastModifiedAt",
                    expected: "(number | undefined)",
                    value: input.lastModifiedAt
                })) && (undefined === input.finishedAt || "number" === typeof input.finishedAt && (0 <= input.finishedAt || $guard(_exceptionable, {
                    path: _path + ".finishedAt",
                    expected: "number (@minimum 0)",
                    value: input.finishedAt
                })) || $guard(_exceptionable, {
                    path: _path + ".finishedAt",
                    expected: "(number | undefined)",
                    value: input.finishedAt
                })) && (undefined === input.error || "string" === typeof input.error || $guard(_exceptionable, {
                    path: _path + ".error",
                    expected: "(string | undefined)",
                    value: input.error
                })) && (undefined === input.result || ("object" === typeof input.result && null !== input.result || $guard(_exceptionable, {
                    path: _path + ".result",
                    expected: "(JobStatusDataResult | undefined)",
                    value: input.result
                })) && $ao2(input.result, _path + ".result", true && _exceptionable) || $guard(_exceptionable, {
                    path: _path + ".result",
                    expected: "(JobStatusDataResult | undefined)",
                    value: input.result
                })) && (4 === Object.keys(input).length || (false === _exceptionable || Object.keys(input).every((key: any) => {
                    if (["id", "datasetId", "status", "meta", "waitTimeTillNextPoll", "createdAt", "lastModifiedAt", "finishedAt", "error", "result"].some((prop: any) => key === prop))
                        return true;
                    const value = input[key];
                    if (undefined === value)
                        return true;
                    return $guard(_exceptionable, {
                        path: _path + $join(key),
                        expected: "undefined",
                        value: value
                    });
                })));
                const $ao1 = (input: any, _path: string, _exceptionable: boolean = true): boolean => ("number" === typeof input.total && (Math.floor(input.total) === input.total || $guard(_exceptionable, {
                    path: _path + ".total",
                    expected: "number (@type int)",
                    value: input.total
                })) && (-2147483648 <= input.total && input.total <= 2147483647 || $guard(_exceptionable, {
                    path: _path + ".total",
                    expected: "number (@type int)",
                    value: input.total
                })) || $guard(_exceptionable, {
                    path: _path + ".total",
                    expected: "number",
                    value: input.total
                })) && ("number" === typeof input.processed && (Math.floor(input.processed) === input.processed || $guard(_exceptionable, {
                    path: _path + ".processed",
                    expected: "number (@type int)",
                    value: input.processed
                })) && (-2147483648 <= input.processed && input.processed <= 2147483647 || $guard(_exceptionable, {
                    path: _path + ".processed",
                    expected: "number (@type int)",
                    value: input.processed
                })) || $guard(_exceptionable, {
                    path: _path + ".processed",
                    expected: "number",
                    value: input.processed
                })) && (undefined === input.uploadMethod || "USER_WEBAPP" === input.uploadMethod || "USER_PIP" === input.uploadMethod || "INTERNAL" === input.uploadMethod || $guard(_exceptionable, {
                    path: _path + ".uploadMethod",
                    expected: "(\"INTERNAL\" | \"USER_PIP\" | \"USER_WEBAPP\" | undefined)",
                    value: input.uploadMethod
                })) && (undefined === input.isRegistered || "boolean" === typeof input.isRegistered || $guard(_exceptionable, {
                    path: _path + ".isRegistered",
                    expected: "(boolean | undefined)",
                    value: input.isRegistered
                })) && (2 === Object.keys(input).length || (false === _exceptionable || Object.keys(input).every((key: any) => {
                    if (["total", "processed", "uploadMethod", "isRegistered"].some((prop: any) => key === prop))
                        return true;
                    const value = input[key];
                    if (undefined === value)
                        return true;
                    return $guard(_exceptionable, {
                        path: _path + $join(key),
                        expected: "undefined",
                        value: value
                    });
                })));
                const $ao2 = (input: any, _path: string, _exceptionable: boolean = true): boolean => ("DATASET_PROCESSING" === input.type || "IMAGEMETA" === input.type || "EMBEDDING" === input.type || "EMBEDDINGS2D" === input.type || "SAMPLING" === input.type || $guard(_exceptionable, {
                    path: _path + ".type",
                    expected: "(\"DATASET_PROCESSING\" | \"EMBEDDING\" | \"EMBEDDINGS2D\" | \"IMAGEMETA\" | \"SAMPLING\")",
                    value: input.type
                })) && true && (1 === Object.keys(input).length || (false === _exceptionable || Object.keys(input).every((key: any) => {
                    if (["type", "data"].some((prop: any) => key === prop))
                        return true;
                    const value = input[key];
                    if (undefined === value)
                        return true;
                    return $guard(_exceptionable, {
                        path: _path + $join(key),
                        expected: "undefined",
                        value: value
                    });
                })));
                return ("object" === typeof input && null !== input || $guard(true, {
                    path: _path + "",
                    expected: "JobStatusData",
                    value: input
                })) && $ao0(input, _path + "", true) || $guard(true, {
                    path: _path + "",
                    expected: "JobStatusData",
                    value: input
                });
            })(input, "$input", true);
        return input;
    })(input);
};
export const JobStatusDataTypiaAssertStringify = (input: JobStatusData): string => {
    return ((input: any): string => { const assert = (input: any): JobStatusData => {
        const __is = (input: any): input is JobStatusData => {
            const $is_custom = (typia.createAssertStringify as any).is_custom;
            const $io0 = (input: any): boolean => "string" === typeof input.id && $is_custom("pattern_id", "string", "", input.id) && (undefined === input.datasetId || "string" === typeof input.datasetId && $is_custom("pattern_datasetId", "string", "", input.datasetId)) && ("UNKNOWN" === input.status || "WAITING" === input.status || "RUNNING" === input.status || "FAILED" === input.status || "FINISHED" === input.status) && (undefined === input.meta || "object" === typeof input.meta && null !== input.meta && $io1(input.meta)) && ("number" === typeof input.waitTimeTillNextPoll && !Number.isNaN(input.waitTimeTillNextPoll) && Math.floor(input.waitTimeTillNextPoll) === input.waitTimeTillNextPoll && (-2147483648 <= input.waitTimeTillNextPoll && input.waitTimeTillNextPoll <= 2147483647)) && ("number" === typeof input.createdAt && !Number.isNaN(input.createdAt) && 0 <= input.createdAt) && (undefined === input.lastModifiedAt || "number" === typeof input.lastModifiedAt && !Number.isNaN(input.lastModifiedAt) && 0 <= input.lastModifiedAt) && (undefined === input.finishedAt || "number" === typeof input.finishedAt && !Number.isNaN(input.finishedAt) && 0 <= input.finishedAt) && (undefined === input.error || "string" === typeof input.error) && (undefined === input.result || "object" === typeof input.result && null !== input.result && $io2(input.result));
            const $io1 = (input: any): boolean => "number" === typeof input.total && !Number.isNaN(input.total) && Math.floor(input.total) === input.total && (-2147483648 <= input.total && input.total <= 2147483647) && ("number" === typeof input.processed && !Number.isNaN(input.processed) && Math.floor(input.processed) === input.processed && (-2147483648 <= input.processed && input.processed <= 2147483647)) && (undefined === input.uploadMethod || "USER_WEBAPP" === input.uploadMethod || "USER_PIP" === input.uploadMethod || "INTERNAL" === input.uploadMethod) && (undefined === input.isRegistered || "boolean" === typeof input.isRegistered);
            const $io2 = (input: any): boolean => ("DATASET_PROCESSING" === input.type || "IMAGEMETA" === input.type || "EMBEDDING" === input.type || "EMBEDDINGS2D" === input.type || "SAMPLING" === input.type) && true;
            return "object" === typeof input && null !== input && $io0(input);
        };
        if (false === __is(input))
            ((input: any, _path: string, _exceptionable: boolean = true): input is JobStatusData => {
                const $guard = (typia.createAssertStringify as any).guard;
                const $is_custom = (typia.createAssertStringify as any).is_custom;
                const $ao0 = (input: any, _path: string, _exceptionable: boolean = true): boolean => ("string" === typeof input.id && ($is_custom("pattern_id", "string", "", input.id) || $guard(_exceptionable, {
                    path: _path + ".id",
                    expected: "string (@pattern_id)",
                    value: input.id
                })) || $guard(_exceptionable, {
                    path: _path + ".id",
                    expected: "string",
                    value: input.id
                })) && (undefined === input.datasetId || "string" === typeof input.datasetId && ($is_custom("pattern_datasetId", "string", "", input.datasetId) || $guard(_exceptionable, {
                    path: _path + ".datasetId",
                    expected: "string (@pattern_datasetId)",
                    value: input.datasetId
                })) || $guard(_exceptionable, {
                    path: _path + ".datasetId",
                    expected: "(string | undefined)",
                    value: input.datasetId
                })) && ("UNKNOWN" === input.status || "WAITING" === input.status || "RUNNING" === input.status || "FAILED" === input.status || "FINISHED" === input.status || $guard(_exceptionable, {
                    path: _path + ".status",
                    expected: "(\"FAILED\" | \"FINISHED\" | \"RUNNING\" | \"UNKNOWN\" | \"WAITING\")",
                    value: input.status
                })) && (undefined === input.meta || ("object" === typeof input.meta && null !== input.meta || $guard(_exceptionable, {
                    path: _path + ".meta",
                    expected: "(JobStatusMeta | undefined)",
                    value: input.meta
                })) && $ao1(input.meta, _path + ".meta", true && _exceptionable) || $guard(_exceptionable, {
                    path: _path + ".meta",
                    expected: "(JobStatusMeta | undefined)",
                    value: input.meta
                })) && ("number" === typeof input.waitTimeTillNextPoll && !Number.isNaN(input.waitTimeTillNextPoll) && (Math.floor(input.waitTimeTillNextPoll) === input.waitTimeTillNextPoll || $guard(_exceptionable, {
                    path: _path + ".waitTimeTillNextPoll",
                    expected: "number (@type int)",
                    value: input.waitTimeTillNextPoll
                })) && (-2147483648 <= input.waitTimeTillNextPoll && input.waitTimeTillNextPoll <= 2147483647 || $guard(_exceptionable, {
                    path: _path + ".waitTimeTillNextPoll",
                    expected: "number (@type int)",
                    value: input.waitTimeTillNextPoll
                })) || $guard(_exceptionable, {
                    path: _path + ".waitTimeTillNextPoll",
                    expected: "number",
                    value: input.waitTimeTillNextPoll
                })) && ("number" === typeof input.createdAt && !Number.isNaN(input.createdAt) && (0 <= input.createdAt || $guard(_exceptionable, {
                    path: _path + ".createdAt",
                    expected: "number (@minimum 0)",
                    value: input.createdAt
                })) || $guard(_exceptionable, {
                    path: _path + ".createdAt",
                    expected: "number",
                    value: input.createdAt
                })) && (undefined === input.lastModifiedAt || "number" === typeof input.lastModifiedAt && !Number.isNaN(input.lastModifiedAt) && (0 <= input.lastModifiedAt || $guard(_exceptionable, {
                    path: _path + ".lastModifiedAt",
                    expected: "number (@minimum 0)",
                    value: input.lastModifiedAt
                })) || $guard(_exceptionable, {
                    path: _path + ".lastModifiedAt",
                    expected: "(number | undefined)",
                    value: input.lastModifiedAt
                })) && (undefined === input.finishedAt || "number" === typeof input.finishedAt && !Number.isNaN(input.finishedAt) && (0 <= input.finishedAt || $guard(_exceptionable, {
                    path: _path + ".finishedAt",
                    expected: "number (@minimum 0)",
                    value: input.finishedAt
                })) || $guard(_exceptionable, {
                    path: _path + ".finishedAt",
                    expected: "(number | undefined)",
                    value: input.finishedAt
                })) && (undefined === input.error || "string" === typeof input.error || $guard(_exceptionable, {
                    path: _path + ".error",
                    expected: "(string | undefined)",
                    value: input.error
                })) && (undefined === input.result || ("object" === typeof input.result && null !== input.result || $guard(_exceptionable, {
                    path: _path + ".result",
                    expected: "(JobStatusDataResult | undefined)",
                    value: input.result
                })) && $ao2(input.result, _path + ".result", true && _exceptionable) || $guard(_exceptionable, {
                    path: _path + ".result",
                    expected: "(JobStatusDataResult | undefined)",
                    value: input.result
                }));
                const $ao1 = (input: any, _path: string, _exceptionable: boolean = true): boolean => ("number" === typeof input.total && !Number.isNaN(input.total) && (Math.floor(input.total) === input.total || $guard(_exceptionable, {
                    path: _path + ".total",
                    expected: "number (@type int)",
                    value: input.total
                })) && (-2147483648 <= input.total && input.total <= 2147483647 || $guard(_exceptionable, {
                    path: _path + ".total",
                    expected: "number (@type int)",
                    value: input.total
                })) || $guard(_exceptionable, {
                    path: _path + ".total",
                    expected: "number",
                    value: input.total
                })) && ("number" === typeof input.processed && !Number.isNaN(input.processed) && (Math.floor(input.processed) === input.processed || $guard(_exceptionable, {
                    path: _path + ".processed",
                    expected: "number (@type int)",
                    value: input.processed
                })) && (-2147483648 <= input.processed && input.processed <= 2147483647 || $guard(_exceptionable, {
                    path: _path + ".processed",
                    expected: "number (@type int)",
                    value: input.processed
                })) || $guard(_exceptionable, {
                    path: _path + ".processed",
                    expected: "number",
                    value: input.processed
                })) && (undefined === input.uploadMethod || "USER_WEBAPP" === input.uploadMethod || "USER_PIP" === input.uploadMethod || "INTERNAL" === input.uploadMethod || $guard(_exceptionable, {
                    path: _path + ".uploadMethod",
                    expected: "(\"INTERNAL\" | \"USER_PIP\" | \"USER_WEBAPP\" | undefined)",
                    value: input.uploadMethod
                })) && (undefined === input.isRegistered || "boolean" === typeof input.isRegistered || $guard(_exceptionable, {
                    path: _path + ".isRegistered",
                    expected: "(boolean | undefined)",
                    value: input.isRegistered
                }));
                const $ao2 = (input: any, _path: string, _exceptionable: boolean = true): boolean => ("DATASET_PROCESSING" === input.type || "IMAGEMETA" === input.type || "EMBEDDING" === input.type || "EMBEDDINGS2D" === input.type || "SAMPLING" === input.type || $guard(_exceptionable, {
                    path: _path + ".type",
                    expected: "(\"DATASET_PROCESSING\" | \"EMBEDDING\" | \"EMBEDDINGS2D\" | \"IMAGEMETA\" | \"SAMPLING\")",
                    value: input.type
                })) && true;
                return ("object" === typeof input && null !== input || $guard(true, {
                    path: _path + "",
                    expected: "JobStatusData",
                    value: input
                })) && $ao0(input, _path + "", true) || $guard(true, {
                    path: _path + "",
                    expected: "JobStatusData",
                    value: input
                });
            })(input, "$input", true);
        return input;
    }; const stringify = (input: JobStatusData): string => {
        const $io1 = (input: any): boolean => "number" === typeof input.total && Math.floor(input.total) === input.total && (-2147483648 <= input.total && input.total <= 2147483647) && ("number" === typeof input.processed && Math.floor(input.processed) === input.processed && (-2147483648 <= input.processed && input.processed <= 2147483647)) && (undefined === input.uploadMethod || "USER_WEBAPP" === input.uploadMethod || "USER_PIP" === input.uploadMethod || "INTERNAL" === input.uploadMethod) && (undefined === input.isRegistered || "boolean" === typeof input.isRegistered);
        const $io2 = (input: any): boolean => ("DATASET_PROCESSING" === input.type || "IMAGEMETA" === input.type || "EMBEDDING" === input.type || "EMBEDDINGS2D" === input.type || "SAMPLING" === input.type) && true;
        const $string = (typia.createAssertStringify as any).string;
        const $throws = (typia.createAssertStringify as any).throws;
        const $is_custom = (typia.createAssertStringify as any).is_custom;
        const $so0 = (input: any): any => `{${undefined === input.datasetId ? "" : `"datasetId":${undefined !== input.datasetId ? $string(input.datasetId) : undefined},`}${undefined === input.meta ? "" : `"meta":${undefined !== input.meta ? $so1(input.meta) : undefined},`}${undefined === input.lastModifiedAt ? "" : `"lastModifiedAt":${undefined !== input.lastModifiedAt ? input.lastModifiedAt : undefined},`}${undefined === input.finishedAt ? "" : `"finishedAt":${undefined !== input.finishedAt ? input.finishedAt : undefined},`}${undefined === input.error ? "" : `"error":${undefined !== input.error ? $string(input.error) : undefined},`}${undefined === input.result ? "" : `"result":${undefined !== input.result ? $so2(input.result) : undefined},`}"id":${$string(input.id)},"status":${(() => {
            if ("string" === typeof input.status)
                return $string(input.status);
            if ("string" === typeof input.status)
                return "\"" + input.status + "\"";
            $throws({
                expected: "(\"FAILED\" | \"FINISHED\" | \"RUNNING\" | \"UNKNOWN\" | \"WAITING\")",
                value: input.status
            });
        })()},"waitTimeTillNextPoll":${input.waitTimeTillNextPoll},"createdAt":${input.createdAt}}`;
        const $so1 = (input: any): any => `{${undefined === input.uploadMethod ? "" : `"uploadMethod":${undefined !== input.uploadMethod ? (() => {
            if ("string" === typeof input.uploadMethod)
                return $string(input.uploadMethod);
            if ("string" === typeof input.uploadMethod)
                return "\"" + input.uploadMethod + "\"";
            $throws({
                expected: "(\"INTERNAL\" | \"USER_PIP\" | \"USER_WEBAPP\" | undefined)",
                value: input.uploadMethod
            });
        })() : undefined},`}${undefined === input.isRegistered ? "" : `"isRegistered":${undefined !== input.isRegistered ? input.isRegistered : undefined},`}"total":${input.total},"processed":${input.processed}}`;
        const $so2 = (input: any): any => `{${undefined === input.data || "function" === typeof input.data ? "" : `"data":${undefined !== input.data ? JSON.stringify(input.data) : undefined},`}"type":${(() => {
            if ("string" === typeof input.type)
                return $string(input.type);
            if ("string" === typeof input.type)
                return "\"" + input.type + "\"";
            $throws({
                expected: "(\"DATASET_PROCESSING\" | \"EMBEDDING\" | \"EMBEDDINGS2D\" | \"IMAGEMETA\" | \"SAMPLING\")",
                value: input.type
            });
        })()}}`;
        return $so0(input);
    }; return stringify(assert(input)); })(input);
};
export const JobStatusDataValidatorShape = {
    'id': yup.string().matches(/^[a-f0-9]{24}$/).defined(),
    'datasetId': yup.string().matches(/^[a-f0-9]{24}$/),
    'status': JobStateValidator.defined(),
    'meta': JobStatusMetaValidator.optional().default(undefined),
    'waitTimeTillNextPoll': yup.number().integer().defined(),
    'createdAt': yup.number().min(0).defined(),
    'lastModifiedAt': yup.number().min(0),
    'finishedAt': yup.number().min(0),
    'error': yup.string(),
    'result': JobStatusDataResultValidator.optional().default(undefined),
};
export const JobStatusDataValidator = yup.object().shape(JobStatusDataValidatorShape);
export const JobStatusDataStringifyShapeProperties: ObjectSchema['properties'] = {
    'id': {
        'type': 'string',
        'pattern': "/^[a-f0-9]{24}$/".slice(1, -1),
    } as unknown as fastJSON.StringSchema,
    'datasetId': {
        'type': 'string',
        'pattern': "/^[a-f0-9]{24}$/".slice(1, -1),
    } as unknown as fastJSON.StringSchema,
    'status': JobStateStringifyShape,
    'meta': JobStatusMetaStringifyShape,
    'waitTimeTillNextPoll': {
        'type': 'integer',
    } as unknown as fastJSON.IntegerSchema,
    'createdAt': {
        'type': 'number',
        'minimum': 0,
    } as unknown as fastJSON.NumberSchema,
    'lastModifiedAt': {
        'type': 'number',
        'minimum': 0,
    } as unknown as fastJSON.NumberSchema,
    'finishedAt': {
        'type': 'number',
        'minimum': 0,
    } as unknown as fastJSON.NumberSchema,
    'error': {
        'type': 'string',
    } as unknown as fastJSON.StringSchema,
    'result': JobStatusDataResultStringifyShape,
};
export const JobStatusDataStringifyShape: ObjectSchema = {
    title: 'JobStatusData Stringifier',
    type: 'object',
    properties: {
        ...JobStatusDataStringifyShapeProperties
    },
    required: [
        'id',
        'status',
        'waitTimeTillNextPoll',
        'createdAt',
    ],
};
export const JobStatusDataStringifyCompiled = (typeof process === 'object' && String(process) === '[object process]') ?
    fastJSON.build(JobStatusDataStringifyShape, {
        ajv: {
            // since we are already doing this with yup, lets skip
            validateSchema: false,
            ownProperties: true,
            validateFormats: false,
        },
        largeArrayMechanism: 'json-stringify',
        largeArraySize: 1000,
    } as Options)
    :
        () => { throw new Error('fast-json-stringify only works in nodejs'); };
export const JobStatusDataStringify: (data: JobStatusData) => string = JobStatusDataStringifyCompiled;
/* LIGHTLY CUSTOM END -> Generic */
export function JobStatusDataFromJSON(json: any): JobStatusData {
    return JobStatusDataFromJSONTyped(json, false);
}
export function JobStatusDataFromJSONTyped(json: any, ignoreDiscriminator: boolean): JobStatusData {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    /* causes circular depdendencies
*/
    return {
        'id': json['id'],
        'datasetId': !exists(json, 'datasetId') ? undefined : json['datasetId'],
        'status': JobStateFromJSON(json['status']),
        'meta': !exists(json, 'meta') ? undefined : JobStatusMetaFromJSON(json['meta']),
        'waitTimeTillNextPoll': json['waitTimeTillNextPoll'],
        'createdAt': json['createdAt'],
        'lastModifiedAt': !exists(json, 'lastModifiedAt') ? undefined : json['lastModifiedAt'],
        'finishedAt': !exists(json, 'finishedAt') ? undefined : json['finishedAt'],
        'error': !exists(json, 'error') ? undefined : json['error'],
        'result': !exists(json, 'result') ? undefined : JobStatusDataResultFromJSON(json['result']),
    };
}
export function JobStatusDataToJSON(value?: JobStatusData | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'id': value.id,
        'datasetId': value.datasetId,
        'status': JobStateToJSON(value.status),
        'meta': JobStatusMetaToJSON(value.meta),
        'waitTimeTillNextPoll': value.waitTimeTillNextPoll,
        'createdAt': value.createdAt,
        'lastModifiedAt': value.lastModifiedAt,
        'finishedAt': value.finishedAt,
        'error': value.error,
        'result': JobStatusDataResultToJSON(value.result),
    };
}
