export const dockerworker = {
	// states
	'field_state_OFFLINE': 'offline',
	'field_state_CRASHED': 'crashed/offline',
	'field_state_IDLE': 'idle',
	'field_state_BUSY': 'busy',

	// create form
	'field_createDockerWorkerRegistryEntryRequest.name': 'Name',
	'field_createDockerWorkerRegistryEntryRequest.workerType': 'Type',
	'field_createDockerWorkerRegistryEntryRequest.labels': 'Labels',
}
export default dockerworker;
