/* tslint:disable */
/* eslint-disable */
/**
 * Lightly API
 * Lightly.ai enables you to do self-supervised learning in an easy and intuitive way. The lightly.ai OpenAPI spec defines how one can interact with our REST API to unleash the full potential of lightly.ai
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: support@lightly.ai
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
// SRC: https://github.com/OpenAPITools/openapi-generator/blob/master/modules/openapi-generator/src/main/resources/typescript-fetch/modelEnumInterfaces.mustache
// synched 02.02.2023
import * as yup from 'yup';
import * as fastJSON from 'fast-json-stringify';
import { StringSchema } from 'fast-json-stringify';
/**
 *
 * @export
 * @enum {string}
 */
export enum Sector {
    ADVERTISING = 'ADVERTISING',
    AGRICULTURE = 'AGRICULTURE',
    AUTOMOTIVE = 'AUTOMOTIVE',
    EDUCATION = 'EDUCATION',
    ENERGY = 'ENERGY',
    ENTERTAINMENT = 'ENTERTAINMENT',
    ENVIRONMENTAL = 'ENVIRONMENTAL',
    FINANCE = 'FINANCE',
    FOOD = 'FOOD',
    HEALTHCARE = 'HEALTHCARE',
    INTERNET_OF_THINGS = 'INTERNET_OF_THINGS',
    LOGISTICS = 'LOGISTICS',
    MACHINE_LEARNING = 'MACHINE_LEARNING',
    MANUFACTURING = 'MANUFACTURING',
    MEDICINE = 'MEDICINE',
    RECYCLING = 'RECYCLING',
    RETAIL = 'RETAIL',
    ROBOTICS = 'ROBOTICS',
    SECURITY = 'SECURITY',
    SOFTWARE_DEVELOPMENT = 'SOFTWARE_DEVELOPMENT',
    SPORTS = 'SPORTS',
    SURVEILLANCE = 'SURVEILLANCE',
    TRANSPORTATION = 'TRANSPORTATION',
    OTHER = 'OTHER'
}
/* LIGHTLY CUSTOM START -> modelEnumInterfaces */
export const SectorValidatorShape = [
    'ADVERTISING',
    'AGRICULTURE',
    'AUTOMOTIVE',
    'EDUCATION',
    'ENERGY',
    'ENTERTAINMENT',
    'ENVIRONMENTAL',
    'FINANCE',
    'FOOD',
    'HEALTHCARE',
    'INTERNET_OF_THINGS',
    'LOGISTICS',
    'MACHINE_LEARNING',
    'MANUFACTURING',
    'MEDICINE',
    'RECYCLING',
    'RETAIL',
    'ROBOTICS',
    'SECURITY',
    'SOFTWARE_DEVELOPMENT',
    'SPORTS',
    'SURVEILLANCE',
    'TRANSPORTATION',
    'OTHER'
];
export const SectorValidator = yup.mixed().oneOf(SectorValidatorShape);
export const SectorStringifyShapeProperties = {};
export const SectorStringifyShape: StringSchema = {
    title: 'Sector Stringifier',
    type: 'string'
};
export const SectorStringify = (typeof process === 'object' && String(process) === '[object process]') ?
    fastJSON.build(SectorStringifyShape)
    :
        () => { throw new Error('fast-json-stringify only works in nodejs'); };
/* LIGHTLY CUSTOM END */
export function SectorFromJSON(json: any): Sector {
    return SectorFromJSONTyped(json, false);
}
export function SectorFromJSONTyped(json: any, ignoreDiscriminator: boolean): Sector {
    return json as Sector;
}
export function SectorToJSON(value?: Sector | null): any {
    return value as any;
}
