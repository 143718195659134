/* tslint:disable */
/* eslint-disable */
/**
 * Lightly API
 * Lightly.ai enables you to do self-supervised learning in an easy and intuitive way. The lightly.ai OpenAPI spec defines how one can interact with our REST API to unleash the full potential of lightly.ai
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: support@lightly.ai
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
// SRC: https://github.com/OpenAPITools/openapi-generator/blob/master/modules/openapi-generator/src/main/resources/typescript-fetch/modelEnumInterfaces.mustache
// synched 02.02.2023
import * as yup from 'yup';
import * as fastJSON from 'fast-json-stringify';
import { StringSchema } from 'fast-json-stringify';
/**
 *
 * @export
 * @enum {string}
 */
export enum SelectionInputPredictionsName {
    CLASS_DISTRIBUTION = 'CLASS_DISTRIBUTION',
    CATEGORY_COUNT = 'CATEGORY_COUNT'
}
/* LIGHTLY CUSTOM START -> modelEnumInterfaces */
export const SelectionInputPredictionsNameValidatorShape = [
    'CLASS_DISTRIBUTION',
    'CATEGORY_COUNT'
];
export const SelectionInputPredictionsNameValidator = yup.mixed().oneOf(SelectionInputPredictionsNameValidatorShape);
export const SelectionInputPredictionsNameStringifyShapeProperties = {};
export const SelectionInputPredictionsNameStringifyShape: StringSchema = {
    title: 'SelectionInputPredictionsName Stringifier',
    type: 'string'
};
export const SelectionInputPredictionsNameStringify = (typeof process === 'object' && String(process) === '[object process]') ?
    fastJSON.build(SelectionInputPredictionsNameStringifyShape)
    :
        () => { throw new Error('fast-json-stringify only works in nodejs'); };
/* LIGHTLY CUSTOM END */
export function SelectionInputPredictionsNameFromJSON(json: any): SelectionInputPredictionsName {
    return SelectionInputPredictionsNameFromJSONTyped(json, false);
}
export function SelectionInputPredictionsNameFromJSONTyped(json: any, ignoreDiscriminator: boolean): SelectionInputPredictionsName {
    return json as SelectionInputPredictionsName;
}
export function SelectionInputPredictionsNameToJSON(value?: SelectionInputPredictionsName | null): any {
    return value as any;
}
