/* tslint:disable */
/* eslint-disable */
/**
 * Lightly API
 * Lightly.ai enables you to do self-supervised learning in an easy and intuitive way. The lightly.ai OpenAPI spec defines how one can interact with our REST API to unleash the full potential of lightly.ai
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: support@lightly.ai
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
// SRC: https://github.com/OpenAPITools/openapi-generator/blob/master/modules/openapi-generator/src/main/resources/typescript-fetch/modelGeneric.mustache
// synched 02.02.2023
import * as yup from 'yup';
import typia from 'typia';
import * as fastJSON from 'fast-json-stringify';
import { Options, ArraySchema, ObjectSchema } from 'fast-json-stringify';
import { exists, mapValues } from '../runtime';
/*
causes circular dependendies
*/
// SRC: https://github.com/OpenAPITools/openapi-generator/blob/master/modules/openapi-generator/src/main/resources/typescript-fetch/modelGenericInterfaces.mustache
// synced: 17.07.2023
/**
 *
 * @export
 * @interface SetEmbeddingsIsProcessedFlagByIdBodyRequest
 *
 */
// create custom validators for the regexes
export interface SetEmbeddingsIsProcessedFlagByIdBodyRequest {
    /**
     * Number of rows in the embeddings file
     * #type number
     * #memberof SetEmbeddingsIsProcessedFlagByIdBodyRequest
        
        
         */
    rowCount: number;
}
/**
 * Check if a given object implements the SetEmbeddingsIsProcessedFlagByIdBodyRequest interface.
 */
export function instanceOfSetEmbeddingsIsProcessedFlagByIdBodyRequest(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "rowCount" in value;
    return isInstance;
}
/* LIGHTLY CUSTOM START -> modelGeneric */
export const SetEmbeddingsIsProcessedFlagByIdBodyRequestTypiaAssertEquals = (input: any) => {
    return ((input: any): SetEmbeddingsIsProcessedFlagByIdBodyRequest => {
        const __is = (input: any, _exceptionable: boolean = true): input is SetEmbeddingsIsProcessedFlagByIdBodyRequest => {
            const $io0 = (input: any, _exceptionable: boolean = true): boolean => "number" === typeof input.rowCount && (1 === Object.keys(input).length || Object.keys(input).every((key: any) => {
                if (["rowCount"].some((prop: any) => key === prop))
                    return true;
                const value = input[key];
                if (undefined === value)
                    return true;
                return false;
            }));
            return "object" === typeof input && null !== input && $io0(input, true);
        };
        if (false === __is(input))
            ((input: any, _path: string, _exceptionable: boolean = true): input is SetEmbeddingsIsProcessedFlagByIdBodyRequest => {
                const $guard = (typia.createAssertEquals as any).guard;
                const $join = (typia.createAssertEquals as any).join;
                const $ao0 = (input: any, _path: string, _exceptionable: boolean = true): boolean => ("number" === typeof input.rowCount || $guard(_exceptionable, {
                    path: _path + ".rowCount",
                    expected: "number",
                    value: input.rowCount
                })) && (1 === Object.keys(input).length || (false === _exceptionable || Object.keys(input).every((key: any) => {
                    if (["rowCount"].some((prop: any) => key === prop))
                        return true;
                    const value = input[key];
                    if (undefined === value)
                        return true;
                    return $guard(_exceptionable, {
                        path: _path + $join(key),
                        expected: "undefined",
                        value: value
                    });
                })));
                return ("object" === typeof input && null !== input || $guard(true, {
                    path: _path + "",
                    expected: "SetEmbeddingsIsProcessedFlagByIdBodyRequest",
                    value: input
                })) && $ao0(input, _path + "", true) || $guard(true, {
                    path: _path + "",
                    expected: "SetEmbeddingsIsProcessedFlagByIdBodyRequest",
                    value: input
                });
            })(input, "$input", true);
        return input;
    })(input);
};
export const SetEmbeddingsIsProcessedFlagByIdBodyRequestTypiaAssertStringify = (input: SetEmbeddingsIsProcessedFlagByIdBodyRequest): string => {
    return ((input: any): string => { const assert = (input: any): SetEmbeddingsIsProcessedFlagByIdBodyRequest => {
        const __is = (input: any): input is SetEmbeddingsIsProcessedFlagByIdBodyRequest => {
            return "object" === typeof input && null !== input && ("number" === typeof (input as any).rowCount && !Number.isNaN((input as any).rowCount));
        };
        if (false === __is(input))
            ((input: any, _path: string, _exceptionable: boolean = true): input is SetEmbeddingsIsProcessedFlagByIdBodyRequest => {
                const $guard = (typia.createAssertStringify as any).guard;
                const $ao0 = (input: any, _path: string, _exceptionable: boolean = true): boolean => "number" === typeof input.rowCount && !Number.isNaN(input.rowCount) || $guard(_exceptionable, {
                    path: _path + ".rowCount",
                    expected: "number",
                    value: input.rowCount
                });
                return ("object" === typeof input && null !== input || $guard(true, {
                    path: _path + "",
                    expected: "SetEmbeddingsIsProcessedFlagByIdBodyRequest",
                    value: input
                })) && $ao0(input, _path + "", true) || $guard(true, {
                    path: _path + "",
                    expected: "SetEmbeddingsIsProcessedFlagByIdBodyRequest",
                    value: input
                });
            })(input, "$input", true);
        return input;
    }; const stringify = (input: SetEmbeddingsIsProcessedFlagByIdBodyRequest): string => {
        return `{"rowCount":${(input as any).rowCount}}`;
    }; return stringify(assert(input)); })(input);
};
export const SetEmbeddingsIsProcessedFlagByIdBodyRequestValidatorShape = {
    'rowCount': yup.number().defined(),
};
export const SetEmbeddingsIsProcessedFlagByIdBodyRequestValidator = yup.object().shape(SetEmbeddingsIsProcessedFlagByIdBodyRequestValidatorShape);
export const SetEmbeddingsIsProcessedFlagByIdBodyRequestStringifyShapeProperties: ObjectSchema['properties'] = {
    'rowCount': {
        'type': 'number',
    } as unknown as fastJSON.NumberSchema,
};
export const SetEmbeddingsIsProcessedFlagByIdBodyRequestStringifyShape: ObjectSchema = {
    title: 'SetEmbeddingsIsProcessedFlagByIdBodyRequest Stringifier',
    type: 'object',
    properties: {
        ...SetEmbeddingsIsProcessedFlagByIdBodyRequestStringifyShapeProperties
    },
    required: [
        'rowCount',
    ],
};
export const SetEmbeddingsIsProcessedFlagByIdBodyRequestStringifyCompiled = (typeof process === 'object' && String(process) === '[object process]') ?
    fastJSON.build(SetEmbeddingsIsProcessedFlagByIdBodyRequestStringifyShape, {
        ajv: {
            // since we are already doing this with yup, lets skip
            validateSchema: false,
            ownProperties: true,
            validateFormats: false,
        },
        largeArrayMechanism: 'json-stringify',
        largeArraySize: 1000,
    } as Options)
    :
        () => { throw new Error('fast-json-stringify only works in nodejs'); };
export const SetEmbeddingsIsProcessedFlagByIdBodyRequestStringify: (data: SetEmbeddingsIsProcessedFlagByIdBodyRequest) => string = SetEmbeddingsIsProcessedFlagByIdBodyRequestStringifyCompiled;
/* LIGHTLY CUSTOM END -> Generic */
export function SetEmbeddingsIsProcessedFlagByIdBodyRequestFromJSON(json: any): SetEmbeddingsIsProcessedFlagByIdBodyRequest {
    return SetEmbeddingsIsProcessedFlagByIdBodyRequestFromJSONTyped(json, false);
}
export function SetEmbeddingsIsProcessedFlagByIdBodyRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): SetEmbeddingsIsProcessedFlagByIdBodyRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    /* causes circular depdendencies
*/
    return {
        'rowCount': json['rowCount'],
    };
}
export function SetEmbeddingsIsProcessedFlagByIdBodyRequestToJSON(value?: SetEmbeddingsIsProcessedFlagByIdBodyRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'rowCount': value.rowCount,
    };
}
