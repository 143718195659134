import React from 'react';

import { flatMap } from 'lodash';
import { Redirect, Route as RouterRoute, RouteProps } from 'react-router-dom';


import { WhiteoutLoading } from './common/WhiteoutLoading';
import { useAuth0 } from './contexts/auth0-context';
import { getStorage } from './helpers/storage';
import { useRedirectForOnboarding } from './pages/onboarding/onboarding';
import { signoutRoute, signinRoute } from './routes';
import { IRoute } from './routes.types';

export const IS_LOGGED_IN_LOCALSTORAGE_KEY = 'isLoggedIn'



interface IPublicRouteProps extends RouteProps {
	route: IRoute;
	token?: string;
}

const PublicRoute = (props: IPublicRouteProps) => {
	const { isLoading, user } = useAuth0();
	const { route, token } = props;
	const { path: onboardingRedirectPath, isLoading: onboardingIsLoading } = useRedirectForOnboarding(!!token, route);

	return (
		<RouterRoute
			key={route.path}
			path={route.path}
			render={(routeProps) => {
				if (isLoading || onboardingIsLoading || (user && !token)) {
					return <WhiteoutLoading show />;
				}

				if (onboardingRedirectPath) {
					return <Redirect
						to={{
							pathname: onboardingRedirectPath,
							state: { from: routeProps.location },
						}}
					/>;
				}
				return <route.component {...routeProps}></route.component>;
			}}
		/>
	)
}

interface IPrivateRouteProps extends RouteProps {
	route: IRoute;
	token: string;
}

const PrivateRoute = (props: IPrivateRouteProps) => {
	const { isLoading, user, isAuthenticated } = useAuth0();
	const { route, token } = props;
	const { path: onboardingRedirectPath, isLoading: onboardingIsLoading } = useRedirectForOnboarding(!!token, route);

	return (
		<RouterRoute
			key={route.path}
			path={route.path}
			render={(routeProps) => {
				if (isNotLoggedIn(isAuthenticated, isLoading)) {
					return <Redirect
						to={{
							pathname: signoutRoute.path,
							state: { from: routeProps.location },
						}}
					/>;
				}
				if (isLoading || onboardingIsLoading || (user && !token)) {
					return <WhiteoutLoading show />;
				}

				if (onboardingRedirectPath) {
					return <Redirect
						to={{
							pathname: onboardingRedirectPath,
							state: { from: routeProps.location },
						}}
					/>;
				}
				if (user) {
					return <route.component {...routeProps}></route.component>
				}
				return <Redirect
					to={{
						pathname: signinRoute.path,
						state: { from: routeProps.location },
					}}
				/>;
			}}
		/>
	);
};

const isNotLoggedIn = (isAuthenticated: boolean, isLoading: boolean): boolean => {
	// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
	const isLoggedIn = getStorage(IS_LOGGED_IN_LOCALSTORAGE_KEY)
	if (!isLoading && (!isLoggedIn || !isAuthenticated)) {
		return true
	}
	return false
}


export const flattenRoutes = (_routes: IRoute[]): { path: string; }[] => {
	return flatMap(_routes, (route) => {
		const children = route.children ? flattenRoutes(route.children) : [];
		return [
			{
				path: route.path,
			},
			...children,
		];
	});
};

export const generateRoutes = (_routes: IRoute[], token: string): JSX.Element[] => {
	return flatMap(_routes, (route) => {
		const children = route.children ? generateRoutes(route.children, token) : [];
		return [
			...children,

			route.isPublic ?
				<PublicRoute
					key={route.path}
					path={route.path}
					route={route}
					token={token}
				/>
				:
				<PrivateRoute
					key={route.path}
					path={route.path}
					token={token}
					route={route}
				/>
			,
		];
	});
};

