import { UserType } from '@lightly/api-spec';


export enum PERMISSIONS {
	MANAGE = 0b100000,
	CREATE = 0b010000,
	UPDATE = 0b001000,
	READ = 0b000100,
	DELETE = 0b000010,
	NONE = 0b000000,
	NOT_EXIST = 0b000001,
}

export enum ROLES {
	OWNER = PERMISSIONS.MANAGE ^ PERMISSIONS.CREATE ^ PERMISSIONS.UPDATE ^ PERMISSIONS.READ ^ PERMISSIONS.DELETE,
	WRITER = PERMISSIONS.CREATE ^ PERMISSIONS.UPDATE ^ PERMISSIONS.READ,
	READER = PERMISSIONS.READ,
	NONE = PERMISSIONS.NONE,
	NOT_EXIST = PERMISSIONS.NOT_EXIST,
}

export const enum Features {
	//page restrictions
	'page:dockerworker',
	'page:onboarding',
	'page:report',

	//permissions
	'perm:scheduleJob',

	'perm:collaboration:share',
	'perm:dataset:video',
	'perm:worker:sequences',
	'perm:worker:sampleSize:QHD',
	'perm:worker:objectlevel',

	'perm:teamfeatures:all',
	'perm:predictions:all',
	'perm:scoresV2:all',
	'perm:runs:logLoad',
}

export const isUserAllowedTo = (feature: Features, type: UserType = UserType.FREE): boolean => {
	let isAllowed = false;
	let allowedTypes: UserType[] = [];
	switch (feature) {
		case Features['page:onboarding']:
			allowedTypes = [UserType.FREE]
			break;
		case Features['page:dockerworker']:
			allowedTypes = [UserType.ADMIN, UserType.ENTERPRISE, UserType.PROFESSIONAL, UserType.FREE]
			break;
		case Features['page:report']:
			allowedTypes = [UserType.ADMIN]
			break;
		case Features['perm:scheduleJob']:
			allowedTypes = [UserType.ADMIN, UserType.ENTERPRISE, UserType.PROFESSIONAL, UserType.FREE]
			break;
		case Features['perm:collaboration:share']:
			allowedTypes = [UserType.ADMIN, UserType.ENTERPRISE, UserType.PROFESSIONAL]
			break;
		case Features['perm:dataset:video']:
			allowedTypes = [UserType.ADMIN, UserType.ENTERPRISE, UserType.PROFESSIONAL]
			break;
		case Features['perm:worker:sequences']:
			allowedTypes = [UserType.ADMIN, UserType.ENTERPRISE, UserType.PROFESSIONAL, UserType.FREE]
			break;
		case Features['perm:worker:sampleSize:QHD']:
			allowedTypes = [UserType.ADMIN, UserType.ENTERPRISE, UserType.PROFESSIONAL, UserType.FREE]
			break;
		case Features['perm:worker:objectlevel']:
			allowedTypes = [UserType.ADMIN, UserType.ENTERPRISE, UserType.PROFESSIONAL, UserType.FREE]
			break;

		case Features['perm:teamfeatures:all']:
			allowedTypes = [UserType.ADMIN, UserType.ENTERPRISE, UserType.PROFESSIONAL]
			break;
		case Features['perm:predictions:all']:
			allowedTypes = [UserType.ADMIN, UserType.ENTERPRISE, UserType.PROFESSIONAL, UserType.FREE]
			break;
		case Features['perm:scoresV2:all']:
			allowedTypes = [UserType.ADMIN, UserType.ENTERPRISE, UserType.PROFESSIONAL, UserType.FREE]
			break;
		case Features['perm:runs:logLoad']:
			allowedTypes = [UserType.ADMIN]
			break;
	}
	isAllowed = allowedTypes.includes(type)

	return isAllowed;
};
