/* tslint:disable */
/* eslint-disable */
/**
 * Lightly API
 * Lightly.ai enables you to do self-supervised learning in an easy and intuitive way. The lightly.ai OpenAPI spec defines how one can interact with our REST API to unleash the full potential of lightly.ai
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: support@lightly.ai
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
// SRC: https://github.com/OpenAPITools/openapi-generator/blob/master/modules/openapi-generator/src/main/resources/typescript-fetch/modelGeneric.mustache
// synched 02.02.2023
import * as yup from 'yup';
import typia from 'typia';
import * as fastJSON from 'fast-json-stringify';
import { Options, ArraySchema, ObjectSchema } from 'fast-json-stringify';
import { exists, mapValues } from '../runtime';
import type { LabelBoxV4DataRow } from './LabelBoxV4DataRow';
import { LabelBoxV4DataRowFromJSON, LabelBoxV4DataRowFromJSONTyped, LabelBoxV4DataRowToJSON, } from './LabelBoxV4DataRow';
/*
causes circular dependendies
*/
// SRC: https://github.com/OpenAPITools/openapi-generator/blob/master/modules/openapi-generator/src/main/resources/typescript-fetch/modelGenericInterfaces.mustache
// synced: 17.07.2023
/**
 *
 * @export
 * @interface LabelBoxV4DataRows
 *
 */
// create custom validators for the regexes
export interface LabelBoxV4DataRows extends Array<LabelBoxV4DataRow> {
}
/**
 * Check if a given object implements the LabelBoxV4DataRows interface.
 */
export function instanceOfLabelBoxV4DataRows(value: object): boolean {
    let isInstance = true;
    return isInstance;
}
/* LIGHTLY CUSTOM START -> modelGeneric */
import { LabelBoxV4DataRowValidator, LabelBoxV4DataRowStringifyShape, LabelBoxV4DataRowStringifyShapeProperties, } from './LabelBoxV4DataRow';
export const LabelBoxV4DataRowsTypiaAssertEquals = (input: any) => {
    return ((input: any): LabelBoxV4DataRows => {
        const __is = (input: any, _exceptionable: boolean = true): input is LabelBoxV4DataRows => {
            const $join = (typia.createAssertEquals as any).join;
            const $io0 = (input: any, _exceptionable: boolean = true): boolean => "number" === typeof input.length && Object.keys(input).every((key: any) => {
                if (["length"].some((prop: any) => key === prop))
                    return true;
                const value = input[key];
                if (undefined === value)
                    return true;
                if (RegExp(/^[+-]?\d+(?:\.\d+)?(?:[eE][+-]?\d+)?$/).test(key))
                    return "object" === typeof value && null !== value && $io1(value, true && _exceptionable);
                return false;
            });
            const $io1 = (input: any, _exceptionable: boolean = true): boolean => "string" === typeof input.row_data && (undefined === input.global_key || "string" === typeof input.global_key) && (undefined === input.media_type || "string" === typeof input.media_type) && (1 === Object.keys(input).length || Object.keys(input).every((key: any) => {
                if (["row_data", "global_key", "media_type"].some((prop: any) => key === prop))
                    return true;
                const value = input[key];
                if (undefined === value)
                    return true;
                return false;
            }));
            return "object" === typeof input && null !== input && $io0(input, true);
        };
        if (false === __is(input))
            ((input: any, _path: string, _exceptionable: boolean = true): input is LabelBoxV4DataRows => {
                const $guard = (typia.createAssertEquals as any).guard;
                const $join = (typia.createAssertEquals as any).join;
                const $ao0 = (input: any, _path: string, _exceptionable: boolean = true): boolean => ("number" === typeof input.length || $guard(_exceptionable, {
                    path: _path + ".length",
                    expected: "number",
                    value: input.length
                })) && (false === _exceptionable || Object.keys(input).every((key: any) => {
                    if (["length"].some((prop: any) => key === prop))
                        return true;
                    const value = input[key];
                    if (undefined === value)
                        return true;
                    if (RegExp(/^[+-]?\d+(?:\.\d+)?(?:[eE][+-]?\d+)?$/).test(key))
                        return ("object" === typeof value && null !== value || $guard(_exceptionable, {
                            path: _path + $join(key),
                            expected: "LabelBoxV4DataRow",
                            value: value
                        })) && $ao1(value, _path + $join(key), true && _exceptionable) || $guard(_exceptionable, {
                            path: _path + $join(key),
                            expected: "LabelBoxV4DataRow",
                            value: value
                        });
                    return $guard(_exceptionable, {
                        path: _path + $join(key),
                        expected: "undefined",
                        value: value
                    });
                }));
                const $ao1 = (input: any, _path: string, _exceptionable: boolean = true): boolean => ("string" === typeof input.row_data || $guard(_exceptionable, {
                    path: _path + ".row_data",
                    expected: "string",
                    value: input.row_data
                })) && (undefined === input.global_key || "string" === typeof input.global_key || $guard(_exceptionable, {
                    path: _path + ".global_key",
                    expected: "(string | undefined)",
                    value: input.global_key
                })) && (undefined === input.media_type || "string" === typeof input.media_type || $guard(_exceptionable, {
                    path: _path + ".media_type",
                    expected: "(string | undefined)",
                    value: input.media_type
                })) && (1 === Object.keys(input).length || (false === _exceptionable || Object.keys(input).every((key: any) => {
                    if (["row_data", "global_key", "media_type"].some((prop: any) => key === prop))
                        return true;
                    const value = input[key];
                    if (undefined === value)
                        return true;
                    return $guard(_exceptionable, {
                        path: _path + $join(key),
                        expected: "undefined",
                        value: value
                    });
                })));
                return ("object" === typeof input && null !== input || $guard(true, {
                    path: _path + "",
                    expected: "LabelBoxV4DataRows",
                    value: input
                })) && $ao0(input, _path + "", true) || $guard(true, {
                    path: _path + "",
                    expected: "LabelBoxV4DataRows",
                    value: input
                });
            })(input, "$input", true);
        return input;
    })(input);
};
export const LabelBoxV4DataRowsTypiaAssertStringify = (input: LabelBoxV4DataRows): string => {
    return ((input: any): string => { const assert = (input: any): LabelBoxV4DataRows => {
        const __is = (input: any): input is LabelBoxV4DataRows => {
            const $join = (typia.createAssertStringify as any).join;
            const $io0 = (input: any): boolean => "number" === typeof input.length && !Number.isNaN(input.length) && Object.keys(input).every((key: any) => {
                if (["length"].some((prop: any) => key === prop))
                    return true;
                const value = input[key];
                if (undefined === value)
                    return true;
                if (RegExp(/^[+-]?\d+(?:\.\d+)?(?:[eE][+-]?\d+)?$/).test(key))
                    return "object" === typeof value && null !== value && $io1(value);
                return true;
            });
            const $io1 = (input: any): boolean => "string" === typeof input.row_data && (undefined === input.global_key || "string" === typeof input.global_key) && (undefined === input.media_type || "string" === typeof input.media_type);
            return "object" === typeof input && null !== input && $io0(input);
        };
        if (false === __is(input))
            ((input: any, _path: string, _exceptionable: boolean = true): input is LabelBoxV4DataRows => {
                const $guard = (typia.createAssertStringify as any).guard;
                const $join = (typia.createAssertStringify as any).join;
                const $ao0 = (input: any, _path: string, _exceptionable: boolean = true): boolean => ("number" === typeof input.length && !Number.isNaN(input.length) || $guard(_exceptionable, {
                    path: _path + ".length",
                    expected: "number",
                    value: input.length
                })) && (false === _exceptionable || Object.keys(input).every((key: any) => {
                    if (["length"].some((prop: any) => key === prop))
                        return true;
                    const value = input[key];
                    if (undefined === value)
                        return true;
                    if (RegExp(/^[+-]?\d+(?:\.\d+)?(?:[eE][+-]?\d+)?$/).test(key))
                        return ("object" === typeof value && null !== value || $guard(_exceptionable, {
                            path: _path + $join(key),
                            expected: "LabelBoxV4DataRow",
                            value: value
                        })) && $ao1(value, _path + $join(key), true && _exceptionable) || $guard(_exceptionable, {
                            path: _path + $join(key),
                            expected: "LabelBoxV4DataRow",
                            value: value
                        });
                    return true;
                }));
                const $ao1 = (input: any, _path: string, _exceptionable: boolean = true): boolean => ("string" === typeof input.row_data || $guard(_exceptionable, {
                    path: _path + ".row_data",
                    expected: "string",
                    value: input.row_data
                })) && (undefined === input.global_key || "string" === typeof input.global_key || $guard(_exceptionable, {
                    path: _path + ".global_key",
                    expected: "(string | undefined)",
                    value: input.global_key
                })) && (undefined === input.media_type || "string" === typeof input.media_type || $guard(_exceptionable, {
                    path: _path + ".media_type",
                    expected: "(string | undefined)",
                    value: input.media_type
                }));
                return ("object" === typeof input && null !== input || $guard(true, {
                    path: _path + "",
                    expected: "LabelBoxV4DataRows",
                    value: input
                })) && $ao0(input, _path + "", true) || $guard(true, {
                    path: _path + "",
                    expected: "LabelBoxV4DataRows",
                    value: input
                });
            })(input, "$input", true);
        return input;
    }; const stringify = (input: LabelBoxV4DataRows): string => {
        const $io1 = (input: any): boolean => "string" === typeof input.row_data && (undefined === input.global_key || "string" === typeof input.global_key) && (undefined === input.media_type || "string" === typeof input.media_type);
        const $join = (typia.createAssertStringify as any).join;
        const $tail = (typia.createAssertStringify as any).tail;
        const $string = (typia.createAssertStringify as any).string;
        const $so0 = (input: any): any => `{${$tail(`"length":${input.length},${Object.entries(input).map(([key, value]: [string, any]) => { if (undefined === value)
            return ""; if (["length"].some((regular: any) => regular === key))
            return ""; if (RegExp(/^[+-]?\d+(?:\.\d+)?(?:[eE][+-]?\d+)?$/).test(key))
            return `${JSON.stringify(key)}:${$so1(value)}`; return ""; }).filter((str: any) => "" !== str).join(",")}`)}}`;
        const $so1 = (input: any): any => `{${undefined === input.global_key ? "" : `"global_key":${undefined !== input.global_key ? $string(input.global_key) : undefined},`}${undefined === input.media_type ? "" : `"media_type":${undefined !== input.media_type ? $string(input.media_type) : undefined},`}"row_data":${$string(input.row_data)}}`;
        return $so0(input);
    }; return stringify(assert(input)); })(input);
};
export const LabelBoxV4DataRowsValidatorShape = {};
export const LabelBoxV4DataRowsValidator = yup.array().of(LabelBoxV4DataRowValidator);
export const LabelBoxV4DataRowsStringifyShapeProperties: ObjectSchema['properties'] = {};
export const LabelBoxV4DataRowsStringifyShape: ArraySchema = {
    title: 'LabelBoxV4DataRows Stringifier',
    type: 'array',
    items: LabelBoxV4DataRowStringifyShape
};
export const LabelBoxV4DataRowsStringifyCompiled = (typeof process === 'object' && String(process) === '[object process]') ?
    fastJSON.build(LabelBoxV4DataRowsStringifyShape, {
        ajv: {
            // since we are already doing this with yup, lets skip
            validateSchema: false,
            ownProperties: true,
            validateFormats: false,
        },
        largeArrayMechanism: 'json-stringify',
        largeArraySize: 1000,
    } as Options)
    :
        () => { throw new Error('fast-json-stringify only works in nodejs'); };
export const LabelBoxV4DataRowsStringify: (data: LabelBoxV4DataRows) => string = LabelBoxV4DataRowsStringifyCompiled;
/* LIGHTLY CUSTOM END -> Generic */
export function LabelBoxV4DataRowsFromJSON(json: any): LabelBoxV4DataRows {
    return LabelBoxV4DataRowsFromJSONTyped(json, false);
}
export function LabelBoxV4DataRowsFromJSONTyped(json: any, ignoreDiscriminator: boolean): LabelBoxV4DataRows {
    return json;
}
export function LabelBoxV4DataRowsToJSON(value?: LabelBoxV4DataRows | null): any {
    return value;
}
