/* tslint:disable */
/* eslint-disable */
/**
 * Lightly API
 * Lightly.ai enables you to do self-supervised learning in an easy and intuitive way. The lightly.ai OpenAPI spec defines how one can interact with our REST API to unleash the full potential of lightly.ai
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: support@lightly.ai
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
// SRC: https://github.com/OpenAPITools/openapi-generator/blob/master/modules/openapi-generator/src/main/resources/typescript-fetch/modelGeneric.mustache
// synched 02.02.2023
import * as yup from 'yup';
import typia from 'typia';
import * as fastJSON from 'fast-json-stringify';
import { Options, ArraySchema, ObjectSchema } from 'fast-json-stringify';
import { exists, mapValues } from '../runtime';
/*
causes circular dependendies
*/
// SRC: https://github.com/OpenAPITools/openapi-generator/blob/master/modules/openapi-generator/src/main/resources/typescript-fetch/modelGenericInterfaces.mustache
// synced: 17.07.2023
/**
 *
 * @export
 * @interface TagChangeDataRename
 *
 */
// create custom validators for the regexes
export interface TagChangeDataRename {
    /**
     *
     * #type string
     * #memberof TagChangeDataRename
        
        
         */
    from: string;
    /**
     *
     * #type string
     * #memberof TagChangeDataRename
        
        
         */
    to: string;
}
/**
 * Check if a given object implements the TagChangeDataRename interface.
 */
export function instanceOfTagChangeDataRename(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "from" in value;
    isInstance = isInstance && "to" in value;
    return isInstance;
}
/* LIGHTLY CUSTOM START -> modelGeneric */
export const TagChangeDataRenameTypiaAssertEquals = (input: any) => {
    return ((input: any): TagChangeDataRename => {
        const __is = (input: any, _exceptionable: boolean = true): input is TagChangeDataRename => {
            const $io0 = (input: any, _exceptionable: boolean = true): boolean => "string" === typeof input.from && "string" === typeof input.to && (2 === Object.keys(input).length || Object.keys(input).every((key: any) => {
                if (["from", "to"].some((prop: any) => key === prop))
                    return true;
                const value = input[key];
                if (undefined === value)
                    return true;
                return false;
            }));
            return "object" === typeof input && null !== input && $io0(input, true);
        };
        if (false === __is(input))
            ((input: any, _path: string, _exceptionable: boolean = true): input is TagChangeDataRename => {
                const $guard = (typia.createAssertEquals as any).guard;
                const $join = (typia.createAssertEquals as any).join;
                const $ao0 = (input: any, _path: string, _exceptionable: boolean = true): boolean => ("string" === typeof input.from || $guard(_exceptionable, {
                    path: _path + ".from",
                    expected: "string",
                    value: input.from
                })) && ("string" === typeof input.to || $guard(_exceptionable, {
                    path: _path + ".to",
                    expected: "string",
                    value: input.to
                })) && (2 === Object.keys(input).length || (false === _exceptionable || Object.keys(input).every((key: any) => {
                    if (["from", "to"].some((prop: any) => key === prop))
                        return true;
                    const value = input[key];
                    if (undefined === value)
                        return true;
                    return $guard(_exceptionable, {
                        path: _path + $join(key),
                        expected: "undefined",
                        value: value
                    });
                })));
                return ("object" === typeof input && null !== input || $guard(true, {
                    path: _path + "",
                    expected: "TagChangeDataRename",
                    value: input
                })) && $ao0(input, _path + "", true) || $guard(true, {
                    path: _path + "",
                    expected: "TagChangeDataRename",
                    value: input
                });
            })(input, "$input", true);
        return input;
    })(input);
};
export const TagChangeDataRenameTypiaAssertStringify = (input: TagChangeDataRename): string => {
    return ((input: any): string => { const assert = (input: any): TagChangeDataRename => {
        const __is = (input: any): input is TagChangeDataRename => {
            return "object" === typeof input && null !== input && ("string" === typeof (input as any).from && "string" === typeof (input as any).to);
        };
        if (false === __is(input))
            ((input: any, _path: string, _exceptionable: boolean = true): input is TagChangeDataRename => {
                const $guard = (typia.createAssertStringify as any).guard;
                const $ao0 = (input: any, _path: string, _exceptionable: boolean = true): boolean => ("string" === typeof input.from || $guard(_exceptionable, {
                    path: _path + ".from",
                    expected: "string",
                    value: input.from
                })) && ("string" === typeof input.to || $guard(_exceptionable, {
                    path: _path + ".to",
                    expected: "string",
                    value: input.to
                }));
                return ("object" === typeof input && null !== input || $guard(true, {
                    path: _path + "",
                    expected: "TagChangeDataRename",
                    value: input
                })) && $ao0(input, _path + "", true) || $guard(true, {
                    path: _path + "",
                    expected: "TagChangeDataRename",
                    value: input
                });
            })(input, "$input", true);
        return input;
    }; const stringify = (input: TagChangeDataRename): string => {
        const $string = (typia.createAssertStringify as any).string;
        return `{"from":${$string((input as any).from)},"to":${$string((input as any).to)}}`;
    }; return stringify(assert(input)); })(input);
};
export const TagChangeDataRenameValidatorShape = {
    'from': yup.string().defined(),
    'to': yup.string().defined(),
};
export const TagChangeDataRenameValidator = yup.object().shape(TagChangeDataRenameValidatorShape);
export const TagChangeDataRenameStringifyShapeProperties: ObjectSchema['properties'] = {
    'from': {
        'type': 'string',
    } as unknown as fastJSON.StringSchema,
    'to': {
        'type': 'string',
    } as unknown as fastJSON.StringSchema,
};
export const TagChangeDataRenameStringifyShape: ObjectSchema = {
    title: 'TagChangeDataRename Stringifier',
    type: 'object',
    properties: {
        ...TagChangeDataRenameStringifyShapeProperties
    },
    required: [
        'from',
        'to',
    ],
};
export const TagChangeDataRenameStringifyCompiled = (typeof process === 'object' && String(process) === '[object process]') ?
    fastJSON.build(TagChangeDataRenameStringifyShape, {
        ajv: {
            // since we are already doing this with yup, lets skip
            validateSchema: false,
            ownProperties: true,
            validateFormats: false,
        },
        largeArrayMechanism: 'json-stringify',
        largeArraySize: 1000,
    } as Options)
    :
        () => { throw new Error('fast-json-stringify only works in nodejs'); };
export const TagChangeDataRenameStringify: (data: TagChangeDataRename) => string = TagChangeDataRenameStringifyCompiled;
/* LIGHTLY CUSTOM END -> Generic */
export function TagChangeDataRenameFromJSON(json: any): TagChangeDataRename {
    return TagChangeDataRenameFromJSONTyped(json, false);
}
export function TagChangeDataRenameFromJSONTyped(json: any, ignoreDiscriminator: boolean): TagChangeDataRename {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    /* causes circular depdendencies
*/
    return {
        'from': json['from'],
        'to': json['to'],
    };
}
export function TagChangeDataRenameToJSON(value?: TagChangeDataRename | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'from': value.from,
        'to': value.to,
    };
}
