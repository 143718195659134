import * as Yup from 'yup';

Yup.setLocale({
	mixed: {
		default: 'error_form:unspecific',
		required: ({ path, label, ...other }) => ({
			key: 'error_form:field_required', field: path, props: {
				label,
				other,
			},
		}),
		defined: ({ path, label, ...other }) => ({
			key: 'error_form:field_defined', field: path, props: {
				label,
				other,
			},
		}),
		oneOf: ({ path, label, ...other }) => ({
			key: 'error_form:field_must_be_oneOf', field: path, props: {
				label,
				other,
			},
		}),
		// eslint-disable-next-line @typescript-eslint/ban-ts-comment
		// @ts-ignore
		notType: ({ path, ...other }) => ({
			key: `error_form:field_must_be_type_${other.type}`, field: path, props: { other },
		}),
	},
	string: {
		matches: ({ path, regex }) => ({
			key: 'error_form:field_not_matches', field: path, props: { regex: regex.toString() },
		}),
		min: ({ path, min }) => ({
			key: 'error_form:field_too_short', field: path, props: { min },
		}),
		max: ({ path, max }) => ({
			key: 'error_form:field_too_big', field: path, props: { max },
		}),
		email: ({ path, value }) => ({
			key: 'error_form:field_not_email', field: path, props: { value: value as string   },
		}),
	},
	number: {
		min: ({ path, min }) => ({
			key: 'error_form:field_value_small', field: path, props: { min },
		}),
		max: ({ path, max }) => ({
			key: 'error_form:field_value_big', field: path, props: { max },
		}),
		positive: ({ path }) => ({
			key: 'error_form:field_value_positive', field: path, props: {},
		}),
		integer: ({ path }) => ({
			key: 'error_form:field_value_integer', field: path, props: {},
		}),
	},
});