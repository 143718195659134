/* tslint:disable */
/* eslint-disable */
/**
 * Lightly API
 * Lightly.ai enables you to do self-supervised learning in an easy and intuitive way. The lightly.ai OpenAPI spec defines how one can interact with our REST API to unleash the full potential of lightly.ai
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: support@lightly.ai
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
// SRC: https://github.com/OpenAPITools/openapi-generator/blob/master/modules/openapi-generator/src/main/resources/typescript-fetch/modelEnumInterfaces.mustache
// synched 02.02.2023
import * as yup from 'yup';
import * as fastJSON from 'fast-json-stringify';
import { StringSchema } from 'fast-json-stringify';
/**
 *
 * @export
 * @enum {string}
 */
export enum DockerRunArtifactType {
    LOG = 'LOG',
    MEMLOG = 'MEMLOG',
    CHECKPOINT = 'CHECKPOINT',
    REPORT_PDF = 'REPORT_PDF',
    REPORT_JSON = 'REPORT_JSON',
    REPORT_V2_JSON = 'REPORT_V2_JSON',
    CORRUPTNESS_CHECK_INFORMATION = 'CORRUPTNESS_CHECK_INFORMATION',
    SEQUENCE_INFORMATION = 'SEQUENCE_INFORMATION',
    RELEVANT_FILENAMES = 'RELEVANT_FILENAMES'
}
/* LIGHTLY CUSTOM START -> modelEnumInterfaces */
export const DockerRunArtifactTypeValidatorShape = [
    'LOG',
    'MEMLOG',
    'CHECKPOINT',
    'REPORT_PDF',
    'REPORT_JSON',
    'REPORT_V2_JSON',
    'CORRUPTNESS_CHECK_INFORMATION',
    'SEQUENCE_INFORMATION',
    'RELEVANT_FILENAMES'
];
export const DockerRunArtifactTypeValidator = yup.mixed().oneOf(DockerRunArtifactTypeValidatorShape);
export const DockerRunArtifactTypeStringifyShapeProperties = {};
export const DockerRunArtifactTypeStringifyShape: StringSchema = {
    title: 'DockerRunArtifactType Stringifier',
    type: 'string'
};
export const DockerRunArtifactTypeStringify = (typeof process === 'object' && String(process) === '[object process]') ?
    fastJSON.build(DockerRunArtifactTypeStringifyShape)
    :
        () => { throw new Error('fast-json-stringify only works in nodejs'); };
/* LIGHTLY CUSTOM END */
export function DockerRunArtifactTypeFromJSON(json: any): DockerRunArtifactType {
    return DockerRunArtifactTypeFromJSONTyped(json, false);
}
export function DockerRunArtifactTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): DockerRunArtifactType {
    return json as DockerRunArtifactType;
}
export function DockerRunArtifactTypeToJSON(value?: DockerRunArtifactType | null): any {
    return value as any;
}
