/* tslint:disable */
/* eslint-disable */
/**
 * Lightly API
 * Lightly.ai enables you to do self-supervised learning in an easy and intuitive way. The lightly.ai OpenAPI spec defines how one can interact with our REST API to unleash the full potential of lightly.ai
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: support@lightly.ai
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
// SRC: https://github.com/OpenAPITools/openapi-generator/blob/master/modules/openapi-generator/src/main/resources/typescript-fetch/modelEnumInterfaces.mustache
// synched 02.02.2023
import * as yup from 'yup';
import * as fastJSON from 'fast-json-stringify';
import { StringSchema } from 'fast-json-stringify';
/**
 *
 * @export
 * @enum {string}
 */
export enum SelectionStrategyThresholdOperation {
    SMALLER = 'SMALLER',
    SMALLER_EQUAL = 'SMALLER_EQUAL',
    BIGGER = 'BIGGER',
    BIGGER_EQUAL = 'BIGGER_EQUAL'
}
/* LIGHTLY CUSTOM START -> modelEnumInterfaces */
export const SelectionStrategyThresholdOperationValidatorShape = [
    'SMALLER',
    'SMALLER_EQUAL',
    'BIGGER',
    'BIGGER_EQUAL'
];
export const SelectionStrategyThresholdOperationValidator = yup.mixed().oneOf(SelectionStrategyThresholdOperationValidatorShape);
export const SelectionStrategyThresholdOperationStringifyShapeProperties = {};
export const SelectionStrategyThresholdOperationStringifyShape: StringSchema = {
    title: 'SelectionStrategyThresholdOperation Stringifier',
    type: 'string'
};
export const SelectionStrategyThresholdOperationStringify = (typeof process === 'object' && String(process) === '[object process]') ?
    fastJSON.build(SelectionStrategyThresholdOperationStringifyShape)
    :
        () => { throw new Error('fast-json-stringify only works in nodejs'); };
/* LIGHTLY CUSTOM END */
export function SelectionStrategyThresholdOperationFromJSON(json: any): SelectionStrategyThresholdOperation {
    return SelectionStrategyThresholdOperationFromJSONTyped(json, false);
}
export function SelectionStrategyThresholdOperationFromJSONTyped(json: any, ignoreDiscriminator: boolean): SelectionStrategyThresholdOperation {
    return json as SelectionStrategyThresholdOperation;
}
export function SelectionStrategyThresholdOperationToJSON(value?: SelectionStrategyThresholdOperation | null): any {
    return value as any;
}
