/* tslint:disable */
/* eslint-disable */
/**
 * Lightly API
 * Lightly.ai enables you to do self-supervised learning in an easy and intuitive way. The lightly.ai OpenAPI spec defines how one can interact with our REST API to unleash the full potential of lightly.ai
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: support@lightly.ai
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
// SRC: https://github.com/OpenAPITools/openapi-generator/blob/master/modules/openapi-generator/src/main/resources/typescript-fetch/modelGeneric.mustache
// synched 02.02.2023
import * as yup from 'yup';
import typia from 'typia';
import * as fastJSON from 'fast-json-stringify';
import { Options, ArraySchema, ObjectSchema } from 'fast-json-stringify';
import { exists, mapValues } from '../runtime';
import type { Creator } from './Creator';
import { CreatorFromJSON, CreatorFromJSONTyped, CreatorToJSON, } from './Creator';
import type { DockerWorkerConfigOmniV2CreateRequest } from './DockerWorkerConfigOmniV2CreateRequest';
import { DockerWorkerConfigOmniV2CreateRequestFromJSON, DockerWorkerConfigOmniV2CreateRequestFromJSONTyped, DockerWorkerConfigOmniV2CreateRequestToJSON, } from './DockerWorkerConfigOmniV2CreateRequest';
import type { DockerWorkerConfigOmniV3CreateRequest } from './DockerWorkerConfigOmniV3CreateRequest';
import { DockerWorkerConfigOmniV3CreateRequestFromJSON, DockerWorkerConfigOmniV3CreateRequestFromJSONTyped, DockerWorkerConfigOmniV3CreateRequestToJSON, } from './DockerWorkerConfigOmniV3CreateRequest';
import type { DockerWorkerConfigOmniV4CreateRequest } from './DockerWorkerConfigOmniV4CreateRequest';
import { DockerWorkerConfigOmniV4CreateRequestFromJSON, DockerWorkerConfigOmniV4CreateRequestFromJSONTyped, DockerWorkerConfigOmniV4CreateRequestToJSON, } from './DockerWorkerConfigOmniV4CreateRequest';
/*
causes circular dependendies
import {
     DockerWorkerConfigOmniV2CreateRequestFromJSONTyped,
} from './DockerWorkerConfigOmniV2CreateRequest';
import {
     DockerWorkerConfigOmniV3CreateRequestFromJSONTyped,
} from './DockerWorkerConfigOmniV3CreateRequest';
import {
     DockerWorkerConfigOmniV4CreateRequestFromJSONTyped
} from './DockerWorkerConfigOmniV4CreateRequest';


*/
// SRC: https://github.com/OpenAPITools/openapi-generator/blob/master/modules/openapi-generator/src/main/resources/typescript-fetch/modelGenericInterfaces.mustache
// synced: 17.07.2023
/**
 *
 * @export
 * @interface DockerWorkerConfigOmniVXCreateRequestBase
 *
 */
// create custom validators for the regexes
export interface DockerWorkerConfigOmniVXCreateRequestBase {
    /**
     * The version of the config. Either V3, V4, etc.etc.
     * #type string
     * #memberof DockerWorkerConfigOmniVXCreateRequestBase
        
        
         */
    version: string;
    /**
     *
     * #type Creator
     * #memberof DockerWorkerConfigOmniVXCreateRequestBase
        
        
         */
    creator?: Creator;
}
/**
 * Check if a given object implements the DockerWorkerConfigOmniVXCreateRequestBase interface.
 */
export function instanceOfDockerWorkerConfigOmniVXCreateRequestBase(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "version" in value;
    return isInstance;
}
/* LIGHTLY CUSTOM START -> modelGeneric */
import { CreatorValidator, CreatorStringifyShape, CreatorStringifyShapeProperties, } from './Creator';
import { DockerWorkerConfigOmniV2CreateRequestValidator, DockerWorkerConfigOmniV2CreateRequestStringifyShape, DockerWorkerConfigOmniV2CreateRequestStringifyShapeProperties, } from './DockerWorkerConfigOmniV2CreateRequest';
import { DockerWorkerConfigOmniV3CreateRequestValidator, DockerWorkerConfigOmniV3CreateRequestStringifyShape, DockerWorkerConfigOmniV3CreateRequestStringifyShapeProperties, } from './DockerWorkerConfigOmniV3CreateRequest';
import { DockerWorkerConfigOmniV4CreateRequestValidator, DockerWorkerConfigOmniV4CreateRequestStringifyShape, DockerWorkerConfigOmniV4CreateRequestStringifyShapeProperties, } from './DockerWorkerConfigOmniV4CreateRequest';
export const DockerWorkerConfigOmniVXCreateRequestBaseTypiaAssertEquals = (input: any) => {
    return ((input: any): DockerWorkerConfigOmniVXCreateRequestBase => {
        const __is = (input: any, _exceptionable: boolean = true): input is DockerWorkerConfigOmniVXCreateRequestBase => {
            const $io0 = (input: any, _exceptionable: boolean = true): boolean => "string" === typeof input.version && (undefined === input.creator || "UNKNOWN" === input.creator || "USER_WEBAPP" === input.creator || "USER_PIP" === input.creator || "USER_PIP_LIGHTLY_MAGIC" === input.creator || "USER_WORKER" === input.creator) && (1 === Object.keys(input).length || Object.keys(input).every((key: any) => {
                if (["version", "creator"].some((prop: any) => key === prop))
                    return true;
                const value = input[key];
                if (undefined === value)
                    return true;
                return false;
            }));
            return "object" === typeof input && null !== input && $io0(input, true);
        };
        if (false === __is(input))
            ((input: any, _path: string, _exceptionable: boolean = true): input is DockerWorkerConfigOmniVXCreateRequestBase => {
                const $guard = (typia.createAssertEquals as any).guard;
                const $join = (typia.createAssertEquals as any).join;
                const $ao0 = (input: any, _path: string, _exceptionable: boolean = true): boolean => ("string" === typeof input.version || $guard(_exceptionable, {
                    path: _path + ".version",
                    expected: "string",
                    value: input.version
                })) && (undefined === input.creator || "UNKNOWN" === input.creator || "USER_WEBAPP" === input.creator || "USER_PIP" === input.creator || "USER_PIP_LIGHTLY_MAGIC" === input.creator || "USER_WORKER" === input.creator || $guard(_exceptionable, {
                    path: _path + ".creator",
                    expected: "(\"UNKNOWN\" | \"USER_PIP\" | \"USER_PIP_LIGHTLY_MAGIC\" | \"USER_WEBAPP\" | \"USER_WORKER\" | undefined)",
                    value: input.creator
                })) && (1 === Object.keys(input).length || (false === _exceptionable || Object.keys(input).every((key: any) => {
                    if (["version", "creator"].some((prop: any) => key === prop))
                        return true;
                    const value = input[key];
                    if (undefined === value)
                        return true;
                    return $guard(_exceptionable, {
                        path: _path + $join(key),
                        expected: "undefined",
                        value: value
                    });
                })));
                return ("object" === typeof input && null !== input || $guard(true, {
                    path: _path + "",
                    expected: "DockerWorkerConfigOmniVXCreateRequestBase",
                    value: input
                })) && $ao0(input, _path + "", true) || $guard(true, {
                    path: _path + "",
                    expected: "DockerWorkerConfigOmniVXCreateRequestBase",
                    value: input
                });
            })(input, "$input", true);
        return input;
    })(input);
};
export const DockerWorkerConfigOmniVXCreateRequestBaseTypiaAssertStringify = (input: DockerWorkerConfigOmniVXCreateRequestBase): string => {
    return ((input: any): string => { const assert = (input: any): DockerWorkerConfigOmniVXCreateRequestBase => {
        const __is = (input: any): input is DockerWorkerConfigOmniVXCreateRequestBase => {
            const $io0 = (input: any): boolean => "string" === typeof input.version && (undefined === input.creator || "UNKNOWN" === input.creator || "USER_WEBAPP" === input.creator || "USER_PIP" === input.creator || "USER_PIP_LIGHTLY_MAGIC" === input.creator || "USER_WORKER" === input.creator);
            return "object" === typeof input && null !== input && $io0(input);
        };
        if (false === __is(input))
            ((input: any, _path: string, _exceptionable: boolean = true): input is DockerWorkerConfigOmniVXCreateRequestBase => {
                const $guard = (typia.createAssertStringify as any).guard;
                const $ao0 = (input: any, _path: string, _exceptionable: boolean = true): boolean => ("string" === typeof input.version || $guard(_exceptionable, {
                    path: _path + ".version",
                    expected: "string",
                    value: input.version
                })) && (undefined === input.creator || "UNKNOWN" === input.creator || "USER_WEBAPP" === input.creator || "USER_PIP" === input.creator || "USER_PIP_LIGHTLY_MAGIC" === input.creator || "USER_WORKER" === input.creator || $guard(_exceptionable, {
                    path: _path + ".creator",
                    expected: "(\"UNKNOWN\" | \"USER_PIP\" | \"USER_PIP_LIGHTLY_MAGIC\" | \"USER_WEBAPP\" | \"USER_WORKER\" | undefined)",
                    value: input.creator
                }));
                return ("object" === typeof input && null !== input || $guard(true, {
                    path: _path + "",
                    expected: "DockerWorkerConfigOmniVXCreateRequestBase",
                    value: input
                })) && $ao0(input, _path + "", true) || $guard(true, {
                    path: _path + "",
                    expected: "DockerWorkerConfigOmniVXCreateRequestBase",
                    value: input
                });
            })(input, "$input", true);
        return input;
    }; const stringify = (input: DockerWorkerConfigOmniVXCreateRequestBase): string => {
        const $string = (typia.createAssertStringify as any).string;
        const $throws = (typia.createAssertStringify as any).throws;
        const $so0 = (input: any): any => `{${undefined === input.creator ? "" : `"creator":${undefined !== input.creator ? (() => {
            if ("string" === typeof input.creator)
                return $string(input.creator);
            if ("string" === typeof input.creator)
                return "\"" + input.creator + "\"";
            $throws({
                expected: "(\"UNKNOWN\" | \"USER_PIP\" | \"USER_PIP_LIGHTLY_MAGIC\" | \"USER_WEBAPP\" | \"USER_WORKER\" | undefined)",
                value: input.creator
            });
        })() : undefined},`}"version":${$string(input.version)}}`;
        return $so0(input);
    }; return stringify(assert(input)); })(input);
};
export const DockerWorkerConfigOmniVXCreateRequestBaseValidatorShape = {
    'version': yup.string().defined(),
    'creator': CreatorValidator.optional().default(undefined),
};
export const DockerWorkerConfigOmniVXCreateRequestBaseValidator = yup.object().shape(DockerWorkerConfigOmniVXCreateRequestBaseValidatorShape);
export const DockerWorkerConfigOmniVXCreateRequestBaseStringifyShapeProperties: ObjectSchema['properties'] = {
    'version': {
        'type': 'string',
    } as unknown as fastJSON.StringSchema,
    'creator': CreatorStringifyShape,
};
export const DockerWorkerConfigOmniVXCreateRequestBaseStringifyShape: ObjectSchema = {
    title: 'DockerWorkerConfigOmniVXCreateRequestBase Stringifier',
    type: 'object',
    properties: {
        ...DockerWorkerConfigOmniVXCreateRequestBaseStringifyShapeProperties
    },
    required: [
        'version',
    ],
};
export const DockerWorkerConfigOmniVXCreateRequestBaseStringifyCompiled = (typeof process === 'object' && String(process) === '[object process]') ?
    fastJSON.build(DockerWorkerConfigOmniVXCreateRequestBaseStringifyShape, {
        ajv: {
            // since we are already doing this with yup, lets skip
            validateSchema: false,
            ownProperties: true,
            validateFormats: false,
        },
        largeArrayMechanism: 'json-stringify',
        largeArraySize: 1000,
    } as Options)
    :
        () => { throw new Error('fast-json-stringify only works in nodejs'); };
export const DockerWorkerConfigOmniVXCreateRequestBaseStringify: (data: DockerWorkerConfigOmniVXCreateRequestBase) => string = DockerWorkerConfigOmniVXCreateRequestBaseStringifyCompiled;
/* LIGHTLY CUSTOM END -> Generic */
export function DockerWorkerConfigOmniVXCreateRequestBaseFromJSON(json: any): DockerWorkerConfigOmniVXCreateRequestBase {
    return DockerWorkerConfigOmniVXCreateRequestBaseFromJSONTyped(json, false);
}
export function DockerWorkerConfigOmniVXCreateRequestBaseFromJSONTyped(json: any, ignoreDiscriminator: boolean): DockerWorkerConfigOmniVXCreateRequestBase {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    /* causes circular depdendencies
    if (!ignoreDiscriminator) {
        if (json['version'] === 'DockerWorkerConfigOmniV2CreateRequest') {
            return DockerWorkerConfigOmniV2CreateRequestFromJSONTyped(json, true);
        }
        if (json['version'] === 'DockerWorkerConfigOmniV3CreateRequest') {
            return DockerWorkerConfigOmniV3CreateRequestFromJSONTyped(json, true);
        }
        if (json['version'] === 'DockerWorkerConfigOmniV4CreateRequest') {
            return DockerWorkerConfigOmniV4CreateRequestFromJSONTyped(json, true);
        }
    }
*/
    return {
        'version': json['version'],
        'creator': !exists(json, 'creator') ? undefined : CreatorFromJSON(json['creator']),
    };
}
export function DockerWorkerConfigOmniVXCreateRequestBaseToJSON(value?: DockerWorkerConfigOmniVXCreateRequestBase | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'version': value.version,
        'creator': CreatorToJSON(value.creator),
    };
}
