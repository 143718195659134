/* tslint:disable */
/* eslint-disable */
/**
 * Lightly API
 * Lightly.ai enables you to do self-supervised learning in an easy and intuitive way. The lightly.ai OpenAPI spec defines how one can interact with our REST API to unleash the full potential of lightly.ai
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: support@lightly.ai
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
// SRC: https://github.com/OpenAPITools/openapi-generator/blob/master/modules/openapi-generator/src/main/resources/typescript-fetch/modelGeneric.mustache
// synched 02.02.2023
import * as yup from 'yup';
import typia from 'typia';
import * as fastJSON from 'fast-json-stringify';
import { Options, ArraySchema, ObjectSchema } from 'fast-json-stringify';
import { exists, mapValues } from '../runtime';
import type { AutoTaskCrop } from './AutoTaskCrop';
import { AutoTaskCropFromJSON, AutoTaskCropFromJSONTyped, AutoTaskCropToJSON, } from './AutoTaskCrop';
import type { AutoTaskTiling } from './AutoTaskTiling';
import { AutoTaskTilingFromJSON, AutoTaskTilingFromJSONTyped, AutoTaskTilingToJSON, } from './AutoTaskTiling';
/*
causes circular dependendies
import {
     AutoTaskCropFromJSONTyped,
} from './AutoTaskCrop';
import {
     AutoTaskTilingFromJSONTyped
} from './AutoTaskTiling';


*/
// SRC: https://github.com/OpenAPITools/openapi-generator/blob/master/modules/openapi-generator/src/main/resources/typescript-fetch/modelGenericInterfaces.mustache
// synced: 17.07.2023
/**
 *
 * @export
 * @interface AutoTaskBase
 *
 */
// create custom validators for the regexes
typia.customValidators.insert("pattern_name")("string")(() => {
    const regex = new RegExp(/^[a-zA-Z0-9_+=,.@:\/-]*$/);
    return (value: string) => !!regex.exec(value);
});
export interface AutoTaskBase {
    /**
     * The type of the auto task
     * #type string
     * #memberof AutoTaskBase
        
        
         */
    type: string;
    /**
     * Since we sometimes stitch together SelectionInputTask+ActiveLearningScoreType, they need to follow the same specs of ActiveLearningScoreType. However, this can be an empty string due to internal logic (no minLength). Also v2config.filespecs.ts has this pattern for predictionTaskJSONSchema as well.
     * #type string
     * #memberof AutoTaskBase
        
        
    * @pattern_name
         */
    name: string;
}
/**
 * Check if a given object implements the AutoTaskBase interface.
 */
export function instanceOfAutoTaskBase(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "type" in value;
    isInstance = isInstance && "name" in value;
    return isInstance;
}
/* LIGHTLY CUSTOM START -> modelGeneric */
import { AutoTaskCropValidator, AutoTaskCropStringifyShape, AutoTaskCropStringifyShapeProperties, } from './AutoTaskCrop';
import { AutoTaskTilingValidator, AutoTaskTilingStringifyShape, AutoTaskTilingStringifyShapeProperties, } from './AutoTaskTiling';
export const AutoTaskBaseTypiaAssertEquals = (input: any) => {
    return ((input: any): AutoTaskBase => {
        const __is = (input: any, _exceptionable: boolean = true): input is AutoTaskBase => {
            const $is_custom = (typia.createAssertEquals as any).is_custom;
            const $io0 = (input: any, _exceptionable: boolean = true): boolean => "string" === typeof input.type && ("string" === typeof input.name && $is_custom("pattern_name", "string", "", input.name)) && (2 === Object.keys(input).length || Object.keys(input).every((key: any) => {
                if (["type", "name"].some((prop: any) => key === prop))
                    return true;
                const value = input[key];
                if (undefined === value)
                    return true;
                return false;
            }));
            return "object" === typeof input && null !== input && $io0(input, true);
        };
        if (false === __is(input))
            ((input: any, _path: string, _exceptionable: boolean = true): input is AutoTaskBase => {
                const $guard = (typia.createAssertEquals as any).guard;
                const $is_custom = (typia.createAssertEquals as any).is_custom;
                const $join = (typia.createAssertEquals as any).join;
                const $ao0 = (input: any, _path: string, _exceptionable: boolean = true): boolean => ("string" === typeof input.type || $guard(_exceptionable, {
                    path: _path + ".type",
                    expected: "string",
                    value: input.type
                })) && ("string" === typeof input.name && ($is_custom("pattern_name", "string", "", input.name) || $guard(_exceptionable, {
                    path: _path + ".name",
                    expected: "string (@pattern_name)",
                    value: input.name
                })) || $guard(_exceptionable, {
                    path: _path + ".name",
                    expected: "string",
                    value: input.name
                })) && (2 === Object.keys(input).length || (false === _exceptionable || Object.keys(input).every((key: any) => {
                    if (["type", "name"].some((prop: any) => key === prop))
                        return true;
                    const value = input[key];
                    if (undefined === value)
                        return true;
                    return $guard(_exceptionable, {
                        path: _path + $join(key),
                        expected: "undefined",
                        value: value
                    });
                })));
                return ("object" === typeof input && null !== input || $guard(true, {
                    path: _path + "",
                    expected: "AutoTaskBase",
                    value: input
                })) && $ao0(input, _path + "", true) || $guard(true, {
                    path: _path + "",
                    expected: "AutoTaskBase",
                    value: input
                });
            })(input, "$input", true);
        return input;
    })(input);
};
export const AutoTaskBaseTypiaAssertStringify = (input: AutoTaskBase): string => {
    return ((input: any): string => { const assert = (input: any): AutoTaskBase => {
        const __is = (input: any): input is AutoTaskBase => {
            const $is_custom = (typia.createAssertStringify as any).is_custom;
            return "object" === typeof input && null !== input && ("string" === typeof (input as any).type && ("string" === typeof (input as any).name && $is_custom("pattern_name", "string", "", (input as any).name)));
        };
        if (false === __is(input))
            ((input: any, _path: string, _exceptionable: boolean = true): input is AutoTaskBase => {
                const $guard = (typia.createAssertStringify as any).guard;
                const $is_custom = (typia.createAssertStringify as any).is_custom;
                const $ao0 = (input: any, _path: string, _exceptionable: boolean = true): boolean => ("string" === typeof input.type || $guard(_exceptionable, {
                    path: _path + ".type",
                    expected: "string",
                    value: input.type
                })) && ("string" === typeof input.name && ($is_custom("pattern_name", "string", "", input.name) || $guard(_exceptionable, {
                    path: _path + ".name",
                    expected: "string (@pattern_name)",
                    value: input.name
                })) || $guard(_exceptionable, {
                    path: _path + ".name",
                    expected: "string",
                    value: input.name
                }));
                return ("object" === typeof input && null !== input || $guard(true, {
                    path: _path + "",
                    expected: "AutoTaskBase",
                    value: input
                })) && $ao0(input, _path + "", true) || $guard(true, {
                    path: _path + "",
                    expected: "AutoTaskBase",
                    value: input
                });
            })(input, "$input", true);
        return input;
    }; const stringify = (input: AutoTaskBase): string => {
        const $string = (typia.createAssertStringify as any).string;
        const $is_custom = (typia.createAssertStringify as any).is_custom;
        return `{"type":${$string((input as any).type)},"name":${$string((input as any).name)}}`;
    }; return stringify(assert(input)); })(input);
};
export const AutoTaskBaseValidatorShape = {
    'type': yup.string().defined(),
    'name': yup.string().matches(/^[a-zA-Z0-9_+=,.@:\/-]*$/).defined(),
};
export const AutoTaskBaseValidator = yup.object().shape(AutoTaskBaseValidatorShape);
export const AutoTaskBaseStringifyShapeProperties: ObjectSchema['properties'] = {
    'type': {
        'type': 'string',
    } as unknown as fastJSON.StringSchema,
    'name': {
        'type': 'string',
        'pattern': "/^[a-zA-Z0-9_+&#x3D;,.@:\/-]*$/".slice(1, -1),
    } as unknown as fastJSON.StringSchema,
};
export const AutoTaskBaseStringifyShape: ObjectSchema = {
    title: 'AutoTaskBase Stringifier',
    type: 'object',
    properties: {
        ...AutoTaskBaseStringifyShapeProperties
    },
    required: [
        'type',
        'name',
    ],
};
export const AutoTaskBaseStringifyCompiled = (typeof process === 'object' && String(process) === '[object process]') ?
    fastJSON.build(AutoTaskBaseStringifyShape, {
        ajv: {
            // since we are already doing this with yup, lets skip
            validateSchema: false,
            ownProperties: true,
            validateFormats: false,
        },
        largeArrayMechanism: 'json-stringify',
        largeArraySize: 1000,
    } as Options)
    :
        () => { throw new Error('fast-json-stringify only works in nodejs'); };
export const AutoTaskBaseStringify: (data: AutoTaskBase) => string = AutoTaskBaseStringifyCompiled;
/* LIGHTLY CUSTOM END -> Generic */
export function AutoTaskBaseFromJSON(json: any): AutoTaskBase {
    return AutoTaskBaseFromJSONTyped(json, false);
}
export function AutoTaskBaseFromJSONTyped(json: any, ignoreDiscriminator: boolean): AutoTaskBase {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    /* causes circular depdendencies
    if (!ignoreDiscriminator) {
        if (json['type'] === 'AutoTaskCrop') {
            return AutoTaskCropFromJSONTyped(json, true);
        }
        if (json['type'] === 'AutoTaskTiling') {
            return AutoTaskTilingFromJSONTyped(json, true);
        }
    }
*/
    return {
        'type': json['type'],
        'name': json['name'],
    };
}
export function AutoTaskBaseToJSON(value?: AutoTaskBase | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'type': value.type,
        'name': value.name,
    };
}
