import { useCallback, useState } from 'react';

export const getStorage = (key: string) => {
	const data = window.localStorage.getItem(key);
	// eslint-disable-next-line @typescript-eslint/no-unsafe-return
	return typeof data === 'string' ? JSON.parse(data) : data;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const setStorage = (key: string, value: any) => {
	window.localStorage.setItem(key, JSON.stringify(value));
};

export const removeStorage = (key: string) => {
	window.localStorage.removeItem(key);
};


export const useStoredState = <T extends string | boolean | number | Record<string, unknown>>(
	key: string,
	defaultVal: T,
) : [
	T,
	(val: T | ((prevState: T) => T)) => void
] => {
	const isSet = getStorage(key) as T ?? defaultVal;
	const [state, setState] = useState<T>(isSet)

	const setStored = useCallback((val: T | ((v: T) => T) ) => {
		let setVal: T;
		if (typeof val === 'function') {
			setVal = val(state)
		}
		else {
			setVal = val;
		}
		setStorage(key, setVal);
		setState(setVal);
	},[key, state])

	return [state, setStored]
}