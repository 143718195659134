/* tslint:disable */
/* eslint-disable */
/**
 * Lightly API
 * Lightly.ai enables you to do self-supervised learning in an easy and intuitive way. The lightly.ai OpenAPI spec defines how one can interact with our REST API to unleash the full potential of lightly.ai
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: support@lightly.ai
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
// SRC: https://github.com/OpenAPITools/openapi-generator/blob/master/modules/openapi-generator/src/main/resources/typescript-fetch/modelEnumInterfaces.mustache
// synched 02.02.2023
import * as yup from 'yup';
import * as fastJSON from 'fast-json-stringify';
import { StringSchema } from 'fast-json-stringify';
/**
 *
 * @export
 * @enum {string}
 */
export enum DockerRunLogLevel {
    VERBOSE = 'VERBOSE',
    NOTSET = 'NOTSET',
    DEBUG = 'DEBUG',
    INFO = 'INFO',
    WARNING = 'WARNING',
    ERROR = 'ERROR',
    CRITICAL = 'CRITICAL'
}
/* LIGHTLY CUSTOM START -> modelEnumInterfaces */
export const DockerRunLogLevelValidatorShape = [
    'VERBOSE',
    'NOTSET',
    'DEBUG',
    'INFO',
    'WARNING',
    'ERROR',
    'CRITICAL'
];
export const DockerRunLogLevelValidator = yup.mixed().oneOf(DockerRunLogLevelValidatorShape);
export const DockerRunLogLevelStringifyShapeProperties = {};
export const DockerRunLogLevelStringifyShape: StringSchema = {
    title: 'DockerRunLogLevel Stringifier',
    type: 'string'
};
export const DockerRunLogLevelStringify = (typeof process === 'object' && String(process) === '[object process]') ?
    fastJSON.build(DockerRunLogLevelStringifyShape)
    :
        () => { throw new Error('fast-json-stringify only works in nodejs'); };
/* LIGHTLY CUSTOM END */
export function DockerRunLogLevelFromJSON(json: any): DockerRunLogLevel {
    return DockerRunLogLevelFromJSONTyped(json, false);
}
export function DockerRunLogLevelFromJSONTyped(json: any, ignoreDiscriminator: boolean): DockerRunLogLevel {
    return json as DockerRunLogLevel;
}
export function DockerRunLogLevelToJSON(value?: DockerRunLogLevel | null): any {
    return value as any;
}
