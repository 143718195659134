/* tslint:disable */
/* eslint-disable */
/**
 * Lightly API
 * Lightly.ai enables you to do self-supervised learning in an easy and intuitive way. The lightly.ai OpenAPI spec defines how one can interact with our REST API to unleash the full potential of lightly.ai
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: support@lightly.ai
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
// SRC: https://github.com/OpenAPITools/openapi-generator/blob/master/modules/openapi-generator/src/main/resources/typescript-fetch/modelEnumInterfaces.mustache
// synched 02.02.2023
import * as yup from 'yup';
import * as fastJSON from 'fast-json-stringify';
import { StringSchema } from 'fast-json-stringify';
/**
 * When the filename is output, which format shall be used. E.g for a sample called 'frame0.png' that was uploaded from a datasource 's3://my_bucket/datasets/for_lightly/' in the folder 'car/green/' - NAME: car/green/frame0.png - DATASOURCE_FULL: s3://my_bucket/datasets/for_lightly/car/green/frame0.png - REDIRECTED_READ_URL: https://api.lightly.ai/v1/datasets/{datasetId}/samples/{sampleId}/readurlRedirect?publicToken={jsonWebToken}
 * @export
 * @enum {string}
 */
export enum FileNameFormat {
    NAME = 'NAME',
    DATASOURCE_FULL = 'DATASOURCE_FULL',
    REDIRECTED_READ_URL = 'REDIRECTED_READ_URL'
}
/* LIGHTLY CUSTOM START -> modelEnumInterfaces */
export const FileNameFormatValidatorShape = [
    'NAME',
    'DATASOURCE_FULL',
    'REDIRECTED_READ_URL'
];
export const FileNameFormatValidator = yup.mixed().oneOf(FileNameFormatValidatorShape);
export const FileNameFormatStringifyShapeProperties = {};
export const FileNameFormatStringifyShape: StringSchema = {
    title: 'FileNameFormat Stringifier',
    type: 'string'
};
export const FileNameFormatStringify = (typeof process === 'object' && String(process) === '[object process]') ?
    fastJSON.build(FileNameFormatStringifyShape)
    :
        () => { throw new Error('fast-json-stringify only works in nodejs'); };
/* LIGHTLY CUSTOM END */
export function FileNameFormatFromJSON(json: any): FileNameFormat {
    return FileNameFormatFromJSONTyped(json, false);
}
export function FileNameFormatFromJSONTyped(json: any, ignoreDiscriminator: boolean): FileNameFormat {
    return json as FileNameFormat;
}
export function FileNameFormatToJSON(value?: FileNameFormat | null): any {
    return value as any;
}
