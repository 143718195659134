/* tslint:disable */
/* eslint-disable */
/**
 * Lightly API
 * Lightly.ai enables you to do self-supervised learning in an easy and intuitive way. The lightly.ai OpenAPI spec defines how one can interact with our REST API to unleash the full potential of lightly.ai
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: support@lightly.ai
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
// SRC: https://github.com/OpenAPITools/openapi-generator/blob/master/modules/openapi-generator/src/main/resources/typescript-fetch/modelEnumInterfaces.mustache
// synched 02.02.2023
import * as yup from 'yup';
import * as fastJSON from 'fast-json-stringify';
import { StringSchema } from 'fast-json-stringify';
/**
 *
 * @export
 * @enum {string}
 */
export enum ImageType {
    full = 'full',
    thumbnail = 'thumbnail',
    meta = 'meta'
}
/* LIGHTLY CUSTOM START -> modelEnumInterfaces */
export const ImageTypeValidatorShape = [
    'full',
    'thumbnail',
    'meta'
];
export const ImageTypeValidator = yup.mixed().oneOf(ImageTypeValidatorShape);
export const ImageTypeStringifyShapeProperties = {};
export const ImageTypeStringifyShape: StringSchema = {
    title: 'ImageType Stringifier',
    type: 'string'
};
export const ImageTypeStringify = (typeof process === 'object' && String(process) === '[object process]') ?
    fastJSON.build(ImageTypeStringifyShape)
    :
        () => { throw new Error('fast-json-stringify only works in nodejs'); };
/* LIGHTLY CUSTOM END */
export function ImageTypeFromJSON(json: any): ImageType {
    return ImageTypeFromJSONTyped(json, false);
}
export function ImageTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): ImageType {
    return json as ImageType;
}
export function ImageTypeToJSON(value?: ImageType | null): any {
    return value as any;
}
