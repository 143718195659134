export const glossary = {

	/*
	* TAGS
	*/
	'tags': 'Tags',
	'tags.intro': 'A tag captures a subset of samples from your dataset. Create tags to keep track of changes.',

	'tags.tag-tree': 'Tag Tree',
	'tags.tag-tree.short': `The tag tree gives you an overview of all the tags you have created in the past and how they have come to be.
	Creating tags allows you to easily keep track of changes while curating your dataset.
	<br/><br/>Tag operations allow you to calculate the union and intersection of two different tags. To access the tag operations menu
	simply <b><i>select a tag</i></b> in the tag tree and <b><i>shift-click</i></b> on a second tag.`,

	/*
	* METADATA
	*/
	'metadata': 'Metadata',
	'metadata.intro': 'Metadata represent structural and descriptive information about the samples in your dataset. E.g. filesize',

	'metadata.decision-boundary': 'Decision Boundary',
	'metadata.decision-boundary.short': `Whenever you move a slider below one of the histograms you update the decision boundary.
	<br/>We highlight the two samples left and right of the slider position. Use the preview to guide your selection.`,

	'metadata.sharpness': 'Sharpness',
	'metadata.sharpness.short': `A low sharpness value is usually an indicator that the image is very blurry (motion blur, gaussian blur, fog, steam).
	<br/>A high sharpness value is an indicator of a stable shot. To calculate the sharpness we use a laplacian 3x3 kernel and calculate the standard deviation of the result.`,

	'metadata.uniformRowRatio': 'Uniform Row Ratio',
	'metadata.uniformRowRatio.short': `The uniform row ratio value is between 0 and 1: Higher values typically indicate undesired artifacts from image decoding.
	<br/>For more details, please refer our <a target='_blank' rel='noopener' href='https://docs.lightly.ai/docs/selection#metadata'>docs about metadata</a>`,

	'metadata.signal-to-noise-ratio': 'Signal to noise ratio',
	'metadata.signal-to-noise-ratio.short': `SnR is a metric to capture signal strength compared to the noise level.
	<br/>A low SnR score means that there is a lot of noise in the image (e.g. night scene where a camera has a high ISO).
	<br/>A high SnR score usually means that there is a clean signal. To calculate the value we simply divide the mean by standard deviation for each color channel.`,

	'metadata.luminance': 'Luminance',
	'metadata.luminance.short': `Since brightness is perceptual we show the luminance adjusted to the non-linear human vision (the perceived lightness of an image <strong><i>L*</i></strong>).
	<br/><strong>Luminance</strong> is a linear measure of light, spectrally weighted for normal vision but not adjusted for the non-linear perception of lightness.
	<br/><strong>Perceived lightness</strong> is used by some vision models such as CIELAB here <strong><i>L*</i></strong> (Lstar) is a value of perceptual lightness, and is non-linear to approximate the human vision non-linear response curve.
	<br/>For more details, please refer to the <a target='_blank' rel='noopener' href='https://en.wikipedia.org/wiki/CIELAB_color_space'>CIELAB article on Wikipedia</a>`,

	/*
	* CUSTOM METADATA
	*/
	'metadata-custom': 'Custom metadata',
	'metadata-custom.intro': 'It is possible to upload additional unstructured metadata to Lightly. To make maximum usage of this information, you can configure how to access the important meta information and what can be done with it.',
	'metadata-custom.custom-configurator': 'Custom metadata',
	'metadata-custom.custom-configurator.short': `
		It is possible to upload additional unstructured metadata to Lightly. To make maximum usage of this information, you can configure how to access the important meta information and what can be done with it.
		<br/>
		We support categorical and numerical data
	`,
	'metadata-custom.custom-configurator.full': `
		It is possible to upload additional unstructured metadata to Lightly. To make maximum usage of this information, you can configure how to access the important meta information and what can be done with it.
		<br/>
		<br/>
		We support different data types for the extracted value. This tells Lightly how to interpret the value and also allows you to do different things.
		<br/>
		<ul>
		<li><i>Numeric</i> means the extracted values are in a range and have a lower and upper bound. E.g. used for color ranges</li>
		<li><i>Categorical</i> means the extracted values are distinct and can be grouped. This allows us to e.g. plot distributions of each unique value within your dataset and to map each unique value to a color </li>
			<ul>
			<li>string: most often used for class/category e.g. for city, animal or weather condition</li>
			<li>int: e.g. for ratings of a meal</li>
			<li>boolean: for true/false distinctions as e.g. isVerified or flashOn</li>
			<li>datetime: e.g. for grouping by time</li>
			<li>timestamp: e.g. for grouping by time</li>
			</ul>
		<li><i>Other</i> means that the extracted value is important to you but does not fit another category. It is displayed alongside other information in the sample detail. E.g. the license</li>
		</ul>
	`,

	'metadata-custom.custom-configurator-path': 'Configurator path',
	'metadata-custom.custom-configurator-path.short': `
	The path allows you to easily access/extract your desired custom metadata from the JSON structure you provided for a sample. It is written in dotnotation e.g. myArray[0].myObject.field
	`,


	'metadata-custom.correlation-explanation': 'Correlate Metadata',
	'metadata-custom.correlation-explanation.short': 'The correlation plot correlates two different metadata with each other. Set a metadata on the X or Y axis to see how they correlate and colorize them by another metadata to get deep insights in to your data.',


	/*
	* EMBEDDINGS
	*/
	'embedding': 'Embedding',
	'embedding.intro': 'Embeddings can be used together with selection strategies to select the most important samples out of your dataset. We leverage self-supervised learning to obtain good features/representations/embeddings of your unlabeled data.',

	'embedding.2d-embedding-explanation': 'What is a 2d embedding',
	'embedding.2d-embedding-explanation.short': `We use dimensionality-reduction methods to reduce the embeddings to two dimensions for the plot.
	<br/>Each dot represents one sample from the dataset. The color indicates whether the sample is in the active tag set.
	<br/>Use the plot to find clusters, dataset imbalances and outliers.`,

	'embedding.2d-embedding-PCA-explanation': 'What is PCA',
	'embedding.2d-embedding-PCA-explanation.short': `Principal Component Analysis (PCA), is a dimensionality-reduction method that is often used to reduce the dimensionality of large datasets so it can be visualized in 2d space. The idea of PCA is simple — reduce the number of variables of a dataset, while preserving as much information as possible.
	<br/>For a more thorough explanation, please refer to the <a target='_blank' rel='noopener' href='https://en.wikipedia.org/wiki/Principal_component_analysis'>PCA article on Wikipedia</a>`,

	'embedding.2d-embedding-UMAP-explanation': 'What is UMAP',
	'embedding.2d-embedding-UMAP-explanation.short': `Uniform Manifold Approximation and Projection (UMAP)) is a novel manifold learning technique for dimension reduction. The result is a practical scalable algorithm that applies to real world data. The UMAP algorithm is competitive with t-SNE for visualization quality, and arguably preserves more of the global structure with superior run time performance.
	<br/>For a more thorough explanation, please refer to the <a target='_blank' rel='noopener' href='https://en.wikipedia.org/wiki/Nonlinear_dimensionality_reduction#Uniform_manifold_approximation_and_projection'>UMAP article on Wikipedia</a>`,

	'embedding.2d-embedding-TSNE-explanation': 'What is TSNE',
	'embedding.2d-embedding-TSNE-explanation.short': `t-distributed stochastic neighbor embedding (t-SNE or TSNE), is a dimensionality-reduction method that is often used to reduce the dimensionality of large datasets so it can be visualized in 2d space. The idea of TSNE is that similar objects are modeled by nearby points and dissimilar objects are modeled by distant points with high probability.
	<br/>For a more thorough explanation, please refer to the <a target='_blank' rel='noopener' href='https://en.wikipedia.org/wiki/T-distributed_stochastic_neighbor_embedding'>TSNE article on Wikipedia</a>`,

	'embedding.2d-embedding.property-coloring': 'Color by property',
	'embedding.2d-embedding.property-coloring.short': 'By selecting a known metadata property you can map the samples values by color on to the scatterplot',

	'embedding.2d-embedding.dynamic-userDefined': 'Dynamic Custom Metadata',
	'embedding.2d-embedding.dynamic-userDefined.short': `Lightly supports dynamic properties which - depending on the context - become available. Like <b>custom metadata</b>;
	<br/>It is possible to upload additional unstructured metadata to Lightly which you can configure to become structured: or more information, please refer to the <a target='_blank' rel='noopener' href='https://docs.lightly.ai/docs/work-with-metadata'>documentation</a>`,

	'embedding.2d-embedding.dynamic-activeLearningv2': 'Dynamic Active Learning',
	'embedding.2d-embedding.dynamic-activeLearningv2.short': `Lightly supports dynamic properties which - depending on the context - become available. Like <b>active learning</b>;
	<br/>There are different score types we support out of the box: for more information, please refer to the <a target='_blank' rel='noopener' href='https://docs.lightly.ai/docs/work-with-predictions'>documentation</a>`,

	'embedding.2d-embedding.dynamic-predictions': 'Dynamic Predictions',
	'embedding.2d-embedding.dynamic-predictions.short': `Lightly supports dynamic properties which - depending on the context - become available. Like <b>predictions</b>;
	<br/>Depending on which prediction type was used we automatically calculate interesting information about your predictions: for more information , please refer to the <a target='_blank' rel='noopener' href='https://docs.lightly.ai/docs/work-with-predictions'>documentation</a>`,

	'embedding.scatterplot-help': 'How to interact with the scatterplot',
	'embedding.scatterplot-help.short': `
		<ul>
			<li>To <strong>pan</strong>, click and drag the scatterplot</li>
			<li>To <strong>zoom</strong>, use your mousewheel</li>
			<li>To <strong>select</strong>, press shift and draw a circle</li>
			<li>To <strong>merge</strong>, press shift and alt while drawing a second circle</li>
		</ul>
	`,

	'embedding.similar-samples': 'Similar samples',
	'embedding.similar-samples.short': 'Similar samples are shown on the basis of the embedding you provided. If available, we calculate the similarity of samples based on the UMAP dimensionality-reduction method.',


	/*
	* Selection
	*/
	'sampling': 'Selection',
	'sampling.intro': 'Selection allows you to reduce the amount of samples in your dataset by using a selection strategy on the embeddings you provided',

	'sampling.sampling-strategy': 'Selection strategies',
	'sampling.sampling-strategy.short': `<ul>
		<li>You can <b>randomly</b> select samples from your dataset using the random selection strategy.</li>
		<li><b>Coreset</b> selection strategy selects samples in a way to ensure that the full space is covered.
		E.g. samples get added to the set one by one ensuring newly added ones are as far apart from the existing set as possible.</li>
	</ul>`,

	'sampling.sampling-stoppingCondition': 'Selection stopping conditions',
	'sampling.sampling-stoppingCondition.short': `
	You can configure certain stopping conditions for your selected strategy.
	<ul>
		<li>Using CORESET or RANDOM allows us to set the amount of dataset points we want to reduce to (decreasing)</li>
		<li>The minimum distance sets the minimum distance needed between two points before considering it. This is helpful if you have many very closely related points and want to have bigger diversity after applying your selection strategy. But it also can hurt your selection strategy if you choose a value too small/too big</li>
	</ul>`,


	'sampling.sampling-explanation': 'Selection explanation',
	'sampling.sampling-explanation.short': `Selection strategies allow you to reduce the dataset size.
	<br/>You can use them to remove redundant/ near-duplicate images.`,

	/*
	* DATASOURCE
	*/
	'datasource': 'External Datasource',
	'datasource.intro': 'Instead of uploading all your images to Lightly you can setup a datasource from which we will show you the images',
	'datasource.thumbSuffix-enable': 'Generating thumbnails',
	'datasource.thumbSuffix-enable.short': `
		Lightly can generate thumbnails for you for which write access to your bucket is required (recommended).
		<br/>We will write the thumbnails to <i>'{yourResourcePath}/.lightly/thumbnails'</i>. If you wish for us to write the files to a different location, please configure an output datasource.
		<br/>If you don't wish us to generate thumbnails, Lightly falls back to use the full image when needed. This will degrade the overall performance of your browser and also causes higher costs due to transferring more data.
	`,
	'datasource.lightly-datasource': 'Output datasource',
	'datasource.lightly-datasource.short': `
		You can configure Lightly to use a different datasource for the .lightly folder.
		<br/>We use this folder to e.g. store thumbnails, frames of videos or for crops.
		<br/>This is also the location where you can provide us with additional information about your samples such as predictions or metadata.
	`,
	'datasource.s3-sse-enable': 'Server Side Encryption (SSE)',
	'datasource.s3-sse-enable.short': `
		You can enable server side encryption for your S3 bucket as outlined in the <a target='_blank' rel='noopener' href='https://docs.aws.amazon.com/AmazonS3/latest/userguide/UsingKMSEncryption.html'>documentation of AWS</a>.
		If set, Lightly Worker will automatically set the necessary headers to use server side encryption with your KMS key when creating assets (crops, frames, thumbnails) in the S3 bucket.
	`,


	/*
	* DOCKERRUNS
	*/
	'dockerruns': 'Docker Runs',
	'dockerruns.intro': 'When using the Lightly Worker to process large datasets (>250\'000 samples) you can monitor its progress within the web platform in the docker runs menu. Please refer to the <a target=\'_blank\' rel=\'noopener\' href=\'https://docs.lightly.ai/docs/runs\'>Lightly Worker documentation</a> for more information.',

	/*
	* DOCKERWORKER
	*/
	'dockerworkers': 'Docker Workers',
	'dockerworkers.intro': `
		The Lightly Worker is used to process large datasets (>250'000 samples) by listening for new runs to be processed after they are <a target='_blank' rel='noopener' href='https://docs.lightly.ai/docs/run-your-first-selection#scheduling-a-run'>scheduled</a>. You can register and run multiple workers in parallel on e.g. different instances and have these process only <a target='_blank' rel='noopener' href='https://docs.lightly.ai/docs/assign-scheduled-runs-to-specific-workers'>runs with specific labels</a>.
		<br/>Please refer to the <a target='_blank' rel='noopener' href='https://docs.lightly.ai/docs/assign-scheduled-runs-to-specific-workers'>Lightly Worker documentation</a> for more information.
	`,
	'dockerworkers.registering': 'Registering a Lightly Worker',
	'dockerworkers.registering.short': `
		<a target='_blank' rel='noopener' href='https://docs.lightly.ai/docs/install-lightly#register-the-lightly-worker'>Registering a Lightly Worker</a> allows you to schedule multiple jobs and have them be processed in the background. Labels allow to <a target='_blank' rel='noopener' href='https://docs.lightly.ai/docs/assign-scheduled-runs-to-specific-workers'>assign scheduled runs to specific workers</a>.
	`,
	'dockerworkers.registering.full': `
		<a target='_blank' rel='noopener' href='https://docs.lightly.ai/docs/install-lightly#register-the-lightly-worker'>Registering a Lightly Worker</a> allows you to have multiple Lightly Workers running on different instances of yours and processing multiple runs of different datasets in parallel in the background.
		<br/>
	`,

	/*
	* ACTIVE LEARNING
	*/
	'alscores': 'Active Learning Scores',
	'alscores.intro': 'You can use model predictions and the scorers from the lightly pip package to compute active learning scores for each sample. You can sort your dataset based on these scores in explore view or color the samples based on the scores in the embedding view. Use the scores to find samples where your model is struggling. Learn more in the <a target=\'_blank\' rel=\'noopener\' href=\'https://docs.lightly.ai/docs/work-with-predictions\'>active learning scores documentation</a>',
}
export default glossary;
