/* tslint:disable */
/* eslint-disable */
/**
 * Lightly API
 * Lightly.ai enables you to do self-supervised learning in an easy and intuitive way. The lightly.ai OpenAPI spec defines how one can interact with our REST API to unleash the full potential of lightly.ai
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: support@lightly.ai
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
// SRC: https://github.com/OpenAPITools/openapi-generator/blob/master/modules/openapi-generator/src/main/resources/typescript-fetch/modelEnumInterfaces.mustache
// synched 02.02.2023
import * as yup from 'yup';
import * as fastJSON from 'fast-json-stringify';
import { StringSchema } from 'fast-json-stringify';
/**
 *
 * @export
 * @enum {string}
 */
export enum Usage {
    LABELING = 'LABELING',
    SELECTING = 'SELECTING',
    VISUALIZATION = 'VISUALIZATION',
    MODEL_TRAINING = 'MODEL_TRAINING',
    DATASET_MANAGING = 'DATASET_MANAGING',
    CHECKING_THE_TOOL = 'CHECKING_THE_TOOL',
    OTHER = 'OTHER'
}
/* LIGHTLY CUSTOM START -> modelEnumInterfaces */
export const UsageValidatorShape = [
    'LABELING',
    'SELECTING',
    'VISUALIZATION',
    'MODEL_TRAINING',
    'DATASET_MANAGING',
    'CHECKING_THE_TOOL',
    'OTHER'
];
export const UsageValidator = yup.mixed().oneOf(UsageValidatorShape);
export const UsageStringifyShapeProperties = {};
export const UsageStringifyShape: StringSchema = {
    title: 'Usage Stringifier',
    type: 'string'
};
export const UsageStringify = (typeof process === 'object' && String(process) === '[object process]') ?
    fastJSON.build(UsageStringifyShape)
    :
        () => { throw new Error('fast-json-stringify only works in nodejs'); };
/* LIGHTLY CUSTOM END */
export function UsageFromJSON(json: any): Usage {
    return UsageFromJSONTyped(json, false);
}
export function UsageFromJSONTyped(json: any, ignoreDiscriminator: boolean): Usage {
    return json as Usage;
}
export function UsageToJSON(value?: Usage | null): any {
    return value as any;
}
