/* tslint:disable */
/* eslint-disable */
/**
 * Lightly API
 * Lightly.ai enables you to do self-supervised learning in an easy and intuitive way. The lightly.ai OpenAPI spec defines how one can interact with our REST API to unleash the full potential of lightly.ai
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: support@lightly.ai
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
// SRC: https://github.com/OpenAPITools/openapi-generator/blob/master/modules/openapi-generator/src/main/resources/typescript-fetch/modelEnumInterfaces.mustache
// synched 02.02.2023
import * as yup from 'yup';
import * as fastJSON from 'fast-json-stringify';
import { StringSchema } from 'fast-json-stringify';
/**
 *
 * @export
 * @enum {string}
 */
export enum DockerRunScheduledPriority {
    LOW = 'LOW',
    MID = 'MID',
    HIGH = 'HIGH',
    CRITICAL = 'CRITICAL'
}
/* LIGHTLY CUSTOM START -> modelEnumInterfaces */
export const DockerRunScheduledPriorityValidatorShape = [
    'LOW',
    'MID',
    'HIGH',
    'CRITICAL'
];
export const DockerRunScheduledPriorityValidator = yup.mixed().oneOf(DockerRunScheduledPriorityValidatorShape);
export const DockerRunScheduledPriorityStringifyShapeProperties = {};
export const DockerRunScheduledPriorityStringifyShape: StringSchema = {
    title: 'DockerRunScheduledPriority Stringifier',
    type: 'string'
};
export const DockerRunScheduledPriorityStringify = (typeof process === 'object' && String(process) === '[object process]') ?
    fastJSON.build(DockerRunScheduledPriorityStringifyShape)
    :
        () => { throw new Error('fast-json-stringify only works in nodejs'); };
/* LIGHTLY CUSTOM END */
export function DockerRunScheduledPriorityFromJSON(json: any): DockerRunScheduledPriority {
    return DockerRunScheduledPriorityFromJSONTyped(json, false);
}
export function DockerRunScheduledPriorityFromJSONTyped(json: any, ignoreDiscriminator: boolean): DockerRunScheduledPriority {
    return json as DockerRunScheduledPriority;
}
export function DockerRunScheduledPriorityToJSON(value?: DockerRunScheduledPriority | null): any {
    return value as any;
}
