import { ApiErrorResponse } from '@lightly/api-spec';

export interface IGenericError {
	code?: number;
	codeName?: string;
	message: string;
}

export async function handleFetchError(err: Response | Error | string): Promise<IGenericError> {
	let codeName = '';
	let code = 666;
	let message = '';
	if (typeof err === 'string') {
		message = err;
	}
	else if (err instanceof Response) {
		// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
		const json = await err.json() as unknown as ApiErrorResponse
		code = Number(err.status);
		codeName = json.code;
		message = `${json.error || err.statusText || 'unknown error'}${json.requestId ? ` (${json.requestId})` : ''}`
	}
	else {
		message = err.message
	}

	return {
		code,
		codeName,
		message: message || 'unknown error',
	};
}