import React, { useMemo } from 'react';

import { generatePath, Link, matchPath, useLocation } from 'react-router-dom';

import { IRoute, RouteParams } from './routes.types';



// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const useMatchRoute = <R extends IRoute>(route: R) => {
	const location = useLocation();
	const matched = useMemo(() => {
		return matchPath<RouteParams<typeof route>>(location.pathname + location.hash, route.path);
	}, [location.pathname, location.hash, route]);
	return matched;
};


type extractPathParams<R> = R extends IRoute<infer P> ? P : undefined;
export const makeRouteURL = <R extends IRoute>(route: R, params: extractPathParams<R> | null = null): string => {
	const gen = unescape(generatePath(route.path, params || {}));
	return gen;
};

export const makeLinkToRoute = <R extends IRoute>(route: R, params: extractPathParams<R> | null = null, name?: string | JSX.Element): JSX.Element => {
	const path = makeRouteURL(route, params);
	return <Link to={path}>{name || route.name}</Link>;
};