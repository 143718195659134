/* tslint:disable */
/* eslint-disable */
/**
 * Lightly API
 * Lightly.ai enables you to do self-supervised learning in an easy and intuitive way. The lightly.ai OpenAPI spec defines how one can interact with our REST API to unleash the full potential of lightly.ai
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: support@lightly.ai
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
// SRC: https://github.com/OpenAPITools/openapi-generator/blob/master/modules/openapi-generator/src/main/resources/typescript-fetch/modelGeneric.mustache
// synched 02.02.2023
import * as yup from 'yup';
import typia from 'typia';
import * as fastJSON from 'fast-json-stringify';
import { Options, ArraySchema, ObjectSchema } from 'fast-json-stringify';
import { exists, mapValues } from '../runtime';
/*
causes circular dependendies
*/
// SRC: https://github.com/OpenAPITools/openapi-generator/blob/master/modules/openapi-generator/src/main/resources/typescript-fetch/modelGenericInterfaces.mustache
// synced: 17.07.2023
/**
 *
 * @export
 * @interface DockerWorkerConfigV4DockerCrops
 *
 */
// create custom validators for the regexes
export interface DockerWorkerConfigV4DockerCrops {
    /**
     * If false, no crop dataset is created nor are the crops uploaded to the bucket. Predictions (e.g bounding boxes) will be created on the main dataset regardless.
     * #type boolean
     * #memberof DockerWorkerConfigV4DockerCrops
        
        
         */
    enableUpload?: boolean;
    /**
     * Array of tasks (prediction tasks and autoTasks) for which the crop dataset creation and upload is enabled. If unset, all tasks are uploaded by default.
     * #type Array<string>
     * #memberof DockerWorkerConfigV4DockerCrops
        
        
         */
    enableUploadForTasks?: Array<string> | null;
}
/**
 * Check if a given object implements the DockerWorkerConfigV4DockerCrops interface.
 */
export function instanceOfDockerWorkerConfigV4DockerCrops(value: object): boolean {
    let isInstance = true;
    return isInstance;
}
/* LIGHTLY CUSTOM START -> modelGeneric */
export const DockerWorkerConfigV4DockerCropsTypiaAssertEquals = (input: any) => {
    return ((input: any): DockerWorkerConfigV4DockerCrops => {
        const __is = (input: any, _exceptionable: boolean = true): input is DockerWorkerConfigV4DockerCrops => {
            const $io0 = (input: any, _exceptionable: boolean = true): boolean => (undefined === input.enableUpload || "boolean" === typeof input.enableUpload) && (null === input.enableUploadForTasks || undefined === input.enableUploadForTasks || Array.isArray(input.enableUploadForTasks) && input.enableUploadForTasks.every((elem: any, _index1: number) => "string" === typeof elem)) && (0 === Object.keys(input).length || Object.keys(input).every((key: any) => {
                if (["enableUpload", "enableUploadForTasks"].some((prop: any) => key === prop))
                    return true;
                const value = input[key];
                if (undefined === value)
                    return true;
                return false;
            }));
            return "object" === typeof input && null !== input && false === Array.isArray(input) && $io0(input, true);
        };
        if (false === __is(input))
            ((input: any, _path: string, _exceptionable: boolean = true): input is DockerWorkerConfigV4DockerCrops => {
                const $guard = (typia.createAssertEquals as any).guard;
                const $join = (typia.createAssertEquals as any).join;
                const $ao0 = (input: any, _path: string, _exceptionable: boolean = true): boolean => (undefined === input.enableUpload || "boolean" === typeof input.enableUpload || $guard(_exceptionable, {
                    path: _path + ".enableUpload",
                    expected: "(boolean | undefined)",
                    value: input.enableUpload
                })) && (null === input.enableUploadForTasks || undefined === input.enableUploadForTasks || (Array.isArray(input.enableUploadForTasks) || $guard(_exceptionable, {
                    path: _path + ".enableUploadForTasks",
                    expected: "(Array<string> | null | undefined)",
                    value: input.enableUploadForTasks
                })) && input.enableUploadForTasks.every((elem: any, _index1: number) => "string" === typeof elem || $guard(_exceptionable, {
                    path: _path + ".enableUploadForTasks[" + _index1 + "]",
                    expected: "string",
                    value: elem
                })) || $guard(_exceptionable, {
                    path: _path + ".enableUploadForTasks",
                    expected: "(Array<string> | null | undefined)",
                    value: input.enableUploadForTasks
                })) && (0 === Object.keys(input).length || (false === _exceptionable || Object.keys(input).every((key: any) => {
                    if (["enableUpload", "enableUploadForTasks"].some((prop: any) => key === prop))
                        return true;
                    const value = input[key];
                    if (undefined === value)
                        return true;
                    return $guard(_exceptionable, {
                        path: _path + $join(key),
                        expected: "undefined",
                        value: value
                    });
                })));
                return ("object" === typeof input && null !== input && false === Array.isArray(input) || $guard(true, {
                    path: _path + "",
                    expected: "DockerWorkerConfigV4DockerCrops",
                    value: input
                })) && $ao0(input, _path + "", true) || $guard(true, {
                    path: _path + "",
                    expected: "DockerWorkerConfigV4DockerCrops",
                    value: input
                });
            })(input, "$input", true);
        return input;
    })(input);
};
export const DockerWorkerConfigV4DockerCropsTypiaAssertStringify = (input: DockerWorkerConfigV4DockerCrops): string => {
    return ((input: any): string => { const assert = (input: any): DockerWorkerConfigV4DockerCrops => {
        const __is = (input: any): input is DockerWorkerConfigV4DockerCrops => {
            const $io0 = (input: any): boolean => (undefined === input.enableUpload || "boolean" === typeof input.enableUpload) && (null === input.enableUploadForTasks || undefined === input.enableUploadForTasks || Array.isArray(input.enableUploadForTasks) && input.enableUploadForTasks.every((elem: any) => "string" === typeof elem));
            return "object" === typeof input && null !== input && false === Array.isArray(input) && $io0(input);
        };
        if (false === __is(input))
            ((input: any, _path: string, _exceptionable: boolean = true): input is DockerWorkerConfigV4DockerCrops => {
                const $guard = (typia.createAssertStringify as any).guard;
                const $ao0 = (input: any, _path: string, _exceptionable: boolean = true): boolean => (undefined === input.enableUpload || "boolean" === typeof input.enableUpload || $guard(_exceptionable, {
                    path: _path + ".enableUpload",
                    expected: "(boolean | undefined)",
                    value: input.enableUpload
                })) && (null === input.enableUploadForTasks || undefined === input.enableUploadForTasks || (Array.isArray(input.enableUploadForTasks) || $guard(_exceptionable, {
                    path: _path + ".enableUploadForTasks",
                    expected: "(Array<string> | null | undefined)",
                    value: input.enableUploadForTasks
                })) && input.enableUploadForTasks.every((elem: any, _index1: number) => "string" === typeof elem || $guard(_exceptionable, {
                    path: _path + ".enableUploadForTasks[" + _index1 + "]",
                    expected: "string",
                    value: elem
                })) || $guard(_exceptionable, {
                    path: _path + ".enableUploadForTasks",
                    expected: "(Array<string> | null | undefined)",
                    value: input.enableUploadForTasks
                }));
                return ("object" === typeof input && null !== input && false === Array.isArray(input) || $guard(true, {
                    path: _path + "",
                    expected: "DockerWorkerConfigV4DockerCrops",
                    value: input
                })) && $ao0(input, _path + "", true) || $guard(true, {
                    path: _path + "",
                    expected: "DockerWorkerConfigV4DockerCrops",
                    value: input
                });
            })(input, "$input", true);
        return input;
    }; const stringify = (input: DockerWorkerConfigV4DockerCrops): string => {
        const $string = (typia.createAssertStringify as any).string;
        const $tail = (typia.createAssertStringify as any).tail;
        const $so0 = (input: any): any => `{${$tail(`${undefined === input.enableUpload ? "" : `"enableUpload":${undefined !== input.enableUpload ? input.enableUpload : undefined},`}${undefined === input.enableUploadForTasks ? "" : `"enableUploadForTasks":${undefined !== input.enableUploadForTasks ? null !== input.enableUploadForTasks ? `[${input.enableUploadForTasks.map((elem: any) => $string(elem)).join(",")}]` : "null" : undefined}`}`)}}`;
        return $so0(input);
    }; return stringify(assert(input)); })(input);
};
export const DockerWorkerConfigV4DockerCropsValidatorShape = {
    'enableUpload': yup.boolean(),
    'enableUploadForTasks': yup.array().of(yup.string()).nullable(),
};
export const DockerWorkerConfigV4DockerCropsValidator = yup.object().shape(DockerWorkerConfigV4DockerCropsValidatorShape);
export const DockerWorkerConfigV4DockerCropsStringifyShapeProperties: ObjectSchema['properties'] = {
    'enableUpload': {
        'type': 'boolean',
    },
    'enableUploadForTasks': {
        type: 'array',
        items: {
            type: 'string'
        } as unknown as fastJSON.Schema,
    } as unknown as fastJSON.ArraySchema,
};
export const DockerWorkerConfigV4DockerCropsStringifyShape: ObjectSchema = {
    title: 'DockerWorkerConfigV4DockerCrops Stringifier',
    type: 'object',
    properties: {
        ...DockerWorkerConfigV4DockerCropsStringifyShapeProperties
    },
    required: [],
};
export const DockerWorkerConfigV4DockerCropsStringifyCompiled = (typeof process === 'object' && String(process) === '[object process]') ?
    fastJSON.build(DockerWorkerConfigV4DockerCropsStringifyShape, {
        ajv: {
            // since we are already doing this with yup, lets skip
            validateSchema: false,
            ownProperties: true,
            validateFormats: false,
        },
        largeArrayMechanism: 'json-stringify',
        largeArraySize: 1000,
    } as Options)
    :
        () => { throw new Error('fast-json-stringify only works in nodejs'); };
export const DockerWorkerConfigV4DockerCropsStringify: (data: DockerWorkerConfigV4DockerCrops) => string = DockerWorkerConfigV4DockerCropsStringifyCompiled;
/* LIGHTLY CUSTOM END -> Generic */
export function DockerWorkerConfigV4DockerCropsFromJSON(json: any): DockerWorkerConfigV4DockerCrops {
    return DockerWorkerConfigV4DockerCropsFromJSONTyped(json, false);
}
export function DockerWorkerConfigV4DockerCropsFromJSONTyped(json: any, ignoreDiscriminator: boolean): DockerWorkerConfigV4DockerCrops {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    /* causes circular depdendencies
*/
    return {
        'enableUpload': !exists(json, 'enableUpload') ? undefined : json['enableUpload'],
        'enableUploadForTasks': !exists(json, 'enableUploadForTasks') ? undefined : json['enableUploadForTasks'],
    };
}
export function DockerWorkerConfigV4DockerCropsToJSON(value?: DockerWorkerConfigV4DockerCrops | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'enableUpload': value.enableUpload,
        'enableUploadForTasks': value.enableUploadForTasks,
    };
}
