import React from 'react';

import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import { createRoot } from 'react-dom/client';
import { matchPath } from 'react-router-dom';


import { CssBaseline, ThemeProvider, StyledEngineProvider } from '@mui/material';

import { App } from './components/App';
import { Auth0Provider } from './contexts/auth0-context';
import { history } from './helpers/router';
import { routes } from './routes';
import { flattenRoutes } from './routing';
import { LightlyTheme } from './theme/lightly';


import './css/index.css';


if ( String(process.env.REACT_APP_SENTRY_TRACK_ERRORS) === 'true' && process.env.REACT_APP_BUILD_MODE && process.env.REACT_APP_BUILD_MODE !== 'local') {
	Sentry.init({
		dsn: process.env.REACT_APP_SENTRY_DSN,
		environment: `${process.env.REACT_APP_SENTRY_ENVIRONMENT || process.env.REACT_APP_BUILD_MODE || 'local'}@${window.location.hostname.split('.')[0]}`,
		release: process.env.REACT_APP_SENTRY_RELEASE || 'dev',
		autoSessionTracking: true,
		integrations: [
			new Integrations.BrowserTracing(
				{
					routingInstrumentation: Sentry.reactRouterV5Instrumentation(
						history,
						flattenRoutes(routes),
						matchPath,
					),
				},
			),
		],
		tracesSampleRate: ['prod', 'staging'].includes(process.env.REACT_APP_BUILD_MODE || '') ? 0.1 : .1,
	});
}

const container = document.getElementById('root');
// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
const root = createRoot(container!);

root.render(<React.StrictMode>
	<Auth0Provider>
		<StyledEngineProvider injectFirst>
			<ThemeProvider theme={LightlyTheme}>
				<CssBaseline enableColorScheme />
				<App />
			</ThemeProvider>
		</StyledEngineProvider>
	</Auth0Provider>
</React.StrictMode> );