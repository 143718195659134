/* tslint:disable */
/* eslint-disable */
/**
 * Lightly API
 * Lightly.ai enables you to do self-supervised learning in an easy and intuitive way. The lightly.ai OpenAPI spec defines how one can interact with our REST API to unleash the full potential of lightly.ai
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: support@lightly.ai
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
// SRC: https://github.com/OpenAPITools/openapi-generator/blob/master/modules/openapi-generator/src/main/resources/typescript-fetch/modelGeneric.mustache
// synched 02.02.2023
import * as yup from 'yup';
import typia from 'typia';
import * as fastJSON from 'fast-json-stringify';
import { Options, ArraySchema, ObjectSchema } from 'fast-json-stringify';
import { exists, mapValues } from '../runtime';
import type { TagChangeData } from './TagChangeData';
import { TagChangeDataFromJSON, TagChangeDataFromJSONTyped, TagChangeDataToJSON, } from './TagChangeData';
import type { TagCreator } from './TagCreator';
import { TagCreatorFromJSON, TagCreatorFromJSONTyped, TagCreatorToJSON, } from './TagCreator';
/*
causes circular dependendies
*/
// SRC: https://github.com/OpenAPITools/openapi-generator/blob/master/modules/openapi-generator/src/main/resources/typescript-fetch/modelGenericInterfaces.mustache
// synced: 17.07.2023
/**
 *
 * @export
 * @interface TagCreateRequest
 *
 */
// create custom validators for the regexes
typia.customValidators.insert("pattern_name")("string")(() => {
    const regex = new RegExp(/^[a-zA-Z0-9][a-zA-Z0-9 .:;=@_-]+$/);
    return (value: string) => !!regex.exec(value);
});
typia.customValidators.insert("pattern_prevTagId")("string")(() => {
    const regex = new RegExp(/^[a-f0-9]{24}$/);
    return (value: string) => !!regex.exec(value);
});
typia.customValidators.insert("pattern_queryTagId")("string")(() => {
    const regex = new RegExp(/^[a-f0-9]{24}$/);
    return (value: string) => !!regex.exec(value);
});
typia.customValidators.insert("pattern_preselectedTagId")("string")(() => {
    const regex = new RegExp(/^[a-f0-9]{24}$/);
    return (value: string) => !!regex.exec(value);
});
typia.customValidators.insert("pattern_bitMaskData")("string")(() => {
    const regex = new RegExp(/^0x[a-f0-9]+$/);
    return (value: string) => !!regex.exec(value);
});
typia.customValidators.insert("pattern_runId")("string")(() => {
    const regex = new RegExp(/^[a-f0-9]{24}$/);
    return (value: string) => !!regex.exec(value);
});
export interface TagCreateRequest {
    /**
     * The name of the tag
     * #type string
     * #memberof TagCreateRequest
        
        
    * @pattern_name
    * @minLength 3
         */
    name: string;
    /**
     * MongoDB ObjectId
     * #type string
     * #memberof TagCreateRequest
        
        
    * @pattern_prevTagId
         */
    prevTagId: string;
    /**
     * MongoDB ObjectId
     * #type string
     * #memberof TagCreateRequest
        
        
    * @pattern_queryTagId
         */
    queryTagId?: string;
    /**
     * MongoDB ObjectId
     * #type string
     * #memberof TagCreateRequest
        
        
    * @pattern_preselectedTagId
         */
    preselectedTagId?: string;
    /**
     * BitMask as a base16 (hex) string
     * #type string
     * #memberof TagCreateRequest
        
        
    * @pattern_bitMaskData
         */
    bitMaskData: string;
    /**
     *
     * #type number
     * #memberof TagCreateRequest
        * @type int
        
         */
    totSize: number;
    /**
     *
     * #type TagCreator
     * #memberof TagCreateRequest
        
        
         */
    creator?: TagCreator;
    /**
     *
     * #type TagChangeData
     * #memberof TagCreateRequest
        
        
         */
    changes?: TagChangeData;
    /**
     * MongoDB ObjectId
     * #type string
     * #memberof TagCreateRequest
        
        
    * @pattern_runId
         */
    runId?: string;
}
/**
 * Check if a given object implements the TagCreateRequest interface.
 */
export function instanceOfTagCreateRequest(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "prevTagId" in value;
    isInstance = isInstance && "bitMaskData" in value;
    isInstance = isInstance && "totSize" in value;
    return isInstance;
}
/* LIGHTLY CUSTOM START -> modelGeneric */
import { TagChangeDataValidator, TagChangeDataStringifyShape, TagChangeDataStringifyShapeProperties, } from './TagChangeData';
import { TagCreatorValidator, TagCreatorStringifyShape, TagCreatorStringifyShapeProperties, } from './TagCreator';
export const TagCreateRequestTypiaAssertEquals = (input: any) => {
    return ((input: any): TagCreateRequest => {
        const __is = (input: any, _exceptionable: boolean = true): input is TagCreateRequest => {
            const $is_custom = (typia.createAssertEquals as any).is_custom;
            const $join = (typia.createAssertEquals as any).join;
            const $io0 = (input: any, _exceptionable: boolean = true): boolean => "string" === typeof input.name && 3 <= input.name.length && $is_custom("pattern_name", "string", "", input.name) && ("string" === typeof input.prevTagId && $is_custom("pattern_prevTagId", "string", "", input.prevTagId)) && (undefined === input.queryTagId || "string" === typeof input.queryTagId && $is_custom("pattern_queryTagId", "string", "", input.queryTagId)) && (undefined === input.preselectedTagId || "string" === typeof input.preselectedTagId && $is_custom("pattern_preselectedTagId", "string", "", input.preselectedTagId)) && ("string" === typeof input.bitMaskData && $is_custom("pattern_bitMaskData", "string", "", input.bitMaskData)) && ("number" === typeof input.totSize && Math.floor(input.totSize) === input.totSize && (-2147483648 <= input.totSize && input.totSize <= 2147483647)) && (undefined === input.creator || "UNKNOWN" === input.creator || "USER_WEBAPP" === input.creator || "USER_PIP" === input.creator || "USER_PIP_LIGHTLY_MAGIC" === input.creator || "USER_WORKER" === input.creator || "SAMPLER_ACTIVE_LEARNING" === input.creator || "SAMPLER_CORAL" === input.creator || "SAMPLER_CORESET" === input.creator || "SAMPLER_RANDOM" === input.creator) && (undefined === input.changes || "object" === typeof input.changes && null !== input.changes && false === Array.isArray(input.changes) && $io1(input.changes, true && _exceptionable)) && (undefined === input.runId || "string" === typeof input.runId && $is_custom("pattern_runId", "string", "", input.runId)) && (4 === Object.keys(input).length || Object.keys(input).every((key: any) => {
                if (["name", "prevTagId", "queryTagId", "preselectedTagId", "bitMaskData", "totSize", "creator", "changes", "runId"].some((prop: any) => key === prop))
                    return true;
                const value = input[key];
                if (undefined === value)
                    return true;
                return false;
            }));
            const $io1 = (input: any, _exceptionable: boolean = true): boolean => (undefined === input.initial || "object" === typeof input.initial && null !== input.initial && false === Array.isArray(input.initial) && $io2(input.initial, true && _exceptionable)) && (undefined === input.rename || "object" === typeof input.rename && null !== input.rename && $io3(input.rename, true && _exceptionable)) && (undefined === input.upsize || "object" === typeof input.upsize && null !== input.upsize && $io4(input.upsize, true && _exceptionable)) && (undefined === input.arithmetics || "object" === typeof input.arithmetics && null !== input.arithmetics && $io5(input.arithmetics, true && _exceptionable)) && (undefined === input.metadata || "object" === typeof input.metadata && null !== input.metadata && $io6(input.metadata, true && _exceptionable)) && (undefined === input.samples || "object" === typeof input.samples && null !== input.samples && $io8(input.samples, true && _exceptionable)) && (undefined === input.scatterplot || "object" === typeof input.scatterplot && null !== input.scatterplot && $io9(input.scatterplot, true && _exceptionable)) && (undefined === input.sampler || "object" === typeof input.sampler && null !== input.sampler && $io10(input.sampler, true && _exceptionable)) && Object.keys(input).every((key: any) => {
                if (["initial", "rename", "upsize", "arithmetics", "metadata", "samples", "scatterplot", "sampler"].some((prop: any) => key === prop))
                    return true;
                const value = input[key];
                if (undefined === value)
                    return true;
                if (RegExp(/(.*)/).test(key))
                    return true;
                return false;
            });
            const $io2 = (input: any, _exceptionable: boolean = true): boolean => (undefined === input.runId || "string" === typeof input.runId && $is_custom("pattern_runId", "string", "", input.runId)) && (0 === Object.keys(input).length || Object.keys(input).every((key: any) => {
                if (["runId"].some((prop: any) => key === prop))
                    return true;
                const value = input[key];
                if (undefined === value)
                    return true;
                return false;
            }));
            const $io3 = (input: any, _exceptionable: boolean = true): boolean => "string" === typeof input.from && "string" === typeof input.to && (2 === Object.keys(input).length || Object.keys(input).every((key: any) => {
                if (["from", "to"].some((prop: any) => key === prop))
                    return true;
                const value = input[key];
                if (undefined === value)
                    return true;
                return false;
            }));
            const $io4 = (input: any, _exceptionable: boolean = true): boolean => (undefined === input.runId || "string" === typeof input.runId && $is_custom("pattern_runId", "string", "", input.runId)) && "number" === typeof input.from && "number" === typeof input.to && (2 === Object.keys(input).length || Object.keys(input).every((key: any) => {
                if (["runId", "from", "to"].some((prop: any) => key === prop))
                    return true;
                const value = input[key];
                if (undefined === value)
                    return true;
                return false;
            }));
            const $io5 = (input: any, _exceptionable: boolean = true): boolean => "string" === typeof input.operation && "string" === typeof input.tag1 && "string" === typeof input.tag2 && (3 === Object.keys(input).length || Object.keys(input).every((key: any) => {
                if (["operation", "tag1", "tag2"].some((prop: any) => key === prop))
                    return true;
                const value = input[key];
                if (undefined === value)
                    return true;
                return false;
            }));
            const $io6 = (input: any, _exceptionable: boolean = true): boolean => ("selected" === input.method || "added" === input.method || "removed" === input.method) && ("number" === typeof input.count && 0 <= input.count) && ("number" === typeof input.added && 0 <= input.added) && ("number" === typeof input.removed && 0 <= input.removed) && ("object" === typeof input.changes && null !== input.changes && false === Array.isArray(input.changes) && $io7(input.changes, true && _exceptionable)) && (5 === Object.keys(input).length || Object.keys(input).every((key: any) => {
                if (["method", "count", "added", "removed", "changes"].some((prop: any) => key === prop))
                    return true;
                const value = input[key];
                if (undefined === value)
                    return true;
                return false;
            }));
            const $io7 = (input: any, _exceptionable: boolean = true): boolean => Object.keys(input).every((key: any) => {
                const value = input[key];
                if (undefined === value)
                    return true;
                if (RegExp(/(.*)/).test(key))
                    return true;
                return false;
            });
            const $io8 = (input: any, _exceptionable: boolean = true): boolean => ("selected" === input.method || "added" === input.method || "removed" === input.method) && ("number" === typeof input.count && 0 <= input.count) && ("number" === typeof input.added && 0 <= input.added) && ("number" === typeof input.removed && 0 <= input.removed) && (4 === Object.keys(input).length || Object.keys(input).every((key: any) => {
                if (["method", "count", "added", "removed"].some((prop: any) => key === prop))
                    return true;
                const value = input[key];
                if (undefined === value)
                    return true;
                return false;
            }));
            const $io9 = (input: any, _exceptionable: boolean = true): boolean => ("selected" === input.method || "added" === input.method || "removed" === input.method) && (undefined === input.view || "string" === typeof input.view) && ("number" === typeof input.count && 0 <= input.count) && ("number" === typeof input.added && 0 <= input.added) && ("number" === typeof input.removed && 0 <= input.removed) && (4 === Object.keys(input).length || Object.keys(input).every((key: any) => {
                if (["method", "view", "count", "added", "removed"].some((prop: any) => key === prop))
                    return true;
                const value = input[key];
                if (undefined === value)
                    return true;
                return false;
            }));
            const $io10 = (input: any, _exceptionable: boolean = true): boolean => "string" === typeof input.method && (1 === Object.keys(input).length || Object.keys(input).every((key: any) => {
                if (["method"].some((prop: any) => key === prop))
                    return true;
                const value = input[key];
                if (undefined === value)
                    return true;
                return false;
            }));
            return "object" === typeof input && null !== input && $io0(input, true);
        };
        if (false === __is(input))
            ((input: any, _path: string, _exceptionable: boolean = true): input is TagCreateRequest => {
                const $guard = (typia.createAssertEquals as any).guard;
                const $is_custom = (typia.createAssertEquals as any).is_custom;
                const $join = (typia.createAssertEquals as any).join;
                const $ao0 = (input: any, _path: string, _exceptionable: boolean = true): boolean => ("string" === typeof input.name && (3 <= input.name.length || $guard(_exceptionable, {
                    path: _path + ".name",
                    expected: "string (@minLength 3)",
                    value: input.name
                })) && ($is_custom("pattern_name", "string", "", input.name) || $guard(_exceptionable, {
                    path: _path + ".name",
                    expected: "string (@pattern_name)",
                    value: input.name
                })) || $guard(_exceptionable, {
                    path: _path + ".name",
                    expected: "string",
                    value: input.name
                })) && ("string" === typeof input.prevTagId && ($is_custom("pattern_prevTagId", "string", "", input.prevTagId) || $guard(_exceptionable, {
                    path: _path + ".prevTagId",
                    expected: "string (@pattern_prevTagId)",
                    value: input.prevTagId
                })) || $guard(_exceptionable, {
                    path: _path + ".prevTagId",
                    expected: "string",
                    value: input.prevTagId
                })) && (undefined === input.queryTagId || "string" === typeof input.queryTagId && ($is_custom("pattern_queryTagId", "string", "", input.queryTagId) || $guard(_exceptionable, {
                    path: _path + ".queryTagId",
                    expected: "string (@pattern_queryTagId)",
                    value: input.queryTagId
                })) || $guard(_exceptionable, {
                    path: _path + ".queryTagId",
                    expected: "(string | undefined)",
                    value: input.queryTagId
                })) && (undefined === input.preselectedTagId || "string" === typeof input.preselectedTagId && ($is_custom("pattern_preselectedTagId", "string", "", input.preselectedTagId) || $guard(_exceptionable, {
                    path: _path + ".preselectedTagId",
                    expected: "string (@pattern_preselectedTagId)",
                    value: input.preselectedTagId
                })) || $guard(_exceptionable, {
                    path: _path + ".preselectedTagId",
                    expected: "(string | undefined)",
                    value: input.preselectedTagId
                })) && ("string" === typeof input.bitMaskData && ($is_custom("pattern_bitMaskData", "string", "", input.bitMaskData) || $guard(_exceptionable, {
                    path: _path + ".bitMaskData",
                    expected: "string (@pattern_bitMaskData)",
                    value: input.bitMaskData
                })) || $guard(_exceptionable, {
                    path: _path + ".bitMaskData",
                    expected: "string",
                    value: input.bitMaskData
                })) && ("number" === typeof input.totSize && (Math.floor(input.totSize) === input.totSize || $guard(_exceptionable, {
                    path: _path + ".totSize",
                    expected: "number (@type int)",
                    value: input.totSize
                })) && (-2147483648 <= input.totSize && input.totSize <= 2147483647 || $guard(_exceptionable, {
                    path: _path + ".totSize",
                    expected: "number (@type int)",
                    value: input.totSize
                })) || $guard(_exceptionable, {
                    path: _path + ".totSize",
                    expected: "number",
                    value: input.totSize
                })) && (undefined === input.creator || "UNKNOWN" === input.creator || "USER_WEBAPP" === input.creator || "USER_PIP" === input.creator || "USER_PIP_LIGHTLY_MAGIC" === input.creator || "USER_WORKER" === input.creator || "SAMPLER_ACTIVE_LEARNING" === input.creator || "SAMPLER_CORAL" === input.creator || "SAMPLER_CORESET" === input.creator || "SAMPLER_RANDOM" === input.creator || $guard(_exceptionable, {
                    path: _path + ".creator",
                    expected: "(\"SAMPLER_ACTIVE_LEARNING\" | \"SAMPLER_CORAL\" | \"SAMPLER_CORESET\" | \"SAMPLER_RANDOM\" | \"UNKNOWN\" | \"USER_PIP\" | \"USER_PIP_LIGHTLY_MAGIC\" | \"USER_WEBAPP\" | \"USER_WORKER\" | undefined)",
                    value: input.creator
                })) && (undefined === input.changes || ("object" === typeof input.changes && null !== input.changes && false === Array.isArray(input.changes) || $guard(_exceptionable, {
                    path: _path + ".changes",
                    expected: "(TagChangeData | undefined)",
                    value: input.changes
                })) && $ao1(input.changes, _path + ".changes", true && _exceptionable) || $guard(_exceptionable, {
                    path: _path + ".changes",
                    expected: "(TagChangeData | undefined)",
                    value: input.changes
                })) && (undefined === input.runId || "string" === typeof input.runId && ($is_custom("pattern_runId", "string", "", input.runId) || $guard(_exceptionable, {
                    path: _path + ".runId",
                    expected: "string (@pattern_runId)",
                    value: input.runId
                })) || $guard(_exceptionable, {
                    path: _path + ".runId",
                    expected: "(string | undefined)",
                    value: input.runId
                })) && (4 === Object.keys(input).length || (false === _exceptionable || Object.keys(input).every((key: any) => {
                    if (["name", "prevTagId", "queryTagId", "preselectedTagId", "bitMaskData", "totSize", "creator", "changes", "runId"].some((prop: any) => key === prop))
                        return true;
                    const value = input[key];
                    if (undefined === value)
                        return true;
                    return $guard(_exceptionable, {
                        path: _path + $join(key),
                        expected: "undefined",
                        value: value
                    });
                })));
                const $ao1 = (input: any, _path: string, _exceptionable: boolean = true): boolean => (undefined === input.initial || ("object" === typeof input.initial && null !== input.initial && false === Array.isArray(input.initial) || $guard(_exceptionable, {
                    path: _path + ".initial",
                    expected: "(TagChangeDataInitial | undefined)",
                    value: input.initial
                })) && $ao2(input.initial, _path + ".initial", true && _exceptionable) || $guard(_exceptionable, {
                    path: _path + ".initial",
                    expected: "(TagChangeDataInitial | undefined)",
                    value: input.initial
                })) && (undefined === input.rename || ("object" === typeof input.rename && null !== input.rename || $guard(_exceptionable, {
                    path: _path + ".rename",
                    expected: "(TagChangeDataRename | undefined)",
                    value: input.rename
                })) && $ao3(input.rename, _path + ".rename", true && _exceptionable) || $guard(_exceptionable, {
                    path: _path + ".rename",
                    expected: "(TagChangeDataRename | undefined)",
                    value: input.rename
                })) && (undefined === input.upsize || ("object" === typeof input.upsize && null !== input.upsize || $guard(_exceptionable, {
                    path: _path + ".upsize",
                    expected: "(TagChangeDataUpsize | undefined)",
                    value: input.upsize
                })) && $ao4(input.upsize, _path + ".upsize", true && _exceptionable) || $guard(_exceptionable, {
                    path: _path + ".upsize",
                    expected: "(TagChangeDataUpsize | undefined)",
                    value: input.upsize
                })) && (undefined === input.arithmetics || ("object" === typeof input.arithmetics && null !== input.arithmetics || $guard(_exceptionable, {
                    path: _path + ".arithmetics",
                    expected: "(TagChangeDataArithmetics | undefined)",
                    value: input.arithmetics
                })) && $ao5(input.arithmetics, _path + ".arithmetics", true && _exceptionable) || $guard(_exceptionable, {
                    path: _path + ".arithmetics",
                    expected: "(TagChangeDataArithmetics | undefined)",
                    value: input.arithmetics
                })) && (undefined === input.metadata || ("object" === typeof input.metadata && null !== input.metadata || $guard(_exceptionable, {
                    path: _path + ".metadata",
                    expected: "(TagChangeDataMetadata | undefined)",
                    value: input.metadata
                })) && $ao6(input.metadata, _path + ".metadata", true && _exceptionable) || $guard(_exceptionable, {
                    path: _path + ".metadata",
                    expected: "(TagChangeDataMetadata | undefined)",
                    value: input.metadata
                })) && (undefined === input.samples || ("object" === typeof input.samples && null !== input.samples || $guard(_exceptionable, {
                    path: _path + ".samples",
                    expected: "(TagChangeDataSamples | undefined)",
                    value: input.samples
                })) && $ao8(input.samples, _path + ".samples", true && _exceptionable) || $guard(_exceptionable, {
                    path: _path + ".samples",
                    expected: "(TagChangeDataSamples | undefined)",
                    value: input.samples
                })) && (undefined === input.scatterplot || ("object" === typeof input.scatterplot && null !== input.scatterplot || $guard(_exceptionable, {
                    path: _path + ".scatterplot",
                    expected: "(TagChangeDataScatterplot | undefined)",
                    value: input.scatterplot
                })) && $ao9(input.scatterplot, _path + ".scatterplot", true && _exceptionable) || $guard(_exceptionable, {
                    path: _path + ".scatterplot",
                    expected: "(TagChangeDataScatterplot | undefined)",
                    value: input.scatterplot
                })) && (undefined === input.sampler || ("object" === typeof input.sampler && null !== input.sampler || $guard(_exceptionable, {
                    path: _path + ".sampler",
                    expected: "(TagChangeDataSampler | undefined)",
                    value: input.sampler
                })) && $ao10(input.sampler, _path + ".sampler", true && _exceptionable) || $guard(_exceptionable, {
                    path: _path + ".sampler",
                    expected: "(TagChangeDataSampler | undefined)",
                    value: input.sampler
                })) && (false === _exceptionable || Object.keys(input).every((key: any) => {
                    if (["initial", "rename", "upsize", "arithmetics", "metadata", "samples", "scatterplot", "sampler"].some((prop: any) => key === prop))
                        return true;
                    const value = input[key];
                    if (undefined === value)
                        return true;
                    if (RegExp(/(.*)/).test(key))
                        return true;
                    return $guard(_exceptionable, {
                        path: _path + $join(key),
                        expected: "undefined",
                        value: value
                    });
                }));
                const $ao2 = (input: any, _path: string, _exceptionable: boolean = true): boolean => (undefined === input.runId || "string" === typeof input.runId && ($is_custom("pattern_runId", "string", "", input.runId) || $guard(_exceptionable, {
                    path: _path + ".runId",
                    expected: "string (@pattern_runId)",
                    value: input.runId
                })) || $guard(_exceptionable, {
                    path: _path + ".runId",
                    expected: "(string | undefined)",
                    value: input.runId
                })) && (0 === Object.keys(input).length || (false === _exceptionable || Object.keys(input).every((key: any) => {
                    if (["runId"].some((prop: any) => key === prop))
                        return true;
                    const value = input[key];
                    if (undefined === value)
                        return true;
                    return $guard(_exceptionable, {
                        path: _path + $join(key),
                        expected: "undefined",
                        value: value
                    });
                })));
                const $ao3 = (input: any, _path: string, _exceptionable: boolean = true): boolean => ("string" === typeof input.from || $guard(_exceptionable, {
                    path: _path + ".from",
                    expected: "string",
                    value: input.from
                })) && ("string" === typeof input.to || $guard(_exceptionable, {
                    path: _path + ".to",
                    expected: "string",
                    value: input.to
                })) && (2 === Object.keys(input).length || (false === _exceptionable || Object.keys(input).every((key: any) => {
                    if (["from", "to"].some((prop: any) => key === prop))
                        return true;
                    const value = input[key];
                    if (undefined === value)
                        return true;
                    return $guard(_exceptionable, {
                        path: _path + $join(key),
                        expected: "undefined",
                        value: value
                    });
                })));
                const $ao4 = (input: any, _path: string, _exceptionable: boolean = true): boolean => (undefined === input.runId || "string" === typeof input.runId && ($is_custom("pattern_runId", "string", "", input.runId) || $guard(_exceptionable, {
                    path: _path + ".runId",
                    expected: "string (@pattern_runId)",
                    value: input.runId
                })) || $guard(_exceptionable, {
                    path: _path + ".runId",
                    expected: "(string | undefined)",
                    value: input.runId
                })) && ("number" === typeof input.from || $guard(_exceptionable, {
                    path: _path + ".from",
                    expected: "number",
                    value: input.from
                })) && ("number" === typeof input.to || $guard(_exceptionable, {
                    path: _path + ".to",
                    expected: "number",
                    value: input.to
                })) && (2 === Object.keys(input).length || (false === _exceptionable || Object.keys(input).every((key: any) => {
                    if (["runId", "from", "to"].some((prop: any) => key === prop))
                        return true;
                    const value = input[key];
                    if (undefined === value)
                        return true;
                    return $guard(_exceptionable, {
                        path: _path + $join(key),
                        expected: "undefined",
                        value: value
                    });
                })));
                const $ao5 = (input: any, _path: string, _exceptionable: boolean = true): boolean => ("string" === typeof input.operation || $guard(_exceptionable, {
                    path: _path + ".operation",
                    expected: "string",
                    value: input.operation
                })) && ("string" === typeof input.tag1 || $guard(_exceptionable, {
                    path: _path + ".tag1",
                    expected: "string",
                    value: input.tag1
                })) && ("string" === typeof input.tag2 || $guard(_exceptionable, {
                    path: _path + ".tag2",
                    expected: "string",
                    value: input.tag2
                })) && (3 === Object.keys(input).length || (false === _exceptionable || Object.keys(input).every((key: any) => {
                    if (["operation", "tag1", "tag2"].some((prop: any) => key === prop))
                        return true;
                    const value = input[key];
                    if (undefined === value)
                        return true;
                    return $guard(_exceptionable, {
                        path: _path + $join(key),
                        expected: "undefined",
                        value: value
                    });
                })));
                const $ao6 = (input: any, _path: string, _exceptionable: boolean = true): boolean => ("selected" === input.method || "added" === input.method || "removed" === input.method || $guard(_exceptionable, {
                    path: _path + ".method",
                    expected: "(\"added\" | \"removed\" | \"selected\")",
                    value: input.method
                })) && ("number" === typeof input.count && (0 <= input.count || $guard(_exceptionable, {
                    path: _path + ".count",
                    expected: "number (@minimum 0)",
                    value: input.count
                })) || $guard(_exceptionable, {
                    path: _path + ".count",
                    expected: "number",
                    value: input.count
                })) && ("number" === typeof input.added && (0 <= input.added || $guard(_exceptionable, {
                    path: _path + ".added",
                    expected: "number (@minimum 0)",
                    value: input.added
                })) || $guard(_exceptionable, {
                    path: _path + ".added",
                    expected: "number",
                    value: input.added
                })) && ("number" === typeof input.removed && (0 <= input.removed || $guard(_exceptionable, {
                    path: _path + ".removed",
                    expected: "number (@minimum 0)",
                    value: input.removed
                })) || $guard(_exceptionable, {
                    path: _path + ".removed",
                    expected: "number",
                    value: input.removed
                })) && (("object" === typeof input.changes && null !== input.changes && false === Array.isArray(input.changes) || $guard(_exceptionable, {
                    path: _path + ".changes",
                    expected: "__type",
                    value: input.changes
                })) && $ao7(input.changes, _path + ".changes", true && _exceptionable) || $guard(_exceptionable, {
                    path: _path + ".changes",
                    expected: "__type",
                    value: input.changes
                })) && (5 === Object.keys(input).length || (false === _exceptionable || Object.keys(input).every((key: any) => {
                    if (["method", "count", "added", "removed", "changes"].some((prop: any) => key === prop))
                        return true;
                    const value = input[key];
                    if (undefined === value)
                        return true;
                    return $guard(_exceptionable, {
                        path: _path + $join(key),
                        expected: "undefined",
                        value: value
                    });
                })));
                const $ao7 = (input: any, _path: string, _exceptionable: boolean = true): boolean => false === _exceptionable || Object.keys(input).every((key: any) => {
                    const value = input[key];
                    if (undefined === value)
                        return true;
                    if (RegExp(/(.*)/).test(key))
                        return true;
                    return $guard(_exceptionable, {
                        path: _path + $join(key),
                        expected: "undefined",
                        value: value
                    });
                });
                const $ao8 = (input: any, _path: string, _exceptionable: boolean = true): boolean => ("selected" === input.method || "added" === input.method || "removed" === input.method || $guard(_exceptionable, {
                    path: _path + ".method",
                    expected: "(\"added\" | \"removed\" | \"selected\")",
                    value: input.method
                })) && ("number" === typeof input.count && (0 <= input.count || $guard(_exceptionable, {
                    path: _path + ".count",
                    expected: "number (@minimum 0)",
                    value: input.count
                })) || $guard(_exceptionable, {
                    path: _path + ".count",
                    expected: "number",
                    value: input.count
                })) && ("number" === typeof input.added && (0 <= input.added || $guard(_exceptionable, {
                    path: _path + ".added",
                    expected: "number (@minimum 0)",
                    value: input.added
                })) || $guard(_exceptionable, {
                    path: _path + ".added",
                    expected: "number",
                    value: input.added
                })) && ("number" === typeof input.removed && (0 <= input.removed || $guard(_exceptionable, {
                    path: _path + ".removed",
                    expected: "number (@minimum 0)",
                    value: input.removed
                })) || $guard(_exceptionable, {
                    path: _path + ".removed",
                    expected: "number",
                    value: input.removed
                })) && (4 === Object.keys(input).length || (false === _exceptionable || Object.keys(input).every((key: any) => {
                    if (["method", "count", "added", "removed"].some((prop: any) => key === prop))
                        return true;
                    const value = input[key];
                    if (undefined === value)
                        return true;
                    return $guard(_exceptionable, {
                        path: _path + $join(key),
                        expected: "undefined",
                        value: value
                    });
                })));
                const $ao9 = (input: any, _path: string, _exceptionable: boolean = true): boolean => ("selected" === input.method || "added" === input.method || "removed" === input.method || $guard(_exceptionable, {
                    path: _path + ".method",
                    expected: "(\"added\" | \"removed\" | \"selected\")",
                    value: input.method
                })) && (undefined === input.view || "string" === typeof input.view || $guard(_exceptionable, {
                    path: _path + ".view",
                    expected: "(string | undefined)",
                    value: input.view
                })) && ("number" === typeof input.count && (0 <= input.count || $guard(_exceptionable, {
                    path: _path + ".count",
                    expected: "number (@minimum 0)",
                    value: input.count
                })) || $guard(_exceptionable, {
                    path: _path + ".count",
                    expected: "number",
                    value: input.count
                })) && ("number" === typeof input.added && (0 <= input.added || $guard(_exceptionable, {
                    path: _path + ".added",
                    expected: "number (@minimum 0)",
                    value: input.added
                })) || $guard(_exceptionable, {
                    path: _path + ".added",
                    expected: "number",
                    value: input.added
                })) && ("number" === typeof input.removed && (0 <= input.removed || $guard(_exceptionable, {
                    path: _path + ".removed",
                    expected: "number (@minimum 0)",
                    value: input.removed
                })) || $guard(_exceptionable, {
                    path: _path + ".removed",
                    expected: "number",
                    value: input.removed
                })) && (4 === Object.keys(input).length || (false === _exceptionable || Object.keys(input).every((key: any) => {
                    if (["method", "view", "count", "added", "removed"].some((prop: any) => key === prop))
                        return true;
                    const value = input[key];
                    if (undefined === value)
                        return true;
                    return $guard(_exceptionable, {
                        path: _path + $join(key),
                        expected: "undefined",
                        value: value
                    });
                })));
                const $ao10 = (input: any, _path: string, _exceptionable: boolean = true): boolean => ("string" === typeof input.method || $guard(_exceptionable, {
                    path: _path + ".method",
                    expected: "string",
                    value: input.method
                })) && (1 === Object.keys(input).length || (false === _exceptionable || Object.keys(input).every((key: any) => {
                    if (["method"].some((prop: any) => key === prop))
                        return true;
                    const value = input[key];
                    if (undefined === value)
                        return true;
                    return $guard(_exceptionable, {
                        path: _path + $join(key),
                        expected: "undefined",
                        value: value
                    });
                })));
                return ("object" === typeof input && null !== input || $guard(true, {
                    path: _path + "",
                    expected: "TagCreateRequest",
                    value: input
                })) && $ao0(input, _path + "", true) || $guard(true, {
                    path: _path + "",
                    expected: "TagCreateRequest",
                    value: input
                });
            })(input, "$input", true);
        return input;
    })(input);
};
export const TagCreateRequestTypiaAssertStringify = (input: TagCreateRequest): string => {
    return ((input: any): string => { const assert = (input: any): TagCreateRequest => {
        const __is = (input: any): input is TagCreateRequest => {
            const $is_custom = (typia.createAssertStringify as any).is_custom;
            const $join = (typia.createAssertStringify as any).join;
            const $io0 = (input: any): boolean => "string" === typeof input.name && 3 <= input.name.length && $is_custom("pattern_name", "string", "", input.name) && ("string" === typeof input.prevTagId && $is_custom("pattern_prevTagId", "string", "", input.prevTagId)) && (undefined === input.queryTagId || "string" === typeof input.queryTagId && $is_custom("pattern_queryTagId", "string", "", input.queryTagId)) && (undefined === input.preselectedTagId || "string" === typeof input.preselectedTagId && $is_custom("pattern_preselectedTagId", "string", "", input.preselectedTagId)) && ("string" === typeof input.bitMaskData && $is_custom("pattern_bitMaskData", "string", "", input.bitMaskData)) && ("number" === typeof input.totSize && !Number.isNaN(input.totSize) && Math.floor(input.totSize) === input.totSize && (-2147483648 <= input.totSize && input.totSize <= 2147483647)) && (undefined === input.creator || "UNKNOWN" === input.creator || "USER_WEBAPP" === input.creator || "USER_PIP" === input.creator || "USER_PIP_LIGHTLY_MAGIC" === input.creator || "USER_WORKER" === input.creator || "SAMPLER_ACTIVE_LEARNING" === input.creator || "SAMPLER_CORAL" === input.creator || "SAMPLER_CORESET" === input.creator || "SAMPLER_RANDOM" === input.creator) && (undefined === input.changes || "object" === typeof input.changes && null !== input.changes && false === Array.isArray(input.changes) && $io1(input.changes)) && (undefined === input.runId || "string" === typeof input.runId && $is_custom("pattern_runId", "string", "", input.runId));
            const $io1 = (input: any): boolean => (undefined === input.initial || "object" === typeof input.initial && null !== input.initial && false === Array.isArray(input.initial) && $io2(input.initial)) && (undefined === input.rename || "object" === typeof input.rename && null !== input.rename && $io3(input.rename)) && (undefined === input.upsize || "object" === typeof input.upsize && null !== input.upsize && $io4(input.upsize)) && (undefined === input.arithmetics || "object" === typeof input.arithmetics && null !== input.arithmetics && $io5(input.arithmetics)) && (undefined === input.metadata || "object" === typeof input.metadata && null !== input.metadata && $io6(input.metadata)) && (undefined === input.samples || "object" === typeof input.samples && null !== input.samples && $io8(input.samples)) && (undefined === input.scatterplot || "object" === typeof input.scatterplot && null !== input.scatterplot && $io9(input.scatterplot)) && (undefined === input.sampler || "object" === typeof input.sampler && null !== input.sampler && $io10(input.sampler)) && Object.keys(input).every((key: any) => {
                if (["initial", "rename", "upsize", "arithmetics", "metadata", "samples", "scatterplot", "sampler"].some((prop: any) => key === prop))
                    return true;
                const value = input[key];
                if (undefined === value)
                    return true;
                if (RegExp(/(.*)/).test(key))
                    return true;
                return true;
            });
            const $io2 = (input: any): boolean => undefined === input.runId || "string" === typeof input.runId && $is_custom("pattern_runId", "string", "", input.runId);
            const $io3 = (input: any): boolean => "string" === typeof input.from && "string" === typeof input.to;
            const $io4 = (input: any): boolean => (undefined === input.runId || "string" === typeof input.runId && $is_custom("pattern_runId", "string", "", input.runId)) && ("number" === typeof input.from && !Number.isNaN(input.from)) && ("number" === typeof input.to && !Number.isNaN(input.to));
            const $io5 = (input: any): boolean => "string" === typeof input.operation && "string" === typeof input.tag1 && "string" === typeof input.tag2;
            const $io6 = (input: any): boolean => ("selected" === input.method || "added" === input.method || "removed" === input.method) && ("number" === typeof input.count && !Number.isNaN(input.count) && 0 <= input.count) && ("number" === typeof input.added && !Number.isNaN(input.added) && 0 <= input.added) && ("number" === typeof input.removed && !Number.isNaN(input.removed) && 0 <= input.removed) && ("object" === typeof input.changes && null !== input.changes && false === Array.isArray(input.changes) && $io7(input.changes));
            const $io7 = (input: any): boolean => Object.keys(input).every((key: any) => {
                const value = input[key];
                if (undefined === value)
                    return true;
                if (RegExp(/(.*)/).test(key))
                    return true;
                return true;
            });
            const $io8 = (input: any): boolean => ("selected" === input.method || "added" === input.method || "removed" === input.method) && ("number" === typeof input.count && !Number.isNaN(input.count) && 0 <= input.count) && ("number" === typeof input.added && !Number.isNaN(input.added) && 0 <= input.added) && ("number" === typeof input.removed && !Number.isNaN(input.removed) && 0 <= input.removed);
            const $io9 = (input: any): boolean => ("selected" === input.method || "added" === input.method || "removed" === input.method) && (undefined === input.view || "string" === typeof input.view) && ("number" === typeof input.count && !Number.isNaN(input.count) && 0 <= input.count) && ("number" === typeof input.added && !Number.isNaN(input.added) && 0 <= input.added) && ("number" === typeof input.removed && !Number.isNaN(input.removed) && 0 <= input.removed);
            const $io10 = (input: any): boolean => "string" === typeof input.method;
            return "object" === typeof input && null !== input && $io0(input);
        };
        if (false === __is(input))
            ((input: any, _path: string, _exceptionable: boolean = true): input is TagCreateRequest => {
                const $guard = (typia.createAssertStringify as any).guard;
                const $is_custom = (typia.createAssertStringify as any).is_custom;
                const $join = (typia.createAssertStringify as any).join;
                const $ao0 = (input: any, _path: string, _exceptionable: boolean = true): boolean => ("string" === typeof input.name && (3 <= input.name.length || $guard(_exceptionable, {
                    path: _path + ".name",
                    expected: "string (@minLength 3)",
                    value: input.name
                })) && ($is_custom("pattern_name", "string", "", input.name) || $guard(_exceptionable, {
                    path: _path + ".name",
                    expected: "string (@pattern_name)",
                    value: input.name
                })) || $guard(_exceptionable, {
                    path: _path + ".name",
                    expected: "string",
                    value: input.name
                })) && ("string" === typeof input.prevTagId && ($is_custom("pattern_prevTagId", "string", "", input.prevTagId) || $guard(_exceptionable, {
                    path: _path + ".prevTagId",
                    expected: "string (@pattern_prevTagId)",
                    value: input.prevTagId
                })) || $guard(_exceptionable, {
                    path: _path + ".prevTagId",
                    expected: "string",
                    value: input.prevTagId
                })) && (undefined === input.queryTagId || "string" === typeof input.queryTagId && ($is_custom("pattern_queryTagId", "string", "", input.queryTagId) || $guard(_exceptionable, {
                    path: _path + ".queryTagId",
                    expected: "string (@pattern_queryTagId)",
                    value: input.queryTagId
                })) || $guard(_exceptionable, {
                    path: _path + ".queryTagId",
                    expected: "(string | undefined)",
                    value: input.queryTagId
                })) && (undefined === input.preselectedTagId || "string" === typeof input.preselectedTagId && ($is_custom("pattern_preselectedTagId", "string", "", input.preselectedTagId) || $guard(_exceptionable, {
                    path: _path + ".preselectedTagId",
                    expected: "string (@pattern_preselectedTagId)",
                    value: input.preselectedTagId
                })) || $guard(_exceptionable, {
                    path: _path + ".preselectedTagId",
                    expected: "(string | undefined)",
                    value: input.preselectedTagId
                })) && ("string" === typeof input.bitMaskData && ($is_custom("pattern_bitMaskData", "string", "", input.bitMaskData) || $guard(_exceptionable, {
                    path: _path + ".bitMaskData",
                    expected: "string (@pattern_bitMaskData)",
                    value: input.bitMaskData
                })) || $guard(_exceptionable, {
                    path: _path + ".bitMaskData",
                    expected: "string",
                    value: input.bitMaskData
                })) && ("number" === typeof input.totSize && !Number.isNaN(input.totSize) && (Math.floor(input.totSize) === input.totSize || $guard(_exceptionable, {
                    path: _path + ".totSize",
                    expected: "number (@type int)",
                    value: input.totSize
                })) && (-2147483648 <= input.totSize && input.totSize <= 2147483647 || $guard(_exceptionable, {
                    path: _path + ".totSize",
                    expected: "number (@type int)",
                    value: input.totSize
                })) || $guard(_exceptionable, {
                    path: _path + ".totSize",
                    expected: "number",
                    value: input.totSize
                })) && (undefined === input.creator || "UNKNOWN" === input.creator || "USER_WEBAPP" === input.creator || "USER_PIP" === input.creator || "USER_PIP_LIGHTLY_MAGIC" === input.creator || "USER_WORKER" === input.creator || "SAMPLER_ACTIVE_LEARNING" === input.creator || "SAMPLER_CORAL" === input.creator || "SAMPLER_CORESET" === input.creator || "SAMPLER_RANDOM" === input.creator || $guard(_exceptionable, {
                    path: _path + ".creator",
                    expected: "(\"SAMPLER_ACTIVE_LEARNING\" | \"SAMPLER_CORAL\" | \"SAMPLER_CORESET\" | \"SAMPLER_RANDOM\" | \"UNKNOWN\" | \"USER_PIP\" | \"USER_PIP_LIGHTLY_MAGIC\" | \"USER_WEBAPP\" | \"USER_WORKER\" | undefined)",
                    value: input.creator
                })) && (undefined === input.changes || ("object" === typeof input.changes && null !== input.changes && false === Array.isArray(input.changes) || $guard(_exceptionable, {
                    path: _path + ".changes",
                    expected: "(TagChangeData | undefined)",
                    value: input.changes
                })) && $ao1(input.changes, _path + ".changes", true && _exceptionable) || $guard(_exceptionable, {
                    path: _path + ".changes",
                    expected: "(TagChangeData | undefined)",
                    value: input.changes
                })) && (undefined === input.runId || "string" === typeof input.runId && ($is_custom("pattern_runId", "string", "", input.runId) || $guard(_exceptionable, {
                    path: _path + ".runId",
                    expected: "string (@pattern_runId)",
                    value: input.runId
                })) || $guard(_exceptionable, {
                    path: _path + ".runId",
                    expected: "(string | undefined)",
                    value: input.runId
                }));
                const $ao1 = (input: any, _path: string, _exceptionable: boolean = true): boolean => (undefined === input.initial || ("object" === typeof input.initial && null !== input.initial && false === Array.isArray(input.initial) || $guard(_exceptionable, {
                    path: _path + ".initial",
                    expected: "(TagChangeDataInitial | undefined)",
                    value: input.initial
                })) && $ao2(input.initial, _path + ".initial", true && _exceptionable) || $guard(_exceptionable, {
                    path: _path + ".initial",
                    expected: "(TagChangeDataInitial | undefined)",
                    value: input.initial
                })) && (undefined === input.rename || ("object" === typeof input.rename && null !== input.rename || $guard(_exceptionable, {
                    path: _path + ".rename",
                    expected: "(TagChangeDataRename | undefined)",
                    value: input.rename
                })) && $ao3(input.rename, _path + ".rename", true && _exceptionable) || $guard(_exceptionable, {
                    path: _path + ".rename",
                    expected: "(TagChangeDataRename | undefined)",
                    value: input.rename
                })) && (undefined === input.upsize || ("object" === typeof input.upsize && null !== input.upsize || $guard(_exceptionable, {
                    path: _path + ".upsize",
                    expected: "(TagChangeDataUpsize | undefined)",
                    value: input.upsize
                })) && $ao4(input.upsize, _path + ".upsize", true && _exceptionable) || $guard(_exceptionable, {
                    path: _path + ".upsize",
                    expected: "(TagChangeDataUpsize | undefined)",
                    value: input.upsize
                })) && (undefined === input.arithmetics || ("object" === typeof input.arithmetics && null !== input.arithmetics || $guard(_exceptionable, {
                    path: _path + ".arithmetics",
                    expected: "(TagChangeDataArithmetics | undefined)",
                    value: input.arithmetics
                })) && $ao5(input.arithmetics, _path + ".arithmetics", true && _exceptionable) || $guard(_exceptionable, {
                    path: _path + ".arithmetics",
                    expected: "(TagChangeDataArithmetics | undefined)",
                    value: input.arithmetics
                })) && (undefined === input.metadata || ("object" === typeof input.metadata && null !== input.metadata || $guard(_exceptionable, {
                    path: _path + ".metadata",
                    expected: "(TagChangeDataMetadata | undefined)",
                    value: input.metadata
                })) && $ao6(input.metadata, _path + ".metadata", true && _exceptionable) || $guard(_exceptionable, {
                    path: _path + ".metadata",
                    expected: "(TagChangeDataMetadata | undefined)",
                    value: input.metadata
                })) && (undefined === input.samples || ("object" === typeof input.samples && null !== input.samples || $guard(_exceptionable, {
                    path: _path + ".samples",
                    expected: "(TagChangeDataSamples | undefined)",
                    value: input.samples
                })) && $ao8(input.samples, _path + ".samples", true && _exceptionable) || $guard(_exceptionable, {
                    path: _path + ".samples",
                    expected: "(TagChangeDataSamples | undefined)",
                    value: input.samples
                })) && (undefined === input.scatterplot || ("object" === typeof input.scatterplot && null !== input.scatterplot || $guard(_exceptionable, {
                    path: _path + ".scatterplot",
                    expected: "(TagChangeDataScatterplot | undefined)",
                    value: input.scatterplot
                })) && $ao9(input.scatterplot, _path + ".scatterplot", true && _exceptionable) || $guard(_exceptionable, {
                    path: _path + ".scatterplot",
                    expected: "(TagChangeDataScatterplot | undefined)",
                    value: input.scatterplot
                })) && (undefined === input.sampler || ("object" === typeof input.sampler && null !== input.sampler || $guard(_exceptionable, {
                    path: _path + ".sampler",
                    expected: "(TagChangeDataSampler | undefined)",
                    value: input.sampler
                })) && $ao10(input.sampler, _path + ".sampler", true && _exceptionable) || $guard(_exceptionable, {
                    path: _path + ".sampler",
                    expected: "(TagChangeDataSampler | undefined)",
                    value: input.sampler
                })) && (false === _exceptionable || Object.keys(input).every((key: any) => {
                    if (["initial", "rename", "upsize", "arithmetics", "metadata", "samples", "scatterplot", "sampler"].some((prop: any) => key === prop))
                        return true;
                    const value = input[key];
                    if (undefined === value)
                        return true;
                    if (RegExp(/(.*)/).test(key))
                        return true;
                    return true;
                }));
                const $ao2 = (input: any, _path: string, _exceptionable: boolean = true): boolean => undefined === input.runId || "string" === typeof input.runId && ($is_custom("pattern_runId", "string", "", input.runId) || $guard(_exceptionable, {
                    path: _path + ".runId",
                    expected: "string (@pattern_runId)",
                    value: input.runId
                })) || $guard(_exceptionable, {
                    path: _path + ".runId",
                    expected: "(string | undefined)",
                    value: input.runId
                });
                const $ao3 = (input: any, _path: string, _exceptionable: boolean = true): boolean => ("string" === typeof input.from || $guard(_exceptionable, {
                    path: _path + ".from",
                    expected: "string",
                    value: input.from
                })) && ("string" === typeof input.to || $guard(_exceptionable, {
                    path: _path + ".to",
                    expected: "string",
                    value: input.to
                }));
                const $ao4 = (input: any, _path: string, _exceptionable: boolean = true): boolean => (undefined === input.runId || "string" === typeof input.runId && ($is_custom("pattern_runId", "string", "", input.runId) || $guard(_exceptionable, {
                    path: _path + ".runId",
                    expected: "string (@pattern_runId)",
                    value: input.runId
                })) || $guard(_exceptionable, {
                    path: _path + ".runId",
                    expected: "(string | undefined)",
                    value: input.runId
                })) && ("number" === typeof input.from && !Number.isNaN(input.from) || $guard(_exceptionable, {
                    path: _path + ".from",
                    expected: "number",
                    value: input.from
                })) && ("number" === typeof input.to && !Number.isNaN(input.to) || $guard(_exceptionable, {
                    path: _path + ".to",
                    expected: "number",
                    value: input.to
                }));
                const $ao5 = (input: any, _path: string, _exceptionable: boolean = true): boolean => ("string" === typeof input.operation || $guard(_exceptionable, {
                    path: _path + ".operation",
                    expected: "string",
                    value: input.operation
                })) && ("string" === typeof input.tag1 || $guard(_exceptionable, {
                    path: _path + ".tag1",
                    expected: "string",
                    value: input.tag1
                })) && ("string" === typeof input.tag2 || $guard(_exceptionable, {
                    path: _path + ".tag2",
                    expected: "string",
                    value: input.tag2
                }));
                const $ao6 = (input: any, _path: string, _exceptionable: boolean = true): boolean => ("selected" === input.method || "added" === input.method || "removed" === input.method || $guard(_exceptionable, {
                    path: _path + ".method",
                    expected: "(\"added\" | \"removed\" | \"selected\")",
                    value: input.method
                })) && ("number" === typeof input.count && !Number.isNaN(input.count) && (0 <= input.count || $guard(_exceptionable, {
                    path: _path + ".count",
                    expected: "number (@minimum 0)",
                    value: input.count
                })) || $guard(_exceptionable, {
                    path: _path + ".count",
                    expected: "number",
                    value: input.count
                })) && ("number" === typeof input.added && !Number.isNaN(input.added) && (0 <= input.added || $guard(_exceptionable, {
                    path: _path + ".added",
                    expected: "number (@minimum 0)",
                    value: input.added
                })) || $guard(_exceptionable, {
                    path: _path + ".added",
                    expected: "number",
                    value: input.added
                })) && ("number" === typeof input.removed && !Number.isNaN(input.removed) && (0 <= input.removed || $guard(_exceptionable, {
                    path: _path + ".removed",
                    expected: "number (@minimum 0)",
                    value: input.removed
                })) || $guard(_exceptionable, {
                    path: _path + ".removed",
                    expected: "number",
                    value: input.removed
                })) && (("object" === typeof input.changes && null !== input.changes && false === Array.isArray(input.changes) || $guard(_exceptionable, {
                    path: _path + ".changes",
                    expected: "__type",
                    value: input.changes
                })) && $ao7(input.changes, _path + ".changes", true && _exceptionable) || $guard(_exceptionable, {
                    path: _path + ".changes",
                    expected: "__type",
                    value: input.changes
                }));
                const $ao7 = (input: any, _path: string, _exceptionable: boolean = true): boolean => false === _exceptionable || Object.keys(input).every((key: any) => {
                    const value = input[key];
                    if (undefined === value)
                        return true;
                    if (RegExp(/(.*)/).test(key))
                        return true;
                    return true;
                });
                const $ao8 = (input: any, _path: string, _exceptionable: boolean = true): boolean => ("selected" === input.method || "added" === input.method || "removed" === input.method || $guard(_exceptionable, {
                    path: _path + ".method",
                    expected: "(\"added\" | \"removed\" | \"selected\")",
                    value: input.method
                })) && ("number" === typeof input.count && !Number.isNaN(input.count) && (0 <= input.count || $guard(_exceptionable, {
                    path: _path + ".count",
                    expected: "number (@minimum 0)",
                    value: input.count
                })) || $guard(_exceptionable, {
                    path: _path + ".count",
                    expected: "number",
                    value: input.count
                })) && ("number" === typeof input.added && !Number.isNaN(input.added) && (0 <= input.added || $guard(_exceptionable, {
                    path: _path + ".added",
                    expected: "number (@minimum 0)",
                    value: input.added
                })) || $guard(_exceptionable, {
                    path: _path + ".added",
                    expected: "number",
                    value: input.added
                })) && ("number" === typeof input.removed && !Number.isNaN(input.removed) && (0 <= input.removed || $guard(_exceptionable, {
                    path: _path + ".removed",
                    expected: "number (@minimum 0)",
                    value: input.removed
                })) || $guard(_exceptionable, {
                    path: _path + ".removed",
                    expected: "number",
                    value: input.removed
                }));
                const $ao9 = (input: any, _path: string, _exceptionable: boolean = true): boolean => ("selected" === input.method || "added" === input.method || "removed" === input.method || $guard(_exceptionable, {
                    path: _path + ".method",
                    expected: "(\"added\" | \"removed\" | \"selected\")",
                    value: input.method
                })) && (undefined === input.view || "string" === typeof input.view || $guard(_exceptionable, {
                    path: _path + ".view",
                    expected: "(string | undefined)",
                    value: input.view
                })) && ("number" === typeof input.count && !Number.isNaN(input.count) && (0 <= input.count || $guard(_exceptionable, {
                    path: _path + ".count",
                    expected: "number (@minimum 0)",
                    value: input.count
                })) || $guard(_exceptionable, {
                    path: _path + ".count",
                    expected: "number",
                    value: input.count
                })) && ("number" === typeof input.added && !Number.isNaN(input.added) && (0 <= input.added || $guard(_exceptionable, {
                    path: _path + ".added",
                    expected: "number (@minimum 0)",
                    value: input.added
                })) || $guard(_exceptionable, {
                    path: _path + ".added",
                    expected: "number",
                    value: input.added
                })) && ("number" === typeof input.removed && !Number.isNaN(input.removed) && (0 <= input.removed || $guard(_exceptionable, {
                    path: _path + ".removed",
                    expected: "number (@minimum 0)",
                    value: input.removed
                })) || $guard(_exceptionable, {
                    path: _path + ".removed",
                    expected: "number",
                    value: input.removed
                }));
                const $ao10 = (input: any, _path: string, _exceptionable: boolean = true): boolean => "string" === typeof input.method || $guard(_exceptionable, {
                    path: _path + ".method",
                    expected: "string",
                    value: input.method
                });
                return ("object" === typeof input && null !== input || $guard(true, {
                    path: _path + "",
                    expected: "TagCreateRequest",
                    value: input
                })) && $ao0(input, _path + "", true) || $guard(true, {
                    path: _path + "",
                    expected: "TagCreateRequest",
                    value: input
                });
            })(input, "$input", true);
        return input;
    }; const stringify = (input: TagCreateRequest): string => {
        const $io1 = (input: any): boolean => (undefined === input.initial || "object" === typeof input.initial && null !== input.initial && false === Array.isArray(input.initial) && $io2(input.initial)) && (undefined === input.rename || "object" === typeof input.rename && null !== input.rename && $io3(input.rename)) && (undefined === input.upsize || "object" === typeof input.upsize && null !== input.upsize && $io4(input.upsize)) && (undefined === input.arithmetics || "object" === typeof input.arithmetics && null !== input.arithmetics && $io5(input.arithmetics)) && (undefined === input.metadata || "object" === typeof input.metadata && null !== input.metadata && $io6(input.metadata)) && (undefined === input.samples || "object" === typeof input.samples && null !== input.samples && $io8(input.samples)) && (undefined === input.scatterplot || "object" === typeof input.scatterplot && null !== input.scatterplot && $io9(input.scatterplot)) && (undefined === input.sampler || "object" === typeof input.sampler && null !== input.sampler && $io10(input.sampler)) && Object.keys(input).every((key: any) => {
            if (["initial", "rename", "upsize", "arithmetics", "metadata", "samples", "scatterplot", "sampler"].some((prop: any) => key === prop))
                return true;
            const value = input[key];
            if (undefined === value)
                return true;
            if (RegExp(/(.*)/).test(key))
                return true;
            return true;
        });
        const $io2 = (input: any): boolean => undefined === input.runId || "string" === typeof input.runId && $is_custom("pattern_runId", "string", "", input.runId);
        const $io3 = (input: any): boolean => "string" === typeof input.from && "string" === typeof input.to;
        const $io4 = (input: any): boolean => (undefined === input.runId || "string" === typeof input.runId && $is_custom("pattern_runId", "string", "", input.runId)) && "number" === typeof input.from && "number" === typeof input.to;
        const $io5 = (input: any): boolean => "string" === typeof input.operation && "string" === typeof input.tag1 && "string" === typeof input.tag2;
        const $io6 = (input: any): boolean => ("selected" === input.method || "added" === input.method || "removed" === input.method) && ("number" === typeof input.count && 0 <= input.count) && ("number" === typeof input.added && 0 <= input.added) && ("number" === typeof input.removed && 0 <= input.removed) && ("object" === typeof input.changes && null !== input.changes && false === Array.isArray(input.changes) && $io7(input.changes));
        const $io7 = (input: any): boolean => Object.keys(input).every((key: any) => {
            const value = input[key];
            if (undefined === value)
                return true;
            if (RegExp(/(.*)/).test(key))
                return true;
            return true;
        });
        const $io8 = (input: any): boolean => ("selected" === input.method || "added" === input.method || "removed" === input.method) && ("number" === typeof input.count && 0 <= input.count) && ("number" === typeof input.added && 0 <= input.added) && ("number" === typeof input.removed && 0 <= input.removed);
        const $io9 = (input: any): boolean => ("selected" === input.method || "added" === input.method || "removed" === input.method) && (undefined === input.view || "string" === typeof input.view) && ("number" === typeof input.count && 0 <= input.count) && ("number" === typeof input.added && 0 <= input.added) && ("number" === typeof input.removed && 0 <= input.removed);
        const $io10 = (input: any): boolean => "string" === typeof input.method;
        const $string = (typia.createAssertStringify as any).string;
        const $throws = (typia.createAssertStringify as any).throws;
        const $join = (typia.createAssertStringify as any).join;
        const $tail = (typia.createAssertStringify as any).tail;
        const $is_custom = (typia.createAssertStringify as any).is_custom;
        const $so0 = (input: any): any => `{${undefined === input.queryTagId ? "" : `"queryTagId":${undefined !== input.queryTagId ? $string(input.queryTagId) : undefined},`}${undefined === input.preselectedTagId ? "" : `"preselectedTagId":${undefined !== input.preselectedTagId ? $string(input.preselectedTagId) : undefined},`}${undefined === input.creator ? "" : `"creator":${undefined !== input.creator ? (() => {
            if ("string" === typeof input.creator)
                return $string(input.creator);
            if ("string" === typeof input.creator)
                return "\"" + input.creator + "\"";
            $throws({
                expected: "(\"SAMPLER_ACTIVE_LEARNING\" | \"SAMPLER_CORAL\" | \"SAMPLER_CORESET\" | \"SAMPLER_RANDOM\" | \"UNKNOWN\" | \"USER_PIP\" | \"USER_PIP_LIGHTLY_MAGIC\" | \"USER_WEBAPP\" | \"USER_WORKER\" | undefined)",
                value: input.creator
            });
        })() : undefined},`}${undefined === input.changes ? "" : `"changes":${undefined !== input.changes ? $so1(input.changes) : undefined},`}${undefined === input.runId ? "" : `"runId":${undefined !== input.runId ? $string(input.runId) : undefined},`}"name":${$string(input.name)},"prevTagId":${$string(input.prevTagId)},"bitMaskData":${$string(input.bitMaskData)},"totSize":${input.totSize}}`;
        const $so1 = (input: any): any => `{${$tail(`${undefined === input.initial ? "" : `"initial":${undefined !== input.initial ? $so2(input.initial) : undefined},`}${undefined === input.rename ? "" : `"rename":${undefined !== input.rename ? `{"from":${$string((input.rename as any).from)},"to":${$string((input.rename as any).to)}}` : undefined},`}${undefined === input.upsize ? "" : `"upsize":${undefined !== input.upsize ? $so4(input.upsize) : undefined},`}${undefined === input.arithmetics ? "" : `"arithmetics":${undefined !== input.arithmetics ? `{"operation":${$string((input.arithmetics as any).operation)},"tag1":${$string((input.arithmetics as any).tag1)},"tag2":${$string((input.arithmetics as any).tag2)}}` : undefined},`}${undefined === input.metadata ? "" : `"metadata":${undefined !== input.metadata ? $so6(input.metadata) : undefined},`}${undefined === input.samples ? "" : `"samples":${undefined !== input.samples ? $so8(input.samples) : undefined},`}${undefined === input.scatterplot ? "" : `"scatterplot":${undefined !== input.scatterplot ? $so9(input.scatterplot) : undefined},`}${undefined === input.sampler ? "" : `"sampler":${undefined !== input.sampler ? `{"method":${$string((input.sampler as any).method)}}` : undefined},`}${Object.entries(input).map(([key, value]: [string, any]) => { if (undefined === value)
            return ""; if (["initial", "rename", "upsize", "arithmetics", "metadata", "samples", "scatterplot", "sampler"].some((regular: any) => regular === key))
            return ""; return `${JSON.stringify(key)}:${undefined !== value ? JSON.stringify(value) : undefined}`; }).filter((str: any) => "" !== str).join(",")}`)}}`;
        const $so2 = (input: any): any => `{${$tail(`${undefined === input.runId ? "" : `"runId":${undefined !== input.runId ? $string(input.runId) : undefined}`}`)}}`;
        const $so4 = (input: any): any => `{${undefined === input.runId ? "" : `"runId":${undefined !== input.runId ? $string(input.runId) : undefined},`}"from":${input.from},"to":${input.to}}`;
        const $so6 = (input: any): any => `{"method":${(() => {
            if ("string" === typeof input.method)
                return $string(input.method);
            if ("string" === typeof input.method)
                return "\"" + input.method + "\"";
            $throws({
                expected: "(\"added\" | \"removed\" | \"selected\")",
                value: input.method
            });
        })()},"count":${input.count},"added":${input.added},"removed":${input.removed},"changes":${$so7(input.changes)}}`;
        const $so7 = (input: any): any => `{${Object.entries(input).map(([key, value]: [string, any]) => { if (undefined === value)
            return ""; return `${JSON.stringify(key)}:${undefined !== value ? JSON.stringify(value) : undefined}`; }).filter((str: any) => "" !== str).join(",")}}`;
        const $so8 = (input: any): any => `{"method":${(() => {
            if ("string" === typeof input.method)
                return $string(input.method);
            if ("string" === typeof input.method)
                return "\"" + input.method + "\"";
            $throws({
                expected: "(\"added\" | \"removed\" | \"selected\")",
                value: input.method
            });
        })()},"count":${input.count},"added":${input.added},"removed":${input.removed}}`;
        const $so9 = (input: any): any => `{${undefined === input.view ? "" : `"view":${undefined !== input.view ? $string(input.view) : undefined},`}"method":${(() => {
            if ("string" === typeof input.method)
                return $string(input.method);
            if ("string" === typeof input.method)
                return "\"" + input.method + "\"";
            $throws({
                expected: "(\"added\" | \"removed\" | \"selected\")",
                value: input.method
            });
        })()},"count":${input.count},"added":${input.added},"removed":${input.removed}}`;
        return $so0(input);
    }; return stringify(assert(input)); })(input);
};
export const TagCreateRequestValidatorShape = {
    'name': yup.string().matches(/^[a-zA-Z0-9][a-zA-Z0-9 .:;=@_-]+$/).min(3).defined(),
    'prevTagId': yup.string().matches(/^[a-f0-9]{24}$/).defined(),
    'queryTagId': yup.string().matches(/^[a-f0-9]{24}$/),
    'preselectedTagId': yup.string().matches(/^[a-f0-9]{24}$/),
    'bitMaskData': yup.string().matches(/^0x[a-f0-9]+$/).defined(),
    'totSize': yup.number().integer().defined(),
    'creator': TagCreatorValidator.optional().default(undefined),
    'changes': TagChangeDataValidator.optional().default(undefined),
    'runId': yup.string().matches(/^[a-f0-9]{24}$/),
};
export const TagCreateRequestValidator = yup.object().shape(TagCreateRequestValidatorShape);
export const TagCreateRequestStringifyShapeProperties: ObjectSchema['properties'] = {
    'name': {
        'type': 'string',
        'pattern': "/^[a-zA-Z0-9][a-zA-Z0-9 .:;&#x3D;@_-]+$/".slice(1, -1),
        'minLength': 3,
    } as unknown as fastJSON.StringSchema,
    'prevTagId': {
        'type': 'string',
        'pattern': "/^[a-f0-9]{24}$/".slice(1, -1),
    } as unknown as fastJSON.StringSchema,
    'queryTagId': {
        'type': 'string',
        'pattern': "/^[a-f0-9]{24}$/".slice(1, -1),
    } as unknown as fastJSON.StringSchema,
    'preselectedTagId': {
        'type': 'string',
        'pattern': "/^[a-f0-9]{24}$/".slice(1, -1),
    } as unknown as fastJSON.StringSchema,
    'bitMaskData': {
        'type': 'string',
        'pattern': "/^0x[a-f0-9]+$/".slice(1, -1),
    } as unknown as fastJSON.StringSchema,
    'totSize': {
        'type': 'integer',
    } as unknown as fastJSON.IntegerSchema,
    'creator': TagCreatorStringifyShape,
    'changes': TagChangeDataStringifyShape,
    'runId': {
        'type': 'string',
        'pattern': "/^[a-f0-9]{24}$/".slice(1, -1),
    } as unknown as fastJSON.StringSchema,
};
export const TagCreateRequestStringifyShape: ObjectSchema = {
    title: 'TagCreateRequest Stringifier',
    type: 'object',
    properties: {
        ...TagCreateRequestStringifyShapeProperties
    },
    required: [
        'name',
        'prevTagId',
        'bitMaskData',
        'totSize',
    ],
};
export const TagCreateRequestStringifyCompiled = (typeof process === 'object' && String(process) === '[object process]') ?
    fastJSON.build(TagCreateRequestStringifyShape, {
        ajv: {
            // since we are already doing this with yup, lets skip
            validateSchema: false,
            ownProperties: true,
            validateFormats: false,
        },
        largeArrayMechanism: 'json-stringify',
        largeArraySize: 1000,
    } as Options)
    :
        () => { throw new Error('fast-json-stringify only works in nodejs'); };
export const TagCreateRequestStringify: (data: TagCreateRequest) => string = TagCreateRequestStringifyCompiled;
/* LIGHTLY CUSTOM END -> Generic */
export function TagCreateRequestFromJSON(json: any): TagCreateRequest {
    return TagCreateRequestFromJSONTyped(json, false);
}
export function TagCreateRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): TagCreateRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    /* causes circular depdendencies
*/
    return {
        'name': json['name'],
        'prevTagId': json['prevTagId'],
        'queryTagId': !exists(json, 'queryTagId') ? undefined : json['queryTagId'],
        'preselectedTagId': !exists(json, 'preselectedTagId') ? undefined : json['preselectedTagId'],
        'bitMaskData': json['bitMaskData'],
        'totSize': json['totSize'],
        'creator': !exists(json, 'creator') ? undefined : TagCreatorFromJSON(json['creator']),
        'changes': !exists(json, 'changes') ? undefined : TagChangeDataFromJSON(json['changes']),
        'runId': !exists(json, 'runId') ? undefined : json['runId'],
    };
}
export function TagCreateRequestToJSON(value?: TagCreateRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'name': value.name,
        'prevTagId': value.prevTagId,
        'queryTagId': value.queryTagId,
        'preselectedTagId': value.preselectedTagId,
        'bitMaskData': value.bitMaskData,
        'totSize': value.totSize,
        'creator': TagCreatorToJSON(value.creator),
        'changes': TagChangeDataToJSON(value.changes),
        'runId': value.runId,
    };
}
