/* tslint:disable */
/* eslint-disable */
/**
 * Lightly API
 * Lightly.ai enables you to do self-supervised learning in an easy and intuitive way. The lightly.ai OpenAPI spec defines how one can interact with our REST API to unleash the full potential of lightly.ai
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: support@lightly.ai
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
// SRC: https://github.com/OpenAPITools/openapi-generator/blob/master/modules/openapi-generator/src/main/resources/typescript-fetch/modelGeneric.mustache
// synched 02.02.2023
import * as yup from 'yup';
import typia from 'typia';
import * as fastJSON from 'fast-json-stringify';
import { Options, ArraySchema, ObjectSchema } from 'fast-json-stringify';
import { exists, mapValues } from '../runtime';
import type { ActiveLearningScores } from './ActiveLearningScores';
import { ActiveLearningScoresFromJSON, ActiveLearningScoresFromJSONTyped, ActiveLearningScoresToJSON, } from './ActiveLearningScores';
/*
causes circular dependendies
*/
// SRC: https://github.com/OpenAPITools/openapi-generator/blob/master/modules/openapi-generator/src/main/resources/typescript-fetch/modelGenericInterfaces.mustache
// synced: 17.07.2023
/**
 *
 * @export
 * @interface ActiveLearningScoreV2Data
 *
 */
// create custom validators for the regexes
typia.customValidators.insert("pattern_id")("string")(() => {
    const regex = new RegExp(/^[a-f0-9]{24}$/);
    return (value: string) => !!regex.exec(value);
});
typia.customValidators.insert("pattern_datasetId")("string")(() => {
    const regex = new RegExp(/^[a-f0-9]{24}$/);
    return (value: string) => !!regex.exec(value);
});
typia.customValidators.insert("pattern_taskName")("string")(() => {
    const regex = new RegExp(/^[a-zA-Z0-9_+=,.@:\/-]*$/);
    return (value: string) => !!regex.exec(value);
});
typia.customValidators.insert("pattern_scoreType")("string")(() => {
    const regex = new RegExp(/^[a-zA-Z0-9_+=,.@:\/-]*$/);
    return (value: string) => !!regex.exec(value);
});
export interface ActiveLearningScoreV2Data {
    /**
     * MongoDB ObjectId
     * #type string
     * #memberof ActiveLearningScoreV2Data
        
        
    * @pattern_id
         */
    id: string;
    /**
     * MongoDB ObjectId
     * #type string
     * #memberof ActiveLearningScoreV2Data
        
        
    * @pattern_datasetId
         */
    datasetId: string;
    /**
     * unix timestamp in milliseconds
     * #type number
     * #memberof ActiveLearningScoreV2Data
        
    * @minimum 0
            
         */
    predictionUUIDTimestamp: number;
    /**
     * Since we sometimes stitch together SelectionInputTask+ActiveLearningScoreType, they need to follow the same specs of ActiveLearningScoreType. However, this can be an empty string due to internal logic (no minLength). Also v2config.filespecs.ts has this pattern for predictionTaskJSONSchema as well.
     * #type string
     * #memberof ActiveLearningScoreV2Data
        
        
    * @pattern_taskName
         */
    taskName: string;
    /**
     * Type of active learning score
     * #type string
     * #memberof ActiveLearningScoreV2Data
        
        
    * @pattern_scoreType
    * @minLength 1
         */
    scoreType: string;
    /**
     *
     * #type ActiveLearningScores
     * #memberof ActiveLearningScoreV2Data
        
        
         */
    scores: ActiveLearningScores;
    /**
     * unix timestamp in milliseconds
     * #type number
     * #memberof ActiveLearningScoreV2Data
        
    * @minimum 0
            
         */
    createdAt: number;
}
/**
 * Check if a given object implements the ActiveLearningScoreV2Data interface.
 */
export function instanceOfActiveLearningScoreV2Data(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "datasetId" in value;
    isInstance = isInstance && "predictionUUIDTimestamp" in value;
    isInstance = isInstance && "taskName" in value;
    isInstance = isInstance && "scoreType" in value;
    isInstance = isInstance && "scores" in value;
    isInstance = isInstance && "createdAt" in value;
    return isInstance;
}
/* LIGHTLY CUSTOM START -> modelGeneric */
import { ActiveLearningScoresValidator, ActiveLearningScoresStringifyShape, ActiveLearningScoresStringifyShapeProperties, } from './ActiveLearningScores';
export const ActiveLearningScoreV2DataTypiaAssertEquals = (input: any) => {
    return ((input: any): ActiveLearningScoreV2Data => {
        const __is = (input: any, _exceptionable: boolean = true): input is ActiveLearningScoreV2Data => {
            const $is_custom = (typia.createAssertEquals as any).is_custom;
            const $join = (typia.createAssertEquals as any).join;
            const $io0 = (input: any, _exceptionable: boolean = true): boolean => "string" === typeof input.id && $is_custom("pattern_id", "string", "", input.id) && ("string" === typeof input.datasetId && $is_custom("pattern_datasetId", "string", "", input.datasetId)) && ("number" === typeof input.predictionUUIDTimestamp && 0 <= input.predictionUUIDTimestamp) && ("string" === typeof input.taskName && $is_custom("pattern_taskName", "string", "", input.taskName)) && ("string" === typeof input.scoreType && 1 <= input.scoreType.length && $is_custom("pattern_scoreType", "string", "", input.scoreType)) && ("object" === typeof input.scores && null !== input.scores && $io1(input.scores, true && _exceptionable)) && ("number" === typeof input.createdAt && 0 <= input.createdAt) && (7 === Object.keys(input).length || Object.keys(input).every((key: any) => {
                if (["id", "datasetId", "predictionUUIDTimestamp", "taskName", "scoreType", "scores", "createdAt"].some((prop: any) => key === prop))
                    return true;
                const value = input[key];
                if (undefined === value)
                    return true;
                return false;
            }));
            const $io1 = (input: any, _exceptionable: boolean = true): boolean => "number" === typeof input.length && Object.keys(input).every((key: any) => {
                if (["length"].some((prop: any) => key === prop))
                    return true;
                const value = input[key];
                if (undefined === value)
                    return true;
                if (RegExp(/^[+-]?\d+(?:\.\d+)?(?:[eE][+-]?\d+)?$/).test(key))
                    return "number" === typeof value;
                return false;
            });
            return "object" === typeof input && null !== input && $io0(input, true);
        };
        if (false === __is(input))
            ((input: any, _path: string, _exceptionable: boolean = true): input is ActiveLearningScoreV2Data => {
                const $guard = (typia.createAssertEquals as any).guard;
                const $is_custom = (typia.createAssertEquals as any).is_custom;
                const $join = (typia.createAssertEquals as any).join;
                const $ao0 = (input: any, _path: string, _exceptionable: boolean = true): boolean => ("string" === typeof input.id && ($is_custom("pattern_id", "string", "", input.id) || $guard(_exceptionable, {
                    path: _path + ".id",
                    expected: "string (@pattern_id)",
                    value: input.id
                })) || $guard(_exceptionable, {
                    path: _path + ".id",
                    expected: "string",
                    value: input.id
                })) && ("string" === typeof input.datasetId && ($is_custom("pattern_datasetId", "string", "", input.datasetId) || $guard(_exceptionable, {
                    path: _path + ".datasetId",
                    expected: "string (@pattern_datasetId)",
                    value: input.datasetId
                })) || $guard(_exceptionable, {
                    path: _path + ".datasetId",
                    expected: "string",
                    value: input.datasetId
                })) && ("number" === typeof input.predictionUUIDTimestamp && (0 <= input.predictionUUIDTimestamp || $guard(_exceptionable, {
                    path: _path + ".predictionUUIDTimestamp",
                    expected: "number (@minimum 0)",
                    value: input.predictionUUIDTimestamp
                })) || $guard(_exceptionable, {
                    path: _path + ".predictionUUIDTimestamp",
                    expected: "number",
                    value: input.predictionUUIDTimestamp
                })) && ("string" === typeof input.taskName && ($is_custom("pattern_taskName", "string", "", input.taskName) || $guard(_exceptionable, {
                    path: _path + ".taskName",
                    expected: "string (@pattern_taskName)",
                    value: input.taskName
                })) || $guard(_exceptionable, {
                    path: _path + ".taskName",
                    expected: "string",
                    value: input.taskName
                })) && ("string" === typeof input.scoreType && (1 <= input.scoreType.length || $guard(_exceptionable, {
                    path: _path + ".scoreType",
                    expected: "string (@minLength 1)",
                    value: input.scoreType
                })) && ($is_custom("pattern_scoreType", "string", "", input.scoreType) || $guard(_exceptionable, {
                    path: _path + ".scoreType",
                    expected: "string (@pattern_scoreType)",
                    value: input.scoreType
                })) || $guard(_exceptionable, {
                    path: _path + ".scoreType",
                    expected: "string",
                    value: input.scoreType
                })) && (("object" === typeof input.scores && null !== input.scores || $guard(_exceptionable, {
                    path: _path + ".scores",
                    expected: "ActiveLearningScores",
                    value: input.scores
                })) && $ao1(input.scores, _path + ".scores", true && _exceptionable) || $guard(_exceptionable, {
                    path: _path + ".scores",
                    expected: "ActiveLearningScores",
                    value: input.scores
                })) && ("number" === typeof input.createdAt && (0 <= input.createdAt || $guard(_exceptionable, {
                    path: _path + ".createdAt",
                    expected: "number (@minimum 0)",
                    value: input.createdAt
                })) || $guard(_exceptionable, {
                    path: _path + ".createdAt",
                    expected: "number",
                    value: input.createdAt
                })) && (7 === Object.keys(input).length || (false === _exceptionable || Object.keys(input).every((key: any) => {
                    if (["id", "datasetId", "predictionUUIDTimestamp", "taskName", "scoreType", "scores", "createdAt"].some((prop: any) => key === prop))
                        return true;
                    const value = input[key];
                    if (undefined === value)
                        return true;
                    return $guard(_exceptionable, {
                        path: _path + $join(key),
                        expected: "undefined",
                        value: value
                    });
                })));
                const $ao1 = (input: any, _path: string, _exceptionable: boolean = true): boolean => ("number" === typeof input.length || $guard(_exceptionable, {
                    path: _path + ".length",
                    expected: "number",
                    value: input.length
                })) && (false === _exceptionable || Object.keys(input).every((key: any) => {
                    if (["length"].some((prop: any) => key === prop))
                        return true;
                    const value = input[key];
                    if (undefined === value)
                        return true;
                    if (RegExp(/^[+-]?\d+(?:\.\d+)?(?:[eE][+-]?\d+)?$/).test(key))
                        return "number" === typeof value || $guard(_exceptionable, {
                            path: _path + $join(key),
                            expected: "number",
                            value: value
                        });
                    return $guard(_exceptionable, {
                        path: _path + $join(key),
                        expected: "undefined",
                        value: value
                    });
                }));
                return ("object" === typeof input && null !== input || $guard(true, {
                    path: _path + "",
                    expected: "ActiveLearningScoreV2Data",
                    value: input
                })) && $ao0(input, _path + "", true) || $guard(true, {
                    path: _path + "",
                    expected: "ActiveLearningScoreV2Data",
                    value: input
                });
            })(input, "$input", true);
        return input;
    })(input);
};
export const ActiveLearningScoreV2DataTypiaAssertStringify = (input: ActiveLearningScoreV2Data): string => {
    return ((input: any): string => { const assert = (input: any): ActiveLearningScoreV2Data => {
        const __is = (input: any): input is ActiveLearningScoreV2Data => {
            const $is_custom = (typia.createAssertStringify as any).is_custom;
            const $join = (typia.createAssertStringify as any).join;
            const $io0 = (input: any): boolean => "string" === typeof input.id && $is_custom("pattern_id", "string", "", input.id) && ("string" === typeof input.datasetId && $is_custom("pattern_datasetId", "string", "", input.datasetId)) && ("number" === typeof input.predictionUUIDTimestamp && !Number.isNaN(input.predictionUUIDTimestamp) && 0 <= input.predictionUUIDTimestamp) && ("string" === typeof input.taskName && $is_custom("pattern_taskName", "string", "", input.taskName)) && ("string" === typeof input.scoreType && 1 <= input.scoreType.length && $is_custom("pattern_scoreType", "string", "", input.scoreType)) && ("object" === typeof input.scores && null !== input.scores && $io1(input.scores)) && ("number" === typeof input.createdAt && !Number.isNaN(input.createdAt) && 0 <= input.createdAt);
            const $io1 = (input: any): boolean => "number" === typeof input.length && !Number.isNaN(input.length) && Object.keys(input).every((key: any) => {
                if (["length"].some((prop: any) => key === prop))
                    return true;
                const value = input[key];
                if (undefined === value)
                    return true;
                if (RegExp(/^[+-]?\d+(?:\.\d+)?(?:[eE][+-]?\d+)?$/).test(key))
                    return "number" === typeof value && !Number.isNaN(value);
                return true;
            });
            return "object" === typeof input && null !== input && $io0(input);
        };
        if (false === __is(input))
            ((input: any, _path: string, _exceptionable: boolean = true): input is ActiveLearningScoreV2Data => {
                const $guard = (typia.createAssertStringify as any).guard;
                const $is_custom = (typia.createAssertStringify as any).is_custom;
                const $join = (typia.createAssertStringify as any).join;
                const $ao0 = (input: any, _path: string, _exceptionable: boolean = true): boolean => ("string" === typeof input.id && ($is_custom("pattern_id", "string", "", input.id) || $guard(_exceptionable, {
                    path: _path + ".id",
                    expected: "string (@pattern_id)",
                    value: input.id
                })) || $guard(_exceptionable, {
                    path: _path + ".id",
                    expected: "string",
                    value: input.id
                })) && ("string" === typeof input.datasetId && ($is_custom("pattern_datasetId", "string", "", input.datasetId) || $guard(_exceptionable, {
                    path: _path + ".datasetId",
                    expected: "string (@pattern_datasetId)",
                    value: input.datasetId
                })) || $guard(_exceptionable, {
                    path: _path + ".datasetId",
                    expected: "string",
                    value: input.datasetId
                })) && ("number" === typeof input.predictionUUIDTimestamp && !Number.isNaN(input.predictionUUIDTimestamp) && (0 <= input.predictionUUIDTimestamp || $guard(_exceptionable, {
                    path: _path + ".predictionUUIDTimestamp",
                    expected: "number (@minimum 0)",
                    value: input.predictionUUIDTimestamp
                })) || $guard(_exceptionable, {
                    path: _path + ".predictionUUIDTimestamp",
                    expected: "number",
                    value: input.predictionUUIDTimestamp
                })) && ("string" === typeof input.taskName && ($is_custom("pattern_taskName", "string", "", input.taskName) || $guard(_exceptionable, {
                    path: _path + ".taskName",
                    expected: "string (@pattern_taskName)",
                    value: input.taskName
                })) || $guard(_exceptionable, {
                    path: _path + ".taskName",
                    expected: "string",
                    value: input.taskName
                })) && ("string" === typeof input.scoreType && (1 <= input.scoreType.length || $guard(_exceptionable, {
                    path: _path + ".scoreType",
                    expected: "string (@minLength 1)",
                    value: input.scoreType
                })) && ($is_custom("pattern_scoreType", "string", "", input.scoreType) || $guard(_exceptionable, {
                    path: _path + ".scoreType",
                    expected: "string (@pattern_scoreType)",
                    value: input.scoreType
                })) || $guard(_exceptionable, {
                    path: _path + ".scoreType",
                    expected: "string",
                    value: input.scoreType
                })) && (("object" === typeof input.scores && null !== input.scores || $guard(_exceptionable, {
                    path: _path + ".scores",
                    expected: "ActiveLearningScores",
                    value: input.scores
                })) && $ao1(input.scores, _path + ".scores", true && _exceptionable) || $guard(_exceptionable, {
                    path: _path + ".scores",
                    expected: "ActiveLearningScores",
                    value: input.scores
                })) && ("number" === typeof input.createdAt && !Number.isNaN(input.createdAt) && (0 <= input.createdAt || $guard(_exceptionable, {
                    path: _path + ".createdAt",
                    expected: "number (@minimum 0)",
                    value: input.createdAt
                })) || $guard(_exceptionable, {
                    path: _path + ".createdAt",
                    expected: "number",
                    value: input.createdAt
                }));
                const $ao1 = (input: any, _path: string, _exceptionable: boolean = true): boolean => ("number" === typeof input.length && !Number.isNaN(input.length) || $guard(_exceptionable, {
                    path: _path + ".length",
                    expected: "number",
                    value: input.length
                })) && (false === _exceptionable || Object.keys(input).every((key: any) => {
                    if (["length"].some((prop: any) => key === prop))
                        return true;
                    const value = input[key];
                    if (undefined === value)
                        return true;
                    if (RegExp(/^[+-]?\d+(?:\.\d+)?(?:[eE][+-]?\d+)?$/).test(key))
                        return "number" === typeof value && !Number.isNaN(value) || $guard(_exceptionable, {
                            path: _path + $join(key),
                            expected: "number",
                            value: value
                        });
                    return true;
                }));
                return ("object" === typeof input && null !== input || $guard(true, {
                    path: _path + "",
                    expected: "ActiveLearningScoreV2Data",
                    value: input
                })) && $ao0(input, _path + "", true) || $guard(true, {
                    path: _path + "",
                    expected: "ActiveLearningScoreV2Data",
                    value: input
                });
            })(input, "$input", true);
        return input;
    }; const stringify = (input: ActiveLearningScoreV2Data): string => {
        const $io1 = (input: any): boolean => "number" === typeof input.length && Object.keys(input).every((key: any) => {
            if (["length"].some((prop: any) => key === prop))
                return true;
            const value = input[key];
            if (undefined === value)
                return true;
            if (RegExp(/^[+-]?\d+(?:\.\d+)?(?:[eE][+-]?\d+)?$/).test(key))
                return "number" === typeof value;
            return true;
        });
        const $string = (typia.createAssertStringify as any).string;
        const $join = (typia.createAssertStringify as any).join;
        const $tail = (typia.createAssertStringify as any).tail;
        const $is_custom = (typia.createAssertStringify as any).is_custom;
        const $so0 = (input: any): any => `{"id":${$string(input.id)},"datasetId":${$string(input.datasetId)},"predictionUUIDTimestamp":${input.predictionUUIDTimestamp},"taskName":${$string(input.taskName)},"scoreType":${$string(input.scoreType)},"scores":${$so1(input.scores)},"createdAt":${input.createdAt}}`;
        const $so1 = (input: any): any => `{${$tail(`"length":${input.length},${Object.entries(input).map(([key, value]: [string, any]) => { if (undefined === value)
            return ""; if (["length"].some((regular: any) => regular === key))
            return ""; if (RegExp(/^[+-]?\d+(?:\.\d+)?(?:[eE][+-]?\d+)?$/).test(key))
            return `${JSON.stringify(key)}:${value}`; return ""; }).filter((str: any) => "" !== str).join(",")}`)}}`;
        return $so0(input);
    }; return stringify(assert(input)); })(input);
};
export const ActiveLearningScoreV2DataValidatorShape = {
    'id': yup.string().matches(/^[a-f0-9]{24}$/).defined(),
    'datasetId': yup.string().matches(/^[a-f0-9]{24}$/).defined(),
    'predictionUUIDTimestamp': yup.number().min(0).defined(),
    'taskName': yup.string().matches(/^[a-zA-Z0-9_+=,.@:\/-]*$/).defined(),
    'scoreType': yup.string().matches(/^[a-zA-Z0-9_+=,.@:\/-]*$/).min(1).defined(),
    'scores': ActiveLearningScoresValidator.defined(),
    'createdAt': yup.number().min(0).defined(),
};
export const ActiveLearningScoreV2DataValidator = yup.object().shape(ActiveLearningScoreV2DataValidatorShape);
export const ActiveLearningScoreV2DataStringifyShapeProperties: ObjectSchema['properties'] = {
    'id': {
        'type': 'string',
        'pattern': "/^[a-f0-9]{24}$/".slice(1, -1),
    } as unknown as fastJSON.StringSchema,
    'datasetId': {
        'type': 'string',
        'pattern': "/^[a-f0-9]{24}$/".slice(1, -1),
    } as unknown as fastJSON.StringSchema,
    'predictionUUIDTimestamp': {
        'type': 'number',
        'minimum': 0,
    } as unknown as fastJSON.NumberSchema,
    'taskName': {
        'type': 'string',
        'pattern': "/^[a-zA-Z0-9_+&#x3D;,.@:\/-]*$/".slice(1, -1),
    } as unknown as fastJSON.StringSchema,
    'scoreType': {
        'type': 'string',
        'pattern': "/^[a-zA-Z0-9_+&#x3D;,.@:\/-]*$/".slice(1, -1),
        'minLength': 1,
    } as unknown as fastJSON.StringSchema,
    'scores': ActiveLearningScoresStringifyShape,
    'createdAt': {
        'type': 'number',
        'minimum': 0,
    } as unknown as fastJSON.NumberSchema,
};
export const ActiveLearningScoreV2DataStringifyShape: ObjectSchema = {
    title: 'ActiveLearningScoreV2Data Stringifier',
    type: 'object',
    properties: {
        ...ActiveLearningScoreV2DataStringifyShapeProperties
    },
    required: [
        'id',
        'datasetId',
        'predictionUUIDTimestamp',
        'taskName',
        'scoreType',
        'scores',
        'createdAt',
    ],
};
export const ActiveLearningScoreV2DataStringifyCompiled = (typeof process === 'object' && String(process) === '[object process]') ?
    fastJSON.build(ActiveLearningScoreV2DataStringifyShape, {
        ajv: {
            // since we are already doing this with yup, lets skip
            validateSchema: false,
            ownProperties: true,
            validateFormats: false,
        },
        largeArrayMechanism: 'json-stringify',
        largeArraySize: 1000,
    } as Options)
    :
        () => { throw new Error('fast-json-stringify only works in nodejs'); };
export const ActiveLearningScoreV2DataStringify: (data: ActiveLearningScoreV2Data) => string = ActiveLearningScoreV2DataStringifyCompiled;
/* LIGHTLY CUSTOM END -> Generic */
export function ActiveLearningScoreV2DataFromJSON(json: any): ActiveLearningScoreV2Data {
    return ActiveLearningScoreV2DataFromJSONTyped(json, false);
}
export function ActiveLearningScoreV2DataFromJSONTyped(json: any, ignoreDiscriminator: boolean): ActiveLearningScoreV2Data {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    /* causes circular depdendencies
*/
    return {
        'id': json['id'],
        'datasetId': json['datasetId'],
        'predictionUUIDTimestamp': json['predictionUUIDTimestamp'],
        'taskName': json['taskName'],
        'scoreType': json['scoreType'],
        'scores': ActiveLearningScoresFromJSON(json['scores']),
        'createdAt': json['createdAt'],
    };
}
export function ActiveLearningScoreV2DataToJSON(value?: ActiveLearningScoreV2Data | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'id': value.id,
        'datasetId': value.datasetId,
        'predictionUUIDTimestamp': value.predictionUUIDTimestamp,
        'taskName': value.taskName,
        'scoreType': value.scoreType,
        'scores': ActiveLearningScoresToJSON(value.scores),
        'createdAt': value.createdAt,
    };
}
