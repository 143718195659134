/* tslint:disable */
/* eslint-disable */
/**
 * Lightly API
 * Lightly.ai enables you to do self-supervised learning in an easy and intuitive way. The lightly.ai OpenAPI spec defines how one can interact with our REST API to unleash the full potential of lightly.ai
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: support@lightly.ai
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
// SRC: https://github.com/OpenAPITools/openapi-generator/blob/master/modules/openapi-generator/src/main/resources/typescript-fetch/modelGeneric.mustache
// synched 02.02.2023
import * as yup from 'yup';
import typia from 'typia';
import * as fastJSON from 'fast-json-stringify';
import { Options, ArraySchema, ObjectSchema } from 'fast-json-stringify';
import { exists, mapValues } from '../runtime';
import type { DimensionalityReductionMethod } from './DimensionalityReductionMethod';
import { DimensionalityReductionMethodFromJSON, DimensionalityReductionMethodFromJSONTyped, DimensionalityReductionMethodToJSON, } from './DimensionalityReductionMethod';
import type { Embedding2dCoordinates } from './Embedding2dCoordinates';
import { Embedding2dCoordinatesFromJSON, Embedding2dCoordinatesFromJSONTyped, Embedding2dCoordinatesToJSON, } from './Embedding2dCoordinates';
/*
causes circular dependendies
*/
// SRC: https://github.com/OpenAPITools/openapi-generator/blob/master/modules/openapi-generator/src/main/resources/typescript-fetch/modelGenericInterfaces.mustache
// synced: 17.07.2023
/**
 *
 * @export
 * @interface Embedding2dCreateRequest
 *
 */
// create custom validators for the regexes
export interface Embedding2dCreateRequest {
    /**
     * Name of the 2d embedding (default is embedding name + __2d)
     * #type string
     * #memberof Embedding2dCreateRequest
        
        
         */
    name: string;
    /**
     *
     * #type DimensionalityReductionMethod
     * #memberof Embedding2dCreateRequest
        
        
         */
    dimensionalityReductionMethod: DimensionalityReductionMethod;
    /**
     *
     * #type Embedding2dCoordinates
     * #memberof Embedding2dCreateRequest
        
        
         */
    coordinatesDimension1: Embedding2dCoordinates;
    /**
     *
     * #type Embedding2dCoordinates
     * #memberof Embedding2dCreateRequest
        
        
         */
    coordinatesDimension2: Embedding2dCoordinates;
}
/**
 * Check if a given object implements the Embedding2dCreateRequest interface.
 */
export function instanceOfEmbedding2dCreateRequest(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "dimensionalityReductionMethod" in value;
    isInstance = isInstance && "coordinatesDimension1" in value;
    isInstance = isInstance && "coordinatesDimension2" in value;
    return isInstance;
}
/* LIGHTLY CUSTOM START -> modelGeneric */
import { DimensionalityReductionMethodValidator, DimensionalityReductionMethodStringifyShape, DimensionalityReductionMethodStringifyShapeProperties, } from './DimensionalityReductionMethod';
import { Embedding2dCoordinatesValidator, Embedding2dCoordinatesStringifyShape, Embedding2dCoordinatesStringifyShapeProperties, } from './Embedding2dCoordinates';
export const Embedding2dCreateRequestTypiaAssertEquals = (input: any) => {
    return ((input: any): Embedding2dCreateRequest => {
        const __is = (input: any, _exceptionable: boolean = true): input is Embedding2dCreateRequest => {
            const $join = (typia.createAssertEquals as any).join;
            const $io0 = (input: any, _exceptionable: boolean = true): boolean => "string" === typeof input.name && ("PCA" === input.dimensionalityReductionMethod || "TSNE" === input.dimensionalityReductionMethod || "UMAP" === input.dimensionalityReductionMethod) && ("object" === typeof input.coordinatesDimension1 && null !== input.coordinatesDimension1 && $io1(input.coordinatesDimension1, true && _exceptionable)) && ("object" === typeof input.coordinatesDimension2 && null !== input.coordinatesDimension2 && $io1(input.coordinatesDimension2, true && _exceptionable)) && (4 === Object.keys(input).length || Object.keys(input).every((key: any) => {
                if (["name", "dimensionalityReductionMethod", "coordinatesDimension1", "coordinatesDimension2"].some((prop: any) => key === prop))
                    return true;
                const value = input[key];
                if (undefined === value)
                    return true;
                return false;
            }));
            const $io1 = (input: any, _exceptionable: boolean = true): boolean => "number" === typeof input.length && Object.keys(input).every((key: any) => {
                if (["length"].some((prop: any) => key === prop))
                    return true;
                const value = input[key];
                if (undefined === value)
                    return true;
                if (RegExp(/^[+-]?\d+(?:\.\d+)?(?:[eE][+-]?\d+)?$/).test(key))
                    return "number" === typeof value;
                return false;
            });
            return "object" === typeof input && null !== input && $io0(input, true);
        };
        if (false === __is(input))
            ((input: any, _path: string, _exceptionable: boolean = true): input is Embedding2dCreateRequest => {
                const $guard = (typia.createAssertEquals as any).guard;
                const $join = (typia.createAssertEquals as any).join;
                const $ao0 = (input: any, _path: string, _exceptionable: boolean = true): boolean => ("string" === typeof input.name || $guard(_exceptionable, {
                    path: _path + ".name",
                    expected: "string",
                    value: input.name
                })) && ("PCA" === input.dimensionalityReductionMethod || "TSNE" === input.dimensionalityReductionMethod || "UMAP" === input.dimensionalityReductionMethod || $guard(_exceptionable, {
                    path: _path + ".dimensionalityReductionMethod",
                    expected: "(\"PCA\" | \"TSNE\" | \"UMAP\")",
                    value: input.dimensionalityReductionMethod
                })) && (("object" === typeof input.coordinatesDimension1 && null !== input.coordinatesDimension1 || $guard(_exceptionable, {
                    path: _path + ".coordinatesDimension1",
                    expected: "Embedding2dCoordinates",
                    value: input.coordinatesDimension1
                })) && $ao1(input.coordinatesDimension1, _path + ".coordinatesDimension1", true && _exceptionable) || $guard(_exceptionable, {
                    path: _path + ".coordinatesDimension1",
                    expected: "Embedding2dCoordinates",
                    value: input.coordinatesDimension1
                })) && (("object" === typeof input.coordinatesDimension2 && null !== input.coordinatesDimension2 || $guard(_exceptionable, {
                    path: _path + ".coordinatesDimension2",
                    expected: "Embedding2dCoordinates",
                    value: input.coordinatesDimension2
                })) && $ao1(input.coordinatesDimension2, _path + ".coordinatesDimension2", true && _exceptionable) || $guard(_exceptionable, {
                    path: _path + ".coordinatesDimension2",
                    expected: "Embedding2dCoordinates",
                    value: input.coordinatesDimension2
                })) && (4 === Object.keys(input).length || (false === _exceptionable || Object.keys(input).every((key: any) => {
                    if (["name", "dimensionalityReductionMethod", "coordinatesDimension1", "coordinatesDimension2"].some((prop: any) => key === prop))
                        return true;
                    const value = input[key];
                    if (undefined === value)
                        return true;
                    return $guard(_exceptionable, {
                        path: _path + $join(key),
                        expected: "undefined",
                        value: value
                    });
                })));
                const $ao1 = (input: any, _path: string, _exceptionable: boolean = true): boolean => ("number" === typeof input.length || $guard(_exceptionable, {
                    path: _path + ".length",
                    expected: "number",
                    value: input.length
                })) && (false === _exceptionable || Object.keys(input).every((key: any) => {
                    if (["length"].some((prop: any) => key === prop))
                        return true;
                    const value = input[key];
                    if (undefined === value)
                        return true;
                    if (RegExp(/^[+-]?\d+(?:\.\d+)?(?:[eE][+-]?\d+)?$/).test(key))
                        return "number" === typeof value || $guard(_exceptionable, {
                            path: _path + $join(key),
                            expected: "number",
                            value: value
                        });
                    return $guard(_exceptionable, {
                        path: _path + $join(key),
                        expected: "undefined",
                        value: value
                    });
                }));
                return ("object" === typeof input && null !== input || $guard(true, {
                    path: _path + "",
                    expected: "Embedding2dCreateRequest",
                    value: input
                })) && $ao0(input, _path + "", true) || $guard(true, {
                    path: _path + "",
                    expected: "Embedding2dCreateRequest",
                    value: input
                });
            })(input, "$input", true);
        return input;
    })(input);
};
export const Embedding2dCreateRequestTypiaAssertStringify = (input: Embedding2dCreateRequest): string => {
    return ((input: any): string => { const assert = (input: any): Embedding2dCreateRequest => {
        const __is = (input: any): input is Embedding2dCreateRequest => {
            const $join = (typia.createAssertStringify as any).join;
            const $io0 = (input: any): boolean => "string" === typeof input.name && ("PCA" === input.dimensionalityReductionMethod || "TSNE" === input.dimensionalityReductionMethod || "UMAP" === input.dimensionalityReductionMethod) && ("object" === typeof input.coordinatesDimension1 && null !== input.coordinatesDimension1 && $io1(input.coordinatesDimension1)) && ("object" === typeof input.coordinatesDimension2 && null !== input.coordinatesDimension2 && $io1(input.coordinatesDimension2));
            const $io1 = (input: any): boolean => "number" === typeof input.length && !Number.isNaN(input.length) && Object.keys(input).every((key: any) => {
                if (["length"].some((prop: any) => key === prop))
                    return true;
                const value = input[key];
                if (undefined === value)
                    return true;
                if (RegExp(/^[+-]?\d+(?:\.\d+)?(?:[eE][+-]?\d+)?$/).test(key))
                    return "number" === typeof value && !Number.isNaN(value);
                return true;
            });
            return "object" === typeof input && null !== input && $io0(input);
        };
        if (false === __is(input))
            ((input: any, _path: string, _exceptionable: boolean = true): input is Embedding2dCreateRequest => {
                const $guard = (typia.createAssertStringify as any).guard;
                const $join = (typia.createAssertStringify as any).join;
                const $ao0 = (input: any, _path: string, _exceptionable: boolean = true): boolean => ("string" === typeof input.name || $guard(_exceptionable, {
                    path: _path + ".name",
                    expected: "string",
                    value: input.name
                })) && ("PCA" === input.dimensionalityReductionMethod || "TSNE" === input.dimensionalityReductionMethod || "UMAP" === input.dimensionalityReductionMethod || $guard(_exceptionable, {
                    path: _path + ".dimensionalityReductionMethod",
                    expected: "(\"PCA\" | \"TSNE\" | \"UMAP\")",
                    value: input.dimensionalityReductionMethod
                })) && (("object" === typeof input.coordinatesDimension1 && null !== input.coordinatesDimension1 || $guard(_exceptionable, {
                    path: _path + ".coordinatesDimension1",
                    expected: "Embedding2dCoordinates",
                    value: input.coordinatesDimension1
                })) && $ao1(input.coordinatesDimension1, _path + ".coordinatesDimension1", true && _exceptionable) || $guard(_exceptionable, {
                    path: _path + ".coordinatesDimension1",
                    expected: "Embedding2dCoordinates",
                    value: input.coordinatesDimension1
                })) && (("object" === typeof input.coordinatesDimension2 && null !== input.coordinatesDimension2 || $guard(_exceptionable, {
                    path: _path + ".coordinatesDimension2",
                    expected: "Embedding2dCoordinates",
                    value: input.coordinatesDimension2
                })) && $ao1(input.coordinatesDimension2, _path + ".coordinatesDimension2", true && _exceptionable) || $guard(_exceptionable, {
                    path: _path + ".coordinatesDimension2",
                    expected: "Embedding2dCoordinates",
                    value: input.coordinatesDimension2
                }));
                const $ao1 = (input: any, _path: string, _exceptionable: boolean = true): boolean => ("number" === typeof input.length && !Number.isNaN(input.length) || $guard(_exceptionable, {
                    path: _path + ".length",
                    expected: "number",
                    value: input.length
                })) && (false === _exceptionable || Object.keys(input).every((key: any) => {
                    if (["length"].some((prop: any) => key === prop))
                        return true;
                    const value = input[key];
                    if (undefined === value)
                        return true;
                    if (RegExp(/^[+-]?\d+(?:\.\d+)?(?:[eE][+-]?\d+)?$/).test(key))
                        return "number" === typeof value && !Number.isNaN(value) || $guard(_exceptionable, {
                            path: _path + $join(key),
                            expected: "number",
                            value: value
                        });
                    return true;
                }));
                return ("object" === typeof input && null !== input || $guard(true, {
                    path: _path + "",
                    expected: "Embedding2dCreateRequest",
                    value: input
                })) && $ao0(input, _path + "", true) || $guard(true, {
                    path: _path + "",
                    expected: "Embedding2dCreateRequest",
                    value: input
                });
            })(input, "$input", true);
        return input;
    }; const stringify = (input: Embedding2dCreateRequest): string => {
        const $io1 = (input: any): boolean => "number" === typeof input.length && Object.keys(input).every((key: any) => {
            if (["length"].some((prop: any) => key === prop))
                return true;
            const value = input[key];
            if (undefined === value)
                return true;
            if (RegExp(/^[+-]?\d+(?:\.\d+)?(?:[eE][+-]?\d+)?$/).test(key))
                return "number" === typeof value;
            return true;
        });
        const $string = (typia.createAssertStringify as any).string;
        const $throws = (typia.createAssertStringify as any).throws;
        const $join = (typia.createAssertStringify as any).join;
        const $tail = (typia.createAssertStringify as any).tail;
        const $so0 = (input: any): any => `{"name":${$string(input.name)},"dimensionalityReductionMethod":${(() => {
            if ("string" === typeof input.dimensionalityReductionMethod)
                return $string(input.dimensionalityReductionMethod);
            if ("string" === typeof input.dimensionalityReductionMethod)
                return "\"" + input.dimensionalityReductionMethod + "\"";
            $throws({
                expected: "(\"PCA\" | \"TSNE\" | \"UMAP\")",
                value: input.dimensionalityReductionMethod
            });
        })()},"coordinatesDimension1":${$so1(input.coordinatesDimension1)},"coordinatesDimension2":${$so1(input.coordinatesDimension2)}}`;
        const $so1 = (input: any): any => `{${$tail(`"length":${input.length},${Object.entries(input).map(([key, value]: [string, any]) => { if (undefined === value)
            return ""; if (["length"].some((regular: any) => regular === key))
            return ""; if (RegExp(/^[+-]?\d+(?:\.\d+)?(?:[eE][+-]?\d+)?$/).test(key))
            return `${JSON.stringify(key)}:${value}`; return ""; }).filter((str: any) => "" !== str).join(",")}`)}}`;
        return $so0(input);
    }; return stringify(assert(input)); })(input);
};
export const Embedding2dCreateRequestValidatorShape = {
    'name': yup.string().defined(),
    'dimensionalityReductionMethod': DimensionalityReductionMethodValidator.defined(),
    'coordinatesDimension1': Embedding2dCoordinatesValidator.defined(),
    'coordinatesDimension2': Embedding2dCoordinatesValidator.defined(),
};
export const Embedding2dCreateRequestValidator = yup.object().shape(Embedding2dCreateRequestValidatorShape);
export const Embedding2dCreateRequestStringifyShapeProperties: ObjectSchema['properties'] = {
    'name': {
        'type': 'string',
    } as unknown as fastJSON.StringSchema,
    'dimensionalityReductionMethod': DimensionalityReductionMethodStringifyShape,
    'coordinatesDimension1': Embedding2dCoordinatesStringifyShape,
    'coordinatesDimension2': Embedding2dCoordinatesStringifyShape,
};
export const Embedding2dCreateRequestStringifyShape: ObjectSchema = {
    title: 'Embedding2dCreateRequest Stringifier',
    type: 'object',
    properties: {
        ...Embedding2dCreateRequestStringifyShapeProperties
    },
    required: [
        'name',
        'dimensionalityReductionMethod',
        'coordinatesDimension1',
        'coordinatesDimension2',
    ],
};
export const Embedding2dCreateRequestStringifyCompiled = (typeof process === 'object' && String(process) === '[object process]') ?
    fastJSON.build(Embedding2dCreateRequestStringifyShape, {
        ajv: {
            // since we are already doing this with yup, lets skip
            validateSchema: false,
            ownProperties: true,
            validateFormats: false,
        },
        largeArrayMechanism: 'json-stringify',
        largeArraySize: 1000,
    } as Options)
    :
        () => { throw new Error('fast-json-stringify only works in nodejs'); };
export const Embedding2dCreateRequestStringify: (data: Embedding2dCreateRequest) => string = Embedding2dCreateRequestStringifyCompiled;
/* LIGHTLY CUSTOM END -> Generic */
export function Embedding2dCreateRequestFromJSON(json: any): Embedding2dCreateRequest {
    return Embedding2dCreateRequestFromJSONTyped(json, false);
}
export function Embedding2dCreateRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): Embedding2dCreateRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    /* causes circular depdendencies
*/
    return {
        'name': json['name'],
        'dimensionalityReductionMethod': DimensionalityReductionMethodFromJSON(json['dimensionalityReductionMethod']),
        'coordinatesDimension1': Embedding2dCoordinatesFromJSON(json['coordinatesDimension1']),
        'coordinatesDimension2': Embedding2dCoordinatesFromJSON(json['coordinatesDimension2']),
    };
}
export function Embedding2dCreateRequestToJSON(value?: Embedding2dCreateRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'name': value.name,
        'dimensionalityReductionMethod': DimensionalityReductionMethodToJSON(value.dimensionalityReductionMethod),
        'coordinatesDimension1': Embedding2dCoordinatesToJSON(value.coordinatesDimension1),
        'coordinatesDimension2': Embedding2dCoordinatesToJSON(value.coordinatesDimension2),
    };
}
