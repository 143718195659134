/* tslint:disable */
/* eslint-disable */
/**
 * Lightly API
 * Lightly.ai enables you to do self-supervised learning in an easy and intuitive way. The lightly.ai OpenAPI spec defines how one can interact with our REST API to unleash the full potential of lightly.ai
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: support@lightly.ai
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
// SRC: https://github.com/OpenAPITools/openapi-generator/blob/master/modules/openapi-generator/src/main/resources/typescript-fetch/modelGeneric.mustache
// synched 02.02.2023
import * as yup from 'yup';
import typia from 'typia';
import * as fastJSON from 'fast-json-stringify';
import { Options, ArraySchema, ObjectSchema } from 'fast-json-stringify';
import { exists, mapValues } from '../runtime';
import type { CropData } from './CropData';
import { CropDataFromJSON, CropDataFromJSONTyped, CropDataToJSON, } from './CropData';
import type { CustomSampleMetaData } from './CustomSampleMetaData';
import { CustomSampleMetaDataFromJSON, CustomSampleMetaDataFromJSONTyped, CustomSampleMetaDataToJSON, } from './CustomSampleMetaData';
import type { SampleMetaData } from './SampleMetaData';
import { SampleMetaDataFromJSON, SampleMetaDataFromJSONTyped, SampleMetaDataToJSON, } from './SampleMetaData';
import type { VideoFrameData } from './VideoFrameData';
import { VideoFrameDataFromJSON, VideoFrameDataFromJSONTyped, VideoFrameDataToJSON, } from './VideoFrameData';
/*
causes circular dependendies
*/
// SRC: https://github.com/OpenAPITools/openapi-generator/blob/master/modules/openapi-generator/src/main/resources/typescript-fetch/modelGenericInterfaces.mustache
// synced: 17.07.2023
/**
 *
 * @export
 * @interface SampleCreateRequest
 *
 */
// create custom validators for the regexes
export interface SampleCreateRequest {
    /**
     *
     * #type string
     * #memberof SampleCreateRequest
        
        
         */
    fileName: string;
    /**
     *
     * #type string
     * #memberof SampleCreateRequest
        
        
         */
    thumbName?: string;
    /**
     *
     * #type { [key: string]: any | undefined; }
     * #memberof SampleCreateRequest
        
        
         */
    exif?: {
        [key: string]: any | undefined;
    };
    /**
     *
     * #type SampleMetaData
     * #memberof SampleCreateRequest
        
        
         */
    metaData?: SampleMetaData;
    /**
     *
     * #type CustomSampleMetaData
     * #memberof SampleCreateRequest
        
        
         */
    customMetaData?: CustomSampleMetaData | null;
    /**
     *
     * #type VideoFrameData
     * #memberof SampleCreateRequest
        
        
         */
    videoFrameData?: VideoFrameData | null;
    /**
     *
     * #type CropData
     * #memberof SampleCreateRequest
        
        
         */
    cropData?: CropData | null;
}
/**
 * Check if a given object implements the SampleCreateRequest interface.
 */
export function instanceOfSampleCreateRequest(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "fileName" in value;
    return isInstance;
}
/* LIGHTLY CUSTOM START -> modelGeneric */
import { CropDataValidator, CropDataStringifyShape, CropDataStringifyShapeProperties, } from './CropData';
import { CustomSampleMetaDataValidator, CustomSampleMetaDataStringifyShape, CustomSampleMetaDataStringifyShapeProperties, } from './CustomSampleMetaData';
import { SampleMetaDataValidator, SampleMetaDataStringifyShape, SampleMetaDataStringifyShapeProperties, } from './SampleMetaData';
import { VideoFrameDataValidator, VideoFrameDataStringifyShape, VideoFrameDataStringifyShapeProperties, } from './VideoFrameData';
export const SampleCreateRequestTypiaAssertEquals = (input: any) => {
    return ((input: any): SampleCreateRequest => {
        const __is = (input: any, _exceptionable: boolean = true): input is SampleCreateRequest => {
            const $join = (typia.createAssertEquals as any).join;
            const $is_custom = (typia.createAssertEquals as any).is_custom;
            const $io0 = (input: any, _exceptionable: boolean = true): boolean => "string" === typeof input.fileName && (undefined === input.thumbName || "string" === typeof input.thumbName) && (undefined === input.exif || "object" === typeof input.exif && null !== input.exif && false === Array.isArray(input.exif) && $io1(input.exif, true && _exceptionable)) && (undefined === input.metaData || "object" === typeof input.metaData && null !== input.metaData && false === Array.isArray(input.metaData) && $io2(input.metaData, true && _exceptionable)) && (null === input.customMetaData || undefined === input.customMetaData || "object" === typeof input.customMetaData && null !== input.customMetaData && false === Array.isArray(input.customMetaData) && $io5(input.customMetaData, true && _exceptionable)) && (null === input.videoFrameData || undefined === input.videoFrameData || "object" === typeof input.videoFrameData && null !== input.videoFrameData && false === Array.isArray(input.videoFrameData) && $io6(input.videoFrameData, true && _exceptionable)) && (null === input.cropData || undefined === input.cropData || "object" === typeof input.cropData && null !== input.cropData && $io7(input.cropData, true && _exceptionable)) && (1 === Object.keys(input).length || Object.keys(input).every((key: any) => {
                if (["fileName", "thumbName", "exif", "metaData", "customMetaData", "videoFrameData", "cropData"].some((prop: any) => key === prop))
                    return true;
                const value = input[key];
                if (undefined === value)
                    return true;
                return false;
            }));
            const $io1 = (input: any, _exceptionable: boolean = true): boolean => Object.keys(input).every((key: any) => {
                const value = input[key];
                if (undefined === value)
                    return true;
                if (RegExp(/(.*)/).test(key))
                    return true;
                return false;
            });
            const $io2 = (input: any, _exceptionable: boolean = true): boolean => (null === input.custom || undefined === input.custom || "object" === typeof input.custom && null !== input.custom && false === Array.isArray(input.custom) && $io3(input.custom, true && _exceptionable)) && (null === input.dynamic || undefined === input.dynamic || "object" === typeof input.dynamic && null !== input.dynamic && false === Array.isArray(input.dynamic) && $io4(input.dynamic, true && _exceptionable)) && (undefined === input.sharpness || "number" === typeof input.sharpness && 0 <= input.sharpness) && (undefined === input.luminance || "number" === typeof input.luminance && 0 <= input.luminance && 100 >= input.luminance) && (undefined === input.sizeInBytes || "number" === typeof input.sizeInBytes && Math.floor(input.sizeInBytes) === input.sizeInBytes && (-2147483648 <= input.sizeInBytes && input.sizeInBytes <= 2147483647) && 0 <= input.sizeInBytes) && (undefined === input.snr || "number" === typeof input.snr && 0 <= input.snr) && (undefined === input.uniformRowRatio || "number" === typeof input.uniformRowRatio && 0 <= input.uniformRowRatio && 1 >= input.uniformRowRatio) && (undefined === input.mean || Array.isArray(input.mean) && input.mean.every((elem: any, _index1: number) => "number" === typeof elem)) && (undefined === input.shape || Array.isArray(input.shape) && input.shape.every((elem: any, _index2: number) => "number" === typeof elem)) && (undefined === input.std || Array.isArray(input.std) && input.std.every((elem: any, _index3: number) => "number" === typeof elem)) && (undefined === input.sumOfSquares || Array.isArray(input.sumOfSquares) && input.sumOfSquares.every((elem: any, _index4: number) => "number" === typeof elem)) && (undefined === input.sumOfValues || Array.isArray(input.sumOfValues) && input.sumOfValues.every((elem: any, _index5: number) => "number" === typeof elem)) && (0 === Object.keys(input).length || Object.keys(input).every((key: any) => {
                if (["custom", "dynamic", "sharpness", "luminance", "sizeInBytes", "snr", "uniformRowRatio", "mean", "shape", "std", "sumOfSquares", "sumOfValues"].some((prop: any) => key === prop))
                    return true;
                const value = input[key];
                if (undefined === value)
                    return true;
                return false;
            }));
            const $io3 = (input: any, _exceptionable: boolean = true): boolean => Object.keys(input).every((key: any) => {
                const value = input[key];
                if (undefined === value)
                    return true;
                if (RegExp(/(.*)/).test(key))
                    return true;
                return false;
            });
            const $io4 = (input: any, _exceptionable: boolean = true): boolean => Object.keys(input).every((key: any) => {
                const value = input[key];
                if (undefined === value)
                    return true;
                if (RegExp(/(.*)/).test(key))
                    return true;
                return false;
            });
            const $io5 = (input: any, _exceptionable: boolean = true): boolean => Object.keys(input).every((key: any) => {
                const value = input[key];
                if (undefined === value)
                    return true;
                if (RegExp(/(.*)/).test(key))
                    return true;
                return false;
            });
            const $io6 = (input: any, _exceptionable: boolean = true): boolean => (undefined === input.sourceVideo || "string" === typeof input.sourceVideo) && (undefined === input.sourceVideoFrameIndex || "number" === typeof input.sourceVideoFrameIndex) && (undefined === input.sourceVideoFrameTimestamp || "number" === typeof input.sourceVideoFrameTimestamp) && (0 === Object.keys(input).length || Object.keys(input).every((key: any) => {
                if (["sourceVideo", "sourceVideoFrameIndex", "sourceVideoFrameTimestamp"].some((prop: any) => key === prop))
                    return true;
                const value = input[key];
                if (undefined === value)
                    return true;
                return false;
            }));
            const $io7 = (input: any, _exceptionable: boolean = true): boolean => "string" === typeof input.parentId && $is_custom("pattern_parentId", "string", "", input.parentId) && ("number" === typeof input.predictionUUIDTimestamp && 0 <= input.predictionUUIDTimestamp) && ("number" === typeof input.predictionIndex && Math.floor(input.predictionIndex) === input.predictionIndex && (-2147483648 <= input.predictionIndex && input.predictionIndex <= 2147483647) && 0 <= input.predictionIndex) && ("string" === typeof input.predictionTaskName && $is_custom("pattern_predictionTaskName", "string", "", input.predictionTaskName)) && ("number" === typeof input.predictionTaskCategoryId && 0 <= input.predictionTaskCategoryId) && ("number" === typeof input.predictionTaskScore && 0 <= input.predictionTaskScore && 1 >= input.predictionTaskScore) && (6 === Object.keys(input).length || Object.keys(input).every((key: any) => {
                if (["parentId", "predictionUUIDTimestamp", "predictionIndex", "predictionTaskName", "predictionTaskCategoryId", "predictionTaskScore"].some((prop: any) => key === prop))
                    return true;
                const value = input[key];
                if (undefined === value)
                    return true;
                return false;
            }));
            return "object" === typeof input && null !== input && $io0(input, true);
        };
        if (false === __is(input))
            ((input: any, _path: string, _exceptionable: boolean = true): input is SampleCreateRequest => {
                const $guard = (typia.createAssertEquals as any).guard;
                const $join = (typia.createAssertEquals as any).join;
                const $is_custom = (typia.createAssertEquals as any).is_custom;
                const $ao0 = (input: any, _path: string, _exceptionable: boolean = true): boolean => ("string" === typeof input.fileName || $guard(_exceptionable, {
                    path: _path + ".fileName",
                    expected: "string",
                    value: input.fileName
                })) && (undefined === input.thumbName || "string" === typeof input.thumbName || $guard(_exceptionable, {
                    path: _path + ".thumbName",
                    expected: "(string | undefined)",
                    value: input.thumbName
                })) && (undefined === input.exif || ("object" === typeof input.exif && null !== input.exif && false === Array.isArray(input.exif) || $guard(_exceptionable, {
                    path: _path + ".exif",
                    expected: "(__type | undefined)",
                    value: input.exif
                })) && $ao1(input.exif, _path + ".exif", true && _exceptionable) || $guard(_exceptionable, {
                    path: _path + ".exif",
                    expected: "(__type | undefined)",
                    value: input.exif
                })) && (undefined === input.metaData || ("object" === typeof input.metaData && null !== input.metaData && false === Array.isArray(input.metaData) || $guard(_exceptionable, {
                    path: _path + ".metaData",
                    expected: "(SampleMetaData | undefined)",
                    value: input.metaData
                })) && $ao2(input.metaData, _path + ".metaData", true && _exceptionable) || $guard(_exceptionable, {
                    path: _path + ".metaData",
                    expected: "(SampleMetaData | undefined)",
                    value: input.metaData
                })) && (null === input.customMetaData || undefined === input.customMetaData || ("object" === typeof input.customMetaData && null !== input.customMetaData && false === Array.isArray(input.customMetaData) || $guard(_exceptionable, {
                    path: _path + ".customMetaData",
                    expected: "(CustomSampleMetaData | null | undefined)",
                    value: input.customMetaData
                })) && $ao5(input.customMetaData, _path + ".customMetaData", true && _exceptionable) || $guard(_exceptionable, {
                    path: _path + ".customMetaData",
                    expected: "(CustomSampleMetaData | null | undefined)",
                    value: input.customMetaData
                })) && (null === input.videoFrameData || undefined === input.videoFrameData || ("object" === typeof input.videoFrameData && null !== input.videoFrameData && false === Array.isArray(input.videoFrameData) || $guard(_exceptionable, {
                    path: _path + ".videoFrameData",
                    expected: "(VideoFrameData | null | undefined)",
                    value: input.videoFrameData
                })) && $ao6(input.videoFrameData, _path + ".videoFrameData", true && _exceptionable) || $guard(_exceptionable, {
                    path: _path + ".videoFrameData",
                    expected: "(VideoFrameData | null | undefined)",
                    value: input.videoFrameData
                })) && (null === input.cropData || undefined === input.cropData || ("object" === typeof input.cropData && null !== input.cropData || $guard(_exceptionable, {
                    path: _path + ".cropData",
                    expected: "(CropData | null | undefined)",
                    value: input.cropData
                })) && $ao7(input.cropData, _path + ".cropData", true && _exceptionable) || $guard(_exceptionable, {
                    path: _path + ".cropData",
                    expected: "(CropData | null | undefined)",
                    value: input.cropData
                })) && (1 === Object.keys(input).length || (false === _exceptionable || Object.keys(input).every((key: any) => {
                    if (["fileName", "thumbName", "exif", "metaData", "customMetaData", "videoFrameData", "cropData"].some((prop: any) => key === prop))
                        return true;
                    const value = input[key];
                    if (undefined === value)
                        return true;
                    return $guard(_exceptionable, {
                        path: _path + $join(key),
                        expected: "undefined",
                        value: value
                    });
                })));
                const $ao1 = (input: any, _path: string, _exceptionable: boolean = true): boolean => false === _exceptionable || Object.keys(input).every((key: any) => {
                    const value = input[key];
                    if (undefined === value)
                        return true;
                    if (RegExp(/(.*)/).test(key))
                        return true;
                    return $guard(_exceptionable, {
                        path: _path + $join(key),
                        expected: "undefined",
                        value: value
                    });
                });
                const $ao2 = (input: any, _path: string, _exceptionable: boolean = true): boolean => (null === input.custom || undefined === input.custom || ("object" === typeof input.custom && null !== input.custom && false === Array.isArray(input.custom) || $guard(_exceptionable, {
                    path: _path + ".custom",
                    expected: "(__type.o1 | null | undefined)",
                    value: input.custom
                })) && $ao3(input.custom, _path + ".custom", true && _exceptionable) || $guard(_exceptionable, {
                    path: _path + ".custom",
                    expected: "(__type.o1 | null | undefined)",
                    value: input.custom
                })) && (null === input.dynamic || undefined === input.dynamic || ("object" === typeof input.dynamic && null !== input.dynamic && false === Array.isArray(input.dynamic) || $guard(_exceptionable, {
                    path: _path + ".dynamic",
                    expected: "(__type.o2 | null | undefined)",
                    value: input.dynamic
                })) && $ao4(input.dynamic, _path + ".dynamic", true && _exceptionable) || $guard(_exceptionable, {
                    path: _path + ".dynamic",
                    expected: "(__type.o2 | null | undefined)",
                    value: input.dynamic
                })) && (undefined === input.sharpness || "number" === typeof input.sharpness && (0 <= input.sharpness || $guard(_exceptionable, {
                    path: _path + ".sharpness",
                    expected: "number (@minimum 0)",
                    value: input.sharpness
                })) || $guard(_exceptionable, {
                    path: _path + ".sharpness",
                    expected: "(number | undefined)",
                    value: input.sharpness
                })) && (undefined === input.luminance || "number" === typeof input.luminance && (0 <= input.luminance || $guard(_exceptionable, {
                    path: _path + ".luminance",
                    expected: "number (@minimum 0)",
                    value: input.luminance
                })) && (100 >= input.luminance || $guard(_exceptionable, {
                    path: _path + ".luminance",
                    expected: "number (@maximum 100)",
                    value: input.luminance
                })) || $guard(_exceptionable, {
                    path: _path + ".luminance",
                    expected: "(number | undefined)",
                    value: input.luminance
                })) && (undefined === input.sizeInBytes || "number" === typeof input.sizeInBytes && (Math.floor(input.sizeInBytes) === input.sizeInBytes || $guard(_exceptionable, {
                    path: _path + ".sizeInBytes",
                    expected: "number (@type int)",
                    value: input.sizeInBytes
                })) && (-2147483648 <= input.sizeInBytes && input.sizeInBytes <= 2147483647 || $guard(_exceptionable, {
                    path: _path + ".sizeInBytes",
                    expected: "number (@type int)",
                    value: input.sizeInBytes
                })) && (0 <= input.sizeInBytes || $guard(_exceptionable, {
                    path: _path + ".sizeInBytes",
                    expected: "number (@minimum 0)",
                    value: input.sizeInBytes
                })) || $guard(_exceptionable, {
                    path: _path + ".sizeInBytes",
                    expected: "(number | undefined)",
                    value: input.sizeInBytes
                })) && (undefined === input.snr || "number" === typeof input.snr && (0 <= input.snr || $guard(_exceptionable, {
                    path: _path + ".snr",
                    expected: "number (@minimum 0)",
                    value: input.snr
                })) || $guard(_exceptionable, {
                    path: _path + ".snr",
                    expected: "(number | undefined)",
                    value: input.snr
                })) && (undefined === input.uniformRowRatio || "number" === typeof input.uniformRowRatio && (0 <= input.uniformRowRatio || $guard(_exceptionable, {
                    path: _path + ".uniformRowRatio",
                    expected: "number (@minimum 0)",
                    value: input.uniformRowRatio
                })) && (1 >= input.uniformRowRatio || $guard(_exceptionable, {
                    path: _path + ".uniformRowRatio",
                    expected: "number (@maximum 1)",
                    value: input.uniformRowRatio
                })) || $guard(_exceptionable, {
                    path: _path + ".uniformRowRatio",
                    expected: "(number | undefined)",
                    value: input.uniformRowRatio
                })) && (undefined === input.mean || (Array.isArray(input.mean) || $guard(_exceptionable, {
                    path: _path + ".mean",
                    expected: "(Array<number> | undefined)",
                    value: input.mean
                })) && input.mean.every((elem: any, _index1: number) => "number" === typeof elem || $guard(_exceptionable, {
                    path: _path + ".mean[" + _index1 + "]",
                    expected: "number",
                    value: elem
                })) || $guard(_exceptionable, {
                    path: _path + ".mean",
                    expected: "(Array<number> | undefined)",
                    value: input.mean
                })) && (undefined === input.shape || (Array.isArray(input.shape) || $guard(_exceptionable, {
                    path: _path + ".shape",
                    expected: "(Array<number> | undefined)",
                    value: input.shape
                })) && input.shape.every((elem: any, _index2: number) => "number" === typeof elem || $guard(_exceptionable, {
                    path: _path + ".shape[" + _index2 + "]",
                    expected: "number",
                    value: elem
                })) || $guard(_exceptionable, {
                    path: _path + ".shape",
                    expected: "(Array<number> | undefined)",
                    value: input.shape
                })) && (undefined === input.std || (Array.isArray(input.std) || $guard(_exceptionable, {
                    path: _path + ".std",
                    expected: "(Array<number> | undefined)",
                    value: input.std
                })) && input.std.every((elem: any, _index3: number) => "number" === typeof elem || $guard(_exceptionable, {
                    path: _path + ".std[" + _index3 + "]",
                    expected: "number",
                    value: elem
                })) || $guard(_exceptionable, {
                    path: _path + ".std",
                    expected: "(Array<number> | undefined)",
                    value: input.std
                })) && (undefined === input.sumOfSquares || (Array.isArray(input.sumOfSquares) || $guard(_exceptionable, {
                    path: _path + ".sumOfSquares",
                    expected: "(Array<number> | undefined)",
                    value: input.sumOfSquares
                })) && input.sumOfSquares.every((elem: any, _index4: number) => "number" === typeof elem || $guard(_exceptionable, {
                    path: _path + ".sumOfSquares[" + _index4 + "]",
                    expected: "number",
                    value: elem
                })) || $guard(_exceptionable, {
                    path: _path + ".sumOfSquares",
                    expected: "(Array<number> | undefined)",
                    value: input.sumOfSquares
                })) && (undefined === input.sumOfValues || (Array.isArray(input.sumOfValues) || $guard(_exceptionable, {
                    path: _path + ".sumOfValues",
                    expected: "(Array<number> | undefined)",
                    value: input.sumOfValues
                })) && input.sumOfValues.every((elem: any, _index5: number) => "number" === typeof elem || $guard(_exceptionable, {
                    path: _path + ".sumOfValues[" + _index5 + "]",
                    expected: "number",
                    value: elem
                })) || $guard(_exceptionable, {
                    path: _path + ".sumOfValues",
                    expected: "(Array<number> | undefined)",
                    value: input.sumOfValues
                })) && (0 === Object.keys(input).length || (false === _exceptionable || Object.keys(input).every((key: any) => {
                    if (["custom", "dynamic", "sharpness", "luminance", "sizeInBytes", "snr", "uniformRowRatio", "mean", "shape", "std", "sumOfSquares", "sumOfValues"].some((prop: any) => key === prop))
                        return true;
                    const value = input[key];
                    if (undefined === value)
                        return true;
                    return $guard(_exceptionable, {
                        path: _path + $join(key),
                        expected: "undefined",
                        value: value
                    });
                })));
                const $ao3 = (input: any, _path: string, _exceptionable: boolean = true): boolean => false === _exceptionable || Object.keys(input).every((key: any) => {
                    const value = input[key];
                    if (undefined === value)
                        return true;
                    if (RegExp(/(.*)/).test(key))
                        return true;
                    return $guard(_exceptionable, {
                        path: _path + $join(key),
                        expected: "undefined",
                        value: value
                    });
                });
                const $ao4 = (input: any, _path: string, _exceptionable: boolean = true): boolean => false === _exceptionable || Object.keys(input).every((key: any) => {
                    const value = input[key];
                    if (undefined === value)
                        return true;
                    if (RegExp(/(.*)/).test(key))
                        return true;
                    return $guard(_exceptionable, {
                        path: _path + $join(key),
                        expected: "undefined",
                        value: value
                    });
                });
                const $ao5 = (input: any, _path: string, _exceptionable: boolean = true): boolean => false === _exceptionable || Object.keys(input).every((key: any) => {
                    const value = input[key];
                    if (undefined === value)
                        return true;
                    if (RegExp(/(.*)/).test(key))
                        return true;
                    return $guard(_exceptionable, {
                        path: _path + $join(key),
                        expected: "undefined",
                        value: value
                    });
                });
                const $ao6 = (input: any, _path: string, _exceptionable: boolean = true): boolean => (undefined === input.sourceVideo || "string" === typeof input.sourceVideo || $guard(_exceptionable, {
                    path: _path + ".sourceVideo",
                    expected: "(string | undefined)",
                    value: input.sourceVideo
                })) && (undefined === input.sourceVideoFrameIndex || "number" === typeof input.sourceVideoFrameIndex || $guard(_exceptionable, {
                    path: _path + ".sourceVideoFrameIndex",
                    expected: "(number | undefined)",
                    value: input.sourceVideoFrameIndex
                })) && (undefined === input.sourceVideoFrameTimestamp || "number" === typeof input.sourceVideoFrameTimestamp || $guard(_exceptionable, {
                    path: _path + ".sourceVideoFrameTimestamp",
                    expected: "(number | undefined)",
                    value: input.sourceVideoFrameTimestamp
                })) && (0 === Object.keys(input).length || (false === _exceptionable || Object.keys(input).every((key: any) => {
                    if (["sourceVideo", "sourceVideoFrameIndex", "sourceVideoFrameTimestamp"].some((prop: any) => key === prop))
                        return true;
                    const value = input[key];
                    if (undefined === value)
                        return true;
                    return $guard(_exceptionable, {
                        path: _path + $join(key),
                        expected: "undefined",
                        value: value
                    });
                })));
                const $ao7 = (input: any, _path: string, _exceptionable: boolean = true): boolean => ("string" === typeof input.parentId && ($is_custom("pattern_parentId", "string", "", input.parentId) || $guard(_exceptionable, {
                    path: _path + ".parentId",
                    expected: "string (@pattern_parentId)",
                    value: input.parentId
                })) || $guard(_exceptionable, {
                    path: _path + ".parentId",
                    expected: "string",
                    value: input.parentId
                })) && ("number" === typeof input.predictionUUIDTimestamp && (0 <= input.predictionUUIDTimestamp || $guard(_exceptionable, {
                    path: _path + ".predictionUUIDTimestamp",
                    expected: "number (@minimum 0)",
                    value: input.predictionUUIDTimestamp
                })) || $guard(_exceptionable, {
                    path: _path + ".predictionUUIDTimestamp",
                    expected: "number",
                    value: input.predictionUUIDTimestamp
                })) && ("number" === typeof input.predictionIndex && (Math.floor(input.predictionIndex) === input.predictionIndex || $guard(_exceptionable, {
                    path: _path + ".predictionIndex",
                    expected: "number (@type int)",
                    value: input.predictionIndex
                })) && (-2147483648 <= input.predictionIndex && input.predictionIndex <= 2147483647 || $guard(_exceptionable, {
                    path: _path + ".predictionIndex",
                    expected: "number (@type int)",
                    value: input.predictionIndex
                })) && (0 <= input.predictionIndex || $guard(_exceptionable, {
                    path: _path + ".predictionIndex",
                    expected: "number (@minimum 0)",
                    value: input.predictionIndex
                })) || $guard(_exceptionable, {
                    path: _path + ".predictionIndex",
                    expected: "number",
                    value: input.predictionIndex
                })) && ("string" === typeof input.predictionTaskName && ($is_custom("pattern_predictionTaskName", "string", "", input.predictionTaskName) || $guard(_exceptionable, {
                    path: _path + ".predictionTaskName",
                    expected: "string (@pattern_predictionTaskName)",
                    value: input.predictionTaskName
                })) || $guard(_exceptionable, {
                    path: _path + ".predictionTaskName",
                    expected: "string",
                    value: input.predictionTaskName
                })) && ("number" === typeof input.predictionTaskCategoryId && (0 <= input.predictionTaskCategoryId || $guard(_exceptionable, {
                    path: _path + ".predictionTaskCategoryId",
                    expected: "number (@minimum 0)",
                    value: input.predictionTaskCategoryId
                })) || $guard(_exceptionable, {
                    path: _path + ".predictionTaskCategoryId",
                    expected: "number",
                    value: input.predictionTaskCategoryId
                })) && ("number" === typeof input.predictionTaskScore && (0 <= input.predictionTaskScore || $guard(_exceptionable, {
                    path: _path + ".predictionTaskScore",
                    expected: "number (@minimum 0)",
                    value: input.predictionTaskScore
                })) && (1 >= input.predictionTaskScore || $guard(_exceptionable, {
                    path: _path + ".predictionTaskScore",
                    expected: "number (@maximum 1)",
                    value: input.predictionTaskScore
                })) || $guard(_exceptionable, {
                    path: _path + ".predictionTaskScore",
                    expected: "number",
                    value: input.predictionTaskScore
                })) && (6 === Object.keys(input).length || (false === _exceptionable || Object.keys(input).every((key: any) => {
                    if (["parentId", "predictionUUIDTimestamp", "predictionIndex", "predictionTaskName", "predictionTaskCategoryId", "predictionTaskScore"].some((prop: any) => key === prop))
                        return true;
                    const value = input[key];
                    if (undefined === value)
                        return true;
                    return $guard(_exceptionable, {
                        path: _path + $join(key),
                        expected: "undefined",
                        value: value
                    });
                })));
                return ("object" === typeof input && null !== input || $guard(true, {
                    path: _path + "",
                    expected: "SampleCreateRequest",
                    value: input
                })) && $ao0(input, _path + "", true) || $guard(true, {
                    path: _path + "",
                    expected: "SampleCreateRequest",
                    value: input
                });
            })(input, "$input", true);
        return input;
    })(input);
};
export const SampleCreateRequestTypiaAssertStringify = (input: SampleCreateRequest): string => {
    return ((input: any): string => { const assert = (input: any): SampleCreateRequest => {
        const __is = (input: any): input is SampleCreateRequest => {
            const $join = (typia.createAssertStringify as any).join;
            const $is_custom = (typia.createAssertStringify as any).is_custom;
            const $io0 = (input: any): boolean => "string" === typeof input.fileName && (undefined === input.thumbName || "string" === typeof input.thumbName) && (undefined === input.exif || "object" === typeof input.exif && null !== input.exif && false === Array.isArray(input.exif) && $io1(input.exif)) && (undefined === input.metaData || "object" === typeof input.metaData && null !== input.metaData && false === Array.isArray(input.metaData) && $io2(input.metaData)) && (null === input.customMetaData || undefined === input.customMetaData || "object" === typeof input.customMetaData && null !== input.customMetaData && false === Array.isArray(input.customMetaData) && $io5(input.customMetaData)) && (null === input.videoFrameData || undefined === input.videoFrameData || "object" === typeof input.videoFrameData && null !== input.videoFrameData && false === Array.isArray(input.videoFrameData) && $io6(input.videoFrameData)) && (null === input.cropData || undefined === input.cropData || "object" === typeof input.cropData && null !== input.cropData && $io7(input.cropData));
            const $io1 = (input: any): boolean => Object.keys(input).every((key: any) => {
                const value = input[key];
                if (undefined === value)
                    return true;
                if (RegExp(/(.*)/).test(key))
                    return true;
                return true;
            });
            const $io2 = (input: any): boolean => (null === input.custom || undefined === input.custom || "object" === typeof input.custom && null !== input.custom && false === Array.isArray(input.custom) && $io3(input.custom)) && (null === input.dynamic || undefined === input.dynamic || "object" === typeof input.dynamic && null !== input.dynamic && false === Array.isArray(input.dynamic) && $io4(input.dynamic)) && (undefined === input.sharpness || "number" === typeof input.sharpness && !Number.isNaN(input.sharpness) && 0 <= input.sharpness) && (undefined === input.luminance || "number" === typeof input.luminance && 0 <= input.luminance && 100 >= input.luminance) && (undefined === input.sizeInBytes || "number" === typeof input.sizeInBytes && !Number.isNaN(input.sizeInBytes) && Math.floor(input.sizeInBytes) === input.sizeInBytes && (-2147483648 <= input.sizeInBytes && input.sizeInBytes <= 2147483647) && 0 <= input.sizeInBytes) && (undefined === input.snr || "number" === typeof input.snr && !Number.isNaN(input.snr) && 0 <= input.snr) && (undefined === input.uniformRowRatio || "number" === typeof input.uniformRowRatio && 0 <= input.uniformRowRatio && 1 >= input.uniformRowRatio) && (undefined === input.mean || Array.isArray(input.mean) && input.mean.every((elem: any) => "number" === typeof elem && !Number.isNaN(elem))) && (undefined === input.shape || Array.isArray(input.shape) && input.shape.every((elem: any) => "number" === typeof elem && !Number.isNaN(elem))) && (undefined === input.std || Array.isArray(input.std) && input.std.every((elem: any) => "number" === typeof elem && !Number.isNaN(elem))) && (undefined === input.sumOfSquares || Array.isArray(input.sumOfSquares) && input.sumOfSquares.every((elem: any) => "number" === typeof elem && !Number.isNaN(elem))) && (undefined === input.sumOfValues || Array.isArray(input.sumOfValues) && input.sumOfValues.every((elem: any) => "number" === typeof elem && !Number.isNaN(elem)));
            const $io3 = (input: any): boolean => Object.keys(input).every((key: any) => {
                const value = input[key];
                if (undefined === value)
                    return true;
                if (RegExp(/(.*)/).test(key))
                    return true;
                return true;
            });
            const $io4 = (input: any): boolean => Object.keys(input).every((key: any) => {
                const value = input[key];
                if (undefined === value)
                    return true;
                if (RegExp(/(.*)/).test(key))
                    return true;
                return true;
            });
            const $io5 = (input: any): boolean => Object.keys(input).every((key: any) => {
                const value = input[key];
                if (undefined === value)
                    return true;
                if (RegExp(/(.*)/).test(key))
                    return true;
                return true;
            });
            const $io6 = (input: any): boolean => (undefined === input.sourceVideo || "string" === typeof input.sourceVideo) && (undefined === input.sourceVideoFrameIndex || "number" === typeof input.sourceVideoFrameIndex && !Number.isNaN(input.sourceVideoFrameIndex)) && (undefined === input.sourceVideoFrameTimestamp || "number" === typeof input.sourceVideoFrameTimestamp && !Number.isNaN(input.sourceVideoFrameTimestamp));
            const $io7 = (input: any): boolean => "string" === typeof input.parentId && $is_custom("pattern_parentId", "string", "", input.parentId) && ("number" === typeof input.predictionUUIDTimestamp && !Number.isNaN(input.predictionUUIDTimestamp) && 0 <= input.predictionUUIDTimestamp) && ("number" === typeof input.predictionIndex && !Number.isNaN(input.predictionIndex) && Math.floor(input.predictionIndex) === input.predictionIndex && (-2147483648 <= input.predictionIndex && input.predictionIndex <= 2147483647) && 0 <= input.predictionIndex) && ("string" === typeof input.predictionTaskName && $is_custom("pattern_predictionTaskName", "string", "", input.predictionTaskName)) && ("number" === typeof input.predictionTaskCategoryId && !Number.isNaN(input.predictionTaskCategoryId) && 0 <= input.predictionTaskCategoryId) && ("number" === typeof input.predictionTaskScore && 0 <= input.predictionTaskScore && 1 >= input.predictionTaskScore);
            return "object" === typeof input && null !== input && $io0(input);
        };
        if (false === __is(input))
            ((input: any, _path: string, _exceptionable: boolean = true): input is SampleCreateRequest => {
                const $guard = (typia.createAssertStringify as any).guard;
                const $join = (typia.createAssertStringify as any).join;
                const $is_custom = (typia.createAssertStringify as any).is_custom;
                const $ao0 = (input: any, _path: string, _exceptionable: boolean = true): boolean => ("string" === typeof input.fileName || $guard(_exceptionable, {
                    path: _path + ".fileName",
                    expected: "string",
                    value: input.fileName
                })) && (undefined === input.thumbName || "string" === typeof input.thumbName || $guard(_exceptionable, {
                    path: _path + ".thumbName",
                    expected: "(string | undefined)",
                    value: input.thumbName
                })) && (undefined === input.exif || ("object" === typeof input.exif && null !== input.exif && false === Array.isArray(input.exif) || $guard(_exceptionable, {
                    path: _path + ".exif",
                    expected: "(__type | undefined)",
                    value: input.exif
                })) && $ao1(input.exif, _path + ".exif", true && _exceptionable) || $guard(_exceptionable, {
                    path: _path + ".exif",
                    expected: "(__type | undefined)",
                    value: input.exif
                })) && (undefined === input.metaData || ("object" === typeof input.metaData && null !== input.metaData && false === Array.isArray(input.metaData) || $guard(_exceptionable, {
                    path: _path + ".metaData",
                    expected: "(SampleMetaData | undefined)",
                    value: input.metaData
                })) && $ao2(input.metaData, _path + ".metaData", true && _exceptionable) || $guard(_exceptionable, {
                    path: _path + ".metaData",
                    expected: "(SampleMetaData | undefined)",
                    value: input.metaData
                })) && (null === input.customMetaData || undefined === input.customMetaData || ("object" === typeof input.customMetaData && null !== input.customMetaData && false === Array.isArray(input.customMetaData) || $guard(_exceptionable, {
                    path: _path + ".customMetaData",
                    expected: "(CustomSampleMetaData | null | undefined)",
                    value: input.customMetaData
                })) && $ao5(input.customMetaData, _path + ".customMetaData", true && _exceptionable) || $guard(_exceptionable, {
                    path: _path + ".customMetaData",
                    expected: "(CustomSampleMetaData | null | undefined)",
                    value: input.customMetaData
                })) && (null === input.videoFrameData || undefined === input.videoFrameData || ("object" === typeof input.videoFrameData && null !== input.videoFrameData && false === Array.isArray(input.videoFrameData) || $guard(_exceptionable, {
                    path: _path + ".videoFrameData",
                    expected: "(VideoFrameData | null | undefined)",
                    value: input.videoFrameData
                })) && $ao6(input.videoFrameData, _path + ".videoFrameData", true && _exceptionable) || $guard(_exceptionable, {
                    path: _path + ".videoFrameData",
                    expected: "(VideoFrameData | null | undefined)",
                    value: input.videoFrameData
                })) && (null === input.cropData || undefined === input.cropData || ("object" === typeof input.cropData && null !== input.cropData || $guard(_exceptionable, {
                    path: _path + ".cropData",
                    expected: "(CropData | null | undefined)",
                    value: input.cropData
                })) && $ao7(input.cropData, _path + ".cropData", true && _exceptionable) || $guard(_exceptionable, {
                    path: _path + ".cropData",
                    expected: "(CropData | null | undefined)",
                    value: input.cropData
                }));
                const $ao1 = (input: any, _path: string, _exceptionable: boolean = true): boolean => false === _exceptionable || Object.keys(input).every((key: any) => {
                    const value = input[key];
                    if (undefined === value)
                        return true;
                    if (RegExp(/(.*)/).test(key))
                        return true;
                    return true;
                });
                const $ao2 = (input: any, _path: string, _exceptionable: boolean = true): boolean => (null === input.custom || undefined === input.custom || ("object" === typeof input.custom && null !== input.custom && false === Array.isArray(input.custom) || $guard(_exceptionable, {
                    path: _path + ".custom",
                    expected: "(__type.o1 | null | undefined)",
                    value: input.custom
                })) && $ao3(input.custom, _path + ".custom", true && _exceptionable) || $guard(_exceptionable, {
                    path: _path + ".custom",
                    expected: "(__type.o1 | null | undefined)",
                    value: input.custom
                })) && (null === input.dynamic || undefined === input.dynamic || ("object" === typeof input.dynamic && null !== input.dynamic && false === Array.isArray(input.dynamic) || $guard(_exceptionable, {
                    path: _path + ".dynamic",
                    expected: "(__type.o2 | null | undefined)",
                    value: input.dynamic
                })) && $ao4(input.dynamic, _path + ".dynamic", true && _exceptionable) || $guard(_exceptionable, {
                    path: _path + ".dynamic",
                    expected: "(__type.o2 | null | undefined)",
                    value: input.dynamic
                })) && (undefined === input.sharpness || "number" === typeof input.sharpness && !Number.isNaN(input.sharpness) && (0 <= input.sharpness || $guard(_exceptionable, {
                    path: _path + ".sharpness",
                    expected: "number (@minimum 0)",
                    value: input.sharpness
                })) || $guard(_exceptionable, {
                    path: _path + ".sharpness",
                    expected: "(number | undefined)",
                    value: input.sharpness
                })) && (undefined === input.luminance || "number" === typeof input.luminance && (0 <= input.luminance || $guard(_exceptionable, {
                    path: _path + ".luminance",
                    expected: "number (@minimum 0)",
                    value: input.luminance
                })) && (100 >= input.luminance || $guard(_exceptionable, {
                    path: _path + ".luminance",
                    expected: "number (@maximum 100)",
                    value: input.luminance
                })) || $guard(_exceptionable, {
                    path: _path + ".luminance",
                    expected: "(number | undefined)",
                    value: input.luminance
                })) && (undefined === input.sizeInBytes || "number" === typeof input.sizeInBytes && !Number.isNaN(input.sizeInBytes) && (Math.floor(input.sizeInBytes) === input.sizeInBytes || $guard(_exceptionable, {
                    path: _path + ".sizeInBytes",
                    expected: "number (@type int)",
                    value: input.sizeInBytes
                })) && (-2147483648 <= input.sizeInBytes && input.sizeInBytes <= 2147483647 || $guard(_exceptionable, {
                    path: _path + ".sizeInBytes",
                    expected: "number (@type int)",
                    value: input.sizeInBytes
                })) && (0 <= input.sizeInBytes || $guard(_exceptionable, {
                    path: _path + ".sizeInBytes",
                    expected: "number (@minimum 0)",
                    value: input.sizeInBytes
                })) || $guard(_exceptionable, {
                    path: _path + ".sizeInBytes",
                    expected: "(number | undefined)",
                    value: input.sizeInBytes
                })) && (undefined === input.snr || "number" === typeof input.snr && !Number.isNaN(input.snr) && (0 <= input.snr || $guard(_exceptionable, {
                    path: _path + ".snr",
                    expected: "number (@minimum 0)",
                    value: input.snr
                })) || $guard(_exceptionable, {
                    path: _path + ".snr",
                    expected: "(number | undefined)",
                    value: input.snr
                })) && (undefined === input.uniformRowRatio || "number" === typeof input.uniformRowRatio && (0 <= input.uniformRowRatio || $guard(_exceptionable, {
                    path: _path + ".uniformRowRatio",
                    expected: "number (@minimum 0)",
                    value: input.uniformRowRatio
                })) && (1 >= input.uniformRowRatio || $guard(_exceptionable, {
                    path: _path + ".uniformRowRatio",
                    expected: "number (@maximum 1)",
                    value: input.uniformRowRatio
                })) || $guard(_exceptionable, {
                    path: _path + ".uniformRowRatio",
                    expected: "(number | undefined)",
                    value: input.uniformRowRatio
                })) && (undefined === input.mean || (Array.isArray(input.mean) || $guard(_exceptionable, {
                    path: _path + ".mean",
                    expected: "(Array<number> | undefined)",
                    value: input.mean
                })) && input.mean.every((elem: any, _index1: number) => "number" === typeof elem && !Number.isNaN(elem) || $guard(_exceptionable, {
                    path: _path + ".mean[" + _index1 + "]",
                    expected: "number",
                    value: elem
                })) || $guard(_exceptionable, {
                    path: _path + ".mean",
                    expected: "(Array<number> | undefined)",
                    value: input.mean
                })) && (undefined === input.shape || (Array.isArray(input.shape) || $guard(_exceptionable, {
                    path: _path + ".shape",
                    expected: "(Array<number> | undefined)",
                    value: input.shape
                })) && input.shape.every((elem: any, _index2: number) => "number" === typeof elem && !Number.isNaN(elem) || $guard(_exceptionable, {
                    path: _path + ".shape[" + _index2 + "]",
                    expected: "number",
                    value: elem
                })) || $guard(_exceptionable, {
                    path: _path + ".shape",
                    expected: "(Array<number> | undefined)",
                    value: input.shape
                })) && (undefined === input.std || (Array.isArray(input.std) || $guard(_exceptionable, {
                    path: _path + ".std",
                    expected: "(Array<number> | undefined)",
                    value: input.std
                })) && input.std.every((elem: any, _index3: number) => "number" === typeof elem && !Number.isNaN(elem) || $guard(_exceptionable, {
                    path: _path + ".std[" + _index3 + "]",
                    expected: "number",
                    value: elem
                })) || $guard(_exceptionable, {
                    path: _path + ".std",
                    expected: "(Array<number> | undefined)",
                    value: input.std
                })) && (undefined === input.sumOfSquares || (Array.isArray(input.sumOfSquares) || $guard(_exceptionable, {
                    path: _path + ".sumOfSquares",
                    expected: "(Array<number> | undefined)",
                    value: input.sumOfSquares
                })) && input.sumOfSquares.every((elem: any, _index4: number) => "number" === typeof elem && !Number.isNaN(elem) || $guard(_exceptionable, {
                    path: _path + ".sumOfSquares[" + _index4 + "]",
                    expected: "number",
                    value: elem
                })) || $guard(_exceptionable, {
                    path: _path + ".sumOfSquares",
                    expected: "(Array<number> | undefined)",
                    value: input.sumOfSquares
                })) && (undefined === input.sumOfValues || (Array.isArray(input.sumOfValues) || $guard(_exceptionable, {
                    path: _path + ".sumOfValues",
                    expected: "(Array<number> | undefined)",
                    value: input.sumOfValues
                })) && input.sumOfValues.every((elem: any, _index5: number) => "number" === typeof elem && !Number.isNaN(elem) || $guard(_exceptionable, {
                    path: _path + ".sumOfValues[" + _index5 + "]",
                    expected: "number",
                    value: elem
                })) || $guard(_exceptionable, {
                    path: _path + ".sumOfValues",
                    expected: "(Array<number> | undefined)",
                    value: input.sumOfValues
                }));
                const $ao3 = (input: any, _path: string, _exceptionable: boolean = true): boolean => false === _exceptionable || Object.keys(input).every((key: any) => {
                    const value = input[key];
                    if (undefined === value)
                        return true;
                    if (RegExp(/(.*)/).test(key))
                        return true;
                    return true;
                });
                const $ao4 = (input: any, _path: string, _exceptionable: boolean = true): boolean => false === _exceptionable || Object.keys(input).every((key: any) => {
                    const value = input[key];
                    if (undefined === value)
                        return true;
                    if (RegExp(/(.*)/).test(key))
                        return true;
                    return true;
                });
                const $ao5 = (input: any, _path: string, _exceptionable: boolean = true): boolean => false === _exceptionable || Object.keys(input).every((key: any) => {
                    const value = input[key];
                    if (undefined === value)
                        return true;
                    if (RegExp(/(.*)/).test(key))
                        return true;
                    return true;
                });
                const $ao6 = (input: any, _path: string, _exceptionable: boolean = true): boolean => (undefined === input.sourceVideo || "string" === typeof input.sourceVideo || $guard(_exceptionable, {
                    path: _path + ".sourceVideo",
                    expected: "(string | undefined)",
                    value: input.sourceVideo
                })) && (undefined === input.sourceVideoFrameIndex || "number" === typeof input.sourceVideoFrameIndex && !Number.isNaN(input.sourceVideoFrameIndex) || $guard(_exceptionable, {
                    path: _path + ".sourceVideoFrameIndex",
                    expected: "(number | undefined)",
                    value: input.sourceVideoFrameIndex
                })) && (undefined === input.sourceVideoFrameTimestamp || "number" === typeof input.sourceVideoFrameTimestamp && !Number.isNaN(input.sourceVideoFrameTimestamp) || $guard(_exceptionable, {
                    path: _path + ".sourceVideoFrameTimestamp",
                    expected: "(number | undefined)",
                    value: input.sourceVideoFrameTimestamp
                }));
                const $ao7 = (input: any, _path: string, _exceptionable: boolean = true): boolean => ("string" === typeof input.parentId && ($is_custom("pattern_parentId", "string", "", input.parentId) || $guard(_exceptionable, {
                    path: _path + ".parentId",
                    expected: "string (@pattern_parentId)",
                    value: input.parentId
                })) || $guard(_exceptionable, {
                    path: _path + ".parentId",
                    expected: "string",
                    value: input.parentId
                })) && ("number" === typeof input.predictionUUIDTimestamp && !Number.isNaN(input.predictionUUIDTimestamp) && (0 <= input.predictionUUIDTimestamp || $guard(_exceptionable, {
                    path: _path + ".predictionUUIDTimestamp",
                    expected: "number (@minimum 0)",
                    value: input.predictionUUIDTimestamp
                })) || $guard(_exceptionable, {
                    path: _path + ".predictionUUIDTimestamp",
                    expected: "number",
                    value: input.predictionUUIDTimestamp
                })) && ("number" === typeof input.predictionIndex && !Number.isNaN(input.predictionIndex) && (Math.floor(input.predictionIndex) === input.predictionIndex || $guard(_exceptionable, {
                    path: _path + ".predictionIndex",
                    expected: "number (@type int)",
                    value: input.predictionIndex
                })) && (-2147483648 <= input.predictionIndex && input.predictionIndex <= 2147483647 || $guard(_exceptionable, {
                    path: _path + ".predictionIndex",
                    expected: "number (@type int)",
                    value: input.predictionIndex
                })) && (0 <= input.predictionIndex || $guard(_exceptionable, {
                    path: _path + ".predictionIndex",
                    expected: "number (@minimum 0)",
                    value: input.predictionIndex
                })) || $guard(_exceptionable, {
                    path: _path + ".predictionIndex",
                    expected: "number",
                    value: input.predictionIndex
                })) && ("string" === typeof input.predictionTaskName && ($is_custom("pattern_predictionTaskName", "string", "", input.predictionTaskName) || $guard(_exceptionable, {
                    path: _path + ".predictionTaskName",
                    expected: "string (@pattern_predictionTaskName)",
                    value: input.predictionTaskName
                })) || $guard(_exceptionable, {
                    path: _path + ".predictionTaskName",
                    expected: "string",
                    value: input.predictionTaskName
                })) && ("number" === typeof input.predictionTaskCategoryId && !Number.isNaN(input.predictionTaskCategoryId) && (0 <= input.predictionTaskCategoryId || $guard(_exceptionable, {
                    path: _path + ".predictionTaskCategoryId",
                    expected: "number (@minimum 0)",
                    value: input.predictionTaskCategoryId
                })) || $guard(_exceptionable, {
                    path: _path + ".predictionTaskCategoryId",
                    expected: "number",
                    value: input.predictionTaskCategoryId
                })) && ("number" === typeof input.predictionTaskScore && (0 <= input.predictionTaskScore || $guard(_exceptionable, {
                    path: _path + ".predictionTaskScore",
                    expected: "number (@minimum 0)",
                    value: input.predictionTaskScore
                })) && (1 >= input.predictionTaskScore || $guard(_exceptionable, {
                    path: _path + ".predictionTaskScore",
                    expected: "number (@maximum 1)",
                    value: input.predictionTaskScore
                })) || $guard(_exceptionable, {
                    path: _path + ".predictionTaskScore",
                    expected: "number",
                    value: input.predictionTaskScore
                }));
                return ("object" === typeof input && null !== input || $guard(true, {
                    path: _path + "",
                    expected: "SampleCreateRequest",
                    value: input
                })) && $ao0(input, _path + "", true) || $guard(true, {
                    path: _path + "",
                    expected: "SampleCreateRequest",
                    value: input
                });
            })(input, "$input", true);
        return input;
    }; const stringify = (input: SampleCreateRequest): string => {
        const $io1 = (input: any): boolean => Object.keys(input).every((key: any) => {
            const value = input[key];
            if (undefined === value)
                return true;
            if (RegExp(/(.*)/).test(key))
                return true;
            return true;
        });
        const $io2 = (input: any): boolean => (null === input.custom || undefined === input.custom || "object" === typeof input.custom && null !== input.custom && false === Array.isArray(input.custom) && $io3(input.custom)) && (null === input.dynamic || undefined === input.dynamic || "object" === typeof input.dynamic && null !== input.dynamic && false === Array.isArray(input.dynamic) && $io4(input.dynamic)) && (undefined === input.sharpness || "number" === typeof input.sharpness && 0 <= input.sharpness) && (undefined === input.luminance || "number" === typeof input.luminance && 0 <= input.luminance && 100 >= input.luminance) && (undefined === input.sizeInBytes || "number" === typeof input.sizeInBytes && Math.floor(input.sizeInBytes) === input.sizeInBytes && (-2147483648 <= input.sizeInBytes && input.sizeInBytes <= 2147483647) && 0 <= input.sizeInBytes) && (undefined === input.snr || "number" === typeof input.snr && 0 <= input.snr) && (undefined === input.uniformRowRatio || "number" === typeof input.uniformRowRatio && 0 <= input.uniformRowRatio && 1 >= input.uniformRowRatio) && (undefined === input.mean || Array.isArray(input.mean) && input.mean.every((elem: any) => "number" === typeof elem)) && (undefined === input.shape || Array.isArray(input.shape) && input.shape.every((elem: any) => "number" === typeof elem)) && (undefined === input.std || Array.isArray(input.std) && input.std.every((elem: any) => "number" === typeof elem)) && (undefined === input.sumOfSquares || Array.isArray(input.sumOfSquares) && input.sumOfSquares.every((elem: any) => "number" === typeof elem)) && (undefined === input.sumOfValues || Array.isArray(input.sumOfValues) && input.sumOfValues.every((elem: any) => "number" === typeof elem));
        const $io3 = (input: any): boolean => Object.keys(input).every((key: any) => {
            const value = input[key];
            if (undefined === value)
                return true;
            if (RegExp(/(.*)/).test(key))
                return true;
            return true;
        });
        const $io4 = (input: any): boolean => Object.keys(input).every((key: any) => {
            const value = input[key];
            if (undefined === value)
                return true;
            if (RegExp(/(.*)/).test(key))
                return true;
            return true;
        });
        const $io5 = (input: any): boolean => Object.keys(input).every((key: any) => {
            const value = input[key];
            if (undefined === value)
                return true;
            if (RegExp(/(.*)/).test(key))
                return true;
            return true;
        });
        const $io6 = (input: any): boolean => (undefined === input.sourceVideo || "string" === typeof input.sourceVideo) && (undefined === input.sourceVideoFrameIndex || "number" === typeof input.sourceVideoFrameIndex) && (undefined === input.sourceVideoFrameTimestamp || "number" === typeof input.sourceVideoFrameTimestamp);
        const $io7 = (input: any): boolean => "string" === typeof input.parentId && $is_custom("pattern_parentId", "string", "", input.parentId) && ("number" === typeof input.predictionUUIDTimestamp && 0 <= input.predictionUUIDTimestamp) && ("number" === typeof input.predictionIndex && Math.floor(input.predictionIndex) === input.predictionIndex && (-2147483648 <= input.predictionIndex && input.predictionIndex <= 2147483647) && 0 <= input.predictionIndex) && ("string" === typeof input.predictionTaskName && $is_custom("pattern_predictionTaskName", "string", "", input.predictionTaskName)) && ("number" === typeof input.predictionTaskCategoryId && 0 <= input.predictionTaskCategoryId) && ("number" === typeof input.predictionTaskScore && 0 <= input.predictionTaskScore && 1 >= input.predictionTaskScore);
        const $string = (typia.createAssertStringify as any).string;
        const $join = (typia.createAssertStringify as any).join;
        const $tail = (typia.createAssertStringify as any).tail;
        const $is_custom = (typia.createAssertStringify as any).is_custom;
        const $so0 = (input: any): any => `{${undefined === input.thumbName ? "" : `"thumbName":${undefined !== input.thumbName ? $string(input.thumbName) : undefined},`}${undefined === input.exif ? "" : `"exif":${undefined !== input.exif ? $so1(input.exif) : undefined},`}${undefined === input.metaData ? "" : `"metaData":${undefined !== input.metaData ? $so2(input.metaData) : undefined},`}${undefined === input.customMetaData ? "" : `"customMetaData":${undefined !== input.customMetaData ? null !== input.customMetaData ? $so5(input.customMetaData) : "null" : undefined},`}${undefined === input.videoFrameData ? "" : `"videoFrameData":${undefined !== input.videoFrameData ? null !== input.videoFrameData ? $so6(input.videoFrameData) : "null" : undefined},`}${undefined === input.cropData ? "" : `"cropData":${undefined !== input.cropData ? null !== input.cropData ? `{"parentId":${$string((input.cropData as any).parentId)},"predictionUUIDTimestamp":${(input.cropData as any).predictionUUIDTimestamp},"predictionIndex":${(input.cropData as any).predictionIndex},"predictionTaskName":${$string((input.cropData as any).predictionTaskName)},"predictionTaskCategoryId":${(input.cropData as any).predictionTaskCategoryId},"predictionTaskScore":${(input.cropData as any).predictionTaskScore}}` : "null" : undefined},`}"fileName":${$string(input.fileName)}}`;
        const $so1 = (input: any): any => `{${Object.entries(input).map(([key, value]: [string, any]) => { if (undefined === value)
            return ""; return `${JSON.stringify(key)}:${undefined !== value ? JSON.stringify(value) : undefined}`; }).filter((str: any) => "" !== str).join(",")}}`;
        const $so2 = (input: any): any => `{${$tail(`${undefined === input.custom ? "" : `"custom":${undefined !== input.custom ? null !== input.custom ? $so3(input.custom) : "null" : undefined},`}${undefined === input.dynamic ? "" : `"dynamic":${undefined !== input.dynamic ? null !== input.dynamic ? $so4(input.dynamic) : "null" : undefined},`}${undefined === input.sharpness ? "" : `"sharpness":${undefined !== input.sharpness ? input.sharpness : undefined},`}${undefined === input.luminance ? "" : `"luminance":${undefined !== input.luminance ? input.luminance : undefined},`}${undefined === input.sizeInBytes ? "" : `"sizeInBytes":${undefined !== input.sizeInBytes ? input.sizeInBytes : undefined},`}${undefined === input.snr ? "" : `"snr":${undefined !== input.snr ? input.snr : undefined},`}${undefined === input.uniformRowRatio ? "" : `"uniformRowRatio":${undefined !== input.uniformRowRatio ? input.uniformRowRatio : undefined},`}${undefined === input.mean ? "" : `"mean":${undefined !== input.mean ? `[${input.mean.map((elem: any) => elem).join(",")}]` : undefined},`}${undefined === input.shape ? "" : `"shape":${undefined !== input.shape ? `[${input.shape.map((elem: any) => elem).join(",")}]` : undefined},`}${undefined === input.std ? "" : `"std":${undefined !== input.std ? `[${input.std.map((elem: any) => elem).join(",")}]` : undefined},`}${undefined === input.sumOfSquares ? "" : `"sumOfSquares":${undefined !== input.sumOfSquares ? `[${input.sumOfSquares.map((elem: any) => elem).join(",")}]` : undefined},`}${undefined === input.sumOfValues ? "" : `"sumOfValues":${undefined !== input.sumOfValues ? `[${input.sumOfValues.map((elem: any) => elem).join(",")}]` : undefined}`}`)}}`;
        const $so3 = (input: any): any => `{${Object.entries(input).map(([key, value]: [string, any]) => { if (undefined === value)
            return ""; return `${JSON.stringify(key)}:${undefined !== value ? JSON.stringify(value) : undefined}`; }).filter((str: any) => "" !== str).join(",")}}`;
        const $so4 = (input: any): any => `{${Object.entries(input).map(([key, value]: [string, any]) => { if (undefined === value)
            return ""; return `${JSON.stringify(key)}:${undefined !== value ? JSON.stringify(value) : undefined}`; }).filter((str: any) => "" !== str).join(",")}}`;
        const $so5 = (input: any): any => `{${Object.entries(input).map(([key, value]: [string, any]) => { if (undefined === value)
            return ""; return `${JSON.stringify(key)}:${undefined !== value ? JSON.stringify(value) : undefined}`; }).filter((str: any) => "" !== str).join(",")}}`;
        const $so6 = (input: any): any => `{${$tail(`${undefined === input.sourceVideo ? "" : `"sourceVideo":${undefined !== input.sourceVideo ? $string(input.sourceVideo) : undefined},`}${undefined === input.sourceVideoFrameIndex ? "" : `"sourceVideoFrameIndex":${undefined !== input.sourceVideoFrameIndex ? input.sourceVideoFrameIndex : undefined},`}${undefined === input.sourceVideoFrameTimestamp ? "" : `"sourceVideoFrameTimestamp":${undefined !== input.sourceVideoFrameTimestamp ? input.sourceVideoFrameTimestamp : undefined}`}`)}}`;
        return $so0(input);
    }; return stringify(assert(input)); })(input);
};
export const SampleCreateRequestValidatorShape = {
    'fileName': yup.string().defined(),
    'thumbName': yup.string(),
    'exif': yup.mixed().transform((val: any) => { return val instanceof Object && !Array.isArray(val) ? val : {}; }),
    'metaData': SampleMetaDataValidator.optional().default(undefined),
    'customMetaData': CustomSampleMetaDataValidator.nullable().optional().default(undefined),
    'videoFrameData': VideoFrameDataValidator.nullable().optional().default(undefined),
    'cropData': CropDataValidator.nullable().optional().default(undefined),
};
export const SampleCreateRequestValidator = yup.object().shape(SampleCreateRequestValidatorShape);
export const SampleCreateRequestStringifyShapeProperties: ObjectSchema['properties'] = {
    'fileName': {
        'type': 'string',
    } as unknown as fastJSON.StringSchema,
    'thumbName': {
        'type': 'string',
    } as unknown as fastJSON.StringSchema,
    'exif': {
        type: 'object',
        additionalProperties: true
    },
    'metaData': SampleMetaDataStringifyShape,
    'customMetaData': CustomSampleMetaDataStringifyShape,
    'videoFrameData': VideoFrameDataStringifyShape,
    'cropData': CropDataStringifyShape,
};
export const SampleCreateRequestStringifyShape: ObjectSchema = {
    title: 'SampleCreateRequest Stringifier',
    type: 'object',
    properties: {
        ...SampleCreateRequestStringifyShapeProperties
    },
    required: [
        'fileName',
    ],
};
export const SampleCreateRequestStringifyCompiled = (typeof process === 'object' && String(process) === '[object process]') ?
    fastJSON.build(SampleCreateRequestStringifyShape, {
        ajv: {
            // since we are already doing this with yup, lets skip
            validateSchema: false,
            ownProperties: true,
            validateFormats: false,
        },
        largeArrayMechanism: 'json-stringify',
        largeArraySize: 1000,
    } as Options)
    :
        () => { throw new Error('fast-json-stringify only works in nodejs'); };
export const SampleCreateRequestStringify: (data: SampleCreateRequest) => string = SampleCreateRequestStringifyCompiled;
/* LIGHTLY CUSTOM END -> Generic */
export function SampleCreateRequestFromJSON(json: any): SampleCreateRequest {
    return SampleCreateRequestFromJSONTyped(json, false);
}
export function SampleCreateRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): SampleCreateRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    /* causes circular depdendencies
*/
    return {
        'fileName': json['fileName'],
        'thumbName': !exists(json, 'thumbName') ? undefined : json['thumbName'],
        'exif': !exists(json, 'exif') ? undefined : json['exif'],
        'metaData': !exists(json, 'metaData') ? undefined : SampleMetaDataFromJSON(json['metaData']),
        'customMetaData': !exists(json, 'customMetaData') ? undefined : CustomSampleMetaDataFromJSON(json['customMetaData']),
        'videoFrameData': !exists(json, 'videoFrameData') ? undefined : VideoFrameDataFromJSON(json['videoFrameData']),
        'cropData': !exists(json, 'cropData') ? undefined : CropDataFromJSON(json['cropData']),
    };
}
export function SampleCreateRequestToJSON(value?: SampleCreateRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'fileName': value.fileName,
        'thumbName': value.thumbName,
        'exif': value.exif,
        'metaData': SampleMetaDataToJSON(value.metaData),
        'customMetaData': CustomSampleMetaDataToJSON(value.customMetaData),
        'videoFrameData': VideoFrameDataToJSON(value.videoFrameData),
        'cropData': CropDataToJSON(value.cropData),
    };
}
