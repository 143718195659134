/* tslint:disable */
/* eslint-disable */
/**
 * Lightly API
 * Lightly.ai enables you to do self-supervised learning in an easy and intuitive way. The lightly.ai OpenAPI spec defines how one can interact with our REST API to unleash the full potential of lightly.ai
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: support@lightly.ai
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
// SRC: https://github.com/OpenAPITools/openapi-generator/blob/master/modules/openapi-generator/src/main/resources/typescript-fetch/modelEnumInterfaces.mustache
// synched 02.02.2023
import * as yup from 'yup';
import * as fastJSON from 'fast-json-stringify';
import { StringSchema } from 'fast-json-stringify';
/**
 *
 * @export
 * @enum {string}
 */
export enum JobStatusUploadMethod {
    USER_WEBAPP = 'USER_WEBAPP',
    USER_PIP = 'USER_PIP',
    INTERNAL = 'INTERNAL'
}
/* LIGHTLY CUSTOM START -> modelEnumInterfaces */
export const JobStatusUploadMethodValidatorShape = [
    'USER_WEBAPP',
    'USER_PIP',
    'INTERNAL'
];
export const JobStatusUploadMethodValidator = yup.mixed().oneOf(JobStatusUploadMethodValidatorShape);
export const JobStatusUploadMethodStringifyShapeProperties = {};
export const JobStatusUploadMethodStringifyShape: StringSchema = {
    title: 'JobStatusUploadMethod Stringifier',
    type: 'string'
};
export const JobStatusUploadMethodStringify = (typeof process === 'object' && String(process) === '[object process]') ?
    fastJSON.build(JobStatusUploadMethodStringifyShape)
    :
        () => { throw new Error('fast-json-stringify only works in nodejs'); };
/* LIGHTLY CUSTOM END */
export function JobStatusUploadMethodFromJSON(json: any): JobStatusUploadMethod {
    return JobStatusUploadMethodFromJSONTyped(json, false);
}
export function JobStatusUploadMethodFromJSONTyped(json: any, ignoreDiscriminator: boolean): JobStatusUploadMethod {
    return json as JobStatusUploadMethod;
}
export function JobStatusUploadMethodToJSON(value?: JobStatusUploadMethod | null): any {
    return value as any;
}
